import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import * as ReCharg from 'recharts'
import { Chart, PieController, ArcElement, Legend, Tooltip, Title, LineController, LineElement, LinearScale, registerables } from 'chart.js';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale)
Chart.register(...registerables)



Chart.register(PieController, ArcElement, Title, Legend, Tooltip, LineController, LineElement, LinearScale,);


const PieChart = () => {
    return (
        <div>
            <Doughnut style={{ marginTop: '60px ' }}
                data={{
                    labels: ['Jawwal', 'Ooredoo', 'Cellcom', 'Pelephone', 'Partner', '012 Mobile'],
                    datasets: [
                        {
                            label: 'Jawwal',
                            data: [100, 220, 150, 170, 210, 230],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }}
                height={400}
                width={600}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    responsive: true,
                    aspectRatio: 1,
                    layout: {
                        padding: {
                            left: 50,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    }
                }}
            />
        </div>
    )
}

export default PieChart