import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  TextField,
  MenuItem,
  Box,
  Typography,
  Grid,
  InputLabel,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "react-dropdown/style.css";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import translate from "i18n/translate";
import CancelIcon from "@mui/icons-material/Cancel";
import UserTypesDropdown from "core/core-components/UserTypesDropdown/UserTypesDropdown";
import Select from "core/core-components/Select/Select";
import { formatSellerOptionsForSelect } from "shared/utils/formatUtils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: 5,
    padding: "16px 16px",
    outline: "none",
  },
  buttonContainer: {
    marginTop: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ModalExample = ({
  allSeller,
  amount,
  openModal,
  setOpenModal,
  onSettingClick,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [changeText, setChangeText] = useState("");
  console.log("selectedName", selectedName);

  useEffect(() => {
    const allSellerObject = formatSellerOptionsForSelect(allSeller);
    setOptions(allSellerObject);
    const selectedName0 = allSellerObject.find(
      (option) => option.value === amount.subprovider
    );

    setSelectedName(selectedName0);
    setConfirmMessage([selectedName0?.label, amount.dealercost]);
    setChangeText(amount.dealercost);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSelectChange = (event) => {
    // console.log("event", event);
    setSelectedName(event);

    setConfirmMessage([event.label, amount.dealercost]);
    setChangeText(amount.dealercost);
  };

  const handleConfirm = () => {
    // Perform the send action here
    handleClose();
    onSettingClick(amount, selectedName.value, confirmMessage[1]);
  };
  const handleEditClick = (e) => {
    setIsEditing(true);
  };

  const isConfirmDisabled = !selectedName;
  const handleChange = (event) => {
    setChangeText(event.target.value);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setConfirmMessage([selectedName.label, changeText]);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <div>
      {/* <Button variant="contained" onClick={handleOpen}>
        Open Modal
      </Button> */}

      <Modal open={openModal} onClose={handleClose} className={classes.modal}>
        <Box
          className={classes.paper}
          sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "40%" } }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {translate("Send Confirmation")}
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Select
                options={options}
                // isDisabled={disabled}
                value={selectedName}
                isClearable
                onChange={handleSelectChange}
                placeholder="User type"
                // styles={{ height: "32px", ...customStyles }}
              />
            </Grid>
            {confirmMessage && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translate("Are you sure you want to send to")}{" "}
                  {confirmMessage[0]} {translate("amount_")}{" "}
                  {isEditing ? "" : confirmMessage[1]} {translate("?")}{" "}
                  {isEditing ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <TextField
                        value={changeText}
                        onChange={handleChange}
                        //   onBlur={handleSaveClick}
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                      />
                      <Button
                        variant="contained"
                        onClick={handleSaveClick}
                        startIcon={<SaveIcon sx={{ ml: "2px", mr: "2px" }} />}
                      >
                        {translate("save")}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleCancelClick}
                        startIcon={<CancelIcon sx={{ ml: "2px", mr: "2px" }} />}
                        color="error"
                      >
                        {translate("cancel")}
                      </Button>
                    </Box>
                  ) : (
                    <IconButton onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ ml: "5px", mr: "5px" }}
                color="error"
              >
                {translate("cancel")}
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirm}
                disabled={isConfirmDisabled}
                sx={{ ml: "5px", mr: "5px" }}
              >
                {translate("Confirm")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalExample;
