import React from "react";
import { Link } from "react-router-dom";
import { PROVIDER_ROUTES } from "shared/constants/constants";

const CompaniesDropDown = ({ mobile, allCompanies }) => {
  const hide = localStorage.getItem("hide companies");

  // const companiesArr = [
  //     { id: 1, className: 'jawwal-back', linkTo: `/company/jawwalCredit/${mobile}`, title: 'jawwal', location: "PS" },
  //     { id: 2, className: 'ooredoo-back', linkTo: `/company/ooredoo/credit/${mobile}`, title: 'ooredoo', location: "PS" },
  //     { id: 3, className: 'cellcom-back', linkTo: `/company/group/cellcom/${mobile}`, title: 'cellcom', location: "IL" },
  //     { id: 4, className: 'pelephone-back', linkTo: `/company/group/pelephone/${mobile}`, title: 'pelephone', location: "IL" },
  //     { id: 5, className: 'golan-back', linkTo: `/company/group/golan/${mobile}`, title: 'golan', location: "IL" },
  //     { id: 6, className: 'partner-back', linkTo: `/company/group/partner/${mobile}`, title: 'partner', location: "IL" },
  //     { id: 7, className: 'mobile019-back', linkTo: `/company/group/mobile019/${mobile}`, title: 'O1Mobile', location: "IL" },
  //     { id: 8, className: 'mobile012-back', linkTo: `/company/group/mobile012/${mobile}`, title: 'mobile012', location: "IL" },
  //     { id: 9, className: 'hot-back', linkTo: `/company/hot/${mobile}`, title: 'hotMobile', location: "IL" },
  // ]

  const LinkToPage = (company) => {
    const { product, id } = company;

    if (id === "001") {
      return {
        pathname: `/company/jawwalCredit/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    }

    if (id === "002") {
      return {
        pathname: `/company/ooredoo/credit/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    }

    if (id === "003") {
      return {
        pathname: `/company/group/cellcom/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    }
    if (id === "008") {
      return {
        pathname: `/company/hot/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    }

    if (id === "009") {
      return {
        pathname: `${PROVIDER_ROUTES.allProviderRoutes.Mobile019}/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    } else {
      return {
        pathname: `/company/group/${product}/${mobile}`,
        state: {
          provider_id: id,
          provider_Id: id,
        },
      };
    }
  };

  return (
    <div>
      {allCompanies.map((company, i) => {
        return (
          (company.location === "PAL" || (company.location === "ISR" && hide !== "true")) &&
          company.active === "true" && (
            <div key={i}>
              <Link to={LinkToPage(company)}>{company.product}</Link>
            </div>
          )
        );
      })}
    </div>
  );
};

export default CompaniesDropDown;
