import React from "react";
// import ApiRequest from "../../../actions/ApiRequest";

import { Button, Modal, Box, Typography } from "@mui/material";
import "react-dropdown/style.css";
import { makeStyles } from "@mui/styles";
import translate from "i18n/translate";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: 5,
    padding: "16px 16px",
    outline: "none",
  },
  buttonContainer: {
    marginTop: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EsimModal = ({
  openModal,
  setOpenModal,
  message,
  onConfirm,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.modal}
    >
      <Box
        className={classes.paper}
        sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "40%" } }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {message}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            setOpenModal(false);
          }}
          sx={{ ml: "5px", mr: "5px" }}
          color="error"
        >
          {translate("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onConfirm}
          sx={{ ml: "5px", mr: "5px" }}
          loading={isLoading}
        >
          {translate("Confirm")}
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default EsimModal;
