import React from "react";
import Badge from "../../ui/Badge/Badge";

import translate from "../../../i18n/translate";
import { dataLanguage } from "../../../i18n/translateRequest";

const JawalMinCard = ({
  onTypeClick,
  columnStyle,
  item,
  index,
  collapseRef,
  isDarktheme,
}) => {
  return (
    <div className={`${columnStyle} mt-3`}>
      <div className="card charge-card">
        <a
          style={{ cursor: "pointer" }}
          data-placement="top"
          title={item && item.des}
          onClick={() => onTypeClick(item)}
        >
          <div className="position-relative">
            <img alt={item.id} src={item.url}></img>
            {(item.renew === "True" || item.renew === "true") && (
              <Badge text={translate("Renewable")}></Badge>
            )}
          </div>
        </a>
      </div>
      <div className={`${isDarktheme ? "dark-card" : "card"}`}>
        <div className="text-center" id="headingOne">
          <h5 className="mb-0 mx-">
            <a
              href="#0"
              className="link-main details"
              style={{ fontWeight: "bolder" }}
              data-toggle="collapse"
              data-target={"#" + index}
              aria-expanded="true"
              aria-controls={index}
            >
              <small>{translate("Details")}</small>
            </a>
          </h5>
        </div>

        <div
          id={index}
          className="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
          ref={(el) => (collapseRef.current[index] = el)}
        >
          <div className="card-body">
            {dataLanguage(item, "ar_des", "en_des", "he_des")}
            <br />
            <p style={{ textAlign: "center" }}>{item.id || item.ID}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JawalMinCard;
