import {
  GET_INSURANCES, CLEAR_ERRORS, GET_ERRORS,
  GET_INSURANCES_CALCULATIONS_FAIL, GET_INSURANCES_CALCULATIONS,
  GET_INSURANCES_CALCULATIONS_LOADING, SUBMIT_INSURANCE_LOADING
} from "./types";
import ApiRequest from "./ApiRequest";
import sha256 from 'crypto-js/sha256';

export const getInsurances = (lang) => (dispatch) => {
  dispatch(clearErrors());
  return new Promise((resolve, reject) => {
    const storageHash = sha256(`insurance`).toString();
    const insurances = JSON.parse(sessionStorage.getItem(storageHash));
    if (insurances) {
      dispatch({
        type: GET_INSURANCES,
        payload: insurances,
      });
      resolve(true);
    } else {
      return ApiRequest
        .post(`get_products?category=insurance&topup_type=manually`) //get_products?category=insurance&topup_type=manually
        .then((res) => {
          dispatch({
            type: GET_INSURANCES,
            payload: res.data,
          });
          sessionStorage.setItem(storageHash, JSON.stringify(res.data));
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload: "Something went wrong !!",
          });
          reject(false);
        });
    }
  })
};

export const getInsuranceCalculations = (car_usage, engine_size, weight, passenger, data, setActiveStep) => (dispatch) => {
  dispatch(clearErrors());
  let p46 = '';
  let p7 = ''
  let p8 = ''

  !engine_size && (engine_size = '')
  !weight && (weight = '')
  !passenger && (passenger = '')

  passenger?.value === 'passengers_4_to_6' && (p46 = 1)
  passenger?.value === 'passengers_7' && (p7 = 1)
  passenger?.value === 'passengers_8' && (p8 = 1)

  dispatch(insuranceCalculationLoading(true))
  return ApiRequest
    .post(`calculate_insuarance_fees?car_usage=${car_usage}&engine_size=${engine_size}&weight=${weight}&p46=${p46}&p7=${p7}&p8=${p8}`)
    .then((res) => {
      dispatch(insuranceCalculation(res.data));
      dispatch(insuranceCalculationLoading(false))
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      return
    })
    .catch((err) => {
      dispatch(insuranceCalculationLoading(false))
      dispatch(insuranceCalculationFail('Something Wend Wrong !'))
      return
    });
}

export const submitInsurance = (data) => (dispatch) => {
  let ecp = ''
  if (data.ecp) {
    ecp = data.ecp
  }
  dispatch(submitInsuranceLoading(true))
  return ApiRequest
    .post(`insert_insurance_order?name=${data.name}&mobile=${data.mobile}&id=${data.id}&v_number=${data.v_number
      }&v_type=${data.v_type}&v_model=${data.v_model}&prod_year=${data.prod_year
      }&provider=${data.provider}&provider_id=${data.provider_id}&amount=${data.amount
      }&insurance_type=${data.insurance_type}&ecp=${ecp}&url=${data.url}&transid-1=${data.front}&transid-2=${data.back
      }&transid-3=${data.left}&transid-4=${data.right}&location=PAL`)
    .then((res) => {

      dispatch(submitInsuranceLoading(false))
      return res.data

    })
    .catch((err) => {
      dispatch(submitInsuranceLoading(false))
      return {
        status: "failed",
        reason: "Something Went Wrong !!"
      }
    });
}

export const insuranceCalculation = (data) => {
  return {
    type: GET_INSURANCES_CALCULATIONS,
    payload: data
  };
};

export const insuranceCalculationLoading = (boolean) => {
  return {
    type: GET_INSURANCES_CALCULATIONS_LOADING,
    payload: boolean
  };
};

export const submitInsuranceLoading = (boolean) => {
  return {
    type: SUBMIT_INSURANCE_LOADING,
    payload: boolean
  };
};


export const insuranceCalculationFail = (errorMessage) => {
  return {
    type: GET_INSURANCES_CALCULATIONS_FAIL,
    payload: errorMessage
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
