import {
  GET_INSURANCES, GET_INSURANCES_CALCULATIONS_FAIL, GET_INSURANCES_CALCULATIONS, GET_INSURANCES_CALCULATIONS_LOADING,
  SUBMIT_INSURANCE_LOADING
} from "../actions/types";
const initialState = {
  list: [],
  loading: true,
  insuranceCalculations: null,
  insuranceCalculationsLoading: false,
  submitInsuranceLoading: false,
  insuranceCalculationsFail: ''
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSURANCES:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case GET_INSURANCES_CALCULATIONS:
      return {
        ...state,
        insuranceCalculations: action.payload,
      };
    case GET_INSURANCES_CALCULATIONS_LOADING:
      return {
        ...state,
        insuranceCalculationsLoading: action.payload,
      };
    case SUBMIT_INSURANCE_LOADING:
      return {
        ...state,
        submitInsuranceLoading: action.payload,
      };
    case GET_INSURANCES_CALCULATIONS_FAIL:
      return {
        ...state,
        insuranceCalculationsFail: action.payload,
      };
    default:
      return state;
  }
}

export default insuranceReducer
