import React from "react";
import { Avatar, Grid, Typography } from "../../../../node_modules/@mui/material/index";
import { makeStyles } from "../../../../node_modules/@mui/styles/index";
import PropTypes from "prop-types";
import classNames from "../../../../node_modules/classnames/index";

const useStyles = makeStyles(() => ({
  avatarContainer: {
    display: "flex",
    marginTop: 8,
  },
  avatarLabel: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: "4px",
    marginLeft: "4px",
  },
  pointer: {
    cursor: "pointer",
  },
}));

export default function AvatarWithText(props) {
  let { name, onClick, avatarProps, src, icon, textProps } = props;
  avatarProps = avatarProps || {};
  const { className, ...restAvatarProps } = avatarProps;

  const classes = useStyles();

  return (
    <div className={classNames(classes.avatarContainer, { [classes.pointer]: !!onClick })} onClick={onClick}>
      <div className={classes.avatarLabel}>
        <Avatar className={classNames(classes.avatar, className)} alt={name} src={src} {...restAvatarProps}>
          {icon ? icon : <i className="icofont-doctor-alt" style={{ color: "#0046c0" }}></i>}
        </Avatar>
        <Typography variant="body2" {...textProps}>
          {name}
        </Typography>
      </div>
    </div>
  );
}

AvatarWithText.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  avatarProps: PropTypes.object,
  src: PropTypes.string,
  icon: PropTypes.any,
  textProps: PropTypes.object,
};

export function AvatarWithTextVertical(props) {
  let { name, onClick, avatarProps, src, icon, textProps, textGridContainerProps, rootGridProps } = props;
  avatarProps = avatarProps || {};
  const { className, ...restAvatarProps } = avatarProps;

  const classes = useStyles();

  return (
    <Grid container className={classNames(classes.avatarContainer, { [classes.pointer]: !!onClick })} onClick={onClick} {...rootGridProps}>
      <Grid item md={12} xs={12}>
        <Avatar className={classNames(classes.avatar, className)} alt={name} src={src} {...restAvatarProps}>
          {icon ? icon : <i className="icofont-doctor-alt" style={{ color: "#0046c0" }}></i>}
        </Avatar>
        <Grid item md={12} xs={12} {...textGridContainerProps}>
          <Typography variant="body2" {...textProps}>
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

AvatarWithTextVertical.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  avatarProps: PropTypes.object,
  rootGridProps: PropTypes.object,
  textGridContainerProps: PropTypes.object,
  src: PropTypes.string,
  icon: PropTypes.any,
  textProps: PropTypes.object,
};

AvatarWithTextVertical.defaultProps = {
  avatarProps: {},
  rootGridProps: {},
  textGridContainerProps: {},
};
