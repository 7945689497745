import React, { useState, useEffect } from "react";
import { getFireBaseToken } from "../../firebase";

const Notifications = ({ setNotToken = () => {} }) => {
  const [isTokenFound, setTokenFound] = useState(false);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getFireBaseToken(setTokenFound, setNotToken);

      if (data) {
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
