import {
  GET_CONTACT_US_MESSAGES
} from "../actions/types";

const initialState = {
  contactUsMessages: [],
  loading: true,
};

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_US_MESSAGES:
      return {
        ...state,
        contactUsMessages: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default contactUsReducer
