import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import ApiRequest from "../../../../actions/ApiRequest.js";
import { parseObjToQueryParams } from "../../../../shared/utils/utils.js";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import Toast from "../../../common/Toast.js";
import NavigateBackHeader from "../../../../core/core-components/NavigateBackHeader/NavigateBackHeader.jsx";
import TextFieldGroup from "../../../common/TextFieldGroup";
import Notiflix from "notiflix";

const propTypes = {};

const defaultProps = {};

const IsraeliFineInquiryStyle = () => ({
  gridRoot: {
    marginTop: 16,
    "& > div": {
      width: "40%",
    },
  },
  inputLabel: {
    "& label": {
      fontWeight: "400 !important",
      fontSize: "1rem",
    },
  },
  inputStyle: {
    "& .input-field": {
      fontWeight: "400 !important",
      fontSize: "1rem !important",
    },
  },
});

const useStyle = makeStyles(IsraeliFineInquiryStyle);

export default function IsraeliFineInquiry(props) {
  const classes = useStyle(props);
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    setIsSubmitting(true);
    try {
      const queryURL = `fine_inquiry?${parseObjToQueryParams(data)}`;
      await ApiRequest.post(queryURL);

      Toast.fire({
        title: intl.formatMessage({
          id: "israeli_bill_inquiry_success_msg",
        }),
        icon: "success",
      });
    } catch (e) {
      console.log("ERROR IsraeliFineInquiry", e);
      Notiflix.Notify.failure("Something Went Wrong! - " +e?.message);
    }
    setIsSubmitting(false);
  }

  const InputField = (pr) => (
    <TextFieldGroup
      labelProps={{
        className: classes.inputLabel,
      }}
      className={classes.inputStyle}
      placeholder={pr.label}
      disable={isSubmitting}
      error={
        !!errors[pr?.name]
          ? intl.formatMessage({
              id: "wrong_input",
            }) + "!"
          : ""
      }
      {...pr}
    />
  );
  return (
    <React.Fragment>
      <Grid container>
        <NavigateBackHeader
          onBackClick={() => {
            history.push("/bills/israel_fines");
          }}
          title={"israeli_fine_inquiry_title"}
        />
        <Grid item md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              className={classes.gridRoot}
              container
              direction="column"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item paddingBottom={1} paddingTop={"0 !important"}>
                <Typography
                  component={"label"}
                  fontSize={"small"}
                  fontWeight={"bolder"}
                  fontFamily={'"Helvetica Neue W23-regular"'}
                  color={"error"}
                >
                  *{intl.formatMessage({ id: "israeli_bill_inquiry_fees_msg" })}
                </Typography>
              </Grid>
              <Grid item>
                <Controller
                  name="license_number"
                  rules={{ required: true, pattern: /^\d+$/ }}
                  control={control}
                  render={({ field }) => (
                    <InputField
                      label={
                        "*" +
                        intl.formatMessage({
                          id: "license_no",
                        })
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  rules={{ required: true, pattern: /^\d+$/ }}
                  name="mobile_number"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      label={
                        "*" +
                        intl.formatMessage({
                          id: "Mobile Number",
                        })
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name={"id"}
                  control={control}
                  rules={{ required: true, pattern: /^\d+$/ }}
                  render={({ field }) => (
                    <InputField
                      id="outlined-basic"
                      label={
                        "*" +
                        intl.formatMessage({
                          id: "Identity Number",
                        })
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item paddingBottom={1}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      type="email"
                      id="outlined-basic"
                      label={intl.formatMessage({ id: "Email" })}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item container justifyContent={"center"}>
                <LoadingButton fullWidth loading={isSubmitting} variant={"contained"} type={"submit"}>
                  {intl.formatMessage({ id: "search" })}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

IsraeliFineInquiry.propTypes = propTypes;
IsraeliFineInquiry.defaultProps = defaultProps;
