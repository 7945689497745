import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserTypesDropdownContainer from "../../core/containers/UserTypesDropdownContainer/UserTypesDropdownContainer";
import ReportHeader from "./ReportHeader";
import SearchButton from "../../core/core-components/SearchButton/SearchButton";
import PPTable from "../../core/core-components/PPTable/PPTable";
import translate from "../../i18n/translate";
import { getSellerDebt } from "../../actions/sellerCreditsAction";
import { PURGE_SELLER_CREDITS } from "../../actions/types";
import { conditionalReturn } from "shared/utils/utils";
import ExportButton from "components/common/ExportButton";

const DebitReport = () => {
  const dispatch = useDispatch();
  const [seller, setSeller] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [paid, setPaid] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const sellerDebts = useSelector((state) => state.credits.sellerDebts);
  useEffect(() => {
    // console.log("sellerDebts", sellerDebts);
  }, [sellerDebts]);
  const isAdminSeller = useSelector(
    (state) => state.user?.seller_type === "admin"
  );

  useEffect(() => {
    dispatch({
      type: PURGE_SELLER_CREDITS,
    });
  }, [dispatch]);

  function handleSearch() {
    setIsLoading(true);
    dispatch(getSellerDebt(seller?.value, paid)).finally(() => {
      setIsLoading(false);
    });
  }

  function renderUserTypes() {
    return (
      <UserTypesDropdownContainer
        enableSelectAll={false}
        onSellerSelected={(val) => setSeller(val)}
      />
    );
  }

  function renderSideHeader() {
    return (
      <Grid container justifyContent={"flex-end"}>
        <Grid item>
          <p>
            {translate("total_debt")} : {sellerDebts[0]?.total_debt || 0} NIS
          </p>
        </Grid>
      </Grid>
    );
  }

  function renderTable() {
    const headers = [
      { children: translate("index"), scope: "col" },
      { children: translate("Admin"), scope: "col" },
      { children: translate("amount"), scope: "col" },
      { children: translate("Paid Amount"), scope: "col" },
      { children: translate("note"), scope: "col" },
      { children: translate("date"), scope: "col" },
    ];

    const rows = [];
    sellerDebts.forEach((val) => {
      const data = [];

      data.push({ children: val.index });
      data.push({ children: val.admin_user });
      data.push({ children: val.amount });
      data.push({ children: val.amount_paid });
      data.push({ children: val.note });
      data.push({ children: val.datetime });

      rows.push({ data });
    });

    return (
      <PPTable
        showEmptyView
        sideHeader={renderSideHeader()}
        headers={headers}
        rows={rows}
        title={translate("debit_report")}
        // count={count}
        // page={page}
        // sort={}
      />
    );
  }

  return (
    <div>
      <ReportHeader />

      <Grid container spacing={1} gap={3} marginTop={1}>
        <Grid
          container
          item
          md={12}
          xs={12}
          justifyContent="right"
          alignContent="center"
          alignItems={"center"}
        >
          {conditionalReturn(
            isAdminSeller,
            <Grid item md={8}>
              {renderUserTypes()}
            </Grid>
          )}
          <Grid item md={1} xs={1} paddingLeft={"8px"}>
            <FormControlLabel
              label={translate("paid")}
              control={
                <Checkbox
                  checked={paid}
                  onChange={(event) => setPaid(event.target.checked)}
                />
              }
            />
          </Grid>
          <Grid item md={3} paddingLeft={"16px"}>
            <SearchButton isLoading={isLoading} onClick={handleSearch} />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}></Grid>
        <Grid item md={12} xs={12} minHeight={200}>
          {renderTable()}
        </Grid>
      </Grid>
    </div>
  );
};

export default DebitReport;
