import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isArray } from "lodash";

import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import CopyAllIcon from "@mui/icons-material/CopyAll";

import ShareIcon from "@mui/icons-material/Share";
import TextFieldGroup from "../common/TextFieldGroup";
import Spinner from "../ui/spinner/Spinner";
import Modal from "../common/Modal";
import ApiRequest from "../../actions/ApiRequest";
import ShareModal from "./ShareModal";
import UsersCompanies from "./UsersCompanies";

import translate from "../../i18n/translate";
import { intl } from "./../../i18n/provider";

import { getDiscounts } from "./../../actions/discountsAction";
import {
  getSellerData,
  updateProfile,
  getAnotherSellerData,
} from "../../actions/userAction";
import { getSellers } from "../../actions/sellerCreditsAction";

import "./styles.css";
import Settings from "../ui/Settings/Settings";
import { useIsAdmin } from "../../shared/hooks/ppHooks.js";
import ChangeProfile from "./ChangeProfile";
import Toast from "components/common/Toast";
import ContactOptions from "./ContactOptions";
import { profileData } from "./profileData";

const Profile = ({
  getSellerData,
  userDetails,
  getAnotherSellerData,
  userDetailsLoading,
  sellers,
  getSellers,
  updateProfile,
  user,
  getDiscounts,
  discounts,
  isDarktheme,
}) => {
  const hide = localStorage.getItem("hide companies");

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [seller, setSeller] = useState(userDetails);
  const [filteredData, setFilteredData] = useState(sellers);
  const [discountData, setDiscountData] = useState([]);

  const [shareModal, setShareModal] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const { isAdmin, isReseller } = useIsAdmin();
  const sellerId = useSelector((state) => state.auth.user?.id);

  // console.log("hide", seller);
  useEffect(() => {
    // console.log("userId", user.id);
    try {
      getSellerData(user.id);
      getSellers(isReseller && sellerId);
      getDiscounts();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails) setSeller(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if (isArray(discounts) && discounts) {
      let discountsArr = [];
      if (hide === "true") {
        discountsArr = discounts.filter((item) => item.location !== "ISR");
        setDiscountData(discountsArr);
        return;
      }
      setDiscountData(discounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discounts]);

  useEffect(() => {
    if (sellers.length) {
      setFilteredData(sellers);
    }
  }, [sellers]);

  const mockChangeProfileData = useMemo(() => profileData(seller), [seller]);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    console.log(seller.password, oldPassword);
    if (oldPassword !== seller.password) {
      setPasswordError("Current Password is invalid");
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordError("Should be equal to New Password");
      return;
    }
    setPasswordError("");
    setUpdateLoading(true);
    try {
      const res = await ApiRequest.post(
        `update_seller_profile?operation=profile&password=${password}&seller_id=${userDetails.id}`
      );
      if (res.data.status.toLowerCase() === "failed") {
        throw new Error(res.data.reason);
      }
      // console.log(res.data);
      Toast.fire({
        title: "Settings Updated Successfully!",
        icon: "success",
      });
      await getSellerData(user.id);
      setChangePasswordModal(false);
      setPassword("");
      setOldPassword("");
      setPasswordConfirmation("");
    } catch (err) {
      Toast.fire({
        title: err.message,
        icon: "error",
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  function stringAvatar(name) {
    let str = name.split(" ");
    let returnedValue;
    if (str.length === 1) {
      returnedValue = `${name.split(" ")[0][0]}`;
    } else {
      returnedValue = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return {
      children: returnedValue,
    };
  }

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options =
    filteredData.length &&
    filteredData?.map((item) => {
      return {
        ...item,
        value: item.seller_id,
        label: item.name + " " + item.seller_id,
      };
    });

  const handleSellerChosen = async (seller) => {
    console.log("seller", seller);

    getAnotherSellerData(seller?.seller_id || seller?.id, setSeller);
    getDiscounts(
      user?.id !== (seller?.seller_id || seller?.id),
      seller?.seller_id || seller?.id,
      setDiscountData
    );
  };

  return (
    <div>
      <Settings isAuthenticated={true} />
      <div
        className={!isDarktheme ? "profile-page" : "dark-profile-page"}
        style={{ width: "100%", margin: "auto" }}
      >
        <h3>{translate("Account Settings")}</h3>
        {isAdmin && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="pt-2"
          >
            <h5 className="mx-3">Change user</h5>
            <div style={{ width: "75%" }}>
              <Select
                // defaultValue={colourOptions[2]}
                placeholder={translate("select_seller")}
                options={options}
                onChange={(e) => handleSellerChosen(e)}
              />
            </div>
          </div>
        )}
        <div className="col-12 px-4 py-1">
          <div>
            {seller && seller.name && (
              <Avatar
                {...stringAvatar(`${seller?.name}`)}
                sx={{
                  bgcolor: "#45b4e3",
                  width: "150px",
                  height: "150px",
                  margin: "32px 26px",
                  fontSize: "36px",
                }}
              />
            )}
            {/* <h6>
              <span>{translate("Name")}:</span> {seller?.name}
            </h6> */}
            {mockChangeProfileData.map((item) =>
              isAdmin || !item.isAdmin ? (
                item.aKey === "password" ? (
                  isAdmin ? (
                    <ChangeProfile
                      desc={item.desc}
                      variable={item.variable}
                      userDetails={seller}
                      aKey={item.aKey}
                      checkbox={item.checkbox}
                      enabled={seller?.enabled === "true" ? true : false}
                      getSellerData={handleSellerChosen.bind(null, seller)}
                    />
                  ) : (
                    (user?.id === (seller?.seller_id || seller?.id) ||
                      isAdmin) && (
                      <div className="d-flex align-items-center">
                        <h6>
                          <span>{translate("Password")}: </span>***********
                        </h6>
                        <p
                          onClick={() => setChangePasswordModal(true)}
                          style={{
                            borderBottom: "1px solid tomato",
                            cursor: "pointer",
                            color: "tomato",
                            marginLeft: "16px",
                          }}
                        >
                          {translate("Change Password")}
                        </p>
                      </div>
                    )
                  )
                ) : item.aKey === "referalCode" ? (
                  <Toolbar
                    title="Copy the code"
                    style={{ minHeight: "auto", padding: "0" }}
                  >
                    <h6
                      onClick={() => {
                        handleCopyClick(
                          `${document.location.origin}/NumberVerification?ref=${seller?.commission_code}`
                        );
                      }}
                    >
                      <span>{translate("Referal Code")}:</span>
                      <span style={{ color: "lightblue", cursor: "pointer" }}>
                        {seller?.commission_code}
                      </span>

                      <CopyAllIcon
                        style={{
                          color: "lightblue",
                          cursor: "pointer",
                          marginLeft: "16px",
                        }}
                      />
                      {isCopied && (
                        <span style={{ marginLeft: "16px" }}>
                          {translate("Copied!")}
                        </span>
                      )}
                      <ShareIcon
                        style={{
                          color: "lightblue",
                          cursor: "pointer",
                          marginLeft: "16px",
                        }}
                        onClick={() => setShareModal(true)}
                      />
                    </h6>
                  </Toolbar>
                ) : (
                  <ChangeProfile
                    desc={item.desc}
                    variable={item.variable}
                    userDetails={seller}
                    aKey={item.aKey}
                    checkbox={item.checkbox}
                    disabled={!isAdmin && item.disabled}
                    special={item.special}
                    enabled={seller?.enabled === "true" ? true : false}
                    getSellerData={handleSellerChosen.bind(null, seller)}
                  />
                )
              ) : null
            )}
          </div>
        </div>
        {seller && <ContactOptions sellerId={seller.id} />}
      </div>
      {/* <UpdateProfileModal
        updateModal={updateModal}
        setUpdateModal={setUpdateModal}
        handleUpdateProfile={handleUpdateProfile}
        setSeller={setSeller}
        seller={seller}
        setUpdateData={setUpdateData}
        updateLoading={updateLoading}
      /> */}
      <Modal
        visible={changePasswordModal}
        onClose={() => setChangePasswordModal(false)}
        width={"50vw"}
        title={"Change Password"}
      >
        <form className="phone-form" onSubmit={handlePasswordUpdate}>
          <TextFieldGroup
            required={true}
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            label="Current Password"
            placeholder="Current Password"
          />
          <TextFieldGroup
            required={true}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="New Password"
            placeholder="New Password"
          />
          <TextFieldGroup
            required={true}
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            label="Confirm New Password"
            placeholder="Confirm New Password"
          />
          {passwordError && (
            <small className="form-text text-left" style={{ color: "red" }}>
              {passwordError}
            </small>
          )}
          <div
            className="d-flex align-items-center justify-content-end mt-4"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger "
              onClick={() => setChangePasswordModal(false)}
              style={{ width: 90 }}
            >
              {translate("Cancel")}
            </button>
            <button
              type="submit"
              className="btn btn-primary mx-3"
              style={{ width: 110 }}
            >
              <div>
                {updateLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  intl.formatMessage({ id: "Update" })
                )}
              </div>
            </button>
          </div>
        </form>
      </Modal>
      {isAdmin && (
        <UsersCompanies
          discountData={discountData}
          setDiscountData={setDiscountData}
          seller={seller}
          getDiscounts={getDiscounts}
          updateData={updateData}
        />
      )}
      <ShareModal
        shareModal={shareModal}
        setShareModal={setShareModal}
        referalCode={`${document.location.origin}/NumberVerification?ref=${seller?.commission_code}`}
      />
      {userDetailsLoading && <Spinner />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
  userDetailsLoading: state.auth.userDetailsLoading,
  sellers: state.credits.sellers,
  user: state.auth.user,
  userData: state.auth.userData,
  anotherUser: state.auth.anotherUser,
  isDarktheme: state.darkTheme.isDarktheme,
  discounts: state.discounts.discounts,
});

export default connect(mapStateToProps, {
  getSellerData,
  updateProfile,
  getSellers,
  getAnotherSellerData,
  getDiscounts,
})(Profile);
