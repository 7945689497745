import React, { useRef, useState } from "react";
// import PropTypes from "prop-types";
import NavigateBackHeader from "../../../../core/core-components/NavigateBackHeader/NavigateBackHeader";
import { Grid, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useIntl } from "react-intl";
import { parseObjToQueryParams } from "../../../../shared/utils/utils.js";
import ApiRequest from "../../../../actions/ApiRequest.js";
import Toast from "../../../common/Toast.js";
import { makeStyles } from "@mui/styles";
import { CURRENCIES_SYMBOLS } from "../../../../shared/constants/constants.js";
import ConformationModal from "../../../../core/core-components/ConformationModal/ConformationModal";
import { useHistory } from "react-router-dom";
import TextFieldGroup from "../../../common/TextFieldGroup";
import Notiflix from "notiflix";

const propTypes = {};

const defaultProps = {};

const IsraeliFineInquiryStyle = () => ({
  inputLabel: {
    "& label": {
      fontWeight: "400 !important",
      fontSize: "1rem",
    },
  },
  inputStyle: {
    "& .input-field": {
      fontWeight: "400 !important",
      fontSize: "1rem !important",
    },
  },
  gridRoot: {
    marginTop: 16,
    "& > div": {
      width: "40%",
    },
  },
});

const useStyle = makeStyles(IsraeliFineInquiryStyle);

export default function PayIsraeliFines(props) {
  const intl = useIntl();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConformation, setShowConformation] = useState(false);
  const formData = useRef({});

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const classes = useStyle(props);

  async function onSubmit(data) {
    setIsSubmitting(true);
    try {
      const queryURL = `pay_fine?${parseObjToQueryParams(data)}`;
      await ApiRequest.post(queryURL);
      setShowConformation(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "after_pay_israel_fine_msg",
        }),
        icon: "success",
      });
      reset({ email: "", amount: "", mobile_number: "", fine_number: "" });
    } catch (e) {
      console.log("ERROR IsraeliFineInquiry", e);
      Notiflix.Notify.failure("Something Went Wrong! - " +e?.message);
    }
    setIsSubmitting(false);
  }

  const renderConformationBody = () => (
    <Typography>
      {`${intl
        .formatMessage({ id: "we_will_add_fee_msg" })
        .replace("{number}", 70 + CURRENCIES_SYMBOLS.ils)}!, ${intl.formatMessage({ id: "are_you_sure" })}?`}
    </Typography>
  );


  const InputField = (pr) => (
    <TextFieldGroup
      labelProps={{
        className: classes.inputLabel,
      }}
      className={classes.inputStyle}
      placeholder={pr.label}
      disable={isSubmitting}
      error={
        !!errors[pr?.name]
          ? intl.formatMessage({
              id: "wrong_input",
            }) + "!"
          : ""
      }
      {...pr}
    />
  );


  return (
    <React.Fragment>
      <ConformationModal
        conformBtnProps={{ loading: isSubmitting, onClick: () => onSubmit(formData.current) }}
        title={intl.formatMessage({ id: "confirmation" })}
        body={renderConformationBody()}
        open={showConformation}
        onCancelClick={() => setShowConformation(false)}
      />
      <NavigateBackHeader
        onBackClick={() => {
          history.push("/bills/israel_fines");
        }}
        title={"pay_fine"}
      />
      <form
        id={"pay_israeli_fine_form"}
        onSubmit={handleSubmit((data) => {
          formData.current = data;
          setShowConformation(true);
        })}
      >
        <Grid
          className={classes.gridRoot}
          container
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Controller
              name="fine_number"
              rules={{ required: true, pattern: /^\d+$/ }}
              control={control}
              render={({ field }) => (
                <InputField
                  label={
                    "*" +
                    intl.formatMessage({
                      id: "fine_number",
                    })
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item paddingTop={"0px !important"}>
            <Typography fontWeight="bold" fontSize={"small"} color={"error"}>
              *
              {intl.formatMessage({ id: "we_will_add_fee_msg" }).replace("{number}", 70 + CURRENCIES_SYMBOLS.ils) + "!"}
            </Typography>
          </Grid>
          <Grid item>
            <Controller
              name="amount"
              rules={{ required: true, pattern: /^\d+$/ }}
              control={control}
              render={({ field }) => (
                <InputField
                  label={
                    "*" +
                    intl.formatMessage({
                      id: "Amount",
                    })
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="mobile_number"
              rules={{ required: true, pattern: /^\d+$/ }}
              control={control}
              render={({ field }) => (
                <InputField
                  label={
                    "*" +
                    intl.formatMessage({
                      id: "Mobile Number",
                    })
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputField
                  type={"email"}
                  label={intl.formatMessage({
                    id: "Email",
                  })}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item container justifyContent={"center"}>
            <LoadingButton fullWidth loading={isSubmitting} type={"submit"} variant={"contained"}>
              {intl.formatMessage({ id: "pay_fine" })}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

PayIsraeliFines.propTypes = propTypes;
PayIsraeliFines.defaultProps = defaultProps;
