import React from "react";
import { Link } from "react-router-dom";

import SettingsIcon from "@mui/icons-material/Settings";
import translate from "../../i18n/translate";
import logo from "../../assests/images/logo/black-logo.svg";
import whiteLogo from "../../assests/images/logo/white-logo.svg";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const settings = {
  id: 1,
  title: translate("Settings"),
  linkTo: null,
  icon: <SettingsIcon color="info" />,
  content: [
    {
      id: 1,
      title: translate("AddBalance A Company"),
      linkTo: "/admin/Companies",
    },
    { id: 2, title: translate("jawwal"), linkTo: "/admin/Jawwal" },
    { id: 3, title: translate("ooredoo"), linkTo: "/admin/Ooredoo" },
    { id: 12, title: translate("Paltel"), linkTo: "/admin/paltel" },
    {
      id: 13,
      title: translate("JawwalInvoice"),
      linkTo: "/admin/jawwalInvoiceSettings",
    },
    {
      id: 15,
      title: translate("Mada"),
      linkTo: "/admin/Mada",
    },
    { id: 16, title: translate("Coolnet"), linkTo: "/admin/Coolnet" },
    { id: 14, title: translate("gemzo"), linkTo: "/admin/gemzo" },
    { id: 17, title: translate("arabBankCaptcha"), linkTo: "/admin/arabBank" },
    {
      id: 19,
      title: translate("Global Updates"),
      linkTo: "/admin/allCompanies",
    },
    { id: 18, title: translate("deleteTrans"), linkTo: "/admin/deleteTrans" },
    { id: 4, title: translate("cellcom"), linkTo: "/admin/Cellcom" },
    { id: 41, title: translate("azy"), linkTo: "/admin/Azy" },
    { id: 42, title: translate("hot"), linkTo: "/admin/Hot" },
    { id: 43, title: translate("golan"), linkTo: "/admin/Golan" },
    { id: 44, title: translate("Mobile012"), linkTo: "/admin/mobile012" },
    { id: 45, title: translate("partner"), linkTo: "/admin/Partner" },
    { id: 46, title: translate("pelephone"), linkTo: "/admin/Pelephone" },
    {
      id: 5,
      title: translate("system"),
      linkTo: "/admin/system",
    },
    { id: 6, title: translate("peletalk"), linkTo: "/admin/Peletalk" },
    {
      id: 7,
      title: translate("Web Notifications"),
      linkTo: "/admin/Company/notifications",
    },
    {
      id: 8,
      title: translate("Massaging System"),
      linkTo: "/admin/Massaging",
    },
    {
      id: 9,
      title: translate("Advertisement"),
      linkTo: "/admin/Advertisement",
    },
    {
      id: 10,
      title: translate("Hypermedia"),
      linkTo: "/admin/Hypermedia",
    },
    // {
    //   id: 11,
    //   title: translate("GolanTelecom"),
    //   linkTo: "/admin/GolanTelecom",
    // },
  ],
};

const AdminSideBar = ({ isDarktheme }) => {
  const history = useHistory();

  return (
    <div className={!isDarktheme ? "admin-sidebar2" : "dark-admin-sidebar2"}>
      <div
        className={
          !isDarktheme ? "admin-sidebar2-Logo" : "dark-admin-sidebar2-Logo"
        }
      >
        <a className="admin-logo" onClick={() => history.push("/")} href="#0">
          <img
            width="auto"
            height="40"
            alt=""
            src={!isDarktheme ? logo : whiteLogo}
            className="d-inline-block align-top "
          />
        </a>
      </div>
      <div style={{ width: "290px", paddingLeft: 16, paddingRight: 16 }}>
        {settings.content.map((setting, i) => (
          <Link to={setting.linkTo} key={i}>
            <p style={{ color: "white", margin: "15px 0" }}>{setting.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps, null)(AdminSideBar);
