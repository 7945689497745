import { CHANGE_THEME } from "./types"

export const DarkTheme = (boolean) => {
    return {
        type: CHANGE_THEME,
        payload: boolean
    }
}


