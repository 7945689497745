export const HOVER_STYLE = {
  color: " #212529 !important",
  "background-color": "#fff !important",
  "border-color": "#86b7fe !important",
  outline: "0 !important",
  "box-shadow": "0 0 0 .25rem rgba(13,110,253,.25) !important",
};

export function textEllipsis(maxWidth) {
  return {
    overflow: "hidden",
    whiteSpace: "nowrap",
    direction: "rtl",
    textOverflow: "ellipsis",
    maxWidth,
  };
}

export const SYSTEM_COLORS = { gray: "#ced4da", red: "#f93154", primaryBlue: "#1976d2" };
