import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import TransactionTable from "../common/TransactionTalbe/TransactionTable";
import CompaniesSkeleton from "./CompaniesSkeleton";

import { getAllCompanies } from "../../actions/companiesAction";
import { getLastTransaction } from "../../actions/reportsAction";
import "./home.css";
import ProvidersCards from "../../core/containers/ProvidersCards/ProvidersCards";
import { parseBool } from "../../shared/utils/utils.js";
import { useMount } from "shared/hooks/ppHooks";
import { useLocation } from "react-router-dom";
import PinEntryModal from "components/common/PinEntry";
import { cleanStorage } from "shared/utils/cleanStorage";

const Home = ({
  user,
  getAllCompanies,
  allCompanies,
  loading,
  getLastTransaction,
}) => {
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-2 col-md-4 col-sm-6 card-sm"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hideIsraelCompany, setHideIsraelCompany] = useState(
    localStorage.getItem("hide companies")
  );
  const location = useLocation();
  const { random } = location.state || {};
  const showIsraeliCarriers = hideIsraelCompany === "true";

  useEffect(() => {
    const showIsraeliCompanies = async () => {
      if (localStorage.getItem("hide companies") === "false") {
        setHideIsraelCompany("true");
        localStorage.setItem("hide companies", "true");
        getLastTransaction("0", 5);
        return;
      }
      if (localStorage.getItem("hide companies") === "true") {
        setIsDialogOpen(true);
      }
    };
    if (random) {
      showIsraeliCompanies();
    }
  }, [random]);

  useEffect(() => {
    document.title = "Home | Phone Play";
    refreshColumnStyle();
    cleanStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    if (!allCompanies || !allCompanies?.length) {
      getAllCompanies();
    }
  });

  useEffect(() => {
    let element = document.body;
    let intervalId;
    let counter = 0;
    setIsLoading(true);

    const getLastTransactionEvery10Seconds = async () => {
      const transaction = await getLastTransaction("0", 5);

      // console.log("transaction", transaction);
      counter++;
      const isPending = transaction?.some(
        (t) =>
          t.status.toLowerCase() === "proccessing" ||
          t.status.toLowerCase() === "pending"
      );
      if (counter === 1 && isPending) {
        setTimeout(getLastTransactionEvery10Seconds, 15000);
      } else if (counter === 2 && isPending) {
        setTimeout(getLastTransactionEvery10Seconds, 30000);
      } else {
        clearInterval(intervalId);
      }
    };
    setTimeout(getLastTransactionEvery10Seconds, 10000);
    setIsLoading(false);
    setTimeout(() => {
      window.onkeydown = async (e) => {
        if (e.code === "")
          if (!scrollbarVisible(element)) {
            document.body.classList.add("padding-class");
          } else {
            document.body.classList.remove("padding-class");
          }

        if (
          ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
          localStorage.getItem("hide companies") === "false"
        ) {
          e.returnValue = false;
          setHideIsraelCompany("true");
          localStorage.setItem("hide companies", "true");
          getLastTransaction("0", 5);
          return;
        }
        if (
          ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
          localStorage.getItem("hide companies") === "true"
        ) {
          setIsDialogOpen(true);
        }
      };
    }, 100);

    // Start the interval to call getLastTransaction every 10 seconds
    // intervalId = setInterval(getLastTransactionEvery10Seconds, 10000);
    // intervalId2 = setInterval(getLastTransactionEvery2Seconds, 2000);
    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let element = document.body;
  //   setTimeout(() => {
  //     window.onkeydown = async (e) => {
  //       if (e.code === "")
  //         if (!scrollbarVisible(element)) {
  //           document.body.classList.add("padding-class");
  //         } else {
  //           document.body.classList.remove("padding-class");
  //         }

  //       if (
  //         ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
  //         localStorage.getItem("hide companies") === "false"
  //       ) {
  //         e.returnValue = false;
  //         setHideIsraelCompany("true");
  //         localStorage.setItem("hide companies", "true");
  //         getLastTransaction("0", 5);
  //         return;
  //       }
  //       if (
  //         ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
  //         localStorage.getItem("hide companies") === "true"
  //       ) {
  //         const { data } = await ApiRequest.post(`allow_f9`);

  //         if (data.reason === "true") {
  //           setHideIsraelCompany("false");
  //           localStorage.setItem("hide companies", "false");
  //           getLastTransaction("0", 5);
  //         }
  //       }
  //     };
  //   }, 100);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function scrollbarVisible(element) {
    return element.scrollHeight > element.clientHeight;
  }

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 card-md");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 card-lg");
        break;
      case "default":
      case "column6":
        setColumnStyle("col-lg-2 col-md-4 col-sm-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 card-md");
    }
  };

  return (
    <>
      {/* <NewProduct /> */}
      <div>
        <div className="card card-home">
          {/* <div className="card img-back" style={
            sessionStorage.getItem("main_picture") ? { backgroundImage: `url("${sessionStorage.getItem("main_picture")}")` } : {}
          }>
            <h1 className="header-text">{translate("Companies")}</h1>
          </div> */}
        </div>
        <div className="">
          <div className="row">
            {loading &&
              !allCompanies.length > 0 &&
              !showIsraeliCarriers &&
              [...Array(9)].map((e, i) => (
                <CompaniesSkeleton
                  height={185}
                  columnStyle={columnStyle}
                  key={i}
                />
              ))}
            {loading &&
              !allCompanies.length > 0 &&
              showIsraeliCarriers &&
              [...Array(2)].map((e, i) => (
                <CompaniesSkeleton
                  height={185}
                  columnStyle={columnStyle}
                  key={i}
                />
              ))}

            <ProvidersCards
              columnStyle={columnStyle}
              md={3}
              hideInformationIcon={false}
              hideIsraeliCarriers={parseBool(hideIsraelCompany)}
              xs={4}
            />

            <TransactionTable isLoading={isLoading} />
          </div>
        </div>
        <PinEntryModal
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          setHideIsraelCompany={setHideIsraelCompany}
          getLastTransaction={getLastTransaction}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({
  auth: { user },
  companies: { allCompanies, loading },
}) => ({
  user,
  allCompanies,
  loading,
});

export default connect(mapStateToProps, {
  getAllCompanies,
  getLastTransaction,
})(Home);
