import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CompanySettings from "../Companies/CompanySettings";
import Reports from '../Reports/Reports';
import UpdateHyperMedia from './UpdateHyperMedia';
import translate from "../../../i18n/translate";
import { connect } from 'react-redux';

const HyperMediaSettingsContainer = ({ isDarktheme }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (

        <Box >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className={!isDarktheme ? "" : "text-light"} label={translate("Hypermedia Settings")} {...a11yProps(0)} />
                    <Tab className={!isDarktheme ? "" : "text-light"} label={translate("Hypermedia Reports")}  {...a11yProps(1)} />
                    <Tab className={!isDarktheme ? "" : "text-light"} label={translate("Hypermedia Balance")}  {...a11yProps(2)} />
                    <Tab className={!isDarktheme ? "" : "text-light"} label={translate("Update Hyper Media")}  {...a11yProps(3)} />
                </Tabs>
            </Box>

            {value === 0 && <CompanySettings companyId="Hypermedia" />}
            {value === 1 && <Reports reportNameParam="HMP" />}
            {value === 2 && <Reports reportNameParam="HMP_balance_log" />}
            {value === 3 && <UpdateHyperMedia />}


        </Box>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
    isDarktheme

});
export default connect(mapStateToProps, null)(HyperMediaSettingsContainer)