import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { LOCALES_COUNTRIES } from "../../../../i18n";
import { useHistory } from 'react-router'
import ApiRequest from '../../../../actions/ApiRequest'

const langs = {
  ar: "PS",
  en: "US",
  he: "IL"
};

const LanguageChooser = () => {
  const [selected, setSelected] = useState(langs[localStorage.langCity] || LOCALES_COUNTRIES[process.env.REACT_APP_DEFAULT_LANG]);
  const history = useHistory()

  useEffect(() => {
    if (localStorage.langCity === "en") {
      setSelected("US");
    } else if (localStorage.langCity === "he") {
      setSelected("IL");
    }
  }, []);

  const onSelectLang = async (code) => {
    if (code === "US") {
      localStorage.langCity = "en";
    } else if (code === "PS") {
      localStorage.langCity = "ar";
    } else {
      localStorage.langCity = "he";
    }
    // Call cdl API
    try {
      await ApiRequest.post("cdl")
    } catch (error) {}
    
    history.go(0);
  };

  return (
    <ReactFlagsSelect
      color={"#fff"}
      countries={["PS", "US", "IL"]}
      selected={selected}
      customLabels={{ US: "English", PS: "Arabic", IL: "Hebrew" }}
      onSelect={(code) => onSelectLang(code)}
      selectedSize={14}
      optionsSize={14}
      showLabal={true}
      className="custom-lang"
    />
  );
};
export default LanguageChooser;