import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";
import { formattedObjects } from "./formatData";
import { Grid } from "@thumbtack/thumbprint-react";
import EditAccount from "../EditAccount";

export default function EditCard() {
  const location = useLocation();
  const { data, carrier } = location.state || {};
  const formatData = formattedObjects(data);

  console.log("console.log", data, carrier);
  return (
    <Box
      sx={{
        margin: "20px auto",
        padding: "30px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        color: "#000",
        width: "100%",
      }}
    >
      <h1>{carrier} Edit Card</h1>

      <Box
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
        }}
      >
        {formatData.map((item) => (
          <EditAccount
            desc={item.desc}
            variable={item.variable}
            checkbox={item.checkbox}
            aKey={item.aKey}
          />
        ))}
      </Box>
    </Box>
  );
}
