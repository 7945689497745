import { HOVER_STYLE } from "shared/constants/stylesConstants";

const reportStyles = (theme) => ({
  gridContainer: {
    "& .report-dropdown-container .Dropdown-control": {
      paddingLeft: "16px !important",
    },
    "& .deleteicon": {
      width: "100%",
    },
    "#topUpDropDown": {
      "& > div": {
        "&:hover": HOVER_STYLE,
      },
    },
    "& .report-dropdown": {
      "& > div": {
        "&:hover": HOVER_STYLE,
      },
    },
    "& div": {
      borderRadius: 4,
      "& ::placeholder": {
        fontSize: "12px !important",
      },
    },
    "& .form-control, .report-dropdown": {
      "&:focus": HOVER_STYLE,
    },
    "& input": {
      borderRadius: "4px !important",
      fontSize: "12px !important",
      width: "100%",
      paddingLeft: "16px !important",
    },
  },
});
export default reportStyles;
