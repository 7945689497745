import React, { useState, useMemo } from "react";
import { useMutation } from "react-query";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";

import { Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import PageTitle from "../common/PageTitle";
import TextFieldGroup from "components/common/TextFieldGroup";
import PayBillsOoredooModal from "./PayBillsOoredooModal";

import {
  getOoredooBillsApiCalled,
  payOoredooBillApiCalled,
} from "shared/endPoints/apiCallers";
import translate from "i18n/translate";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import EditableTextFieldForm from "./shared/InputBills";
import DataTable from "./shared/DataTable";
import GeneralPayModal from "./shared/GeneralPayModal";
import { calcTotalAmount } from "./shared/calcTotalAmount";

const OoredooBills = () => {
  const [number, setNumber] = useState("");
  const [identity, setIdentity] = useState("");
  const [selectedBill, setSelectedBill] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [ooredooPills, setOoredooPills] = useState([]);
  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    const { state } = history.location;
    if (state?.number) {
      setNumber(state.number);
      setIdentity(state.identity);
      getOoredooBills({ id: state.identity, number: state.number });
    }
  }, []);

  const {
    isLoading,
    mutate: getOoredooBills,
    data: pills,
    reset,
  } = useMutation(getOoredooBillsApiCalled, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        const errorReason = res?.reason ?? "Something went wrong";
        setErrorMessage(errorReason);
        return;
      }
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });

  useEffect(() => {
    if (pills?.length > 0) {
      setOoredooPills(
        pills.reverse().map((pill) => ({
          ...pill,
          date: pill.date1,
        }))
      );
    }
  }, [pills]);
  const onSubmit = async (value) => {
    setNumber(value);
    setSelectedBill([]);
    setOoredooPills([]);
    getOoredooBills({ id: identity, number: value });
  };

  const clearSelections = () => {
    setSelectedBill([]);
    reset();
  };
  const totalAmount = parseFloat(calcTotalAmount(selectedBill).toFixed(2));

  return (
    <div>
      <PageTitle title={translate("Ooredoo Bills")} backPage="/pay_bills">
        <img
          src="https://logowik.com/content/uploads/images/ooredoo3309.logowik.com.webp"
          alt="Ooredoo"
          className="Ooredoo-image"
          style={{ width: 200, marginTop: 20 }}
        />
        <EditableTextFieldForm
          initialValue={number}
          label={translate("Phone number")}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />

        {ooredooPills?.length > 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <p>
                {translate("Total Bills Amount")}:{" "}
                <span className="text-info">
                  {totalAmount} {translate("ILS")}
                </span>
              </p>
              <GeneralPayModal
                selectedBill={selectedBill}
                number={number}
                isDisabled={!totalAmount || isLoading}
                clearSelections={clearSelections}
                totalAmount={totalAmount}
                apiCall={payOoredooBillApiCalled}
              />
            </div>
            <DataTable
              rows={ooredooPills}
              isLoading={isLoading}
              onRowSelect={setSelectedBill}
              selectedRows={selectedBill}
            />
          </>
        )}
        {pills?.length === 0 && <h5>No Results Found</h5>}
        {errorMessage && <h5 className="text-danger">{errorMessage}</h5>}
      </PageTitle>
    </div>
  );
};

export default OoredooBills;
