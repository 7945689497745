import React from "react";
import PropTypes from "prop-types";
import Pagination from "../../../components/common/Pagination";
import { useSelector } from "react-redux";
import translate from "../../../i18n/translate";
import ExportButton from "components/common/ExportButton";

const propTypes = {
  showEmptyView: PropTypes.bool,
  customEmptyView: PropTypes.any,
  headers: PropTypes.array,
  rows: PropTypes.array,
  className: PropTypes.string,
  title: PropTypes.any,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleOffsetValue: PropTypes.func,
  handleSortChange: PropTypes.func,
  sort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sideHeader: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

const defaultProps = {};

export default function PPTable(props) {
  const {
    headers,
    rows,
    className,
    title,
    handleOffsetValue,
    handleSortChange,
    count,
    sort = { value: "5", label: 5 },
    page,
    sideHeader,
    showEmptyView,
    customEmptyView,
  } = props;
  const isDarkTheme = useSelector((state) => state.darkTheme.isDarktheme);

  function renderTableHead() {
    return (
      <thead>
        <tr>
          {headers.map((h, index) => (
            <th key={h.key || index} scope="col" {...h.props}>
              {h.children}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  function renderTableBody() {
    return (
      <tbody>
        {rows.map((row, index) => (
          <tr key={row.key || index} {...row.props}>
            {row.data.map((td, i) => (
              <td key={td.key || i} {...td.props}>
                {td.children}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <div
      className={!isDarkTheme ? "row table-wrapper" : "row dark-table-wrapper"}
    >
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "flex-start" }}
      >
        <div className="table-title">
          <h5>{title}</h5>
        </div>
        <div
          className=""
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <ExportButton reports={rows} />
          {sideHeader}
        </div>
      </div>
      <Pagination
        count={count}
        page={page}
        handleOffsetValue={handleOffsetValue}
        handleSortChange={handleSortChange}
        sort={sort}
      />
      <table className={className ? className : "table table-striped"}>
        {renderTableHead()}
        {renderTableBody()}
      </table>
      {showEmptyView && !customEmptyView && !rows?.length ? (
        <div className="no-data-to-show">{translate("No data to show")}</div>
      ) : null}
    </div>
  );
}

PPTable.propTypes = propTypes;
PPTable.defaultProps = defaultProps;
