import {
    GET_GAMES_DATA, GET_GAMES_DATA_LOADING, GET_GAME_DATA,
    GET_FREE_FIRE_PLAYER_DATA, GAMES_ERROR, PLAYER_MODAL,
    GET_GAME_DETAILS_LOADING, SUCCESSFULL_MODAL, GAME_SUCCESSFULL, GAME_VALIDATION_LOADING, GAME_SUBMIT_LOADING, GAME_VALIDATION_MODAL, GAME_VALIDATION_DATA
} from "../actions/types";

const initialState = {
    loading: false,
    gameBundleLoading: false,
    gamesArray: [],
    gameBundlesData: null,
    freeFirePlayerData: null,
    gameSuccessfull: null,
    errorMessage: null,
    validationModalOpen: false,
    successfullModalOpen: false,
    // free fire
    freeFireValidationLoading: false,
    freeFireTopUpLoading: false,
    freeFireModalOpen: false,
    freeFireSuccessModalOpen: false,
    freeFireValidatedData: {},
    freeFireTopUpData: {},

    // Mobile Legends
    mobileLegendsValidationLoading: false,
    mobileLegendsTopUpLoading: false,
    mobileLegendsModalOpen: false,
    mobileLegendsValidatedData: {},
    mobileLegendsTopUpData: {},

    // razor
    razorValidationLoading: false,
    razorTopUpLoading: false,
    razorModalOpen: false,
    razorModalValidatedData: {},
    razorTopUpData: {},

};

const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GAMES_DATA_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_GAME_DETAILS_LOADING:
            return {
                ...state,
                gameBundleLoading: action.payload,
            };
        case GET_GAMES_DATA:
            return {
                ...state,
                gamesArray: action.payload,
            };
        case GET_GAME_DATA:
            return {
                ...state,
                gameBundlesData: action.payload,
            };
        case GET_FREE_FIRE_PLAYER_DATA:
            return {
                ...state,
                freeFirePlayerData: action.payload,
            };
        case PLAYER_MODAL:
            return {
                ...state,
                validationModalOpen: action.payload,
            };
        case SUCCESSFULL_MODAL:
            return {
                ...state,
                successfullModalOpen: action.payload,
            };
        case GAME_SUCCESSFULL:
            return {
                ...state,
                gameSuccessfull: action.payload,
            };
        case GAMES_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case GAME_SUBMIT_LOADING:
            const { topUpLoading } = action.payload
            return {
                ...state,
                [topUpLoading]: action.payload.boolean
            };
        case GAME_VALIDATION_LOADING:
            const { validationLoading } = action.payload
            return {
                ...state,
                [validationLoading]: action.payload.boolean
            };
        case GAME_VALIDATION_MODAL:
            const { validationModalOpen } = action.payload
            return {
                ...state,
                [validationModalOpen]: action.payload.boolean
            };
        case GAME_VALIDATION_DATA:
            const { validationData, data } = action.payload
            return {
                ...state,
                [validationData]: data
            };

        default:
            return state;
    }
}

export default gameReducer
