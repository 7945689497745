import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import classes from "./UploadImage.module.css";
import translate from "i18n/translate";

export default function UploadImage(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imageChanged, setImageChanged] = useState();
  useEffect(() => {
    props.setImage(null);
  }, []);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  const handleFileInput = (event) => {
    const file = event.target.files[0];
    props.setImage(file);
    setSuccess(false);
    setLoading(true);
    const reader = new FileReader();
    setTimeout(() => {
      reader.onloadend = () => {
        setImageChanged(reader.result);
        setLoading(false);
        setSuccess(true);
      };
      reader.readAsDataURL(file);
    }, 1000);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box sx={{ m: 1, position: "relative" }}>
        <Fab
          aria-label="save"
          color="primary"
          sx={buttonSx}
          // onClick={handleButtonClick}
          component="label"
        >
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={handleFileInput}
          />
          {success ? <CheckIcon /> : <PhotoCamera />}
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      {imageChanged && (
        <img src={imageChanged} alt="product" className={classes.image} />
      )}
      {!imageChanged && (
        <div className={classes.fakeImage}>
          {props.message ? props.message : translate("Upload Id Image")}
        </div>
      )}
    </Box>
  );
}
