import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PeletalkSettings from "./PeletalkSettings"
import CompanySettings from "../Companies/CompanySettings";
import translate from "../../../i18n/translate";
import { connect } from 'react-redux';

const PeletalkSettingsContainer = ({ isDarktheme }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (

        <Box >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs className={!isDarktheme ? "PeletalkTabs" : "dark-PeletalkTabs"} value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className={!isDarktheme ? "PeletalkTabsTab" : "dark-PeletalkTabsTab"} label={translate("Update Peletalk")} {...a11yProps(0)} />
                    <Tab className={!isDarktheme ? "PeletalkTabsTab" : "dark-PeletalkTabsTab"} label={translate("Peletalk Settings")} {...a11yProps(1)} />
                </Tabs>
            </Box>
            {
                value === 0
                    ?
                    <PeletalkSettings />
                    :
                    <CompanySettings companyId="Peletalk" />

            }
        </Box>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}

export default connect(mapStateToProps, null)(PeletalkSettingsContainer)