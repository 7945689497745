import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography, CircularProgress } from "@mui/material";
import OperatorSettings from "./OperatorSettings";
import OperatorProducts from "./OperatorProducts";
import ApiRequest from "../../../../actions/ApiRequest";
import Toast from "components/common/Toast";

const OPERATOR_PRODUCTS = "operator products";
const OPERATOR_SETTINGS = "operator settings items";

export default function GetSettings({ carrier, defaultImg, children }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log("carrier", carrier);

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await ApiRequest.post(
          `get_settings?carrier=${carrier}`
        );
        const dataRes = response?.data;
        setData(dataRes);
      } catch (error) {
        Toast.fire({
          title: "Something Went Wrong!",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        margin: "20px auto",
        padding: "30px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        color: "#000",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {carrier.toUpperCase()} Settings
      </Typography>
      {isLoading ? (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          {children}
          <Tabs value={selectedTab} onChange={handleChange} centered>
            <Tab label="Operator Settings" />
            <Tab label="Operator Products" />
          </Tabs>
          <Box sx={{ padding: 2 }}>
            {selectedTab === 0 && (
              <Box>
                {data && (
                  <OperatorSettings
                    data={data[OPERATOR_SETTINGS]}
                    carrier={carrier}
                  />
                )}
              </Box>
            )}
            {selectedTab === 1 && (
              <Box>
                {data && (
                  <OperatorProducts
                    data={data[OPERATOR_PRODUCTS]}
                    carrier={carrier}
                    defaultImg={defaultImg}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
