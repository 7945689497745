import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import translate from "i18n/translate";
import React from "react";
import { useHistory } from "react-router-dom";

export default function OperatorProducts({ data, carrier, defaultImg }) {
  const history = useHistory();
  console.log("OperatorProducts", data, carrier);
  const handleItemClick = (itemId) => {
    history.push(`/admin/editSettings`, {
      data: data.find((item) => item.ProviderCardID === itemId),
      carrier,
    });
  };

  return (
    <Grid
      container
      spacing={4}
      mt={3}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {data?.map((item, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <Box
            onClick={() => handleItemClick(item.ProviderCardID)}
            sx={{
              cursor: "pointer",
              padding: "20px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <img
              src={item.url || defaultImg}
              alt={item.Provider || carrier}
              style={{ height: "120px" }}
            />
            <p>
              {item.Provider
                ? `${item.Provider} = ${item.Amount} ILS`
                : `Settings = ${item.Settings}`}
            </p>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
