import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import LogoutIcon from "@mui/icons-material/Logout";
import NotificationContainer from "../common/NotificationContainer";
import Notifications from "../common/Notification";
import ControlledSwitches from "../common/Checked";
import Modal from "../common/Modal";

import { onMessageListener } from "../../firebase";
import translate from "../../i18n/translate";
import { intl } from "../../i18n/provider";

import { logoutUser } from "./../../actions/userAction";
import { userData } from "../../actions/userAction";
import { DarkTheme } from "../../actions/darkTheme";
import { getNotifications } from "../../actions/notificationActions";

import logo from "../../assests/images/logo/black-logo.svg";
import whiteLogo from "../../assests/images/logo/white-logo.svg";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { AdminPanelSettingsSharp } from "@mui/icons-material";
import { ShowChartOutlined } from "@mui/icons-material";
import "./nav.css";
import { useIsAdmin, useIsRtl } from "../../shared/hooks/ppHooks.js";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Switch,
  Avatar,
  Button,
  Box,
  Container,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import MenuIcon from "@mui/icons-material/Menu";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";
import Collapse from "@mui/material/Collapse";

const Navbar = ({
  isAuthenticated,
  logoutUser,
  userData,
  user,
  isDarktheme,
  DarkTheme,
  getNotifications,
  list,
}) => {
  const history = useHistory();
  const [logoutModal, setLogOutModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const { isAdmin, isReseller } = useIsAdmin();
  const isAdminOrReseller = isAdmin || isReseller;
  const isRtL = useIsRtl();

  const items = [
    { to: "/seller_credit", show: true, label: "seller credits" },
    { to: "/report", show: true, label: "Report" },
    { to: "/add_credits", show: isAdminOrReseller, label: "add credits" },
    // { to: "/messages", show: true, label: "messages" },
    // { to: "/contactus", show: true, label: "Contact Us" },
    { to: "/discounts", show: true, label: "discounts" },
    { to: "/credits", show: isAdminOrReseller, label: "credits" },
    { to: "/seller_debt", show: isAdminOrReseller, label: "Debt" },
    { to: "/payments", show: isAdminOrReseller, label: "payments" },
    { to: "/system_balance", show: isAdmin, label: "system_balance" },
    { to: "/system_support", show: isAdmin, label: "system_support" },
    { to: "/dealers", show: isAdmin, label: "Send Whatsapp" },
  ];

  const showIsraeliCompanies = async () => {
    const random = Math.floor(Math.random() * 1000000000000000000);
    history.push("/", {
      random: random,
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => userData(history), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headerData = [
    {
      id: 1,
      icon: <PermPhoneMsgIcon color="success" />,
      linkTo: "/contactus",
      title: translate("Contact Us"),
      condition: isAuthenticated,
    },
    {
      id: 2,
      icon: <AdminPanelSettingsSharp color="error" />,
      linkTo: "/admin",
      title: translate("Admin Panel"),
      condition: isAdmin,
    },
    {
      id: 3,
      icon: <ListIcon />,
      onClick: () => {
        setShowSubMenu(!showSubMenu);
      },
      title: translate("Report"),
      condition: isAuthenticated,
    },
    {
      id: 4,
      icon: <ShowChartOutlined color="warning" />,
      onClick: showIsraeliCompanies,
      title: "F9",
      condition: true,
    },
    {
      id: 5,
      icon: <LogoutIcon />,
      onClick: () => setLogOutModal(true),
      title: translate("Logout"),
      condition: isAuthenticated,
    },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    history.push(path);
    setDrawerOpen(false);
  };

  const handleChange = (e) => {
    if (isDarktheme) {
      DarkTheme(false);
    } else {
      DarkTheme(true);
    }
  };
  const onLogoutClick = () => {
    logoutUser(history);
  };

  const notificationAudo = () => {
    let audio = document.querySelector(".audio-play");
    audio.currentTime = 0;
    audio.play();
  };

  onMessageListener()
    .then(() => {
      getNotifications();
      notificationAudo();
      setNewNotification(true);
    })
    .catch((err) => console.log("failed: ", err));

  const mobileMenu = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem
          button
          onClick={() => handleNavigation("/Profile")}
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <Avatar
            alt="Remy Sharp"
            src={require("../../assests/images/icons/UserProfile.PNG").default}
            sx={{ width: 30, height: 30 }}
          />
          <ListItemText primary={translate("Profile")} />
        </ListItem>
        <Divider />
        {headerData.map((data) =>
          data.condition ? (
            <React.Fragment key={data.id}>
              <ListItem
                button
                onClick={() =>
                  data.onClick ? data.onClick() : handleNavigation(data.linkTo)
                }
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.title} />
              </ListItem>
              {data.id === 3 && (
                <Collapse in={showSubMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map((item) =>
                      item.show ? (
                        <ListItem
                          key={item.to}
                          button
                          component={Link}
                          to={item.to}
                          sx={{ pl: 4 }}
                        >
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ) : null
                    )}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ) : null
        )}

        {/* <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
          <ControlledSwitches check={isDarktheme} Change={handleChange} />
          <ListItemText primary={translate("Dark Mode")} />
        </Box> */}
      </List>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <nav
        className={
          !isDarktheme
            ? "navbar navbar-expand-lg navbar-light "
            : "navbar navbar-expand-lg dark-navbar-light "
        }
      >
        <Notifications />
        <NotificationContainer
          list={list}
          newNotification={newNotification}
          setNewNotification={setNewNotification}
        />
        {/* <div id='notification-bar'></div> */}

        <Link className="navbar-brand my-0 p-0" to="/">
          <div className="nav-imsg">
            <img
              width="auto"
              height="60"
              alt=""
              src={!isDarktheme ? logo : whiteLogo}
              className="d-inline-block align-top "
            />
          </div>
        </Link>
        {IS_MOBILE_DISPLAY && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* {!IS_MOBILE_DISPLAY && (
            <div
              className="ControlledSwitches left"
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ControlledSwitches check={isDarktheme} Change={handleChange} />
              <p
                style={
                  !isDarktheme
                    ? { paddingTop: "16px" }
                    : { paddingTop: "16px", color: "white" }
                }
              >
                {intl.formatMessage({ id: "Dark Mode" })}
              </p>
            </div>
          )} */}
          <ul
            className="navbar-nav me-auto mb-2 mb-lg-0 dark-nav-item"
            style={{
              display: "flex",
            }}
          >
            {isAuthenticated && !IS_MOBILE_DISPLAY && (
              <li className="nav-item">
                <div id="notification-secti`on">
                  <Button
                    // component="label"
                    // variant="contained"
                    startIcon={
                      <PermPhoneMsgIcon
                        sx={{
                          marginLeft: 1,
                          marginRight: 1,
                          color: "green",
                          fontSize: "1.5rem",
                        }}
                      />
                    }
                    sx={{ color: "#000", fontSize: "1rem" }}
                    onClick={() => history.push("/contactus")}
                  >
                    {translate("Contact Us")}
                  </Button>
                </div>
              </li>
            )}

            {!isAuthenticated && (
              <li className="nav-item">
                <Link className="nav-link active" to="/signup">
                  {translate("Sign Up")}
                </Link>
              </li>
            )}
            {!isAuthenticated && (
              <li className="nav-item">
                <Link className="nav-link active" to="/signin">
                  {translate("Login")}
                </Link>
              </li>
            )}

            {isAuthenticated && !IS_MOBILE_DISPLAY && (
              <li
                className="nav-item"
                onMouseEnter={() => setShowSubMenu(true)}
                onMouseLeave={() => setShowSubMenu(false)}
              >
                <Link
                  to="/report"
                  className="nav-link active"
                  style={{ cursor: "pointer" }}
                >
                  <ListIcon />
                </Link>

                {showSubMenu && (
                  <div className="sublinks-wrapper">
                    {items.reduce((prev, curr) => {
                      if (curr.show) {
                        prev.push(
                          <Link
                            to={curr.to}
                            className="nav-link"
                            style={{ cursor: "pointer" }}
                          >
                            {translate(curr.label)}
                          </Link>
                        );
                      }
                      return prev;
                    }, [])}
                  </div>
                )}
              </li>
            )}

            {isAuthenticated && isAdmin && !IS_MOBILE_DISPLAY && (
              <li className="nav-item position-relative">
                <a
                  onClick={() => history.push("/admin")}
                  href="#0"
                  className="nav-link active"
                  style={{ cursor: "pointer" }}
                >
                  {translate("Admin Panel")}
                </a>
              </li>
            )}
            {isAuthenticated && !IS_MOBILE_DISPLAY && (
              <li className="nav-item">
                <Link
                  to="/Profile"
                  className="nav-link active"
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      require("../../assests/images/icons/UserProfile.PNG")
                        .default
                    }
                    onClick={() => history.push("/Profile")}
                    href="#0"
                  />
                </Link>
              </li>
            )}

            {isAuthenticated && !IS_MOBILE_DISPLAY && (
              <li className="nav-item">
                <LogoutIcon
                  onClick={() => setLogOutModal(true)}
                  style={
                    !isDarktheme
                      ? { cursor: "pointer", margin: "0 16px" }
                      : {
                          cursor: "pointer",
                          margin: "0 16px",
                          color: "white",
                        }
                  }
                />
              </li>
            )}
          </ul>
        </div>

        <Modal
          visible={logoutModal}
          onClose={() => setLogOutModal(false)}
          title={"logout"}
          width={"30vw"}
        >
          <div className="fire-free-modal mt-4">
            <h4>{intl.formatMessage({ id: "log out message" })}</h4>
            <div className="btn-section">
              <button
                style={{ margin: "0 16px" }}
                className="btn btn-primary"
                onClick={onLogoutClick}
              >
                {intl.formatMessage({ id: "logout" })}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setLogOutModal(false)}
              >
                {intl.formatMessage({ id: "Close" })}
              </button>
            </div>
          </div>
        </Modal>
        <audio className="audio-play" src="./audios/pristine-609.mp3" />
      </nav>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {mobileMenu}
      </Drawer>
    </Container>
  );
};
const mapStateToProps = ({
  auth: { userData, isAuthenticated },
  darkTheme: { isDarktheme },
  notification: { list },
}) => ({
  isAuthenticated,
  user: userData,
  isDarktheme,
  list,
});
export default connect(mapStateToProps, {
  logoutUser,
  userData,
  DarkTheme,
  getNotifications,
})(Navbar);
