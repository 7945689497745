import React, { useState } from 'react'
import Notiflix from "notiflix";

import Button from '../../common/Button'

import ApiRequest from '../../../actions/ApiRequest'
import translate from "../../../i18n/translate";

const UpdateHyperMedia = () => {
    const [loading, setLoading] = useState(false)

    const updateHyperMedia = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {
            await ApiRequest.post(`HM_update_ports`, {}, { timeout: 3000000 })
            setLoading(false)
            Notiflix.Report.success(`HyperMedia has been updated !`, '', 'Close')
        } catch (err) {
            Notiflix.Report.failure(`Some Thing Went Wrong !`, '', 'Close')
            setLoading(false)
        }
    }

    return (
        <div>
            <form className='contact-us mt-4' onSubmit={updateHyperMedia}>
                <Button
                    type='primary'
                    style={{ width: '100%' }}
                    className='mt-4'
                    title='Update'
                    formType='submit'
                    loading={loading}
                />
            </form>
        </div>
    )
}

export default UpdateHyperMedia
