import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import translate from "../../i18n/translate";
import { connect } from "react-redux";

// const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

function HorizontalLinearStepper({
  steps,
  activeStep,
  setActiveStep,
  isDarktheme,
}) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stepper
        className={!isDarktheme ? "stipper" : "dark-stipper"}
        activeStep={activeStep}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index.toString()}>
              <StepLabel>
                <label style={{ paddingInline: 4 }}>{translate(label)}</label>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    isDarktheme: state.darkTheme.isDarktheme,
  };
};
export default connect(mapStateToProps, null)(HorizontalLinearStepper);
