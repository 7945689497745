import React from "react";
// import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PPTextFieldStyle from "./PPTextFieldStyle";
import classNames from "classnames";
import { useIsRtl } from "../../../shared/hooks/ppHooks.js";

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(PPTextFieldStyle);
export default function PPTextField(props) {
  const classes = useStyle(props);
  const isRtl = useIsRtl();

  return (
    <TextField
      className={classNames(
        props.className,
        {
          [classes.textBold]: props.textBold,
          [classes.rtl]: isRtl,
          [classes.disabledStyle]: props.showDisabledStyle && props.disabled,
        },
        classes.inputField
      )}
      variant="outlined"
      size="small"
      {...props}
    />
  );
}

PPTextField.propTypes = propTypes;
PPTextField.defaultProps = defaultProps;
