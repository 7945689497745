import { ONE_DAY_IN_MSEC } from 'shared/constants/constants';

const otherUtils = require('./uutils.js');

export const parseObjToQueryParams = otherUtils.parseObjToQueryParams;
export const parseQueryToObjParams = otherUtils.parseQueryToObjParams;

export function conditionalReturn(
     condition = false,
     component,
     other = null
) {
     return condition ? component : other;
}

export function isNullOrUndefined(val) {
     return val === null || val === undefined;
}

export function parseBool(val) {
     return val === 'true' || val === true;
}

export function isPast24Hours(data = new Date()) {
     return new Date().getTime() - data.getTime() > ONE_DAY_IN_MSEC;
}

export function isPast48Hours(data = new Date()) {
     return (
          new Date().getTime() - data.getTime() > ONE_DAY_IN_MSEC * 2
     );
}
