import translate from "i18n/translate";
import React from "react";
import { connect } from "react-redux";

const MagneticCard = ({ isDarktheme }) => {
  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("magneticCardTitle")}</h1>
        </div>
      </div>
      <h2
        style={{
          textAlign: "center",
          color: isDarktheme ? "white" : "black",
        }}
      >
        {translate("comingSoon")}
      </h2>
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(MagneticCard);
