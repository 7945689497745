import React, { useState } from "react";
import Notiflix from "notiflix";

import Button from "../../common/Button";
import translate from "../../../i18n/translate";
import Checkbox from "@mui/material/Checkbox";

import ApiRequest from "../../../actions/ApiRequest";
import { connect, useDispatch } from "react-redux";
import { logoutUser } from "actions/userAction";

const JawwalUpdateRoaming = ({ isDarktheme }) => {
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const dispatch = useDispatch();

  const updateRoaming = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await ApiRequest.post(
        `URB?force_update=${forceUpdate}`,
        {},
        { timeout: 3000000 }
      );
      if (data?.reason?.includes("token expired")) {
        dispatch(logoutUser());
        return;
      }
      setLoading(false);
      Notiflix.Report.success(`Jawwal Roaming has been updated !`, "", "Close");
    } catch (err) {
      Notiflix.Report.failure(`Some Thing Went Wrong !`, "", "Close");
      setLoading(false);
    }
  };

  return (
    <div>
      <form
        className={
          !isDarktheme ? "contact-us mt-4" : "contact-us mt-4 dark-contuct-us"
        }
        onSubmit={updateRoaming}
      >
        <div className="report-checkbox-container">
          <Checkbox
            className="report-checkbox"
            onClick={() => setForceUpdate(!forceUpdate)}
            checked={forceUpdate}
            color="primary"
            id="force-Update"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <label htmlFor="force-Update">{translate("Force Update")}</label>
        </div>
        <Button
          type="primary"
          style={{ width: "100%" }}
          className="mt-2"
          title="Update"
          formType="submit"
          loading={loading}
        />
      </form>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps, null)(JawwalUpdateRoaming);
