import {
  GET_LAST_TRANSACTION,
  GET_SELLER_POINTS,
  GET_SELLER_REPORTS,
  GET_SELLER_PROFIT,
  GET_SELLER_RUNNING_REPORTS,
  GET_SELLER_CANCELATION_REPORTS,
  UPDATE_TRANSACTION_LOADING
} from "../actions/types";
const initialState = {
  lastTransaction: [],
  loading: true,
  sellerRunning: [],
  sellerCancelationReports: [],
  sellerReports: [],
  sellerPoints: [],
  sellerProfit: [],
  updateTransactionLoading: false
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAST_TRANSACTION:
      return {
        ...state,
        lastTransaction: action.payload,
        loading: false,
      };
    case GET_SELLER_POINTS:
      return {
        ...state,
        sellerPoints: action.payload,
        loading: false,
      };
    case GET_SELLER_REPORTS:
      return {
        ...state,
        sellerReports: action.payload,
        loading: false,
      };
    case GET_SELLER_PROFIT:
      return {
        ...state,
        sellerProfit: action.payload,
        loading: false,
      };
    case GET_SELLER_RUNNING_REPORTS:
      return {
        ...state,
        sellerRunning: action.payload,
        loading: false,
      };
    case GET_SELLER_CANCELATION_REPORTS:
      return {
        ...state,
        sellerCancelationReports: action.payload,
        loading: false,
      };
    case UPDATE_TRANSACTION_LOADING:
      return {
        ...state,
        updateTransactionLoading: action.payload,
      };
    default:
      return state;
  }
}

export default reportsReducer
