import React, { useState } from "react";
import { formattedObjects } from "./formatData";
import { Box, Divider, Typography, Paper, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditAccount from "../EditAccount";

function capitalizeFirstChar(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function OperatorSettings({ data, carrier }) {
  const [formatData, setFormatData] = useState(
    data?.map((item) => formattedObjects(item))
  );

  const handleDelete = (index) => {
    const newData = [...formatData];
    newData.splice(index, 1);
    setFormatData(newData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        padding: 2,
      }}
    >
      {formatData?.map((subArray, index) => (
        <React.Fragment key={index}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              mb: 2,
              backgroundColor: "background.paper",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {capitalizeFirstChar(data[index].Settings)}
              </Typography>
              <IconButton
                color="error"
                onClick={() => handleDelete(index)}
                aria-label="delete"
                sx={{ marginLeft: "auto" }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 2 }} />
            {subArray.map(
              (item, subIndex) =>
                (item.aKey.toLowerCase() == "description" ||
                  item.aKey.toLowerCase() == "value") && (
                  <Box key={subIndex} sx={{ mb: 2 }}>
                    <EditAccount
                      desc={item.desc}
                      variable={item.variable}
                      checkbox={item.checkbox}
                      aKey={item.aKey}
                      readOnly={item.aKey === "index"}
                      weight="normal"
                      selectOptions={item.selectOptions}
                      updateSettings={true}
                      index={data[index].index}
                      carrier={carrier}
                    />
                  </Box>
                )
            )}
          </Paper>
          {index < formatData.length - 1 && (
            <Divider sx={{ mb: 2, backgroundColor: "primary.main" }} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
