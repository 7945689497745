import React, { useState } from "react";

import Modal from "../common/Modal";

import { intl } from "../../i18n/provider";

import {
  WhatsappShareButton,
  WhatsappIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import { buyCardsAction, shareCardsAction } from "../../actions/cardsAction";
import { useDispatch } from "react-redux";
import translate from "../../i18n/translate";
// import { PrintCard } from "./PrintCards";
import { useParams } from "react-router";

import { connect } from "react-redux";

const DenominationCard = ({ match, card, columnStyle, isDarktheme }) => {
  const [cardModal, setCardModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  // const Barcode = require('react-barcode');
  const { ean, brand_name } = card
  const { share_id } = useParams();
  const img = card.image_path;
  const path = card.icon;
  const dispatch = useDispatch();

  const shareUrl = "";
  return (
    <div className={`${columnStyle} mb-4 wrapper`}>
      <div
        className={!isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"}
        onClick={() => setCardModal(true)}
        style={{ cursor: "pointer" }}
      >
        <div className="frame">
          <img alt="card" src={`${card.image_path}${card.icon}`} />

        </div>
        <div className="card nav-layout">
          <h5 className="m-3 text-center">{card.value + " " + card.currency}</h5>
          <div className={!isDarktheme ? "card nav-layout" : "card dark-nav-layout"}>
            <h5 className="m-3 text-center">{card.value + " " + card.currency}</h5>
          </div>
        </div>


        {cardModal && <>
          <Modal
            visible={cardModal}
            bgColor={!isDarktheme ? "white" : "#242526"}
            onClose={() => setCardModal(false)}
            onSuccess={() => {
              setCardModal(false)
              dispatch(buyCardsAction(ean, brand_name))
              setOpenCardModal(true)

            }}
            title="Card Details"
            titleColor={!isDarktheme ? "black" : "white"}
            width={"40vw"}
            card={card}
            titleButton="Buy Now"
          >
            <div className={!isDarktheme ? "card-summary mt-4" : "dark-card-summary mt-4"}>
              <div className="d-flex align-items-center justify-content-between">
                <h6 >{intl.formatMessage({ id: "Card Description" })}</h6>

                {/* <h6 style={{ whiteSpace: "pre-wrap" }}>{card.des}</h6> */}
                <h6>{translate(`${card.des}`)} </h6>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <h6>{intl.formatMessage({ id: "Price" })}</h6>
                <h6>{card.customer_price} NIS</h6>
              </div>

            </div>
          </Modal>
        </>}

        {/* {openCardModal && <CardModal />} */}
        {openCardModal && !cardModal &&
          <>
            <Modal
              style={{ overflowY: "scroll !important" }}
              visible={openCardModal}
              onClose={() => setOpenCardModal(false)}
              onSuccess={() =>
                // window.print()

                dispatch(shareCardsAction(share_id))

              }
              title=""
              width={"70vw"}
              card={card}
              titleButton="Print"
            >
              <div className="card-summary mt-4" >
                <div>
                  <div className="d-flex justify-content-center" >
                    <img style={{ width: "30%", maxHeight: "100px", }} alt="card" src={`${img}` + `${path}`} />
                  </div>


                  <div className="d-flex justify-content-center">

                    <h6 style={{
                      fontSize: "20px",
                      padding: "10px 60px ", border: "1px solid ",
                      borderRadius: "7px",
                      marginTop: "15px"
                    }}>
                      {card.value} <span style={{ fontSize: "10px" }}>{card.currency} </span>
                    </h6>

                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Brand Information" })}</h6>

                  <h6>{translate(`${card.des}`)} </h6>

                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Brand Information" })}</h6>

                  <h6 style={{ whiteSpace: "pre-wrap" }}>{translate(`${card.deno_des}`)} </h6>

                </div>
                <div className="d-flex justify-content-center">

                  <h6 style={{
                    padding: "10px 60px ",
                    borderRadius: "7px",
                    marginTop: "10px"
                  }}>
                    PIN CODE <br />
                    {card.barcode}
                  </h6>

                </div>


                <div className="d-flex justify-content-center">

                  {/* <Barcode value={card.barcode} /> */}

                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Share Via" })}</h6>

                  <h6>
                    <WhatsappShareButton url={shareUrl}>
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                    <EmailShareButton url={shareUrl}>
                      <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                  </h6>
                </div>
              </div>
            </Modal>
          </>}
      </div>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme
  }
}
export default connect(mapStateToProps, null)(DenominationCard);
