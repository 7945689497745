import { IconButton } from "@mui/material";
import React from "react";
import { FaPrint } from "react-icons/fa";

const PrintButton = ({ content }) => {
  console.log(content);
  const handlePrint = () => {
    const printWindow = window.open("", "PRINT", "height=600,width=800");

    printWindow.document.write(
      "<html><head><title>" + document.title + "</title>"
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(content);
    printWindow.document.write("</body></html>");

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      // Needed for large documents
      printWindow.print();
      printWindow.close();
    }, 1000);

    return true;
  };

  return (
    <IconButton onClick={handlePrint}>
      <FaPrint fontSize={16} />
    </IconButton>
  );
};

export default PrintButton;
