import ApiRequest from "./actions/ApiRequest";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC0rmtrz4ePxg_uKySO7-KOgjR2T1vybs8",
  authDomain: "phoneplay-8d087.firebaseapp.com",
  projectId: "phoneplay-8d087",
  storageBucket: "phoneplay-8d087.appspot.com",
  messagingSenderId: "663613436513",
  appId: "1:663613436513:web:5d5720d3e1c1acd313b020",
  measurementId: "G-CR0WDJEQS0",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

const publicKey =
  "BMK4Z7YhskJAMo8yz3PQOZSbYXAt2SyjThT3BzJeR12gmf_Pxo0KHIXrVTpi5cE48pWFL6dcFiQrn49wo0JUaNs";

export const getFireBaseToken = async (setTokenFound, setNotToken) => {
  // console.log("publicKey", publicKey);
  let currentToken = "";
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      currentToken = await getToken(messaging, { vapidKey: publicKey });
      console.log("permission", permission);
      let config = {
        headers: {
          firebase_token: currentToken,
        },
      };
      if (currentToken) {
        setTokenFound(true);
        setNotToken(currentToken);
        // await ApiRequest.post(`update_firebase_token`, null, config);
      } else {
        setTokenFound(false);
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    }
    console.log("currentToken", currentToken);
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
