import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useHistory } from "react-router-dom";
import TextFieldGroup from "./../../common/TextFieldGroup";
import "./jawwal.css";
import credits, { EMPTY_CREDIT } from "./credits";
import SubNav from "./SubNav";
import Selected from "./Selected";
import CompaniesNav from "../../common/CompaniesNav";
import logo from "../../../assests/images/bundles/jawwal/banner.jpg";
import { jawwalPlusCheck } from "../../../actions/companiesAction";
import { jsonParser } from "shared/utils/ppUtils";

const JawwalCredit = ({ jawwalPlusCheck, isDarktheme }) => {
  const history = useHistory().location.pathname;
  const state = useHistory().location.state;
  const [price, setPrice] = useState(null);
  const [selected, setSelected] = useState({});
  const [jawwal3g, setJawwal3g] = useState("");
  const [jawwalRom, setJawwalRom] = useState("");
  const [jawwalMin, setJawwalMin] = useState("");
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 col-6"
  );
  const [plus, setPlus] = useState("");

  let routerMobileNo = history.split("/")[3];

  useEffect(() => {
    jawwalPlusCheck(routerMobileNo);

    const handleSessionStorageChange = () => {
      document.title = "Credit Jawwal | Phone Play";
      if (sessionStorage.JawwalMin) {
        setJawwalMin(jsonParser(sessionStorage.JawwalMin));
      }
      if (sessionStorage.Jawwal3g) {
        setJawwal3g(jsonParser(sessionStorage.Jawwal3g));
      }
      if (sessionStorage.JawwalCredit) {
        setSelected(jsonParser(sessionStorage.JawwalCredit));
      }
      if (sessionStorage.JawwalRom) {
        setJawwalRom(jsonParser(sessionStorage.JawwalRom));
      }
      if (sessionStorage.JawwalPlus) {
        setPlus(jsonParser(sessionStorage.JawwalPlus));
      }
      refreshColumnStyle();
    };
    handleSessionStorageChange();

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    // Clean up the event listener
    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
  }, [history]);

  const onChange = (e) => {
    const value = e.target.value;
    if (value.includes(".") || value.includes(",")) {
      e.target.value = Math.floor(value);
    }
    const selectedCredit = { ...EMPTY_CREDIT, price: e.target.value };
    setPrice(e.target.value);
    setSelected(selectedCredit);
    sessionStorage.JawwalCredit = JSON.stringify(selectedCredit);
  };

  const onTypeClick = (item) => {
    sessionStorage.JawwalCredit = JSON.stringify(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    setSelected(item);
  };

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6 card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };
  let topDiv = null;

  return (
    <>
      <div ref={(ref) => (topDiv = ref)}>
        <div className="">
          <CompaniesNav
            title="Jawwal"
            logo={logo}
            mobile={routerMobileNo}
            onRefresh={() => {}}
            actionWidth="130px"
            providerId={
              window?.history?.state?.state?.provider_Id
                ? window?.history?.state?.state?.provider_Id
                : state?.provider_id
            }
            backLink="/company/jawwalNo"
            comingFromReports={
              !window?.history?.state?.state?.provider_Id && state?.provider_id
            }
            credit={selected}
          />
          <div className="row">
            <div className="col-12">
              <SubNav mobile={history.split("/")[3]} />
            </div>
          </div>
          <div className="position-relative">
            <Selected
              min={jawwalMin}
              setMin={setJawwalMin}
              g3={jawwal3g}
              setg3={setJawwal3g}
              credit={selected}
              setCredit={setSelected}
              setRom={setJawwalRom}
              rom={jawwalRom}
              setPlus={setPlus}
              plus={plus}
            />

            <hr
              className="mt-3"
              style={{
                border: "2px solid #42ace3",
                backgroundColor: "#42ace3",
                fontWeight: "bolder",
              }}
            />

            <div className="row ">
              {credits.map((item, i) => (
                <div key={i.toString()} className={`${columnStyle} my-2`}>
                  <div className="charge-card" id="charge-card-div">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => onTypeClick(item)}
                      id="credit-card-div-root-clicker"
                    >
                      <div className="card">
                        <img alt="bundle" src={item.url}></img>
                        {item && item.des}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className={`${columnStyle} my-2`}>
                <div className="card card-credit">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      onTypeClick({ ...EMPTY_CREDIT, price: price })
                    }
                  >
                    <div className="card">
                      <img alt="" src={EMPTY_CREDIT.url}></img>
                      <TextFieldGroup
                        style={{
                          width: "calc(50% - 20px)",
                          height: "70%",
                          padding: 0,
                          position: "absolute",
                          top: "50%",
                          left: "calc(50% + 12px)",
                          transform: "translateY(-50%)",
                          fontSize: "2rem",
                          fontFamily: '"Montserrat", sans-serif',
                          textAlign: "center",
                          outline: "rgb(16, 16, 16) auto 4px",
                        }}
                        name="price"
                        type="number"
                        pattern="\d+"
                        onChange={onChange}
                        min={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.companies.loading,
  isDarktheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, { jawwalPlusCheck })(JawwalCredit);
