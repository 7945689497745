import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import SellerCredits from "../pages/SellerCredits/SellerCredits";
import { getSellerDebt, getSellers } from "../../actions/sellerCreditsAction";
import translate from "../../i18n/translate";
import Modal from "../common/Modal";
import Checkbox from "@mui/material/Checkbox";
import ApiRequest from "../../actions/ApiRequest";
import Notiflix from "notiflix";
import PaymentIcon from "@mui/icons-material/Payment";
import TextField from "@mui/material/TextField";
import { logoutUser, userData } from "../../actions/userAction";
import { FormControlLabel, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { USER_TYPES_WITH_ADMIN } from "../../shared/constants/constants";
import FromToDatePicker from "components/common/FromToDatePicker";
import Select from "core/core-components/Select/Select";
import moment from "../../../node_modules/moment/moment";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Button,
  CircularProgress,
  IconButton,
} from "../../../node_modules/@mui/material/index";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import {
  conditionalReturn,
  isNullOrUndefined,
  parseBool,
} from "shared/utils/utils";
import { useIsAdmin } from "shared/hooks/ppHooks";

const SellerDebt = ({
  getSellerDebt,
  sellerDebts,
  sellerDebtLoading,
  getSellers,
  sellers,
  user,
  userData,
}) => {
  const [seller, setSeller] = useState(null);
  const [debtModal, setDebtModal] = useState(false);
  const [debtData, setDebtData] = useState({
    index: "",
    amount_paid: "",
    seller_debt_amount: "",
    note: "",
    remaining_amount: "",
    amount_to_pay: "",
  });
  const [isFullPaid, setIsFullPaid] = useState(false);
  const [debtSubmitLoading, setDebtSubmitLoading] = useState(false);
  const [paid, setPaid] = useState(false);
  const [isDetailsButtonClicked, setIsDetailsButtonClicked] = useState({
    flag: false,
    index: null,
  });
  const now = new Date();
  const { isReseller } = useIsAdmin();
  const sellerId = useSelector((state) => state.auth.user?.id);

  const TotalDebt = sellerDebts?.reduce((acc, curr) => {
    return acc + Number(curr?.amount) - Number(curr?.amount_paid);
  }, 0);
  const [dateTo, setDateTo] = useState(now);
  const [dateFrom, setDateFrom] = useState(now);
  const [langPopoverAnchorEl, setLangPopoverAnchorEl] = useState(null);
  const [isSendingReminder, setIsSendingReminder] = useState(false);

  const location = useLocation();
  const sellerIdParam = location?.search
    ?.split("seller_id=")
    ?.at(-1)
    ?.split("&")
    ?.at(0);
  const selectedSellerIndex = useRef();
  const dispatch = useDispatch();
  const isAdmin =
    USER_TYPES_WITH_ADMIN.admin.value === user.seller_type?.toLowerCase();

  useEffect(() => {
    if (sellerIdParam) {
      const seller = sellers?.find((se) => se?.seller_id == sellerIdParam);
      if (seller) {
        setPaid(false);
        setSeller({
          value: seller.seller_id,
          label: seller.name + " " + seller.seller_id,
          name: seller.name,
        });
      }
    }
  }, [sellerIdParam, sellers]);

  useEffect(() => {
    try {
      getSellers(isReseller && sellerId);
    } catch (e) {
      console.log(e);
    }
  }, [getSellers]);

  useEffect(() => {
    getSellerDebt(
      seller ? seller?.value : user?.id,
      paid,
      !isNullOrUndefined(sellerIdParam)
    );
  }, [getSellerDebt, user?.id, seller, paid, sellerIdParam]);

  const options = useMemo(() => {
    return sellers.map((seller) => ({
      value: seller.seller_id,
      label: seller.name + " " + seller.seller_id,
      name: seller.name,
    }));
  }, [sellers]);

  const handleSellerChange = (seller) => {
    setSeller(seller);
  };

  const handleDebtChange = (e) => {
    let { name, value } = e.target;
    value = Math.max(
      0,
      Math.min(Number(debtData?.remaining_amount), Number(value))
    )
      .toString()
      .replace(/^0+/, "");
    // if (Number(debtData?.seller_debt_amount) > Number(debtData?.amount_paid)) {
    //   return;
    // }
    setDebtData({ ...debtData, [name]: value });
  };

  const handleDebtSubmit = async () => {
    setDebtSubmitLoading(true);
    moment(dateFrom).format("YYYY-MM-DD");

    try {
      const { data } = await ApiRequest.post(
        `seller_pay_debt?sellerid=${
          seller ? seller?.value : user?.id
        }&amount_paid=${
          isFullPaid
            ? Number(debtData?.seller_debt_amount) -
              Number(debtData.amount_paid)
            : Number(debtData?.amount_to_pay)
        }&seller_debt_amount=${debtData?.seller_debt_amount}&note=${
          debtData?.note
        }&index=${debtData?.index}&from_date=${moment(dateFrom).format(
          "YYYY-MM-DD"
        )}&to_date=${moment(dateTo).format("YYYY-MM-DD")}`
      );

      if (!parseBool(data?.status)) {
        Notiflix.Notify.failure(data?.reason);
        if (data?.reason?.includes("token expired")) {
          dispatch(logoutUser());
          return;
        }
      } else {
        Notiflix.Notify.success("Successfully paid");
      }
    } catch (e) {
      Notiflix.Notify.failure("Something went wrong!!, please try again");
    } finally {
      setDebtSubmitLoading(false);
      setDebtModal(false);
      getSellerDebt(seller ? seller?.value : user?.id, false);
      userData();
      setPaid(false);
    }
  };

  function onSendReminder(id, e) {
    selectedSellerIndex.current = id;
    setLangPopoverAnchorEl(e.currentTarget);
  }

  async function sendReminder(language) {
    setIsSendingReminder(true);

    try {
      const mobile_number = sellerDebts?.find(
        (s) => s.index === selectedSellerIndex.current
      )?.mobile_number;
      await ApiRequest.post(
        `/send_whatsapp?language=${language}&mobile_number=${mobile_number}`
      );
    } catch (error) {
      console.log("sendReminder", error);
    }

    setIsSendingReminder(false);
    setLangPopoverAnchorEl(null);
  }

  function renderLanguagePopover() {
    return (
      <Popover
        open={!!langPopoverAnchorEl}
        anchorEl={langPopoverAnchorEl}
        onClose={() => setLangPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {conditionalReturn(
          isSendingReminder,
          <Grow
            in={isSendingReminder}
            style={{ transformOrigin: "0 0 0" }}
            {...(isSendingReminder ? { timeout: 1000 } : {})}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width={210}
              height={108}
            >
              <CircularProgress />
            </Grid>
          </Grow>
        )}
        {conditionalReturn(
          !isSendingReminder,
          <Grow
            in={!isSendingReminder}
            style={{ transformOrigin: "0 0 0" }}
            {...(!isSendingReminder ? { timeout: 1000 } : {})}
          >
            <Grid container>
              <Grid item md={12} xs={12}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => sendReminder("en")}
                >
                  <Typography>English</Typography>
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => sendReminder("ar")}
                >
                  <Typography>عربي</Typography>
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => sendReminder("he")}
                >
                  <Typography>עִברִית</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grow>
        )}
      </Popover>
    );
  }

  return (
    <>
      {renderLanguagePopover()}
      <div style={{ overflow: "scroll" }}>
        {isAdmin ? (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FromToDatePicker
                selectedDateFrom={dateFrom}
                selectedDateTo={dateTo}
                onSelectDateTo={(e) => setDateTo(e)}
                onSelectDateFrom={(e) => setDateFrom(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Select
                // defaultValue={colourOptions[2]}
                placeholder={translate("select_seller")}
                options={options}
                onChange={handleSellerChange}
                value={seller}
              />
            </Grid>
            <Grid item md={2} xs={12} paddingTop={"10px !important"}>
              <FormControlLabel
                label={translate("paid")}
                control={
                  <Checkbox
                    checked={paid}
                    onChange={(event) => setPaid(event.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        ) : null}

        <div className="d-flex justify-content-between mt-4">
          <h5>
            {!seller || seller?.value?.toString() === user?.id
              ? translate("your_invoices")
              : `${seller?.name}'s Invoices`}
          </h5>
          {sellerDebts?.length > 0 && (
            <h5>
              {paid ? translate("total_payments") : translate("total_debt")}:{" "}
              {TotalDebt} ₪
            </h5>
          )}
        </div>
        <table className={"table table-striped mt-2"}>
          <thead>
            <tr className="first-Row">
              <td> </td>
              <td>{translate("index")}</td>
              <td>{translate("seller_name")}</td>
              <td>{translate("date")}</td>
              <td>{translate("amount")}</td>
              <td>{translate("paid_amount")}</td>
              <td>{translate("remaining_amount")}</td>
              <td>{translate("payment")}</td>
              <td>{translate("send_reminder")}</td>
            </tr>
          </thead>
          <tbody>
            {sellerDebts?.map((debt, index) => (
              <React.Fragment>
                <tr key={index.toString()}>
                  <td
                    onClick={() =>
                      setIsDetailsButtonClicked({
                        flag: !isDetailsButtonClicked.flag,
                        index: index,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      className={
                        isDetailsButtonClicked.flag &&
                        isDetailsButtonClicked.index === index
                          ? "bi bi-chevron-down flip"
                          : "bi bi-chevron-down"
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </td>
                  <td>{debt?.index}</td>
                  <td>{debt?.seller_name}</td>
                  <td>{debt?.datetime}</td>
                  <td>{debt?.amount}</td>
                  <td>{debt?.amount_paid}</td>
                  <td>{Number(debt?.amount) - debt?.amount_paid}</td>
                  <td>
                    <IconButton
                      onClick={() => {
                        setDebtModal(true);
                        setDebtData({
                          index: debt?.index,
                          amount_paid: debt?.amount_paid,
                          seller_debt_amount: debt?.amount,
                          note: debt?.note,
                          remaining_amount:
                            Number(debt?.amount) - debt?.amount_paid,
                          amount_to_pay: 0,
                        });
                      }}
                    >
                      <PaymentIcon color="primary" sx={{ cursor: "pointer" }} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={(e) => onSendReminder(debt?.index, e)}>
                      <NotificationsNoneIcon color="primary" />
                    </IconButton>
                  </td>
                </tr>
                {isDetailsButtonClicked.flag &&
                  isDetailsButtonClicked.index === index && (
                    <tr style={{ backgroundColor: "white" }}>
                      <td colSpan={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            {conditionalReturn(
                              !isNullOrUndefined(debt?.note),
                              <div className="table-details">
                                <span>
                                  <p
                                    style={{ paddingRight: 8 }}
                                    className="list-label"
                                  >
                                    {translate("seller_id")}:
                                  </p>
                                </span>
                                <span title={debt?.seller_id}>
                                  <p>{debt?.seller_id}</p>
                                </span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {conditionalReturn(
                              !isNullOrUndefined(debt?.note),
                              <div className="table-details">
                                <span>
                                  <p
                                    style={{ paddingRight: 8 }}
                                    className="list-label"
                                  >
                                    {translate("admin_user")}:
                                  </p>
                                </span>
                                <span title={debt?.admin_user}>
                                  <p>{debt?.admin_user}</p>
                                </span>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {conditionalReturn(
                              !isNullOrUndefined(debt?.note),
                              <div className="table-details">
                                <span>
                                  <p
                                    style={{ paddingRight: 8 }}
                                    className="list-label"
                                  >
                                    Note:
                                  </p>
                                </span>
                                <span title={debt?.note}>
                                  <p
                                    style={{
                                      margin: 0,
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      maxWidth: 100,
                                    }}
                                  >
                                    {debt?.note}
                                  </p>
                                </span>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <Modal
          title="Debt Payment"
          visible={debtModal}
          onClose={() => {
            setDebtModal(false);
            setIsFullPaid(false);
          }}
          width={500}
          onSuccess={handleDebtSubmit}
          onSuccessLoading={debtSubmitLoading}
          onSuccessDisable={Number(debtData.amount_to_pay) <= 0 && !isFullPaid}
        >
          <h6 className="mt-2 mb-0">
            {translate("remaining_amount")}: {debtData?.remaining_amount}
          </h6>
          <div className="phone-form credits-row">
            <div
              className="report-checkbox-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                className="report-checkbox"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                id="full"
                checked={isFullPaid}
                onClick={() => setIsFullPaid(!isFullPaid)}
              />
              <label htmlFor="full">{translate("Full Paid")}</label>
            </div>
            <input
              type="number"
              value={
                isFullPaid
                  ? debtData?.remaining_amount
                  : debtData?.amount_to_pay
              }
              onChange={handleDebtChange}
              placeholder="Amount To Pay"
              className="form-control credit-input"
              name="amount_to_pay"
              disabled={isFullPaid}
            />
          </div>
          <TextField
            variant="standard"
            label="Note"
            name="message"
            onChange={(e) => setDebtData({ ...debtData, note: e.target.value })}
            multiline
            minRows={3}
            maxRows={7}
            inputProps={{ maxLength: 499 }}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({
  credits: { sellerDebts, sellerDebtLoading, sellers },
  auth: { user },
}) => ({
  sellerDebts,
  sellerDebtLoading,
  sellers,
  user,
});

export default connect(mapStateToProps, {
  getSellerDebt,
  getSellers,
  userData,
})(SellerDebt);
