import React from "react";
import Skeleton from "@mui/material/Skeleton";

const CompaniesSkeleton = ({ columnStyle, height }) => {
  return (
    <div className={`${columnStyle} mb-4 wrapper`}>
      <Skeleton
        sx={{ transform: "scale(1)" }}
        animation="wave"
        width="100%"
        height={height}
      />
    </div>
  );
};

export default CompaniesSkeleton;
