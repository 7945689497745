const SaleReportTableStyles = (theme) => ({
  tableDanger: {
    backgroundColor: "#eccccf",
  },
  copyNumberBtn: {
    position: "absolute",
    padding: 0,
    top: 24,
    left: 48,
    "& svg": {
      fontSize: 16,
    },
  },
  relativePosition: {
    position: "relative",
  },
  removeMargin: {
    "& p": {
      margin: 0,
    },
  },
  referenceNoCopyBtn: {
    position: "absolute",
    padding: 0,
    right: -20,
    "& svg": {
      fontSize: 16,
    },
    paddingInline: 8,
  },
  textRed: {
    color: "red",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "bold",
  },
  textGreen: {
    color: "green",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontWeight: "bold",
  },
  textBlue: {
    color: "blue !important",
  },
  copyBtn: {
    position: "absolute",
    padding: 0,
    left: 40,
    top: 30,
  },
  copyIcon: {
    fontSize: 16,
  },
  sellerNameP: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  canceledRequestUnderLine: {
    marginTop: 2,
    width: 20,
    backgroundColor: "red",
    height: 1.5,
  },
  returnedMessageText: {
    overflow: "hidden",
    whiteSpace: "normal",
    direction: "rtl",
    textOverflow: "ellipsis",
    width: "75%",
    maxWidth: 350,
    margin: 0,
    minWidth: 28,
    display: "-webkit-box",
    " -webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  returnedMessageDiv: {
    // height: 19.5,
  },
  sellerIdCopyBtn: {
    position: "relative",
    padding: 0,
    paddingInline: 8,
    left: 8,
  },
  referenceNoText: {
    margin: 0,
  },
});
export default SaleReportTableStyles;
