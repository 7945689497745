import React from "react";
import "./style.css";
import PropTypes from "prop-types";

const Badge = ({ text }) => {
  return (
    <span
      className="badge position-absolute top-0 left-100  bg-danger"
      style={{
        left: "100%",
        transform: "translate(-100%,-70%)",
      }}
    >
      {text}
    </span>
  );
};
Badge.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Badge;
