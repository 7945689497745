import React from "react";
import PropTypes from "prop-types";
import translate from "../../i18n/translate";

const Message = ({ msg, className, type = "danger", ...rest }) => {
  return (
    <label className={`alert alert-${type} ${className}`} role="alert" {...rest}>
      {translate(msg)}
    </label>
  );
};

Message.propTypes = {
  msg: PropTypes.string,
  type: PropTypes.string,
};

export default Message;
