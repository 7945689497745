import { toast } from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { intl } from "i18n/provider";
// import { useState } from "react";

export const ShowToastPromise = async (callback, { mobileNumber }) => {
  console.log("ShowToastPromise", mobileNumber, callback);
  const closeToast = (toastId) => {
    console.log("closeToast", toastId);
    toast.dismiss(toastId); // Dismiss the specific toast
  };

  toast.promise(
    callback(),
    {
      loading: `${intl.formatMessage({
        id: "Your request is in progress",
      })} (${mobileNumber})`,
      success: (t) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          // onClick={togglePosition}
        >
          <span>
            {intl.formatMessage({ id: "success_op" })} ({mobileNumber})
          </span>
          <CloseIcon
            onClick={() => closeToast(t.id)}
            style={{ cursor: "pointer", marginLeft: "16px" }}
          />
        </div>
      ),
      error: (t) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            {intl.formatMessage({ id: "failed" })} ({mobileNumber})
          </span>
          <CloseIcon
            onClick={() => closeToast(t.id)}
            style={{ cursor: "pointer", marginLeft: "16px" }}
          />
        </div>
      ),
    },
    {
      duration: 180000,
      position: "top-left", // Position of the toast
    }
  );
};
