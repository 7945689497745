import React from "react";
import ReactDOM from "react-dom";
import "./extensions";
import "./index.css";
import "react-dropdown/style.css";
import App from "./App";
import Header from "./Header";
import "./web.config";
import "./assests/lib/jQStyled";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
const TITLE = process.env.REACT_APP_SITE_TITLE;

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function (registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>{TITLE}</title>
    </Helmet>
    <Header />
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
const body = document.getElementsByTagName("body")[0];
if (localStorage.langCity === "en") {
  body.setAttribute("dir", "ltr");
  body.setAttribute("lang", "en");
  body.classList.add("english");
} else if (!localStorage.langCity) {
  body.setAttribute("dir", "rtl");
  body.setAttribute("lang", "ar");
  body.classList.add("arabic");
} else {
  body.setAttribute("dir", "rtl");
  body.setAttribute("lang", "ar");
  body.classList.add("arabic");
}

if (!window.$) throw new Error("JQUERY IS REQUIRED");
