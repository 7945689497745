import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import translate from "../../../i18n/translate";
import { useLocation } from "react-router-dom";
import TextFieldGroup from "../../common/TextFieldGroup";
import "react-phone-number-input/style.css";
import BackNav from "../../common/BankNav";
import { jawwalPlus, jawwalPlusCheck } from "../../../actions/companiesAction";
import { getAllCompanies } from "../../../actions/companiesAction";
import ProvidersCards from "../../../core/containers/ProvidersCards/ProvidersCards.jsx";
import { Box, Button, Grid, TextField } from "@mui/material";
import {
  conditionalReturn,
  isNullOrUndefined,
  parseBool,
} from "../../../shared/utils/utils.js";

import {
  PROVIDER_ID_TO_NAME_DICTIONARY,
  PROVIDER_ROUTES,
} from "../../../shared/constants/constants.js";

import { intl } from "../../../i18n/provider.js";
import { SYSTEM_COLORS } from "../../../shared/constants/stylesConstants.js";
import { makeStyles } from "@mui/styles";
import MobileNumberInfoBox from "./MobileNumberInfoBox.jsx";
import { checkNumberProviderApiCaller } from "../../../shared/endPoints/apiCallers.js";
import { useMount } from "shared/hooks/ppHooks";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import ApiRequest from "../../../actions/ApiRequest";
import { Na } from "react-flags-select";

const {
  parseQueryParams,
  useCustomHistory,
  useObjectState,
  useQueryString,
  useSessionStorage,
} = require("../../../shared/hooks");
const { parseString } = require("../../../shared/utils/uutils");

const useStyle = makeStyles(() => ({
  nextBtnContainer: {
    display: "flex",
    alignItems: "end",
  },
  nextBtn: {
    height: 48,
    width: 100,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  errorMsg: {
    position: "absolute",
    color: SYSTEM_COLORS.red,
    width: "calc(100% - 16px)",
    marginTop: 4,
  },
}));

export default function AddBalance(props) {
  const classes = useStyle();
  const history = useCustomHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = parseQueryParams(location?.search);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const inputRef = useRef();
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-2 col-md-4 col-sm-6 card-sm"
  );
  const [hideIsraelCompany, setHideIsraelCompany] = useState(
    localStorage.getItem("hide companies")
  );

  useEffect(() => {
    // Check if window and sessionStorage are available
    if (typeof window !== "undefined" && window.sessionStorage) {
      window.sessionStorage.removeItem("JawwalPlus");
      window.sessionStorage.removeItem("JawwalMin");
      window.sessionStorage.removeItem("JawwalCredit");
      window.sessionStorage.removeItem("Jawwal3g");
      window.sessionStorage.removeItem("JawwalRom");
      window.sessionStorage.removeItem("ooredoo3g");
      window.sessionStorage.removeItem("ooredooMin");
      window.sessionStorage.removeItem("ooredooCredit");
      window.sessionStorage.removeItem("ooredooRom");
      window.sessionStorage.removeItem("ooredooSuper");
    }
  }, []);

  useEffect(() => {
    let element = document.body;

    refreshColumnStyle();

    setTimeout(() => {
      window.onkeydown = async (e) => {
        if (e.code === "")
          if (!scrollbarVisible(element)) {
            document.body.classList.add("padding-class");
          } else {
            document.body.classList.remove("padding-class");
          }

        if (
          ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
          localStorage.getItem("hide companies") === "false"
        ) {
          e.returnValue = false;
          setHideIsraelCompany("true");
          localStorage.setItem("hide companies", "true");
          return;
        }
        if (
          ((e.ctrlKey && e.keyCode === 73) || e.code === "F9") &&
          localStorage.getItem("hide companies") === "true"
        ) {
          const { data } = await ApiRequest.post(`allow_f9`);

          if (data.reason === "true") {
            setHideIsraelCompany("false");
            localStorage.setItem("hide companies", "false");
          }
        }
      };
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function scrollbarVisible(element) {
    return element.scrollHeight > element.clientHeight;
  }

  const sessionStorage = useSessionStorage();
  const providerId = (
    parseString(queryParams?.providerId, {}) || "001"
  ).toString();
  const passedMobileNumber = queryParams?.mobileNumber || "";
  const allCompanies = useSelector((state) => state?.companies?.allCompanies);
  const currentProvider = allCompanies?.find(
    (c) => c.id?.toString() === providerId?.toString()
  );
  const [mobileNumberInfo, setMobileNumberInfo] = useState(null);
  // const [mobileNumber, setMobileNumber] = useQueryString({
  //   key: "mobileNumber",
  //   initialValue: passedMobileNumber,
  // });

  const [mobileNumber, setMobileNumber] = useState(
    passedMobileNumber.length === 4 ? "" : passedMobileNumber
  );
  const [toggles, setToggles] = useObjectState({
    showInfoBox: false,
    invalidNumberErr: false,
    wrongProviderNumberErr: false,
  });
  const changeNumberHandler = (e) => {
    const number = e.target.value.replace(/\D/g, "");
    if (number)
      if (!isNaN(e.target.value[number.length - 1])) {
        setMobileNumber(number);
        // window.speechSynthesis.cancel();

        // handleReadAloud(number[number.length - 1]);
      }
    if (number.length === 0) {
      setMobileNumber();
    }
  };

  function redirectToProviderId(
    providerId = "",
    mobileNumber = "",
    queryObj = {}
  ) {
    history.push(
      {
        path: `${PROVIDER_ROUTES.getProviderRoute(
          providerId?.toString()
        )}/${mobileNumber}`,
        queryObj,
      },
      { provider_Id: providerId }
    );
  }
  // useEffect(() => {
  //   // Function to read the number aloud
  //   const readNumberAloud = () => {
  //     if (!window.speechSynthesis) {
  //       // SpeechSynthesis not supported by the browser
  //       return;
  //     }

  //     for (let i = 0; i < mobileNumber.length; i++) {
  //       const char = mobileNumber[i];
  //       const utterance = new SpeechSynthesisUtterance(char);
  //       utterance.lang = localStorage.getItem("langCity");
  //       window.speechSynthesis.speak(utterance);
  //     }
  //   };

  //   readNumberAloud();
  // }, [mobileNumber]);
  useEffect(() => {
    if (mobileNumber && mobileNumber?.trim()?.length === 10) {
      const product = currentProvider?.product.toLowerCase();
      (async function () {
        try {
          const { data } = await checkNumberProviderApiCaller({
            mobileNumber,
            carrier: product,
          });
          setMobileNumberInfo(data);
          setToggles({
            invalidNumberErr: false,
            showInfoBox: data?.pci !== "unknown",
            wrongProviderNumberErr: data?.provider !== currentProvider?.product,
          });
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setToggles({ showInfoBox: false });
    }
  }, [currentProvider, mobileNumber, setToggles]);

  useLayoutEffect(() => {
    if (!providerId || !providerId?.length) {
      history.push({ path: "/add_balance" });
    }
  }, [history, providerId]);

  useMount(() => {
    if (!allCompanies || !allCompanies?.length) {
      dispatch(getAllCompanies());
    }
  });

  useEffect(() => {
    document.title = PROVIDER_ID_TO_NAME_DICTIONARY[providerId];

    if (providerId === "001") {
      dispatch(jawwalPlus(false));
    }
  }, [currentProvider, history, providerId, dispatch]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (
      mobileNumber?.split("").length !== 0 &&
      mobileNumber?.split("").length !== 10
    ) {
      setToggles({ invalidNumberErr: true });
    } else {
      let mobileNumber2 =
        mobileNumber.split("").length === 0 ? "null" : mobileNumber;
      setToggles({ invalidNumberErr: false });
      const callbacks = {
        "001": async () => {
          await dispatch(jawwalPlusCheck(mobileNumber2));
        },
      };
      await callbacks[providerId]?.();

      sessionStorage.removeItems([
        "groupCompany",
        "JawwalMin",
        "JawwalCredit",
        "Jawwal3g",
        "JawwalRom",
        "ooredoo3g",
        "ooredooMin",
        "ooredooCredit",
        "ooredooRom",
        "ooredooSuper",
      ]);

      redirectToProviderId(providerId, mobileNumber2);
    }
    setLoading(false);
    setSuccess(true);
    mobileNumber && handleReadAloud(mobileNumber);
  };
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  function onAmountClick(isSecond = false) {
    if (!toggles.showInfoBox) return;

    const correctProvider = allCompanies.find(
      (c) => c.product === mobileNumberInfo.provider
    );

    if (isNullOrUndefined(correctProvider)) return;

    props.onBalanceCardClick?.({ mobileNumber, mobileNumberInfo, isSecond });
    // redirectToProviderId(correctProvider.id, mobileNumber);
  }

  function onProviderClick() {
    if (!toggles.showInfoBox) return;

    const correctProvider = allCompanies.find(
      (c) => c.product === mobileNumberInfo.provider
    );

    if (isNullOrUndefined(correctProvider)) return;

    redirectToProviderId(correctProvider.id, mobileNumber);
  }
  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 card-md");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 card-lg");
        break;
      case "default":
      case "column6":
        setColumnStyle("col-lg-2 col-md-4 col-sm-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 card-md");
    }
  };
  const handleReadAloud = (number) => {
    if (!window.speechSynthesis) {
      // SpeechSynthesis not supported by the browser
      return;
    }
    // window.speechSynthesis.cancel();
    const speechText = number;

    const utterance = new SpeechSynthesisUtterance(speechText);
    utterance.lang = localStorage.getItem("langCity");
    // utterance.rate = 1.5;
    window.speechSynthesis.speak(utterance);
  };

  return (
    <Grid container id={"backNav"}>
      <Grid item md={12} xs={12}>
        <BackNav title={currentProvider?.product} icon={currentProvider?.url} />
      </Grid>

      <Grid
        container
        md={12}
        xs={12}
        paddingTop={4}
        sx={{
          display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <img
              src={currentProvider?.url}
              alt={currentProvider?.product}
              style={{
                width: "210px",
                marginBottom: "40px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <form onSubmit={onSubmit} style={{ margin: "auto" }}>
            <Grid
              container
              justifyContent={"center"}
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                item
                position={"relative"}
                id={"inter_mobileNumber_form"}
                xs={12}
              >
                {/* <label
                  onClick={() => {
                    setToggles({ invalidNumberErr: false });
                  }}
                  htmlFor="mobileNumber"
                >
                  {translate("jawwalNo")}
                </label> */}
                <TextField
                  id="add-balance-number-input"
                  value={mobileNumber}
                  onChange={changeNumberHandler}
                  label={translate("jawwalNo")}
                  variant="outlined"
                  min={10}
                  // type="number"
                  autoFocus={true}
                  inputRef={inputRef}
                  name={"mobileNumber"}
                  error={toggles.invalidNumberErr}
                  helperText={toggles.invalidNumberErr && "Enter valid number"}
                  InputProps={{
                    style: {
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                    },
                  }}
                />
                {/* {conditionalReturn(
                  toggles.invalidNumberErr,
                  <label className={classes.errorMsg}>
                    * {intl.formatMessage({ id: "Enter valid number" })} *
                  </label>
                )} */}
              </Grid>
              <Grid item className={classes.nextBtnContainer} xs={12}>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    sx={buttonSx}
                    disabled={loading}
                    className={classes.nextBtn}
                    variant={"contained"}
                    type={"submit"}
                    id="add-balance-next-btn"
                  >
                    {translate("next")}
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid
        container
        item
        // height={300}
        marginBottom={"24px"}
        marginTop={"24px"}
        alignItems={"center"}
        justifyContent={"center"}
        pb={1}
        pt={1}
      >
        {conditionalReturn(
          toggles.showInfoBox,
          <MobileNumberInfoBox
            onAmountClick={onAmountClick}
            onProviderClick={onProviderClick}
            mobileNumberInfo={mobileNumberInfo}
            isError={toggles.wrongProviderNumberErr}
          />
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        <ProvidersCards
          callBack={() => {
            document.getElementById("mobileNumber")?.focus();
            inputRef.current.focus();
          }}
          columnStyle={columnStyle}
          md={3}
          hideInformationIcon={false}
          hideIsraeliCarriers={parseBool(hideIsraelCompany)}
          xs={4}
        />
      </Grid>
    </Grid>
  );
}
