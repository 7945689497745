import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { USER_TYPES_WITH_ADMIN } from "../../../shared/constants/constants";
import Select from "../Select/Select";

const userTypesArr = Object.values(USER_TYPES_WITH_ADMIN);

const propTypes = {
  selectedUserType: PropTypes.object,
  onSelectSeller: PropTypes.func,
  onSelectUserType: PropTypes.func,
  sellersOptions: PropTypes.array,
  userTypesOptions: PropTypes.array,
  selectedSeller: PropTypes.object,
  disabled: PropTypes.bool,
};

export default function UserTypesDropdown(props) {
  const {
    selectedUserType,
    onSelectSeller,
    onSelectUserType,
    sellersOptions,
    selectedSeller,
    userTypesOptions,
    disabled,
  } = props;

  return (
    <React.Fragment>
      <Grid container item xs={12} md={12}>
        {/* <Grid item xs={6} md={6} height={32} paddingRight={"2px"}>
          <Select
            options={userTypesOptions || userTypesArr}
            isDisabled={disabled}
            value={selectedUserType}
            isClearable
            onChange={onSelectUserType}
            placeholder="User type"
            // styles={{ height: "32px", ...customStyles }}
          />
        </Grid> */}
        <Grid item xs={12} md={12} height={32} paddingLeft={"4px"}>
          <Select
            options={sellersOptions}
            value={selectedSeller}
            isClearable
            onChange={onSelectSeller}
            isDisabled={disabled}
            // styles={{ height: "32px", ...customStyles }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

UserTypesDropdown.propTypes = propTypes;
