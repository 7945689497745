import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart,
    PieController,
    ArcElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    LineElement,
    LinearScale,
    registerables,
} from "chart.js";
import { CategoryScale } from "chart.js";
import DatePicker from "react-datepicker";
import moment from "moment";
import ApiRequest from "../../../actions/ApiRequest";
import Button from "../../common/Button";
import { connect } from "react-redux";
import "./graphStyle.css";

Chart.register(CategoryScale);
Chart.register(...registerables);

Chart.register(
    PieController,
    ArcElement,
    Title,
    Legend,
    Tooltip,
    LineController,
    LineElement,
    LinearScale
);
// defaults.global.tooltips.enabled = false
// defaults.global.legend.position = 'bottom'

const BarChart = ({ isDarktheme }) => {
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [barChartdate, setBarChartdate] = useState("");

    const params = {
        dateTo: dateTo ? moment(dateTo).format("Y-M-D H:M:S") : "",
        dateFrom: dateFrom ? moment(dateFrom).format("Y-M-D H:M:S") : "",
    };
    const getdata = async () => {
        try {
            const { data } = await ApiRequest.post(
                `product_sales?from_date=${params.dateFrom}&to_date=${params.dateTo}`
            );
            setBarChartdate(data);
        } catch (err) {
            console.log(err);
        }
    };

    let GraphCompanyName = [];
    let GraphCompanyCount = [];
    if (barChartdate.length > 0) {
        barChartdate.forEach((data) => {
            // console.log(Object.keys(data)[0])
            // console.log(Object.values(data)[1])
            GraphCompanyName.push(Object.keys(data)[0]);
            GraphCompanyCount.push(Object.values(data)[2]);
        });
    }
    console.log("GraphCompanyCount", GraphCompanyCount, barChartdate)
    let graphValues = GraphCompanyName?.map((company, i) => Number(GraphCompanyCount[i]));
    return (
        <>
            <div className={!isDarktheme ? "dateBicker" : "dark-dateBicker"}>
                <div dateBicker>
                    <h6>From_Date</h6>
                    <DatePicker
                        selected={dateFrom}
                        onChange={(date) => {
                            setDateFrom(date);
                        }}
                    />
                </div>
                <div>
                    <h6>To_Date</h6>
                    <DatePicker
                        selected={dateTo}
                        onChange={(date) => {
                            setDateTo(date);
                        }}
                    />
                </div>
                <div>
                    <Button
                        title="search"
                        onClick={getdata}
                        type="primary"
                        disabled={!dateTo || !dateFrom}
                        style={{ width: "110px", marginTop: "16px" }}
                    />
                </div>
            </div>
            <div>
                {barChartdate && <Bar
                    style={{ backgroundColor: "white" }}
                    data={{
                        labels: GraphCompanyName,
                        datasets: [

                            {
                                label: "profit",
                                hoverBackgroundColor: "rgba(232,150,90,0.8)",
                                data: graphValues,
                                backgroundColor: ["rgba(120, 102, 255, 1)", "red", "green"],
                                borderColor: ["rgba(153, 102, 255, 0.2)"],
                                borderWidth: 1,
                            },


                        ],
                        // datasets: graphValues,
                    }}
                    height={400}
                    width={600}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                        responsive: true,
                        aspectRatio: 1,
                        layout: {
                            padding: {
                                left: 50,
                                right: 0,
                                top: 0,
                                bottom: 0,
                            },
                        },
                    }}
                />}
            </div>
        </>
    );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme,
    };
};

export default connect(mapStateToProps, null)(BarChart);