import React from "react";
import translate from "../../i18n/translate";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@mui/material";

const ReportHeader = ({ isDarktheme }) => {
  const history = useHistory().location.pathname;
  return (
    <div className={!isDarktheme ? "card" : "dark-card"}>
      <Box
        className="mb-2"
        sx={{
          display: "flex",
          // alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "flex-start", sm: "space-around" },
          alignItems: { xs: "flex-start", sm: "center" },
          background: !isDarktheme ? "#f1f1f1" : "#242526",
        }}
      >
        <label htmlFor="inputEmail3" className=" col-form-label">
          <Link
            to="report"
            className={`semi-nav ${history === "/report" && "active-semi"} m-4`}
          >
            {translate("sales")}
          </Link>
        </label>
        <label htmlFor="inputEmail3" className=" col-form-label">
          <Link
            to="profit"
            className={`semi-nav ${history === "/profit" && "active-semi"} m-0`}
          >
            {translate("profit_calculation")}
          </Link>
        </label>
        <label htmlFor="inputEmail3" className="col-form-label">
          <Link
            to="running"
            className={`semi-nav ${history === "/running" && "active-semi"}`}
          >
            {translate("running_balance")}
          </Link>
        </label>
        <label htmlFor="inputEmail3" className=" col-form-label">
          <Link
            to="credit_report"
            className={`semi-nav ${
              history === "/credit_report" && "active-semi"
            }`}
          >
            {translate("credits_report")}
          </Link>
        </label>
        <label htmlFor="inputEmail3" className=" col-form-label">
          <Link
            to="points"
            className={`semi-nav ${history === "/points" && "active-semi"}`}
          >
            {translate("points")}
          </Link>
        </label>
        <label htmlFor="inputEmail3" className=" col-form-label">
          <Link
            to="debit_report"
            className={`semi-nav ${
              history === "/debit_report" && "active-semi"
            }`}
          >
            {translate("debit_report")}
          </Link>
        </label>
      </Box>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(ReportHeader);
