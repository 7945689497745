import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getRewards, addRewards } from "../../../actions/sellerCreditsAction";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../../common/Modal";
import TextFieldGroup from "../../common/TextFieldGroup";
import translate from "../../../i18n/translate";

const PointsPanel = ({ getRewards, loading, rewards, addRewardsLoading, addRewards, isDarktheme }) => {
  const [addRewardsModal, setAddRewardsModal] = useState(false);
  const [addRewardsData, setAddRewardsData] = useState(null);

  useEffect(() => {
    getRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { value, name } = e.target;
    setAddRewardsData({
      ...addRewardsData,
      [name]: value,
    });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex justify-content-end my-4" style={{ width: "100%" }}>
        <button className="btn btn-primary" onClick={() => setAddRewardsModal(true)}>
          {translate("AddBalance A Reward")}
        </button>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650, margin: "auto" }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("ID")}
              </TableCell>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("Name")}
              </TableCell>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("Price")}
              </TableCell>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("type")}
              </TableCell>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("Edit")}
              </TableCell>
              <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                {" "}
                {translate("Delete")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map((reward, i) => (
              <TableRow key={i.toString()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className={!isDarktheme ? "" : "text-light"} component="th" scope="row" align="center">
                  {reward.id}
                </TableCell>
                <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                  {reward.name}
                </TableCell>
                <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                  {reward.price}
                </TableCell>
                <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                  {reward.type}
                </TableCell>
                <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                  <EditIcon color="primary" sx={{ cursor: "pointer" }} />{" "}
                </TableCell>
                <TableCell className={!isDarktheme ? "" : "text-light"} align="center">
                  <DeleteIcon color="error" sx={{ cursor: "pointer" }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <CircularProgress size={80} sx={{ marginTop: "160px" }} />}
      <Modal
        visible={addRewardsModal}
        onClose={() => setAddRewardsModal(false)}
        width={"45vw"}
        title="AddBalance A Rewards"
      >
        <form
          className="phone-form"
          onSubmit={(e) => {
            e.preventDefault();
            addRewards(addRewardsData).then(() => setAddRewardsModal(false));
          }}
        >
          <TextFieldGroup
            placeholder="id"
            name="id"
            type="text"
            value={addRewardsData?.id}
            label={translate("ID")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="name"
            name="name"
            type="text"
            value={addRewardsData?.name}
            label={translate("Name")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="price"
            name="price"
            type="text"
            value={addRewardsData?.price}
            label={translate("type")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="url"
            name="url"
            type="text"
            value={addRewardsData?.url}
            label={translate("url")}
            onChange={onChange}
            required={true}
          />
          <div className="d-flex justify-content-end align-items-center mt-4" style={{ width: "100%" }}>
            <button className="btn btn-danger mx-3" onClick={() => setAddRewardsModal(false)}>
              {translate("Cancel")}
            </button>
            <button type="submit" className="btn btn-primary">
              <div>
                {addRewardsLoading ? <CircularProgress sx={{ color: "white" }} size={20} /> : translate("submit")}
              </div>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ credits: { rewards, loading, addRewardsLoading }, darkTheme: { isDarktheme } }) => {
  return {
    rewards,
    loading,
    addRewardsLoading,
    isDarktheme,
  };
};

export default connect(mapStateToProps, { getRewards, addRewards })(PointsPanel);
