import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefined, parseBool } from "../../../shared/utils/utils.js";
import ProvidersCard from "./ProviderCard.jsx";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { getAllCompanies } from "../../../actions/companiesAction.js";
import { useMount } from "shared/hooks/ppHooks.js";
import { HCard, HCardBody } from "material-hcard-private";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";
import Div from "core/Div/Div.jsx";
import TypeNumber from "../TypeNumber/TypeNumber.jsx";
import { carrierCheckApiCaller } from "shared/endPoints/apiCallers.js";
import { intlTranselate } from "shared/utils/ppUtils.js";

const propTypes = {
  currentProviderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.number,
  xs: PropTypes.number,
  hideIsraeliCarriers: PropTypes.bool,
  hideInformationIcon: PropTypes.bool,
  callBack: PropTypes.func,
};

const defaultProps = {
  md: 2,
  xs: 4,
  hideInformationIcon: true,
  hideIsraeliCarriers: parseBool(localStorage.getItem("hide companies")),
};

export default function ProvidersCards(props) {
  const {
    currentProviderId,
    md,
    hideInformationIcon,
    hideIsraeliCarriers,
    xs,
    callBack,
  } = props;
  const allCompanies = useSelector((state) => state?.companies?.allCompanies);
  const dispatch = useDispatch();
  const filteredCompanies = (
    hideIsraeliCarriers
      ? allCompanies.filter((c) => c.location === "PAL")
      : allCompanies
  )?.filter(
    (c) =>
      parseBool(c.active) && c?.id?.toString() !== currentProviderId?.toString()
  );

  useMount(() => {
    if (isNullOrUndefined(allCompanies) || !allCompanies?.length) {
      dispatch(getAllCompanies());
    }
  });

  return (
    <div className="row">
      {filteredCompanies.map((company, i) => (
        <div key={i} className={`${props.columnStyle}  wrapper`}>
          <ProvidersCard
            callBack={callBack}
            hideInformationIcon={hideInformationIcon}
            rootDivProps={{ style: { width: "100%", position: "relative" } }}
            key={company.id}
            company={company}
          />
        </div>
      ))}
      {!hideIsraeliCarriers && (
        // <div
        //   className={`${
        //     props.columnStyle === "col-lg-2 col-md-4 col-sm-6 card-sm"
        //       ? "col-lg-3 col-md-4 col-sm-6 card-sm"
        //       : props.columnStyle
        //   }  wrapper`}
        // >
        <div className={`${props.columnStyle}  wrapper`}>
          <SearchCarrier
            callBack={callBack}
            logos={filteredCompanies.map((c) => c.url)}
          />
        </div>
      )}
    </div>
  );
}

ProvidersCards.propTypes = propTypes;
ProvidersCards.defaultProps = defaultProps;

function SearchCarrier({ logos, callBack }) {
  const allCompanies = useSelector((state) => state?.companies?.allCompanies);

  const companiesLogos = logos;
  const [show, setShow] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [carrier, setCarrier] = useState(null);
  const mobileNumber = useRef();

  function toggleModal() {
    setShow((prev) => !prev);
    setCarrier(null);
    setIsLoading(false);
  }

  function getCarrierById(id) {
    if (id === "298")
      return {
        ...allCompanies[0],
        url: "https://images.celltop.vip/logo_location/rami_levi.png",
      };
    return allCompanies.find((c) => c.id === id);
  }
  function renderModal() {
    return (
      <Dialog
        open={show}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        id="carrier_check_dialog"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intlTranselate("check_carrier")}
        </DialogTitle>
        <DialogContent>
          {carrier && (
            <ProvidersCard
              mobileNumber={mobileNumber.current}
              callBack={callBack}
              hideInformationIcon={false}
              rootDivProps={{ style: { width: "100%", position: "relative" } }}
              company={carrier}
            />
          )}
          <TypeNumber
            getNumberOnSubmit={true}
            submitButtonText={intlTranselate("search")}
            isLoading={loading}
            onSubmit={async (number) => {
              mobileNumber.current = number;
              try {
                setIsLoading(true);
                const { data } = await carrierCheckApiCaller({
                  mobileNumber: number,
                });
                setCarrier(getCarrierById(data?.carrier_id));
              } catch (error) {}
              setIsLoading(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal}>{intlTranselate("Close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {renderModal()}
      <Grid
        item
        onClick={toggleModal}
        // md={3}
        // sm={6}
        // xs={12}
        sx={{
          height: "100%",
          transition: "all 0.2s ease-in-out",
          position: "relative",
          cursor: "pointer",
          "& :hover": {
            "& .card-body": {
              opacity: 0.4,
            },
            "& .svg": {
              opacity: 1,
            },
          },
        }}
      >
        <HCard
          style={{
            margin: "0px",
            // height: "calc(100% - 48px)",
            transition: "all 0.2s ease-in-out",
            border: "1px solid #25ace3",
            borderStyle: "dashed",
          }}
        >
          <HCardBody
            className="card-body"
            style={{ padding: "8px", height: "150px" }}
          >
            <img
              src="/companies.png"
              alt="logo"
              style={{ width: "200px", height: "200px" }}
            />
          </HCardBody>
          <SearchIcon
            className="svg"
            sx={{
              fontSize: 120,
              color: "primary.main",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0,
              zIndex: 2,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
          <Div
            sx={{
              margin: 0,
              backgroundColor: "#25ace3",
              color: "white",
              // height: "80px",
              borderRadius: "6px",
              verticalAlign: "middle",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "18px", padding: "4.5px 0 " }}>
              {intlTranselate("check_carrier")}
            </p>
          </Div>
        </HCard>
      </Grid>
    </>
  );
}
