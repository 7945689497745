import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ApiRequest from "../../actions/ApiRequest";
import classes from "./UploadImage.module.css";
import { Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import translate from "i18n/translate";
import { set } from "lodash";
import Notiflix from "notiflix";
// import { set } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "70%", md: "50%", lg: "40%" },

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  borderColor: "white",
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    gap: theme.spacing(1),
    color: "red",
    fontWeight: "bold",
  },
}));

const ImageModal = ({
  openModal,
  setOpenModal,
  number,
  transId,
  paltelNumber,
}) => {
  number = "0" + [...number].slice(3).join("");
  //   console.log("number", number, transId, paltelNumber);
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CircularIntegration
          setOpenModal={setOpenModal}
          number={number}
          transId={transId}
          paltelNumber={paltelNumber}
        />
      </Box>
    </Modal>
  );
};

function CircularIntegration({
  number,
  url,
  setOpenModal,
  transId,
  paltelNumber,
}) {
  //   const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imageChanged, setImageChanged] = useState(url);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSuccess(false);
    setLoading(true);
    const reader = new FileReader();
    setTimeout(() => {
      reader.onloadend = () => {
        setImageChanged(reader.result);
        setLoading(false);
        setSuccess(true);
      };
      reader.readAsDataURL(file);
    }, 1000);

    // console.log("event", event.target.files[0]);
    // setTimeout(() => {
    //   setImageChanged(event.target.files[0]);
    //   setLoading(false);
    //   setSuccess(true);
    // }, 2000);

    // const imageRef = ref(
    //   storage,
    //   `images/${event.target.files[0].name + v4()}`
    // );
    // uploadBytes(imageRef, event.target.files[0]).then((snapshot) => {
    //   getDownloadURL(snapshot.ref).then((url) => {
    //     setImageChanged(url);
    //     setLoading(false);
    //     setSuccess(true);
    //     onChange(url);
    //   });
    // });
  };
  const handleSubmit = async (event) => {
    setOpenModal(false);
    event.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile, `${transId}.jpg`);

      try {
        const response = await ApiRequest.post(
          `send_whatsapp_image?mobile_number=${number}&transId=${transId}&number=${paltelNumber}`,
          formData
        );

        // Handle the response from the Flask API
        if (response.ok) {
          Notiflix.Notify.success("Image uploaded successfully");
          // Perform any necessary actions after successful upload
        } else {
          throw new Error("Image upload failed");
          // Handle the failed upload case
        }
      } catch (error) {
        Notiflix.Notify.failure("Error occurred during image upload");
        // console.log("Error occurred during image upload", error);
        // Handle the error case
      }
    }
  };

  return (
    <Box
      //   sx={{ display: "flex", }}
      className={classes.root}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab
            aria-label="save"
            color="primary"
            sx={buttonSx}
            // onClick={handleButtonClick}
            component="label"
          >
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={handleFileInput}
            />
            {success ? <CheckIcon /> : <PhotoCamera />}
          </Fab>
          {loading && (
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: "absolute",
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          )}
        </Box>
        {imageChanged && (
          <img src={imageChanged} alt="product" className={classes.image} />
        )}
        {!imageChanged && (
          <div className={classes.fakeImage}>{translate("Upload Image")}</div>
        )}
      </Box>
      {imageChanged && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {translate(
              "Are you sure you want to upload this image to this number"
            )}{" "}
            {number}
            {" ?"}
          </Typography>
        </Box>
      )}

      {imageChanged && (
        <Box>
          <Button
            variant="outlined"
            onClick={() => setOpenModal(false)}
            sx={{ ml: "5px", mr: "5px" }}
            color="error"
          >
            {translate("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            // disabled={isConfirmDisabled}
            sx={{ ml: "5px", mr: "5px" }}
          >
            {translate("Confirm")}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default ImageModal;
