import React, { useEffect, useState } from "react";
import translate from "../../i18n/translate";
import { connect } from "react-redux";
import "./sidebar.css";
import { currentRates } from "../../actions/currencyAction";
import _ from "lodash";
import Toolbar from "@mui/material/Toolbar";

import { Blink } from "@bdchauvette/react-blink";
import { dataLanguage } from "../../i18n/translateRequest";

const RightSideBar = ({ user, userData, isDarktheme }) => {
  const [rates, setRates] = useState([]);
  const [loading, isLoading] = useState(false);
  const screenWidth = window.innerWidth;

  useEffect(() => {
    updateCurrencyRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCurrencyRate = () => {
    isLoading(true);
    currentRates().then((rates) => {
      rates = [
        rates[0],
        rates[1],
        rates[2],
        rates[6],
        rates[7],
        rates[3],
        rates[4],
        rates[5],
      ];
      setRates(rates);
      isLoading(false);
    });
  };

  return (
    <div className="col-lg-2 col-md-3 col-sm-6">
      <div className="card card-currency">
        <div className={!isDarktheme ? "card-body" : "dark-card-body"}>
          {!loading && (
            <>
              <h6
                style={{
                  fontSize: ".8rem",
                }}
              >
                {translate("Currency exchange rates")}
              </h6>
              <hr className="divider my-1"></hr>
              <table className="currecy-rates mt-2">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: ".7rem",
                      }}
                    >
                      {translate("Currency")}
                    </th>
                    <th
                      style={{
                        fontSize: ".7rem",
                      }}
                    >
                      {translate("Price")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rates.map((rate, index) => (
                    <tr key={index.toString()}>
                      <td>
                        <Toolbar
                          title={dataLanguage(
                            rate,
                            "ar_des",
                            "en_des",
                            "he_des"
                          )}
                          style={{
                            minHeight: "auto",
                            padding: "0",
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: ".7rem",
                          }}
                        >
                          {/* {dataLanguage(rate, "ar_des", "en_des", "he_des")} /{" "}
                          {translate("ILS")} */}
                          {/* {rate.currency === "XAU" ||
                          rate.currency === "XAG" ||
                          rate.currency === "BTC" */}
                          {index > 4
                            ? translate(_.trim(rate.currency) + " / USD")
                            : translate(_.trim(rate.currency) + " / ILS")}
                        </Toolbar>
                      </td>
                      <td style={{ fontSize: ".7rem" }}>{rate.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {loading && (
            <div>
              <Blink>
                <div
                  style={{
                    height: 5,
                    width: 5,
                    borderWidth: 1,
                    borderRadius: 50,
                    borderColor: "red",
                    backgroundColor: "red",
                    borderStyle: "solid",
                  }}
                />
              </Blink>
            </div>
          )}
        </div>
      </div>
      {screenWidth > 768 && (
        <div
          className="add-banner"
          style={{
            background: `url("${sessionStorage.getItem("rightImage")}")`,
          }}
        ></div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  auth: { user, userData },
  darkTheme: { isDarktheme },
}) => ({
  user,
  userData,
  isDarktheme,
});

export default connect(mapStateToProps, {})(RightSideBar);
