import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import "./style.css";
import { getDiscounts } from "../../../actions/discountsAction";
import Spinner from "../../ui/spinner/Spinner";
import { isArray } from 'lodash';

const Discounts = ({ discounts, getDiscounts, isDarktheme }) => {
  const hide = localStorage.getItem('hide companies')

  const [loading, isLoading] = useState(false);
  const [currentPageContent, setCurrentPageContent] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [selectedButtom, setSelectedButtom] = useState(0)
  const [discountData, setDiscountData] = useState([])

  useEffect(() => {
    document.title = "Seller Points | PhonePlay ";
    isLoading(true)
    getDiscounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isArray(discounts) && discounts) {
      let discountsArr = discounts.map((discount) => {
        return {
          company: discount.product,
          id: discount.id,
          discountPer: discount.discount,
          active: discount.active,
          location: discount.location
        }
      })
      if (hide === "true") {
        discountsArr = discountsArr.filter((item) => item.location !== "ISR")
      }

      setDiscountData(discountsArr)
      getPageContent(1, discountsArr)
      getPagesNumbers(discountsArr)
      isLoading(false)
    }
    isLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discounts])
  const getPageContent = (pageNumber, data) => {
    const temp = data.length > 0 ? [...data] : []
    let currentContent = temp?.slice(pageNumber * 10 - 10, pageNumber * 10)
    setCurrentPageContent([...currentContent])
  }

  const getPagesNumbers = (data) => {
    let pagesCount = Math.ceil(data?.length / 10);

    let buttons = []
    for (let i = 1; i <= pagesCount; i++) {
      buttons.push({ index: i })
    }
    setButtons(buttons)
  }

  return (
    <>
      <div className="discounts-page">
        <div>
          <div className={!isDarktheme ? "row table-wrapper" : "row dark-table-wrapper"}>
            <div className='table-title'>
              <h5>{translate("Discount Table")}</h5>
            </div>
            {currentPageContent.length !== 0 && <div style={{ minHeight: 505, padding: 0 }}>
              <table>
                <thead>
                  <tr>
                    <th>{translate('Provider')}</th>
                    <th>{translate('discount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageContent.map((item, index) => (
                    <tr key={index}>
                      <td>{item.company}</td>
                      <td>%{item.discountPer}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>}
            {/* {currentPageContent.length === 0 && <div style={{ marginRight: 32, paddingTop: 32 }}>{translate('No data to show')}</div>} */}

            <div className='row mt-4 pagging'>
              {buttons?.map((page, index) =>
                <button key={index.toString()} onClick={() => {
                  getPageContent(index + 1, discountData)
                  setSelectedButtom(index, discountData)
                }}
                  id="page-number"
                  className={`${index === selectedButtom ? "selected-btn" : ""}`}
                >{index + 1}</button>
              )}
            </div>
            <div>
              {/* {sellerPoints[0].sellerPoints} مجموع النقاط */}
            </div>
          </div>
        </div>
        {loading && (<Spinner />)}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
  isDarktheme: state.darkTheme.isDarktheme
});

export default connect(mapStateToProps, { getDiscounts })(Discounts);

