import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import PageTitle from "../common/PageTitle";
import Spinner from "../ui/spinner/Spinner";
import DenominationCard from "./DenominationCard";

import { getCompaniesCards } from "../../actions/cardsAction";
import { refreshColumnStyle } from "../Gaming/reusableFunctions";

import "./styles.css";
import { useParams } from "react-router";

// import translate from "../../i18n/translate";

const GiftCardDenominations = ({
  match,
  location,
  getCompaniesCards,
  companyCardsLoading,
  companyCards,
}) => {
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 card-md"
  );

  // let cardTitle = location.state.cardTitle
  const { brand_name } = useParams();

  useEffect(() => {
    document.title = "Cards | Phone Play";
    refreshColumnStyle(setColumnStyle);
    getCompaniesCards(brand_name);
    // console.log("...params", getCompaniesCards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand_name]);
  return (
    <>
      <PageTitle title={`${brand_name} Cards`} selectText="Select A Card">
        <div className="card card-home"></div>
        <div className="row mb-5">
          {companyCards.length &&
            companyCards?.map((card, i) => (
              <DenominationCard key={i} card={card} columnStyle={columnStyle} />
            ))}
        </div>
      </PageTitle>
      {companyCardsLoading && <Spinner />}
    </>
  );
};

const mapStateToProps = ({ cards: { companyCards, companyCardsLoading } }) => ({
  companyCards,
  companyCardsLoading,
});

export default connect(mapStateToProps, { getCompaniesCards })(
  GiftCardDenominations
);
