import React from "react";
import translate from "../../../../i18n/translate";
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router'

const options = [
  { value: 'default', label: translate('Default') },
  { value: 'column3', label: translate('column3') },
  { value: 'column4', label: translate('column4') },
  { value: 'column6', label: translate('column6') },
];

const SizeChooser = ({ type = "dropdown" }) => {
  const history = useHistory()
  let size = (localStorage.size || "default")

  const _onSelectSize = (e) => {
    let newSize = e.value;
    if (e.target) {
      newSize = e.target.value;
    }
    localStorage.setItem("size", newSize);
    history.go(0)
  }

  return (
    <>
      {type === "dropdown" && (
        <Dropdown
          options={options}
          onChange={_onSelectSize}
          value={size}
          placeholder="Size"
          className='style1' />
      )}
      {type === "radio" && (
        <div className="size-options">
          {options.map((op, index) => (
            <div key={index.toString()} className="form-check">
              <input className="form-check-input"
                type="radio"
                name="size"
                value={op.value}
                id={op.value}
                checked={size === op.value}
                onChange={_onSelectSize}
              />
              <label className="form-check-label" htmlFor={op.value}>
                {op.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default SizeChooser;