import React, { useState } from 'react'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField';
import Button from '../../common/Button'
import ReactFlagsSelect from "react-flags-select";
import translate from "../../../i18n/translate";

import styles from "./styles.css"
import { saveTranslationData } from '../../../actions/settingsActions';



const TranslateSettings = ({ saveTranslationData, isDarktheme }) => {
    const [translateUsData, setTranslateUsData] = useState({
        dbName: '',
        keyField: '',
        sourceFieldName: '',
        targetFieldName: '',
        TargetLanguage: 'ar'
    })
    const [savedTranslateUsData, setSavedTranslateUsData] = useState({})
    const [formErrors, setFormErrors] = useState({
        dbName: '',
        keyField: '',
        sourceFieldName: '',
        targetFieldName: '',
        TargetLanguage: ''
    })

    const [translationLoading, setTranslationLoading] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const onChange = (e) => {
        const { name, value } = e.target
        setTranslateUsData({
            ...translateUsData,
            [name]: value
        })

        setFormErrors({
            ...formErrors,
            [name]: ''
        })
    }

    const validate = data => {
        let valid = true
        const errors = {
            dbName: '',
            keyField: '',
            sourceFieldName: '',
            targetFieldName: '',
            TargetLanguage: ''
        }

        if (data.dbName === "") {
            errors.dbName = translate('Please Enter Your DB Name')
            valid = false
        }
        if (data.keyField === "") {
            errors.keyField = translate('Please Enter Your key Field')
            valid = false
        }
        if (data.sourceFieldName === "") {
            errors.sourceFieldName = translate('Please Enter Your source Field Name')
            valid = false
        }
        if (data.targetFieldName === "") {
            errors.targetFieldName = translate('Please Enter Your target Field Name')
            valid = false
        }
        if (data.TargetLanguage === "") {
            errors.TargetLanguage = translate('Please select Your Target Language')
            valid = false
        }
        return {
            valid,
            errors
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault()
        const { valid, errors } = validate(translateUsData)
        if (!valid) {
            setFormErrors(errors)
            return
        }
        setTranslationLoading(true)
        const dataSaved = await saveTranslationData(translateUsData)
        if (dataSaved) {
            setSubmitSuccess(true)
        }
        setTranslationLoading(false)
        setSavedTranslateUsData(translateUsData)
        setTranslateUsData({
            dbName: '',
            keyField: '',
            sourceFieldName: '',
            targetFieldName: '',
            TargetLanguage: 'ar'
        })
    }

    return (
        <div className={!isDarktheme ? 'd-flex flex-column align-items-center justify-content-center mt-6' : 'd-flex flex-column align-items-center justify-content-center mt-6 dark-translate'}>
            <h2>Translate</h2>
            <form className='contact-us mt-4 ' onSubmit={onSubmit}>
                <TextField
                    sx={!isDarktheme ? '' : { background: 'white', paddingLeft: '5px' }}
                    className={!isDarktheme ? "" : "dark-text-feild"}
                    variant="standard"
                    label={translate('Enter your DB name')}
                    name='dbName'
                    type='dbName'
                    value={translateUsData.dbName}
                    onChange={onChange}
                    error={formErrors.dbName}
                    helperText={formErrors.dbName}
                />
                <TextField
                    sx={!isDarktheme ? '' : { background: 'white', paddingLeft: '5px' }}
                    variant="standard"
                    label={translate('Enter the key Field')}
                    name='keyField'
                    value={translateUsData.keyField}
                    onChange={onChange}
                    error={formErrors.keyField}
                    helperText={formErrors.keyField}

                />
                <TextField
                    sx={!isDarktheme ? '' : { background: 'white', paddingLeft: '5px' }}
                    variant="standard"
                    label={translate('Enter the Source Field Name')}
                    name='sourceFieldName'
                    value={translateUsData.sourceFieldName}
                    onChange={onChange}
                    error={formErrors.sourceFieldName}
                    helperText={formErrors.sourceFieldName}

                />
                <TextField
                    sx={!isDarktheme ? '' : { background: 'white', paddingLeft: '5px' }}
                    variant="standard"
                    label={translate('Enter the target Field Name')}
                    name='targetFieldName'
                    value={translateUsData.targetFieldName}
                    onChange={onChange}
                    error={formErrors.targetFieldName}
                    helperText={formErrors.targetFieldName}

                />
                <ReactFlagsSelect
                    className={!isDarktheme ? "" : "dark-text-feild"}
                    countries={["PS", "US", "IL"]}
                    selected={translateUsData.TargetLanguage === "en" ? "US" : translateUsData.TargetLanguage === "ar" ? "PS" : "IL"}
                    customLabels={{ US: "English", PS: "Arabic", IL: "Hebrew" }}
                    onSelect={(language) => {
                        let selectedLan = ""
                        if (language === "US") {
                            selectedLan = "en";
                        } else if (language === "PS") {
                            selectedLan = "ar";
                        } else {
                            selectedLan = "he";
                        }
                        setTranslateUsData({
                            ...translateUsData,
                            TargetLanguage: selectedLan
                        })
                    }}
                    selectedSize={14}
                    optionsSize={14}
                    showLabal={true}
                />
                <Button
                    type='dark'
                    color="white"
                    style={{ width: '100%' }}
                    className='mt-4'
                    title='submit'
                    formType='submit'
                    loading={translationLoading}
                />

            </form>
            {
                submitSuccess && (<div style={{ marginTop: '15px' }}>
                    <p style={{ marginLeft: '15px' }}>{translate(" your submitted data are:")} </p>
                    <ul className='submited-data-list'>
                        <li>
                            {translate("DB Name:")} {savedTranslateUsData.dbName}
                        </li>
                        <li>
                            {translate("key Field:")} {savedTranslateUsData.keyField}
                        </li>
                        <li>
                            {translate("Source Field Name:")} {savedTranslateUsData.sourceFieldName}
                        </li>
                        <li>
                            {translate("Target Field Name:")} {savedTranslateUsData.targetFieldName}
                        </li>
                        <li>
                            {translate("Selected Language:")} {savedTranslateUsData.TargetLanguage === 'en' ? "English" : savedTranslateUsData.TargetLanguage === 'ar' ? "Arabic" : "Hebrew"}
                        </li>
                    </ul>
                </div>
                )
            }
        </div>
    )
}
const mapStateTpProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}

export default connect(mapStateTpProps, { saveTranslationData })(TranslateSettings)
