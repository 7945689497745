import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TextFieldGroup from "./../../common/TextFieldGroup";
import "./ooredoo.css";
import credits, { EMPTY_CREDIT } from "./credits";
import SubNavOoredoo from "./SubNavOoredoo";
import Selected from "./Selected";
import CompaniesNav from "../../common/CompaniesNav";
import logo from "../../../assests/images/bundles/ooredoo/banner.jpg";
import { jsonParser } from "shared/utils/ppUtils";

const CreditOoredoo = ({ auth, loading }) => {
  const history = useHistory().location.pathname;
  const mobileNo = history.split("/")[4];
  const state = useHistory().location.state;

  const [price, setPrice] = useState(null);
  const [g3, setG3] = useState("");
  const [min, setMin] = useState("");
  const [credit, setCredit] = useState("");
  const [rom, setRom] = useState("");
  const [shabab, setShabab] = useState("");
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 col-6"
  );

  useEffect(() => {
    const handleSessionStorageChange = () => {
      document.title = "Ooredoo Credit | Phone Play";
      if (sessionStorage.ooredoo3g) {
        setG3(jsonParser(sessionStorage.ooredoo3g));
      }
      if (sessionStorage.ooredooMin) {
        setMin(jsonParser(sessionStorage.ooredooMin));
      }
      if (sessionStorage.ooredooCredit) {
        setCredit(jsonParser(sessionStorage.ooredooCredit));
      }
      if (sessionStorage.ooredooCredit) {
        setCredit(jsonParser(sessionStorage.ooredooCredit));
      }
      if (sessionStorage.ooredooRom) {
        setRom(jsonParser(sessionStorage.ooredooRom));
      }
      if (sessionStorage.ooredooSuper) {
        setShabab(jsonParser(sessionStorage.ooredooSuper));
      }
      refreshColumnStyle();
    };
    handleSessionStorageChange();

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    // Clean up the event listener
    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
  }, [mobileNo, history]);

  const onChange = (e) => {
    const value = e.target.value;
    if (value.includes(".") || value.includes(",")) {
      e.target.value = Math.floor(value);
    }
    const selectedCredit = { ...EMPTY_CREDIT, price: e.target.value };
    setPrice(e.target.value);
    setCredit(selectedCredit);
    sessionStorage.ooredooCredit = JSON.stringify(selectedCredit);
  };
  const onTypeClick = (item) => {
    sessionStorage.ooredooCredit = JSON.stringify(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    setCredit(item);
  };

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6 card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };
  let topDiv = null;
  return (
    <>
      <div ref={(ref) => (topDiv = ref)} className="ooredoo-container">
        <CompaniesNav
          providerId={
            window?.history?.state?.state?.provider_Id
              ? window?.history?.state?.state?.provider_Id
              : state?.provider_id
          }
          title="Ooredoo"
          logo={logo}
          mobile={mobileNo}
          onRefresh={() => {}}
          actionWidth="130px"
          backLink="/company/ooredoo/MobileNumer"
          comingFromReports={
            !window?.history?.state?.state?.provider_Id && state?.provider_id
          }
          credit={credit}
        />
        <div className="row">
          <div className="col-12">
            <SubNavOoredoo mobile={mobileNo} />
          </div>
        </div>
        <div className="position-relative">
          <Selected
            min={min}
            setMin={setMin}
            g3={g3}
            setg3={setG3}
            credit={credit}
            setCredit={setCredit}
            shabab={shabab}
            setShabab={setShabab}
            setRom={setRom}
            rom={rom}
          />

          <hr
            className="mt-3"
            style={{
              border: "2px solid #42ace3",
              backgroundColor: "#42ace3",
              fontWeight: "bolder",
            }}
          />

          <div className="row ">
            {credits.map((item, i) => (
              <div key={i.toString()} className={`${columnStyle} my-2`}>
                <div className="card charge-card">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onTypeClick(item)}
                  >
                    <div className="card">
                      <img alt="" src={item.url}></img>
                      {item && item.des}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className={`${columnStyle} my-2`}>
              <div className="card card-credit">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => onTypeClick({ ...EMPTY_CREDIT, price: price })}
                >
                  <div className="card">
                    <img alt="" src={EMPTY_CREDIT.url}></img>
                    <TextFieldGroup
                      style={{
                        width: "calc(50% - 20px)",
                        height: "70%",
                        padding: 0,
                        position: "absolute",
                        top: "50%",
                        left: "calc(50% + 12px)",
                        transform: "translateY(-50%)",
                        fontSize: "2rem",
                        fontFamily: '"Montserrat", sans-serif',
                        textAlign: "center",
                        outline: "rgb(16, 16, 16) auto 4px",
                        color: "#bb0d0e",
                      }}
                      name="price"
                      type="number"
                      onChange={onChange}
                      min={10}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.companies.loading,
});

export default connect(mapStateToProps)(CreditOoredoo);
