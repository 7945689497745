import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getSystemBalance } from "actions/reportsAction.js";
import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { AvatarWithTextVertical } from "core/core-components/AvatarWithText/AvatarWithText.jsx";
import { makeStyles } from "@mui/styles";
import systemBalanceStyle from "./systemBalanceStyle.js";
import { getTopUpAccounts } from "actions/sellerCreditsAction.js";
import translate from "i18n/translate.js";
import IconButton from "@mui/material/IconButton";
import ApiRequest from "../../../../actions/ApiRequest.js";
import {
  isNullOrUndefined,
  parseQueryToObjParams,
} from "../../../../shared/utils/utils.js";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { isSuccessStatus } from "../../../../shared/utils/ppUtils.js";
import Notiflix from "notiflix";
import { SYSTEM_COLORS } from "../../../../shared/constants/stylesConstants.js";
import { useMount } from "shared/hooks/ppHooks.js";
import { useStateMap } from "shared/hooks";
import { CURRENCIES_SYMBOLS } from "shared/constants/constants.js";
import { intl } from "i18n/provider.js";

const propTypes = {};
const defaultProps = {};

const useStyle = makeStyles(systemBalanceStyle);
export default function SystemBalance(props) {
  const dispatch = useDispatch();
  const classes = useStyle(props);
  const [carrierBalance, setCarrierBalance] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [sellerTopUps, setSellerTopUps] = useState([]);
  const [carrierAnchorEl, setCarrierAnchorEl] = useState(null);
  const [pubgAnchorEl, setPubgAnchorEl] = useState(null);
  const [pubgUCList, setPubgList] = useStateMap();
  const [voucher, setVoucher] = useStateMap();

  async function getBalance() {
    setIsLoading(true);

    try {
      const { data } = await getSystemBalance();
      const objKeys = Object.keys(data);
      const gamesLogoKeys = objKeys.filter((k) => k.includes("games"));
      const games = [];
      const peletalk = {
        logos: [],
        balance: 0,
        label: "",
      };
      const otherCarriers = [];
      objKeys.forEach((key) => {
        const gameKeyIndex = gamesLogoKeys.findIndex(
          (g) => g.split("_")[2] === key.toLowerCase()
        );

        if (gameKeyIndex !== -1) {
          games.push({
            balance: data[key],
            logo: data[gamesLogoKeys[gameKeyIndex]],
            label: key,
          });
        } else if (key.toLowerCase().includes("peletalk")) {
          if (key.toLowerCase().includes("logo")) {
            peletalk.logos.push(data[key]);
          } else {
            peletalk.balance = data[key];
            peletalk.label = key;
          }
        } else {
          if (key.includes("logo") && !key.includes("games")) {
            const carrierName = objKeys.find(
              (k) =>
                k.toLowerCase()?.split(" ")[0] ===
                key.split("_")[0]?.toLowerCase()
            );
            if (carrierName) {
              otherCarriers.push({
                balance: data[carrierName],
                label: carrierName,
                logo: data[key],
              });
            }
          }
        }
      });

      const voucher = window.HMap.from(data).filter((_, k) =>
        k.toLowerCase().includes("e-voucher")
      );
      voucher.set("logo", data?.peletalk_logo_pelephone);

      setPubgList(
        window.HMap.from(data).filter((_, k) =>
          k.toLowerCase().startsWith("uc")
        )
      );
      setVoucher(voucher);
      setCarrierBalance({ peletalk, others: otherCarriers, games });
    } catch (error) {
      console.log("ERROR", error);
    }

    setIsLoading(false);
  }

  useMount(getBalance);

  function renderLoader() {
    if (!isLoading) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  async function onClickPalestinianCarrier(event, carrier) {
    setIsLoadingAccounts(true);
    setCarrierAnchorEl(event.currentTarget);
    try {
      const data = await dispatch(getTopUpAccounts(carrier));
      setSellerTopUps(data);
    } catch (error) {
      console.log("onClickPalestinianCarrier", error);
    }

    setIsLoadingAccounts(false);
  }

  function renderOtherCarriers(render) {
    if (!render) return null;
    const otherCarriers = carrierBalance?.others;

    const jawwlOorredooClicks = {
      Jawwal: (e) => onClickPalestinianCarrier(e, "jawwal"),
      Ooredoo: (e) => onClickPalestinianCarrier(e, "ooredoo"),
    };

    return otherCarriers.map((carrier, i) => {
      return (
        <Grid item md={6} xs={12} key={i}>
          <AvatarWithTextVertical
            avatarProps={{ className: classes.avatarImg }}
            name={carrier.label}
            onClick={jawwlOorredooClicks[carrier.label]}
            src={carrier?.logo}
            textProps={{ className: classes.avatarText }}
          />
          <Typography className={classes.carrierBalanceText}>
            <b>{Math.round(carrier.balance)}</b>
          </Typography>
        </Grid>
      );
    });
  }

  async function onUpdateHmBalanceClick(topups) {
    if (isNullOrUndefined(topups)) return;
    const { balance, ...rest } = topups;
    setIsLoadingAccounts(true);
    try {
      const url = parseQueryToObjParams("/update_hm_balance", rest);
      const { data } = await ApiRequest.post(url);

      if (data.status !== true) {
        Notiflix.Notify.failure("Something went wrong! " + data?.reason);
      } else {
        Notiflix.Notify.success(data?.reason);
        const accounts = await dispatch(getTopUpAccounts(rest.carrier));
        setSellerTopUps(accounts);
      }
    } catch (e) {
      Notiflix.Notify.failure("Something went wrong! " + e?.message);
    }
    setIsLoadingAccounts(false);
  }

  function renderPeletalk(render) {
    if (!render) return null;
    const peletalk = carrierBalance.peletalk;

    return (
      <Grid
        item
        container
        md={12}
        xs={12}
        style={{
          borderTop: `1px solid ${SYSTEM_COLORS.gray}`,
          marginTop: "8px",
        }}
      >
        <Grid item md={6} xs={12} container>
          <Grid item container md={12} xs={12} alignItems="center">
            {peletalk.logos.map((logo, i) => (
              <Avatar key={i} src={logo} />
            ))}
          </Grid>
          <Grid item md={12} xs={12} paddingLeft={8}>
            <Typography className={classes.avatarText}>
              {peletalk.label}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} container>
            <Typography className={classes.peletalkBalanceText}>
              <b>{Math.round(peletalk.balance)}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={6} xs={12}>
          <Grid item>
            <Avatar src={voucher.get("logo")} />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography>
              Voucher Count:{" "}
              <b>{voucher.findValue((_, k) => k.includes("count"))}</b>
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} container>
            <Typography>
              Voucher Value:{" "}
              <b>{voucher.findValue((_, k) => k.includes("value"))}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderTopUpAccounts() {
    let view = null;

    if (isLoadingAccounts) {
      view = (
        <Grid width={"100%"} justifyContent={"center"} container padding={2}>
          <CircularProgress />
        </Grid>
      );
    } else if (sellerTopUps?.length) {
      view = (
        <Grid container style={{ width: "100%", padding: 8 }}>
          <Grid item md={6} xs={6}>
            <Typography>{translate("topup_account")}</Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Typography>{translate("Balance")}</Typography>
          </Grid>
          <Grid item md={2} xs={6}>
            {" "}
          </Grid>
          {sellerTopUps?.map((s, i) => (
            <React.Fragment key={i}>
              <Grid item md={6} xs={5}>
                <Typography>{s.topup_account}</Typography>
              </Grid>
              <Grid item md={4} xs={5}>
                <Typography>{Math.round(s.balance)}</Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <IconButton
                  title={"Update hm balance"}
                  onClick={() => onUpdateHmBalanceClick(s)}
                >
                  <AutorenewIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      );
    } else {
      view = (
        <Grid container padding={2}>
          <Typography>{translate("No data to show")}</Typography>
        </Grid>
      );
    }

    return (
      <Popover
        open={!!carrierAnchorEl}
        anchorEl={carrierAnchorEl}
        classes={{ paper: classes.paperStyle }}
        onClose={() => setCarrierAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {view}
      </Popover>
    );
  }

  function renderUCAmounts() {
    return (
      <Popover
        open={!!pubgAnchorEl}
        anchorEl={pubgAnchorEl}
        classes={{ paper: classes.paperStyle }}
        onClose={() => setPubgAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container padding={1}>
          <Grid item md={6} xs={6}>
            <Typography variant="h6">UC</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="h6">Value</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>
          {pubgUCList?.mapArray((v, k) => (
            <React.Fragment key={k}>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle1">{k}</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle1">
                  {CURRENCIES_SYMBOLS.usd + v}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Popover>
    );
  }

  function renderGames(render) {
    if (!render) return null;
    const games = carrierBalance.games;

    return games.map((game, i) => {
      return (
        <Grid
          item
          container
          key={i}
          md={12}
          xs={12}
          style={{
            borderTop: `1px solid ${SYSTEM_COLORS.gray}`,
            marginTop: "8px",
          }}
        >
          <Grid item md={6} xs={12}>
            <AvatarWithTextVertical
              rootGridProps={{
                style: {
                  cursor: "pointer",
                },
              }}
              avatarProps={{ className: classes.avatarImg }}
              name={game.label}
              onClick={(e) => setPubgAnchorEl(e.currentTarget)}
              src={game?.logo}
              textGridContainerProps={{
                style: { maxWidth: 64, textAlign: "center" },
              }}
              textProps={{ className: classes.gameLabel }}
            />
            <Grid item maxWidth={64} textAlign={"center"}>
              <Typography className={classes.carrierBalanceText}>
                <b>{Math.round(game.balance)}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }

  function renderTotalValues(render) {
    if (!render) return null;

    const peletalkBalance = Math.round(
      Number(carrierBalance.peletalk?.balance)
    );
    const othersBalance = Math.round(
      carrierBalance?.others
        ?.map((o) => Number(o.balance))
        ?.reduce((a, b) => a + b, 0)
    );
    const voucherValue = Math.round(
      Number(voucher.findValue((_, k) => k.includes("value")))
    );
    const gamesBalanceUsd = Math.round(
      Number(
        carrierBalance?.games?.map((o) => o.balance)?.reduce((a, b) => a + b, 0)
      )
    );

    return (
      <Grid container>
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12} container padding={2}>
          <Grid item md={6} xs={6}>
            <Typography>
              {intl.formatMessage({ id: "the_sum" }) + " USD"}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography>
              {intl.formatMessage({ id: "the_sum" }) + " ILS"}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography>
              <b>{CURRENCIES_SYMBOLS.usd + gamesBalanceUsd}</b>
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography>
              <b>
                {CURRENCIES_SYMBOLS.ils +
                  (peletalkBalance + othersBalance + voucherValue)}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      {renderTopUpAccounts()}
      {renderUCAmounts()}
      <Grid
        container
        justifyContent="center"
        style={{ padding: 48 }}
        spacing={2}
      >
        {renderLoader()}
        {renderOtherCarriers(!!carrierBalance)}
        {renderPeletalk(!!carrierBalance)}
        {renderGames(!!carrierBalance)}
        {renderTotalValues(!!carrierBalance)}
      </Grid>
    </React.Fragment>
  );
}

SystemBalance.propTypes = propTypes;
SystemBalance.defaultProps = defaultProps;
