import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import EditAccount from "./EditAccount";
import { Box, CircularProgress, Grid } from "@mui/material";
import Notiflix from "notiflix";
import { parseQueryToObjParams } from "shared/utils/utils";
import ApiRequest from "actions/ApiRequest";

export default function EditSettings() {
  const history = useHistory();
  const setting = history.location?.state?.data;
  const carrier = history.location?.state?.carrier;
  const isAccountActive = history.location?.state?.active;
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    if (setting) {
      setSettings(setting);
    }
  }, [setting]);
  const checkboxData = useMemo(() => {
    if (!settings) return [];
    return [
      {
        desc: "topup",
        variable: settings["topup"] === "true",
        aKey: "topup",
        checkbox: true,
      },
      {
        desc: "Min",
        variable: settings["min"] === "true",
        aKey: "min",
        checkbox: true,
      },
      {
        desc: "3G",
        variable: settings["3g"] === "true",
        aKey: "3g",
        checkbox: true,
      },
      {
        desc: "youth",
        variable: settings["youth"] === "true",
        aKey: "youth",
        checkbox: true,
      },
      {
        desc: "rom",
        variable: settings["rom"] === "true",
        aKey: "rom",
        checkbox: true,
      },
      {
        desc: "Jawwal Invoice",
        variable: settings["jawwal_invoice"] === "true",
        aKey: "jawwal_invoice",
        checkbox: true,
      },
      {
        desc: "nedco",
        variable: settings["nedco"] === "true",
        aKey: "nedco",
        checkbox: true,
      },
      {
        desc: "Enabled",
        variable: settings["enabled"] === "true",
        aKey: "enabled",
        checkbox: true,
      },
      {
        desc: "headless",
        variable: settings["headless"] === "true",
        aKey: "headless",
        checkbox: true,
      },
      {
        desc: "incognito",
        variable: settings["incognito"] === "true",
        aKey: "incognito",
        checkbox: true,
      },
      {
        desc: "Busy",
        variable: settings["busy"] === "true" || settings["busy"] == "1",
        aKey: "busy",
        checkbox: true,
      },
      {
        desc: "ooredoo Invoice",
        variable: settings["ooredoo_invoice"] === "true",
        aKey: "ooredoo_invoice",
        checkbox: true,
      },
    ];
  }, [settings]);

  const data = useMemo(() => {
    if (!settings) return [];
    return [
      {
        desc: "User Name",
        variable: settings["user_name"],
        aKey: "user_name",
        checkbox: false,
      },

      {
        desc: "Password",
        variable: settings["pass"],
        aKey: "pass",
        checkbox: false,
      },
      {
        desc: "Balance",
        variable: settings["balance"],
        aKey: "balance",
        checkbox: false,
      },
      {
        desc: "Type",
        variable: settings["type"],
        aKey: "type",
        checkbox: false,
      },
      {
        desc: "Session ID",
        variable: settings["session_id"],
        aKey: "session_id",
        checkbox: false,
      },
      {
        desc: "Number",
        variable: settings["number"],
        aKey: "number",
        checkbox: false,
      },
      {
        desc: "3G Driver",
        variable: settings["[3g-driver]"],
        aKey: "3g-driver",
        checkbox: false,
      },

      {
        desc: "Jawwal Invoice Driver",
        variable: settings["[jawwal_invoice-driver]"],
        aKey: "jawwal_invoice-driver",
        checkbox: false,
      },
      {
        desc: "Min Driver",
        variable: settings["[min-driver]"],
        aKey: "min-driver",
        checkbox: false,
      },
      {
        desc: "Nedco Driver",
        variable: settings["[nedco-driver]"],
        aKey: "nedco-driver",
        checkbox: false,
      },
      {
        desc: "Rom Driver",
        variable: settings["[rom-driver]"],
        aKey: "rom-driver",
        checkbox: false,
      },
      {
        desc: "Topup Driver",
        variable: settings["[topup-driver]"],
        aKey: "topup-driver",
        checkbox: false,
      },
      {
        desc: "Youth Driver",
        variable: settings["[youth-driver]"],
        aKey: "youth-driver",
        checkbox: false,
      },
      {
        desc: "Account Type",
        variable: settings["account_type"],
        aKey: "account_type",
        checkbox: false,
      },

      {
        desc: "ccID",
        variable: settings["ccid"],
        aKey: "ccid",
        checkbox: false,
      },
      {
        desc: "DATE Password",
        variable: settings["date_password"],
        aKey: "date_password",
        checkbox: false,
      },

      {
        desc: "ID",
        variable: settings["id"],
        aKey: "id",
        checkbox: false,
      },
      {
        desc: "imei",
        variable: settings["imei"],
        aKey: "imei",
        checkbox: false,
      },
      {
        desc: "imsi",
        variable: settings["imsi"],
        aKey: "imsi",
        checkbox: false,
      },

      {
        desc: "location",
        variable: settings["location"],
        aKey: "location",
        checkbox: false,
      },

      {
        desc: "port",
        variable: settings["port"],
        aKey: "port",
        checkbox: false,
      },

      {
        desc: "Sub provider",
        variable: settings["subprovider"],
        aKey: "subprovider",
        checkbox: false,
      },

      // ... other non-boolean fields here

      // ... other boolean fields here
    ];
  }, [settings]);

  const handleUpdateBalance = async () => {
    setIsLoading(true);
    const rest = {
      carrier,
      topup_account: settings["user_name"],
    };
    try {
      const url = parseQueryToObjParams("/update_hm_balance", rest);
      const { data } = await ApiRequest.post(url);
      if (data.status !== true) {
        Notiflix.Notify.failure("Something went wrong! " + data?.reason);
      } else {
        Notiflix.Notify.success(data?.reason);
        const res = await ApiRequest.post(
          `edit_topup_account?operation=get&carrier=jawwal&user_name=${settings["user_name"]}`
        );
        setSettings(res.data[0]);
      }
    } catch (e) {
      Notiflix.Notify.failure("Something went wrong! " + e?.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <h1 style={!isAccountActive ? { color: "red" } : {}}>Edit Account</h1>
      {isLoading && (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      )}
      {!settings ? (
        <h2 style={{ color: "red" }}>
          No Accounts found for this carrier. Please add them first.
        </h2>
      ) : (
        <>
          <Box
            sx={{
              margin: "20px auto",
              padding: "30px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <Grid container>
              {checkboxData.map((item) => (
                <EditAccount
                  desc={item.desc}
                  variable={item.variable}
                  checkbox={item.checkbox}
                  aKey={item.aKey}
                  userName={settings["user_name"]}
                  carrier={carrier}
                  isAccountActive={isAccountActive}
                />
              ))}
            </Grid>
          </Box>
          <Grid container>
            {data.map((item) => (
              <EditAccount
                desc={item.desc}
                variable={item.variable}
                checkbox={item.checkbox}
                aKey={item.aKey}
                userName={settings["user_name"]}
                carrier={carrier}
                onUpdateBalance={handleUpdateBalance}
                isAccountActive={isAccountActive}
              />
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}
