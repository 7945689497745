import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getJawwalPlus } from "../../../actions/companiesAction";

import CompaniesNav from "../../common/CompaniesNav";
import SubNav from "./SubNav";
import Selected from "./Selected";

import logo from "../../../assests/images/bundles/jawwal/banner.jpg";
import JawwalPlusCard from "./JawwalPlusCard";
import CompaniesSkeleton from "../../homePage/CompaniesSkeleton";
import translate from "../../../i18n/translate";
import { jsonParser } from "shared/utils/ppUtils";

const JawwalPlus = ({ getJawwalPlus, JawwalPlus, JawwalPlusLoading }) => {
  const history = useHistory().location.pathname;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [selected, setSelected] = useState({});
  const [jawwal3g, setJawwal3g] = useState("");
  const [jawwalRom, setJawwalRom] = useState("");
  const [jawwalMin, setJawwalMin] = useState("");
  const [credit, setCredit] = useState("");
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 col-6"
  );

  let routerMobileNo = history.split("/")[3];
  let mobileNo =
    history.split("/")[3].slice(0, 3) +
    "-" +
    history.split("/")[3].slice(3, 6) +
    "-" +
    history.split("/")[3].slice(6, 10);

  useEffect(() => {
    const number = params.get("number");
    const mobileNumber = number || mobileNo;
    getJawwalPlus(mobileNumber, false);
    const handleSessionStorageChange = () => {
      document.title = "Jawwal Plus | Phone Play";
      if (sessionStorage.JawwalMin) {
        setJawwalMin(jsonParser(sessionStorage.JawwalMin));
      }
      if (sessionStorage.Jawwal3g) {
        setJawwal3g(jsonParser(sessionStorage.Jawwal3g));
      }
      if (sessionStorage.JawwalCredit) {
        setCredit(jsonParser(sessionStorage.JawwalCredit));
      }
      if (sessionStorage.JawwalRom) {
        setJawwalRom(jsonParser(sessionStorage.JawwalRom));
      }
      if (sessionStorage.JawwalPlus) {
        setSelected(jsonParser(sessionStorage.JawwalPlus));
      }
      refreshColumnStyle();
    };
    handleSessionStorageChange();

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onTypeClick = (item) => {
    sessionStorage.JawwalPlus = JSON.stringify(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    setSelected(item);
  };

  let topDiv = null;

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6 card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };

  return (
    <div ref={(ref) => (topDiv = ref)} className="mb-5">
      <div className="card card-home">
        <CompaniesNav
          title="Jawwal"
          providerId={window?.history?.state?.state?.provider_Id}
          logo={logo}
          mobile={routerMobileNo}
          onRefresh={() => getJawwalPlus(mobileNo, true)}
          actionWidth="130px"
          backLink="/company/jawwalNo"
        />
        <div className="row">
          <div className="col-12">
            <SubNav mobile={history.split("/")[3]} />
          </div>
        </div>
      </div>
      <div className="position-relative">
        <Selected
          min={jawwalMin}
          setMin={setJawwalMin}
          g3={jawwal3g}
          setg3={setJawwal3g}
          credit={credit}
          setCredit={setCredit}
          rom={jawwalRom}
          setRom={setJawwalRom}
          setPlus={setSelected}
          plus={selected}
        />
      </div>
      <div className="row">
        {JawwalPlusLoading &&
          [...Array(6)].map((e, i) => (
            <CompaniesSkeleton height={130} columnStyle={columnStyle} key={i} />
          ))}
        {JawwalPlus.length === 0 && !JawwalPlusLoading ? (
          <div className="d-flex justify-content-center mt-3">
            <h2 className="text-info">{translate("No available bundles")}</h2>
          </div>
        ) : (
          JawwalPlus.map((bundle) => (
            <JawwalPlusCard
              onTypeClick={onTypeClick}
              columnStyle={columnStyle}
              key={bundle.PCI}
              bundle={bundle}
            />
          ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ companies: { JawwalPlus, JawwalPlusLoading } }) => {
  return {
    JawwalPlus,
    JawwalPlusLoading,
  };
};

export default connect(mapStateToProps, { getJawwalPlus })(JawwalPlus);
