import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Select from 'react-select';
import Spinner from "../../ui/spinner/Spinner";
import translate from '../../../i18n/translate'
import Modal from '../../common/Modal'
import TextFieldGroup from '../../common/TextFieldGroup'
import Button from '../../common/Button'
import Toast from '../../common/Toast';
import Notiflix from "notiflix";

import { submitInsurance } from "../../../actions/insuranceAction";
import { intl } from '../../../i18n/provider';

const InsuranceCard = ({ columnStyle,
    insurance,
    loadVehicleData,
    handleVehicleDataChange,
    submitInsuranceLoading,
    driverType,
    engineSize,
    ton,
    passenger,
    vehiclePrice,
    insuranceType,
    total,
    submitInsurance,
    handleReset, isDarktheme }) => {

    const [submitInsuranceModal, setSubmitInsuranceModal] = useState(false)
    const [steps, setSteps] = useState(1)
    const [modalYears, setModalYears] = useState(null)
    const [cars, setCars] = useState([])
    const [carModal, setCarModal] = useState([])
    const [successModal, setSuccessModal] = useState(false)
    const [noteMessage, setNoteMessage] = useState('')
    const [ticketNo, setTicketNo] = useState('')
    const [image, setImage] = useState({
        front: '',
        back: '',
        left: '',
        right: ''
    });
    const [loading, isLoading] = useState(false);
    const [imageAlt, setImageAlt] = useState("");
    const inputFile = useRef(null);
    const [imageValidation, setImageValidation] = useState({})
    const carsJSON = require('./cars.json');

    useEffect(() => {
        yearModalsYears()
        checkCurrentTime()
        getCars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkCurrentTime = () => {
        const currentDate = new Date();
        const currentHour = currentDate.getHours()

        const currentDay = currentDate.getDay()
        if (currentHour > 13 || currentHour < 8 || currentDay > 4) {
            setNoteMessage(intl.formatMessage({ id: "The insurance will be in the next working days (Sunday to Thursday), and customer will be notified" }))
            return
        }
        setNoteMessage(intl.formatMessage({ id: "The insurance will be ready after approximately 1 hour, and customer will be notified" }))
    }

    const yearModalsYears = () => {
        let yearArr = []
        let minYear = 1941
        let maxYear = new Date().getFullYear()

        for (let i = maxYear; i >= minYear; i--) {
            yearArr.push({ value: i, label: `${i}` })
        }
        setModalYears(yearArr)

    }

    const handleYearChange = (objValue, action) => {
        let event = { target: {} }
        event.target = {
            name: action.name,
            value: objValue.value
        }
        handleVehicleDataChange(event)
    }

    const handleCarChange = (objValue, action) => {
        let event = { target: {} }
        event.target = {
            name: action.name,
            value: objValue.value
        }
        handleVehicleDataChange(event)
        getCarsModels(objValue.value)
    }

    const handleModalChange = (objValue, action) => {
        let event = { target: {} }
        event.target = {
            name: action.name,
            value: objValue.value
        }
        handleVehicleDataChange(event)
    }

    const getCars = () => {
        let carsBrand = []
        carsJSON.forEach((car) => {
            carsBrand.push({ label: car.brand, value: car.brand })
        })
        setCars(carsBrand)
    }

    const getCarsModels = (make) => {
        let carsModel = carsJSON.filter((car) => car.brand === make)
        let carModels = []
        carsModel[0].models.forEach((model) => {
            carModels.push({ label: model, value: model })
        })
        setCarModal(carModels)
    }

    const handleNextStep = () => {
        if (!firstStepDisable()) {
            setSteps((prevStep) => prevStep + 1)
            return
        }
        // TODO: set error message
    }

    const handleBackStep = () => {
        setSteps((prevStep) => prevStep - 1)
    }

    const handleModalClose = () => {
        setSteps(1)
        setSubmitInsuranceModal(false)
    }

    const firstStepDisable = () => {
        if (loadVehicleData.identityNumber.length < 5 || loadVehicleData.mobileNumber.length < 16
            || loadVehicleData.FamilyName.length < 3 || loadVehicleData.ThirdName.length < 3 || loadVehicleData.SecondName.length < 3 || loadVehicleData.firstName.length < 3) {
            return true
        }
        return false
    }

    const handleInsuranceSubmit = (e, provider_id, provider) => {
        e.preventDefault()
        const data = {
            id: loadVehicleData.identityNumber,
            name: `${loadVehicleData.firstName} ${loadVehicleData.SecondName} ${loadVehicleData.ThirdName} ${loadVehicleData.FamilyName}`,
            mobile: `${loadVehicleData.mobileNumber}`,
            v_number: `${loadVehicleData.vehicleNumber}`,
            v_type: `${loadVehicleData.vehicleType}`,
            v_model: `${loadVehicleData.vehicleModel}`,
            prod_year: `${loadVehicleData.productionYear}`,
            amount: total,
            insurance_type: insuranceType.value,
            provider_id,
            provider,
            ecp: vehiclePrice,
            url: `${insurance.url}`,
            front: image.front,
            back: image.back,
            left: image.left,
            right: image.right,
        }
        submitInsurance(data).then((res) => {

            setSubmitInsuranceModal(false)
            if (res.status === "failed") {
                Toast.fire({
                    title: res.reason,
                    icon: "error",
                });
                return
            }
            setTicketNo(res?.insurance_id)
            setSuccessModal(true)
        })
    }

    const successModalClose = () => {
        setSuccessModal(false)
        setImageValidation({})
        handleReset()
    }

    const handleFileUpload = (e) => {
        let { files } = e.target;

        if (!validateImages(files[0])) {

            e.target.value = null
            Notiflix.Report.warning(`Please Pick up a new picture`, '', 'Close')
            return
        }

        setImageValidation({
            ...imageValidation,
            [files[0].name]: files[0].size
        })

        if (files && files.length) {
            let formData = new FormData()
            formData.append('file', files[0])
            formData.append('upload_preset', 'ydo2ammk')

            const requestOptions = {
                method: 'POST',
                body: formData
            };
            isLoading(true)
            fetch('https://api.cloudinary.com/v1_1/nouri/upload', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        console.error(data.error.message)
                        Toast.fire({
                            title: "Something Went Wrong!",
                            icon: "error",
                        });
                    }
                    if (data.url) {
                        setImage({
                            ...image,
                            [imageAlt]: data.url
                        })
                    }
                    isLoading(false)
                    e.target.value = null;
                })
        }
    };

    const onButtonClick = (e) => {
        setImageAlt(e.target.alt)
        inputFile.current.click();
    };

    const validateImages = (file) => {

        if (imageValidation[file?.name] && imageValidation[file?.name] === file?.size) {
            return false
        }
        return true
    }

    return (
        <div className={`${columnStyle} mt-4`}>

            <div className="card outer-wrapper" onClick={() => setSubmitInsuranceModal(true)} style={{ cursor: 'pointer' }}>
                <Toolbar title={insurance.discount} style={{ minHeight: 30 }}>
                    <div className="frame">
                        <img
                            alt={insurance.id}
                            src={`${insurance.url}`}
                        />
                    </div>
                </Toolbar>
                <div className="card nav-layout">
                    <Toolbar title={insurance.discount} style={{ minHeight: 30, flexDirection: 'column' }}>
                        <h5 className="m-2 text-center">{insurance.company_name}</h5>
                        {/* <h6 className="m-2 text-center">{translate("Phone Number")}<br />{insurance.phone}</h6> */}
                    </Toolbar>
                </div>
            </div>

            <Modal
                visible={submitInsuranceModal}
                width={'620px'}
                onClose={handleModalClose}
                title='Submit Insurance'
            >
                <div className='phone-form insurance-submit' style={{ height: '650px' }} onSubmit={(e) => {
                    e.preventDefault()
                    handleModalClose()
                }}>
                    <h5 style={{ padding: '16px 0', borderBottom: '1px solid gainsboro' }}>{translate("Insurance")}: {insurance.company_name}</h5>

                    {<div className={`${steps === 1 && 'active'} ${steps > 1 && 'next'} step scrollable`}>
                        <TextFieldGroup
                            name="firstName"
                            placeholder={intl.formatMessage({ id: "First Name" })}
                            label={intl.formatMessage({ id: "First Name" })}
                            value={loadVehicleData.firstName}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.firstName.length > 0 && loadVehicleData.firstName.length < 3 ? "must be more than 3 letters" : ""}
                        />
                        <TextFieldGroup
                            name="SecondName"
                            placeholder={intl.formatMessage({ id: "Second Name" })}
                            label={intl.formatMessage({ id: "Second Name" })}
                            value={loadVehicleData.SecondName}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.SecondName.length > 0 && loadVehicleData.SecondName.length < 3 ? "must be more than 3 letters" : ""}
                        />
                        <TextFieldGroup
                            name="ThirdName"
                            placeholder={intl.formatMessage({ id: "Third Name" })}
                            label={intl.formatMessage({ id: "Third Name" })}
                            value={loadVehicleData.ThirdName}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.ThirdName.length > 0 && loadVehicleData.ThirdName.length < 3 ? "must be more than 3 letters" : ""}
                        />
                        <TextFieldGroup
                            name="FamilyName"
                            placeholder={intl.formatMessage({ id: "Family Name" })}
                            label={intl.formatMessage({ id: "Family Name" })}
                            value={loadVehicleData.FamilyName}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.FamilyName.length > 0 && loadVehicleData.FamilyName.length < 3 ? "must be more than 3 letters" : ""}
                        />
                        <TextFieldGroup
                            name="mobileNumber"
                            placeholder={intl.formatMessage({ id: "Mobile Number" })}
                            label={intl.formatMessage({ id: "Mobile Number" })}
                            type="tel"
                            value={loadVehicleData.mobileNumber}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.mobileNumber.length > 0 && loadVehicleData.mobileNumber.length < 16 ? "must be a valid number" : ""}
                        />
                        <TextFieldGroup
                            name="identityNumber"
                            placeholder={intl.formatMessage({ id: "Identity Number" })}
                            label={intl.formatMessage({ id: "Identity Number" })}
                            type="number"
                            value={loadVehicleData.identityNumber}
                            onChange={handleVehicleDataChange}
                            required={true}
                            maxLength={9}
                            error={loadVehicleData.identityNumber.length > 0 && loadVehicleData.identityNumber.length < 9 ? "must be a valid number" : ""}
                        />

                    </div>
                    }
                    {<div className={`${steps === 2 && 'active'} ${steps > 2 && 'next'} step insurance-submit-selections`}>
                        <TextFieldGroup
                            name="vehicleNumber"
                            placeholder={intl.formatMessage({ id: "Vehicle Number" })}
                            label={intl.formatMessage({ id: "Vehicle Number" })}
                            value={loadVehicleData.vehicleNumber}
                            onChange={handleVehicleDataChange}
                            required={true}
                            error={loadVehicleData.vehicleNumber.length > 0 && loadVehicleData.vehicleNumber.length < 5 ? "must be a valid number" : ""}
                        />
                        <div className="form-group">
                            <label ><span>{intl.formatMessage({ id: "Production Year" })}<i className="asterisk">*</i></span></label>
                            <Select
                                name="productionYear"
                                placeholder={intl.formatMessage({ id: "Production Year" })}
                                options={modalYears}
                                required={true}
                                value={{ value: loadVehicleData.productionYear, label: loadVehicleData.productionYear }}
                                onChange={handleYearChange}
                            />
                        </div>

                        <div className="form-group">
                            <label ><span>{intl.formatMessage({ id: "Vehicle Type" })}<i className="asterisk">*</i></span></label>
                            <Select
                                name="vehicleType"
                                placeholder={intl.formatMessage({ id: "Vehicle Type" })}
                                options={cars}
                                required={true}
                                value={{ value: loadVehicleData.vehicleType, label: loadVehicleData.vehicleType }}
                                onChange={handleCarChange}
                            />
                        </div>

                        <div className="form-group">
                            <label ><span>{intl.formatMessage({ id: "Vehicle Model" })}<i className="asterisk">*</i></span></label>
                            <Select
                                name="vehicleModel"
                                placeholder={intl.formatMessage({ id: "Vehicle Model" })}
                                options={carModal}
                                required={true}
                                value={{ value: loadVehicleData.vehicleModel, label: loadVehicleData.vehicleModel }}
                                onChange={handleModalChange}
                            />
                        </div>
                        {insuranceType?.value === "comprehensive" && <div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <label style={{ width: '350px', margin: '10px auto' }}><span>{intl.formatMessage({ id: "Vehicle Photos" })}<i className="asterisk">*</i> ({intl.formatMessage({ id: "4 sides" })})</span></label>
                            </div>
                            <div className="d-flex flex-wrap">
                                <input
                                    style={{ display: "none" }}
                                    ref={inputFile}
                                    onChange={handleFileUpload}
                                    type="file"
                                />
                                <img
                                    className="insurance-car-image"
                                    style={{ opacity: image.front ? 1 : 0.30 }}
                                    src={image.front || "https://res.cloudinary.com/nouri/image/upload/v1635104760/phonePlay/placeholder/carFront_acgz6x.png"}
                                    alt="front"
                                    width="120px"
                                    height="120px"
                                    loading="lazy"
                                    onClick={onButtonClick}
                                />
                                <img
                                    className="insurance-car-image"
                                    style={{ opacity: image.back ? 1 : 0.30 }}
                                    src={image.back || "https://res.cloudinary.com/nouri/image/upload/v1635104759/phonePlay/placeholder/carBack_zcsmat.png"}
                                    alt="back"
                                    width="120px"
                                    height="120px"
                                    loading="lazy"
                                    onClick={onButtonClick}
                                />
                                <img
                                    className="insurance-car-image"
                                    style={{ opacity: image.left ? 1 : 0.30 }}
                                    src={image.left || "https://res.cloudinary.com/nouri/image/upload/v1635104760/phonePlay/placeholder/carLeft_zlyyuu.png"}
                                    alt="left"
                                    loading="lazy"
                                    width="120px"
                                    height="120px"
                                    onClick={onButtonClick}
                                />
                                <img
                                    className="insurance-car-image"
                                    style={{ opacity: image.right ? 1 : 0.30 }}
                                    src={image.right || "https://res.cloudinary.com/nouri/image/upload/v1635104759/phonePlay/placeholder/carRight_zc1lnb.png"}
                                    alt="right"
                                    loading="lazy"
                                    width="120px"
                                    height="120px"
                                    onClick={onButtonClick}
                                />
                            </div>
                        </div>}
                    </div>}
                    {
                        <div className={`${steps === 3 && 'active'} step insurance-submit-selections`}>
                            <div className='card-summary mt-2' style={{ width: '400px' }}>
                                {loadVehicleData && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Fullname" })}:</h6>
                                    <h6>{`${loadVehicleData.firstName} ${loadVehicleData.SecondName} ${loadVehicleData.ThirdName} ${loadVehicleData.FamilyName}`}</h6>
                                </div>}

                                {loadVehicleData && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Mobile Number" })}:</h6>
                                    <h6>{loadVehicleData.mobileNumber}</h6>
                                </div>}

                                {loadVehicleData && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Identity Number" })}:</h6>
                                    <h6>{loadVehicleData.identityNumber}</h6>
                                </div>}
                                {loadVehicleData && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Vehicle Number" })}:</h6>
                                    <h6>{loadVehicleData.vehicleNumber}</h6>
                                </div>}

                                {driverType && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Vehicle Type" })}:</h6>
                                    <h6>{driverType.label}</h6>
                                </div>}

                                {engineSize && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Engine Size" })}:</h6>
                                    <h6>{engineSize.label}</h6>
                                </div>}
                                {ton && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Vehicle Weight" })}:</h6>
                                    <h6>{ton.label}</h6>
                                </div>}

                                {passenger && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Vehicle Passengers" })}:</h6>
                                    <h6>{passenger.label}</h6>
                                </div>}

                                {vehiclePrice && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Vehicle Value" })}:</h6>
                                    <h6>{vehiclePrice} ILS</h6>
                                </div>}
                                {insuranceType && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "Insurance Type" })}:</h6>
                                    <h6>{insuranceType.label}</h6>
                                </div>}
                                {total && <div className='d-flex align-items-center justify-content-between'>
                                    <h6>{intl.formatMessage({ id: "total" })}:</h6>
                                    <h6>{total}</h6>
                                </div>}

                                <h6 className='text-success mt-4'> *{noteMessage} </h6>
                            </div>
                        </div>
                    }
                    <div className='d-flex align-items-center justify-content-between mt-4' style={{ width: '100%', position: 'absolute', bottom: 0 }}>
                        <div className='d-flex align-items-center justify-content-start'>
                            {steps > 1 && <button className='btn btn-primary' onClick={handleBackStep}>
                                {translate("Back")}
                            </button>}
                        </div>

                        <div className='d-flex align-items-center justify-content-end'>
                            <button className='btn btn-danger ' onClick={handleModalClose} style={{ width: 90 }}>
                                {translate("Cancel")}
                            </button>
                            {steps === 3 && <Button type='primary'
                                title='submit'
                                loading={submitInsuranceLoading}
                                className='mx-3'
                                onClick={(e) => handleInsuranceSubmit(e, insurance.id, insurance.company_name)}
                                style={{ width: 110 }}
                            />
                            }
                            {steps === 1 && <button className='btn btn-primary mx-3'
                                disabled={firstStepDisable()}
                                onClick={handleNextStep}
                                style={{ width: 85 }}>
                                {translate("next")}
                            </button>}
                            {steps === 2 && <button className='btn btn-primary mx-3'
                                disabled={!loadVehicleData.vehicleNumber || !loadVehicleData.vehicleModel || !loadVehicleData.vehicleType || !loadVehicleData.productionYear || ((!image.front || !image.back || !image.right || !image.left) && insuranceType?.value === "comprehensive")}
                                onClick={handleNextStep}
                                style={{ width: 85 }}>
                                {translate("next")}
                            </button>}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={successModal}
                onClose={successModalClose}
                width={'40vw'}
                title='Success'
                onConfirm='success'
            >
                <div className={!!isDarktheme ? 'message-container' : 'dark-message-container'}>
                    <h4>{intl.formatMessage({ id: "transactionNo" })}: {ticketNo}</h4>
                    <h4>{noteMessage}</h4>
                </div>

            </Modal>
            {loading && (<Spinner />)}
        </div>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}
export default connect(mapStateToProps, { submitInsurance })(InsuranceCard)