import React from "react";
import logo from '../../../assests/images/logo/white-logo.svg'
import "./style.css";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="innter-spinner-container">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
      </div>

      <svg xmlns="http://wwwwww.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" />
          </filter>
        </defs>
      </svg>
      <img src={logo} className='spinner-logo' alt="Site Logo" height='60' style={{ marginTop: '3.5rem' }} />
    </div>
  );
};
export default Spinner;
