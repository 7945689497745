import React, { useEffect, useState } from "react";
import { RiLoader4Line } from "react-icons/ri";
import { makeStyles } from "@mui/styles";
import PendingIcon from "@mui/icons-material/Pending";

const useStyles = makeStyles({
  rotatingIcon: {
    fontSize: "2rem",
    marginLeft: "0.5rem",
  },
  rotatingIconActive: {
    animation: "$rotation 2s infinite linear",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
});

const RotatingIcon = ({ color, time }) => {
  const classes = useStyles();
  const [rotate, setRotate] = useState(false);
  const howMuchTimeExpired =
    (new Date().getTime() - new Date(time)) / 1000 / 60;

  useEffect(() => {
    // Start rotating the icon
    setRotate(true);

    // Stop rotating after 5 minutes

    // Clean up the timer
  }, []);

  return (
    <>
      {howMuchTimeExpired < 5 ? (
        <RiLoader4Line
          color={color}
          className={`${classes.rotatingIcon} ${
            rotate ? classes.rotatingIconActive : ""
          }`}
        />
      ) : (
        <PendingIcon color={color} className={classes.rotatingIcon} />
      )}
    </>
  );
};

export default RotatingIcon;
