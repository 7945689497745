import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import translate from "../../../i18n/translate";
import { intl } from "../../../i18n/provider";

const engineSizes = [
  {
    value: "below 1000 cc",
    label: intl.formatMessage({ id: "below 1000 cc" }),
  },
  {
    value: "from 1001 cc to 1500 cc",
    label: intl.formatMessage({ id: "from 1001 cc to 1500cc" }),
  },
  {
    value: "from 1501 to 2000 cc",
    label: intl.formatMessage({ id: "from 1501 to 2000 cc" }),
  },
];

const tons = [
  { value: "below 1 ton", label: intl.formatMessage({ id: "below 1 ton" }) },
  {
    value: "from 1 ton to 1.6 to ton",
    label: intl.formatMessage({ id: "from 1 ton to 1.6 to ton" }),
  },
  {
    value: "more than 4 tons",
    label: intl.formatMessage({ id: "more than 4 tons" }),
  },
];

const passengers = [
  {
    value: "passengers_4_to_6",
    label: intl.formatMessage({ id: "4 to 6 passengers" }),
  },
  { value: "passengers_7", label: intl.formatMessage({ id: "7 passengers" }) },
  { value: "passengers_8", label: intl.formatMessage({ id: "8 passengers" }) },
];

const LoadStepper = ({
  driverType,
  engineSize,
  setEngineSize,
  setTon,
  setPassenger,
  ton,
  passenger,
  handleNext,
  handleBack,
  insuranceCalculationsLoading,
  isDarktheme,
}) => {
  const disableBtn = () => {
    if (driverType.value === "private" && !engineSize) {
      return true;
    }
    if (driverType.value === "commercial" && !ton) {
      return true;
    }
    if (
      (driverType.value === "taxi_1_driver" ||
        driverType.value === "taxi_2_drivers" ||
        driverType.value === "taxi_multiple_drivers") &&
      !passenger
    ) {
      return true;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (driverType.value === "private" && !engineSize) {
      return;
    }
    if (driverType.value === "commercial" && !ton) {
      return;
    }
    if (
      (driverType.value === "taxi_1_driver" ||
        driverType.value === "taxi_2_drivers" ||
        driverType.value === "taxi_multiple_drivers") &&
      !passenger
    ) {
      return;
    }
    handleNext(e);
  };

  return (
    <form className="vehicle-stepper" onSubmit={onSubmit}>
      {driverType.value === "private" && (
        <div className="select-input">
          <label
            style={
              !isDarktheme
                ? { marginBottom: 6 }
                : { marginBottom: 6, color: "white" }
            }
          >
            <span>
              {translate("Engine Size")} <i className="asterisk">*</i>
            </span>
          </label>
          <Select
            name="enigne_size"
            placeholder={intl.formatMessage({ id: "Engine Size" })}
            options={engineSizes}
            value={engineSize}
            required={true}
            onChange={(v) => {
              setEngineSize(v);
              setTon(null);
              setPassenger(null);
            }}
          />
        </div>
      )}
      {driverType.value === "commercial" && (
        <div className="select-input">
          <label
            style={
              !isDarktheme
                ? { marginBottom: 6 }
                : { marginBottom: 6, color: "white" }
            }
          >
            <span>
              {translate("Select Vehicle Weight")}
              <i className="asterisk">*</i>
            </span>
          </label>
          <Select
            name="tons"
            placeholder={intl.formatMessage({ id: "Select Vehicle Weight" })}
            options={tons}
            value={ton}
            required={true}
            onChange={(v) => {
              setTon(v);
              setEngineSize(null);
              setPassenger(null);
            }}
          />
        </div>
      )}
      {(driverType.value === "taxi_1_driver" ||
        driverType.value === "taxi_2_drivers" ||
        driverType.value === "taxi_multiple_drivers") && (
        <div className="select-input">
          <label
            style={
              !isDarktheme
                ? { marginBottom: 6 }
                : { marginBottom: 6, color: "white" }
            }
          >
            <span>
              {translate("Select Vehicle Passengers")}
              <i className="asterisk">*</i>
            </span>
          </label>
          <Select
            name="passengers"
            placeholder={intl.formatMessage({
              id: "Select Vehicle Passengers",
            })}
            options={passengers}
            value={passenger}
            required={true}
            onChange={(v) => {
              setPassenger(v);
              setTon(null);
              setEngineSize(null);
            }}
          />
        </div>
      )}
      <div
        className="d-flex justify-content-between align-items-center mt-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0 15px",
        }}
      >
        <button
          className="btn btn-primary back"
          onClick={handleBack}
          style={{ position: "static" }}
        >
          {translate("Back")}
        </button>
        <button
          onSubmit={onSubmit}
          type="submit"
          className="btn btn-primary d-flex align-items-center justify-content-center"
          style={{ position: "static" }}
          disabled={disableBtn()}
        >
          <div>{intl.formatMessage({ id: "next" })}</div>
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = ({
  insurances: { insuranceCalculationsLoading },
  darkTheme: { isDarktheme },
}) => {
  return {
    insuranceCalculationsLoading,
    isDarktheme,
  };
};

export default connect(mapStateToProps, null)(LoadStepper);
