import React from "react";
import AddBalance from "./AddBalance.js";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { intl } from "../../../i18n/provider.js";
import { isNullOrUndefined } from "../../../shared/utils/utils.js";
import { CircularProgress } from "@mui/material";
import { getProviderAPiCaller } from "../../../shared/endPoints/apiCallers.js";
import {
  JAWWAL_ID,
  PROVIDER_NAME_TO_ID_DICTIONARY,
  PROVIDER_ROUTES,
} from "shared/constants/constants.js";
import JAWWAL_CREDITS, {
  EMPTY_JAWWAL_CREDIT,
} from "components/companies/jawwal/credits.js";
import OOREDOO_CREDITS, {
  EMPTY_OOREDOO_CREDIT,
} from "components/companies/ooredoo/credits.js";
import {
  LOCAL_STORAGE_ITEMS_ENUM,
  SESSION_STORAGE_ITEMS_ENUM,
} from "shared/constants/localStorageItemsEnum.js";
import sharedStyles from "shared/styles/sharedStyles.js";
import { makeStyles } from "@mui/styles";
import { getProviderBundleSessionKey } from "shared/utils/ppUtils.js";
import SendModal from "./SendModal.js";

const { useObjectState, useCustomHistory } = require("../../../shared/hooks");

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles({ ...sharedStyles() });

export default function AddBalancePage(props) {
  const classes = useStyle(props);
  const [openModal, setOpenModal] = React.useState(false);
  const [myCredits, setMyCredits] = React.useState({});

  const [toggle, setToggle] = useObjectState({ isLoadingBundles: false });
  const history = useCustomHistory();

  function redirectToProviderRoute({ providerId, number, bundleType, bundle }) {
    history.push(
      {
        path:
          PROVIDER_ROUTES.getProviderRoute(providerId, bundleType) +
          "/" +
          number,
        reset: true,
      },
      { provider_Id: providerId, bundle }
    );
  }

  async function onBalanceCardClick({
    mobileNumber,
    mobileNumberInfo,
    isSecond,
  }) {
    setToggle({ isLoadingBundles: true });
    try {
      const pci = !isSecond ? mobileNumberInfo.pci : mobileNumberInfo.pci2;
      const type = !isSecond ? mobileNumberInfo.type : mobileNumberInfo.type2;

      const isCredit = Boolean(pci?.toLocaleLowerCase()?.startsWith("c"));
      const providerName = mobileNumberInfo?.provider;
      const providerId = PROVIDER_NAME_TO_ID_DICTIONARY[providerName];
      const bundleType = type?.toLocaleLowerCase();
      if (isCredit) {
        console.log("isCredit", isCredit);
        const isJawwal = providerId === JAWWAL_ID;
        const providerCredits = isJawwal ? JAWWAL_CREDITS : OOREDOO_CREDITS;
        let credit = providerCredits.find((credit) =>
          credit.id.includes(pci?.toLocaleLowerCase()?.replace("c", ""))
        );

        if (isNullOrUndefined(credit) && isJawwal) credit = EMPTY_JAWWAL_CREDIT;
        if (isNullOrUndefined(credit) && !isJawwal)
          credit = EMPTY_OOREDOO_CREDIT;
        setOpenModal(true);

        const sessionStorageKey = isJawwal
          ? SESSION_STORAGE_ITEMS_ENUM.JawwalCredit
          : SESSION_STORAGE_ITEMS_ENUM.ooredooCredit;
        setMyCredits({
          isJawwal,
          providerId,
          number: mobileNumber,
          undefined,
          credit,
        });

        // sessionStorage.setItem(sessionStorageKey, JSON.stringify(credit));
        // redirectToProviderRoute({
        //   providerId,
        //   number: mobileNumber,
        //   undefined,
        //   credit,
        // });
        setToggle({ isLoadingBundles: false });
      } else {
        const { data } = await getProviderAPiCaller(providerId)({
          providerId,
          mobileNumber,
          bundle: bundleType,
          refresh: false,
          language: localStorage.getItem(LOCAL_STORAGE_ITEMS_ENUM.langCity),
        });

        const bundle = data?.find(
          (x) =>
            x.productid === pci ||
            x?.bundleid === pci ||
            x?.SPID === pci ||
            x?.pci === pci ||
            x?.PID === pci ||
            x.id === pci
        );

        if (isNullOrUndefined(bundle))
          redirectToProviderRoute({
            providerId,
            number: mobileNumber,
            bundleType,
          });

        const providerIdToSessionStorageKey = getProviderBundleSessionKey({
          providerId,
          bundleType,
        });

        if (!isNullOrUndefined(providerIdToSessionStorageKey))
          sessionStorage.setItem(
            providerIdToSessionStorageKey,
            JSON.stringify(bundle)
          );

        redirectToProviderRoute({
          providerId,
          number: mobileNumber,
          bundleType,
          bundle,
        });
        setToggle({ isLoadingBundles: true });
      }
    } catch (e) {
      console.log("AddBalancePage => onBalanceCardClick", e?.message);
    }
  }

  if (toggle.isLoadingBundles) {
    return (
      <Dialog open={true}>
        <div className={classes.centerDiv} style={{ width: 256, height: 64 }}>
          <CircularProgress />
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h6">
            {intl.formatMessage({ id: "redirecting" })}
          </Typography>
        </div>
      </Dialog>
    );
  }

  return (
    <React.Fragment>
      <AddBalance onBalanceCardClick={onBalanceCardClick} />
      <SendModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        myCredits={myCredits}
      />
    </React.Fragment>
  );
}

AddBalancePage.propTypes = propTypes;
AddBalancePage.defaultProps = defaultProps;
