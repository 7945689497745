import React from 'react';
import { connect } from 'react-redux';
import { dataLanguage } from './../../i18n/translateRequest';

const SelectionCard = ({ selection, columnStyle, setSelectedCard, selectedCard, reward, disabled, onClick, isDarktheme }) => {

    return (
        <div className={`${columnStyle} mb-4`}>
            {selection && <div onClick={() => setSelectedCard(selection)}
                className={`selection-card ${selectedCard?.id === selection?.id && 'selected'}`}>

                <div className='border-bar'>
                    {selectedCard?.id === selection?.id && <span>&#10003;</span>}
                </div>

                <div className={!isDarktheme ? 'card-details' : 'dark-card-details'}>
                    <h6>{selection?.value} {selection?.currency}</h6>
                </div>
            </div>}
            {reward &&
                <div onClick={!disabled && onClick}>
                    <div onClick={() => !disabled && setSelectedCard(reward)}
                        className={`selection-card ${selectedCard?.id === reward?.id && 'selected'} ${disabled && 'disabled'}`}>
                        {(reward.type !== 'bank' && reward.type !== 'balance') && <div className='border-bar'>
                            {selectedCard?.id === reward?.id && <span>&#10003;</span>}
                        </div>}

                        <div className={!isDarktheme ? 'card-details' : 'card-details darkCard'}>
                            <h6>{reward?.price > 0 && reward?.price} {reward?.price > 0 && `Points`} {reward?.name}</h6>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}
export default connect(mapStateToProps, null)(SelectionCard)