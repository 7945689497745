import React from "react";
import { Typography, Box, Button, Alert } from "@mui/material";
import { intl } from "i18n/provider"; // Assuming 'intl' is properly initialized and imported

const ConfirmationMessage = ({ playerId, playerName, totalUC, cost }) => {
  // const confirmationMessage = intl
  //   .formatMessage({ id: "pubg_conformation_modal_msg" })
  //   .replace("{player_id}", playerId)
  //   .replace("{player_name}", playerName)
  //   .replace("{total_uc}", totalUC);
  const formattedMessage = intl.formatMessage(
    { id: "pubg_conformation_modal_msg" },
    {
      player_id: `<span style="font-weight: bold; font-size: 1.2em; ">${playerId}</span>`,
      player_name: `<span style="font-weight: bold; font-size: 1.2em;">${playerName}</span>`,
      total_uc: `<span style="font-weight: bold; font-size: 1.2em;">${totalUC}</span>`,
      cost: `<span style="font-weight: bold; font-size: 1.2em;">${cost}</span>`,
    }
  );

  return (
    <Alert
      severity="warning"
      sx={{
        marginTop: 2,
        marginBottom: 2,
      }}
    >
      <Typography
        variant="h5"
        dangerouslySetInnerHTML={{ __html: formattedMessage }}
      />
    </Alert>
  );
};

export default ConfirmationMessage;
