import * as React from "react";
import Box from "@mui/material/Box";
import ModalMui from "@mui/material/Modal";
import translate from "../../i18n/translate";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";

const Modal = ({
  visible,
  onClose,
  children,
  width,
  height,
  title,
  titleButton = "submit",
  onSuccess,
  onSuccessLoading,
  onConfirm,
  onSuccessDisable,
  isDarktheme,
  overflow,
  justifyCenter,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    // padding: 22,
    p: 4,
    overflowX: "hidden",
    background: !isDarktheme ? "white" : "#242526",
    justifyContent: justifyCenter ? "" : "center",
  };
  const ModelTitleColor = {
    color: !isDarktheme ? "black" : "white",
  };
  // titleButton = translate(`${titleButton}`);
  return (
    <div>
      <ModalMui
        open={visible}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            ...ModelTitleColor,
            width: { xs: "100%", sm: "80%", md: "60%", lg: "50%" },
            height: height || "auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: ".25rem",
            overflowY: overflow ? overflow : "auto",
          }}
        >
          {title && (
            <div className="top-section">
              <h6 style={{ ...ModelTitleColor }}>{translate(`${title}`)}</h6>
            </div>
          )}
          {children}
          {onSuccess && (
            <div
              className="d-flex justify-content-end align-items-center mt-4"
              style={{ width: "100%", gap: 16 }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                style={{ width: 130 }}
                color="error"
                disabled={onSuccessLoading}
              >
                {translate("cancel")}
              </Button>

              <LoadingButton
                style={{ width: 130 }}
                onClick={onSuccess}
                loading={onSuccessLoading}
                variant="contained"
                disabled={onSuccessDisable}
              >
                {titleButton}
              </LoadingButton>
            </div>
          )}
          {onConfirm && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              <LoadingButton
                className={`btn btn-${onConfirm} mt-4`}
                style={{ width: 160 }}
                onClick={onClose}
                loading={onSuccessLoading}
              >
                {translate("Close")}
              </LoadingButton>
            </div>
          )}
        </Box>
      </ModalMui>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(Modal);
