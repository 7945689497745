import SaleReportTableStyles from "core/core-components/SaleReportTable/SaleReportTableStyles";

const TransactionTableStyles = (theme) => ({
  ...SaleReportTableStyles(),
  hCopyButton: {
      padding: 0,
      margin: 0,
  },
  collapseItemValue:{
    paddingLeft:"4px"
  },
  collapseItemContainer:{
    flexWrap:'nowrap'
  }
});
export default TransactionTableStyles;
