

const sharedStyles = theme=>({
    centerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullWidth:{
        width: '100%',
    },
    fullHeight:{
        height: '100%',
    },
    fullWidthHeight:{
        width: '100%',
        height: '100%',
    },
});
export default sharedStyles;