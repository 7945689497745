import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";
import translate from "i18n/translate";

const propTypes = {
  usedNumber: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {};

export default function UserAlreadyExistsModal(props) {
  const { usedNumber, open, onClose } = props;
  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{color:'#ed6c02'}} id="alert-dialog-title">{translate("warning")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {translate("this_number_is_already_in_use")} : <b>{usedNumber}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>{translate("Close")}</Button>
        <Button
          onClick={() => {
            history.push("/forgot-password");
          }}
          autoFocus
          variant="text"
        >
          {translate("Forgot Password?")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserAlreadyExistsModal.propTypes = propTypes;
UserAlreadyExistsModal.defaultProps = defaultProps;
