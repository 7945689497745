import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import GiftCard from "./GiftCard";
import translate from "../../i18n/translate";
import { getCards, setCards } from "../../actions/cardsAction";
import { refreshColumnStyle } from '../Gaming/reusableFunctions'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import sha256 from 'crypto-js/sha256';

const GiftCardsPage = ({ getCards, loading, isDarktheme, cards, setCards }) => {
  const storageHash = sha256(`giftCards`).toString();

  const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-6 card-md");

  useEffect(() => {
    document.title = "Electornic Cards | Phone Play";
    refreshColumnStyle(setColumnStyle);
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cards.length) {
      localStorage.setItem(storageHash, JSON.stringify(cards.map((card, i) => { return { ...card, index: i } })));
    }
  }, [cards, setCards, storageHash])

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];
    setCards(update(cards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    }));
  }, [cards, setCards]);

  return (

    <>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card img-back" : "card dark-img-back"} >
          <h1 className="header-text">{translate("Gift Cards")}</h1>
        </div>
      </div>

      <div className="row mb-5" >
        <DndProvider backend={HTML5Backend}>
          {cards?.map((card, i) =>
            <GiftCard key={card.ID} id={card.ID} index={i} card={card} columnStyle={columnStyle} moveCard={moveCard} />
          )}
        </DndProvider>
      </div>
    </>

  );
};
const mapStateToProps = ({ cards: { cards, loading }, darkTheme: { isDarktheme } }) => ({
  cards,
  loading,
  isDarktheme
});

export default connect(mapStateToProps, { getCards, setCards })(GiftCardsPage);









