import {
    GET_ALL_ADVERTISEMENTS
  } from "../actions/types";
  
  const initialState = {
    advertisementData: [],
  };
  
  const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_ADVERTISEMENTS:
        return {
          ...state,
          advertisementData: action.payload,
        };
      default:
        return state;
    }
  }
  
  export default settingsReducer
  