import { combineReducers } from "redux";

import authReducer from "./userReducer";
import companiesReducer from "./companiesReducer";
import errorReducer from "./errorReducer";
import reportReducer from "./reportsReducer";
import insuranceReducer from "./insuranceReducer";
import internetReducer from "./internetReducer"
import discountsReducer from "./discountsReducer"
import sellerCreditsReducer from './sellerCreditsReducer'
import cardReducer from "./cards";
import gameReducer from "./gameReducer";
import adminReducer from "./adminReducer";
import contactUsReducer from "./contactUsReducer";
import settingsReducer from './settingsReducer'
import darkThemeReducer from "./darkThemeReducer";
import notificationReducer from "./notificationReducer";


export default combineReducers({
  auth: authReducer,
  companies: companiesReducer,
  error: errorReducer,
  reports: reportReducer,
  cards: cardReducer,
  insurances: insuranceReducer,
  internets: internetReducer,
  discounts: discountsReducer,
  credits: sellerCreditsReducer,
  games: gameReducer,
  contactUs: contactUsReducer,
  admin: adminReducer,
  settings: settingsReducer,
  darkTheme: darkThemeReducer,
  notification: notificationReducer

});
