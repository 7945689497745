import React, { useState, useEffect, Fragment } from "react";
import translate from "../../../i18n/translate";
import { getLastTransaction } from "../../../actions/reportsAction";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "../Pagination";
import { dataLanguage } from "../../../i18n/translateRequest";
import Toolbar from "@mui/material/Toolbar";
import TextFieldGroup from "../TextFieldGroup";
import { EMPTY_CREDIT } from "../../companies/jawwal/credits";
import { userData } from "../../../actions/userAction";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CancelTransactionModal from "../CancelTransactionModal";
import CopyButton from "core/core-components/CopyButton/CopyButton";
import { makeStyles } from "../../../../node_modules/@mui/styles/index";
import TransactionTableStyles from "./TransactionTableStyles";
import classNames from "../../../../node_modules/classnames/index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ShareIcon from "@mui/icons-material/Share";

import {
  conditionalReturn,
  isNullOrUndefined,
  parseBool,
} from "shared/utils/utils";
import { isEnglish } from "shared/utils/languageUtils";
import { JAWWAL_ID, OOREDOO_ID, PROVIDERS } from "shared/constants/constants";
import { useIsAdmin } from "../../../shared/hooks/ppHooks";
import HTable from "material-htable2";
import "./styles.module.css";
import { HCard, HCardHeader } from "material-hcard-private";
import RefreshButton from "core/core-components/RefreashButton/RefreshButton";
import { isUnknownOrNaStr } from "shared/utils/ppUtils";
import RotatePendingIcon from "../RotatePendingIcon";
import ShareModal from "components/profile/ShareModal";
const useStyle = makeStyles(TransactionTableStyles);

const TransactionTable = ({
  getLastTransaction,
  last,
  isDarktheme,
  userData,
  onRefresh,
  isLoading,
}) => {
  const classes = useStyle();
  const history = useHistory();
  // const [loading, isLoading] = useState(false);
  const url = new URLSearchParams(history.location.search);
  const [isDetailsButtonClicked, setIsDetailsButtonClicked] = useState({
    flag: false,
    index: null,
  });
  const isDarkTheme = useSelector((state) => state.darkTheme.isDarktheme);
  const { isAdmin } = useIsAdmin();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [sort, setSort] = useState({ value: "5", label: 5 });
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [referalCode, setReferalCode] = React.useState("");
  const hide = localStorage.getItem("hide companies") === "true";

  useEffect(() => {
    updateTransactions();
    if (url.get("refresh") === "true") {
      setTimeout(() => {
        handleLastTransactions("0", sort.label);
        userData();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (last.length) {
      setCount(Math.ceil(Number(last[0].total_count) / sort.label));
      // setTotalCount(Number(last[0].total_count))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last]);

  const handleLastTransactions = (offset, rows) => {
    // isLoading(true);
    getLastTransaction(offset, rows).finally(() => {
      // isLoading(false);
    });
  };

  const updateTransactions = () => {
    setPage(1);
    handleLastTransactions("0", sort.label);
  };

  const handleSortChange = (v) => {
    setPage(1);
    setSort(v);
    if (last?.length) {
      handleLastTransactions("0", v.value);
    }
  };

  const handleOffsetValue = (event, value) => {
    setPage(value);
    let queryOffset = value * sort.label - sort.label;
    handleLastTransactions(queryOffset, sort.value);
  };
  function enforceLTR(str) {
    return `\u202A${str}\u202C`;
  }
  const handleTelecomRouting = (provider, number) => {
    switch (provider) {
      case "Jawwal":
        history.push(`/company/jawwalCredit/${number}`, {
          provider_id: "001",
        });
        break;
      case "Ooredoo":
        history.push(`/company/ooredoo/credit/${number}`, {
          provider_id: "002",
        });
        break;
      case "Paltel":
        history.push(`/pay_bills/paltel`, {
          number: number,
        });
        break;
      case "Hot":
        history.push(
          `/company/hot/${number}?providerId=008&mobileNumber=${number}`,
          {
            provider_Id: "008",
            mobileNumber: number,
          }
        );
        break;
      case "Mobile019":
        history.push(
          `/company/azy/${number}?providerId=009&mobileNumber=${number}`,
          {
            provider_Id: "009",
            mobileNumber: number,
          }
        );
        break;
      case "Cellcom 2":
        history.push(
          `/company/group/Cellcom 2/${number}?providerId=218&mobileNumber=${number}`,
          {
            provider_Id: "218",
            mobileNumber: number,
          }
        );
        break;
      case "Pelephone":
        history.push(
          `/company/group/Pelephone/${number}?providerId=095&mobileNumber=${number}`,
          {
            provider_Id: "095",
            mobileNumber: number,
          }
        );
        break;
      case "Partner":
        history.push(
          `/company/group/Partner/${number}?providerId=127&mobileNumber=${number}`,
          {
            provider_Id: "127",
            mobileNumber: number,
          }
        );
        break;
      case "012 Mobile":
        history.push(
          `/company/group/012 mobile/${number}?providerId=201&mobileNumber=${number}`,
          {
            provider_Id: "201",
            mobileNumber: number,
          }
        );
        break;
      case "Golan Telecom":
        history.push(
          `/company/group/Golan telecom/${number}?providerId=214&mobileNumber=${number}`,
          {
            provider_Id: "214",
            mobileNumber: number,
          }
        );
        break;
      case "Nedco":
        history.push(`/pay_bills/nedco`, {
          number: number,
        });
        break;
      case "Selco":
        history.push(`/pay_bills/selco`, {
          number: number,
        });
        break;
      case "Mada":
        history.push(`/pay_bills/mada`, {
          number: number,
        });
        break;
      case "Gemzo":
        history.push(`/pay_bills/gemzo`, {
          number: number,
        });
        break;
      case "Jawwal Invoice":
        history.push(`/pay_bills/jawwal`, {
          number: number,
        });
        break;
      default:
        break;

      // Other companies check when adding provider id from BE
    }
  };

  const isTelecom = (provider) =>
    provider === "Jawwal" || provider === "Ooredoo";

  const cardAmountHandle = (item) => {
    const isJawwalOrOoredoo = [JAWWAL_ID, OOREDOO_ID].includes(
      item.provider_id
    );
    if (
      ![10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 200].includes(
        item.cardamount
      ) &&
      isJawwalOrOoredoo &&
      ((item.cardamount &&
        Number(item.cardamount) % 5 !== 0 &&
        item.url === "N/A") ||
        item.card_type.toLowerCase() === "credit")
    ) {
      return (
        <div className="card" style={{ width: "90%", margin: "auto" }}>
          <img
            alt=""
            src={EMPTY_CREDIT.url}
            style={{ width: "220px", aspectRatio: "3/2", objectFit: "contain" }}
          ></img>
          <TextFieldGroup
            style={{
              width: "calc(30%)",
              height: "50%",
              padding: 0,
              position: "absolute",
              top: "50%",
              left: "calc(55%)",
              transform: "translateY(-50%)",
              fontFamily: '"Montserrat", sans-serif',
              textAlign: "center",
              border: "none",
              fontSize: "2.4rem",
              color: "green",
              fontWeight: "bold",
            }}
            name="price"
            type="number"
            value={item.cardamount}
            onChange={() => {}}
            min={10}
          />
        </div>
      );
    } else {
      let imgSrc = "";
      try {
        imgSrc =
          item.url === "N/A"
            ? require(`../../../assests/images/bundles/${item.provider.toLowerCase()}/${item.provider.toLowerCase()}-${item.cardamount
                ?.toString()
                ?.replace("-", "")}.png`).default
            : item.url;
      } catch (error) {
        console.log("cardAmountHandle SaleReportTable", error);
      }
      return (
        <img
          alt={item.cardamount?.toString()}
          src={imgSrc}
          style={{
            width: "220px",
            aspectRatio: "3/2",
            objectFit: "contain",
            // maxHeight: 200,
            paddingRight: 4,
          }}
        />
      );
    }
  };

  function renderHTable() {
    const showReportName =
      isAdmin &&
      !isNullOrUndefined(last[0]?.name) &&
      !isUnknownOrNaStr(last[0]?.name);
    const heads = [
      translate("serial.no"),
      "",
      showReportName && translate("seller"),
      translate("Trans. Type"),
      translate("Mobile No."),
      translate("Card Type"),
      translate("date"),
      translate("seller cost"),
      translate("Amount"),
      translate("Status"),
    ]
      .filter((h) => h !== false)
      .map((h, i) => ({
        id: new Date().getTime(),
        component: <h6>{h}</h6>,
        props: { style: { width: 100 } },
      }));

    const rows = last.map((report) => {
      const isSuccessfulReport = report.status === "success";
      const isFailedReport = report.status === "failed";
      const StatusIcon = isSuccessfulReport
        ? CheckCircleIcon
        : isFailedReport
        ? CancelIcon
        : RotatePendingIcon;

      const isManualCorrection = report?.["manual correction"] === "true";
      const isCancellableRequest =
        report.transtype === "Topup" && report.card_type === "Credit";

      const cells = [
        {
          component: (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                id="sales-trans-id"
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {report.transid}
              </Typography>
              <Grid item md={12} container justifyContent={"center"}>
                <CopyButton
                  buttonClassName={classes.hCopyButton}
                  textToCopy={report?.transid?.toString()}
                  iconClassName={classes.copyIcon}
                />
              </Grid>
            </Box>
          ),
          props: { style: { paddingLeft: "8px" } },
        },
        <img
          style={{ width: 55, margin: 0 }}
          src={report.provider_url}
          alt="provider logo"
        />,
        showReportName && (
          <td
            style={{
              fontSize: `11px`,
            }}
            title={report.name}
          >
            <p
              id="transaction-report-name"
              className={classes.sellerNameP}
              style={{ direction: isEnglish(report.name) ? "ltr" : "rtl" }}
            >
              {report.name}
            </p>
          </td>
        ),
        <Typography id="transaction-trans-type">{report.transtype}</Typography>,
        {
          props: {
            padding: "none",
            style: {
              color: "black",
              position: "relative",
              cursor: "pointer",
            },
            onClick: () => handleTelecomRouting(report.provider, report.number),
          },
          component: (
            <Grid container>
              <Grid item md={12} container justifyContent={"center"}>
                <p id="transaction-number" style={{ padding: 0, margin: 0 }}>
                  {report.number}
                </p>
              </Grid>
              <Grid item md={12} container justifyContent={"center"}>
                <CopyButton
                  buttonClassName={classes.hCopyButton}
                  textToCopy={report?.number?.toString()}
                  iconClassName={classes.copyIcon}
                />
              </Grid>
            </Grid>
          ),
        },
        <Typography>{report.card_type}</Typography>,
        <Typography id="transaction-date">
          {moment(report.datetime).format("YYYY-MM-DD - HH:mm")}
        </Typography>,
        <Typography>{report.dealercost || 0}</Typography>,
        <Typography id="transaction-amount">
          {report.cardamount || 0}
        </Typography>,

        <>
          <StatusIcon
            color={
              isSuccessfulReport ? "success" : isFailedReport ? "error" : ""
            }
            sx={{
              fontSize: "2rem",
              marginLeft: "0.5rem",
            }}
          />
          {conditionalReturn(
            report?.status === "success" && parseBool(report?.cancelrequest),
            <span className={classes.canceledRequestUnderLine} />
          )}
        </>,
      ].filter((c) => !!c);

      const isNotSuccessfulTrans = report.status !== "success";
      const isSuccessfulTrans = report.status === "success";
      const isFailedTrans = report.status === "failed";

      const showCancel =
        PROVIDERS.jawwal === report.provider && !isCancellableRequest
          ? null
          : report.status === "success" &&
            !report.cancelrequest &&
            !report.canceled &&
            isNaN(Number(report.provider)) &&
            report.card_type !== "GAME" &&
            report.card_type !== "voucher";

      const showCancelRequist = report?.cancelrequest && !report?.canceled;

      // className={
      //   report?.canceled?.toLowerCase() === "success"
      //     ? classes.textGreen
      //     : report?.canceled?.toLowerCase() === "failed"
      //       ? classes.textRed
      //       : ""
      // }

      const collapse = (
        <Grid
          container
          justifyContent="space-between"
          flexWrap={"nowrap"}
          minHeight="200px"
          paddingTop={"4px"}
        >
          <Grid item md={4}>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("Renewable")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.collapseItemValue}
                  fontWeight={"bolder"}
                  color="blue"
                >
                  {report.autorenew ? translate("Yes") : translate("No")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("trans type")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.collapseItemValue}>
                  {translate(report.card_type)}
                </Typography>
              </Grid>
            </Grid>
            {conditionalReturn(
              isSuccessfulTrans,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("Reason")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.collapseItemValue}>
                    {report.reason}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("description")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.collapseItemValue}>
                  {" "}
                  {dataLanguage(report, "ar_des", "en_des", "he_des")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes.collapseItemContainer}
              sx={{ alignItems: "center" }}
            >
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("Reference Number")}
                </Typography>
              </Grid>
              <CopyButton
                textToCopy={report?.reference_no?.toString()}
                iconClassName={classes.copyIcon}
                buttonClassName={classes.hCopyButton}
              />
              {report.provider === "Nedco" && (
                <IconButton
                  onClick={() => {
                    setReferalCode(report?.reference_no);
                    setOpenShareModal(true);
                  }}
                >
                  <ShareIcon />
                </IconButton>
              )}
              :
              <Grid item>
                <Typography
                  id="transaction-reference-no"
                  className={classes.referenceNoText}
                >
                  {enforceLTR(report?.reference_no)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid>
                <Typography fontWeight={"bold"}>
                  {translate("Provider")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  id="transaction-provider"
                  className={classes.collapseItemValue}
                >
                  {" "}
                  {report?.provider}
                </Typography>
              </Grid>
            </Grid>
            {conditionalReturn(
              isAdmin && report?.seller_id,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("Seller ID")}
                  </Typography>
                </Grid>
                <CopyButton
                  textToCopy={report?.seller_id?.toString()}
                  iconClassName={classes.copyIcon}
                  buttonClassName={classes.hCopyButton}
                />{" "}
                :
                <Grid item>
                  <Typography
                    id="transaction-seller-id"
                    className={classes.collapseItemValue}
                  >
                    {report?.seller_id}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {conditionalReturn(
              isAdmin && report?.topup_account,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("topUpAccount")}
                  </Typography>
                </Grid>
                <CopyButton
                  textToCopy={report?.topup_account?.toString()}
                  iconClassName={classes.copyIcon}
                  buttonClassName={classes.hCopyButton}
                />{" "}
                :
                <Grid item>
                  <Typography
                    id="transaction-topup-account"
                    className={classes.collapseItemValue}
                  >
                    {report?.topup_account}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item md={4}>
            <Grid container>
              {conditionalReturn(
                !isNullOrUndefined(report?.cancelrequest),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Canceled Request")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.cancelrequest?.toString()}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.canceled),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Canceled")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color={
                        isSuccessfulTrans
                          ? "green"
                          : isFailedTrans
                          ? "red"
                          : undefined
                      }
                      className={classes.collapseItemValue}
                    >
                      {report?.canceled?.toString()}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.["manual correction"]),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography
                      className="list-label"
                      fontWeight={"bold"}
                      color={isManualCorrection ? "blue" : undefined}
                    >
                      {translate("Manual Correction")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color={
                        isSuccessfulTrans
                          ? "green"
                          : isFailedTrans
                          ? "red"
                          : undefined
                      }
                      className={classes.collapseItemValue}
                    >
                      {report?.["manual correction"]}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.note),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Note")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.note}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.returned_message),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      Returned message:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.returned_message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.PCI),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      PCI:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.PCI}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.dealercost),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Dealer Coast")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.dealercost}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={1} sx={{ width: "100%" }}>
              <Grid
                item
                md={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {cardAmountHandle(report)}
              </Grid>
              {conditionalReturn(
                showCancel,
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  sx={{
                    "& *": {
                      padding: 0,
                      margin: 0,
                    },
                  }}
                >
                  <CancelTransactionModal
                    tranId={report.transid}
                    isCNumber={report?.provider === "Jawwal"}
                  />
                </Grid>
              )}
              {conditionalReturn(
                showCancelRequist,
                <Grid item container justifyContent="center" md={12} xs={12}>
                  <HourglassEmptyIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );

      const className = `${
        ["proccessing", "pending"].includes(report.status) && "table-active"
      } ${isSuccessfulReport && "table-green"} ${
        isFailedReport && classes.tableDanger
      }`;

      return {
        id: new Date().getTime(),
        cells: cells.filter((c) => !!c),
        props: { className },
        collapseRow: {
          component: collapse,
          cellProps: { colspan: 100 },
        },
      };
    });

    return (
      <HCard style={{ minHeight: "200px" }} id="transactions-table-card">
        <HCardHeader color="primary" icon>
          <Typography
            color="black"
            sx={{ display: "inline-block", fontSize: "20px" }}
          >
            {translate("Transaction Table")}
          </Typography>
          <RefreshButton
            onClick={() => {
              handleLastTransactions("0", sort.label);
            }}
          />
          {conditionalReturn(
            hide,
            <VisibilityOffIcon style={{ margin: 0, color: "black" }} />
          )}
        </HCardHeader>
        <ShareModal
          shareModal={openShareModal}
          setShareModal={setOpenShareModal}
          referalCode={referalCode}
        />
        {isLoading ? (
          <Box>
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Box>
        ) : (
          <HTable
            rowOptions={{
              cellProps: {
                padding: "none",
              },
            }}
            emptyViewText={translate("No data to show")}
            tableContainerProps={{
              component: "div",
            }}
            heads={heads}
            rows={rows}
          />
        )}
      </HCard>
    );
  }

  return renderHTable();
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  last: state.reports.lastTransaction,
  isDarktheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { getLastTransaction, userData })(
  TransactionTable
);
