import React, { useEffect, useMemo, useState } from "react";
import ApiRequest from "../../actions/ApiRequest";
import Select from "core/core-components/Select/Select";
import {
  Alert,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import translate from "i18n/translate";
import Modal from "components/common/Modal";
import { set } from "lodash";
import { intl } from "i18n/provider";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";
import Toast from "components/common/Toast";
import { connect } from "react-redux";

const Icloud = ({ isDarktheme }) => {
  const [iphoneTypes, setIphoneTypes] = useState([]);
  const [selectedIphone, setSelectedIphone] = useState({
    model: intl.formatMessage({ id: "Select Iphone" }),
    id: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [imei, setImei] = useState("");
  const [confirmImei, setConfirmImei] = useState("");
  const [isImeiMatch, setIsImeiMatch] = useState(true);
  const [isConfirmImeiFocused, setIsConfirmImeiFocused] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [sendIngDataLoading, setSendIngDataLoading] = useState(false);
  const [imeiIsValid, setImeiIsValid] = useState(false);

  const options = useMemo(
    () =>
      iphoneTypes.map((item) => ({
        label: `${item.model}    (${item["customer_price"]}${intl.formatMessage(
          {
            id: "ILS",
          }
        )})`,
        value: item.id,
      })),
    [iphoneTypes]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiRequest.post(
          "get_iphone_models?iphone_type=unlock"
        );
        setIphoneTypes(response.data);
        setIsLoading(false);
      } catch (error) {
        Notify.failure(error.message);
      }
    };

    fetchData();
  }, []);

  const formatImei = (imei) => {
    return imei.replace(/(.{4})/g, "$1 ").trim();
  };

  const handleSelectIphone = (selectedIphone) => {
    setSelectedIphone(
      iphoneTypes.find((item) => item.id === selectedIphone.value)
    );
  };

  const handleImeiChange = (event) => {
    const formattedValue = event.target.value.replace(/\s+/g, "");
    setImei(formatImei(formattedValue));
    setIsImeiMatch(formattedValue === confirmImei.replace(/\s+/g, ""));
    if (!/^\d{15}$/.test(formattedValue)) {
      return false; // IMEI must be exactly 15 digits
    }
    // Apply the Luhn algorithm to check the validity
    let sum = 0;
    for (let i = 0; i < 14; i++) {
      let digit = parseInt(formattedValue.charAt(i));
      if (i % 2 !== 0) {
        digit *= 2;
        if (digit > 9) {
          digit = (digit % 10) + 1;
        }
      }
      sum += digit;
    }

    // The check digit is the number required to make the sum a multiple of 10
    const checkDigit = (10 - (sum % 10)) % 10;
    console.log(checkDigit, checkDigit === parseInt(formattedValue.charAt(14)));

    setImeiIsValid(checkDigit === parseInt(formattedValue.charAt(14)));
  };

  const handleConfirmImeiChange = (event) => {
    const formattedValue = event.target.value
      .replace(/\s+/g, "")
      .substring(0, 15);
    setConfirmImei(formatImei(formattedValue));
    setIsImeiMatch(imei.replace(/\s+/g, "") === formattedValue);
  };

  const handleSubmit = () => {
    setOpenModal(true);
    const imeiNumber = `\u202A${imei}\u202C`;
    const formattedMessage = intl.formatMessage(
      { id: "confirmation_msg" },
      {
        imei: `<span style="font-weight: bold; font-size: 1.2em; ">${imeiNumber}</span>`,
        iphone_type: `<span style="font-weight: bold; font-size: 1.2em;">${selectedIphone.model}</span>`,
        customer_price: `<span style="font-weight: bold; font-size: 1.2em;">${selectedIphone["customer_price"]}</span>`,
        unlock_price: `<span style="font-weight: bold; font-size: 1.2em;">${
          selectedIphone["customer_price"] * 0.85
        }</span>`,
      }
    );
    setModalMessage(formattedMessage);
  };

  const handleOnConfirm = async () => {
    setSendIngDataLoading(true);
    try {
      const response = await ApiRequest.post(
        `icloud_unlock?imei=${imei.replace(/\s+/g, "")}&id=${selectedIphone.id}`
      );
      if (response.data.status === "failed") {
        throw new Error(response.data.message);
      }
      Toast.fire({
        icon: "success",
        title: intl.formatMessage({
          id: "Your request has been sent successfully",
        }),
      });
      setImei("");
      setConfirmImei("");
      setSelectedIphone({ model: translate("Select Iphone"), id: undefined });
    } catch (error) {
      Notify.failure(error.message);
      Toast.fire({
        icon: "error",
        title: intl.formatMessage({ id: "Your request has been failed" }),
      });
    } finally {
      setSendIngDataLoading(false);
    }
    setOpenModal(false);
  };

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("Icloud Unlock Service")}</h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <Box
            sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
          >
            <CircularProgress />
          </Box>
        ) : options.length > 0 ? (
          <div style={{ width: 400, margin: "0 auto" }}>
            <Select
              options={options}
              value={{
                label: `${selectedIphone.model} ${
                  selectedIphone["customer_price"]
                    ? `(${selectedIphone["customer_price"]}${intl.formatMessage(
                        {
                          id: "ILS",
                        }
                      )})`
                    : ""
                }`,
                value: selectedIphone.id,
              }}
              isClearable
              onChange={handleSelectIphone}
            />
            {selectedIphone.id && (
              <div>
                <TextField
                  label="IMEI Number"
                  value={imei}
                  onChange={handleImeiChange}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    maxLength: 18, // Allowing for spaces between every 4 digits
                    onCopy: (e) => e.preventDefault(), // Prevent copying from this field
                  }}
                  error={!imeiIsValid && imei.replace(/\s+/g, "").length === 15}
                  style={{
                    filter: !isConfirmImeiFocused ? "none" : "blur(3px)",
                    direction: "ltr",
                  }}
                  helperText={
                    !imeiIsValid && imei.replace(/\s+/g, "").length === 15
                      ? translate("Your IMEI number is not valid")
                      : translate(
                          "Enter IMEI number without spaces and must be 15 digits long"
                        )
                  }
                />
                <TextField
                  label={translate("Confirm IMEI Number")}
                  value={confirmImei}
                  onChange={handleConfirmImeiChange}
                  onFocus={() => setIsConfirmImeiFocused(true)}
                  onBlur={() => setIsConfirmImeiFocused(false)}
                  disabled={
                    imei.replace(/\s+/g, "").length !== 15 || !imeiIsValid
                  }
                  margin="normal"
                  fullWidth
                  inputProps={{
                    maxLength: 18, // Allowing for spaces between every 4 digits
                  }}
                  style={{
                    direction: "ltr",
                  }}
                  error={!isImeiMatch && confirmImei !== ""}
                  helperText={
                    !isImeiMatch && confirmImei !== ""
                      ? translate("IMEI numbers do not match")
                      : ""
                  }
                />
                <Alert
                  severity="warning"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {translate("imei_confirmation_warning")}
                  </Typography>
                </Alert>

                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={
                    !isImeiMatch ||
                    confirmImei.replace(/\s+/g, "").length !== 15 ||
                    imei.replace(/\s+/g, "").length !== 15
                  }
                >
                  {translate("submit")}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>{translate("No Iphone Types")}</div>
        )}
      </div>
      {openModal && (
        <Modal
          visible={true}
          onClose={() => setOpenModal(false)}
          width={"45vw"}
          title={intl.formatMessage({ id: "Icloud Unlock Service" })}
        >
          <Typography dangerouslySetInnerHTML={{ __html: modalMessage }} />
          <div
            className="d-flex justify-content-end align-items-center mt-4"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger mx-3"
              onClick={() => setOpenModal(false)}
            >
              {translate("Cancel")}
            </button>
            <LoadingButton
              onClick={handleOnConfirm}
              loading={sendIngDataLoading}
              variant="contained"
            >
              {translate("Confirm")}
            </LoadingButton>
          </div>
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(Icloud);
