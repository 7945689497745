import React, { useState } from "react";
import { connect } from "react-redux";

import Modal from "./Modal";
import TextFieldGroup from "./TextFieldGroup";
import { cancelTransction } from "../../actions/reportsAction";
import { IconButton } from "../../../node_modules/@mui/material/index";
import AutorenewSharpIcon from "@mui/icons-material/AutorenewSharp";

import { intl } from "i18n/provider";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  rotatingIcon: {
    // fontSize: "2rem",
    // marginLeft: "0.5rem",
  },
  rotatingIconActive: {
    animation: "$rotation 2s infinite linear",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
});

const CancelTransactionModal = ({
  tranId,
  callback,
  isCNumber,
  notClickable = false,
  canceled = false,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [cnumber, setCNumber] = useState("");
  const [transLoading, setTransLoading] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");

  const cancelTransaction = (tranId) => {
    if (isCNumber && !isValidNumber(cnumber)) {
      setInvalidMessage("Please Enter A Valid Number");
      return;
    }
    setInvalidMessage("");
    setTransLoading(true);
    cancelTransction(tranId, cnumber)
      .then((res) => {
        callback && callback();
      })
      .finally(() => {
        setTransLoading(false);
        setOpen(false);
        setCNumber("");
      });
  };

  const isValidNumber = (number) => {
    if (
      number === undefined ||
      number.split("").length < 10 ||
      number.split("").length > 10
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <IconButton
        title="Cancel transaction"
        sx={
          !notClickable
            ? {
                backgroundColor: "#ebebeb",
                margin: "0  10px ",
              }
            : {}
        }
        onClick={notClickable ? () => {} : () => setOpen(true)}
        color={canceled ? "success" : "error"}
      >
        <AutorenewSharpIcon
          className={`${classes.rotatingIcon} ${classes.rotatingIconActive}`}
          sx={
            notClickable
              ? {
                  fontSize: 14,
                }
              : {}
          }
        />
      </IconButton>
      <Modal
        title={intl.formatMessage({ id: "cancel_transaction" })}
        visible={open}
        onClose={() => {
          setOpen(false);
          setCNumber("");
          setInvalidMessage("");
        }}
        onSuccess={() => cancelTransaction(tranId)}
        onSuccessLoading={transLoading}
      >
        <div
          className="phone-form"
          style={{
            paddingLeft: 0,
          }}
        >
          <h6 className="mt-2">
            Are you sure you want to cancel this transaction ?{" "}
          </h6>
          {isCNumber && (
            <TextFieldGroup
              type="number"
              value={cnumber}
              onChange={(e) => setCNumber(e.target.value)}
              label="Correct Number"
            />
          )}
          {invalidMessage && (
            <small
              className="form-text text-left"
              style={{ color: "red", width: "100%" }}
            >
              {invalidMessage}
            </small>
          )}
        </div>
      </Modal>
    </>
  );
};

export default connect(null)(CancelTransactionModal);
