import {
    CHANGE_THEME
} from "../actions/types";
const initialState = {
    isDarktheme: (localStorage.getItem('darkMode') === 'true') ? true : false,
};

const darkThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            localStorage.setItem('darkMode', action.payload)
            return {
                ...state,
                isDarktheme: action.payload
            };
        default:
            return state;
    }
}

export default darkThemeReducer