import React from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { intl } from "../../../i18n/provider";
import TextFieldGroup from "../../common/TextFieldGroup";
import Select from "react-select";
import Button from "../../common/Button";

import "./style.css";

const insuranceTypes = [
  {
    value: "comprehensive",
    label: intl.formatMessage({ id: "Comprehensive Insurance" }),
  },
  { value: "basic", label: intl.formatMessage({ id: "Basic Insurance" }) },
];

const PriceStepper = ({
  setVehiclePrice,
  vehiclePrice,
  handleNext,
  handleBack,
  insuranceCalculationsLoading,
  insuranceType,
  setInsuranceType,
  isDarktheme,
}) => {
  return (
    <div className="vehicle-stepper">
      <div className="select-input">
        <label
          style={
            !isDarktheme
              ? { marginBottom: 6 }
              : { marginBottom: 6, color: "white" }
          }
        >
          <span>
            {intl.formatMessage({ id: "Select Insurance Type" })}
            <i className="asterisk">*</i>
          </span>
        </label>
        <Select
          name="ComprehensiveType"
          placeholder={intl.formatMessage({ id: "Select Insurance Type" })}
          options={insuranceTypes}
          value={insuranceType}
          required={true}
          onChange={(v) => {
            setInsuranceType(v);
            setVehiclePrice("");
          }}
        />
      </div>
      {insuranceType?.value === "comprehensive" && (
        <div className="select-input">
          <TextFieldGroup
            name="vehiclePrice"
            placeholder={intl.formatMessage({ id: "Vehicle Value" })}
            label={intl.formatMessage({ id: "For Comprehensive Fee Only" })}
            value={vehiclePrice}
            type="number"
            onChange={(e) => setVehiclePrice(e.target.value)}
          />
        </div>
      )}

      <div
        className="d-flex justify-content-between align-items-center mt-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0 15px",
        }}
      >
        <button
          className="btn btn-primary back"
          onClick={handleBack}
          style={{ position: "static" }}
        >
          {translate("Back")}
        </button>
        <Button
          title="next"
          loading={insuranceCalculationsLoading}
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleNext}
          disabled={
            !insuranceType ||
            (insuranceType?.value === "comprehensive" && !vehiclePrice)
          }
          style={{ position: "static" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  insurances: { insuranceCalculationsLoading },
  darkTheme: { isDarktheme },
}) => {
  return {
    insuranceCalculationsLoading,
    isDarktheme,
  };
};

export default connect(mapStateToProps, null)(PriceStepper);
