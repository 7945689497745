export function isEnglish(str = "") {
  const english = /^[A-Za-z0-9]*$/;

  return english.test(
    str.trim().replaceAll(" ", "").replaceAll("!", "").replaceAll("?", "")
  );
}

export function isCurrentLanguageArabic() {
  const currentLang = localStorage.getItem("langCity");

  return currentLang === "ar";
}
