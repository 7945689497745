import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiRating from '@mui/material/Rating';

const ratingColor = (value) => {
    if (value === 1) {
        return 'red'
    }
    if (value === 2) {
        return '#ff6d75'
    }
    if (value === 3) {
        return 'green'
    }
    if (value === 4) {
        return 'yellow'
    }
    if (value === 5) {
        return 'unset'
    }
}

const StyledRating = styled(MuiRating)(({ value }) => ({
    '& .MuiRating-iconFilled': {
        color: `${ratingColor(value)}`,
    },
    '& .MuiRating-iconHover': {
        color: `${ratingColor(value)}`,
    },
}));

const Rating = ({ value }) => {
    return (
        <Box
            sx={{
                '& > legend': { mt: 2 },
                display: 'flex'
            }}
        >
            <StyledRating
                name="customized-color"
                value={value}
                readOnly
            />
        </Box>
    );
}

export default Rating