import { makeStyles } from "@mui/styles";

const useAmountCardStyles = makeStyles(() => ({
  cardGridRoot: {
    backgroundColor: (props) => props.color,
    cursor: (props) => (props.onClick ? "pointer" : "auto"),
  },
}));

export default useAmountCardStyles;
