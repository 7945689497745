import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCreditModalStyles from "./AddCreditModalStyles";
import PPTextField from "../../../core/core-components/PPTextField/PPTextField";
import translate from "../../../i18n/translate";
import { useDispatch, useSelector } from "react-redux";
import {
  addSellerCredit,
  getSellerDebt,
  getSellers,
} from "../../../actions/sellerCreditsAction";
import { GET_SELLER_DEBT } from "../../../actions/types";
import { userData } from "../../../actions/userAction";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ConformationModal from "core/core-components/ConformationModal/ConformationModal.jsx";
import { useIntl } from "react-intl";

const useStyle = makeStyles(AddCreditModalStyles);
function AddCreditModalComponent(props) {
  const classes = useStyle(props);
  const { seller, onClose } = props;
  const dispatch = useDispatch();
  const [isFullPaid, setIsFullPaid] = useState(false);
  const [paidAmount, setPaidAmount] = useState("0");
  const [formErrors, setFormErrors] = useState({});
  const [creditValue, setCreditValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasDebitListClosed, setIsListClosed] = useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isConfirmationOpened, setIsConfirmationOpened] = React.useState(false);

  const sellerDebit = useSelector((state) => state.credits.sellerDebts);
  const isLoading = useSelector((state) => state.credits.loading);
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getSellerDebt(seller?.value, false));

    return () => {
      dispatch({ type: GET_SELLER_DEBT, payload: [] });
    };
  }, [seller?.value, dispatch]);

  useEffect(() => {
    if (isNaN(paidAmount) && !formErrors?.paidAmount) {
      setFormErrors((prev) => ({ ...prev, paidAmount: true }));
    } else if (!isNaN(paidAmount) && formErrors?.paidAmount) {
      setFormErrors((prev) => {
        delete prev.paidAmount;
        return prev;
      });
    }
  }, [paidAmount, formErrors]);

  useEffect(() => {
    if (isNaN(creditValue) && !formErrors?.creditValue) {
      setFormErrors((prev) => ({ ...prev, creditValue: true }));
    } else if (!isNaN(creditValue) && formErrors?.creditValue) {
      setFormErrors((prev) => {
        delete prev.creditValue;
        return prev;
      });
    }
  }, [creditValue, formErrors]);

  function isValidForm() {
    let isValid = true;

    if (!isFullPaid && !paidAmount?.length) {
      isValid = false;
    }

    if (!creditValue?.length) {
      isValid = false;
    }

    if (Boolean(Object.keys(formErrors).length)) {
      isValid = false;
    }

    if (Number(creditValue) === 0) {
      isValid = false;
    }

    return isValid;
  }

  const handleAddCredit = () => {
    if (!isValidForm()) return;

    // Show confirmation dialog
    setIsConfirmationOpened(true);
  };

  const onSubmit = () => {
    setIsConfirmationOpened(false);
    setIsSubmitting(true);
    dispatch(
      addSellerCredit(
        creditValue,
        seller.value,
        () => {},
        isFullPaid ? creditValue : paidAmount,
        noteValue
      )
    ).then(() => {
      dispatch(getSellers());
      onClose();
      dispatch(userData());
    });
  };

  function closeDebitList() {
    setIsListClosed(true);
    setAnchorEl(null);
  }

  function renderDebitListPopover() {
    return (
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeDebitList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container maxWidth={"258px"} maxHeight={"150px"}>
          <Grid
            justifyContent={"flex-end"}
            container
            item
            md={12}
            height={"32px"}
            xs={12}
          >
            <IconButton onClick={closeDebitList}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container md={12} xs={12} padding={1} paddingTop={0}>
            {sellerDebit?.length ? (
              <Grid container item>
                <Grid item xs={4} md={4}>
                  <Typography fontWeight={"bolder"} fontSize={"smaller"}>
                    {translate("date")}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography fontWeight={"bolder"} fontSize={"smaller"}>
                    {translate("amount")}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography fontWeight={"bolder"} fontSize={"smaller"}>
                    {translate("paid_amount")}
                  </Typography>
                </Grid>
                {sellerDebit?.map((d, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4} md={4}>
                      <Typography fontSize={"smaller"}>
                        {moment(d.datetime?.split(" ")[0]).format("YYYY-MM-DD")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography fontSize={"smaller"}>{d.amount}</Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography fontSize={"smaller"}>
                        {d.amount_paid}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Grid container item justifyContent={"center"}>
                No data
              </Grid>
            )}
          </Grid>
        </Grid>
      </Popover>
    );
  }

  function renderInputs() {
    return (
      <>
        {renderDebitListPopover()}
        {isSubmitting ? renderLoaderBackdrop() : null}
        <Grid
          container
          flexDirection="row"
          direction="row"
          justifyContent={"center"}
          alignContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            className={classes.sellerInfoContainer}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12} xs={12}>
              <Typography color="GrayText">
                {translate("seller_info")}
              </Typography>
            </Grid>
            <Grid item md={6} container justifyContent={"center"} xs={12}>
              <PPTextField
                defaultValue={seller?.value}
                disabled
                textBold
                label={translate("Seller ID")}
              />
            </Grid>
            <Grid item md={6} container justifyContent={"center"} xs={12}>
              <PPTextField
                defaultValue={seller?.label?.replace(/[0-9]/g, "")}
                disabled
                textBold
                label={translate("seller_name")}
              />
            </Grid>
            <Grid item md={6} container justifyContent={"center"} xs={6}>
              <PPTextField
                defaultValue={`${seller?.balance || 0}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₪</InputAdornment>
                  ),
                }}
                disabled
                textBold
                label={translate("current_balance")}
              />
            </Grid>
            <Grid item md={6} container justifyContent={"center"} xs={6}>
              <PPTextField
                textBold
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₪</InputAdornment>
                  ),
                }}
                onClick={() => {
                  history.push(`/seller_debt?seller_id=${seller?.value}`);
                }}
                className={classes.debitInput}
                onMouseMove={(e) => {
                  if (!anchorEl && !hasDebitListClosed) {
                    setAnchorEl(e.currentTarget);
                  }
                }}
                value={`${sellerDebit?.[sellerDebit.length - 1] || 0}`}
                label={translate("current_debit")}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={classes.inputsGridContainer}
            md={12}
            justifyContent={"center"}
            flexDirection="row"
            paddingTop={"24px !important"}
          >
            <Grid item md={6} container justifyContent={"center"} xs={6}>
              <PPTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₪</InputAdornment>
                  ),
                }}
                value={creditValue}
                inputMode="numeric"
                onChange={(event) => {
                  setCreditValue(event?.target?.value?.trim());
                }}
                disabled={isSubmitting}
                helperText={
                  formErrors?.creditValue ? translate("number_only") : ""
                }
                error={formErrors?.creditValue}
                label={translate("credit_value")}
                autoFocus
              />
            </Grid>
            <Grid item md={6} container justifyContent={"center"} xs={6}>
              <PPTextField
                value={paidAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₪</InputAdornment>
                  ),
                }}
                inputMode="numeric"
                onChange={(event) => {
                  setPaidAmount(event?.target?.value?.trim());
                }}
                disabled={isFullPaid || isSubmitting}
                helperText={formErrors?.paidAmount ? "Numbers Only!" : ""}
                error={formErrors?.paidAmount}
                label={translate("paid_amount")}
              />
            </Grid>
            <Grid item md={12} container justifyContent={"center"} xs={12}>
              <PPTextField
                fullWidth
                value={noteValue}
                disabled={isSubmitting}
                inputMode="numeric"
                multiline
                onChange={(event) => {
                  setNoteValue(event?.target?.value);
                }}
                label={translate("note")}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            container
            display={"flex"}
            justifyContent={"center"}
            sx={{ display: { xs: "none", md: "initial" } }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    isSubmitting ||
                    creditValue === "0" ||
                    !creditValue?.trim()?.length
                  }
                  checked={isFullPaid}
                  onChange={(_, checked) => setIsFullPaid(checked)}
                />
              }
              label="Full Paid"
              sx={{ margin: "auto" }}
            />
          </Grid>
          <Grid
            item
            container
            gap={2}
            xs={12}
            // justifyContent="center"
            paddingTop={"24px !important"}
          >
            <Grid item xs={3}>
              <Button variant="outlined" color="error" onClick={onClose}>
                {translate("cancel")}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                disabled={!isValidForm() || isSubmitting}
                onClick={handleAddCredit}
              >
                {translate("submit")}
              </Button>
            </Grid>
            <Grid
              item
              // justifyContent={"center"}
              xs={4}
              sx={{ display: { md: "none" } }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      isSubmitting ||
                      creditValue === "0" ||
                      !creditValue?.trim()?.length
                    }
                    checked={isFullPaid}
                    onChange={(_, checked) => setIsFullPaid(checked)}
                  />
                }
                label={<span style={{ fontSize: "14px" }}>Full Paid</span>}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderLoaderBackdrop() {
    return (
      <Grid
        className={classes.loaderBackdrop}
        container
        justifyContent={"center"}
        alignItems="center"
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  function renderLoader() {
    return (
      <Grid container justifyContent={"center"} alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  function renderConfirmationModal() {
    const messageTemplate = intl.formatMessage({
      id: "add_credit_conformation_modal_msg",
    });
    const message = messageTemplate
      .replace(
        "{total}",
        `<span style="font-weight: bold; font-size: 1.2em;">${creditValue}</span>`
      )
      .replace(
        "{seller_name}",
        `<span style="font-weight: bold; font-size: 1.2em;">${seller?.label?.replace(
          /[0-9]/g,
          ""
        )}</span>`
      )
      .replace(
        "{seller_number}",
        `<span style="font-weight: bold; font-size: 1.2em;">${seller?.value}</span>`
      );

    const body = <Typography dangerouslySetInnerHTML={{ __html: message }} />;
    return (
      <ConformationModal
        conformBtnProps={{ loading: isSubmitting, onClick: onSubmit }}
        title={intl.formatMessage({ id: "confirmation" })}
        body={body}
        open={isConfirmationOpened}
        onCancelClick={() => setIsConfirmationOpened(false)}
      />
    );
  }

  return (
    <React.Fragment>
      {renderConfirmationModal()}
      <Modal
        open={true}
        keepMounted={false}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          className={classes.cardContainer}
          sx={{
            top: {
              xs: "5% !important",
              md: "50% !important",
            },
            transform: {
              xs: "translate(-50%, 0%) !important",
              md: "translate(-50%, -50%) !important",
            },
          }}
        >
          <CardHeader
            title={translate("add credits")}
            // sx={{ paddingBottom: "0" }}
          />
          <CardContent className={classes.cardBody}>
            {isLoading ? renderLoader() : renderInputs()}
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  );
}

const propTypes = {
  seller: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function AddCreditModal(props) {
  const { open, ...rest } = props;
  if (open) {
    return <AddCreditModalComponent {...rest} />;
  }

  return <React.Fragment></React.Fragment>;
}

AddCreditModal.propTypes = propTypes;
