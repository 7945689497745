import axios from 'axios';
import Notiflix from 'notiflix';

export const IP = localStorage.getItem('ip');
export const BASE_URL = 'https://api.celltop.vip/api/v1/resources/';
export const DEFAULT_HEADERS = {
     'Access-Control-Allow-Origin': 'http://localhost:8080',
     IP: IP,
};

axios.interceptors.response.use(
     function (config) {
          if (
               config.data?.reason?.includes?.('token') &&
               config.data?.reason?.includes?.('expired') &&
               !window.location.href.includes?.('/signin')
          ) {
               // getIP()
               localStorage.removeItem('jwtUserToken');
               localStorage.removeItem('companies');
               localStorage.removeItem('token exp');
               window.location.href = '/signin?token-expired';
          }
          return config;
     },
     function (error) {
          return Promise.reject(error);
     }
);

axios.interceptors.request.use(
     (config) => {
          // use config.params if it has been set
          config.params = config.params || {};
          // add any client instance specific params to config
          config.params['dl'] = localStorage.langCity ?? "en";

          const token = localStorage.jwtUserToken;

          if (token) {
               axios.defaults.headers['token'] = token;
          }

          return config;
     },
     (error) => {
          Promise.reject(error);
     }
);

axios.interceptors.response.use(
     function (response) {
          // Do something with response data
          return response;
     },
     function (error) {
          // Do something with response error
          Notiflix.Notify.failure(
               'Something went Wrong - ' + error?.message
          );
          return Promise.reject(error);
     }
);

axios.defaults.baseURL = BASE_URL;

axios.defaults.headers = {
     ...axios.defaults.headers,
     ...DEFAULT_HEADERS,
};

window.axios = axios;

export default axios;
