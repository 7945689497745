import en from "./en.json";
import ar from "./ar.json";
import he from "./he.json";

const messages = {
  en: en,
  ar: ar,
  he: he,
};

export default messages


