import { createTheme } from "@mui/system";

const theme = createTheme();

const AddCreditModalStyles = () => ({
  cardContainer: {
    position: "absolute",
    // top: "50%",
    left: "50%",
    // // padding: "16px 8px",
    // transform: "translate(-50%, -50%)",
    boxShadow: 24,
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "570px",
    },
  },
  cardBody: {
    maxWidth: 450,
    margin: "0 auto",
  },
  sellerInfoContainer: {
    "& > div": {
      padding: 8,
    },
    borderBottom: `1px solid #9e9e9e96`,
  },
  inputsGridContainer: {
    "& > div": {
      padding: "8px !important",
    },
  },
  loaderBackdrop: {
    position: "absolute",
    margin: 0,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#00000040",
    height: "101%",
    width: "100%",
    cursor: "not-allowed",
    userSelect: "none",
    pointerEvents: "none",
  },
  debitInput: {
    "& label": {
      paddingTop: 4,
    },
    "& .MuiInputAdornment-root": {
      paddingTop: 8,
    },
    "& input": {
      cursor: "pointer",
      paddingTop: 16,
      paddingBottom: 8,
    },
  },
});
export default AddCreditModalStyles;
