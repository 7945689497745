import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
import GetSettings from "./IsrealCompanies/IsraelSettings";
const JawwalSettings = () => {
  const history = useHistory();
  const [jawwalAccounts, setJawwalAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    fetchJawwalAccounts();
  }, []);

  const fetchJawwalAccounts = async () => {
    try {
      const response = await ApiRequest.post(
        `get_topup_accounts?carrier=jawwal`
      ); // Replace with your API endpoint
      const data = response.data;
      setJawwalAccounts(data);
      // console.log("Jawwal accounts:", response.data);
    } catch (error) {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
    }
  };

  const handleToggleAccount = (account) => {
    // Enable or disable the selected Jawwal account
    setIsLoading(true);
    ApiRequest.post(
      `update_settings?user_name=${account.topup_account}&new_value=${
        account.enable === "true" ? "false" : "true"
      }&id=${1}&carrier=jawwal`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          throw new Error(res.data.message);
        }
        const updatedAccounts = jawwalAccounts.map((acc) => {
          if (acc.ccid === account.ccid) {
            return { ...acc, enable: acc.enable === "true" ? "false" : "true" };
          }
          return acc;
        });
        setJawwalAccounts(updatedAccounts);
        setIsChanged(true);
      })
      .catch((err) => {
        Toast.fire({
          title: "Something Went Wrong!",
          icon: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isLoading && isChanged) {
    Toast.fire({
      title: "Settings Updated Successfully!",
      icon: "success",
    });
    setIsChanged(false);
  }

  const onClickJawwalAccount = async (account) => {
    console.log("Account:", account);
    const res = await ApiRequest.post(
      `edit_topup_account?operation=get&carrier=jawwal&user_name=${account.topup_account}`
    );
    history.push({
      pathname: `/edit-account`,
      state: {
        data: res.data[0],
        carrier: "jawwal",
        active: account.enable === "true",
      },
    });
  };

  return (
    <GetSettings carrier="jawwal">
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          gap: "30px",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {jawwalAccounts?.map((account) => (
          <li
            key={account.ccid}
            style={{
              display: "flex",
              alignItems: "center",

              justifyContent: "flex-start",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={account.enable === "true" ? true : false}
                  onChange={() => handleToggleAccount(account)}
                />
              }
            />
            <p
              style={{
                marginLeft: -20,
                cursor: "pointer",
              }}
              onClick={() => onClickJawwalAccount(account)}
            >
              {account.topup_account}
            </p>
          </li>
        ))}
      </ul>
    </GetSettings>
  );
};
export default JawwalSettings;
