import React from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { intl } from "../../../i18n/provider";
import { CircularProgress } from "@mui/material";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";

const SubNav = ({
  mobile,
  isPlus,
  Jawwal3gLoading,
  JawwalPlusLoading,
  JawwalRomLoading,
  JawwalMinLoading,
  isDarktheme,
}) => {
  const history = useHistory();
  const currentRoute = history?.location?.pathname?.split("/")[2];
  const imageWidth = IS_MOBILE_DISPLAY ? "30px" : "60px";
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const handlePathChange = (newPath, { provider_Id }) => {
    history.push({
      pathname: newPath,
      search: params.toString(),
      state: history.location.state,
      provider_Id,
    });
  };

  return (
    <div
      className={`d-flex justify-content-around sub-nav ${
        isDarktheme && "dark"
      } my-2 jawwal-sub`}
    >
      <div className="my-1">
        <a
          href={`/company/jawwalCredit/${mobile}`}
          onClick={(e) => {
            e.preventDefault();
            handlePathChange(`/company/jawwalCredit/${mobile}`, {
              provider_Id: window.history?.state?.state?.provider_Id || "001",
            });
            // history.push(`/company/jawwalCredit/${mobile}`, {
            //   provider_Id: window.history?.state?.state?.provider_Id || "001",
            // });
          }}
          className={`link-main ${
            currentRoute === "jawwalCredit" ? "active-sub" : ""
          }`}
          style={{
            width: !IS_MOBILE_DISPLAY ? "90px" : "60px",
          }}
        >
          <Toolbar
            title={intl.formatMessage({ id: "addCreadit" })}
            style={{ minHeight: "initial", padding: "0", margin: "0" }}
          >
            <Avatar
              alt="Minuts"
              src={
                require("../../../assests/images/companiesLogos/add.PNG")
                  .default
              }
              style={{ height: imageWidth, width: imageWidth }}
            />
          </Toolbar>
        </a>
      </div>
      <div className="my-1">
        <a
          href={`/company/jawwalMin/${mobile}`}
          className={`link-main ${
            currentRoute === "jawwalMin" ? "active-sub" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            if (
              (params.get("number") && params.get("number").length == 10) ||
              !params.get("number")
            ) {
              handlePathChange(`/company/jawwalMin/${mobile}`, {
                provider_Id: window.history?.state?.state?.provider_Id || "001",
              });
            }
            // history.push(`/company/jawwalMin/${mobile}`, {
            //   provider_Id: window.history?.state?.state?.provider_Id || "001",
            // });
          }}
        >
          <Toolbar
            title={intl.formatMessage({ id: "jawwalMin" })}
            style={{ minHeight: "initial", padding: "0", margin: "0" }}
          >
            {JawwalMinLoading ? (
              <CircularProgress
                style={{ height: imageWidth, width: imageWidth }}
              />
            ) : (
              <Avatar
                alt="Minuts"
                src={
                  require("../../../assests/images/companiesLogos/credit.png")
                    .default
                }
                style={{ height: imageWidth, width: imageWidth }}
              />
            )}
          </Toolbar>
        </a>
      </div>
      <div className="my-1">
        <a
          href={`/company/jawwal3g/${mobile}`}
          onClick={(e) => {
            e.preventDefault();
            if (
              (params.get("number") && params.get("number").length == 10) ||
              !params.get("number")
            ) {
              handlePathChange(`/company/jawwal3g/${mobile}`, {
                provider_Id: window.history?.state?.state?.provider_Id || "001",
              });
            }
            // history.push(`/company/jawwal3g/${mobile}`, {
            //   provider_Id: window.history?.state?.state?.provider_Id || "001",
            // });
          }}
          className={`link-main ${
            currentRoute === "jawwal3g" ? "active-sub" : ""
          }`}
        >
          <Toolbar
            title={intl.formatMessage({ id: "g3" })}
            style={{ minHeight: "initial", padding: "0", margin: "0" }}
          >
            {Jawwal3gLoading ? (
              <CircularProgress
                style={{ height: imageWidth, width: imageWidth }}
              />
            ) : (
              <Avatar
                alt="Minuts"
                src={
                  require("../../../assests/images/companiesLogos/g33.png")
                    .default
                }
                style={{ height: imageWidth, width: imageWidth }}
              />
            )}
          </Toolbar>
        </a>
      </div>

      <div className="my-1">
        <a
          className={`link-main ${
            currentRoute === "JawwalPlus" ? "active-sub" : ""
          }`}
          href={`/company/JawwalPlus/${mobile}`}
          onClick={(e) => {
            e.preventDefault();
            handlePathChange(`/company/JawwalPlus/${mobile}`, {
              provider_Id: window.history?.state?.state?.provider_Id || "001",
            });
            // history.push(`/company/JawwalPlus/${mobile}`, {
            //   provider_Id: window.history?.state?.state?.provider_Id || "001",
            // });
          }}
        >
          <Toolbar
            title="Plus"
            style={{ minHeight: "initial", padding: "0", margin: "0" }}
          >
            {JawwalPlusLoading ? (
              <CircularProgress
                style={{ height: imageWidth, width: imageWidth }}
              />
            ) : (
              <Avatar
                alt="plus"
                src={
                  require("../../../assests/images/companiesLogos/plus.png")
                    .default
                }
                style={{ height: imageWidth, width: imageWidth }}
              />
            )}
          </Toolbar>
        </a>
      </div>

      <div className="my-1">
        <a
          className={`link-main ${
            currentRoute === "jawwalRom" ? "active-sub" : ""
          }`}
          href={`/company/jawwalRom/${mobile}`}
          onClick={(e) => {
            e.preventDefault();
            handlePathChange(`/company/jawwalRom/${mobile}`, {
              provider_Id: window.history?.state?.state?.provider_Id || "001",
            });
            // history.push(`/company/jawwalRom/${mobile}`, {
            //   provider_Id: window.history?.state?.state?.provider_Id || "001",
            // });
          }}
        >
          <Toolbar
            title={intl.formatMessage({ id: "Roaming" })}
            style={{ minHeight: "initial", padding: "0", margin: "0" }}
          >
            {JawwalRomLoading ? (
              <CircularProgress
                style={{ height: imageWidth, width: imageWidth }}
              />
            ) : (
              <Avatar
                alt="roaming"
                src={
                  require("../../../assests/images/companiesLogos/roaming.png")
                    .default
                }
                style={{ height: imageWidth, width: imageWidth }}
              />
            )}
          </Toolbar>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  companies: {
    isPlus,
    Jawwal3gLoading,
    JawwalPlusLoading,
    JawwalRomLoading,
    JawwalMinLoading,
  },
  darkTheme: { isDarktheme },
}) => {
  return {
    isPlus,
    Jawwal3gLoading,
    JawwalPlusLoading,
    JawwalRomLoading,
    JawwalMinLoading,
    isDarktheme,
  };
};

export default connect(mapStateToProps)(SubNav);
