import translate from "i18n/translate";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const BillCard = ({ bill, columnStyle, isDarktheme }) => {
  return (
    <div className={`${columnStyle} mt-4 wrapper`}>
      <Link
        to={{
          pathname: bill.linkTo,
          state: {
            billTitle: bill.link,
          },
        }}
      >
        <div
          className={
            !isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"
          }
        >
          <div className="frame">
            <img
              alt="bill card"
              src={bill.imageUrl}
              // style={{ objectFit: "fill" }}
            />
          </div>
          <div
            className={
              !isDarktheme ? "card nav-layout" : "card dark-nav-layout"
            }
          >
            <h5 className="m-3 text-center">{bill.name}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(BillCard);
