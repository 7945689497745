import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { isEmpty } from "lodash";
import { getInternts } from "../../../actions/internetAction";
import { useIntl } from 'react-intl';
import Spinner from "../../ui/spinner/Spinner";
import "./style.css";
import { refreshColumnStyle } from '../../Gaming/reusableFunctions'

const Languages = {
  "en": "english",
  "ar": "arabic",
  "il": "israel",
};

const Internet = ({ getInternts, internets, isDarktheme }) => {
  const intl = useIntl()
  const [loading, isLoading] = useState([]);
  const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-6 card-md");

  useEffect(() => {
    document.title = "Internet | PhonePlay ";
    if (Array.isArray(internets) && isEmpty(internets)) {
      initInternets();
    }
    refreshColumnStyle(setColumnStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initInternets = () => {
    isLoading(true);
    getInternts(Languages[intl.locale])
      .finally(() => {
        isLoading(false);
      })
  }

  return (
    <>
      <div>
        <div className="row mb-5 wrapper">
          {internets.map((internet, index) => (
            <div key={index.toString()} className={`${columnStyle} mt-4`}>
              <div className={!isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"}>
                <div className="frame">
                  <img
                    alt={internet.id}
                    src={`${internet.url}`}
                  />
                </div>
                <div className={!isDarktheme ? "card nav-layout" : "card dark-nav-layout"}>
                  <h5 className="m-2 text-center">{internet.name}</h5>
                  <h5 className="m-2 text-center">{translate("Phone Number")}<br />{internet.phone}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading && internets.length === 0 && (<Spinner />)}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  internets: state.internets.list,
  isDarktheme: state.darkTheme.isDarktheme
});

export default connect(mapStateToProps, { getInternts })(Internet);

