import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { intl } from "../../i18n/provider";
import Modal from "../common/Modal";
import { logoutUser } from "../../actions/userAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import ControlledSwitches from "../common/Checked";

import "./styles.css";
import LanguageChooser from "../ui/Settings/Language/LanguageChooser";
import { DarkTheme } from "../../actions/darkTheme";

const AdminHeader = ({ history, user, isDarktheme, DarkTheme }) => {
  const [logoutModal, setLogOutModal] = useState(false);

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(logoutUser(history));
  };
  const handleChange = (e) => {
    if (isDarktheme) {
      DarkTheme(false);
    } else {
      DarkTheme(true);
    }
  };
  return (
    <>
      <nav className={!isDarktheme ? "admin-header" : "dark-admin-header"}>
        <div>
          <ControlledSwitches check={isDarktheme} Change={handleChange} />
        </div>
        <div className="admin-Icon-name">
          <LanguageChooser />
          <Avatar
            alt="Remy Sharp"
            src={require("../../assests/images/icons/UserProfile.PNG").default}
            onClick={() => history.push("/Profile")}
            href="#0"
            style={{
              cursor: "pointer",
            }}
          />
          <h4 className="nameTopagraphy">{user.name}</h4>
          <a
            href="#0"
            className="active text-decoration-none"
            style={{ color: "#000", fontSize: "1.2rem" }}
            onClick={() => setLogOutModal(true)}
          >
            <div
              className={
                !isDarktheme
                  ? "admin-header-logout"
                  : "dark-admin-header-logout"
              }
            >
              <LogoutIcon />
            </div>
          </a>
        </div>
      </nav>
      <Modal
        visible={logoutModal}
        onClose={() => setLogOutModal(false)}
        title={"logout"}
        width={"30vw"}
      >
        <div className="fire-free-modal mt-4">
          <h4>{intl.formatMessage({ id: "log out message" })}</h4>
          <div className="btn-section">
            <button
              style={{ margin: "0 16px" }}
              className="btn btn-primary"
              onClick={onLogoutClick}
            >
              {intl.formatMessage({ id: "logout" })}
            </button>
            <button
              className="btn btn-danger"
              onClick={() => setLogOutModal(false)}
            >
              {intl.formatMessage({ id: "Close" })}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const mapStateToProps = ({ auth: { user }, darkTheme: { isDarktheme } }) => {
  return {
    user,
    isDarktheme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    DarkTheme: (darkMode) => dispatch(DarkTheme(darkMode)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminHeader));
