import React, { useState, useEffect } from "react";
import { refreshColumnStyle } from "../Gaming/reusableFunctions";
import BillCard from "./BillsCard";
import { intl } from "../../i18n/provider";
import FineImage from "../../assests/images/PayBills/is-fines.PNG";
import { connect } from "react-redux";
import {
  paltelImage,
  Bnet,
  gemzo,
  Selco,
} from "assests/images/bundles/paltel/paltelImage";
import translate from "i18n/translate";

const paltelImageUrl = "";

const billsArr = [
  {
    id: 1,
    name: translate("Paltel Bills"),
    link: "Paltel Bills",
    imageUrl: paltelImage,
    linkTo: "/pay_bills/paltel",
  },

  {
    id: 3,
    name: translate("Jawwal Bills"),
    link: "Jawwal Bills",
    imageUrl: "https://www.asd.ps/wp-content/uploads/2016/03/jawwal.png",
    linkTo: "/pay_bills/jawwal",
  },
  {
    id: 9,
    name: translate("Ooredoo Bills"),
    link: "ooredoo_bills",
    imageUrl:
      "https://logowik.com/content/uploads/images/ooredoo3309.logowik.com.webp",
    linkTo: "/pay_bills/ooredoo_bills",
  },
  {
    id: 4,
    name: translate("Mada"),
    link: "Mada",
    imageUrl: "https://speedtest.mada.ps/logo-v.png",
    linkTo: "/pay_bills/mada",
  },
  {
    id: 2,
    name: translate("Israeli Fines"),
    link: "Israeli Fines",
    imageUrl: FineImage,
    linkTo: "/bills/israel_fines",
  },
  {
    id: 5,
    name: translate("Nedco"),
    link: "Nedco",
    imageUrl: "https://images.celltop.vip/logo_location/nedco.jpg",
    linkTo: "/pay_bills/nedco",
  },
  {
    id: 66,
    name: translate("Selco"),
    link: "Selco",
    imageUrl: Selco,
    linkTo: "/pay_bills/selco",
  },
  {
    id: 6,
    name: translate("Coolnet"),
    link: "Coolnet",
    imageUrl:
      "https://pbs.twimg.com/profile_images/1184992438/logoweb2_400x400.png",
    linkTo: "/pay_bills/coolnet",
  },
  {
    id: 7,
    name: translate("Gemzo"),
    link: "Gemzo",
    imageUrl: gemzo,
    linkTo: "/pay_bills/gemzo",
  },
  {
    id: 8,
    name: translate("Bnet"),
    link: "Bnet",
    imageUrl: Bnet,
    linkTo: "pay_bills/Bnet",
  },
];

const PayBills = ({ isDarktheme }) => {
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 card-md"
  );

  useEffect(() => {
    document.title = "Pay Bills | Phone Play";
    refreshColumnStyle(setColumnStyle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={!isDarktheme ? "bg-white" : "bg-dark"}>
        <h1 className="header-text p-4">
          {intl.formatMessage({ id: "pay bills" })}
        </h1>
      </div>

      <div className="row">
        {billsArr.map((bill) => (
          <BillCard
            key={bill.id.toString()}
            bill={bill}
            columnStyle={columnStyle}
          />
        ))}
      </div>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(PayBills);
