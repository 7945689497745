import React, { useState, useEffect } from "react";
import translate from "../../i18n/translate";
import {
  getSellerRunningReports,
  getLast3DaysRunningReports,
} from "../../actions/reportsAction";
import "./report.css";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../ui/spinner/Spinner";
import DatePicker from "react-datepicker";
import ReportHeader from "./ReportHeader";
import Pagination from "../common/Pagination";
import Select from "react-select";
import { isArray } from "lodash";
import { getSellers } from "../../actions/sellerCreditsAction";
import ExportButton from "components/common/ExportButton";
// Icons
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { GET_SELLER_REPORTS } from "actions/types";
import { Box } from "@mui/material";
import { useIsAdmin } from "shared/hooks/ppHooks";

const RuningBalance = ({
  sellerRunning,
  getSellerRunningReports,
  getLast3DaysRunningReports,
  history,
  phoneNumber,
  isDarktheme,
  auth,
  getSellers,
  sellers,
}) => {
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(
    new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
  );
  const [loading, isLoading] = useState(false);
  const [, setTotalValue] = useState(0);
  const [, setTotalSellerCost] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ value: "5", label: 5 });
  const [sellersOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState(null);
  const { isReseller, isAdmin } = useIsAdmin();
  const hide = localStorage.getItem("hide companies");
  const sellerId = useSelector((state) => state.auth.user?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_SELLER_REPORTS,
      payload: [],
    });
  }, [dispatch]);

  useEffect(() => {
    document.title = "Report | Phone Play";
  }, []);

  useEffect(() => {
    if (sellerRunning.length) {
      setCount(Number(sellerRunning[0].total_count));
      // setCount(Math.ceil(Number(sellerRunning[0].total_count) / sort.label));
      // setTotalCount(Number(sellerRunning[0].total_count))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerRunning]);

  useEffect(() => {
    if (history.location.search === "?checkBalance" && phoneNumber) {
      isLoading(true);
      getLast3DaysRunningReports(phoneNumber).then(() => {
        isLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    if (sellerRunning) {
      calculateTotal();
    }
    try {
      getSellers(isReseller && sellerId).then(({ all_sellers: data }) => {
        if (isArray(data)) {
          let sellersOptionsData = data?.map((item) => {
            return {
              value: item.seller_id,
              label: item.name + " " + item.seller_id,
              balance: item.balance,
            };
          });
          setSellerOptions([
            { value: "", label: translate("Select all") },
            ...sellersOptionsData,
          ]);
        }
      });
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerRunning, sellers?.length]);

  const calculateTotal = () => {
    let totalVal = 0;
    let totalSeller = 0;
    sellerRunning?.forEach((sellerProfit) => {
      totalVal = totalVal + sellerProfit.cardamount;
      totalSeller = totalSeller + sellerProfit.dealercost;
    });
    setTotalValue(totalVal);
    setTotalSellerCost(totalSeller);
  };

  const handleSearch = (offset, rows) => {
    isLoading(true);
    // console.log(seller?.value, auth.user.id);
    getSellerRunningReports(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
      phoneNumber,
      offset,
      rows,
      "",
      "",
      seller?.value || auth.user.id,
      "running_balance"
    ).then(() => {
      isLoading(false);
    });
  };

  const handleSortChange = (v) => {
    v = { value: v, label: v };
    setPage(1);
    setSort(v);
    if (sellerRunning?.length) {
      handleSearch("0", v.value);
    }
  };

  const handleOffsetValue = (event, value) => {
    setPage(value);
    let queryOffset = value * sort.label - sort.label;
    handleSearch(queryOffset, sort.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      border: "1px solid #242526",
    }),
    menu: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      color: isDarktheme ? "white" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarktheme ? "white" : "",
    }),
  };

  return (
    <>
      <div>
        <ReportHeader />

        <div className={!isDarktheme ? "mt-3" : "mt-3 dark-input"}>
          <div className="row">
            <div className="form-group row">
              <label className="col-sm-1 col-form-label">
                {translate("from")}
              </label>
              <div className="col-sm-4">
                <DatePicker
                  selected={dateFrom}
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  onChange={(e) => setDateFrom(e)}
                />
                {/* <input
                      name="from"
                      value={dateForm.from}
                      type="date"
                      className="form-control"
                      onChange={(e) => onChangeDate(e)}
                    /> */}
              </div>
              <label className="col-sm-1 col-form-label">
                {translate("to")}
              </label>
              <div className="col-sm-4">
                <DatePicker
                  selected={dateTo}
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  onChange={(e) => setDateTo(e)}
                />
                {/* <input
                      name="to"
                      value={dateForm.to}
                      type="date"
                      className="form-control"
                      onChange={(e) => onChangeDate(e)}
                    /> */}
              </div>
              <div className="col-sm-2">
                <button
                  onClick={() => handleSearch("0", sort.value)}
                  className="btn sign-but"
                >
                  {translate("search")}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "16px",
              width: 400,
            }}
          >
            {(auth.user?.seller_type === "admin" || isReseller) && (
              <>
                <h6>Select Seller</h6>
                <Select
                  options={sellersOptions}
                  value={seller}
                  className="report-dropdown"
                  onChange={(value) => setSeller(value)}
                  styles={{ height: "32px", ...customStyles }}
                />
              </>
            )}
          </div>
        </div>

        <div
          className={
            !isDarktheme ? "row table-wrapper" : "row dark-table-wrapper"
          }
        >
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "flex-start" }}
          >
            <div className="table-title">
              <h5>{translate("Running Balance Table")}</h5>
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <ExportButton reports={sellerRunning} />
              {hide === "true" ? <VisibilityOffIcon /> : null}
            </div>
          </div>

          <Pagination
            count={count}
            page={page}
            handleOffsetValue={handleOffsetValue}
            handleSortChange={handleSortChange}
            sort={sort}
          />
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col text-center">{translate("movmentNo")}</th>
                <th scope="col text-center">{translate("Trans. Type")}</th>
                <th scope="col text-center">{translate("Card Type")}</th>
                <th
                  scope="col text-center"
                  style={{ textTransform: "capitalize" }}
                >
                  {translate("Time")}
                </th>
                <th scope="col text-center">{translate("number")}</th>
                <th scope="col text-center">{translate("value")}</th>
                <th scope="col text-center">{translate("seller cost")}</th>
                <th scope="col text-center">{translate("status")}</th>
                <th scope="col text-center">{translate("seller balance")}</th>
              </tr>
            </thead>
            <tbody>
              {sellerRunning?.map((report, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{report.transid}</td>
                    <td>{report.transtype}</td>
                    <td>{report.card_type}</td>
                    <td className="text-center">
                      {moment(report.datetime).format("YYYY-MM-DD - HH:mm")}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        color: report?.cancelrequest ? "red" : "black",
                      }}
                    >
                      {report.number}
                    </td>
                    {/* <td className="text-center">{report.profit}</td> */}
                    <td className="text-center">{report.cardamount}</td>
                    <td className="text-center">{report.dealercost}</td>
                    <td className="text-center">{report.status}</td>
                    <td className="text-center">{report.newsellerbalance}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!sellerRunning?.length && (
            <div className="no-data-to-show">
              {translate("No data to show")}
            </div>
          )}
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

const mapStateToProps = (state) => ({
  sellerRunning: state.reports.sellerRunning,
  phoneNumber: state.auth.user["mobile number"],
  isDarktheme: state.darkTheme.isDarktheme,
  auth: state.auth,
  sellers: state.credits.sellers,
});

export default connect(mapStateToProps, {
  getSellerRunningReports,
  getLast3DaysRunningReports,
  getSellers,
})(RuningBalance);
