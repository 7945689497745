import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompaniesDropDown from "./CompaniesDropDown";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import TextFieldGroup from "./TextFieldGroup";
import { connect } from "react-redux";
import { jawwalPlusCheck } from "../../actions/companiesAction";
import { getAllCompanies } from "./../../actions/companiesAction";
import { useDispatch } from "react-redux";
import { setNumberChanged } from "../../actions/companiesAction";
import { Box } from "@mui/system";
import {
  IS_MOBILE_DISPLAY,
  JAWWAL_ID,
  PROVIDER_ROUTES,
  setSessionStorage,
} from "shared/constants/constants";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  checkNumberProviderApiCaller,
  getProviderAPiCaller,
} from "shared/endPoints/apiCallers";
import JAWWAL_CREDITS, {
  EMPTY_JAWWAL_CREDIT,
} from "components/companies/jawwal/credits";
import OOREDOO_CREDITS, {
  EMPTY_OOREDOO_CREDIT,
} from "components/companies/ooredoo/credits";
import { isNullOrUndefined } from "shared/utils/utils";
import {
  LOCAL_STORAGE_ITEMS_ENUM,
  SESSION_STORAGE_ITEMS_ENUM,
} from "shared/constants/localStorageItemsEnum";
import { getProviderBundleSessionKey } from "shared/utils/ppUtils";
import { cleanStorage } from "shared/utils/cleanStorage";

const CompaniesNav = ({
  logo,
  title,
  mobile,
  children,
  onRefresh,
  actionWidth,
  isDarktheme,
  jawwalPlusCheck,
  getAllCompanies,
  allCompanies,
  providerId,
  comingFromReports,
  credit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = history.location.pathname.split("/");
  const currentPath = pathname.splice(0, pathname.length - 1).join("/");
  const [showDropMenu, setShowDropMenu] = useState(false);
  const [openInput, setOpenInput] = useState(true);
  const [error, setError] = useState(false);
  const listRef = useRef(null);
  const inputRef = useRef(null);
  const mobileNumber = pathname[pathname.length - 1];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialNumber = params.get("number") || "";
  const [numberInput, setNumberInput] = useState(initialNumber);
  const isValidMobileNumber = (number) => /^\d{10}$/.test(number);
  useEffect(() => {
    if (numberInput) {
      params.set("number", numberInput);
    } else {
      params.delete("number");
    }
    history.replace({
      search: params.toString(),
      state: history.location.state,
    });
  }, [numberInput, history, location.search]);

  useEffect(() => {
    document.addEventListener("click", (e) =>
      handleClickOutside(e, listRef, setShowDropMenu)
    );

    getAllCompanies();

    if (numberInput && numberInput !== mobileNumber) {
      submitNumber();
    } else {
      setOpenInput(false);
    }

    return () => {
      document.removeEventListener("click", (e) =>
        handleClickOutside(e, listRef, setShowDropMenu)
      );
    };
  }, []);
  // 0597518854
  useEffect(() => {
    if (/^\d{10}$/.test(mobileNumber) && !numberInput) {
      comingFromReports && checkNumberProvider(mobileNumber);
      // setNumberInput(mobileNumber);
      setOpenInput(false);
    }
  }, [allCompanies]);

  function redirectToProviderRoute({ providerId, number, bundleType, bundle }) {
    const pathname =
      PROVIDER_ROUTES.getProviderRoute(providerId, bundleType) + "/" + number;
    history.push(
      {
        pathname,
        reset: true,
      },
      { provider_Id: providerId, bundle }
    );
  }
  useEffect(() => {
    if (!isValidMobileNumber(mobileNumber)) {
      setOpenInput(true);
    }
    if (credit) {
      submitNumber();
    }
  }, [mobileNumber, credit]);

  const handleClickOutside = (event, ref, setState) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setState(false);
    }
  };
  const submitNumber = (e) => {
    e?.preventDefault();
    if (numberInput === "") return;
    if (numberInput?.length !== 10) {
      setError(true);
      return;
    }
    handleReadAloud(numberInput);
    dispatch(setNumberChanged(true));
    params.delete("number");
    history.push({
      pathname: `${currentPath}/${numberInput}`,
      state: history.location.state,
    });
    !credit && checkNumberProvider(numberInput);
    setOpenInput(false);
    setNumberInput("");
    jawwalPlusCheck(numberInput);
    setError(false);
  };

  const checkNumberProvider = async (mobileNumber) => {
    const currentProvider = allCompanies?.find(
      (c) => c.id?.toString() === providerId?.toString()
    );

    const product = currentProvider?.product.toLowerCase();
    if (product) {
      try {
        const { data } = await checkNumberProviderApiCaller({
          mobileNumber: mobileNumber,
          carrier: product,
        });
        if (data) {
          onBalanceCardClick({
            mobileNumber: mobileNumber,
            mobileNumberInfo: data,
            providerId: currentProvider?.id,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  async function onBalanceCardClick({
    mobileNumber,
    mobileNumberInfo,
    providerId,
  }) {
    try {
      const pci = mobileNumberInfo.pci;
      const type = mobileNumberInfo.type;

      const isCredit = Boolean(pci?.toLocaleLowerCase()?.startsWith("c"));
      const bundleType = type?.toLocaleLowerCase();
      if (isCredit) {
        const isJawwal = providerId === JAWWAL_ID;
        const providerCredits = isJawwal ? JAWWAL_CREDITS : OOREDOO_CREDITS;
        let credit = providerCredits.find((credit) =>
          credit.id.includes(pci?.toLocaleLowerCase()?.replace("c", ""))
        );
        if (isNullOrUndefined(credit)) {
          credit = isJawwal ? EMPTY_JAWWAL_CREDIT : EMPTY_OOREDOO_CREDIT;
          credit.price = mobileNumberInfo.amount;
        }

        const sessionStorageKey = isJawwal
          ? SESSION_STORAGE_ITEMS_ENUM.JawwalCredit
          : SESSION_STORAGE_ITEMS_ENUM.ooredooCredit;

        setSessionStorage(sessionStorageKey, credit);
      } else {
        const { data } = await getProviderAPiCaller(providerId)({
          providerId,
          mobileNumber,
          bundle: bundleType,
          refresh: false,
          language: localStorage.getItem(LOCAL_STORAGE_ITEMS_ENUM.langCity),
        });

        const bundle = data?.find(
          (x) =>
            x.productid === pci ||
            x?.bundleid === pci ||
            x?.SPID === pci ||
            x?.pci === pci ||
            x?.PID === pci ||
            x.id === pci
        );

        const providerIdToSessionStorageKey = getProviderBundleSessionKey({
          providerId,
          bundleType,
        });

        if (!isNullOrUndefined(providerIdToSessionStorageKey)) {
          redirectToProviderRoute({
            providerId,
            number: mobileNumber,
            bundleType,
            bundle,
          });
          setSessionStorage(providerIdToSessionStorageKey, bundle);
        }
      }
    } catch (e) {
      console.log("AddBalancePage => onBalanceCardClick", e?.message);
    }
  }

  const handleReadAloud = (number) => {
    if (!window.speechSynthesis) {
      // SpeechSynthesis not supported by the browser
      return;
    }
    // window.speechSynthesis.cancel();
    const speechText = number;

    const utterance = new SpeechSynthesisUtterance(speechText);
    utterance.lang = localStorage.getItem("langCity");
    // utterance.rate = 1.5;
    window.speechSynthesis.speak(utterance);
  };
  return (
    <Box
      className={!isDarktheme ? "companies-nav" : "dark-companies-nav"}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        padding: { xs: "10px 0", sm: 0 },
        width: "100%",
        gap: "10px",
        height: { xs: "auto", sm: "50px" },
      }}
    >
      <div className="companies-container" ref={listRef}>
        <ArrowBackIcon
          color={isDarktheme ? "info" : "black"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/");
            cleanStorage();
          }}
        />
        <Avatar
          alt="Remy Sharp"
          src={logo}
          style={{ margin: "0 16px", cursor: "pointer" }}
          onClick={() => setShowDropMenu(!showDropMenu)}
        />
        <div
          style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
          onClick={() => setShowDropMenu(!showDropMenu)}
        >
          <p>{title}</p>
          <ArrowDropDownIcon
            color={isDarktheme ? "info" : "black"}
            style={{ cursor: "pointer", position: "relative", top: "-3px" }}
            fontSize="large"
          />
        </div>
        <div
          className="company-dropdown"
          style={{ width: "100%" }}
          onClick={() => setShowDropMenu(false)}
        >
          {showDropMenu && (
            <CompaniesDropDown mobile={mobile} allCompanies={allCompanies} />
          )}
        </div>
      </div>

      <div className="position-relative" ref={inputRef}>
        <PhoneIcon color={isDarktheme ? "info" : "black"} fontSize="large" />
        {!openInput && (
          <p
            style={{
              fontSize: "1.7rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenInput(true);
              mobile && setNumberInput(mobile);
            }}
          >
            {mobile}
          </p>
        )}

        {openInput && (
          <Box
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              margin: "0 10px",
            }}
            onSubmit={submitNumber}
          >
            <TextFieldGroup
              autoFocus={true}
              value={numberInput}
              onChange={(e) => setNumberInput(e.target.value)}
              InputProps={{
                style: {
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                },
              }}
              error={error && " "}
            />
            <IconButton type="submit" color="error" className="bounce">
              <SendIcon />
            </IconButton>
          </Box>
        )}
      </div>

      <div
        className={`companies-actions`}
        style={{
          width: actionWidth,
          clipPath: IS_MOBILE_DISPLAY
            ? "polygon(0% 0, 100% 0, 100% 100%, 0% 100%)"
            : "polygon(30% 0, 100% 0, 100% 100%, 0% 100%)",
        }}
      >
        {children}
        <RefreshIcon style={{ cursor: "pointer" }} onClick={onRefresh} />
      </div>
    </Box>
  );
};
const mapStateToProps = ({
  darkTheme: { isDarktheme },
  companies: { allCompanies, loading },
}) => {
  return {
    isDarktheme,
    allCompanies,
  };
};
export default connect(mapStateToProps, { jawwalPlusCheck, getAllCompanies })(
  CompaniesNav
);
