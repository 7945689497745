import {
  GET_CARDS,
  CLEAR_ERRORS,
  GET_ERRORS,
  GET_COPMANY_CARDS,
  COMPANY_CARDS_LOADING,
  CARDS_LOADING,
  SHARE_CARDS,
  SHARE_CARDS_LOADING,
} from "./types";
import ApiRequest from "./ApiRequest";
import sha256 from "crypto-js/sha256";
import { jsonParser } from "shared/utils/ppUtils";

export const getCards = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setCardsLoading(true));
  const storageHash = sha256(`giftCards`).toString();
  const giftCards = jsonParser(localStorage.getItem(storageHash));
  if (giftCards) {
    dispatch(setCards(giftCards));
    return;
  }

  return ApiRequest.post(`get_cards`)
    .then((res) => {
      dispatch({
        type: GET_CARDS,
        payload: res.data,
      });

      dispatch(setCards(res.data));
      dispatch(setCardsLoading(false));
      localStorage.setItem(
        storageHash,
        JSON.stringify(
          res.data.map((card, i) => {
            return { ...card, index: i };
          })
        )
      );
      return res.data;
    })
    .catch((err) => {
      dispatch(setCardsLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const getCompaniesCards = (brand_name) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(setCompanyCardsLoading(true));
  return ApiRequest.post(`get_denominations?brand_name=${brand_name}`)
    .then((res) => {
      dispatch(setCompanyCards(res.data));
      dispatch(setCompanyCardsLoading(false));
    })
    .catch((err) => {
      dispatch(setCompanyCardsLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const buyCardsAction = (ean, brand_name) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(buyCardsLoading(true));

  return ApiRequest.post(`buy_voucher`, {
    ean,
    brand_name,
  })
    .then((res) => {
      dispatch(buyCards(res.data));
      dispatch(buyCardsLoading(false));
    })
    .catch((err) => {
      dispatch(buyCardsLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const shareCardsAction = (share_id) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(shareCardsLoading(true));
  return ApiRequest.post(`share=share_id?${share_id}`)
    .then((res) => {
      dispatch(shareCards(res.data));
      dispatch(shareCardsLoading(false));
    })
    .catch((err) => {
      dispatch(shareCardsLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const setCards = (data) => ({
  type: GET_CARDS,
  payload: data,
});

export const setCompanyCards = (data) => ({
  type: GET_COPMANY_CARDS,
  payload: data,
});

export const setCompanyCardsLoading = (boolean) => ({
  type: COMPANY_CARDS_LOADING,
  payload: boolean,
});

export const setCardsLoading = (boolean) => ({
  type: CARDS_LOADING,
  payload: boolean,
});

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

///////
export const shareCards = (data) => ({
  type: SHARE_CARDS,
  payload: data,
});

export const shareCardsLoading = (boolean) => ({
  type: SHARE_CARDS_LOADING,
  payload: boolean,
});

export const buyCards = (data) => ({
  type: SHARE_CARDS,
  payload: data,
});

export const buyCardsLoading = (boolean) => ({
  type: SHARE_CARDS_LOADING,
  payload: boolean,
});
