import translate from "i18n/translate";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ApiRequest from "../../../../actions/ApiRequest";
import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import Modal from "components/common/Modal";
import JawakerCard from "./JawakerCard";
import Information from "./Information";
import UserDetails from "./UserDetails";
import Toast from "components/common/Toast";
import { intl } from "i18n/provider";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Jawaker = ({ isDarktheme }) => {
  const [coins, setCoins] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedCardsIds, setSelectedCardsIds] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isCharging, setIsCharging] = useState(false);
  const [playerId, setPlayerId] = useState("");
  const history = useHistory();
  useEffect(() => {
    const { state } = history.location;

    if (state?.number) {
      console.log(state.number);
      setPlayerId(state.number);
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const response = await ApiRequest.post("get_jawaker_tokens");
        if (response.data.status === "failed") {
          throw new Error(response.data.message);
        }
        setCoins(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);
  console.log(coins);

  useEffect(() => {
    if (Object.keys(selectedCardsIds).length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [selectedCardsIds]);

  const onSelectCard = (id, quantity) => {
    if (quantity === 0) {
      const { [id]: _, ...rest } = selectedCardsIds;
      setSelectedCardsIds(rest);
    } else {
      setSelectedCardsIds((prev) => ({
        ...prev,
        [id]: quantity,
      }));
    }
  };
  function getSelectedJawakerCards() {
    return Object.entries(selectedCardsIds)
      .filter(([_, quantity]) => quantity > 0)
      .map(([id, quantity]) => {
        const card = getJawakerCards(id);
        return {
          ...card,
          quantity,
        };
      });
  }
  function getJawakerCards(id) {
    return coins.find((coin) => coin.id === id);
  }

  async function chargeJawaker() {
    setIsCharging(true);
    try {
      const cardIds = getSelectedJawakerCards();

      const requests = cardIds.flatMap((card) => {
        return Array(card.quantity)
          .fill()
          .map(() => {
            return ApiRequest.post(
              `jawaker?id=${card.id}&player_id=${playerId}`
            );
          });
      });

      await Promise.all(requests);
      setOpenModal(false);
      setSelectedCardsIds({});
      setPlayerId("");
      Toast.fire({
        title: intl.formatMessage({
          id: "Your request is in progress",
        }),
        icon: "success",
      });
    } catch (e) {
      setOpenModal(false);
      console.log(e);
      await Toast.fire({
        title: intl.formatMessage({
          id: "Jawaker_coins_addition_failed",
        }),
        icon: "error",
      });
    } finally {
      setIsCharging(false);
      setSelectedCardsIds({});
      setPlayerId("");
    }
  }

  const handleConfirm = (playerId) => {
    const totalCoins = coins.reduce(
      (acc, curr) => acc + (selectedCardsIds[curr.id] || 0) * curr.coins,
      0
    );
    const customerPrice = coins.reduce(
      (acc, curr) =>
        acc + (selectedCardsIds[curr.id] || 0) * curr.customer_price,
      0
    );
    const sellerPrice = coins.reduce(
      (acc, curr) => acc + (selectedCardsIds[curr.id] || 0) * curr.seller_price,
      0
    );

    const formattedMessage = intl.formatMessage(
      { id: "jawakerConfirmationMessage" },
      {
        player_id: `<span style="font-weight: bold; font-size: 1.2em; ">${playerId}</span>`,
        total_coins: `<span style="font-weight: bold; font-size: 1.2em; ">${totalCoins}</span>`,
        customer_price: `<span style="font-weight: bold; font-size: 1.2em; ">${customerPrice}</span>`,
        seller_price: `<span style="font-weight: bold; font-size: 1.2em; ">${sellerPrice}</span>`,
      }
    );
    setPlayerId(playerId);
    setConfirmMessage(formattedMessage);
    setOpenModal(true);
  };

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("jawaker_welcome")}</h1>
        </div>
      </div>
      {isLoading ? (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <UserDetails
            onConfirm={handleConfirm}
            buttonDisabled={buttonDisabled}
            playerId={playerId}
          />
          <Information selectedCardsIds={selectedCardsIds} coins={coins} />
          <Grid
            container
            justifyContent="center"
            spacing={2}
            sx={{
              mt: 2,
              mb: 4,
            }}
          >
            {coins.map((coin) => (
              <JawakerCard
                coin={coin}
                onClick={onSelectCard}
                isSelected={selectedCardsIds[coin.id] ? true : false}
                cardQuantity={selectedCardsIds[coin.id] || 1}
                key={coin.id}
              />
            ))}
          </Grid>
          {openModal && (
            <Modal
              visible={openModal}
              onClose={() => setOpenModal(false)}
              width={"45vw"}
              title={intl.formatMessage({ id: "Confirm" })}
            >
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: confirmMessage }}
              />
              <div
                className="d-flex justify-content-end align-items-center mt-4"
                style={{ width: "100%" }}
              >
                <button
                  className="btn btn-danger mx-3"
                  onClick={() => setOpenModal(false)}
                >
                  {translate("Cancel")}
                </button>
                <LoadingButton
                  onClick={chargeJawaker}
                  loading={isCharging}
                  variant="contained"
                >
                  {translate("Confirm")}
                </LoadingButton>
              </div>
            </Modal>
          )}
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(Jawaker);
