import React from "react";
import { Grid, Button } from "@mui/material";
import translate from "../../../i18n/translate";
import { makeStyles } from "@mui/styles";
import PaymentPageStyles from "./PaymentPageStyles.js";
import { useHistory } from "react-router-dom";
const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(PaymentPageStyles);
export default function PaymentsPage(props) {
  const classes = useStyle(props);
  const history = useHistory();

  return (
    <Grid container>
      <Grid item container md={12} xs={12} justifyContent={"center"}>
        <Button
          size="large"
          className={classes.linkButton}
          onClick={(e) => {
            e.preventDefault();
            history.push("/seller_debt");
          }}
          href={"/seller_debt"}
        >
          {translate("add_payment")}
        </Button>
      </Grid>
      <Grid item container md={12} xs={12} justifyContent={"center"}>
        <Button
          size="large"
          onClick={(e) => {
            e.preventDefault();
            history.push("/payments_report");
          }}
          className={classes.linkButton}
        >
          {translate("payments_report")}
        </Button>
      </Grid>
    </Grid>
  );
}

PaymentsPage.propTypes = propTypes;
PaymentsPage.defaultProps = defaultProps;
