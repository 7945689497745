export const refreshColumnStyle = (setColumnStyle) => {
    switch (localStorage.size) {
        case "default":
            setColumnStyle("col-lg-4 col-md-6 col-sm-6 card-lg");
            break
        case "column3":
            setColumnStyle("col-lg-4 col-md-6 col-sm-6 card-lg");
            break;
        case "column4":
            setColumnStyle("col-lg-3 col-md-4 col-sm-6 card-md");
            break;
        case "column6":
            setColumnStyle("col-lg-2 col-md-4 col-sm-6 card-sm");
            break;
        default:
            setColumnStyle("col-lg-4 col-md-6 col-sm-6 card-lg");
            return
    }
}