import React, { useState } from "react";
import { TextField, MenuItem, Grid, Button, Box } from "@mui/material";
import translate from "i18n/translate";
import { intl } from "i18n/provider";

const UserDetails = ({ onConfirm, buttonDisabled }) => {
  const [countryCode, setCountryCode] = useState("+972");
  const [userNumber, setUserNumber] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (!userNumber || userNumber.length < 9) {
      setError(true);
      return;
    }
    setError(false);
    const fullPhoneNumber = countryCode.replace("+", "") + userNumber;
    onConfirm(fullPhoneNumber);
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      sx={{
        mb: 2,
      }}
    >
      {intl.locale !== "en" && (
        <Grid item md={4}>
          <TextField
            fullWidth
            label={translate("phoneNumber")}
            value={userNumber}
            onChange={(e) => setUserNumber(e.target.value)}
            variant="outlined"
            inputProps={{
              dir: "ltr",
            }}
            error={error}
            helperText={error && translate("invalidPhoneNumber")}
          />
        </Grid>
      )}
      <Grid item>
        <TextField
          select
          label={translate("countryCode")}
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          variant="outlined"
          error={error}
          helperText={error && translate("error")}
        >
          <MenuItem value="+972">+972</MenuItem>
          <MenuItem value="+970">+970</MenuItem>
        </TextField>
      </Grid>
      {intl.locale === "en" && (
        <Grid item md={4}>
          <TextField
            fullWidth
            label={translate("phoneNumber")}
            value={userNumber}
            onChange={(e) => setUserNumber(e.target.value)}
            variant="outlined"
            error={error}
            helperText={error && translate("invalidPhoneNumber")}
          />
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            padding: "15px 30px",
          }}
          disabled={buttonDisabled}
        >
          {translate("submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
