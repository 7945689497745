import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField';
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from '../../common/Modal'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "../Companies/styles.css"
import { deleteAdvertisement, addAdvertisement, getAdvertisement, updateAdvertisement } from '../../../actions/settingsActions'
import translate from "../../../i18n/translate";

const AdvertisementSettings = ({ advertisementData, deleteAdvertisement, getAdvertisement, addAdvertisement, updateAdvertisement, isDarktheme }) => {
    const intle = useIntl();
    const [loading, setLoading] = useState(false)
    const [addAdvModal, setAddAdvModal] = useState(false)
    const [addAdvLoading, setAddAdvLoading] = useState(false)
    const [advertisements, setAdvertisments] = useState([])
    const [addNewAdv, setAddNewAdv] = useState("")
    const [editDeleteDataAdv, setEditDeleteDataAdv] = useState({})
    const [advDelete, setAdvDelete] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (!advertisementData.length) {
            getAdvertisement().then((data) => {
                if (data) {
                    setAdvertisments(data)
                }
                setLoading(false)
            })
        } else {
            setAdvertisments(advertisementData)
            setLoading(false)

        }
    }, [])

    const onChange = (e) => {
        const { value } = e.target
        setAddNewAdv(value)
    }

    const handleDeleteSubmit = (e) => {
        e.preventDefault()
        setAddAdvLoading(true)
        deleteAdvertisement(editDeleteDataAdv.index).then((res) => {
            if (res) {
                let editeArray = advertisements
                editeArray.splice(editDeleteDataAdv.key, 1)
                setAdvertisments([...editeArray])
            }
            setAddAdvModal(false)
            setAdvDelete(false)
            setAddAdvLoading(false)
            setEditDeleteDataAdv({})
        })
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()
        setAddAdvLoading(true)
        updateAdvertisement(editDeleteDataAdv).then((res) => {
            setAddAdvModal(false)
            if (res) {

                let editeArray = advertisements
                editeArray[editDeleteDataAdv.key] = {
                    ...advertisements[editDeleteDataAdv.key],
                    ar: editDeleteDataAdv.advertisement,
                    en: editDeleteDataAdv.advertisement,
                    he: editDeleteDataAdv.advertisement,
                }
                setAdvertisments([...editeArray])
            }
            setAddAdvLoading(false)
            setEditDeleteDataAdv({})
        })
    }

    const handleAddNewAdvSubmit = async (e) => {
        e.preventDefault()
        setAddAdvLoading(true)
        await addAdvertisement(addNewAdv).then((id) => {
            setAddAdvModal(false)
            setAdvertisments([...advertisements, { index: Number(id), ar: addNewAdv, en: addNewAdv, he: addNewAdv }])
        })
        setAddAdvLoading(false)

    }

    const handleEditClick = (advertisement, i) => {
        setEditDeleteDataAdv({ index: advertisement.index, advertisement: advertisement[`${intle.locale}`], key: i });
        setAddAdvModal(true)
    }

    const handleDeleteClick = (advertisement, i) => {
        setEditDeleteDataAdv({ index: advertisement.index, advertisement: advertisement[`${intle.locale}`], key: i });
        setAdvDelete(true); setAddAdvModal(true)
    }
    return (
        <div className='d-flex flex-column align-items-center justify-content-center mt-6'>
            <h2 className='company-header'>{translate("Advertisements")}</h2>

            <button className='btn btn-primary' style={{ margin: '10px 0 10px auto' }} onClick={() => { setEditDeleteDataAdv({}); setAddAdvModal(true) }}>
                {translate("Add An advertisemet")}
            </button>
            <TableContainer >
                <Table sx={{ minWidth: 650, margin: 'auto' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow style={{ background: "#263238 " }}>
                            <TableCell style={{ color: "white" }} align="center">{translate("ID")}</TableCell>
                            <TableCell style={{ color: "white" }} align="center">{translate("location")}</TableCell>
                            <TableCell style={{ color: "white" }} align="center">{translate("Edit")}</TableCell>
                            <TableCell style={{ color: "white" }} align="center">{translate("Delete")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading && <TableBody>
                        {advertisements.map((advertisement, i) => (
                            <TableRow
                                key={i.toString()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                            >
                                <TableCell style={!isDarktheme ? {} : { color: "white" }} component="th" scope="row" align="center">
                                    {i + 1}
                                </TableCell>
                                <TableCell style={!isDarktheme ? {} : { color: "white" }} align="center">{advertisement[`${intle.locale}`]}</TableCell>
                                <TableCell style={!isDarktheme ? {} : { color: "white" }} align="center"><EditIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => handleEditClick(advertisement, i)} /> </TableCell>
                                <TableCell style={!isDarktheme ? {} : { color: "white" }} align="center"><DeleteIcon color='error' sx={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(advertisement, i)} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>}
                </Table>
            </TableContainer>
            {loading && <CircularProgress size={80} sx={{ marginTop: '160px' }} />}
            <Modal
                visible={addAdvModal}
                onClose={() => setAddAdvModal(false)}
                width={'45vw'}
                title='Advertisement'
            >
                {advDelete ?
                    <form className='phone-form' onSubmit={handleDeleteSubmit}>
                        <div>
                            {translate("are you sure you want to delete this advertisement?")}
                            <br />
                            ({editDeleteDataAdv.advertisement})
                        </div>
                        <div className='d-flex justify-content-end align-items-center mt-4' style={{ width: '100%' }}>
                            <button className='btn btn-danger mx-3' onClick={() => { setAdvDelete(false); setAddAdvModal(false) }}>
                                {translate("Cancel")}
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary'
                            >
                                {addAdvLoading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : translate("submit")}
                            </button>

                        </div>
                    </form>
                    :
                    editDeleteDataAdv.index ?
                        <form className='phone-form' onSubmit={handleEditSubmit}>
                            <div className="w-100 mb-3">
                                {translate("edit Advertisement")}
                            </div>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                placeholder='enter your Advertisement'
                                name="enter your Advertisement"
                                type="text"
                                onChange={(e) => setEditDeleteDataAdv({ ...editDeleteDataAdv, advertisement: e.target.value })}
                                value={editDeleteDataAdv['advertisement']}
                                style={{ width: "100%" }}
                                required={true}
                            />
                            <div className='d-flex justify-content-end align-items-center mt-4' style={{ width: '100%' }}>
                                <button className='btn btn-danger mx-3' onClick={() => setAddAdvModal(false)}>
                                    {translate("Cancel")}
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                >
                                    {addAdvLoading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : translate("submit")}
                                </button>

                            </div>
                        </form> :
                        <form className='phone-form' onSubmit={handleAddNewAdvSubmit}>
                            <div className="w-100 mb-3">
                                {translate("add an Advertisement")}
                            </div>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                placeholder='enter your Advertisement'
                                name="enter your Advertisement"
                                type="text"
                                onChange={onChange}
                                value={addNewAdv}
                                style={{ width: "100%" }}
                                required={true}
                            />
                            <div className='d-flex justify-content-end align-items-center mt-4' style={{ width: '100%' }}>
                                <button className='btn btn-danger mx-3' onClick={() => setAddAdvModal(false)}>
                                    {translate("Cancel")}
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                >
                                    {addAdvLoading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : translate("submit")}
                                </button>

                            </div>
                        </form>

                }
            </Modal>
        </div>
    )
}
const mapStateToProps = ({ settings: { advertisementData }, darkTheme: { isDarktheme } }) => ({
    advertisementData,
    isDarktheme

});

export default connect(mapStateToProps, { addAdvertisement, deleteAdvertisement, getAdvertisement, updateAdvertisement })(AdvertisementSettings)