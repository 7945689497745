import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import "./authheader.css";
import translate from "i18n/translate";
import { Typography } from "@mui/material";
import { FaTiktok } from "react-icons/fa";

const AuthHeaderLink = ({ data, isDarktheme }) => {
  const [showSubLinks, setShowSubLinks] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // New state to track hover
  const styles = {
    badge: {
      backgroundColor: "red", // Background color of the badge
      padding: "2px 4px", // Padding inside the badge
      borderRadius: "5px", // Rounded corners of the badge
      display: "inline-block", // Makes the badge inline
      marginLeft: "5px",
      marginRight: "5px",
    },
    text: {
      color: "white", // Text color
      fontSize: "12px", // Font size of the text
      fontWeight: "bold", // Font weight of the text
    },
  };

  return (
    <div
      className={data.parentClass}
      onMouseEnter={() => setShowSubLinks(true)}
      onMouseLeave={() => setShowSubLinks(false)}
    >
      <span style={{ display: "flex", alignItems: "center" }}>
        <NavLink
          to={data.linkTo}
          className={!isDarktheme ? `link-main` : `dark-link-main`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            cursor: "pointer",
          }}
        >
          {data.id === 18 ? (
            <FaTiktok
              style={{
                color: "#45B4E3",
                fontSize: 20,
              }}
            />
          ) : (
            <i
              className={data.icon}
              style={{
                color: "#45B4E3",
                fontSize: 20,
              }}
            ></i>
          )}

          <Typography
            style={{
              fontWeight: "bold",
              fontSize: isHovered ? 16 : 12,
            }}
          >
            {data.title}
          </Typography>
        </NavLink>
        {data.id === 21 && (
          <div style={styles.badge}>
            <span style={styles.text}>{translate("New")}</span>
          </div>
        )}
      </span>
      {data.content?.length > 0 && showSubLinks && (
        <div className="sublinks-wrapper">
          {data.content.map((link, i) => (
            <NavLink key={i.toString()} to={link.linkTo} className="link-main">
              {link.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(AuthHeaderLink);
