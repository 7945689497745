export const HTTP_METHODS = {
  get: "get",
  post: "post",
  put: "put",
};
export const END_POINTS = {
  getJawwalBundles: {
    url: "/JAB?number={mobileNumber}&bundle={bundle}&language={language}&refresh={refresh}",
    method: HTTP_METHODS.post,
    key: "getJawwalBundles",
  },
  checkNumberProvider: {
    url: "/number_provider?number={mobileNumber}&carrier={carrier}",
    method: HTTP_METHODS.post,
    key: "checkNumberProvider",
  },
  getPeletalkProducts: {
    url: "/peletalk_get_products?provider_id={providerId}",
    method: HTTP_METHODS.post,
    key: "getPeletalkProducts",
  },
  getAzyProducts: {
    url: "/azy_get_products?language={language}",
    method: HTTP_METHODS.post,
    key: "getAzyProducts",
  },
  getHotProducts: {
    url: "/hot_get_products?language={language}",
    method: HTTP_METHODS.post,
    key: "getHotProducts",
  },
  getOoredooBundles: {
    url: "/ooredoo_get_bundles?bundle={bundle}&language={language}",
    method: HTTP_METHODS.post,
    key: "getOoredooBundles",
  },
  getAllSellers: {
    url: "/get_allseller?reseller_id={resellerId}",
    method: HTTP_METHODS.post,
    key: "getAllSellers",
  },
  syncAccounts: {
    url: "/sync_account?seller_id={sellerId}&fix={fix}",
    method: HTTP_METHODS.post,
    key: "syncAccounts",
  },
  carrierCheck: {
    url: "/carrier_check?number={mobileNumber}",
    method: HTTP_METHODS.post,
    key: "carrierCheck",
  },
  getSellerDiscount: {
    url: "/seller_discount?seller_id={sellerId}",
    method: HTTP_METHODS.post,
    key: "getSellerDiscount",
  },
  getPaltelBills: {
    url: "/paltel_enquiry",
    method: HTTP_METHODS.post,
    key: "paltel-enquiry",
  },
  getMadaBills: {
    url: "/mada_enquiry",
    method: HTTP_METHODS.post,
    key: "mada-enquiry",
  },
  getCoolnetBills: {
    url: "/coolnet_enquiry",
    method: HTTP_METHODS.post,
    ket: "coolnet-enquiry",
  },
  getGemzoBills: {
    url: "/gemzo_enquiry",
    method: HTTP_METHODS.post,
    key: "gemzo-enquiry",
  },
  getJawwalBills: {
    url: "/jawwal_invoice_enquiry",
    method: HTTP_METHODS.post,
    key: "jawwal_invoice_enquiry",
  },
  getOoredooBills: {
    url: "/ooredoo_invoice_enquiry",
    method: HTTP_METHODS.post,
    key: "ooredoo_invoice_enquiry",
  },
  verifyJawwalNumber: {
    url: "/is_id_required",
    method: HTTP_METHODS.post,
    key: "verify-jawwal-number",
  },
  verifyPaltelNumber: {
    url: "/is_id_required",
    method: HTTP_METHODS.post,
    key: "verify-paltel-number",
  },
  verifyMadaNumber: {
    url: "/is_id_required",
    method: HTTP_METHODS.post,
    key: "verify-mada-number",
  },
  verifyCoolnetNumber: {
    url: "/is_id_required",
    method: HTTP_METHODS.post,
    key: "verify-coolnet-number",
  },
  verifyGemzoNumber: {
    url: "/is_id_required",
    method: HTTP_METHODS.post,
    key: "verify-gemzo-number",
  },
  payPaltelBill: {
    url: "/paltel",
    method: HTTP_METHODS.post,
    key: "pay-paltel-bill",
  },
  payMadaBill: {
    url: "/mada",
    method: HTTP_METHODS.post,
    key: "pay-mada-bill",
  },
  payCoolnetBill: {
    url: "/coolnet",
    method: HTTP_METHODS.post,
    key: "pay-coolnet-bill",
  },
  payGemzoBill: {
    url: "/gemzo",
    method: HTTP_METHODS.post,
    key: "pay-gemzo-bill",
  },
  payJawwalBill: {
    url: "/jawwal_invoice",
    method: HTTP_METHODS.post,
    key: "pay-jawwal-bill",
  },
  payOoredooBill: {
    url: "/ooredoo_invoice",
    method: HTTP_METHODS.post,
    key: "pay-ooredoo-bill",
  },
  nedcoEnquiry: {
    url: "/nedco_enquiry?s_number={s_number}&amount={amount}",
    method: HTTP_METHODS.post,
    key: "nedco_enquiry",
  },
  nedcoBuyVoucher: {
    url: "/nedco_buy_voucher?s_number={s_number}&amount={amount}",
    method: HTTP_METHODS.post,
    key: "nedco_enquiry",
  },
  selcoEnquiry: {
    url: "/selco_enquiry?s_number={s_number}&amount={amount}",
    method: HTTP_METHODS.post,
    key: "selco_enquiry",
  },
  selcoBuyVoucher: {
    url: "/selco_buy_voucher?s_number={s_number}&amount={amount}",
    method: HTTP_METHODS.post,
    key: "selco_enquiry",
  },
};
