import React from "react";
import translate from "../../../i18n/translate";

const JawwalPlusCard = ({
  bundle,
  columnStyle,
  onTypeClick,
  children,
  selected,
}) => {
  const { minutes, messages, gb, amount } = bundle;
  return (
    <div className={`${columnStyle} ${selected ? "selected-plus" : "mt-4"} `}>
      <div
        className="jawwal-plus py-2"
        onClick={() => !selected && onTypeClick(bundle)}
        style={{
          cursor: "pointer",
          position: "relative",
        }}
      >
        {children}
        <div className="d-flex align-items-center justify-content-around">
          <img
            alt="logo"
            height="50"
            width="50"
            src="https://play-lh.googleusercontent.com/28-YWH_Nctl0KO2p6CMpVGUkFERHeIbdkpPP9US9OPhJzYna-4Ab_L-vDZ_S4B-Y6NA"
            style={{ margin: "initial" }}
          />
          <p style={{ marginBottom: 0 }}>
            {amount} {translate("nis")}
          </p>
        </div>
        <div
          className={`d-flex align-items-start justify-content-around ${
            selected ? "mt-1" : "mt-4"
          }`}
        >
          <p>
            {gb} {translate("GB")}
          </p>
          <div>
            <p>
              {minutes} {translate("Min")}
            </p>
            <p>
              {messages} {translate("SMS")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JawwalPlusCard;
