import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { conditionalReturn } from "shared/utils/utils";
import classNames from "../../../../node_modules/classnames/index";

const propTypes = {
  inputProps: PropTypes.object,
  onClearClick: PropTypes.func,
  divContainerProps: PropTypes.object,
  inputClassName: PropTypes.string,
  divContainerClassName: PropTypes.string,
};

export default function CancelableInput(props) {
  const {
    onClearClick,
    inputClassName,
    divContainerClassName,
    divContainerProps,
    value,
    ...rest
  } = props;
  return (
    <div
      className={classNames("divContainer", divContainerClassName)}
      {...divContainerProps}
    >
      <span className="deleteicon" style={{ width: "100%" }}>
        <input
          value={value}
          type="text"
          {...rest}
          className={classNames(
            "form-control",
            "cancelableInput",
            inputClassName
          )}
        />
        {conditionalReturn(
          !!onClearClick && value?.length,
          <div
            className="pointer css-tlfecz-indicatorContainer"
            aria-hidden="true"
            onClick={onClearClick}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </div>
        )}
      </span>
    </div>
  );
}

CancelableInput.propTypes = propTypes;
