import { AZY_Name } from "./constants";

export const LOCAL_STORAGE_ITEMS_ENUM = {
  darkMode: "darkMode",
  "hide companies": "hide companies",
  langCity: "langCity",
  jwtUserToken: "jwtUserToken",
  "token exp": "token exp",
  currentUser: "currentUser",
  hotBundle: "hotBundle",
};

export const SESSION_STORAGE_ITEMS_ENUM = {
  Jawwal3g: "Jawwal3g",
  JawwalMin: "JawwalMin",
  JawwalCredit: "JawwalCredit",
  JawwalRom: "JawwalRom",
  JawwalPlus: "JawwalPlus",
  ooredoo3g: "ooredoo3g",
  ooredooMin: "ooredooMin",
  ooredooCredit: "ooredooCredit",
  ooredooRom: "ooredooRom",
  ooredooSuper: "ooredooSuper",
  ooredooYOUTH: "ooredooSuper",
  azyBundle: "azyBundle",
  hotBundle: "hotBundle",
  [AZY_Name]: "azyBundle",
  peletalkBundle: "peletalkBundle",
};
