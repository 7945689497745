import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import translate from "../../i18n/translate";
import ApiRequest from "../../actions/ApiRequest";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { intl } from "i18n/provider";
import Toast from "components/common/Toast";
import Notiflix from "notiflix";
import Button from "components/common/Button";
import EsimModal from "./EsimModal";
import Spinner from "components/ui/spinner/Spinner";
import UploadImage from "./UploadImage";
import { useHistory } from "react-router";
import { set } from "lodash";

const PELEPHONE = "Pelephone";
const HOT = "Hot";
const OOREDOO = "ooredoo";
const JAWWAL = "jawwal";

const myCarriers = [
  {
    carrier: OOREDOO,
    image: "https://mmbiztoday.com/wp-content/uploads/2022/08/1200x1200.png",
  },
  {
    carrier: JAWWAL,
    image:
      "https://cdn6.aptoide.com/imgs/0/3/e/03ede0eac01482e56950361340681eae_icon.png",
  },
  {
    carrier: PELEPHONE,
    image:
      "https://www.algosec.com/wp-content/uploads/2017/01/Jan17_2017_resized.jpg",
  },
  {
    carrier: HOT,
    image: "https://images.officialiphoneunlock.co.uk/images/hot.png",
  },
];

const Esim = ({ isDarktheme }) => {
  const history = useHistory();
  const [carrier, setCarrier] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false); // ['newEsim', 'convertEsim'
  const [message, setMessage] = useState(""); // ['newEsim', 'convertEsim'
  const [image, setImage] = useState(""); // ['newEsim', 'convertEsim'
  const [carriers, setCarriers] = useState(myCarriers);
  const [contactUsData, setContactUsData] = useState({
    amount: "",
    newPhone: "",
    email: "",
    whatsapp: "",
  });
  useEffect(() => {
    if (localStorage.getItem("hide companies") === "true") {
      setCarriers(
        myCarriers.filter(
          (carrier) => carrier.carrier === OOREDOO || carrier.carrier === JAWWAL
        )
      );
    }
  }, []);

  const [esimType, setEsimType] = useState(); // ['newEsim', 'convertEsim'
  const [err, setErr] = useState(false);

  const [formErrors, setFormErrors] = useState({
    amount: "",
    newPhone: "",
    email: "",
    whatsapp: "",
  });
  const isPelephoneOrHot = carrier === PELEPHONE || carrier === HOT;

  const onChange = (e) => {
    const { name, value } = e.target;
    setContactUsData({
      ...contactUsData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validate = (data) => {
    let valid = true;
    const errors = {
      amount: "",
      newPhone: "",
      email: "",
      whatsapp: "",
    };
    if (esimType === "newEsim") {
      if (data.amount < 20) {
        errors.amount = translate("minEsim");
        valid = false;
      }
    } else {
      if (data.newPhone.length < 9) {
        errors.newPhone = translate("invalidNumber");
        valid = false;
      }
    }
    if (data.email?.trim() === "" && data.whatsapp?.trim() === "") {
      errors.email = translate("Please enter your email or whatsapp");
      errors.whatsapp = translate("Please enter your email or whatsapp");
      valid = false;
    }
    return {
      valid,
      errors,
    };
  };

  useEffect(() => {
    document.title = "Esim | Phone Play";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPelephoneOrHot) {
      setContactUsData({
        amount: 60,
        newPhone: "",
        email: "",
        whatsapp: "",
      });
      setEsimType("newEsim");
    }
  }, [isPelephoneOrHot]);

  const handleOnConfirm = async () => {
    setOpenModal(false);
    setLoading(true);
    try {
      let { amount, email, whatsapp, newPhone } = contactUsData;
      const formData = new FormData();
      formData.append("image", image, `img.jpg`);
      const { data } = await ApiRequest.post(
        `esim?carrier=${carrier}&esim_type=${
          esimType === "newEsim" ? "new" : "old"
        }&new_number=${newPhone}&mobile_no=${whatsapp}&amount=${amount}&email=${email}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.status === "failed") {
        throw new Error(data.message);
      }
      setLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your Message has been sent successfully",
        }),
        icon: "success",
      });
      setContactUsData({
        name: "",
        email: "",
        newPhone: "",
        subject: "",
        message: "",
        mobile: "",
        amount: "",
      });
      setImage("");
      setCarrier(null);
      setEsimType(null);
      history.push("/");
    } catch (err) {
      setErr(true);
      setLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Something Went Wrong",
        }),
        icon: "error",
      });
      Notiflix.Report.failure("Something Went Wrong", "", "Close");
    }
  };
  const onSubmit = async (e) => {
    let { amount, email, whatsapp, newPhone } = contactUsData;
    console.log("amount", contactUsData);
    const now = new Date();
    const currentHour = now.getHours();

    const isBefore5PM = currentHour < 17;

    e.preventDefault();
    const { valid, errors } = validate(contactUsData);
    if (!valid) {
      setFormErrors(errors);
      return;
    }
    setErr(false);

    const mess = isPelephoneOrHot
      ? intl
          .formatMessage({ id: "pelephoneMessage" })
          .replace("{company}", carrier)
      : `${
          esimType === "newEsim"
            ? `${intl.formatMessage({
                id: "willChargeYourNewEsimBy",
              })} ${amount} ${intl.formatMessage({ id: "nis" })}`
            : `${intl.formatMessage({
                id: "willConvertYourNumber",
              })} ${newPhone} ${intl.formatMessage({ id: "toEsim" })}`
        } ${intl.formatMessage({ id: "in" })} ${
          isBefore5PM
            ? intl.formatMessage({ id: "30Minutes" })
            : intl.formatMessage({ id: "tomorrow" })
        } ${intl.formatMessage({
          id: "thePriceForThisServiceIs",
        })}${intl.formatMessage({
          id: "andYouWillReceiveAQRCodeOnYour",
        })} ${
          whatsapp
            ? intl.formatMessage({ id: "whatsapp" })
            : intl.formatMessage({ id: "email" })
        } ${whatsapp ? whatsapp : email}
     ${intl.formatMessage({
       id: "toCompleteTheProcess",
     })}
    ${intl.formatMessage({ id: "ifYouSurePleaseClickConfirm" })}`;

    setMessage(mess);
    setOpenModal(true);
  };

  const backgroundStyle = {
    backgroundColor: "#fff",
    width: 170,
    height: 170,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    marginBottom: 16,
  };
  const selectedWalletStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // subtle shadow
    transform: "scale(1.05)", // slightly larger
  };
  const unselectedWalletStyle = {
    filter: "blur(3px)", // Apply a blur effect
    opacity: 0.7, // Reduce the opacity to make it look less focused
  };

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("Esim")}</h1>
        </div>
      </div>
      <div className="row">
        <h3 className="text-center mb-4">{translate("Pick your carrier")}:</h3>
        <div
          style={{
            display: "flex",
            gap: 32,
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {carriers.map((currentCarrier, index) => (
            <div
              onClick={() => setCarrier(currentCarrier.carrier)}
              style={{
                ...backgroundStyle,
                ...(carrier && carrier === currentCarrier.carrier
                  ? selectedWalletStyle
                  : {}),
              }}
              key={index}
            >
              <img
                src={currentCarrier.image}
                alt={currentCarrier.carrier}
                style={{
                  borderRadius: 16,
                  cursor: "pointer",
                  ...(carrier && carrier !== currentCarrier.carrier
                    ? unselectedWalletStyle
                    : {}),
                }}
              />
            </div>
          ))}
        </div>
        {carrier && !isPelephoneOrHot && (
          <FormControl style={{ marginTop: 20 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue={esimType}
              name="radio-buttons-group"
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                margin: "auto",
              }}
              value={esimType}
              onChange={(e) => setEsimType(e.target.value)}
            >
              <FormControlLabel
                value="newEsim"
                control={<Radio />}
                label={translate("New Esim")}
              />
              <FormControlLabel
                value="convertEsim"
                control={<Radio />}
                label={translate("Convert to Esim")}
              />
            </RadioGroup>
          </FormControl>
        )}

        {((carrier && esimType) || isPelephoneOrHot) && (
          <form className="contact-us mt-4" onSubmit={onSubmit}>
            {esimType === "convertEsim" ? (
              <TextField
                variant="standard"
                label={intl.formatMessage({
                  id: "Enter the phone number you want to convert to esim",
                })}
                name="newPhone"
                type={"string"}
                onChange={onChange}
                value={contactUsData["newPhone"]}
                error={formErrors["newPhone"]}
                helperText={formErrors["newPhone"]}
              />
            ) : (
              <TextField
                variant="standard"
                label={intl.formatMessage({
                  id: "Enter the amount you want to charge your new esim",
                })}
                name="amount"
                type={"number"}
                onChange={onChange}
                value={contactUsData["amount"]}
                error={formErrors["amount"]}
                helperText={formErrors["amount"]}
                disabled={isPelephoneOrHot}
              />
            )}

            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "Enter the email you want to receive the QR code on",
              })}
              name="email"
              type="email"
              onChange={onChange}
              value={contactUsData["email"]}
              error={formErrors["email"]}
              helperText={
                formErrors["email"]
                  ? formErrors["email"]
                  : intl.formatMessage({
                      id: "Please enter your email or whatsapp",
                    })
              }
            />

            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "Enter the whatsapp number you want to receive the QR code on",
              })}
              name="whatsapp"
              onChange={onChange}
              value={contactUsData.whatsapp}
              error={formErrors.whatsapp}
              helperText={
                formErrors.whatsapp
                  ? formErrors.whatsapp
                  : intl.formatMessage({
                      id: "Please enter your email or whatsapp",
                    })
              }
            />
            {isPelephoneOrHot || <UploadImage setImage={setImage} />}
            <Button
              type="primary"
              bgColor="#242526"
              style={{ width: "100%" }}
              className="mt-4"
              title="submit"
              formType="submit"
              disabled={!isPelephoneOrHot && !image}
            />
            {err && (
              <p className="text-danger mt-2">
                {intl.formatMessage({ id: "Some Thing Went Wrong !" })}
              </p>
            )}
          </form>
        )}
      </div>
      <EsimModal
        openModal={openModal}
        message={message}
        setOpenModal={setOpenModal}
        onConfirm={handleOnConfirm}
      />
      {loading && <Spinner />}
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(Esim);
