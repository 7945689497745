import {
  CLEAR_ERRORS,
  GET_ERRORS,
  GET_CONTACT_US_MESSAGES
} from "./types";
import ApiRequest from "./ApiRequest";

export const getContactUsMessages = (offset, no_of_rows) => (dispatch) => {
  dispatch(clearErrors());
  return ApiRequest
    .post(
      `seller_tickets?offset=${offset}&number_of_rows=${no_of_rows}`
    )
    .then((res) => {
      dispatch({
        type: GET_CONTACT_US_MESSAGES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};