import React from "react";
import { Route, Switch } from "react-router";

import AdminSideBar from "../adminPanel/AdminSideBar";
import AdminHeader from "../adminPanel/AdminHeader";

import AdminPage from "../adminPanel/AdminPage";
import PointsPanel from "../adminPanel/Points/PointsPanel";
import CompaniesPanel from "../adminPanel/Companies/CompaniesPanel";
import PeletalkSettingsContainer from "../adminPanel/Settings/PeletalkSettingsContainer";
import TranslateSettings from "../adminPanel/Settings/TranslateSettings";
import CompanySettings from "../adminPanel/Companies/CompanySettings";
import AdvertisementSettings from "../adminPanel/Settings/AdvertisementSettings";
import Reports from "../adminPanel/Reports/Reports";
import HyperMediaSettingsContainer from "../adminPanel/Settings/HypermediaSettingsContainer";
import JawwalSettingsContainer from "../adminPanel/Companies/JawwalSettings";
import MintRoutContainer from "../adminPanel/Settings/MintRouteContainer";
import Settings from "../pages/settings/Settings";
import { connect } from "react-redux";
import JawaalSettings from "components/pages/settings/JawaalSettings";
import OoredooSettings from "components/pages/settings/OoredooSettings";
import PaltelSetting from "components/pages/settings/PaltelSetting";
import CellcomSettings from "components/pages/settings/IsrealCompanies/Companies/Cellcom";
import JawwalInvoiceSettings from "components/pages/settings/JawwalInvoiceSetting";
import GemzoSettings from "components/pages/settings/GemzoSettings";
import MadaSettings from "components/pages/settings/MadaSettings";
import CoolnetSettings from "components/pages/settings/Coolnet";
import ArabBank from "components/pages/settings/ArabBank";
import DeleteTrans from "components/pages/settings/DeleteTrans";
import AllCompanies from "components/pages/settings/AllCompanies";
import SystemSettings from "components/pages/settings/SystemSettings";
import EditCard from "components/pages/settings/IsrealCompanies/EditCard";
import HotSettings from "components/pages/settings/IsrealCompanies/Companies/Hot";
import AzySettings from "components/pages/settings/IsrealCompanies/Companies/Azy";
import GolanSettings from "components/pages/settings/IsrealCompanies/Companies/Golan";
import MobileSettings from "components/pages/settings/IsrealCompanies/Companies/Mobile012";
import PartnerSettings from "components/pages/settings/IsrealCompanies/Companies/Partner";
import PelephoneSettings from "components/pages/settings/IsrealCompanies/Companies/Pelephone";

const AdminRoutes = ({ isDarktheme }) => {
  const adminRoutes = [
    { exact: true, path: "/admin", component: AdminPage },
    // { exact: true, path: "/admin", component: Settings },
    { exact: true, path: "/admin/Points", component: PointsPanel },
    { exact: true, path: "/admin/Companies", component: CompaniesPanel },
    { exact: true, path: "/admin/Company/:id", component: CompanySettings },
    {
      exact: true,
      path: "/admin/peletalk",
      component: PeletalkSettingsContainer,
    },
    {
      exact: true,
      path: "/admin/hypermedia",
      component: HyperMediaSettingsContainer,
    },
    { exact: true, path: "/admin/translate", component: TranslateSettings },
    { exact: true, path: "/admin/ads", component: AdvertisementSettings },
    { exact: true, path: "/admin/Reports", component: Reports },
    { exact: true, path: "/admin/Jawwal", component: JawaalSettings },
    { exact: true, path: "/admin/ooredoo", component: OoredooSettings },
    { exact: true, path: "/admin/paltel", component: PaltelSetting },
    { exact: true, path: "/admin/gemzo", component: GemzoSettings },
    { exact: true, path: "/admin/arabBank", component: ArabBank },
    { exact: true, path: "/admin/allCompanies", component: AllCompanies },
    { exact: true, path: "/admin/deleteTrans", component: DeleteTrans },
    { exact: true, path: "/admin/mada", component: MadaSettings },

    { exact: true, path: "/admin/coolnet", component: CoolnetSettings },
    { exact: true, path: "/admin/system", component: SystemSettings },
    {
      exact: true,
      path: "/admin/jawwalInvoiceSettings",
      component: JawwalInvoiceSettings,
    },
    { exact: true, path: "/admin/Cellcom", component: CellcomSettings },
    { exact: true, path: "/admin/Hot", component: HotSettings },
    { exact: true, path: "/admin/azy", component: AzySettings },
    { exact: true, path: "/admin/golan", component: GolanSettings },
    { exact: true, path: "/admin/mobile012", component: MobileSettings },
    { exact: true, path: "/admin/partner", component: PartnerSettings },
    { exact: true, path: "/admin/pelephone", component: PelephoneSettings },

    { exact: true, path: "/admin/editSettings", component: EditCard },
    { exact: true, path: "/admin/Mintroute", component: MintRoutContainer },
    { exact: true, path: "/admin/Mintroute", component: MintRoutContainer },
  ];

  return (
    <Switch>
      <section className="row item-translate">
        <div className="admin-panel-Page">
          <AdminSideBar />
          <div style={{ width: "100%" }}>
            <AdminHeader />
            <div
              className={
                !isDarktheme
                  ? "col-lg-11 col-sm-12  white"
                  : "col-lg-11 col-sm-12  dark"
              }
              style={{ padding: "25px" }}
            >
              {adminRoutes.map((r) => (
                <Route exact={r?.exact} path={r.path} component={r.component} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Switch>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(AdminRoutes);
