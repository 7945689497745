import { Calculate } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import TextFieldGroup from "components/common/TextFieldGroup";
import { intl } from "i18n/provider";
import React from "react";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";
import { SYSTEM_COLORS } from "shared/constants/stylesConstants";

const PlayerInformation = ({
  playerId,
  setPlayerId,
  isLoading,
  onGetPlayerName,
  playerName,
  classes,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          justifyContent: "flex-start",
        }}
      >
        <form
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: IS_MOBILE_DISPLAY ? "column" : "row",
            alignItems: "center",
            gap: 8,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            onGetPlayerName();
          }}
        >
          <TextFieldGroup
            placeholder={intl.formatMessage({
              id: "Enter Player ID",
            })}
            disable={isLoading.nextStep}
            name="playerId"
            type="number"
            value={playerId}
            onChange={(e) => setPlayerId(e.target.value)}
            autoFocus={true}
            label={intl.formatMessage({ id: "player_id" })}
            required={true}
            fullWidth
          />
          <LoadingButton
            type="submit"
            className={classes.nextBtn}
            disabled={isLoading.isSearching}
            variant="contained"
            style={{ marginTop: 25, width: 200, height: 48 }}
            loading={isLoading.isSearching}
          >
            {intl.formatMessage({ id: "search" })}
          </LoadingButton>
        </form>

        <Box
          item
          md={4}
          container
          justifyContent={"center"}
          alignItems={"center"}
          xs={12}
          style={{
            border: `1px solid ${SYSTEM_COLORS.gray}`,
            borderRadius: 4,
            marginTop: -16,
            width: IS_MOBILE_DISPLAY ? 300 : 440,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant={"h4"} fontWeight={"bold"}>
            {playerName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerInformation;
