import { useCallback, useEffect } from "react";
import Notiflix from "notiflix";
import store from "store";
import { USER_TYPES_WITH_ADMIN } from "shared/constants/constants";

export function useErrorHandler(error, msg = "Something went wrong - ") {
  const fireErr = useCallback((errMsg) => {
    Notiflix.Notify.failure(errMsg);
  }, []);

  useEffect(() => {
    if (error) {
      fireErr(msg + error?.message);
    }
  }, [error, fireErr, msg]);

  const onError = useCallback(
    function (err) {
      if (err) {
        fireErr(msg + err?.message);
      }
    },
    [fireErr, msg]
  );

  return { onError: onError };
}


export function useMount(callback) {
  useEffect(() => {
    callback();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useIsAdmin() {
  const userType = store.getState()?.auth?.user?.seller_type?.toLocaleLowerCase();
  const isAdmin = userType === USER_TYPES_WITH_ADMIN.admin.value;
  const isReseller = userType === USER_TYPES_WITH_ADMIN.reseller.value;
  return { isAdmin, isReseller };
}

export function useIsRtl() {
  return localStorage.getItem("langCity") !== "en";
}
