import { CLEAR_ERRORS, GET_ERRORS } from "./../actions/types";

const initialState = {
  massage: null,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        massage: action.payload,
      };
    default:
      return state;
    case CLEAR_ERRORS:
      return {};
  }
}

export default errorReducer
