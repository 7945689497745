export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const USER_DETAILS_LOADING = "USER_DETAILS_LOADING";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";
export const VERIFY_NUMBER = "VERIFY_NUMBER";
export const VERIFY_NUMBER_LOADING = "VERIFY_NUMBER_LOADING";
export const CONFIRM_NUMBER = "CONFIRM_NUMBER";
export const CONFIRM_NUMBER_LOADING = "CONFIRM_NUMBER_LOADING";
export const CONFIRM_NUMBER_ERROR = "CONFIRM_NUMBER_ERROR";

//Report

export const GET_LAST_TRANSACTION = "GET_LAST_TRANSACTION";
export const GET_SELLER_POINTS = "GET_SELLER_POINTS";
export const GET_SELLER_REPORTS = "GET_SELLER_REPORTS";
export const GET_SELLER_PROFIT = "GET_SELLER_PROFIT";
export const GET_SELLER_CANCELATION_REPORTS = "GET_SELLER_CANCELATION_REPORTS";
export const GET_SELLER_RUNNING_REPORTS = "GET_SELLER_RUNNING_REPORTS";
export const UPDATE_TRANSACTION_LOADING = "UPDATE_TRANSACTION_LOADING";
//Companies Types
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const ADD_COMPANIES_LOADING = "ADD_COMPANIES_LOADING";

//jawwal
export const GET_JAWWAL_3G = "GET_JAWWAL_3G";
export const GET_JAWWAL_CREDIT = "GET_JAWWAL_CREDIT";
export const GET_JAWWAL_MIN = "GET_JAWWAL_MIN";
export const GET_JAWWAL_ROM = "GET_JAWWAL_ROM";
export const GET_JAWWAL_CHARGE = "GET_JAWWAL_CHARGE";
export const ADD_JAWWAL_CHARGE = "ADD_JAWWAL_CHARGE";
export const GET_RENEW_JAWWAL_MIN = "GET_RENEW_JAWWAL_MIN";
export const GET_NOT_RENEW_JAWWAL_MIN = "GET_NOT_RENEW_JAWWAL_MIN";
export const GET_RENEW_JAWWAL_3G = "GET_RENEW_JAWWAL_3G";
export const GET_NOT_RENEW_JAWWAL_3G = "GET_NOT_RENEW_JAWWAL_3G";
export const LOADING_TRUE = "LOADING_TRUE";
export const IS_PLUS = "IS_PLUS";
export const GET_JAWWAL_PLUS = "GET_JAWWAL_PLUS";
export const JAWWAL_BUNDLE_LOADING = "JAWWAL_BUNDLE_LOADING";
export const SET_NUMBER_CHANGED = "SET_NUMBER_CHANGED";

//Ooredoo
export const GET_OOREDOO_3G = "GET_OOREDOO_3G";
export const GET_OOREDOO_RENEW_3G = "GET_OOREDOO_RENEW_3G";
export const GET_OOREDOO_NOT_RENEW_3G = "GET_OOREDOO_NOT_RENEW_3G";
export const GET_OOREDOO_MIN = "GET_OOREDOO_MIN";
export const GET_OOREDOO_RENEW_MIN = "GET_OOREDOO_RENEW_MIN";
export const GET_OOREDOO_NOT_RENEW_MIN = "GET_OOREDOO_NOT_RENEW_MIN";
export const GET_OOREDOO_ROM = "GET_OOREDOO_ROM";
export const GET_OOREDOO_RENEW_ROM = "GET_OOREDOO_RENEW_ROM";
export const GET_OOREDOO_NOT_RENEW_ROM = "GET_OOREDOO_NOT_RENEW_ROM";
export const GET_OOREDOO_SUPER = "GET_OOREDOO_SUPER";
export const GET_RENEW_OOREDOO_SUPER = "GET_RENEW_OOREDOO_SUPER";
export const GET_NOR_RENEW_OOREDOO_SUPER = "GET_NOR_RENEW_OOREDOO_SUPER";

//Group Companies

export const GET_GROUP_COMPANIES = "GET_GROUP_COMPANIES";

// hot and azy Company
export const GET_AZY = "GET_AZY";
export const GET_HOT = "GET_HOT";

//Cards
export const GET_CARDS = "GET_CARDS";
export const CARDS_LOADING = "CARDS_LOADING";
export const GET_COPMANY_CARDS = "GET_COPMANY_CARDS";
export const COMPANY_CARDS_LOADING = "COMPANY_CARDS_LOADING";

export const SHARE_CARDS = "SHARE_CARDS";
export const SHARE_CARDS_LOADING = "SHARE_CARDS_LOADING";

export const BUY_CARDS = "BUY_CARDS";
export const BUY_CARDS_LOADING = "BUY_CARDS_LOADING";

// Insurances
export const GET_INSURANCES = "GET_INSURANCES";
export const GET_INSURANCES_LOADING = "GET_INSURANCES_LOADING";
export const GET_INSURANCES_FAIL = "GET_INSURANCES_FAIL";
export const GET_INSURANCES_CALCULATIONS = "GET_INSURANCES_CALCULATIONS";
export const GET_INSURANCES_CALCULATIONS_LOADING =
  "GET_INSURANCES_CALCULATIONS_LOADING";
export const GET_INSURANCES_CALCULATIONS_FAIL =
  "GET_INSURANCES_CALCULATIONS_FAIL";
export const SUBMIT_INSURANCE_LOADING = "SUBMIT_INSURANCE_LOADING";

// Internet
export const GET_INTERNETS = "GET_INTERNETS";

//Discounts
export const GET_SELLER_DISCOUNTS = "GET_SELLER_DISCOUNTS";

export const GET_SELLER_CREDITS = "GET_SELLER_CREDITS";
export const ADD_SELLER_CREDITS = "ADD_SELLER_CREDITS";
export const GET_SELLERS = "GET_SELLERS";
export const GET_REWARDS = "GET_REWARDS";
export const GET_TOPUP_ACCOUNTS = "GET_TOPUP_ACCOUNTS";
export const CONVERT_POINTS = "CONVERT_POINTS";
export const ADD_REWARDS_LOADING = "ADD_REWARDS_LOADING";
export const GET_SELLER_DEBT = "GET_SELLER_DEBT";
export const GET_SELLER_DEBT_LOADING = "GET_SELLER_DEBT_LOADING";
export const PURGE_SELLER_CREDITS = "PURGE_SELLER_CREDITS";

// Game
export const GET_GAMES_DATA_LOADING = "GET_GAMES_DATA_LOADING";
export const GET_GAME_DETAILS_LOADING = "GET_GAME_DETAILS_LOADING";
export const GET_GAMES_DATA = "GET_GAMES_DATA";
export const GET_GAME_DATA = "GET_GAME_DATA";
export const GET_FREE_FIRE_PLAYER_DATA = "GET_FREE_FIRE_PLAYER_DATA";
export const GAMES_ERROR = "GAMES_ERROR";
export const PLAYER_MODAL = "PLAYER_MODAL";
export const SUCCESSFULL_MODAL = "SUCCESSFULL_MODAL";
export const GAME_SUCCESSFULL = "GAME_SUCCESSFULL";
export const GAME_VALIDATION_LOADING = "GAME_VALIDATION_LOADING";
export const GAME_SUBMIT_LOADING = "GAME_SUBMIT_LOADING";
export const GAME_VALIDATION_MODAL = "GAME_VALIDATION_MODAL";
export const GAME_VALIDATION_DATA = "GAME_VALIDATION_DATA";

//Company settings
export const COMPANY_SETTINGS_OBJECT = "COMPANY_SETTINGS_OBJECT";

// Wallet

// Bills

// Admin Reports
export const ADMIN_GET_REPORT = "ADMIN_GET_REPORT";
export const ADMIN_GET_REPORT_LOADING = "ADMIN_GET_REPORT_LOADING";
export const ON_ADMIN_SEARCH = "ON_ADMIN_SEARCH";
export const ADMIN_Edit_REPORT = "ADMIN_Edit_REPORT";

// Contact Us
export const GET_CONTACT_US_MESSAGES = "GET_CONTACT_US_MESSAGES";

// Contact Us
export const GET_ALL_ADVERTISEMENTS = "GET_ALL_ADVERTISEMENTS";

// darkTheme
export const CHANGE_THEME = "CHANGE_THEME";

//notificaitons
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING";
export const SINGLE_NOTIFICATION_LOADING = "SINGLE_NOTIFICATION_LOADING";
