import React, { useState, useMemo } from "react";
import { useMutation } from "react-query";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";

import PageTitle from "../common/PageTitle";
// import PayBillsJawwalModal from "./PayBillsJawwalModal";

import {
  getJawwalBillsApiCalled,
  payJawwalBillApiCalled,
} from "shared/endPoints/apiCallers";

import NoBalanceCard from "components/cards/NoBalanceCard";
import translate from "i18n/translate";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import EditableTextFieldForm from "./shared/InputBills";
import DataTable from "./shared/DataTable";
import { calcTotalAmount } from "./shared/calcTotalAmount";
import GeneralPayModal from "./shared/GeneralPayModal";

const JawwalBills = () => {
  const [number, setNumber] = useState("");
  const [identity, setIdentity] = useState("");
  const [selectedBill, setSelectedBill] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [jawwalPills, setJawwalPills] = useState([]);
  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    const { state } = history.location;
    if (state?.number) {
      setNumber(state.number);
      setIdentity(state.identity);
      getJawwalBills({ id: state.identity, number: state.number });
    }
  }, []);

  // const {
  //   mutate: verifyJawwalNumber,
  //   data: isIdentityNeeded,
  //   isSuccess,
  // } = useMutation(verifyJawwalNumberApiCalled);
  const {
    isLoading,
    mutate: getJawwalBills,
    data: pills,
    reset,
  } = useMutation(getJawwalBillsApiCalled, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        const errorReason = res?.reason ?? "Something went wrong";
        setErrorMessage(errorReason);
        return;
      }
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });

  useEffect(() => {
    if (pills?.length > 0) {
      setJawwalPills(
        pills.reverse().map((pill) => ({
          ...pill,
          date: pill.date1,
        }))
      );
    }
  }, [pills]);
  const onSubmit = async (value) => {
    setNumber(value);
    setSelectedBill([]);
    setJawwalPills([]);
    getJawwalBills({ id: identity, number: value });
  };

  const clearSelections = () => {
    setSelectedBill([]);
    reset();
  };

  const totalAmount = parseFloat(calcTotalAmount(selectedBill).toFixed(2));

  return (
    <div>
      <PageTitle title={translate("Jawwal Bills")} backPage="/pay_bills">
        <img
          src="https://www.asd.ps/wp-content/uploads/2016/03/jawwal.png"
          alt="jawwal"
          className="jawwal-image"
          style={{ width: 150, height: 150, marginTop: 20 }}
        />
        <EditableTextFieldForm
          initialValue={number}
          label={translate("Phone number")}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
        {jawwalPills?.length > 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <p>
                {translate("Total Bills Amount")}:{" "}
                <span className="text-info">
                  {totalAmount} {translate("ILS")}
                </span>
              </p>
              {/* <PayBillsJawwalModal
                selectedBill={selectedBill}
                number={number}
                isDisabled={!totalAmount || isLoading}
                clearSelections={clearSelections}
                totalAmount={totalAmount}
              /> */}
              <GeneralPayModal
                selectedBill={selectedBill}
                number={number}
                isDisabled={!totalAmount || isLoading}
                clearSelections={clearSelections}
                totalAmount={totalAmount}
                apiCall={payJawwalBillApiCalled}
              />
            </div>
            <DataTable
              rows={jawwalPills}
              isLoading={isLoading}
              onRowSelect={setSelectedBill}
              selectedRows={selectedBill}
            />
          </>
        )}
        {pills?.length === 0 && <h5>No Results Found</h5>}
        {errorMessage && <h5 className="text-danger">{errorMessage}</h5>}
      </PageTitle>
    </div>
  );
};

export default JawwalBills;
