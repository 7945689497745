import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
import { styled } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";
import Modal from "components/common/Modal";
import translate from "i18n/translate";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  width: "100%",
}));

const AllCompanies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productEditable, setProductEditable] = useState(null);
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setFilteredCompanies(
      allCompanies?.filter((company) =>
        company.company_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, allCompanies]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await ApiRequest.post(`get_products?category=all`);
      setAllCompanies(res.data);
    } catch (error) {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Loop through each setting in the payload to check if it changed
    try {
      // If a setting has changed, send an update request for that setting
      const res = await ApiRequest.post(
        `global_update?carrier_id=${selectedItem.id}&bit=active&new_Value=${
          selectedItem.active === "true" ? "F" : "T"
        }`
      );
      if (res.data.status == "failed") {
        throw new Error("Something went wrong");
      }
      const resProducts = await ApiRequest.post(`get_products?category=all`);
      setAllCompanies(resProducts.data);
      Toast.fire({
        title: "Settings Updated Successfully!",
        icon: "success",
      });
    } catch (error) {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
    } finally {
      setOpenModal(false);
      setIsLoading(false);
      setNewValue("");
    }
  };

  if (!isLoading && isChanged) {
    Toast.fire({
      title: "Settings Updated Successfully!",
      icon: "success",
    });
    setIsChanged(false);
  }
  const handleSaveClick = () => {
    if (newValue.trim().length === 0) return;
    setIsLoading(true);
    sendApiRequest(newValue);
  };

  const sendApiRequest = async (text) => {
    try {
      const res = await ApiRequest.post(
        `global_update?carrier_id=${productEditable}&bit=discount&new_Value=${text}`
      );
      if (res.data.status.toLowerCase() === "failed") {
        throw new Error(res.data.reason);
      }
      const resProducts = await ApiRequest.post(`get_products?category=all`);
      setAllCompanies(resProducts.data);
      Toast.fire({
        title: "Settings Updated Successfully!",
        icon: "success",
      });
    } catch (err) {
      Toast.fire({
        title: err.message,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
      setNewValue("");
      setProductEditable(null);
    }
  };

  return (
    <Box
      sx={{
        margin: "20px auto",
        padding: "30px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <h1>Global Updates</h1>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Search>
      {isLoading && (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid
        container
        spacing={4}
        mt={3}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {filteredCompanies.map((company) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <img
              src={company.url}
              alt={company.company_name}
              style={{
                height: "150px",
                width: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Checkbox
                checked={company.active === "true"}
                onClick={() => {
                  setSelectedItem(company);
                  setOpenModal(true);
                }}
              />
              <div style={{}}>
                <p>{company.company_name}</p>
                {productEditable !== company.id ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>dis: {company.discount}</p>
                    <IconButton
                      onClick={() => setProductEditable(company.id)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      value={newValue ? newValue : company.discount}
                      onChange={(e) => setNewValue(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                    <Box>
                      <IconButton onClick={handleSaveClick} size="small">
                        <SaveIcon
                          sx={{ ml: "2px", mr: "2px" }}
                          color="success"
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => setProductEditable(null)}
                        size="small"
                      >
                        <CancelIcon
                          sx={{ ml: "2px", mr: "2px" }}
                          color="error"
                        />
                      </IconButton>
                    </Box>
                  </Box>
                )}

                <p>id: {company.id}</p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      {openModal && (
        <Modal
          visible={openModal}
          onClose={() => setOpenModal(false)}
          width={"45vw"}
          title="Confirmation"
        >
          <form className="phone-form" onSubmit={handleConfirmSubmit}>
            <div>
              {selectedItem?.active === "true"
                ? translate("are you sure you want to deactivate this product?")
                : translate("are you sure you want to activate this product?")}
            </div>
            <img
              src={selectedItem?.url}
              alt={selectedItem?.company_name}
              style={{
                width: 200,
                height: 200,
              }}
            />
            <div
              className="d-flex justify-content-end align-items-center mt-4"
              style={{ width: "100%" }}
            >
              <button
                className="btn btn-danger mx-3"
                onClick={() => setOpenModal(false)}
              >
                {translate("Cancel")}
              </button>
              <button type="submit" className="btn btn-primary">
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  translate("submit")
                )}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </Box>
  );
};
export default AllCompanies;
