import React, { Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GiftCardsPage from "../cards/GiftCardsPage";
import GiftCardDenominations from "../cards/GiftCardDenominations";
import Azy from "../companies/azy/Azy";
import InputAzy from "../companies/azy/InputAzy";
import GroupCom from "../companies/group/GroupCom";
import InputNumber from "../companies/group/InputNumber";
import Hot from "../companies/hot/Hot";
import InputHot from "../companies/hot/InputHot";
import InputNoPage from "../companies/jawwal/InputNoPage";
import Jawwal3g from "../companies/jawwal/Jawwal3g";
import JawwalCredit from "../companies/jawwal/JawwalCredit";
import JawwalMin from "../companies/jawwal/JawwalMin";
import JawwalRom from "../companies/jawwal/JawwalRom";
import JawwalPlus from "../companies/jawwal/JawwalPlus";
import TypeChargeJa from "../companies/jawwal/TypeChargeJa";
import CreditOoredoo from "../companies/ooredoo/CreditOoredoo";
import MinOoredoo from "../companies/ooredoo/MinOoredoo";
import Ooredoo3g from "../companies/ooredoo/Ooredoo3g";
import OoredooInputNu from "../companies/ooredoo/OoredooInputNu";
import RomOoredoo from "../companies/ooredoo/RomOoredoo";
import ShababOoredoo from "../companies/ooredoo/ShababOoredoo";
import GamesListing from "../Gaming/GamesListing";
import Report from "../report/Report";
import Internet from "../pages/Internet/Internet";
import Insurance from "../pages/Insurance/Insurance";
import Navar from "../layout/Navbar";
import SubNavbar from "../layout/SubNavbar";
import Home from "../../components/homePage/Home";
import Points from "../pages/SellerCredits/Points";
import Profit from "../report/Profit";
import Cancelation from "../report/Cancelation";
import RuningBalance from "../report/RuningBalance";
import SellerCredits from "../pages/SellerCredits/SellerCredits";
import ConvertPoints from "../pages/SellerCredits/ConvertPoints";
// import ScrollToTop from "../common/ScrollToTop";
import AuthHeader from "../layout/AuthHeader";
import LeftSideBar from "../layout/LeftSideBar";
import RightSideBar from "../layout/RightSideBar";
// import GameDetails from "../Gaming/GameDetails";
import Profile from "../profile/Profile";
import PayBills from "../PayBills/PayBills";
import SellerCredit from "../SellerCredits/SellerCredits";
import JawwalBills from "../PayBills/JawwalBills";
// import PayIsraelFines from "../PayBills/PayIsraelFines";
import PaltelBills from "../PayBills/PaltelBills";
import Malchat from "./../SellerCredits/Malchat";
import PayPalWallet from "./../SellerCredits/PalPayWallet";
import JawwalPay from "../SellerCredits/JawwalPay";
import Discounts from "../pages/Discounts/Discounts";
import ContactUs from "../ContactUs/ContactUs";
import PrintCards from "../cards/PrintCards";
import Mada from "../PayBills/Mada";
import CoolnetBills from "components/PayBills/Coolnet";
import GemzoBills from "components/PayBills/Gemzo";
import CreditReport from "../report/CreditReport";
import DebitReport from "../report/DebitReport";
import SellerDebt from "../SellerDebt/SellerDebt";
import CreditsPage from "../pages/Credits/CreditsPage";
import PaymentsPage from "../pages/Payments/PaymentsPage";
import PaymentsReport from "components/pages/Payments/PaymentsReport";
import SystemBalance from "components/pages/admin/SystemBalance/SystemBalance";
import IsraelFinesPage from "components/pages/Israel/IsraelFinesPage/IsraelFinesPage";
import IsraeliFineInquiry from "components/pages/Israel/IsraeliFineInquiry/IsraeliFineInquiry";
import PayIsraeliFines from "../pages/Israel/PayIsraeliFines/PayIsraeliFines.jsx";
// import PubgCharge from "../pages/Games/PubgCharge.jsx";
import GamesCharge from "../pages/Games/pubg/GamesCharge.jsx";
// import AddBalance from "../pages/AddBalance/AddBalance.js";
import { useIsAdmin } from "../../shared/hooks/ppHooks.js";
import AddBalancePage from "../pages/AddBalance/AddBalancePage.jsx";
import SystemSupport from "components/pages/admin/SystemSupport/SystemSupport";
import Dealers from "components/pages/Dealers/Dealers";
import Nedco from "components/PayBills/Nedco";
import NoBalanceCard from "components/cards/NoBalanceCard";
import { CircularProgress, Container } from "@mui/material";
import EditSettings from "components/pages/settings/EditSettings";
import Esim from "components/esim/Esim";
import Icloud from "components/icloud/Icloud";
import Wallet from "components/wallet/Wallet";
import Sms from "components/sms/Sms";
import NEWSim from "components/newSim/NEWSim";
import MagneticCard from "components/magneticCard/MagneticCard.jsx";
import Tiktok from "../tiktok/Tiktok.jsx";
import Bnet from "components/PayBills/Bnet";
import OoredooBills from "components/PayBills/OoredooBills";
import Selco from "components/PayBills/Selco";
import Jawaker from "components/pages/Games/jawaker/Jawaker";
import { Toaster, toast } from "react-hot-toast";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const AuthenticatedRoutes = () => {
  const theme = createTheme();
  const { isAdmin, isReseller } = useIsAdmin();
  const screenWidth = window.innerWidth;
  const authRouts = [
    {
      access: isAdmin,
      exact: true,
      path: "/system_balance",
      component: SystemBalance,
    },
    {
      access: isAdmin,
      exact: true,
      path: "/system_support",
      component: SystemSupport,
    },
    {
      access: isAdmin,
      exact: true,
      path: "/edit-account",
      component: EditSettings,
    },

    { access: true, exact: true, path: "/", component: Home },
    {
      access: true,
      exact: false,
      path: "/add_balance",
      component: AddBalancePage,
    },
    { access: true, exact: true, path: "/Profile", component: Profile },
    // { access: trzue, exact: true, path: "/add_balance/:companyId", component: Profile },
    //Reports
    {
      access: isAdmin || isReseller,
      exact: true,
      path: "/credits",
      component: CreditsPage,
    },
    { access: isAdmin, exact: true, path: "/dealers", component: Dealers },
    {
      access: isAdmin,
      exact: true,
      path: "/payments",
      component: PaymentsPage,
    },
    {
      access: true,
      exact: true,
      path: "/payments_report",
      component: PaymentsReport,
    },
    { access: true, exact: true, path: "/report", component: Report },
    { access: true, exact: true, path: "/profit", component: Profit },
    { access: true, exact: true, path: "/running", component: RuningBalance },
    {
      access: true,
      exact: true,
      path: "/credit_report",
      component: CreditReport,
    },
    {
      access: true,
      exact: true,
      path: "/debit_report",
      component: DebitReport,
    },
    { access: true, exact: true, path: "/seller_debt", component: SellerDebt },
    { access: true, exact: true, path: "/cancelation", component: Cancelation },
    { access: true, exact: true, path: "/internet", component: Internet },
    { access: true, exact: true, path: "/insurance", component: Insurance },
    {
      access: isAdmin || isReseller,
      exact: true,
      path: "/add_credits",
      component: SellerCredits,
    },
    {
      access: true,
      exact: true,
      path: "/convert_points",
      component: ConvertPoints,
    },
    { access: true, exact: true, path: "/discounts", component: Discounts },
    { access: true, exact: true, path: "/contactus", component: ContactUs },
    //Company
    //JAWWAL
    {
      access: true,
      exact: true,
      path: "/company/jawwal/:id",
      component: TypeChargeJa,
    },
    {
      access: true,
      exact: true,
      path: "/company/jawwalNo",
      component: InputNoPage,
    },
    {
      access: true,
      exact: true,
      path: "/company/jawwal3g/:id",
      component: Jawwal3g,
    },
    {
      access: true,
      exact: true,
      path: "/company/jawwalMin/:id",
      component: JawwalMin,
    },
    {
      access: true,
      exact: true,
      path: "/company/jawwalCredit/:id",
      component: JawwalCredit,
    },
    {
      access: true,
      exact: true,
      path: "/company/jawwalRom/:id",
      component: JawwalRom,
    },
    {
      access: true,
      exact: true,
      path: "/company/JawwalPlus/:id",
      component: JawwalPlus,
    },
    //OOREDOO
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/MobileNumer",
      component: OoredooInputNu,
    },
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/minutes/:id",
      component: MinOoredoo,
    },
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/credit/:id",
      component: CreditOoredoo,
    },
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/3g/:id",
      component: Ooredoo3g,
    },
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/rom/:id",
      component: RomOoredoo,
    },
    {
      access: true,
      exact: true,
      path: "/company/ooredoo/shabab/:id",
      component: ShababOoredoo,
    },
    //Cellcom Pelephone Partner Golan 012mobile
    {
      access: true,
      exact: true,
      path: "/company/group/:company/",
      component: InputNumber,
    },
    {
      access: true,
      exact: true,
      path: "/company/group/:company/:id",
      component: GroupCom,
    },
    //Azy &hot
    { access: true, exact: true, path: "/company/azy/", component: InputAzy },
    { access: true, exact: true, path: "/company/azy/:id", component: Azy },
    { access: true, exact: true, path: "/company/hot/", component: InputHot },
    { access: true, exact: true, path: "/company/hot/:id", component: Hot },
    //Cards
    { access: true, exact: true, path: "/cards", component: GiftCardsPage },
    {
      access: true,
      exact: true,
      path: "/cards/:brand_name",
      component: GiftCardDenominations,
    },
    { access: true, exact: true, path: "/points", component: Points },
    { access: true, exact: true, path: "/print-card", component: PrintCards },
    //GAMIN
    { access: true, exact: true, path: "/gaming", component: GamesListing },
    // { access: true, exact: true, path: "/gaming/pubg", component: PubgCharge },
    { access: true, exact: true, path: "/gaming/:id", component: GamesCharge },
    { access: true, exact: true, path: "/jwaker", component: Jawaker },
    //Pills & FINES
    { access: true, exact: true, path: "/pay_bills", component: PayBills },
    { access: true, exact: true, path: "/esim", component: Esim },
    {
      access: true,
      exact: true,
      path: "/newSim",
      component: NEWSim,
    },
    {
      access: true,
      exact: true,
      path: "/magneticCard",
      component: MagneticCard,
    },
    {
      access: true,
      exact: true,
      path: "/icloud",
      component: Icloud,
    },
    {
      access: true,
      exact: true,
      path: "/tiktok",
      component: Tiktok,
    },
    {
      access: true,
      exact: true,
      path: "/sms",
      component: Sms,
    },
    {
      access: true,
      exact: true,
      path: "/wallet",
      component: Wallet,
    },
    {
      access: true,
      exact: true,
      path: "/pay_bills/jawwal",
      component: JawwalBills,
    },
    {
      access: true,
      exact: true,
      path: "/bills/israel_fines",
      component: IsraelFinesPage,
    },
    {
      access: true,
      exact: true,
      path: "/bills/paltel",
      component: PaltelBills,
    },
    {
      access: true,
      exact: true,
      path: "/bills/pay_israel_fines",
      component: PayIsraeliFines,
    },
    {
      access: true,
      exact: true,
      path: "/bills/inquire_israel_fines",
      component: IsraeliFineInquiry,
    },
    {
      access: true,
      exact: true,
      path: "/pay_bills/paltel",
      component: PaltelBills,
    },
    { access: true, exact: true, path: "/pay_bills/mada", component: Mada },
    {
      access: true,
      exact: true,
      path: "/pay_bills/Bnet",
      component: Bnet,
    },
    {
      access: true,
      exact: true,
      path: "/pay_bills/ooredoo_bills",
      component: OoredooBills,
    },
    {
      access: true,
      exact: true,
      path: "/pay_bills/coolnet",
      component: CoolnetBills,
    },
    {
      access: true,
      exact: true,
      path: "/pay_bills/gemzo",
      component: GemzoBills,
    },
    { access: true, exact: true, path: "/pay_bills/nedco", component: Nedco },
    { access: true, exact: true, path: "/pay_bills/selco", component: Selco },

    // Wallets
    {
      access: true,
      exact: true,
      path: "/seller_credit",
      component: SellerCredit,
    },
    {
      access: true,
      exact: true,
      path: "/seller_credit/Jawwal-Pay",
      component: JawwalPay,
    },
    {
      access: true,
      exact: true,
      path: "/seller_credit/PalPay Wallet",
      component: PayPalWallet,
    },
    {
      access: true,
      exact: true,
      path: "/seller_credit/Malchat",
      component: Malchat,
    },
  ];

  return (
    <>
      <Router>
        <Navar />
        <Toaster
          toastOptions={{
            duration: {
              loading: 100000, // 5 seconds for loading
              success: 30000, // 30 seconds for success
              error: 20000, // 5 minutes for error
            },
            style: {
              padding: "16px",
              minWidth: "300px",
              fontSize: "16px",
            },
            success: {
              icon: "✅",
              style: {
                background: theme.palette.success.main,
                color: "white",
              },
            },
            error: {
              icon: "❌",
              style: {
                background: theme.palette.error.main,
                color: "white",
              },
            },
            loading: {
              icon: <CircularProgress size={20} color="inherit" />,
              style: {
                background: theme.palette.primary.main,
                color: "white",
              },
            },
            dismissible: {
              closeOnClick: true,
              pauseOnHover: true,
            },
            onClick: (t) => {
              toast.dismiss(t.id);
              console.log("toast", t);
            },
          }}
        />
        <AuthHeader />
        <NoBalanceCard />
        <Container maxWidth="xl">
          <Fragment>
            {/* <ScrollToTop /> */}
            <SubNavbar />
            <Switch>
              <section className="row">
                {screenWidth > 768 && <LeftSideBar />}
                <div className="col-lg-8 col-md-6 col-sm-12  ">
                  {authRouts.reduce((prev, curr, index) => {
                    if (curr.access) {
                      prev.push(
                        <Route
                          key={index}
                          exact={curr.exact}
                          path={curr.path}
                          component={curr.component}
                        />
                      );
                    }

                    return prev;
                  }, [])}
                </div>
                {screenWidth > 768 ? (
                  <RightSideBar />
                ) : (
                  <div style={{ display: "flex", gap: 8, marginTop: 32 }}>
                    <LeftSideBar />
                    <RightSideBar />
                  </div>
                )}
              </section>
            </Switch>
          </Fragment>
        </Container>
      </Router>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, {})(AuthenticatedRoutes);
