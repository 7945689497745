/* eslint-disable no-useless-escape */
import ApiRequest from './ApiRequest'
import sha256 from 'crypto-js/sha256';
import Notiflix from "notiflix";

import { clearErrors } from './reportsAction'
import {
    GET_GAMES_DATA, GET_ERRORS, GET_GAMES_DATA_LOADING,
    GET_GAME_DATA, GET_FREE_FIRE_PLAYER_DATA, PLAYER_MODAL,
    GET_GAME_DETAILS_LOADING, SUCCESSFULL_MODAL, GAME_SUCCESSFULL, GAME_VALIDATION_LOADING, GAME_SUBMIT_LOADING, GAME_VALIDATION_MODAL, GAME_VALIDATION_DATA
} from './types'
import Toast from "../components/common/Toast";

export const getGamingCards = () => (dispatch) => {
    dispatch(clearErrors());
    dispatch(Loading(true))
    const storageHash = sha256(`games`).toString();
    const games = JSON.parse(sessionStorage.getItem(storageHash));
    if (games) {
        dispatch({
            type: GET_GAMES_DATA,
            payload: games,
        });
        return
    }
    return ApiRequest
        .post(`get_products?category=game&topup_type=topup`)
        .then((res) => {
            dispatch({
                type: GET_GAMES_DATA,
                payload: res.data,
            });
            sessionStorage.setItem(storageHash, JSON.stringify(res.data));
            dispatch(Loading(false))
        })
        .catch((err) => {
            dispatch(Loading(false))
            dispatch(handleError())
        });
};

export const getGameCardsDetails = (denominations_db_name, game_id) => (dispatch) => {
    dispatch(clearErrors());
    dispatch(gameBundlesDataLoading(true))
    return ApiRequest
        .post(`get_denominations?brand_name=${denominations_db_name}`)
        .then((res) => {
            dispatch(setGameBundlesData(res.data));
            dispatch(gameBundlesDataLoading(false))
        })
        .catch((err) => {
            dispatch(gameBundlesDataLoading(false))
            dispatch(handleError())
        });


}

export const freeFireValidate = (brand_id, account_id, deno_id, denominations_db_name) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameValidationLoading(true, 'freeFireValidationLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`validate_${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}`, null, config)
        if (!data.status || data.status === 'failed') {
            let reason = data.reason.split(`\"`)
            Toast.fire({
                title: `${reason[3]}`,
                icon: "warning",
                text: `${reason[5]}`
            });
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'freeFireValidatedData'))
        dispatch(GameValidationModal(true, 'freeFireModalOpen'))
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
        console.log("freeFireValidate", err);
    }
    finally {
        dispatch(GameValidationLoading(false, 'freeFireValidationLoading'))
    }
}

export const freeFireTopUp = (brand_id, account_id, deno_id, denominations_db_name) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameTopUpLoading(true, 'freeFireTopUpLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}&location=PAL`, null, config)
        if (!data.status || data.status === 'failed') {
            dispatch(GameValidationModal(false, 'freeFireModalOpen'))
            Toast.fire({
                title: `Top up Failed`,
                icon: "warning",
                text: `${data.reason}`
            });
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'freeFireTopUpData'))
        dispatch(GameValidationModal(false, 'freeFireModalOpen'))
        dispatch(GameValidationModal(true, 'freeFireSuccessModalOpen'))
        // open success Modal
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
    }
    finally {
        dispatch(GameTopUpLoading(false, 'freeFireTopUpLoading'))
    }
}

export const mobileLegendsValidate = (brand_id, account_id, deno_id, denominations_db_name, zone_id) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameValidationLoading(true, 'mobileLegendsValidationLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`validate_${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}&zone_id=${zone_id}`, null, config)
        if (!data.status || data.status === 'failed') {
            let reason = data.reason.split(`\"`)
            Toast.fire({
                title: `${reason[3]}`,
                icon: "warning",
                text: `${reason[5]}`
            });
            // Notiflix.Report.warning(`${reason[3]}`, `${reason[5]}`, 'Close')
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'mobileLegendsValidatedData'))
        dispatch(GameValidationModal(true, 'mobileLegendsModalOpen'))
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
    }
    finally {
        dispatch(GameValidationLoading(false, 'mobileLegendsValidationLoading'))
    }
}

export const mobileLegendsTopUp = (brand_id, account_id, deno_id, denominations_db_name, zone_id) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameTopUpLoading(true, 'mobileLegendsTopUpLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}&location=PAL&zone_id=${zone_id}`, null, config)
        if (!data.status || data.status === 'failed') {
            dispatch(GameValidationModal(false, 'mobileLegendsModalOpen'))
            Toast.fire({
                title: `Top up Failed`,
                icon: "warning",
                text: `${data.reason}`
            });
            // Notiflix.Report.warning(`Top up failed`, `${data.reason}`, 'Close')
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'mobileLegendsTopUpData'))
        dispatch(GameValidationModal(false, 'mobileLegendsModalOpen'))
        // open success Modal
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
    }
    finally {
        dispatch(GameTopUpLoading(false, 'mobileLegendsTopUpLoading'))
    }
}


export const razorGoldValidate = (brand_id, account_id, deno_id, denominations_db_name) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameValidationLoading(true, 'razorValidationLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`validate_${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}`, null, config)
        if (!data.status || data.status === 'failed') {
            let reason = data.reason.split(`\"`)
            Toast.fire({
                title: `${reason[3]}`,
                icon: "warning",
                text: `${reason[5]}`
            });
            // Notiflix.Report.warning(`${reason[3]}`, `${reason[5]}`, 'Close')
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'razorModalValidatedData'))
        dispatch(GameValidationModal(true, 'razorModalOpen'))
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
    }
    finally {
        dispatch(GameValidationLoading(false, 'razorValidationLoading'))
    }
}

export const razorGoldTopUp = (brand_id, account_id, deno_id, denominations_db_name) => async (dispatch) => {
    dispatch(clearErrors());
    dispatch(GameTopUpLoading(true, 'razorTopUpLoading'))

    let config = {
        headers: {
            account_id,
        }
    }

    try {
        const { data } = await ApiRequest.post(`${brand_id}?deno_id=${deno_id}&brand_id=${denominations_db_name}&location=PAL`, null, config)
        if (!data.status || data.status === 'failed') {
            dispatch(GameValidationModal(false, 'razorModalOpen'))
            Toast.fire({
                title: `Top up Failed`,
                icon: "warning",
                text: `${data.reason}`
            });
            // Notiflix.Report.warning(`Top up failed`, `${data.reason}`, 'Close')
            return
        }
        dispatch(GameValidationOrTopUpData(data, 'razorTopUpData'))
        dispatch(GameValidationModal(false, 'razorModalOpen'))
        // open success Modal
    } catch (err) {
        // open failure modal
        Notiflix.Report.failure(`SomeThing Went Wrong !`, '', 'Close')
    }
    finally {
        dispatch(GameTopUpLoading(false, 'razorTopUpLoading'))
    }
}

export const validationModal = (boolean) => {
    return {
        type: PLAYER_MODAL,
        payload: boolean,
    }
}

export const successfullModal = (boolean) => {
    return {
        type: SUCCESSFULL_MODAL,
        payload: boolean,
    }
}

export const fireFreePlayerData = (res) => {
    return {
        type: GET_FREE_FIRE_PLAYER_DATA,
        payload: res.data,
    }
}

export const setGamesSuccessfull = (res) => {
    return {
        type: GAME_SUCCESSFULL,
        payload: res.data,
    }
}

export const setGameBundlesData = (bundles) => {
    return {
        type: GET_GAME_DATA,
        payload: bundles,
    }
}

export const gameBundlesDataLoading = (boolean) => ({
    type: GET_GAME_DETAILS_LOADING,
    payload: boolean,
})

export const Loading = (boolean) => {
    return {
        type: GET_GAMES_DATA_LOADING,
        payload: boolean,
    };
}

export const handleError = (message) => {
    return {
        type: GET_ERRORS,
        payload: message,
    }
}


export const GameValidationLoading = (boolean, validationLoading) => ({
    type: GAME_VALIDATION_LOADING,
    payload: {
        boolean,
        validationLoading
    }
})

export const GameTopUpLoading = (boolean, topUpLoading) => ({
    type: GAME_SUBMIT_LOADING,
    payload: {
        boolean,
        topUpLoading
    }
})

export const GameValidationModal = (boolean, validationModalOpen) => ({
    type: GAME_VALIDATION_MODAL,
    payload: {
        boolean,
        validationModalOpen
    }
})

export const GameValidationOrTopUpData = (data, validationData) => ({
    type: GAME_VALIDATION_DATA,
    payload: {
        data,
        validationData
    }
})