const systemBalanceStyle = (theme) => ({
  avatarImg: {
    width: 64,
    height: 64,
    background: "white",
    "& img": {
      objectFit: "contain",
    },
  },
  avatarText: {
    paddingLeft: 8,
    fontSize: 16,
  },
  peletalkBalanceText: {
    fontSize: 18,
    paddingLeft: 72,
  },
  carrierBalanceText: {
    paddingLeft: 8,
    fontSize: 18,
  },
  gameLabel: {
    paddingLeft: 8,
    fontSize: 18,
  },
  paperStyle: {
    resize: "both",
    width: 400,
  },
});
export default systemBalanceStyle;
