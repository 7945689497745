import React from "react";
import { Button, Grid } from "@mui/material";
import translate from "../../../i18n/translate";
import { useHistory } from "react-router-dom";
import CreditsPageStyles from "./CreditsPageStyles";
import { makeStyles } from "@mui/styles";

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(CreditsPageStyles);
export default function CreditsPage(props) {
  const history = useHistory();
  const classes = useStyle(props);

  return (
    <Grid container>
      <Grid
        item
        container
        className={classes.fullHeight}
        md={12}
        xs={12}
        justifyContent={"center"}
      >
        <Button
          size="large"
          className={classes.linkButton}
          onClick={(e) => {
            e.preventDefault();
            history.push("/add_credits");
          }}
          href={"/add_credits"}
        >
          {translate("add credits")}
        </Button>
      </Grid>
      <Grid item container md={12} xs={12} justifyContent={"center"}>
        <Button
          className={classes.linkButton}
          size="large"
          onClick={(e) => {
            e.preventDefault();
            history.push("/credit_report");
          }}
          href={"/credit_report"}
        >
          {translate("credits_report")}
        </Button>
      </Grid>
    </Grid>
  );
}

CreditsPage.propTypes = propTypes;
CreditsPage.defaultProps = defaultProps;
