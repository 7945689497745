import React, { useCallback, useEffect, useRef, useState } from "react";
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import translate from "i18n/translate";
import {
  Box,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import Pagination from "components/common/Pagination";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment";
import CopyButton from "core/core-components/CopyButton/CopyButton";
import { conditionalReturn, isNullOrUndefined } from "shared/utils/utils";
import paymentsReportStyles from "./paymentsReportStyles";
import { makeStyles } from "../../../../node_modules/@mui/styles/index";
import { getPaymentsReport } from "actions/reportsAction";
import UserTypesDropdownContainer from "core/containers/UserTypesDropdownContainer/UserTypesDropdownContainer";
import { isArray } from "lodash";
import SearchButton from "core/core-components/SearchButton/SearchButton";
import "../../report/report.css";
import DatePicker from "react-datepicker";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isEnglish } from "shared/utils/languageUtils";
import CancelableInput from "core/core-components/CancelableInput/CancelableInput";
import { intl } from "i18n/provider";
import Spinner from "components/ui/spinner/Spinner";

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(paymentsReportStyles);
export default function PaymentsReport(props) {
  const classes = useStyle(props);
  const isDarkTheme = useSelector((state) => state.darkTheme.isDarktheme);
  const [rows, setRows] = useState({ value: "5", label: 5 });
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsButtonClicked, setIsDetailsButtonClicked] = useState({
    flag: false,
    index: null,
  });
  const [paymentsReport, setPaymentsReport] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState();
  const [note, setNote] = useState("");
  const dateOneDayEarlier = new Date().setHours(new Date().getHours() - 24);

  // const [selectedDateType, setSelectedDateType] = useState();
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const dispatch = useDispatch();
  const hide = localStorage.getItem("hide companies");
  const reqInterval = useRef(null);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  let language = localStorage.getItem("langCity");
  const getReportData = useCallback(
    async (offset, rowsParam) => {
      setIsLoading(true);
      try {
        const { data } = await dispatch(
          getPaymentsReport({
            date_from: moment(dateFrom).format("YYYY-MM-DD"),
            date_to: moment(dateTo).format("YYYY-MM-DD"),
            no_of_rows: rowsParam ?? rows.value,
            offset: offset ?? 0,
            seller_id: selectedSeller?.value ?? "",
            note: note,
          })
        );

        if (!isArray(data)) throw new Error("Could not get report");
        setPaymentsReport(data);
      } catch (error) {
        setPaymentsReport([]);
        console.log("ERROR", error);
      }
      setIsLoading(false);
    },
    [dateFrom, dateTo, dispatch, rows?.value, selectedSeller?.value]
  );

  const stopRequest = () => {
    clearInterval(reqInterval.current);
  };

  const handleOffsetValue = (event, value) => {
    setPage(value);
    let queryOffset = value * rows.label - rows.label;
    getReportData(queryOffset, rows.value);
    stopRequest();
  };

  const handleSortChange = (v) => {
    v = { value: v, label: v };
    setPage(1);
    setRows(v);
    if (paymentsReport?.length) {
      getReportData("0", v.value);
    }
    stopRequest();
  };

  useEffect(() => {
    if (paymentsReport?.length) {
      setCount(Number(paymentsReport[0].count));
    } else {
      setCount(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsReport?.length]);

  function renderTotals() {
    return (
      <>
        <Grid item md={6} xs={12}>
          <Typography>
            {translate("total_count")} : <b>{paymentsReport?.[0]?.count}</b>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>
            {translate("sum_of_payments")} :{" "}
            <b>{paymentsReport?.[0]?.sum_of_payments}</b>
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {conditionalReturn(
          isAdmin,
          <Grid item md={6} xs={12}>
            <UserTypesDropdownContainer
              onSellerSelected={(s) => {
                setSelectedSeller(s);
              }}
            />
          </Grid>
        )}
        <Grid item md={6} xs={12} sm={12}>
          <CancelableInput
            onChange={(element) => setNote(element.target.value)}
            className="form-control report-input-left-column"
            placeholder={intl.formatMessage({ id: "Note" })}
            onClearClick={(e) => setNote("")}
            value={note}
            style={{ width: "100%" }}
            // disabled={disableAttr}
          />
        </Grid>
        <Grid
          item
          padding={0}
          spacing={2}
          container
          md={12}
          xs={12}
          style={{ position: "relative" }}
        >
          <Grid item md={6} xs={12}>
            <DatePicker
              selected={dateFrom}
              type="date"
              dateFormat="dd-MM-yyyy"
              className={"date-picker form-control"}
              onChange={(e) => setDateFrom(e)}
            />
          </Grid>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              left: "calc(50% + 8px)",
              top: 38,
              width: 16,
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <ArrowForwardIcon
              style={{
                fontSize: 16,
                transform: language == "ar" ? "scaleX(-1)" : "none", // Flips the icon horizontally if Arabic
              }}
            />
          </span>
          <Grid item md={6} xs={12}>
            <DatePicker
              selected={dateTo}
              type="date"
              dateFormat="dd-MM-yyyy"
              className={"date-picker form-control"}
              onChange={(e) => setDateTo(e)}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} display="flex" justifyContent="end">
          <SearchButton
            isLoading={isLoading}
            onClick={() => getReportData(0, 5)}
          />
        </Grid>
        {conditionalReturn(
          paymentsReport?.length,
          <Grid item md={12} xs={12} display="flex" justifyContent="center">
            {renderTotals()}
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <div
            style={{ marginTop: 16 }}
            className={
              !isDarkTheme ? "row table-wrapper" : "row dark-table-wrapper"
            }
          >
            <div className="d-flex justify-content-between">
              <div className="table-title">
                <h5>{translate("payments_report")}</h5>
              </div>
              <div className="d-flex justify-content-between">
                <Box sx={{ marginRight: "16px", marginLeft: 16 }}>
                  {paymentsReport[0]?.total_wholesales && (
                    <div>
                      {translate("total")}:{" "}
                      {paymentsReport[0]?.total_count || 0} NIS
                    </div>
                  )}
                </Box>
                <div className="d-flex">
                  {hide === "true" ? <VisibilityOffIcon /> : null}
                </div>
              </div>
            </div>
            <Pagination
              count={count}
              page={page}
              handleOffsetValue={handleOffsetValue}
              handleSortChange={handleSortChange}
              sort={rows}
            />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ width: 50 }}>
                    <i className="m-1" aria-hidden="true"></i>
                  </th>
                  <th style={{ width: 150 }} scope="col">
                    {translate("transactionNo")}
                  </th>
                  <th style={{ width: 150 }} scope="col">
                    {translate("dateTime")}
                  </th>
                  <th style={{ width: 150 }} scope="col">
                    {translate("seller_id")}
                  </th>
                  <th style={{ width: 150 }} scope="col">
                    {translate("seller_name")}
                  </th>
                  <th style={{ width: 150 }} scope="col text-center">
                    {translate("amount")}
                  </th>
                  {/* <th style={{ width: 150 }} scope="col text-center">
                    {translate("paid_amount")}
                  </th> */}
                  {/* <th style={{ width: 150 }} scope="col">
                    {translate("date_paid")}
                  </th> */}
                  <th style={{ width: 150 }} scope="col">
                    {translate("paidInFull")}
                  </th>
                  <th style={{ width: 150 }} scope="col">
                    {translate("user")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {paymentsReport?.map((report, index) => {
                  return (
                    <React.Fragment key={report?.index}>
                      <tr
                        style={{
                          height: 60,
                        }}
                      >
                        <td
                          onClick={() =>
                            setIsDetailsButtonClicked({
                              flag: !isDetailsButtonClicked.flag,
                              index: index,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            className={
                              isDetailsButtonClicked.flag &&
                              isDetailsButtonClicked.index === index
                                ? "bi bi-chevron-down flip"
                                : "bi bi-chevron-down"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </td>
                        <td
                          style={{
                            color: "black",
                            position: "relative",
                          }}
                        >
                          <div style={{ width: 100 }}>
                            <CopyButton
                              buttonClassName={classes.copyBtn}
                              textToCopy={report?.trans_id?.toString()}
                            />
                            <p>{report.trans_id}</p>
                          </div>
                        </td>
                        <td>
                          {moment(report.datetime.split(".")[0]).format(
                            "YYYY-MM-DD - HH:mm"
                          )}
                        </td>
                        <td
                          style={{
                            color: "black",
                            position: "relative",
                          }}
                        >
                          <div style={{ width: 100 }}>
                            <CopyButton
                              buttonClassName={classes.copyBtn}
                              textToCopy={report?.seller_id?.toString()}
                            />
                            <p>{report.seller_id}</p>
                          </div>
                        </td>
                        <td
                          style={{
                            color: "black",
                            position: "relative",
                          }}
                        >
                          <div>
                            <p
                              className={classes.sellerName}
                              style={{
                                direction: isEnglish(report.name)
                                  ? "ltr"
                                  : "rtl",
                              }}
                            >
                              {report.seller_name}
                            </p>
                          </div>
                        </td>
                        <td>{report.amount}</td>

                        {/* <td>
                          <div style={{ width: 120 }}>{report.amount_paid}</div>
                        </td> */}

                        {/* <td>
                          {moment(report.date_paid.split(".")[0]).format(
                            "YYYY-MM-DD - HH:mm"
                          )}
                        </td> */}
                        <td>
                          <div style={{ width: 100 }}>
                            {report.paid_in_full.toLowerCase() === "true"
                              ? "YES"
                              : "NO"}
                          </div>
                        </td>
                        <td
                          style={{
                            color: "black",
                            position: "relative",
                          }}
                        >
                          <div>
                            <CopyButton
                              buttonClassName={classes.copyBtn}
                              textToCopy={report?.admin_user?.toString()}
                            />
                            <p>{report.user}</p>
                          </div>
                        </td>
                      </tr>
                      {isDetailsButtonClicked.flag &&
                        isDetailsButtonClicked.index === index && (
                          <tr style={{ backgroundColor: "white" }}>
                            <td colSpan={9}>
                              <Grid container>
                                <Grid item xs={12} md={12}>
                                  {conditionalReturn(
                                    !isNullOrUndefined(report?.note),
                                    <div className="table-details">
                                      <span>Note:</span>
                                      <span>{report.note}</span>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </td>
                          </tr>
                        )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            {!paymentsReport?.length && (
              <div className="no-data-to-show">
                {translate("No data to show")}
              </div>
            )}
            {/* {sellerReports?.length > 0 && <Pagination count={count} page={page} handleOffsetValue={handleOffsetValue} handleSortChange={handleSortChange} sort={sort} />} */}
          </div>
        </Grid>
      </Grid>
      {isLoading && <Spinner />}
    </React.Fragment>
  );
}

PaymentsReport.propTypes = propTypes;
PaymentsReport.defaultProps = defaultProps;
