import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import NavigateBackHeader from "../../../../core/core-components/NavigateBackHeader/NavigateBackHeader";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isNullOrUndefined } from "../../../../shared/utils/utils.js";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import IconButton from "@mui/material/IconButton";
import PubgCharge from "./PubgCharge.jsx";
import { makeStyles } from "@mui/styles";
import { useIsRtl } from "../../../../shared/hooks/ppHooks";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryMessage from "components/ErrorBoundary/ErrorBoundaryMessage";

const { useGoBackIf } = require("../../../../shared/hooks");

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(() => ({
  rtl: {
    direction: "rtl",
    flexDirection: "row-reverse",
  },
}));

export default function GamesCharge(props) {
  const gamesList = useSelector((state) => state?.games?.gamesArray) || [];
  const { id: gameId } = useParams();
  const goBackIf = useGoBackIf("");
  const isRtl = useIsRtl();
  const classes = useStyle();
  const history = useHistory();
  useEffect(() => {
    goBackIf(!gamesList.length);
  }, [goBackIf, gamesList?.length]);

  const currentGame = gamesList.find((game) => game.id === gameId);
  goBackIf(isNullOrUndefined(currentGame));

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={12} xs={12}>
          <NavigateBackHeader
            onBackClick={() => history.push("/gaming")}
            title={currentGame?.company_name || "Back"}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryMessage}
            onError={(e) => {
              console.log(e);
            }}
          >
            <PubgCharge gameName={currentGame?.company_name} />
          </ErrorBoundary>
          {/*<Wizard footer={<WizardFooter children={<Footer />} getWizard={(wiz) => {}} />}>{renderSteps()}</Wizard>*/}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

GamesCharge.propTypes = propTypes;
GamesCharge.defaultProps = defaultProps;
//
// function Footer(props) {
//   console.log("footerPropss", props);
//   const { wizard } = props;
//   const { nextStep, previousStep, activeStep, stepCount } = wizard;
//   return (
//     <Grid item container justifyContent={"center"} paddingTop={"16px"}>
//       <Grid item container justifyContent={"center"}>
//         <Grid item>
//           <IconButton title="Back" aria-label="Back" size="small" color="info" onClick={previousStep}>
//             <ArrowBackIosIcon fontSize="large" />
//           </IconButton>
//         </Grid>
//         <Grid item>
//           <IconButton title="Next" aria-label="Next" size="small" color="info" onClick={nextStep}>
//             <ArrowForwardIosIcon fontSize="large" />
//           </IconButton>
//         </Grid>
//       </Grid>
//       <Grid item>
//         <p>
//           Question number {activeStep + 1} of {stepCount}
//         </p>
//       </Grid>
//     </Grid>
//   );
// }
