import { createContext, useState } from "react";

export const noBalanceContext = createContext({
  openCard: false,
  toggleCard: () => {},
});

const NoBalanceProvider = ({ children }) => {
  const [openCard, setOpenCard] = useState(false);

  const openCardHandler = () => {
    setOpenCard(true);
  };
  const closeCard = () => {
    setOpenCard(false);
  };
  const value = {
    openCardHandler,
    openCard,
    closeCard,
  };

  return (
    <noBalanceContext.Provider value={value}>
      {children}
    </noBalanceContext.Provider>
  );
};

export default NoBalanceProvider;
