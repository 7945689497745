import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useHistory, useLocation } from "react-router-dom";
import { getHot, ChargeHot, setHot } from "../../../actions/companiesAction";
import Spinner from "../../ui/spinner/Spinner";
import Badge from "../../ui/Badge/Badge";
import { isArray, isEmpty, isNil } from "lodash";
import { dataLanguage } from "./../../../i18n/translateRequest";
import CompaniesNav from "../../common/CompaniesNav";
import Button from "../../common/Button";
import { SESSION_STORAGE_ITEMS_ENUM } from "../../../shared/constants/localStorageItemsEnum.js";
import { jsonParser } from "shared/utils/ppUtils";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import {
  HOT_ID,
  HOT_Name,
  IS_MOBILE_DISPLAY,
  PROVIDER_NAME_TO_ID_DICTIONARY,
} from "shared/constants/constants";
import { noBalanceContext } from "reducers/no_balance";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNumberChanged } from "../../../actions/companiesAction";
import { DndProvider } from "react-dnd";
import GroupCompaniesBundleCard from "../group/GroupCompaniesBundleCard";
import ConfirmModal from "../shared/InfoModal";

const Hot = ({ getHot, ChargeHot, hot, loading, isDarkTheme, setHot }) => {
  const noBalanceCtx = useContext(noBalanceContext);

  const history = useHistory();
  const pathname = history.location.pathname.split("/");
  const mobileNumber = pathname?.[pathname.length - 1];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const numberFromQuery = queryParams.get("number") || "";
  const disabledButton =
    !/^\d{10}$/.test(mobileNumber) ||
    (numberFromQuery && numberFromQuery !== mobileNumber);
  const [selected, setSelected] = useState();
  const [openInfoModel, setOpenInfoModel] = useState(false);

  const mobileNo = history.location.pathname.split("/")[3];
  const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-4");
  const [loadingSpinner, isLoading] = useState(false);

  const collapseRef = useRef([]);
  const listRef = useRef(null);

  const dispatch = useDispatch();
  // To read the state value
  const numberIsChanged = useSelector(
    (state) => state.companies.numberIsChanged
  );
  useEffect(() => {
    if (numberIsChanged) {
      dispatch(setNumberChanged(false));
      clearSelected();
    }
  }, [numberIsChanged]);

  useEffect(() => {
    getHot(mobileNo);
    document.title = "Hot mobile | Phone Play";
    refreshColumnStyle();
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      collapseRef.current.forEach((item) => {
        item?.classList?.remove("show");
      });
    }
  };

  useEffect(() => {
    const handleSessionStorageChange = () => {
      if (sessionStorage[SESSION_STORAGE_ITEMS_ENUM.hotBundle]) {
        setSelected(
          jsonParser(sessionStorage[SESSION_STORAGE_ITEMS_ENUM.hotBundle])
        );
      }
    };

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    // Clean up the event listener
    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
  }, [history]);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = hot[dragIndex];
      const data = update(hot, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      console.log("hotdata", data);
      setHot(data);
    },
    [hot, setHot]
  );

  const onTypeClick = (item) => {
    setSelected(item);
  };

  const onChargeClick = (e) => {
    e.preventDefault();
    isLoading(true);

    ChargeHot(mobileNo, selected, history)
      .then((check) => {
        if (check === "noBalance") {
          noBalanceCtx.openCardHandler();
        }
      })
      .finally(() => {
        clearSelected();
        isLoading(false);
      });
  };
  const clearSelected = () => {
    setSelected(null);
  };
  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6  card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };
  const refreshClick = () => {
    getHot(mobileNo, undefined, true);
  };
  let topDiv = null;

  let backLink = history.location.pathname.split("/");
  backLink.pop();
  backLink = backLink.join("/");

  useEffect(() => {
    window.localStorage.setItem("hot", JSON.stringify(hot));
  }, [hot]);

  return (
    <>
      <div ref={(ref) => (topDiv = ref)}>
        <CompaniesNav
          title="Hot Mobile"
          logo={
            require(`../../../assests/images/bundles/hot/banner.jpg`).default
          }
          mobile={mobileNo}
          providerId={window?.history?.state?.state?.provider_Id || HOT_ID}
          onRefresh={refreshClick}
          actionWidth="130px"
          backLink={backLink}
          comingFromReports={!window?.history?.state?.state?.provider_Id}
        />

        <div className="position-relative">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className={IS_MOBILE_DISPLAY ? "col-12" : "col-10"}>
                  <div
                    className={`card m-4s fixed-top1 position-sticky mt-2 ${
                      isDarkTheme && "dark-background"
                    }`}
                    style={IS_MOBILE_DISPLAY ? { padding: "10px 0 30px" } : {}}
                  >
                    {IS_MOBILE_DISPLAY && (
                      <div className="mt-2">
                        <div
                          className={` ${isDarkTheme && "dark-background"}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h5
                              className="text-muted mt-1 mb-2"
                              title="Balance"
                              style={{ fontSize: "1.2rem" }}
                            >
                              {translate("total")}:{" "}
                            </h5>
                            <h3 className="text-info mt-2">
                              ₪{" "}
                              {selected?.amount
                                ? parseFloat(selected?.amount)
                                : 0}
                            </h3>
                          </div>
                          <Button
                            formType="submit"
                            className={`mx-2 btn btn sign-but ${
                              !selected && "disabled"
                            }`}
                            onClick={() => setOpenInfoModel(true)}
                            title="accept"
                            loading={loadingSpinner}
                            disabled={disabledButton}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row mt-1 fixed-topx px-3">
                      {!isNil(selected) && !isEmpty(selected) && (
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                          <div className="card">
                            <div className="frame1">
                              <img
                                alt="Hot Credit"
                                src={selected.url}
                                width="260px"
                                height="100px"
                              ></img>
                              <span
                                className="close-btn"
                                onClick={clearSelected}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!IS_MOBILE_DISPLAY && (
                  <div className="col-2">
                    <div className="card total-balance-card mt-2">
                      <div
                        className={`card-body p-2 ${
                          isDarkTheme && "dark-background"
                        }`}
                      >
                        <h5
                          className="text-muted mt-1 mb-2"
                          title="Balance"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {translate("total")}
                        </h5>
                        <h3 className="text-info mt-2">
                          ₪{" "}
                          {selected?.amount ? parseFloat(selected?.amount) : 0}
                        </h3>
                        <Button
                          formType="submit"
                          className={`mx-2 btn btn sign-but ${
                            !selected && "disabled"
                          }`}
                          onClick={() => setOpenInfoModel(true)}
                          title="accept"
                          loading={loadingSpinner}
                          disabled={disabledButton}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr
            className="mt-3"
            style={{
              border: "2px solid #42ace3",
              backgroundColor: "#42ace3",
              fontWeight: "bolder",
            }}
          />

          <div
            className={`${isDarkTheme ? "dark-card" : "card"} list-cards p-4`}
            ref={listRef}
          >
            <div className="row">
              {loading && <Spinner />}
              {!isArray(hot) && !loading ? (
                <div className="d-flex justify-content-center mt-3">
                  <h2 className="text-info">
                    {translate("No available bundles")}
                  </h2>
                </div>
              ) : (
                <DndProvider backend={HTML5Backend}>
                  {hot.map((item, index) => (
                    <GroupCompaniesBundleCard
                      key={item.PID}
                      columnStyle={columnStyle}
                      item={item}
                      onTypeClick={onTypeClick}
                      index={index}
                      id={item.PID}
                      collapseRef={collapseRef}
                      isDarkTheme={isDarkTheme}
                      moveCard={moveCard}
                    />
                  ))}
                </DndProvider>
              )}
            </div>
          </div>
        </div>
        <ConfirmModal
          phoneNumber={mobileNumber}
          selectedCards={[selected]}
          open={openInfoModel}
          handleConfirm={(e) => {
            setOpenInfoModel(false);
            onChargeClick(e);
          }}
          handleClose={() => setOpenInfoModel(false)}
          totalAmount={selected?.amount ? parseFloat(selected?.amount) : 0}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  hot: state.companies.hot,
  loading: state.companies.loading,
  isDarkTheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { getHot, ChargeHot, setHot })(Hot);
