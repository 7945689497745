import React, { useState } from 'react'
import './styles.css'
import ContactUsForm from "./ContactUsFrom";
import ContactUsTable from './ContactUsTable';
const ContactUs = () => {
    const [sort, setSort] = useState({ value: '5', label: 5 })

    return (
        <>
            <ContactUsForm sort={sort}  />
            <ContactUsTable sort={sort} setSort={setSort} />
        </>
    )
}

export default ContactUs