import {
  SET_CURRENT_USER,
  GET_USER_DATA,
  GET_USER_DETAILS,
  USER_DETAILS_LOADING,
  VERIFY_NUMBER,
  VERIFY_NUMBER_LOADING,
  CONFIRM_NUMBER,
  CONFIRM_NUMBER_LOADING,
  CONFIRM_NUMBER_ERROR,
} from "../actions/types";
import isEmpty from "./../validation/is_empty";
import setRequestHeader from "../components/common/setRequestHeader";

const rating = {
  A: 5,
  B: 4,
  C: 3,
  D: 2,
  E: 1,
};

const initialState = {
  isAuthenticated: false,
  user: null,
  userData: null,
  userDetails: null,
  userDetailsLoading: false,
  verifiedNumber: false,
  verifiedNumberLoading: false,
  confirmedNumber: false,
  confirmerNumberLoading: false,
  confirmedNumberError: false,
  rating: 0,
  isAdmin: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      if (action.payload?.token) {
        setRequestHeader("token", action.payload.token);
      }
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        isAdmin: action.payload?.seller_type?.toLocaleLowerCase() === "admin",
        rating: rating[action.payload?.category?.toUpperCase()],
      };
    case GET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        rating: rating[action.payload?.category?.toUpperCase()],
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        isAuthenticated: true,
        userDetails: action.payload,
        user: action.payload,
        userDetailsLoading: false,
      };
    case USER_DETAILS_LOADING:
      return {
        ...state,
        userDetailsLoading: action.payload,
      };
    case VERIFY_NUMBER_LOADING:
      return {
        ...state,
        verifiedNumberLoading: true,
      };
    case VERIFY_NUMBER:
      return {
        ...state,
        verifiedNumber: action.payload,
        verifiedNumberLoading: false,
      };
    case CONFIRM_NUMBER_LOADING:
      return {
        ...state,
        confirmerNumberLoading: true,
      };
    case CONFIRM_NUMBER:
      return {
        ...state,
        confirmedNumber: action.payload,
        confirmerNumberLoading: false,
        confirmedNumberError: false,
      };
    case CONFIRM_NUMBER_ERROR:
      return {
        ...state,

        confirmedNumberError: true,
      };
    default:
      return state;
  }
};

export default userReducer;
