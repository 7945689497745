import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import translate from "../../../i18n/translate";
// Icons
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import SettingsIcon from "@mui/icons-material/Settings";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import ShareIcon from "@mui/icons-material/Share";
import { dataLanguage } from "../../../i18n/translateRequest";
import TextFieldGroup from "../../../components/common/TextFieldGroup";
import { EMPTY_CREDIT } from "../../../components/companies/jawwal/credits";
import RotatingIcon from "components/common/RotatePendingIcon";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { chargeJawwal, chargeOoredoo } from "../../../actions/companiesAction";

import {
  Box,
  CircularProgress,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import CancelTransactionModal from "../../../components/common/CancelTransactionModal";

// import refund from "assests/images/icons/cancel.png";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import SaleReportTableStyles from "./SaleReportTableStyles.js";
import { makeStyles } from "@mui/styles";
import {
  CARD_TYPES,
  CARD_TYPES_FROM_API,
  PROVIDERS,
} from "../../../shared/constants/constants";
import CopyButton from "../CopyButton/CopyButton";
import { isEnglish } from "../../../shared/utils/languageUtils";
import {
  conditionalReturn,
  isNullOrUndefined,
  isPast24Hours,
  isPast48Hours,
  parseBool,
} from "shared/utils/utils";
import { isPeletalk } from "shared/utils/ppUtils";
import HTable from "material-htable2";
import { HCard, HCardHeader } from "material-hcard-private";
import RefreshButton from "../RefreashButton/RefreshButton";
import Modal from "../../../components/common/Modal";
import ApiRequest from "../../../actions/ApiRequest";
import Notiflix, { Loading } from "notiflix";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ExportButton from "components/common/ExportButton";
import { useEffect } from "react";
import SelectSeller from "../../../components/cards/SelectSeller";
import ImageModal from "components/cards/UploadImage";
import ShareModal from "components/profile/ShareModal";
import { useIsAdmin } from "shared/hooks/ppHooks";
import UploadImage from "components/esim/UploadImage";
import Toast from "components/common/Toast";
import { intl } from "i18n/provider";
import { set } from "lodash";
import PrintButton from "../printButton/PrintButton";
import { ShowToastPromise } from "components/common/ShowToastPromise";
const propTypes = {
  title: PropTypes.oneOfType(
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ),
  isLoading: PropTypes.bool,
  isCreditReport: PropTypes.bool,
  hideTotals: PropTypes.bool,
  totalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleOffsetValue: PropTypes.func,
  handleSortChange: PropTypes.func,
  sort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEditClick: PropTypes.func,
};

const useStyle = makeStyles(SaleReportTableStyles);

function SaleReportTable(props) {
  const {
    title,
    count,
    onRefresh,
    page,
    handleOffsetValue,
    handleSortChange,
    sort,
    onEditClick,
    isCreditReport,
    onRowsPerPageChange,
    onPageChange,
    rowsPerPage,
    totalText,
    onSettingClick,
    isLoading,
    onLoadingSetting,
    chargeOoredoo,
    chargeJawwal,
  } = props;
  const classes = useStyle(props);
  const [image, setImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isUploadImageLoading, setIsUploadImageLoading] = useState(false);
  const hide = localStorage.getItem("hide companies") === "true";

  const sellerReports = useSelector((state) => state.reports.sellerReports);

  const isAdminSeller = useSelector(
    (state) => state.auth?.user.seller_type?.toLowerCase() === "admin"
  );

  const isOtherSeller = useSelector(
    (state) => state.auth?.user.seller_type?.toLowerCase() === "other"
  );
  const [isUpdateFieldLoading, setIsUpdateFieldLoading] = useState(false);
  const [editField, setEditField] = React.useState({
    fieldName: "",
    value: "",
  });
  const [tranId, setTranId] = React.useState("");
  const [isFieldModalOpened, setIsFieldModalOpened] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [get_allsellers, setGet_allsellers] = React.useState([]);
  const [amountSeller, setAmountSeller] = React.useState("");
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const [WhatsAppNote, setWhatsAppNote] = React.useState("");
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [referalCode, setReferalCode] = React.useState("");
  const sellerId = useSelector((state) => state.auth?.user);
  const { isReseller } = useIsAdmin();

  useEffect(() => {
    const getAllSellers = async () => {
      const res = await ApiRequest.post(
        `get_allseller?reseller_id=${isReseller ? sellerId.id : ""}`
      );
      setGet_allsellers(res.data.all_sellers);
    };

    getAllSellers();
  }, []);

  const sendImage = async () => {
    if (!image) return;
    setIsUploadImageLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image, `img.jpg`);
      await ApiRequest.post(
        `upload_bill_receipt?trans_id=${selectedReport.transid}`,
        formData
      );
      setIsUploadImageLoading(false);
      setImageModal(false);
      setImage(null);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your Message has been sent successfully",
        }),
        icon: "success",
      });
    } catch (error) {
      setIsUploadImageLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Something Went Wrong",
        }),
        icon: "error",
      });
      Notiflix.Report.failure("Something Went Wrong", "", "Close");
    }
  };
  const history = useHistory();
  const onDownloadBillClick = (report) => {
    const url = report?.url;

    if (url) {
      window.open(url, "_blank");
    }
  };

  const cardAmountHandle = (item) => {
    const isJawwalOrOoredoo = ["Jawwal", "Ooredoo"].includes(item.provider);

    if (
      ![10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 200].includes(
        item.cardamount
      ) &&
      isJawwalOrOoredoo &&
      item.card_type === "Credit"
    ) {
      return (
        <div className="card" style={{ width: "100%", margin: "auto" }}>
          <img alt="" src={EMPTY_CREDIT.url} style={{ width: "350px" }}></img>
          <TextFieldGroup
            style={{
              width: "calc(40%)",
              height: "50%",
              padding: 0,
              position: "absolute",
              top: "50%",
              left: "calc(55%)",
              transform: "translateY(-50%)",
              fontFamily: '"Montserrat", sans-serif',
              textAlign: "center",
              border: "none",
              fontSize: "2.8rem",
              color: "green",
              fontWeight: "bold",
            }}
            name="price"
            type="number"
            value={item.cardamount}
            onChange={() => {}}
            min={10}
          />
        </div>
      );
    } else {
      let imgSrc = "";
      try {
        imgSrc =
          item.url === "N/A"
            ? require(`../../../assests/images/bundles/${item.provider.toLowerCase()}/${item.provider.toLowerCase()}-${item.cardamount
                ?.toString()
                ?.replace("-", "")}.png`).default
            : item.url;
      } catch (error) {
        console.log("cardAmountHandle SaleReportTable", error);
      }
      // return (
      //   <span style={{ display: "inline-block", width: 120, height: 57, paddingRight: 4 }}>
      //     <AmountCard amount={item.cardamount?.toString()?.replace("-", "")} />
      //   </span>
      // );
      return (
        <img
          alt={item.cardamount?.toString()}
          src={imgSrc}
          style={{
            width: "350px",
            maxHeight: 200,
            paddingRight: 4,
          }}
        />
      );
    }
  };

  const handleTelecomRouting = (provider, number) => {
    switch (provider) {
      case "Jawwal":
        history.push(`/company/jawwalCredit/${number}`, {
          provider_id: "001",
        });
        break;
      case "Ooredoo":
        history.push(`/company/ooredoo/credit/${number}`, {
          provider_id: "002",
        });
        break;
      case "Paltel":
        history.push(`/pay_bills/paltel`, {
          number: number,
        });
        break;
      case "Hot":
        history.push(
          `/company/hot/${number}?providerId=008&mobileNumber=${number}`,
          {
            provider_Id: "008",
            mobileNumber: number,
          }
        );
        break;
      case "Mobile019":
        history.push(
          `/company/azy/${number}?providerId=009&mobileNumber=${number}`,
          {
            provider_Id: "009",
            mobileNumber: number,
          }
        );
        break;
      case "Cellcom 2":
        history.push(
          `/company/group/Cellcom 2/${number}?providerId=218&mobileNumber=${number}`,
          {
            provider_Id: "218",
            mobileNumber: number,
          }
        );
        break;
      case "Pelephone":
        history.push(
          `/company/group/Pelephone/${number}?providerId=095&mobileNumber=${number}`,
          {
            provider_Id: "095",
            mobileNumber: number,
          }
        );
        break;
      case "Partner":
        history.push(
          `/company/group/Partner/${number}?providerId=127&mobileNumber=${number}`,
          {
            provider_Id: "127",
            mobileNumber: number,
          }
        );
        break;
      case "012 Mobile":
        history.push(
          `/company/group/012 mobile/${number}?providerId=201&mobileNumber=${number}`,
          {
            provider_Id: "201",
            mobileNumber: number,
          }
        );
        break;
      case "Golan Telecom":
        history.push(
          `/company/group/Golan telecom/${number}?providerId=214&mobileNumber=${number}`,
          {
            provider_Id: "214",
            mobileNumber: number,
          }
        );
        break;
      case "Nedco":
        history.push(`/pay_bills/nedco`, {
          number: number,
        });
        break;
      case "Selco":
        history.push(`/pay_bills/selco`, {
          number: number,
        });
        break;
      case "Mada":
        history.push(`/pay_bills/mada`, {
          number: number,
        });
        break;
      case "Gemzo":
        history.push(`/pay_bills/gemzo`, {
          number: number,
        });
        break;
      case "Jawwal Invoice":
        history.push(`/pay_bills/jawwal`, {
          number: number,
        });
        break;
      case "Ooredoo Invoice":
        history.push(`/pay_bills/ooredoo_bills`, {
          number: number,
        });
        break;

      case "Pubg":
        history.push(`/gaming/004`, {
          number: number,
        });
        break;
      case "Jawaker":
        history.push("/jwaker", {
          number: number,
        });
        break;
      case "Tiktok":
        history.push("/tiktok", {
          number: number,
        });
        break;
      default:
        break;
    }
  };

  const chargeJawwalExtra = async (updateReport, cardType) => {
    const g3 = cardType == CARD_TYPES_FROM_API.jawwal3g ? updateReport : null;
    const rom = cardType == CARD_TYPES_FROM_API.jawwalRom ? updateReport : null;
    const credit =
      cardType == CARD_TYPES_FROM_API.jawwalCredit ? updateReport : null;
    const min = cardType == CARD_TYPES_FROM_API.jawwalMin ? updateReport : null;
    const plus =
      cardType == CARD_TYPES_FROM_API.jawwalPlus ? updateReport : null;
    return await chargeJawwal(
      {
        jawwal3g: g3,
        jawwalRom: rom,
        jawwalCredit: credit,
        jawwalMin: min,
        jawwalPlus: plus,
      },
      undefined,
      undefined,
      false,
      updateReport.number,
      true
    );
  };

  const chargeOoredooExtra = async (updateReport, cardType) => {
    const min =
      cardType == CARD_TYPES_FROM_API.ooredooMin ? updateReport : null;
    const rom =
      cardType == CARD_TYPES_FROM_API.ooredooRom ? updateReport : null;
    const shabab =
      cardType == CARD_TYPES_FROM_API.ooredooShabab ? updateReport : null;
    const g3 = cardType == CARD_TYPES_FROM_API.ooredoo3g ? updateReport : null;
    const credit =
      cardType == CARD_TYPES_FROM_API.ooredooCredit ? updateReport : null;

    console.log("updateReport", updateReport, rom, credit, min, g3, shabab);

    return await chargeOoredoo(
      {
        ooredoo3g: g3,
        ooredooRom: rom,
        ooredooCredit: credit,
        ooredooMin: min,
        ooredooSuper: shabab,
      },
      undefined,
      undefined,
      false,
      updateReport.number,
      true
    );
  };

  const resendFailedTransaction = async (report) => {
    const provider = report.provider;
    const cardType = report.card_type?.toLowerCase();
    const updateReport = {
      price: report.cardamount,
      pci: report.PCI,
      id: cardType === "credit" ? "0" : report.PCI,
      bundleid: report.PCI,
      cardType: report.card_type,
      url: report.url,
      number: report.number,
    };
    if (provider === "Jawwal") {
      ShowToastPromise(chargeJawwalExtra.bind(null, updateReport, cardType), {
        mobileNumber: report.number,
      });
    } else if (provider === "Ooredoo") {
      ShowToastPromise(chargeOoredooExtra.bind(null, updateReport, cardType), {
        mobileNumber: report.number,
      });
    }
  };

  const showCancellation = (report) => {
    const cardType = report?.card_type;
    const provider = report?.provider;
    if (
      parseBool(report.cancelrequest) ||
      parseBool(report.canceled) ||
      report.status !== "success" ||
      !isNaN(Number(provider)) ||
      ["GAME", "voucher"].includes(cardType) ||
      [
        "Jawaker",
        "Pubg",
        "Ooredoo Invoice",
        "Nedco",
        "Selco",
        "Jawwal Invoice",
        "Mada",
        "Tiktok",
        "Sms",
        "Paltel",
      ].includes(provider) ||
      (provider === "Jawwal" && cardType !== "Credit")
    ) {
      return false;
    }

    let show = false;

    const reportDate = new Date(report?.datetime);
    const past24Hours = isPast24Hours(reportDate);
    const past48Hours = isPast48Hours(reportDate);

    if (!past24Hours) {
      show = true;
    }

    if (!past48Hours && provider === PROVIDERS.ooredoo) {
      show = true;
    }

    if (!past24Hours && provider === PROVIDERS.hot) {
      show = true;
    }

    if (isPeletalk(provider) && !past48Hours) {
      show = true;
    }

    return show;
  };

  const handleEditField = (value, fieldName, tranId) => {
    setEditField({
      fieldName,
      value,
    });
    setTranId(tranId);
    setIsFieldModalOpened(true);
  };

  const handleWhatsApp = (note, transId, number) => {
    const phoneNumberPattern = /^\d+$/;
    if (phoneNumberPattern.test(note)) {
      // Note is a valid number
      // const urlOrPhoneNumber = `http://wa.me/${note}`;
      // window.open(urlOrPhoneNumber, "_blank");
      setWhatsAppNote({ note, transId, number });
      setOpenImageModal(true);
    } else {
      Notiflix.Notify.failure("Invalid Phone Number");
    }
  };
  function enforceLTR(str) {
    return `\u202A${str}\u202C`;
  }

  const handleCloseEditField = () => {
    setEditField({
      fieldName: "",
      value: "",
    });
    setTranId("");
    setIsUpdateFieldLoading(false);
    setIsFieldModalOpened(false);
  };

  const handleNoteChange = (event) => {
    setEditField({ ...editField, value: event.target.value });
  };

  const updateTransactionField = async () => {
    handleCloseEditField();
    setIsUpdateFieldLoading(true);
    let fieldName = "note";

    if (editField.fieldName === "Reference Number") {
      fieldName = "ref";
    } else if (editField.fieldName === "Top Up Account") {
      fieldName = "topup_account";
    }
    try {
      const { data } = await ApiRequest.post(
        `update_field?trans_id=${tranId}&new_value=${editField.value}&req_field=${fieldName}`
      );
      if (data.status === "failed") {
        throw new Error(data["reason:"]);
      }
      handleCloseEditField();
      onRefresh();
    } catch (error) {
      Notiflix.Notify.failure("Something went Wrong - " + error?.message);
    } finally {
      setIsUpdateFieldLoading(false);
    }
  };

  function renderHTable() {
    const heads = [
      translate("serial.no"),
      "",
      !isOtherSeller && {
        component: <h6>{translate("seller")}</h6>,
        props: {
          sx: { width: 150, textAlign: "center" },
        },
      },
      !isOtherSeller && translate("seller_id"),
      translate("Trans. Type"),
      !isCreditReport && translate("Mobile No."),
      translate("Card Type"),
      translate("date"),
      translate("dealer_cost"),
      translate("value"),
      translate("Status"),
    ]
      .filter((h) => h !== false)
      .map((h, i) =>
        h.component
          ? {
              ...h,
              props: Object.assign(
                {
                  style: {
                    width: 100,
                    padding: 0,
                  },
                },
                h.props
              ),
            }
          : {
              id: new Date().getTime(),
              component: <h6>{h}</h6>,
              props: {
                style: {
                  width: 100,
                  padding: 0,
                },
              },
            }
      );

    const rows = sellerReports.map((report, index) => {
      const isSuccessfulReport = report.status === "success";
      const isFailedReport = report.status === "failed";
      const StatusIcon = isSuccessfulReport
        ? CheckCircleIcon
        : isFailedReport
        ? CancelIcon
        : RotatingIcon;
      const isManualCorrection = report?.["manual correction"] === "true";

      const cells = [
        {
          component: (
            <Box>
              <Typography
                id="sales-trans-id"
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  ...(+report.newsellerbalance < 0 && { color: "red" }),
                }}
              >
                {report.transid}
              </Typography>
              <Grid item md={12} container justifyContent={"center"}>
                <CopyButton
                  buttonClassName={classes.hCopyButton}
                  textToCopy={report?.transid?.toString()}
                  iconClassName={classes.copyIcon}
                />
              </Grid>
            </Box>
          ),
          props: { style: { paddingLeft: "12px" } },
        },
        {
          props: { style: { textAlign: "center" } },
          component: (
            <Box
              sx={{
                width: "100px",
              }}
            >
              <img
                style={{
                  maxHeight: 45,
                  maxWidth: 100,
                  margin: 0,
                  display: "inline-block",
                }}
                src={report.provider_logo_url}
                alt="provider logo"
              />
            </Box>
          ),
        },
        !isOtherSeller && {
          component: (
            <Typography
              className={classes.sellerNameP}
              sx={{
                direction: isEnglish(report.name) ? "ltr" : "rtl",
                width: "150px",
                ...(+report.newsellerbalance < 0 && { color: "red" }),
              }}
              //     maxWidth={"150px"}
            >
              {report.name}
            </Typography>
          ),
          props: {
            title: report.name,
            sx: {
              padding: 0,
              minWidth: 100,
              maxWidth: 150,
              textAlign: "center",
              "& *": { display: "inline-block" },
            },
          },
        },
        !isOtherSeller && (
          <Box>
            <Typography
              id="sales-seller-id"
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                ...(+report.newsellerbalance < 0 && { color: "red" }),
              }}
            >
              {report.seller_id}
            </Typography>
            <Grid item md={12} container justifyContent={"center"}>
              <CopyButton
                buttonClassName={classes.hCopyButton}
                textToCopy={report?.seller_id?.toString()}
                iconClassName={classes.copyIcon}
              />
            </Grid>
          </Box>
        ),
        <Typography sx={{ width: "60px" }}>{report.transtype}</Typography>,
        !isCreditReport && {
          props: {
            style: {
              color: "black",
              position: "relative",
              cursor: "pointer",
            },
            onClick: () => handleTelecomRouting(report.provider, report.number),
          },
          component: (
            <Grid container sx={{ width: "70px" }}>
              <Grid item md={12} container justifyContent={"center"}>
                <p
                  id="sales-table-number"
                  style={{
                    padding: 0,
                    margin: 0,
                    ...(+report.newsellerbalance < 0 && { color: "red" }),
                  }}
                >
                  {report.number}
                </p>
              </Grid>
              <Grid item md={12} container justifyContent={"center"}>
                <CopyButton
                  buttonClassName={classes.hCopyButton}
                  textToCopy={report?.number?.toString()}
                  iconClassName={classes.copyIcon}
                />
                <CopyButton
                  buttonClassName={classes.hCopyButton}
                  textToCopy={report?.number?.toString().substring(2)}
                  iconClassName={classes.copyIcon}
                  copyType={true}
                />
              </Grid>
            </Grid>
          ),
        },
        <Typography
          sx={{ ...(+report.newsellerbalance < 0 && { color: "red" }) }}
        >
          {report.card_type}
        </Typography>,
        <Box sx={{ mr: 2 }}>
          <Typography
            sx={{ ...(+report.newsellerbalance < 0 && { color: "red" }) }}
          >
            {moment(report.datetime).format("LT")}
          </Typography>
          <Typography
            sx={{ ...(+report.newsellerbalance < 0 && { color: "red" }) }}
          >
            {moment(report.datetime).format("YYYY/MM/DD")}
          </Typography>
        </Box>,
        <Typography
          sx={{
            width: "60px",
            ...(+report.newsellerbalance < 0 && { color: "red" }),
          }}
        >
          {report.dealercost || 0}
        </Typography>,
        <Typography
          sx={{
            width: "60px",
            ...(+report.newsellerbalance < 0 && { color: "red" }),
          }}
        >
          {report.cardamount || 0}
        </Typography>,

        <>
          <StatusIcon
            color={
              isSuccessfulReport ? "success" : isFailedReport ? "error" : ""
            }
            time={report.datetime}
            sx={{
              fontSize: "2rem",
              marginLeft: "0.5rem",
            }}
          />
          {conditionalReturn(
            report?.status === "success" && parseBool(report?.cancelrequest),
            <span className={classes.canceledRequestUnderLine} />
          )}
        </>,
        <>
          {conditionalReturn(
            report.cancelrequest &&
              report.cancelrequest?.toLowerCase() !== "false",
            <CancelTransactionModal
              canceled={report.canceled?.toLowerCase() !== "false"}
              notClickable={true}
            />
          )}
        </>,
      ];

      const isNotSuccessfulTrans = report.status !== "success";
      const isSuccessfulTrans = report.status === "success";
      const isFailedTrans = report.status === "failed";

      const showCancelRequist =
        parseBool(report?.cancelrequest) && !parseBool(report?.canceled);

      const collapse = (
        <Grid
          container
          key={index}
          justifyContent="space-between"
          flexWrap={"nowrap"}
          minHeight="200px"
          paddingTop={"4px"}
          paddingLeft="12px"
          paddingRight={"8px"}
        >
          <Grid item xs={5} md={5}>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("Renewable")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.collapseItemValue}
                  fontWeight={"bolder"}
                  color="blue"
                >
                  {report.autorenew ? translate("Yes") : translate("No")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("trans type")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.collapseItemValue}>
                  {translate(report.card_type)}
                </Typography>
              </Grid>
            </Grid>
            {conditionalReturn(
              isNotSuccessfulTrans,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("Reason")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.collapseItemValue}>
                    {report.reason}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item container className={classes.collapseItemContainer}>
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  {translate("description")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.collapseItemValue}>
                  {dataLanguage(report, "ar_des", "en_des", "he_des")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes.collapseItemContainer}
              sx={{ alignItems: "center" }}
            >
              <Grid item>
                <Typography className="list-label" fontWeight={"bold"}>
                  Reference Number
                </Typography>
              </Grid>
              <PrintButton content={report?.reference_no} />
              <CopyButton
                textToCopy={report?.reference_no?.toString()}
                iconClassName={classes.copyIcon}
                buttonClassName={classes.hCopyButton}
              />
              {isAdminSeller && (
                <Box>
                  <EditIcon
                    onClick={() => {
                      handleEditField(
                        report.reference_no,
                        "Reference Number",
                        report.transid
                      );
                    }}
                    sx={{
                      margin: 0,
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    color="info"
                  />
                </Box>
              )}
              {report.provider === "Nedco" && (
                <IconButton
                  onClick={() => {
                    setReferalCode(report?.reference_no);
                    setOpenShareModal(true);
                  }}
                >
                  <ShareIcon />
                </IconButton>
              )}
              :
              <Grid item>
                <Typography className={classes.referenceNoText}>
                  {enforceLTR(report?.reference_no)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={classes.collapseItemContainer}>
              <Grid>
                <Typography fontWeight={"bold"}>
                  {translate("Provider")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.collapseItemValue}>
                  {" "}
                  {report?.provider}
                </Typography>
              </Grid>
            </Grid>
            {/* {conditionalReturn(
              isAdminSeller && report?.seller_id,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("Seller ID")}
                  </Typography>
                </Grid>
                <CopyButton
                  textToCopy={report?.seller_id?.toString()}
                  iconClassName={classes.copyIcon}
                  buttonClassName={classes.hCopyButton}
                />{" "}
                :
                <Grid item>
                  <Typography className={classes.collapseItemValue}>
                    {report?.seller_id}
                  </Typography>
                </Grid>
              </Grid>
            )} */}
            {conditionalReturn(
              !isNullOrUndefined(report?.topup_account) || isAdminSeller,
              <Grid item container className={classes.collapseItemContainer}>
                <Grid item>
                  <Typography className="list-label" fontWeight={"bold"}>
                    {translate("topUpAccount")}
                  </Typography>
                </Grid>
                <CopyButton
                  textToCopy={report?.topup_account?.toString()}
                  iconClassName={classes.copyIcon}
                  buttonClassName={classes.hCopyButton}
                />{" "}
                :
                <Grid item>
                  <Typography className={classes.collapseItemValue}>
                    {report?.topup_account}
                  </Typography>
                </Grid>
                {isAdminSeller && (
                  <Box>
                    <EditIcon
                      onClick={() => {
                        handleEditField(
                          report.topup_account,
                          "Top Up Account",
                          report.transid
                        );
                      }}
                      sx={{
                        margin: 0,
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      color="info"
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={5} md={4}>
            <Grid container>
              {conditionalReturn(
                !isNullOrUndefined(report?.cancelrequest),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Cancel Request")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.cancelrequest?.toString()}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.canceled),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Canceled")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color={
                        isSuccessfulTrans
                          ? "green"
                          : isFailedTrans
                          ? "red"
                          : undefined
                      }
                      className={classes.collapseItemValue}
                    >
                      {report?.canceled?.toString()}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.["manual correction"]),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography
                      className="list-label"
                      fontWeight={"bold"}
                      color={isManualCorrection ? "blue" : undefined}
                    >
                      {translate("Manual Correction")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color={
                        isSuccessfulTrans
                          ? "green"
                          : isFailedTrans
                          ? "red"
                          : undefined
                      }
                      className={classes.collapseItemValue}
                    >
                      {report?.["manual correction"]}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.note) || isAdminSeller,
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography
                      className="list-label"
                      fontWeight={"bold"}
                      sx={{ marginRight: "8px" }}
                    >
                      {translate("Note")}:
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: "flex" }}>
                    <Typography
                      component="span"
                      className={classes.collapseItemValue}
                    >
                      {report?.note}
                    </Typography>
                    {isAdminSeller && (
                      <Box>
                        <EditIcon
                          onClick={() => {
                            handleEditField(
                              report.note,
                              "Note",
                              report.transid
                            );
                          }}
                          sx={{
                            margin: 0,
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          color="info"
                        />
                        <WhatsAppIcon
                          onClick={() => {
                            // console.log("report.note", report);
                            handleWhatsApp(
                              report.note,
                              report.transid,
                              report.number
                            );
                          }}
                          color={+report?.note ? "success" : "error"}
                          sx={{
                            margin: 0,
                            marginLeft: "4px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.returned_message),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Returned Message")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.returned_message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.PCI),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("PCI")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.PCI}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.dealercost),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Dealer Cost")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.dealercost}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.newsellerbalance),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("New Seller Balance")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.newsellerbalance}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                !isNullOrUndefined(report?.profit),
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Profit")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.profit}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {conditionalReturn(
                isAdminSeller,
                <Grid item container className={classes.collapseItemContainer}>
                  <Grid item>
                    <Typography className="list-label" fontWeight={"bold"}>
                      {translate("Sub Provider")}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.collapseItemValue}>
                      {report?.subprovider}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            minWidth={"120px"}
            xs={report.card_type === "Bill" ? 5 : 3}
            md={report.card_type === "Bill" ? 5 : 3}
          >
            <Grid container spacing={1}>
              <Grid item md={12} maxHeight={"200px"}>
                {cardAmountHandle(report)}
              </Grid>
              {/* <Grid
                container
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
                pt={2}
                pb={2}
              > */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  paddingBottom: " 10px",
                }}
              >
                {conditionalReturn(
                  isNotSuccessfulTrans &&
                    report?.provider === "Jawwal" &&
                    report?.provider !== "Ooredoo",
                  <IconButton
                    aria-label="resend"
                    color="info"
                    onClick={() => resendFailedTransaction(report)}
                    sx={{
                      direction: "rtl",
                      margin: "0  10px ",
                      backgroundColor: "#ebebeb",
                    }}
                  >
                    <CancelScheduleSendIcon />
                  </IconButton>
                )}
                {conditionalReturn(
                  showCancellation(report),

                  <CancelTransactionModal
                    tranId={report.transid}
                    isCNumber={report?.provider === "Jawwal"}
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#bd0303",
                    }}
                  />
                )}

                {conditionalReturn(
                  isAdminSeller,
                  <Box
                    sx={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {report.card_type === "Bill" &&
                      conditionalReturn(
                        isAdminSeller,
                        <IconButton
                          aria-label="settings"
                          color="info"
                          onClick={() => {
                            setAmountSeller(report);
                            setOpenModal(true);
                            //  onSettingClick(report);
                          }}
                          disabled={report?.status === "failed"}
                          sx={{
                            direction: "rtl",
                            margin: "0  10px ",
                            backgroundColor: "#ebebeb",
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      )}
                    {report.card_type === "Bill" && (
                      <IconButton
                        aria-label="settings"
                        color="info"
                        onClick={onDownloadBillClick.bind(null, report)}
                        disabled={report?.status === "failed"}
                        sx={{
                          direction: "rtl",
                          margin: "0  10px ",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    )}
                    {report.card_type === "Bill" && (
                      <IconButton
                        aria-label="settings"
                        color="info"
                        onClick={() => {
                          setSelectedReport(report);
                          setImageModal(true);
                        }}
                        disabled={report?.status === "failed"}
                        sx={{
                          direction: "rtl",
                          margin: "0  10px ",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <UploadIcon color="success" />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="failed"
                      color="error"
                      onClick={onEditClick.bind(null, report, "failed")}
                      disabled={report?.status === "failed"}
                      sx={{
                        direction: "rtl",
                        margin: "0  10px ",
                        backgroundColor: "#ebebeb",
                      }}
                    >
                      <CancelIcon size="large" />
                    </IconButton>
                    <IconButton
                      aria-label="pending"
                      color="black"
                      onClick={onEditClick.bind(null, report, "pending")}
                      disabled={
                        report?.status === "pending" ||
                        report?.status === "proccessing"
                      }
                      sx={{
                        direction: "rtl",
                        margin: "0 10px ",
                        backgroundColor: "#ebebeb",
                      }}
                    >
                      <PendingIcon color="success" />
                    </IconButton>
                    <IconButton
                      aria-label="success"
                      color="success"
                      onClick={onEditClick.bind(null, report, "success")}
                      disabled={report?.status === "success"}
                      sx={{
                        direction: "rtl",
                        margin: "0 10px",
                        backgroundColor: "#ebebeb",
                      }}
                    >
                      <CheckCircleIcon size="large" />
                    </IconButton>

                    {/* </Box> */}
                  </Box>
                )}
              </Box>
              {conditionalReturn(
                showCancelRequist,
                <Grid item container justifyContent="center" md={12} xs={12}>
                  <HourglassEmptyIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );

      const className = isFailedReport
        ? `${classes.tableDanger}`
        : isSuccessfulReport
        ? "table-green"
        : "table-active";

      return {
        id: new Date().getTime(),
        cells: cells.filter((c) => !!c),
        props: { className },
        collapseRow: {
          component: collapse,
          cellProps: { colspan: 100 },
        },
      };
    });

    return (
      <HCard style={{ minHeight: "200px", zIndex: 0 }}>
        <HCardHeader
          color="primary"
          icon
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Typography
            color="black"
            sx={{
              display: "inline-block",
              fontSize: "20px",
            }}
          >
            {title}
          </Typography>
          <RefreshButton onClick={onRefresh} sx={{ m: 0 }} />
          <ExportButton reports={sellerReports} />
          {conditionalReturn(
            hide,
            <VisibilityOffIcon
              style={{
                margin: 0,
                color: "black",
              }}
            />
          )}
        </HCardHeader>
        <HTable
          rowOptions={{
            cellProps: {
              padding: "none",
              style: {
                padding: "4px",
              },
            },
          }}
          collapseOptions={{}}
          emptyViewText={translate("No data to show")}
          tableContainerProps={{
            component: "div",
          }}
          // pagination
          heads={heads}
          rows={rows}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Page: {page}</Typography>
            <Pagination
              count={Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={(_, page) => {
                onPageChange?.(page);
              }}
            />
          </Box>
          <TablePagination
            component="div"
            count={count}
            page={(page || 1) - 1}
            onPageChange={(_, page) => {
              onPageChange?.(page + 1);
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => onRowsPerPageChange?.(e.target.value)}
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
          />
        </Box>
      </HCard>
    );
  }

  const renderNoteModal = () => (
    <Modal
      visible={isFieldModalOpened}
      onClose={handleCloseEditField}
      title={`update ${editField.fieldName}`}
      width="400px"
      onSuccess={updateTransactionField}
      onSuccessLoading={isUpdateFieldLoading}
    >
      <div
        style={{
          height: "150px",
          marginTop: "26px",
        }}
      >
        <TextFieldGroup
          type="text"
          label={editField.fieldName}
          value={editField.value}
          onChange={handleNoteChange}
          required={true}
        />
      </div>
    </Modal>
  );

  return (
    <>
      {imageModal && (
        <Modal
          visible={ImageModal}
          onClose={() => setImageModal(false)}
          title="Upload Image"
          width="400px"
          onSuccess={sendImage}
          onSuccessLoading={isUploadImageLoading}
        >
          <UploadImage setImage={setImage} message="Upload Image" />
        </Modal>
      )}

      {openModal && (
        <SelectSeller
          allSeller={get_allsellers}
          amount={amountSeller}
          openModal={openModal}
          setOpenModal={setOpenModal}
          onSettingClick={onSettingClick}
        />
      )}
      {openImageModal && (
        <ImageModal
          openModal={openImageModal}
          setOpenModal={setOpenImageModal}
          number={WhatsAppNote.note}
          transId={WhatsAppNote.transId}
          paltelNumber={WhatsAppNote.number}
        />
      )}
      <ShareModal
        shareModal={openShareModal}
        setShareModal={setOpenShareModal}
        referalCode={referalCode}
      />
      {renderHTable()}
      {renderNoteModal()}
      {(isLoading || onLoadingSetting) && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

SaleReportTable.propTypes = propTypes;
const mapStateToProps = (state) => ({
  auth: state.auth,
  isDarkTheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { chargeJawwal, chargeOoredoo })(
  SaleReportTable
);
