import React, { useEffect, useState } from "react";
import ApiRequest from "../../../actions/ApiRequest";

import {
  Button,
  Modal,
  TextField,
  MenuItem,
  Box,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { makeStyles } from "@mui/styles";
import translate from "i18n/translate";
import UserTypesDropdown from "core/core-components/UserTypesDropdown/UserTypesDropdown";
import Select from "core/core-components/Select/Select";
import { formatSellerOptionsForSelect } from "shared/utils/formatUtils";
import { set } from "lodash";
import { AVALIABLE_LANGUAGES } from "i18n/locales";
import { intl } from "i18n/provider";
import { LoadingButton } from "@mui/lab";
import Toast from "components/common/Toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: 5,
    padding: "16px 16px",
    outline: "none",
  },
  buttonContainer: {
    marginTop: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const SendModal = ({ openModal, setOpenModal, myCredits }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const lang = AVALIABLE_LANGUAGES[intl.locale];
  const history = useHistory();
  const handleConfirm = async () => {
    setIsLoading(true);
    let res;
    try {
      if (myCredits.isJawwal) {
        res = await ApiRequest.post(
          `jawwal_topup?number=${myCredits.number}&pci=0&cardtype=credit&language=${lang}&amount=${myCredits.credit.price}&pci=${myCredits.credit.price}&url=&location=PAL`
        );
      } else {
        res = await ApiRequest.post(
          `ooredoo_topup?number=${myCredits.number}&pci=0&cardtype=credit&language=${lang}&amount=${myCredits.credit.price}&pci=${myCredits.credit.id}&url=${myCredits.credit.url}&location=PAL`
        );
      }
      console.log(res.data);
      if (res.data.status.toLowerCase() === "failed")
        throw new Error("Something went wrong");

      setIsLoading(false);
      setOpenModal(false);
      history.push("/");
      Toast.fire({
        title: "Topup Request Sent Successfully!",
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false);
      setOpenModal(false);
      Toast.fire({
        title: "Topup Request Sent failed!",
        icon: "error",
      });
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      className={classes.modal}
    >
      <Box
        className={classes.paper}
        sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "40%" } }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {translate("RUSURE")}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            setOpenModal(false);
          }}
          sx={{ ml: "5px", mr: "5px" }}
          color="error"
        >
          {translate("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleConfirm}
          sx={{ ml: "5px", mr: "5px" }}
        >
          {translate("Confirm")}
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default SendModal;
