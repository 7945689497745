import React, { useState } from 'react'

import Button from '../../common/Button'

import ApiRequest from '../../../actions/ApiRequest'
import translate from "../../../i18n/translate";

const PeletalkProducts = ({ value }) => {
    const [loading, setLoading] = useState(false)

    const updateProducts = async () => {
        setLoading(true)
        try {
            await ApiRequest.post('peletalk_update_products', {}, { timeout: 3000000 })
            setLoading(false)
        } catch (err) {

            setLoading(false)
        }
    }

    return (
        <div className='d-flex justify-content-center align-items-center'>
            {value === 0 && <h6>{translate("Update Products")}</h6>}
            {value === 1 && <Button
                title='Update'
                loading={loading}
                color="white"
                type='secondary'
                style={{ marginLeft: '30px', width: '90px' }}
                onClick={updateProducts}
            />}
        </div>
    )
}

export default PeletalkProducts