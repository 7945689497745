import translate from "../../i18n/translate";
import { connect } from 'react-redux';
import BarChart from './Graph/BarChart'
import PieChart from './Graph/DoughnutChart';

const AdminPage = ({ isDarktheme }) => {

    return (
        <div className={!isDarktheme ? "AdminMainPage" : "dark-AdminMainPage"}>
            <h1>{translate('Admin Panel')}</h1>
            <div className='AdminChart'>
                <BarChart />
                <PieChart />
            </div>
        </div>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}

export default connect(mapStateToProps, null)(AdminPage)