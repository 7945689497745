import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import "./style.css";
import {
  convertPoints,
  getRewards,
} from "../../../actions/sellerCreditsAction";
import Spinner from "../../ui/spinner/Spinner";
import { intl } from "../../../i18n/provider";
import Dropdown from "react-dropdown";
import PointsHeader from "./PointsHeader";
import SelectionCard from "../../Gaming/SelectionCard";
import { refreshColumnStyle } from "../../Gaming/reusableFunctions";
import CustomModal from "../../common/Modal";

const ConvertPoints = ({
  convertPoints,
  getRewards,
  prizeRewards,
  bankRewards,
  balanceRewards,
  points,
  sellerid,
  name,
  daysRemaining,
  isDarktheme,
}) => {
  const [loading, isLoading] = useState(false);
  const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false);
  const [isCashModal, setIsCashModal] = useState(false);
  const [columStyle, setColumnStyle] = useState();

  const [accountNumber, setAccountNumber] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [amount, setAmount] = useState(0);
  const [amountBalance, setAmountBalance] = useState(0);

  const [selectedCard, setSelectedCard] = useState(null);
  const [rewardModal, setRewardModal] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [succesfulModal, setSuccessfulModal] = useState(false);

  const banks = [
    {
      value: translate("islamicPalestineBank"),
      label: translate("islamicPalestineBank"),
    },
    {
      value: translate("islamicArabicBank"),
      label: translate("islamicArabicBank"),
    },
    { value: translate("arabBank"), label: translate("arabBank") },
    { value: translate("AllCompanies"), label: translate("AllCompanies") },
    { value: translate("egybtBank"), label: translate("egybtBank") },
    { value: translate("nationalBank"), label: translate("nationalBank") },
    { value: translate("housingBank"), label: translate("housingBank") },
    { value: translate("jerusalembank"), label: translate("jerusalembank") },
    { value: translate("ciroAmmanBank"), label: translate("ciroAmmanBank") },
    {
      value: translate("jordeninAhliBank"),
      label: translate("jordeninAhliBank"),
    },
    { value: translate("safaBank"), label: translate("safaBank") },
    { value: translate("bankofjordan"), label: translate("bankofjordan") },
    {
      value: translate("palestineInvestmentBank"),
      label: translate("palestineInvestmentBank"),
    },
    {
      value: translate("bankOfPalestine"),
      label: translate("bankOfPalestine"),
    },
  ];
  useEffect(() => {
    document.title = "Seller Credits | PhonePlay ";
    if (
      !balanceRewards?.length ||
      !bankRewards?.length ||
      !prizeRewards?.length
    ) {
      handleGetRewards();
    }
    refreshColumnStyle(setColumnStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetRewards = () => {
    isLoading(true);
    getRewards().then((res) => {
      isLoading(false);
    });
  };

  const handleConvertToCash = () => {
    isLoading(true);
    const myAmount = amount ? amount : amountBalance;
    convertPoints(selectedCard.id, bankName, accountNumber, myAmount).then(
      () => {
        setIsCashModal(false);
        setAccountNumber("");
        setAmount(0);
        setAmountBalance(0);
        setBankName("");
        isLoading(false);
        setBankModal(false);
        setSuccessfulModal(true);
      }
    );
  };

  const handleConvertToBalance = () => {
    isLoading(true);
    const myAmount = amount ? amount : amountBalance;
    convertPoints(selectedCard.id, "", "", myAmount).then(() => {
      setIsBalanceModalVisible(false);
      setAmountBalance(0);
      isLoading(false);
      setBalanceModal(false);
      setSuccessfulModal(true);
    });
  };

  const handleRewardClicked = () => {
    setRewardModal(true);
  };

  return (
    <>
      <div>
        <PointsHeader></PointsHeader>

        <div style={{ marginTop: 32 }} />
        <div className="row">
          {bankRewards?.map((reward, index) => (
            <SelectionCard
              key={index.toString()}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              reward={reward}
              columnStyle={columStyle}
              onClick={() => {
                setIsCashModal(true);
                setIsBalanceModalVisible(false);
              }}
              disabled={
                (points && parseFloat(points) <= 0) ||
                (daysRemaining && daysRemaining > 0)
              }
            />
          ))}

          {balanceRewards?.map((reward, index) => (
            <SelectionCard
              key={index.toString()}
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              reward={reward}
              columnStyle={columStyle}
              onClick={() => {
                setIsBalanceModalVisible(true);
                setIsCashModal(false);
              }}
              disabled={
                (points && parseFloat(points) <= 0) ||
                (daysRemaining && daysRemaining > 0)
              }
            />
          ))}
        </div>
        <div className="row">
          {prizeRewards?.map((reward, index) => (
            <SelectionCard
              key={index.toString()}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              reward={reward}
              columnStyle={columStyle}
              onClick={() => {
                setIsBalanceModalVisible(false);
                setIsCashModal(false);
              }}
              disabled={
                (points && parseFloat(points) < parseFloat(reward.price)) ||
                (daysRemaining && daysRemaining > 0)
              }
            />
          ))}
        </div>

        <div
          className="game-submit d-flex align-items-center justify-content-center"
          style={
            !isDarktheme
              ? { marginTop: "1.5rem", marginBottom: 200 }
              : {
                  marginTop: "1.5rem",
                  marginBottom: 200,
                  background: "#242526",
                }
          }
        >
          {isBalanceModalVisible && !isCashModal && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "75%" }}
            >
              <input
                onChange={(element) => setAmountBalance(element.target.value)}
                className="form-control convert-points-input"
                placeholder={intl.formatMessage({ id: "Enter the amount" })}
              />

              <button
                onClick={() => setBalanceModal(true)}
                className="btn btn-primary"
                disabled={!amountBalance}
              >
                {translate("submit")}
              </button>
            </div>
          )}

          {isCashModal && !isBalanceModalVisible && (
            <div style={{ width: "75%" }}>
              <div className="d-flex mb-4 justify-content-between">
                <div className="convert-points-input">
                  <Dropdown
                    options={banks}
                    onChange={(e) =>
                      setBankName(intl.formatMessage({ id: e.value.props.id }))
                    }
                    key={bankName}
                    placeholder={intl.formatMessage({ id: "Select a bank" })}
                  />
                </div>
                <input
                  onChange={(element) => setAccountNumber(element.target.value)}
                  className="form-control convert-points-input"
                  placeholder={intl.formatMessage({
                    id: "Enter account number",
                  })}
                />
              </div>
              <div className="d-flex justify-content-between">
                <input
                  onChange={(element) => setAmount(element.target.value)}
                  className="form-control convert-points-input"
                  placeholder={intl.formatMessage({ id: "Enter the amount" })}
                />
                <button
                  onClick={() => setBankModal(true)}
                  className="btn btn-primary convert-points-input"
                  style={{ width: 150 }}
                  disabled={!amount || !accountNumber || !bankName}
                >
                  {translate("submit")}
                </button>
              </div>
            </div>
          )}

          {!isCashModal && !isBalanceModalVisible && (
            <div
              className={`d-flex ${
                selectedCard ? "justify-content-between" : "justify-content-end"
              } align-items-center`}
              style={{ width: "75%" }}
            >
              {selectedCard && (
                <div
                  className="message-container"
                  style={{ width: "auto", padding: "24px 32px" }}
                >
                  <h6 style={{ marginBottom: 0 }}>
                    {selectedCard?.price} {translate("ILS")}{" "}
                    {selectedCard?.name}
                  </h6>{" "}
                </div>
              )}
              <button
                className="btn btn-primary"
                disabled={!selectedCard}
                onClick={handleRewardClicked}
                style={{ height: 35 }}
              >
                {translate("Transfer")}
              </button>
            </div>
          )}
        </div>
      </div>
      {loading && <Spinner />}

      <CustomModal
        visible={succesfulModal}
        onClose={() => setSuccessfulModal(false)}
        width={"40vw"}
      >
        <div className="inner-modal-wrapper">
          <div className="top-section">
            <h6>{translate("Transfer")}</h6>
          </div>
          <div className="message-container">
            <h4>
              {intl.formatMessage({
                id: "Operation was successfully performed",
              })}
            </h4>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            <button
              onClick={() => setSuccessfulModal(false)}
              className="btn btn-danger mx-4"
            >
              {translate("Confirm")}
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        visible={balanceModal}
        onClose={() => setBalanceModal(false)}
        width={"40vw"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="top-section">
            <h6>{translate("Transfer")}</h6>
          </div>
          <div className="message-container">
            <h4>
              {intl.formatMessage({
                id: "Are you sure you want to transfer?",
              }) +
                amountBalance +
                "?"}
            </h4>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            <button
              onClick={handleConvertToBalance}
              className="btn btn-primary mx-4"
            >
              {translate("Confirm")}
            </button>
            <button
              onClick={() => setBalanceModal(false)}
              className="btn btn-danger"
            >
              {translate("Cancel")}
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        visible={bankModal}
        onClose={() => setBankModal(false)}
        width={"40vw"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="top-section">
            <h6>{translate("Transfer")}</h6>
          </div>
          <div className="message-container">
            <h4>
              {intl.formatMessage({
                id: "Are you sure you want to transfer?",
              }) +
                amount +
                intl.formatMessage({ id: "points to" }) +
                accountNumber +
                "?"}
            </h4>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            <button
              onClick={handleConvertToCash}
              className="btn btn-primary mx-4"
            >
              {translate("Confirm")}
            </button>
            <button
              onClick={() => setBankModal(false)}
              className="btn btn-danger"
            >
              {translate("Cancel")}
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        visible={rewardModal}
        onClose={() => setRewardModal(false)}
        width={"40vw"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="top-section">
            <h6>{translate("Transfer")}</h6>
          </div>
          <div className="message-container">
            <h4>
              {intl.formatMessage({
                id: "Are you sure you want to transfer?",
              }) +
                selectedCard?.price +
                intl.formatMessage({ id: "points to" }) +
                selectedCard?.name +
                "?"}
            </h4>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            <button
              onClick={() => {
                setRewardModal(false);
                isLoading(true);
                convertPoints(selectedCard?.id).then(() => {
                  isLoading(false);
                  setSuccessfulModal(true);
                });
              }}
              className="btn btn-primary mx-4"
            >
              {translate("Confirm")}
            </button>
            <button
              onClick={() => setRewardModal(false)}
              className="btn btn-danger"
            >
              {translate("Cancel")}
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

const mapStateToProps = ({
  credits: { prizeRewards, bankRewards, balanceRewards },
  auth: { userData },
  darkTheme: { isDarktheme },
}) => ({
  prizeRewards,
  bankRewards,
  balanceRewards,
  sellerid: userData?.sellerid,
  points: userData?.points,
  name: userData?.name,
  daysRemaining: userData?.days_remaining,
  isDarktheme,
});

export default connect(mapStateToProps, { convertPoints, getRewards })(
  ConvertPoints
);
