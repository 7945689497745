import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "../common/Button";
import ApiRequest from "../../actions/ApiRequest";
import Modal from "../common/Modal";
import Notiflix from "notiflix";
import { intl } from "../../i18n/provider";
import { getContactUsMessages } from "../../actions/contactUsAction";

import "./styles.css";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import translate from "i18n/translate";
import Toast from "components/common/Toast";
import { To } from "react-flags-select";

const ContactUsForm = ({
  userData,
  getContactUsMessages,
  sort,
  isDarktheme,
}) => {
  const [contactUsData, setContactUsData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [contactUsLoading, setContactUsLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [contactUsWays, setContactUsWays] = useState("whatsapp"); // ['whatsapp', 'email'
  const [err, setErr] = useState(false);
  useEffect(() => {
    if (userData) {
      setContactUsData({
        ...contactUsData,
        name: userData.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setContactUsData({
      ...contactUsData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validate = (data) => {
    let valid = true;
    const errors = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };

    if (data.email === "" && contactUsWays === "email") {
      errors.email = intl.formatMessage({ id: "Please Enter Your Email" });
      valid = false;
    }
    if (data.subject === "") {
      errors.subject = intl.formatMessage({ id: "Please write a subject" });
      valid = false;
    }
    if (data.message === "") {
      errors.message = intl.formatMessage({ id: "Please write your message" });
      valid = false;
    }

    return {
      valid,
      errors,
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { valid, errors } = validate(contactUsData);
    if (!valid) {
      setFormErrors(errors);
      return;
    }
    setErr(false);
    setContactUsLoading(true);
    try {
      let { name, email, subject, message, mobile } = contactUsData;
      console.log("contactUsWays", contactUsData);
      email = contactUsWays === "email" ? email : "";
      const { data } = await ApiRequest.post(
        `contact_us?contact_method=${contactUsWays}&mobile_no=${userData?.mobile}&name=${name}&email=${email}&subject=${subject}&message=${message}`
      );
      setResponseData(data);
      setContactUsLoading(false);
      setSuccessModal(true);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your Message has been sent successfully",
        }),
        icon: "success",
      });
      setContactUsData({
        name: "",
        email: "",
        subject: "",
        message: "",
        mobile: "",
      });
    } catch (err) {
      setErr(true);
      setContactUsLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Something Went Wrong",
        }),
        icon: "error",
      });
      Notiflix.Report.failure("Something Went Wrong", "", "Close");
    }
  };

  return (
    <div
      className={
        !isDarktheme
          ? "d-flex flex-column align-items-center justify-content-center my-4"
          : "d-flex flex-column align-items-center justify-content-center my-4 dark-contect-us"
      }
    >
      <h2>{translate("Contact Us")}</h2>
      <div
        className="user-info d-flex justify-content-between mt-4 p-2"
        style={{ width: "85%" }}
      >
        <h6 className="text-secondary">
          {intl.formatMessage({ id: "name" })}: {userData?.name}
        </h6>
        <h6 className="text-secondary">
          {intl.formatMessage({ id: "Account ID" })} {userData?.id}
        </h6>
        <h6 className="text-secondary">
          {intl.formatMessage({ id: "Mobile Number" })}: {userData?.mobile}
        </h6>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="whatsapp"
          name="radio-buttons-group"
          sx={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
          value={contactUsWays}
          onChange={(e) => setContactUsWays(e.target.value)}
        >
          <FormControlLabel
            value="web_message"
            control={<Radio />}
            label={translate("Web Message")}
          />
          <FormControlLabel
            value="email"
            control={<Radio />}
            label={translate("Email")}
          />
          <FormControlLabel
            value="whatsapp"
            control={<Radio />}
            label={translate("Whatsapp")}
          />
        </RadioGroup>
      </FormControl>
      <form className="contact-us mt-4" onSubmit={onSubmit}>
        {contactUsWays === "email" && (
          <TextField
            variant="standard"
            label={intl.formatMessage({
              id: "Enter your email",
            })}
            name="email"
            type="email"
            onChange={onChange}
            value={contactUsData["email"]}
            error={formErrors["email"]}
            helperText={formErrors["email"]}
          />
        )}

        <TextField
          variant="standard"
          label={intl.formatMessage({ id: "Subject" })}
          name="subject"
          onChange={onChange}
          value={contactUsData.subject}
          error={formErrors.subject}
          helperText={formErrors.subject}
          inputProps={{ maxLength: 99 }}
        />
        <TextField
          variant="standard"
          label={intl.formatMessage({ id: "Enter Your Message" })}
          name="message"
          onChange={onChange}
          value={contactUsData.message}
          error={formErrors.message}
          helperText={formErrors.message}
          multiline
          minRows={3}
          maxRows={7}
          inputProps={{ maxLength: 499 }}
        />
        <Button
          type="primary"
          bgColor="#242526"
          style={{ width: "100%" }}
          className="mt-4"
          title="submit"
          formType="submit"
          loading={contactUsLoading}
        />
        {err && (
          <p className="text-danger mt-2">
            {intl.formatMessage({ id: "Some Thing Went Wrong !" })}
          </p>
        )}
      </form>
      {/* <Modal
        title="Information Submited"
        visible={successModal}
        onClose={() => setSuccessModal(false)}
        width={"40vw"}
        onConfirm="success"
      >
        <div className="d-flex flex-column align-items-center justify-content-center mt-4">
          <h4>
            {intl.formatMessage({ id: "Ticket No." })}:{" "}
            <span style={{ fontWeight: "bolder" }}>
              {responseData?.ticket_no}
            </span>
          </h4>
          <h4 className="mt-4">
            {intl.formatMessage({ id: "Submited Information" })}
          </h4>
        </div>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = ({
  auth: { userData },
  darkTheme: { isDarktheme },
}) => ({
  userData,
  isDarktheme,
});

export default connect(mapStateToProps, { getContactUsMessages })(
  ContactUsForm
);
