import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import ApiRequest from "../../../actions/ApiRequest";
import { getTopUpAccounts } from "actions/sellerCreditsAction";
import { Api } from "@mui/icons-material";
import Toast from "components/common/Toast";
import { set } from "lodash";
import GetSettings from "./IsrealCompanies/IsraelSettings";
const JawwalSettings = () => {
  return <GetSettings carrier="jawwal_invoice" />;
};
export default JawwalSettings;
