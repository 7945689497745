import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { AmountCardOrImg } from "../../../core/core-components/AmountCard/AmountCard.jsx";
// import { makeStyles } from "@mui/styles";
import style from "./mobileNumberInfo.module.css";
import { SYSTEM_COLORS } from "../../../shared/constants/stylesConstants.js";
import { intl } from "../../../i18n/provider.js";
import { conditionalReturn } from "../../../shared/utils/utils.js";
import { isUnknownOrNaStr } from "shared/utils/ppUtils.js";

const propTypes = {
  onAmountClick: PropTypes.func,
  onProviderClick: PropTypes.func,
  mobileNumberInfo: PropTypes.object,
  isError: PropTypes.bool,
};

const defaultProps = {
  mobileNumberInfo: {},
};

// const useStyle = makeStyles(() => ({}));

export default function MobileNumberInfoBox(props) {
  const { isError, onAmountClick, onProviderClick, mobileNumberInfo } = props;
  const {
    provider: providerName,
    provider_logo: providerLogoUrl,
    pci,
    pci_url: pciUrl,
    amount,
  } = mobileNumberInfo;

  const showSecond = !window.HMap.from(mobileNumberInfo)
    .filter((_, x) => !x.includes("pci_url") && x.includes("2"))
    .any((x) => isUnknownOrNaStr(x));

  return (
    <div
      style={{
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        className={
          isError ? style.Company_Container_white_Red : style.Company_Container
        }
        style={
          isError
            ? { borderColor: SYSTEM_COLORS.red, height: 200 }
            : { height: 200 }
        }
      >
        <Grid
          className="img__wrap"
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item paddingRight={"8px"}>
            <Grid item md={12} xs={12}>
              <img
                style={{ cursor: "pointer" }}
                title={providerName}
                src={providerLogoUrl}
                alt={providerName}
                onClick={onProviderClick}
                className={style.Company_Logo}
              />
            </Grid>
            <Grid container item md={12} xs={12} justifyContent="center">
              <p className={style.Company_Provider}>{providerName}</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            width={"150px"}
            height="150px"
            spacing={1}
            alignItems="center"
          >
            <Grid item md={12} xs={12}>
              <span
                style={{
                  width: 150,
                  height: 70,
                  maxHeight: 70,
                  display: "inline-block",
                }}
              >
                <AmountCardOrImg
                  onClick={() => onAmountClick(false)}
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  title={pci}
                  src={pciUrl}
                  amount={amount}
                />
              </span>
            </Grid>
            {conditionalReturn(
              showSecond,
              <Grid item md={12} xs={12}>
                <span
                  style={{
                    width: 150,
                    height: 70,
                    maxHeight: 70,
                    display: "inline-block",
                  }}
                >
                  <AmountCardOrImg
                    onClick={() => onAmountClick(true)}
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    title={mobileNumberInfo?.pci2}
                    src={mobileNumberInfo?.pci_url2}
                    amount={mobileNumberInfo?.amount2}
                  />
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        {conditionalReturn(
          isError,
          <div className={style.attention_pay_div_container}>
            <p className={style.Company_Provider_Error}>
              {intl.formatMessage({ id: "Attention_Pay" })}
            </p>
          </div>
        )}
      </Grid>
    </div>
  );
}

MobileNumberInfoBox.propTypes = propTypes;
MobileNumberInfoBox.defaultProps = defaultProps;
