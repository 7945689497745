import sha256 from "crypto-js/sha256";
import {
  GET_JAWWAL_3G,
  GET_JAWWAL_CREDIT,
  GET_JAWWAL_MIN,
  CLEAR_ERRORS,
  GET_ERRORS,
  GET_JAWWAL_CHARGE,
  GET_NOT_RENEW_JAWWAL_MIN,
  GET_RENEW_JAWWAL_MIN,
  GET_RENEW_JAWWAL_3G,
  GET_JAWWAL_ROM,
  GET_NOT_RENEW_JAWWAL_3G,
  GET_OOREDOO_3G,
  GET_OOREDOO_MIN,
  GET_OOREDOO_ROM,
  GET_OOREDOO_SUPER,
  LOADING_TRUE,
  GET_RENEW_OOREDOO_SUPER,
  GET_OOREDOO_RENEW_ROM,
  GET_NOR_RENEW_OOREDOO_SUPER,
  GET_OOREDOO_NOT_RENEW_ROM,
  GET_OOREDOO_NOT_RENEW_3G,
  GET_OOREDOO_RENEW_3G,
  GET_OOREDOO_RENEW_MIN,
  GET_OOREDOO_NOT_RENEW_MIN,
  GET_GROUP_COMPANIES,
  GET_AZY,
  GET_HOT,
  GET_ALL_COMPANIES,
  ADD_COMPANIES_LOADING,
  COMPANY_SETTINGS_OBJECT,
  IS_PLUS,
  GET_JAWWAL_PLUS,
  JAWWAL_BUNDLE_LOADING,
  SET_NUMBER_CHANGED,
} from "./types";

import { logoutUser } from "./userAction";
import ApiRequest from "./ApiRequest";
import Toast from "../components/common/Toast";
import { isEmpty, isNil } from "lodash";
import { intl } from "../i18n/provider";
import { AVALIABLE_LANGUAGES } from "../i18n/locales";
import { userData } from "./userAction";
import Notiflix from "notiflix";
import { isCurrentLanguageArabic } from "shared/utils/languageUtils";
import { jsonParser } from "shared/utils/ppUtils";
const lang = AVALIABLE_LANGUAGES[intl.locale];

export const getAllCompanies = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: LOADING_TRUE,
  });
  return ApiRequest.post(`seller_discount?type=telecom&topup_type=topup`) // topup_type params (card,topup,manually)
    .then((res) => {
      dispatch({
        type: GET_ALL_COMPANIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
      // dispatch(handleError("Something Went Wrong !"))
    });
};

export const getCompanySettings = (companyName, source) => (dispatch) => {
  dispatch(clearErrors());
  return ApiRequest.post(
    `settings_data?company=${companyName.toLowerCase()}&operation=get`,
    {},
    {
      cancelToken: source.token,
    }
  ) // topup_type params (card,topup,manually)
    .then((res) => {
      if (res.data?.reason === "token exipred!") {
        dispatch(logoutUser());
      }
      dispatch({
        type: COMPANY_SETTINGS_OBJECT,
        payload: {
          company: companyName,
          settings: res.data,
        },
      });
      return res.data;
    })
    .catch(function (thrown) {
      if (ApiRequest.isCancel(thrown)) {
      } else {
        Toast.fire({
          title: "Something Went Wrong!",
          icon: "error",
        });
      }
    });
};
export const editCompaanySettingsValue = (company) => (dispatch) => {
  dispatch(clearErrors());
  return ApiRequest.post(
    `settings_data?company=${company.name.toLowerCase()}&settings_name=${
      company.settings_name
    }&value=${company.settings_value}&des=${company.des}&operation=update`
  ) // topup_type params (card,topup,manually)
    .then((res) => {
      Toast.fire({
        title: "All is good",
        icon: "success",
      });
      return true;
    })
    .catch((err) => {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
      return false;
      // dispatch(handleError("Something Went Wrong !"))
    });
};
export const getJawwal3g = (mobileNo, refresh) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_JAWWAL_3G,
    payload: [],
  });
  dispatch(JawwalBundleLoading(true, "Jawwal3gLoading"));

  const storageHash = sha256(`N${mobileNo}T3g`).toString();
  const jawwal3g = jsonParser(sessionStorage.getItem(storageHash));
  if (false) {
    dispatch({
      type: GET_JAWWAL_3G,
      payload: jawwal3g,
    });
    if (jawwal3g[jawwal3g.length - 1]?.Plus) {
      dispatch({
        type: IS_PLUS,
        payload: true,
      });
      return;
    }
    dispatch({
      type: IS_PLUS,
      payload: false,
    });
  } else {
    ApiRequest.post(
      `/JAB?number=${mobileNo
        .split("-")
        .join("")}&bundle=3g&language=${lang}&refresh=${refresh}`
    )
      .then((res) => {
        dispatch({
          type: GET_JAWWAL_3G,
          payload: res.data,
        });
        sessionStorage.setItem(storageHash, JSON.stringify(res.data));
        if (res?.data[res?.data?.length - 1]?.Plus) {
          dispatch({
            type: IS_PLUS,
            payload: true,
          });

          return;
        }
        dispatch({
          type: IS_PLUS,
          payload: false,
        });
      })
      .catch((err) => console.log())
      .finally(() => dispatch(JawwalBundleLoading(false, "Jawwal3gLoading")));
  }
};

export const getJawwalRom = (mobileNo, refresh) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_JAWWAL_ROM,
    payload: [],
  });
  dispatch(JawwalBundleLoading(true, "JawwalRomLoading"));

  const storageHash = sha256(`N${mobileNo}TRom`).toString();
  const jawwalRom = jsonParser(sessionStorage.getItem(storageHash));
  if (false) {
    dispatch({
      type: GET_JAWWAL_ROM,
      payload: jawwalRom,
    });
  } else {
    ApiRequest.post(
      `JAB?number=${mobileNo
        .split("-")
        .join("")}&bundle=rom&language=${lang}&refresh=${refresh}`
    )
      .then((res) => {
        dispatch({
          type: GET_JAWWAL_ROM,
          payload: res.data,
        });
        sessionStorage.setItem(storageHash, JSON.stringify(res.data));
        dispatch(JawwalBundleLoading(false, "JawwalRomLoading"));
      })
      .catch((err) => dispatch(JawwalBundleLoading(false, "JawwalRomLoading")));
  }
};

export const getJawwalPlus = (mobileNo, refresh) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_JAWWAL_PLUS,
    payload: [],
  });
  dispatch(JawwalBundleLoading(true, "JawwalPlusLoading"));

  const storageHash = sha256(`N${mobileNo}Plus`).toString();
  const JawwalPlus = jsonParser(sessionStorage.getItem(storageHash));
  if (false) {
    dispatch({
      type: GET_JAWWAL_PLUS,
      payload: JawwalPlus,
    });
  } else {
    ApiRequest.post(`get_plus_bundles`)
      .then((res) => {
        dispatch({
          type: GET_JAWWAL_PLUS,
          payload: res.data,
        });
        sessionStorage.setItem(storageHash, JSON.stringify(res.data));
        dispatch(JawwalBundleLoading(false, "JawwalPlusLoading"));
      })
      .catch((err) =>
        dispatch(JawwalBundleLoading(false, "JawwalPlusLoading"))
      );
  }
};

export const getJawwalCredit =
  (mobileNo, refresh = false) =>
  (dispatch) => {
    dispatch(clearErrors());

    const storageHash = "jawwalCredit";
    const jawwalCredit = jsonParser(localStorage.getItem(storageHash));
    const expirationDate = new Date(
      localStorage.getItem(`${storageHash}_expiration`)
    );

    if (!refresh && jawwalCredit && expirationDate > new Date()) {
      dispatch({
        type: GET_JAWWAL_CREDIT,
        payload: jawwalCredit,
      });
    } else {
      ApiRequest.post(
        `JAB?number=${mobileNo
          .split("-")
          .join("")}&bundle=jawwalMin&language=${lang}`
      )
        .then((res) => {
          dispatch({
            type: GET_JAWWAL_CREDIT,
            payload: res.data,
          });

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);

          localStorage.setItem(storageHash, JSON.stringify(res.data));
          localStorage.setItem(
            `${storageHash}_expiration`,
            expirationDate.toISOString()
          );
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          })
        );
    }
  };

export const getJawwalMin = (mobileNo, refresh) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_JAWWAL_MIN,
    payload: [],
  });
  dispatch(JawwalBundleLoading(true, "JawwalMinLoading"));

  const storageHash = sha256(`N${mobileNo}TMin`).toString();
  const jawwalMin = jsonParser(sessionStorage.getItem(storageHash));
  if (false) {
    dispatch({
      type: GET_JAWWAL_MIN,
      payload: jawwalMin,
    });
    if (jawwalMin[jawwalMin.length - 1]?.Plus) {
      dispatch({
        type: IS_PLUS,
        payload: true,
      });
      return;
    }
    dispatch({
      type: IS_PLUS,
      payload: false,
    });
  } else {
    ApiRequest.post(
      `JAB?number=${mobileNo
        .split("-")
        .join("")}&bundle=min&language=${lang}&refresh=${refresh}`
    )
      .then((res) => {
        dispatch({
          type: GET_JAWWAL_MIN,
          payload: res.data,
        });
        sessionStorage.setItem(storageHash, JSON.stringify(res.data));
        if (res?.data[res?.data?.length - 1]?.Plus) {
          dispatch({
            type: IS_PLUS,
            payload: true,
          });
          return;
        }
        dispatch({
          type: IS_PLUS,
          payload: false,
        });
      })
      .catch((err) => console.log())
      .finally(() => dispatch(JawwalBundleLoading(false, "JawwalMinLoading")));
  }
};

export const getRnewJawwalMin = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_RENEW_JAWWAL_MIN,
  });
};
export const getNotRnewJawwalMin = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_NOT_RENEW_JAWWAL_MIN,
  });
};

export const getRnewJawwal3g = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_RENEW_JAWWAL_3G,
  });
};
export const getNotRnewJawwal3g = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_NOT_RENEW_JAWWAL_3G,
  });
};

export const checkTransStatusAction = (transId = "") => {
  return ApiRequest.post(`check_trans_status?trans_no=${transId}`);
};

export const handleTransStatus = (transId = "") => {
  let callTime = 1;
  let sec = 10000;

  const call = async () => {
    const { data } = await checkTransStatusAction(transId);
    const status = data?.status;

    if (status === "success") {
      Notiflix.Notify.success(
        intl.formatMessage({
          id: "Operation add credit was successfully performed",
        })
      );
    } else if (status === "proccessing") {
      setTimeout(() => {
        if (callTime !== 3) {
          callTime += 1;
          sec -= 5000;
          call();
        }
      }, sec);
    } else {
      Notiflix.Notify.failure(
        intl.formatMessage({ id: "Operation add credit failed" })
      );
    }
  };
  call();
};

export const chargeJawwal =
  (data, history, pushHistory, isNormal, phoneNumber = null, repost = false) =>
  async (dispatch) => {
    dispatch(clearErrors());
    const number = phoneNumber || history.split("/")[3];
    const promises = [];
    console.log(data, "data", number, "number");

    if (!isNil(data.jawwalCredit)) {
      if (number.length === 4) {
        Toast.fire({
          title: intl.formatMessage({ id: "Please enter a valid number" }),
          icon: "warning",
        });
        return new Promise((resolve, reject) => {
          reject();
        });
      }
      if (data.jawwalCredit.price < 10) {
        Toast.fire({
          title: intl.formatMessage({ id: "The minimum credit is 10 NIS" }),
          icon: "warning",
        });
        return new Promise((resolve, reject) => {
          reject();
        });
      }

      const promise = ApiRequest.post(
        `jawwal_topup?number=${number}&pci=0&cardtype=credit&language=${lang}&amount=${data.jawwalCredit.price}&pci=${data.jawwalCredit.id}&url=&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.jawwal3g)) {
      const promise = ApiRequest.post(
        `jawwal_3g?number=${number}&cardtype=3g&language=${lang}&amount=0&pci=${data.jawwal3g.id}&url=${data.jawwal3g.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.jawwalRom)) {
      const promise = ApiRequest.post(
        `jawwal_3g?number=${number}&cardtype=rom&language=${lang}&amount=0&pci=${data.jawwalRom.id}&url=${data.jawwalRom.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.jawwalMin)) {
      const promise = ApiRequest.post(
        `jawwal_3g?number=${number}&cardtype=min&language=${lang}&amount=0&pci=${data.jawwalMin.id}&url=${data.jawwalMin.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    // Toast.fire({
    //   title: intl.formatMessage({ id: "Your request is in progress" }),
    //   icon: "info",
    // });

    return Promise.all(promises)
      .then((res) => {
        const isAuthFailed = res.some(
          (result) =>
            result.data === "failed, token error" ||
            result.data.reason === "token expired" ||
            result.data.reason === "expired token"
        );
        if (isAuthFailed) {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "You must log in again!" })
          );

          return pushHistory && logoutUser(pushHistory);
        }

        const noBalance = res.some(
          (result) => result.data.reason === "seller no balance"
        );
        if (noBalance) {
          return "noBalance";
        }

        const isFailed = res.some((result) => result.data.status === "failed");
        if (isFailed) {
          const erros = [];
          res.forEach((result) => {
            if (result.data.status === "failed") {
              erros.push(result.data.reason);
            }
          });

          if (!isEmpty(erros)) {
            if (isNormal)
              Toast.fire({
                title: intl.formatMessage({ id: "failed" }),
                icon: "error",
              });
            else throw new Error("API call failed");
          }

          return;
        }

        isNormal &&
          Toast.fire({
            title: intl.formatMessage({
              id: "success_op",
            }),
            icon: "success",
          });

        setTimeout(() => dispatch(userData(history)), 1000);
        clearJawwalSelected();
      })
      .catch((err) => {
        !err && Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
        throw new Error("API call failed");
      });
  };

const clearJawwalSelected = () => {
  sessionStorage.removeItem("JawwalMin");
  sessionStorage.removeItem("Jawwal3g");
  sessionStorage.removeItem("JawwalCredit");
  sessionStorage.removeItem("JawwalRom");
};

export const addChargeJawwal = (data) => (dispatch) => {
  dispatch(clearErrors());
  const chargeJal = jsonParser(sessionStorage.chargeJawwal);
  chargeJal.push(data);
  sessionStorage.setItem("chargeJawwal", JSON.stringify(chargeJal));
};

export const getChargeJawwal = () => (dispatch) => {
  dispatch(clearErrors());
  const charge = jsonParser(sessionStorage.chargeJawwal);
  dispatch({
    type: GET_JAWWAL_CHARGE,
    payload: charge,
  });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

//OOREDOO
export const getOoredooMin =
  (refresh = false) =>
  (dispatch) => {
    dispatch(clearErrors());
    dispatch({
      type: GET_OOREDOO_MIN,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });

    const storageHash = sha256(`NallTooredooMin`).toString();
    const ooredooMin = jsonParser(localStorage.getItem(storageHash));
    const expirationDate = new Date(
      localStorage.getItem(`${storageHash}_expiration`)
    );

    if (!refresh && ooredooMin && expirationDate > new Date()) {
      dispatch({
        type: GET_OOREDOO_MIN,
        payload: ooredooMin,
      });
    } else {
      ApiRequest.post(`ooredoo_get_bundles?bundle=MIN&language=${lang}`)
        .then((res) => {
          dispatch({
            type: GET_OOREDOO_MIN,
            payload: res.data,
          });

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);

          localStorage.setItem(storageHash, JSON.stringify(res.data));
          localStorage.setItem(
            `${storageHash}_expiration`,
            expirationDate.toISOString()
          );
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err,
          })
        );
    }
  };

export const getOoredooMinRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_RENEW_MIN,
  });
};
export const getOoredooMinNotRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_NOT_RENEW_MIN,
  });
};

export const getOoredoo3g =
  (refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_OOREDOO_3G,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    const storageHash = sha256(`NallTooredoo3G`).toString();
    const ooredoo3g = jsonParser(localStorage.getItem(storageHash));
    const expirationDate = new Date(
      localStorage.getItem(`${storageHash}_expiration`)
    );

    if (!refresh && ooredoo3g && expirationDate > new Date()) {
      dispatch({
        type: GET_OOREDOO_3G,
        payload: ooredoo3g,
      });
    } else {
      ApiRequest.post(`ooredoo_get_bundles?bundle=3G&language=${lang}`)
        .then((res) => {
          dispatch({
            type: GET_OOREDOO_3G,
            payload: res.data,
          });

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);

          localStorage.setItem(storageHash, JSON.stringify(res.data));
          localStorage.setItem(
            `${storageHash}_expiration`,
            expirationDate.toISOString()
          );
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err,
          })
        );
    }
  };

export const getOoredoo3gRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_RENEW_3G,
  });
};
export const getOoredoo3gNotRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_NOT_RENEW_3G,
  });
};

export const getOoredooRom =
  (refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_OOREDOO_ROM,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    const storageHash = sha256(`NallTooredooRom`).toString();
    const ooredooRom = jsonParser(localStorage.getItem(storageHash));
    const expirationDate = jsonParser(
      localStorage.getItem(`${storageHash}_expiration`)
    );

    if (
      !refresh &&
      ooredooRom &&
      expirationDate &&
      new Date() < expirationDate
    ) {
      dispatch({
        type: GET_OOREDOO_ROM,
        payload: ooredooRom,
      });
    } else {
      ApiRequest.post(`ooredoo_get_bundles?bundle=ROM&language=${lang}`)
        .then((res) => {
          dispatch({
            type: GET_OOREDOO_ROM,
            payload: res.data,
          });
          const newExpirationDate = new Date();
          newExpirationDate.setMonth(newExpirationDate.getMonth() + 1);
          localStorage.setItem(storageHash, JSON.stringify(res.data));
          localStorage.setItem(
            `${storageHash}_expiration`,
            newExpirationDate.toISOString()
          );
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err,
          })
        );
    }
  };

export const getOoredooRomRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_RENEW_ROM,
  });
};
export const getOoredooRomNotRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_OOREDOO_NOT_RENEW_ROM,
  });
};
export const setNumberChanged = (value) => ({
  type: SET_NUMBER_CHANGED,
  payload: value,
});

export const getOoredooSuper =
  (refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_OOREDOO_SUPER,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    const storageHash = sha256(`NallTooredooSuper`).toString();
    const ooredooSuper = jsonParser(localStorage.getItem(storageHash));
    const expirationDate = jsonParser(
      localStorage.getItem(`${storageHash}_expiration`)
    );

    if (
      !refresh &&
      ooredooSuper &&
      expirationDate &&
      new Date() < expirationDate
    ) {
      dispatch({
        type: GET_OOREDOO_SUPER,
        payload: ooredooSuper,
      });
    } else {
      ApiRequest.post(`ooredoo_get_bundles?bundle=YOUTH&language=${lang}`)
        .then((res) => {
          dispatch({
            type: GET_OOREDOO_SUPER,
            payload: res.data,
          });
          const newExpirationDate = new Date();
          newExpirationDate.setMonth(newExpirationDate.getMonth() + 1);
          localStorage.setItem(storageHash, JSON.stringify(res.data));
          localStorage.setItem(
            `${storageHash}_expiration`,
            newExpirationDate.toISOString()
          );
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload: err,
          });
          Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
        });
    }
  };

export const getOoredooSuperRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_RENEW_OOREDOO_SUPER,
  });
};
export const getOoredooSuperNotRenew = () => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_NOR_RENEW_OOREDOO_SUPER,
  });
};

export const chargeOoredoo =
  (data, history, pushHistory, isNormal, phoneNumber = null, repost = false) =>
  (dispatch) => {
    console.log(data, "data", phoneNumber, "phoneNumber");
    dispatch(clearErrors());
    const number = phoneNumber || history.split("/")[4];
    const promises = [];

    if (number.length === 4) {
      Toast.fire({
        title: intl.formatMessage({ id: "Please enter a valid number" }),
        icon: "warning",
      });
      return new Promise((resolve, reject) => {
        reject();
      });
    }

    // Toast.fire({
    //   title: intl.formatMessage({ id: "Your request is in progress" }),
    //   icon: "info",
    // });

    if (
      data.ooredooCredit &&
      ((Number(data.ooredooCredit.price) < 15 &&
        Number(data.ooredooCredit.price) > 10) ||
        (Number(data.ooredooCredit.price) < 20 &&
          Number(data.ooredooCredit.price) > 15))
    ) {
      Notiflix.Notify.warning(intl.formatMessage({ id: "Amount Not Allowed" }));

      return new Promise((resolve, reject) => {
        reject();
      });
    }

    if (!isNil(data.ooredooCredit)) {
      if (number.length === 4) {
        Toast.fire({
          title: intl.formatMessage({ id: "Please enter a valid number" }),
          icon: "warning",
        });
        return new Promise((resolve, reject) => {
          reject();
        });
      }
      if (data.ooredooCredit.price < 10) {
        Notiflix.Notify.warning(
          intl.formatMessage({ id: "The minimum credit is 10 NIS" })
        );

        return new Promise((resolve, reject) => {
          reject();
        });
      }
      const promise = ApiRequest.post(
        `ooredoo_topup?number=${number}&pci=0&cardtype=credit&language=${lang}&amount=${data.ooredooCredit.price}&pci=${data.ooredooCredit.id}&url=${data.ooredooCredit.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.ooredoo3g)) {
      const promise = ApiRequest.post(
        `ooredoo_3g?number=${number}&cardtype=3g&language=${lang}&amount=0&pci=${data.ooredoo3g.bundleid}&url=${data.ooredoo3g.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.ooredooRom)) {
      const promise = ApiRequest.post(
        `ooredoo_3g?number=${number}&cardtype=rom&language=${lang}&amount=0&pci=${data.ooredooRom.bundleid}&url=${data.ooredooRom.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.ooredooMin)) {
      const promise = ApiRequest.post(
        `ooredoo_3g?number=${number}&cardtype=min&language=${lang}&amount=0&pci=${data.ooredooMin.bundleid}&url=${data.ooredooMin.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    if (!isNil(data.ooredooSuper)) {
      const promise = ApiRequest.post(
        `ooredoo_3g?number=${number}&cardtype=super&language=${lang}&amount=0&pci=${data.ooredooSuper.bundleid}&url=${data.ooredooSuper.url}&location=PAL&repost=${repost}`
      );
      promises.push(promise);
    }

    return Promise.all(promises)
      .then((res) => {
        const isAuthFailed = res.some(
          (result) =>
            result.data === "failed, token error" ||
            result.data.reason === "token expired"
        );

        if (isAuthFailed) {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "You must log in again!" })
          );

          return logoutUser(pushHistory);
        }

        dispatch(userData(history));

        const noBalance = res.some(
          (result) => result.data.reason === "seller no balance"
        );
        if (noBalance) {
          return "noBalance";
        }

        const isFailed = res.some((result) => result.data.status === "failed");
        if (isFailed) {
          const erros = [];
          res.forEach((result) => {
            if (result.data.status === "failed") {
              erros.push(result.data.reason);
            }
          });
          if (!isEmpty(erros)) {
            if (isNormal)
              Toast.fire({
                title: intl.formatMessage({ id: "failed" }),
                icon: "error",
              });
            else throw new Error("API call failed");
          }
          return;
        }

        isNormal &&
          Toast.fire({
            title: intl.formatMessage({
              id: "success_op",
            }),
            icon: "success",
          });

        clearOoredooSelected();
      })
      .catch((err) => {
        !err && Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
        throw new Error("API call failed");
      });
  };

const clearOoredooSelected = () => {
  sessionStorage.removeItem("ooredooMin");
  sessionStorage.removeItem("ooredoo3g");
  sessionStorage.removeItem("ooredooCredit");
  sessionStorage.removeItem("ooredooRom");
  sessionStorage.removeItem("ooredooSuper");
};

// Group Companies
export const getGroupesData =
  (provider_id, refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_GROUP_COMPANIES,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    const storageHash =
      sha256(`GroupesData${provider_id}`).toString() + "_GroupesData";
    // const GroupsSort = jsonParser(window.localStorage.getItem(storageHash));
    // const storedDate = window.localStorage.getItem(`${storageHash}_expiration`);
    // if (GroupsSort && storedDate && !refresh) {
    //   const currentDate = new Date().getTime();
    //   const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    //   const storedDateMilliseconds = parseInt(storedDate);

    //   // Check if the stored data is still valid (less than 1 month old)
    //   if (currentDate - storedDateMilliseconds < oneMonthInMilliseconds) {
    //     dispatch(setGroupCompanies(GroupsSort));
    //     return;
    //   }
    // }

    return ApiRequest.post(`peletalk_get_products?provider_id=${provider_id}`)
      .then((res) => {
        const responseData = res.data;
        // window.localStorage.setItem(storageHash, JSON.stringify(responseData));
        // window.localStorage.setItem(
        //   `${storageHash}_expiration`,
        //   new Date().getTime().toString()
        // );
        dispatch(setGroupCompanies(res.data));
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
      });
  };

export const setGroupCompanies = (data) => ({
  type: GET_GROUP_COMPANIES,
  payload: data,
});

export const setHot = (data) => ({
  type: GET_HOT,
  payload: data,
});
export const setAzy = (data) => ({
  type: GET_AZY,
  payload: data,
});

export const chargeGrpupCompany =
  (type, mobile, data, history) => (dispatch) => {
    dispatch(clearErrors());
    if (mobile.length === 4) {
      Toast.fire({
        title: intl.formatMessage({ id: "Please enter a valid number" }),
        icon: "warning",
      });
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    return ApiRequest.post(
      `peletalk_topup?number=${mobile}&pci=${data.PID}&language=${lang}&amount=${data.amount}&url=${data.url}&location=ISR&provider_id=${data.provider_id}`
    )
      .then((res) => {
        if (
          res.data.reason === "seller no balance" ||
          res.data.reason === "seller disabled/no balance"
        ) {
          return "noBalance";
        }
        if (
          res.data === "failed, token error" ||
          res.data.reason === "token expired"
        ) {
          Toast.fire({
            title: intl.formatMessage({ id: "You must log in again!" }),
            icon: "error",
          });
          return logoutUser(history);
        }

        dispatch(userData(history));

        if (res.data.reason === "seller no balance") {
          Toast.fire({
            title: intl.formatMessage({ id: "No balance available" }),
            icon: "warning",
          });
          return "noBalance";
        }

        if (res.data.status === "failed") {
          Toast.fire({
            title: res.data.reason,
            icon: "warning",
          });
          return;
        }

        Toast.fire({
          title: intl.formatMessage({ id: "Your request is in progress" }),
          icon: "success",
        });
      })
      .catch((err) => {
        Notiflix.Report.failure(`SomeThing Went Wrong !`, "", "Close");

        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

//Hot && azy
export const getAzy =
  (mobile, refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_AZY,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    // const azyData = window.localStorage.getItem("azy");
    // const storedDate = window.localStorage.getItem("azyStoredDate");

    // // Check if the stored data exists and if it is still valid (less than 1 month old)
    // if (azyData && storedDate && !refresh) {
    //   const currentDate = new Date().getTime();
    //   const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    //   const storedDateMilliseconds = parseInt(storedDate);

    //   // Check if the stored data is still valid (less than 1 month old)
    //   if (currentDate - storedDateMilliseconds < oneMonthInMilliseconds) {
    //     dispatch({
    //       type: GET_AZY,
    //       payload: JSON.parse(azyData),
    //     });
    //     return;
    //   }
    // }

    // If the stored data is not valid or doesn't exist, send a new request
    ApiRequest.post(`azy_get_products?`)
      .then((res) => {
        const responseData = res.data;
        // window.localStorage.setItem("azy", JSON.stringify(responseData));
        // window.localStorage.setItem(
        //   "azyStoredDate",
        //   new Date().getTime().toString()
        // );
        dispatch({
          type: GET_AZY,
          payload: responseData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
      });
  };
export const ChargeAzy = (mobileNo, pci, history) => (dispatch) => {
  dispatch(clearErrors());
  if (mobileNo.length === 4) {
    Toast.fire({
      title: intl.formatMessage({ id: "Please enter a valid number" }),
      icon: "warning",
    });
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  Toast.fire({
    title: intl.formatMessage({ id: "Your request is in progress" }),
    icon: "info",
  });

  return ApiRequest.post(
    `mobile019?number=${mobileNo.split("-").join("")}&pci=${pci}&location=ISR`
  )
    .then((res) => {
      dispatch(userData(history));
      if (res.data.reason === "seller no balance") {
        return "noBalance";
      }
      if (res.data.status === "failed") {
        // Notiflix.Report.warning(`Top Up Failed`, ``, 'Close')

        Notiflix.Notify.warning(res.data.reason);
      } else {
        Notiflix.Notify.success(
          isCurrentLanguageArabic() ? "طلبك قيد التنفيذ" : "Top up is Pending"
        );
      }
      // Notiflix.Report.success(`Balance Added !`, '', 'Close')
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
    });
};

export const getHot =
  (mobile, history, refresh = false) =>
  (dispatch) => {
    dispatch({
      type: GET_HOT,
      payload: [],
    });
    dispatch({
      type: LOADING_TRUE,
    });
    dispatch(clearErrors());

    // const hotData = window.localStorage.getItem("hot");
    // const storedDate = window.localStorage.getItem("hotStoredDate");

    // // Check if the stored data exists and if it is still valid (less than 1 month old)
    // if (hotData && storedDate && !refresh) {
    //   const currentDate = new Date().getTime();
    //   const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    //   const storedDateMilliseconds = parseInt(storedDate);

    //   // Check if the stored data is still valid (less than 1 month old)
    //   if (currentDate - storedDateMilliseconds < oneMonthInMilliseconds) {
    //     dispatch({
    //       type: GET_HOT,
    //       payload: JSON.parse(hotData),
    //     });
    //     return;
    //   }
    // }

    // If the stored data is not valid or doesn't exist, send a new request
    ApiRequest.post(`hot_get_products?language=${lang}`)
      .then((res) => {
        const responseData = res.data;
        // window.localStorage.setItem("hot", JSON.stringify(responseData));
        // window.localStorage.setItem(
        //   "hotStoredDate",
        //   new Date().getTime().toString()
        // );
        dispatch({
          type: GET_HOT,
          payload: responseData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
      });
  };

export const ChargeHot = (mobile, selected, history) => (dispatch) => {
  dispatch(clearErrors());
  if (mobile.length === 4) {
    Toast.fire({
      title: intl.formatMessage({ id: "Please enter a valid number" }),
      icon: "warning",
    });
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  return ApiRequest.post(
    `hot?product_id=${selected.productid}&number=${mobile}&provider_id=${selected.providerid}&location=ISR`
  )
    .then((res) => {
      dispatch(userData(history));

      if (res.data.reason === "seller no balance") {
        return "noBalance";
      }
      if (res.data.status === "failed") {
        Toast.fire({
          title: res.data.reason,
          icon: "warning",
        });
        return;
      }
      Toast.fire({
        title: res.data.reason,
        icon: "success",
      });
      // Notiflix.Report.success(`Balance Added !`, '', 'Close')
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      Notiflix.Report.failure(`Something Went Wrong`, ``, "Close");
    });
};

export const addCompany = (companyData) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: ADD_COMPANIES_LOADING,
    payload: true,
  });
  return ApiRequest.post(
    `add_main_products?product_name=${companyData.product_name}&product_id=${companyData.product_id}&discount=${companyData.discount}&active=${companyData.active}&type=${companyData.type}&category=${companyData.category}&url=${companyData.url}&location=${companyData.location}`
  )
    .then((res) => {
      dispatch({
        type: ADD_COMPANIES_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_COMPANIES_LOADING,
        payload: false,
      });
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const jawwalPlusCheck = (number) => async (dispatch) => {
  // const { data } = await ApiRequest.post(`isplus?number=${number}`);
  // if (data.is_plus) {
  //   dispatch(jawwalPlus(true));
  //   return;
  // }
  dispatch(jawwalPlus(false));
};

export const jawwalPlus = (boolean) => ({
  type: IS_PLUS,
  payload: boolean,
});

export const JawwalBundleLoading = (boolean, bundleLoading) => ({
  type: JAWWAL_BUNDLE_LOADING,
  payload: {
    boolean,
    bundleLoading,
  },
});
