import React, { useState } from "react";
import { TextField, MenuItem, Grid, Button, Box } from "@mui/material";
import translate from "i18n/translate";
import { intl } from "i18n/provider";

const UserDetails = ({ onConfirm, buttonDisabled, playerId }) => {
  const [userNumber, setUserNumber] = useState(playerId || "");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (!userNumber) {
      setError(true);
      return;
    }
    setError(false);

    onConfirm(userNumber);
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      sx={{
        mb: 2,
      }}
    >
      <Grid item md={4}>
        <TextField
          fullWidth
          label={translate("playerId")}
          value={userNumber}
          onChange={(e) => setUserNumber(e.target.value)}
          variant="outlined"
          inputProps={{
            dir: "ltr",
          }}
          error={error}
          helperText={error && translate("invalidPlayerId")}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            padding: "15px 30px",
          }}
          disabled={buttonDisabled}
        >
          {translate("submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
