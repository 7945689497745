import React, { useState } from "react";
import Modal from "components/common/Modal";
import { TextField, Button, Grid } from "@mui/material";
import { Spinner } from "react-bootstrap";
import ApiRequest from "../../actions/ApiRequest";
import { set } from "lodash";
import { LoadingButton } from "@mui/lab";

export default function ModalReplyMessage({
  openModal,
  setOpenModal,
  message,
}) {
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await ApiRequest.post(
      `reply_tickets?msg_id=${message.transid}&mode=update&reply=${reply}`
    );
    setLoading(false);
    setOpenModal(false);
    setReply("");
  };

  return (
    <Modal
      visible={openModal}
      onClose={() => setOpenModal(false)}
      title={"Reply"}
      width={"30vw"}
    >
      <Grid container spacing={2}>
        <GridInfo item label="Message" value={message.Message} />
        <GridInfo item label="Name" value={message.Name} />
        <GridInfo item label="Note" value={message.Note} />
        <GridInfo item label="Subject" value={message.Subject} />
        <GridInfo item label="User" value={message.User} />
        <GridInfo item label="Date & Time" value={message.datetime} />
        <GridInfo item label="Mobile" value={message.mobile} />
        <GridInfo item label="Status" value={message.status} />
        <GridInfo item label="Transaction ID" value={message.transid} />

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Reply"
            variant="filled"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ margin: 10 }}
            disabled={reply === ""}
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenModal(false)}
            style={{ margin: 10 }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

function GridInfo({ label, value }) {
  return (
    <Grid item xs={4}>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        value={value}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        disabled
        margin="normal"
      />
    </Grid>
  );
}
