import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import translate from "./../../../i18n/translate";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  AttachMoney,
  Phone,
  DataUsage,
  Public,
  Group,
} from "@mui/icons-material";

const SubNavOoredoo = ({ mobile, isDarktheme }) => {
  const history = useHistory().location.pathname.split("/")[3];
  const myHis = useHistory();
  const location = useLocation();

  const handleNavigation = (path) => {
    const params = new URLSearchParams(location.search);
    myHis.push({
      pathname: path,
      provider_Id: window.history?.state?.state?.provider_Id || "001",
      search: params.toString(),
      state: history.location?.state,
    });
  };

  return (
    <Paper
      sx={{ marginTop: 2, padding: "10px 0" }}
      elevation={3}
      className={`sub-nav ${isDarktheme ? "dark" : ""}`}
    >
      <BottomNavigation
        value={history}
        showLabels
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          gap: "10px",
          height: "auto",
        }}
      >
        <BottomNavigationAction
          label={translate("etopup")}
          value="credit"
          icon={<AttachMoney />}
          onClick={() => handleNavigation(`/company/ooredoo/credit/${mobile}`)}
          className={`${isDarktheme ? "dark-link-main" : "link-main"}`}
        />
        <BottomNavigationAction
          label={translate("minutes")}
          value="minutes"
          icon={<Phone />}
          onClick={() => handleNavigation(`/company/ooredoo/minutes/${mobile}`)}
          className={`${isDarktheme ? "dark-link-main" : "link-main"}`}
        />
        <BottomNavigationAction
          label={translate("ooredoo3g")}
          value="3g"
          icon={<DataUsage />}
          onClick={() => handleNavigation(`/company/ooredoo/3g/${mobile}`)}
          className={`${isDarktheme ? "dark-link-main" : "link-main"}`}
        />
        <BottomNavigationAction
          label={translate("Roaming")}
          value="rom"
          icon={<Public />}
          onClick={() => handleNavigation(`/company/ooredoo/rom/${mobile}`)}
          className={`${isDarktheme ? "dark-link-main" : "link-main"}`}
        />
        <BottomNavigationAction
          label={translate("superShabab")}
          value="shabab"
          icon={<Group />}
          onClick={() => handleNavigation(`/company/ooredoo/shabab/${mobile}`)}
          className={`${isDarktheme ? "dark-link-main" : "link-main"}`}
        />
      </BottomNavigation>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  isDarktheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps)(SubNavOoredoo);
