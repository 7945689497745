import { Grid, Typography } from "@mui/material";
import { intl } from "i18n/provider";
import React from "react";
import { CURRENCIES_SYMBOLS } from "shared/constants/constants";
import GameCard from "./GameCards";

const CardsWithPrice = ({
  playerName,
  customer_price,
  totalUC,
  cost,
  sellerDiscount,
  denominationCards,
  selectedCardsIds,
  setSelectedCardsIds,
}) => {
  const onSelectCard = (id, quantity) => {
    if (quantity === 0) {
      const { [id]: _, ...rest } = selectedCardsIds;
      setSelectedCardsIds(rest);
    } else {
      setSelectedCardsIds((prev) => ({
        ...prev,
        [id]: quantity,
      }));
    }
  };

  return (
    <Grid container md={12} xs={12} justifyContent={"center"} paddingTop={1}>
      <Grid
        item
        md={12}
        xs={12}
        container
        justifyContent={"center"}
        paddingTop={1}
        spacing={3}
      >
        <Grid item>
          <Typography variant="h6" fontWeight={"bolder"}>
            {intl.formatMessage({ id: "customer_price" })}:{" "}
            <b style={{ color: "red" }}>
              {customer_price + CURRENCIES_SYMBOLS.ils}
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={"bolder"} color="GrayText">
            ــــــ
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={"bolder"}>
            UC: <b style={{ color: "red" }}>{totalUC}</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={"bolder"} color="GrayText">
            ــــــ
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={"bolder"}>
            {intl.formatMessage({ id: "seller cost" })}:{" "}
            <b style={{ color: "red" }}>{cost + CURRENCIES_SYMBOLS.ils}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12} container spacing={2} marginTop={1}>
        {denominationCards.map((card) => {
          const costAfterDiscount =
            (Number(card.cost) * (100 - Number(sellerDiscount))) / 100;
          const updatedCardObj = {
            ...card,
            cost: parseFloat(costAfterDiscount?.toString()).toFixed(1),
          };
          return (
            <Grid item lg={2} md={3} xs={12} sm={6} key={card?.id}>
              <GameCard
                card={updatedCardObj}
                isSelected={!!selectedCardsIds[card?.id]}
                onClick={onSelectCard}
                cardQuantity={selectedCardsIds[card?.id] || 1}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CardsWithPrice;
