import React from "react";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { IconButton } from "@mui/material";

const propTypes = {
  textToCopy: PropTypes.string,
  title: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  callBack: PropTypes.func,
};

const defaultProps = {};

export default function CopyButton(props) {
  const {
    textToCopy,
    title,
    iconClassName,
    buttonClassName,
    callBack,
    copyType = false,
  } = props;
  return (
    <IconButton
      title={title || `Copy ${textToCopy}`}
      className={buttonClassName}
      onClick={(e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(textToCopy);
        callBack && callBack();
      }}
    >
      {!copyType && (
        <ContentCopyIcon className={iconClassName} fontSize="small" />
      )}
      {copyType && (
        <AiOutlineCopyrightCircle className={iconClassName} fontSize="small" />
      )}
    </IconButton>
  );
}

CopyButton.propTypes = propTypes;
CopyButton.defaultProps = defaultProps;
