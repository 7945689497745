import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./styles.css";
import { getContactUsMessages } from "../../actions/contactUsAction";
import Pagination from "../common/Pagination";
import moment from "moment";
import translate from "../../i18n/translate";
import Spinner from "../ui/spinner/Spinner";
import "./styles.css";
import ApiRequest from "../../actions/ApiRequest";
import ModalReplyMessage from "./ModalReplyMessage";

const ContactUsTable = ({
  userData,
  getContactUsMessages,
  contactUsMessages,
  sort,
  setSort,
  isDarktheme,
}) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loading, isLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [messageRes, setMessageRes] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getData();
  }, [userData]);

  useEffect(() => {
    if (contactUsMessages.length) {
      setCount(
        Math.ceil(Number(contactUsMessages[0].total_count) / sort.label)
      );
      // setTotalCount(Number(contactUsMessages[0].total_count))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUsMessages]);

  const getData = async () => {
    isLoading(true);
    await getContactUsMessages("0", sort.value);
    isLoading(false);
  };

  const handleOffsetValue = async (event, value) => {
    isLoading(true);
    setPage(value);
    let queryOffset = value * sort.label - sort.label;
    await getContactUsMessages(queryOffset, sort.value);
    isLoading(false);
  };

  const handleSortChange = async (v) => {
    setPage(1);
    setSort(v);
    if (contactUsMessages?.length) {
      isLoading(true);
      await getContactUsMessages("0", v.value);
      isLoading(false);
    }
  };

  const handleClickOnRow = async (message) => {
    setReplyLoading(true);
    const res = await ApiRequest.post(
      `reply_tickets?msg_id=${message.transid}&mode=read`
    );
    setMessageRes(res.data[0]);
    setOpenModal(true);
    setReplyLoading(false);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center my-4">
      <div className="row table-wrapper w-100">
        <Pagination
          count={count}
          page={page}
          handleOffsetValue={handleOffsetValue}
          handleSortChange={handleSortChange}
          sort={sort}
        />

        <table
          className={
            !isDarktheme ? "table table-striped" : "table dark-table-striped"
          }
        >
          <thead>
            <tr className="first-Row">
              <th scope="col">{translate("Message ID")}</th>
              <th scope="col">{translate("Time")}</th>
              <th scope="col">{translate("Subject")}</th>
              <th scope="col">{translate("Message")}</th>
              <th scope="col">{translate("Status")}</th>
            </tr>
          </thead>

          <tbody>
            {contactUsMessages.map((message, index) => {
              return (
                <tr
                  key={index.toString()}
                  onClick={handleClickOnRow.bind(null, message)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{message.transid}</td>
                  <td>{moment(message.datetime).format("YYYY-MM-DD HH:mm")}</td>
                  <td className="contact-us_subject">{message.Subject}</td>
                  <td
                    style={{ whiteSpace: "break-spaces" }}
                    className="contact-us_message"
                  >
                    {message.Message}
                  </td>
                  <td>{translate(message.status)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!contactUsMessages?.length && (
          <div className="no-data-to-show">{translate("No data to show")}</div>
        )}
      </div>
      {messageRes && (
        <ModalReplyMessage
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
          message={messageRes}
        />
      )}

      {(loading || replyLoading) && <Spinner />}
    </div>
  );
};

const mapStateToProps = ({
  auth: { userData },
  contactUs: { contactUsMessages },
  darkTheme: { isDarktheme },
}) => ({
  userData,
  contactUsMessages,
  isDarktheme,
});

export default connect(mapStateToProps, { getContactUsMessages })(
  ContactUsTable
);
