import { GET_CARDS, GET_COPMANY_CARDS, COMPANY_CARDS_LOADING, CARDS_LOADING } from "../actions/types";

const initialState = {
  cards: [],
  companyCards: [],
  companyCardsLoading: false,
  loading: false,
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARDS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      };
    case GET_COPMANY_CARDS:
      return {
        ...state,
        companyCards: action.payload
      };
    case COMPANY_CARDS_LOADING:
      return {
        ...state,
        companyCardsLoading: action.payload
      };
    case CARDS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};

export default cardsReducer;
