import { Grid, TextField, Typography } from "@mui/material";
import { isArray } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiscounts } from "../../actions/discountsAction";
import { getSellerReports } from "../../actions/reportsAction";
import { getSellers } from "../../actions/sellerCreditsAction";
import { GET_SELLER_REPORTS } from "../../actions/types";
import SaleReportTable from "../../core/core-components/SaleReportTable/SaleReportTable";
import translate from "../../i18n/translate";
import { USER_TYPES_WITH_ADMIN } from "../../shared/constants/constants";
import { formatSellerOptionsForSelect } from "../../shared/utils/formatUtils";
import Button from "../common/Button";
import FromToDatePicker from "../common/FromToDatePicker";
import ReportHeader from "./ReportHeader";
import Select from "react-select";
import { conditionalReturn } from "shared/utils/utils";
import { intl } from "../../i18n/provider.js";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExportButton from "components/common/ExportButton";
import CancelableInput from "core/core-components/CancelableInput/CancelableInput";
import { useIsAdmin } from "shared/hooks/ppHooks";

const CreditReport = () => {
  const now = new Date();
  const [loading, isLoading] = useState(false);
  const [sellersOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState({
    value: "",
    label: translate("Select all"),
  });
  const [note, setNote] = useState("");
  const [updateTransactionData, setUpdateTransactionData] = useState({
    transid: "",
    value: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [dateTo, setDateTo] = useState(now);
  const [dateFrom, setDateFrom] = useState(now);

  const sellerType = useSelector((state) =>
    state.auth?.user.seller_type?.toLowerCase()
  );

  const isAdmin = USER_TYPES_WITH_ADMIN.admin.value === sellerType;

  const sellerReports = useSelector((state) => state.reports.sellerReports);
  const sellers = useSelector((state) => state.credits.sellers);
  const discounts = useSelector((state) => state.discounts.discounts);
  const reqInterval = useRef(null);
  const allSellers = useRef(null);
  const { isReseller } = useIsAdmin();
  const sellerId = useSelector((state) => state.auth.user?.id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_SELLER_REPORTS,
      payload: [],
    });
  }, [dispatch]);

  const getReportData = (page = pageNumber, rowsCount = rowsPerPage) => {
    const isAdminOrSeller =
      seller.type === USER_TYPES_WITH_ADMIN.admin.value ||
      seller.type === USER_TYPES_WITH_ADMIN.reseller.value;
    const providerId = isAdminOrSeller ? seller?.value : "";
    const sellerId = isAdmin
      ? seller?.value
      : JSON.parse(localStorage.currentUser).id;

    isLoading(true);
    dispatch(
      getSellerReports(
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
        "",
        "add credits",
        "",
        "",
        "",
        "",
        false,
        "",
        "",
        providerId,
        "",
        page,
        rowsCount,
        sellerId,
        "",
        "",
        "",
        "credit",
        note
      )
    ).then(() => {
      isLoading(false);
    });
  };

  const stopRequest = () => {
    clearInterval(reqInterval.current);
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    stopRequest();
  };

  const onRowsPerPageChange = (v) => {
    setPageNumber(1);
    setRowsPerPage(v);
    if (sellerReports?.length) {
      getReportData("0", v);
    }
    stopRequest();
  };

  const handleSearch = () => {
    setPageNumber(1);
    getReportData("0", rowsPerPage);
  };

  useEffect(() => {
    if (isAdmin) {
      setSeller({ value: "", label: translate("Select all") });
    }
  }, [isAdmin]);

  useEffect(() => {
    document.title = "Report | Phone Play";
    if (!discounts?.length) {
      dispatch(getDiscounts());
    }
    try {
      dispatch(getSellers(isReseller && sellerId)).then(
        ({ all_sellers: data }) => {
          if (isArray(data)) {
            allSellers.current = data;
            setSellerOptions(formatSellerOptionsForSelect(data));
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers?.length]);

  useEffect(function () {
    if (!allSellers.current) return;

    setSellerOptions(formatSellerOptionsForSelect(allSellers.current));
    setSeller({ value: "", label: translate("Select all") });
  }, []);

  useEffect(() => {
    if (sellerReports.length) {
      setTotalCount(Number(sellerReports[0].total_count));
    }
  }, [sellerReports]);

  function renderSearchButton() {
    return (
      <Button
        className="btn sign-but report-search-btn"
        style={{ height: "32px", minHeight: "unset", right: "20%" }}
        onClick={handleSearch}
        loading={loading}
        title="null"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </Button>
    );
  }

  function renderFromToDatePicker() {
    return (
      <FromToDatePicker
        arrowProps={{
          fontSize: "smaller",
        }}
        divContainerClassName="date-picker-div-container"
        arrowInsteadOfTo={true}
        selectedDateFrom={dateFrom}
        selectedDateTo={dateTo}
        onSelectDateFrom={(e) => setDateFrom(e)}
        onSelectDateTo={(e) => setDateTo(e)}
      />
    );
  }

  return (
    <div>
      <ReportHeader />

      <Grid container spacing={1} justifyContent="right">
        <Grid item md={12} xs={12} marginTop={1}>
          {renderFromToDatePicker()}
        </Grid>
        <Grid container item md={12} xs={12}>
          {conditionalReturn(
            isAdmin || isReseller,
            <Grid
              item
              md={4}
              className="report-dropdown-container"
              paddingRight={"10px"}
            >
              <Select
                options={sellersOptions}
                value={seller}
                isClearable
                className="report-dropdown"
                onChange={(value) => {
                  setSeller(
                    value || { value: "", label: translate("Select all") }
                  );
                }}
              />
            </Grid>
          )}
          <Grid item md={4} xs={12} sm={12}>
            <CancelableInput
              onChange={(element) => setNote(element.target.value)}
              className="form-control report-input-left-column"
              placeholder={intl.formatMessage({ id: "Note" })}
              onClearClick={(e) => setNote("")}
              value={note}
              style={{ width: "100%" }}
              // disabled={disableAttr}
            />
          </Grid>
          <Grid
            item
            md={4}
            paddingLeft={isAdmin ? "10px" : ""}
            position="relative"
          >
            {renderSearchButton()}
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} paddingTop={"16px"}>
          {conditionalReturn(
            !!sellerReports?.length,
            [
              {
                label: "Total Sales",
                value: sellerReports[0]?.total_sales || 0,
              },
              {
                label: "Total Count",
                value: sellerReports[0]?.total_count || 0,
              },
              {
                label: "Total Wholesales",
                value: sellerReports[0]?.total_wholesales || 0,
              },
            ].map((x, i) => (
              <Grid item md={4} xs={12} key={i}>
                <Typography>
                  {x.label} : <b>{x.value}</b>
                </Typography>
              </Grid>
            ))
          )}
        </Grid>
        <div
          style={{
            height: "32px !important",
            marginTop: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        ></div>
        <Grid item md={12} xs={12}>
          <SaleReportTable
            totalText={<>{translate("total_credit")} NIS</>}
            onRefresh={() => getReportData()}
            page={pageNumber}
            isCreditReport
            count={totalCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            title={intl.formatMessage({ id: "credits_report" })}
            onEditClick={(report) => {
              setUpdateTransactionData({
                ...updateTransactionData,
                value: report.status,
                transid: report.transid,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreditReport;
