
import {
    CLEAR_ERRORS,
    LOADING_TRUE,
    GET_ALL_ADVERTISEMENTS

} from "./types";

import ApiRequest from "./ApiRequest";
import Toast from "../components/common/Toast";


export const saveTranslationData = (data) => (dispatch) => {
    dispatch(clearErrors());

    return ApiRequest
        .post(`translate?dbn=${data.dbName}&kf=${data.keyField}&sfn=${data.sourceFieldName}&tfn=${data.targetFieldName}&tl=${data.TargetLanguage}`)// topup_type params (card,topup,manually)
        .then((res) => {
            Toast.fire({
                title: "all is good",
                icon: "success",
            });
            return true
        })
        .catch((err) => {
            Toast.fire({
                title: "Something Went Wrong!",
                icon: "error",
            });
            // dispatch(handleError("Something Went Wrong !"))
            return false
        });
}

export const deleteAdvertisement = (index) => async (dispatch) => {
    dispatch(clearErrors());
    try {
        await ApiRequest.post(`update_advertise?location=${index}&operation=delete`)
        Toast.fire({
            title: "all is good",
            icon: "success",
        });
        return true

    } catch (err) {
        Toast.fire({
            title: "Something Went Wrong!",
            icon: "error",
        });
        return false
    }
}
export const getAdvertisement = () => async (dispatch) => {
    dispatch(clearErrors());

    try {
        return ApiRequest.post(`advertise`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_ADVERTISEMENTS,
                    payload: res.data
                });
                Toast.fire({
                    title: "all is good",
                    icon: "success",
                });

                return res.data
            })


    } catch (err) {
        Toast.fire({
            title: "Something Went Wrong!",
            icon: "error",
        });
    }
}
export const updateAdvertisement = (data) => async (dispatch) => {
    dispatch(clearErrors());

    try {
        await ApiRequest.post(`update_advertise?text=${data.advertisement}&location=${data.index}&operation=edit`)
        Toast.fire({
            title: "all is good",
            icon: "success",
        });
        return true

    } catch (err) {
        Toast.fire({
            title: "Something Went Wrong!",
            icon: "error",
        });
        return false
    }
}
export const addAdvertisement = (data) => async (dispatch) => {
    dispatch(clearErrors());

    try {
        const response= await ApiRequest.post(`update_advertise?text=${data}&operation=add`)
        Toast.fire({
            title: "all is good",
            icon: "success",
        });
        
        return response.data.trans_id

    } catch (err) {
        Toast.fire({
            title: "Something Went Wrong!",
            icon: "error",
        });
        return false
    }
}
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

