import React from 'react'
import PageTitle from '../common/PageTitle'

const PalPayWallet = () => {
    return (
        <div>
            <PageTitle title={'PalPay Wallet'}>

            </PageTitle>
        </div>
    )
}

export default PalPayWallet