export const formatSellerOptionsForSelect = (data = [], showOnlyName) =>
  data.map((item) => ({
    value: item.seller_id,
    label: showOnlyName ? item?.name : item.name + " " + item.seller_id,
    balance: item.balance,
    type: item.type,
  }));

export const formatTopUpAccountsOptionsForSelect = (data = []) =>
  data.map((item) => ({
    value: item.topup_account,
    label: item.topup_account,
    carrier: item.carrier,
  }));
