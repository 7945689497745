import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";

// material
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "../../common/Button";
import {
  getReportData,
  setReportData,
  EditReportData,
} from "../../../actions/adminActions";
import { isArray } from "lodash";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import translate from "../../../i18n/translate";
import Modal from "../../../components/common/Modal";
import { intl } from "../../../i18n/provider";
import {
  Grid,
  IconButton,
  InputAdornment,
} from "../../../../node_modules/@mui/material/index";
import { conditionalReturn } from "shared/utils/utils";
import { ON_ADMIN_SEARCH } from "actions/types";
import SearchIcon from "@mui/icons-material/Search";
import { Fragment } from "react";

const reportNames = [
  { label: "cancelation", value: "cancelations", date: true },
  {
    label: "automation_balance_log",
    value: "automation_balance_log",
    date: true,
  },
  { label: "bank_details", value: "bank_details", date: true },
  { label: "HMP_balance_log", value: "HMP_balance_log", date: true },
  { label: "Hot_Balance_Log", value: "Hot_Balance_Log", date: true },
  { label: "IP_Log", value: "IP_Log", date: true },
  { label: "JAB", value: "JAB", date: true },
  { label: "MintRoute_Card_Log", value: "MintRoute_Card_Log", date: true },
  { label: "MintRoute_Log", value: "MintRoute_Log", date: true },
  { label: "Peletalk_Balance_Log", value: "Peletalk_Balance_Log", date: true },
  {
    label: "Peletalk_Cancelation_Report",
    value: "Peletalk_Cancelation_Report",
    date: true,
  },
  { label: "Points", value: "Points", date: true },
  { label: "SMS_Log", value: "SMS_Log", date: true },
  { label: "Tickets", value: "Tickets", date: true },
  { label: "transactions", value: "transactions", date: true },

  { label: "automation_accounts", value: "automation_accounts", date: false },
  { label: "card_ids", value: "card_ids", date: false },
  { label: "cursor", value: "cursor", date: false },
  { label: "currency", value: "currency", date: false },
  { label: "HMP", value: "HMP", date: false },
  { label: "Hot_Balance", value: "Hot_Balance", date: false },
  { label: "Hot_Inventory", value: "Hot_Inventory", date: false },
  { label: "Insurance_Basic_Fees", value: "Insurance_Basic_Fees", date: false },
  { label: "Jawwal_Inventory", value: "Jawwal_Inventory", date: false },
  { label: "Ooredoo_Inventory", value: "Ooredoo_Inventory", date: false },
  { label: "peletalk_012Mobile", value: "peletalk_012Mobile", date: false },
  { label: "Peletalk_Balance", value: "Peletalk_Balance", date: false },
  { label: "Peltalk_cellcom", value: "Peltalk_cellcom", date: false },
  {
    label: "Peletalk_GolanTelecom",
    value: "Peletalk_GolanTelecom",
    date: false,
  },
  { label: "Peletalk_Mobile019", value: "Peletalk_Mobile019", date: false },
  { label: "Peletalk_Partner", value: "Peletalk_Partner", date: false },
  { label: "Peletalk_Pelephone", value: "Peletalk_Pelephone", date: false },
  { label: "Products", value: "Products", date: false },
  { label: "reward_Conversion", value: "reward_Conversion", date: false },
  { label: "Rewards", value: "Rewards", date: false },
  { label: "Rules", value: "Rules", date: false },
  {
    label: "Seller_Active_Products",
    value: "Seller_Active_Products",
    date: false,
  },
  {
    label: "Seller_Products_Discount",
    value: "Seller_Products_Discount",
    date: false,
  },
  { label: "Seller_ID", value: "Seller_ID", date: false },
];

const sellerOptions = [
  { value: "", label: "choose Option" },
  { value: "name", label: "name" },
  { value: "sellerid", label: "sellerid" },
  { value: "Seller_ID", label: "Seller_ID" },
];

const Reports = ({
  reports,
  getReportData,
  reportsLoading,
  reportNameParam,
  isDarktheme,
  EditReportData,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: !isDarktheme ? "var(--bs-table-bg)" : "#242526",
      color: !isDarktheme
        ? theme.palette.common.black
        : theme.palette.common.white,
      minWidth: 120,
      margin: "16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const dispatch = useDispatch();

  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [reportName, setReportName] = useState(null);
  const [dateReport, setDateReport] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterType, setFilterType] = useState(sellerOptions[0]["value"]);
  const [fields, setFields] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (reportNameParam) {
      search(reportNameParam);
    }
    return () => dispatch(setReportData([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isArray(reports) && reports.length) {
      dynamicReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  const params = {
    dateTo: dateReport ? moment().format("YYYY-MM-DD") : "",
    dateFrom: dateReport ? moment(dateFrom).format("YYYY-MM-DD") : "",
    report_name: reportName?.value || reportNameParam,
  };
  const search = () => {
    getReportData(params);
  };

  const dynamicReport = () => {
    if (reports && reports.length) {
      // console.log({ reports })
      // console.log('s', Object.keys(reports[0]))
      setFields(Object.keys(reports[0]));
      setFilterData(reports);
    }
  };

  const SearchfliterData = (e) => {
    const { value } = e.target;
    if (filterType === "name") {
      return setFilterData(
        reports &&
          reports?.filter((report) =>
            report.name?.toLowerCase().includes(value.toLowerCase())
          )
      );
    } else if (filterType === "sellerid") {
      return setFilterData(
        reports && reports?.filter((report) => report.sellerid.includes(value))
      );
    } else if (filterType === "Seller_ID") {
      return setFilterData(
        reports &&
          reports?.filter((report) =>
            report.Seller_ID?.toLowerCase().includes(value.toLowerCase())
          )
      );
    } else {
      return setFilterData(
        reports &&
          reports?.filter(
            (report) =>
              report.sellerid.includes(value) ||
              report.name?.toLowerCase().includes(value.toLowerCase()) ||
              report.Seller_ID?.toLowerCase().includes(value.toLowerCase())
          )
      );
    }
  };

  const typeFilter = (value) => {
    setFilterType(value.value);
    // setFilterData(reports)
  };
  return (
    <div className={!isDarktheme ? "mt-4" : "mt-4 dark"}>
      {!reportNameParam && (
        <div style={{ width: "45%", margin: "auto" }}>
          <Select
            placeholder="Select Report Name"
            value={reportName}
            options={reportNames}
            onChange={(v) => {
              setReportName(v);
              v.date ? setDateReport(true) : setDateReport(false);
            }}
          />
        </div>
      )}

      <>
        <Grid container>
          {conditionalReturn(
            dateReport,
            <Grid
              className="d-flex align-items-center justify-content-between section-two mt-4"
              style={{ width: "45%", margin: "auto" }}
            >
              <DatePicker
                selected={dateFrom}
                type="date"
                dateFormat="dd-MM-yyyy"
                className="form-control report-form-control-date-picker"
                onChange={(e) => setDateFrom(e)}
              />
              <h6 style={isDarktheme ? { color: "white" } : { color: "black" }}>
                To
              </h6>
              <DatePicker
                selected={dateTo}
                type="date"
                dateFormat="dd-MM-yyyy"
                className="form-control report-form-control-date-picker"
                onChange={(e) => setDateTo(e)}
              />
            </Grid>
          )}
          <Grid padding={2} justifyContent="center" item container md={12}>
            <TextField
              style={{ width: "46%" }}
              sx={!isDarktheme ? "" : { background: "white" }}
              id="outlined-basic"
              placeholder="Search number, seller_name, seller_id,provider_trans_id, cancelation_status, sub_provider_card_id,cancelation_status"
              label="Search"
              variant="outlined"
              size="small"
              value={searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        dispatch({ type: ON_ADMIN_SEARCH, payload: searchText })
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
        </Grid>
      </>
      {(reports && reports[0]?.name) ||
      (reports && reports[0]?.sellerid) ||
      (reports && reports[0]?.Seller_ID) ? (
        <div
          className="d-flex align-items-center justify-content-between section-two mt-3"
          style={{ width: "45%", margin: "auto" }}
        >
          <TextField
            style={{ width: "43%" }}
            sx={!isDarktheme ? "" : { background: "white" }}
            id="outlined-basic"
            label="Search"
            variant="filled"
            size="small"
            onChange={SearchfliterData}
          />
          <h6 style={isDarktheme ? { color: "white" } : { color: "black" }}>
            Feild
          </h6>
          <div style={{ width: "43%" }}>
            <Select
              placeholder={
                filterType === "" ? translate("type of search") : filterType
              }
              options={sellerOptions}
              onChange={typeFilter}
              value={filterType}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-end">
        <Button
          title={reportNameParam ? "update" : "search"}
          onClick={search}
          type="primary"
          disabled={
            ((dateReport && (!dateTo || !dateFrom || !reportName)) ||
              (!dateReport && !reportName)) &&
            !reportNameParam
          }
          loading={reportsLoading}
          style={{ width: "110px" }}
        />
      </div>

      <TableContainer sx={{ Width: "100vw", height: "80vh" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {reports &&
                fields?.map((feild, i) => (
                  <StyledTableCell align="center" key={i}>
                    {feild}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(reports && reports[0]?.name) ||
            (reports && reports[0]?.sellerid) ||
            (reports && reports[0]?.Seller_ID)
              ? filterData.map((report, i) => (
                  <Report
                    key={i.toString()}
                    report={report}
                    reports={reports}
                    index={i}
                    fields={fields}
                    isDarktheme={isDarktheme}
                    params={params}
                    EditReportData={EditReportData}
                  />
                ))
              : reports &&
                reports?.map((report, i) => (
                  <Report
                    key={i.toString()}
                    report={report}
                    reports={reports}
                    index={i}
                    fields={fields}
                    isDarktheme={isDarktheme}
                    params={params}
                    EditReportData={EditReportData}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const Report = ({
  report,
  index,
  fields,
  isDarktheme,
  reports,
  params,
  EditReportData,
}) => {
  const [editModal, setEditModal] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [editValue, setEditValue] = useState("");

  const settingsData = [];
  fields.forEach((element) => {
    return settingsData.push({ value: element, label: element });
  });
  const handelEditValue = (e) => {
    const { value } = e.target;
    setEditValue(value);
  };
  const onEditModel = () => {
    if (editValue.length !== 0) {
      EditReportData(params, editIndex, selectValue, editValue);
      setEditModal(false);
    }
  };
  return (
    <React.Fragment>
      <TableRow>
        {Report &&
          fields?.map((field, i) => (
            <Fragment key={i}>
              <TableCell
                onClick={() => {
                  if (field !== "index" && field !== "date") {
                    setEditModal(true);
                    setEditIndex(report["index"]);
                    setSelectValue(field);
                  }
                }}
                align="center"
              >
                {report[field]}
              </TableCell>
            </Fragment>
          ))}
      </TableRow>
      <Modal
        onClick={onEditModel}
        visible={editModal}
        bgColor={!isDarktheme ? "white" : "#242526"}
        onClose={() => setEditModal(false)}
        title="Edit Reports"
        titleColor={!isDarktheme ? "black" : "white"}
        width={"40vw"}
        height={"30%"}
        justifyCenter
      >
        <div
          className={
            !isDarktheme
              ? "card-summary mt-4"
              : "dark-card-summary mt-4 EditValue"
          }
        >
          <div>
            <input
              onChange={handelEditValue}
              type="text"
              className="form-control"
              placeholder={
                report[`${selectValue}`]
                  ? report[`${selectValue}`]
                  : "Type here"
              }
              style={{ marginTop: "20px" }}
            />
          </div>

          <div className="btn-section d-flex flex-row align-items-center justify-content-end ">
            <button
              className="btn btn-danger
                        "
              onClick={() => setEditModal(false)}
            >
              {intl.formatMessage({ id: "Close" })}
            </button>
            <button
              style={{ margin: "0 16px" }}
              className="btn btn-primary"
              onClick={onEditModel}
            >
              {intl.formatMessage({ id: "Edit" })}
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  admin: { reports, reportsLoading },
  darkTheme: { isDarktheme },
}) => ({
  reports,
  reportsLoading,
  isDarktheme,
});

export default connect(mapStateToProps, {
  getReportData,
  setReportData,
  EditReportData,
})(Reports);
