import React, { useState } from "react";
import translate from "i18n/translate";
import { Typography, Box, Paper, Grid, TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FromToDatePicker from "components/common/FromToDatePicker";
import { LoadingButton } from "@mui/lab";
import ApiRequest from "../../actions/ApiRequest";
import { intl } from "i18n/provider";
import Notiflix from "notiflix";
import Toast from "components/common/Toast";
import { connect, useSelector } from "react-redux";
import Modal from "components/esim/EsimModal";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";

const Sms = ({ isDarktheme }) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [searchLoading, setSearchLoading] = useState(false);
  const [messageIsLoading, setMessageIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [message, setMessage] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const sellerId = useSelector((state) => state.auth.user?.id);
  const maxMessageLength = localStorage.langCity === "ar" ? 70 : 160;

  const handleOnSearch = async () => {
    setSearchLoading(true);
    const formattedDateFrom = `${dateFrom.getFullYear()}-${
      dateFrom.getMonth() + 1
    }-${dateFrom.getDate()}`;
    const formattedDateTo = `${dateTo.getFullYear()}-${
      dateTo.getMonth() + 1
    }-${dateTo.getDate()}`;
    try {
      const { data } = await ApiRequest.post(
        `get_unique_numbers?s_date=${formattedDateFrom}&e_date=${formattedDateTo}&seller_id=${sellerId}`
      );
      console.log(data);
      if (data.status === "failed") {
        throw new Error(data.message);
      }

      setCustomers(data);
      Notiflix.Notify.success(intl.formatMessage({ id: "search_success" }));
    } catch (e) {
      Notiflix.Notify.failure(intl.formatMessage({ id: "search_failed" }));
    } finally {
      setSearchLoading(false);
    }
  };

  const handleOnConfirm = async () => {
    setMessageIsLoading(true);
    const formattedDateFrom = `${dateFrom.getFullYear()}-${
      dateFrom.getMonth() + 1
    }-${dateFrom.getDate()}`;
    const formattedDateTo = `${dateTo.getFullYear()}-${
      dateTo.getMonth() + 1
    }-${dateTo.getDate()}`;
    const cost = customers.length * 0.11; // Assuming $0.11 per customer

    try {
      ApiRequest.post(
        `send_smss?s_date=${formattedDateFrom}&e_date=${formattedDateTo}&msg=${message}&amount=${cost.toFixed(
          2
        )}`
      );
      // if (data.status === "failed") {
      //   throw new Error(data.message);
      // }
      setMessageIsLoading(false);
      setOpenModal(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your request is in progress",
        }),
        icon: "success",
      });
    } catch (e) {
      setMessageIsLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Something Went Wrong",
        }),
        icon: "error",
      });
    }
  };

  const handleOnSend = () => {
    const cost = customers.length * 0.11; // Assuming $0.11 per customer

    const message = intl
      .formatMessage({ id: "sendingSMS" })
      .replace("{amount}", cost.toFixed(2));

    setConfirmMessage(message);
    setOpenModal(true);
  };
  const handleChange = (e) => {
    setMessage(e.target.value.slice(0, maxMessageLength));
  };

  return (
    <Box sx={{ margin: 2 }}>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("sendMessageTitle")}</h1>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <FromToDatePicker
            selectedDateFrom={dateFrom}
            selectedDateTo={dateTo}
            onSelectDateTo={(e) => setDateTo(e)}
            onSelectDateFrom={(e) => setDateFrom(e)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LoadingButton
            variant="contained"
            loading={searchLoading}
            loadingPosition="start"
            startIcon={<AccessTimeIcon />}
            onClick={handleOnSearch}
          >
            {translate("search")}
          </LoadingButton>
        </Grid>
      </Grid>
      <Box
        style={{
          margin: "20px 0",
        }}
      >
        <Typography variant="h6" gutterBottom component="div">
          {translate("total")}: {customers.length}
        </Typography>
      </Box>
      {customers.length === 0 ? (
        <Box
          style={{
            margin: "20px 0",
          }}
        >
          <Typography variant="h4" gutterBottom component="div">
            {translate("no_data")}...
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: IS_MOBILE_DISPLAY ? "column-reverse" : "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              height: "1000px",
              overflow: "auto",
              width: IS_MOBILE_DISPLAY ? "100%" : "40%",
            }}
          >
            <table className={"table table-striped mt-2"}>
              <thead>
                <tr className="first-Row">
                  <td>{translate("index")}</td>
                  <td>{translate("number")}</td>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{customer.number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Box
            sx={{
              maxWidth: IS_MOBILE_DISPLAY ? "100%" : "60%",
              display: "flex",
              // flexDirection: "column",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            <TextField
              label={translate("enter_message")}
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              value={message}
              onChange={handleChange}
              helperText={`${message.length}/${maxMessageLength}`}
            />
            <LoadingButton
              variant="contained"
              // loading={messageIsLoading}
              loadingPosition="start"
              startIcon={<AccessTimeIcon />}
              onClick={handleOnSend}
              disabled={message.trim().length === 0}
            >
              {translate("send")}
            </LoadingButton>
          </Box>
        </Box>
      )}
      <Modal
        openModal={openModal}
        message={confirmMessage}
        setOpenModal={setOpenModal}
        onConfirm={handleOnConfirm}
        isLoading={messageIsLoading}
      />
    </Box>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(Sms);
