import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/Button";

const propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {};

export default function SearchButton(props) {
  const { isLoading, onClick, className } = props;

  return (
    <React.Fragment>
      <Button
        className={className ? className : "btn sign-but report-search-btn"}
        style={{ height: "32px", minHeight: "unset" }}
        onClick={onClick}
        loading={isLoading}
        title={"null"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </Button>
    </React.Fragment>
  );
}

SearchButton.propTypes = propTypes;
SearchButton.defaultProps = defaultProps;
