import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import "./style.css";
import { getSellers } from "../../../actions/sellerCreditsAction";
import Select from "react-select";
import { intl } from "../../../i18n/provider";
import AddCreditModal from "../../modals/AddCreditModal/AddCreditModal";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { END_POINTS } from "shared/endPoints/endPoints";
import { getAllSellersApiCaller } from "shared/endPoints/apiCallers";
import { formatSellerOptionsForSelect } from "shared/utils/formatUtils";
import { isArray } from "lodash";
import { USER_TYPES_WITH_ADMIN } from "shared/constants/constants";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const SellerCredits = ({ isDarktheme, type }) => {
  const [seller, setSeller] = useState(null);
  const [sellerType, setSellerType] = useState({ value: "", label: "Type" });
  const [openAddCredit, setOpenAddCredit] = useState(false);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc"); // or 'desc'
  const selectedResellerId = selectedReseller?.value || "";
  const history = useHistory();
  const { data, isLoading } = useQuery(
    [END_POINTS.getAllSellers.key, selectedResellerId || 0],
    () => getAllSellersApiCaller({ resellerId: selectedResellerId })
  );

  const { all_sellers, ...rest } = data?.data || {};
  const allSellersTotals = rest;
  const sellers = all_sellers;

  const [filteredData, setFilteredData] = useState(sellers);

  const SortArrow = ({ field }) => {
    if (sortField !== field) return <KeyboardArrowDownIcon />;
    return sortDirection === "asc" ? (
      <KeyboardArrowUpIcon />
    ) : (
      <KeyboardArrowDownIcon />
    );
  };

  const handleSort = (field) => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
  };

  const sortedData = React.useMemo(() => {
    if (!sortField) return filteredData;

    return [...filteredData].sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      // Check if the field values are numeric and convert them if they are
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortField, sortDirection]);
  const filtered = React.useMemo(() => {
    let val = [];
    if (isArray(sellers)) {
      const filtered = sellers.filter(
        (x) =>
          x.type === USER_TYPES_WITH_ADMIN.admin.value ||
          x.type === USER_TYPES_WITH_ADMIN.reseller.value
      );
      val = formatSellerOptionsForSelect(filtered, true);
    }
    return val;
  }, [sellers]);

  useEffect(() => {
    document.title = "Seller Credits | PhonePlay ";
  }, []);

  useEffect(() => {
    if (sellers?.length) {
      setFilteredData(sellers);
    }
  }, [sellers]);

  const options = filteredData?.map((item) => {
    return {
      value: item.seller_id,
      label: item.name + " " + item.seller_id,
      balance: item.balance,
    };
  });

  function renderTotals() {
    return (
      <>
        <Grid item md={4} xs={12}>
          <Typography>
            {translate("total_credit")} :{" "}
            <b>{allSellersTotals?.total_credits}</b>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>
            {translate("total_debt")} : <b>{allSellersTotals?.total_debt}</b>
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>
            {translate("total_points")} :{" "}
            <b>{allSellersTotals?.total_points}</b>
          </Typography>
        </Grid>
      </>
    );
  }

  const sellerOptions = [
    { value: "", label: "All" },
    { value: "admin", label: "Admin" },
    { value: "seller", label: "Seller" },
  ];

  const filterData = (e) => {
    const { value } = e.target;

    setFilteredData(
      sellers.filter(
        (seller) =>
          seller.type.toLowerCase().includes(sellerType.value.toLowerCase()) &&
          (seller.name.toLowerCase().includes(value.toLowerCase()) ||
            seller.seller_id.toLowerCase().includes(value.toLowerCase()) ||
            seller.mobile_number.toLowerCase().includes(value.toLowerCase()))
      )
    );
  };

  const typeFilter = (value) => {
    setFilteredData(
      sellers.filter((seller) =>
        seller.type.toLowerCase().includes(value.value.toLowerCase())
      )
    );
    setSellerType(value);
  };

  const handleIdClick = (sellerId) => {
    let clickedSeller = options.filter((seller) => seller.value === sellerId);
    setSeller(clickedSeller[0]);
    toggleAddCredit(true);
  };

  function toggleAddCredit(state = false) {
    setOpenAddCredit(state);
  }

  return (
    <>
      <AddCreditModal
        seller={seller}
        open={openAddCredit}
        onClose={() => toggleAddCredit()}
      />
      <div className="credits">
        <div className="card card-home">
          <div>
            <h1
              className={
                !isDarktheme ? "header-text p-4" : "dark-header-text p-4"
              }
            >
              {type === "debt"
                ? translate("Seller Debt")
                : translate("add credits")}
            </h1>
          </div>
        </div>

        <Grid container spacing={1}>
          <Grid item md={3} xs={12}>
            <Select
              placeholder={translate("type")}
              options={sellerOptions}
              onChange={typeFilter}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Select
              name="reseller_id"
              placeholder={intl.formatMessage({
                id: "select_reseller",
              })}
              label={intl.formatMessage({ id: "reseller" })}
              options={filtered}
              value={selectedReseller || ""}
              onChange={(val) => setSelectedReseller(val)}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <input
              className="form-control"
              placeholder={`${intl.formatMessage({ id: "search_record" })}`}
              onChange={filterData}
            />
          </Grid>
          <Grid
            alignItems={"center"}
            height={"40px"}
            item
            container
            md={12}
            xs={12}
          >
            {renderTotals()}
          </Grid>
        </Grid>
        <div
          className="table-wrapper"
          style={{
            padding: 0,
          }}
        >
          {isLoading ? (
            <Grid container justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          ) : (
            <table
              className={
                !isDarktheme
                  ? "table table-striped"
                  : "table dark-table-striped"
              }
            >
              <thead>
                <tr className="first-Row">
                  <td
                    onClick={() => handleSort("seller_id")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {translate("user id")}
                    <SortArrow field="seller_id" />
                  </td>
                  <td>{translate("name")}</td>
                  <td>{translate("Mobile No.")}</td>
                  <td
                    onClick={() => handleSort("balance")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {translate("Balance")} <SortArrow field="balance" />
                  </td>
                  <td
                    onClick={() => handleSort("debth")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {translate("Debt")} <SortArrow field="debth" />
                  </td>
                  <td>{translate("city")}</td>
                  <td>{translate("country")}</td>
                  <td>{translate("comission_code")}</td>
                  <td>{translate("enabled")}</td>

                  <td
                    onClick={() => handleSort("failed_login_attempt")}
                    style={{ cursor: "pointer" }}
                  >
                    {translate("failed_login_attempts")}{" "}
                    <SortArrow field="failed_login_attempt" />
                  </td>
                  <td
                    onClick={() => handleSort("points")}
                    style={{ cursor: "pointer" }}
                  >
                    {translate("points")} <SortArrow field="points" />
                  </td>
                  <td>{translate("type")}</td>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((seller, index) => (
                  <tr key={index.toString()}>
                    <td
                      onClick={() => {
                        handleIdClick(seller.seller_id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {seller.seller_id}
                    </td>
                    <td>{seller.name}</td>
                    <td>{seller.mobile_number}</td>
                    <td>{seller.balance} ₪</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          `/seller_debt?seller_id=${seller?.seller_id}`
                        );
                      }}
                    >
                      {seller.debth}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {seller.city}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {seller.country}
                    </td>
                    <td>{seller.commission_code}</td>
                    <td>
                      {seller.enabled === "true"
                        ? translate("Yes")
                        : translate("No")}
                    </td>
                    <td>{seller.failed_login_attempt}</td>
                    {/* <td>{seller.last_converted_points_date}</td> */}
                    <td>{seller.points}</td>
                    <td>
                      {seller.type === "N/A" || !seller?.type?.trim()?.length
                        ? translate("Not available")
                        : translate(seller.type)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sellers: state.credits.sellers,
  isDarktheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, {
  getSellers,
})(SellerCredits);
