import ApiRequest from "./ApiRequest";
import {
  SET_NOTIFICATIONS,
  GET_NOTIFICATIONS_LOADING,
  SINGLE_NOTIFICATION_LOADING,
} from "./types";
import Notiflix from "notiflix";

export const getNotifications = () => async (dispatch) => {
  dispatch(notificationsLoading(true));

  try {
    const { data } = await ApiRequest.post(`notifications?operation=get`);
    dispatch(setNotifications(data.data.reverse()));
  } catch (err) {
    // open failure modal
    Notiflix.Report.failure(`SomeThing Went Wrong !`, "", "Close");
  } finally {
    dispatch(notificationsLoading(false));
    dispatch(singleNotificationLoading(false));
  }
};

export const deleteUpdateNotifications =
  (operation, index, view) => async (dispatch) => {
    dispatch(singleNotificationLoading(true));

    try {
      // TODO: check the status
      await ApiRequest.post(
        `notifications?index=${index}&operation=${operation}&view=${view}`
      );
      dispatch(getNotifications());
      // dispatch(setNotifications(data.data))
    } catch (err) {
      // open failure modal
      Notiflix.Report.failure(`SomeThing Went Wrong !`, "", "Close");
    }
  };

export const setNotifications = (data) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: data,
  };
};

export const notificationsLoading = (boolean) => {
  return {
    type: GET_NOTIFICATIONS_LOADING,
    payload: boolean,
  };
};

export const singleNotificationLoading = (boolean) => {
  return {
    type: SINGLE_NOTIFICATION_LOADING,
    payload: boolean,
  };
};
