import { setEditReportData } from "../actions/adminActions";
import {
  ADMIN_GET_REPORT,
  ADMIN_GET_REPORT_LOADING,
  ON_ADMIN_SEARCH,
} from "../actions/types";

const initialState = {
  reports: [],
  reportsLoading: false,
  rawReports: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_ADMIN_SEARCH:
      return {
        ...state,
        reports: handleSearchText(action.payload, state.rawReports),
      };
    case ADMIN_GET_REPORT:
      return {
        ...state,
        rawReports: action.payload,
        reports: action.payload,
      };
    case setEditReportData:
      return {
        ...state,
        reports: action.payload,
      };
    case ADMIN_GET_REPORT_LOADING:
      return {
        ...state,
        reportsLoading: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;

function handleSearchText(searchText = "", rawReports = []) {
  searchText = searchText.trim();
  if (searchText.length) {
    return rawReports.filter(
      (r) =>
        r.number?.toString()?.includes(searchText) ||
        r.seller_name?.toString()?.includes(searchText) ||
        r.seller_id?.toString()?.includes(searchText) ||
        r.trans_id?.toString()?.includes(searchText) ||
        r.provider_trans_id?.toString()?.includes(searchText) ||
        r.sub_provider_card_id?.toString()?.includes(searchText) ||
        r.cancelation_status?.toString()?.includes(searchText)
    );
  }

  return rawReports;
}
