const PPTextFieldStyle = (theme) => ({
  inputField: {
    width: "100%",
    "& .MuiFormHelperText-root": {
      marginTop: "0px !important",
    },
    "& .Mui-disabled input": {
      pointerEvents: "none",
      cursor: "not-allowed",
      "-webkit-text-fill-color": "black !important",
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: "normal",
      paddingTop: 16,
      paddingBottom: 8,
    },
    "& textarea": {
      fontWeight: "normal",
      paddingTop: 16,
    },
    "& .MuiInputAdornment-root": {
      paddingTop: 8,
    },
    "& label": {
      paddingTop: 4,
    },
  },
  rtl: {
    "& label": {
      left: "calc(100% - 80px)",
    },
  },
  disabledStyle: {
    opacity: 0.5,
  },
  textBold: {
    "& input": {
      fontWeight: "bolder !important",
    },
  },
});
export default PPTextFieldStyle;
