import React from "react";
import { Button, Grid } from "@mui/material";
import translate from "../../../../i18n/translate.js";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CreditsPageStyles from "../../Credits/CreditsPageStyles.js";

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles(CreditsPageStyles);
export default function IsraelFinesPage(props) {
  const history = useHistory();
  const classes = useStyle(props);

  return (
    <Grid container>
      <Grid item container className={classes.fullHeight} md={12} xs={12} justifyContent={"center"}>
        <Button
          size="large"
          className={classes.linkButton}
          onClick={(e) => {
            e.preventDefault();
            history.push("/bills/pay_israel_fines");
          }}
          href={"/bills/pay_israel_fines"}
        >
          {translate("pay_fine")}
        </Button>
      </Grid>
      <Grid item container md={12} xs={12} justifyContent={"center"}>
        <Button
          className={classes.linkButton}
          size="large"
          onClick={(e) => {
            e.preventDefault();
            history.push("/bills/inquire_israel_fines");
          }}
          href={"/bills/inquire_israel_fines"}
        >
          {translate("fine_inquiry")}
        </Button>
      </Grid>
    </Grid>
  );
}

IsraelFinesPage.propTypes = propTypes;
IsraelFinesPage.defaultProps = defaultProps;
