import {
  SET_CURRENT_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_USER_DATA,
  GET_USER_DETAILS,
  USER_DETAILS_LOADING,
  VERIFY_NUMBER,
  VERIFY_NUMBER_LOADING,
  CONFIRM_NUMBER,
  CONFIRM_NUMBER_LOADING,
  CONFIRM_NUMBER_ERROR,
} from "./types";
import Notiflix from "notiflix";
import IPData from "ipdata";
import { isObject, isString } from "lodash";
import ApiRequest from "./ApiRequest";
import jwt_decode from "jwt-decode";

const ipdata = new IPData(process.env.REACT_APP_IPDATA_KEY);

export const setCurrentUser = (decode) => {
  return {
    type: SET_CURRENT_USER,
    payload: decode,
  };
};

// login user

export const loginUser =
  (userData, ip, history, errorCount) => async (dispatch) => {
    dispatch(userDetailsLoading(true));
    localStorage.setItem("hide companies", "true");
    dispatch(clearErrors());
    const config = {
      headers: {
        "X-Real-IP": ip,
        "X-Identifier": userData.username || userData.userName,
        pass: userData.password,
        sellerid: userData.username || userData.userName,
        identifier: userData?.notToken,
      },
  };
    const myIP = localStorage.getItem("ip");
    return ApiRequest.post(`signin?ip=${myIP}`, null, config)
      .then((res) => {
        //save to local storage
        if (res.data.status === "failed") {
          localStorage.setItem("errorCount", errorCount + 1);
          dispatch({
            type: GET_ERRORS,
            payload: "Invalid username or password",
          });
          dispatch(userDetailsLoading(false));
          return;
        }
        const { token } = res.data;
        localStorage.setItem("jwtUserToken", token);
        const decode = jwt_decode(localStorage.jwtUserToken);
        localStorage.setItem("token exp", decode.exp);
        if (
          localStorage.getItem(userData?.userName) ||
          userData?.userName === "100100"
        ) {
          //userData.userName,
          //set token to local storage
          // localStorage.setItem("companies", JSON.stringify(res.data));
          //set current user
          dispatch(getSellerData(true, history));
          localStorage.setItem("errorCount", 0);
        } else {
          ApiRequest.post(
            `verification?sellerid=${userData?.userName}&ip=${myIP}`
          );

          dispatch(userDetailsLoading(false));

          history.push({
            pathname: `/verification/${userData?.userName}`,
            state: {
              mobile: res.data["mobile"],
              sellerId: userData.userName,
              userData: res.data,
              token,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Something went wrong!",
        });
        dispatch(userDetailsLoading(false));
      });
  };

export const verfiyUser = (userId, verfiyData, history) => (dispatch) => {
  const myIP = localStorage.ip;
  dispatch(userDetailsLoading(true));
  dispatch(clearErrors());
  return ApiRequest.post(
    `check_verification_code?number=${verfiyData.mobile}&code=${verfiyData.virefy}&ip=${myIP}`
  )
    .then((res) => {
      if (!res.data) {
        Notiflix.Notify.failure("Wronge confirmation code");
      } else {
        localStorage.setItem(userId, userId);
        dispatch(getSellerData(true, history));
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Something went Wrong !!",
      });
    })
    .finally(() => {
      dispatch(userDetailsLoading(false));
    });
};

export const verfiySignUpUser = (data, history) => (dispatch) => {
  dispatch(clearErrors());
  // const myIP = localStorage.ip
  return ApiRequest.post(
    `verify_mobile_number?code=${data.verificationCode}&sellerid=${data.sellerId}&mobile_number=${data.mobile}`
  )
    .then((res) => {
      if (isObject(res.data)) {
        const { token } = res.data;
        localStorage.setItem("jwtUserToken", token);
        const decode = jwt_decode(localStorage.jwtUserToken);
        localStorage.setItem("token exp", decode.exp);
        localStorage.setItem("companies", JSON.stringify(res.data));
        localStorage.setItem("cityCell", "cityCell");
        dispatch(setCurrentUser(res.data));
        history.push("/");
      } else if (isString(res.data) && res.data === "failed") {
        Notiflix.Notify.failure(
          "You entered a wrong confirmation code, please try again"
        );
      } else if (
        isString(res.data) &&
        res.data === "verification code timeout"
      ) {
        Notiflix.Notify.failure("Timeout, Please sign up again");
        setTimeout(() => {
          history.push("/signup?timeout");
        }, 2000);
      } else {
        Notiflix.Notify.failure(
          "Something went wrong!!, please try sign up again"
        );
        setTimeout(() => {
          history.push("/signup?timeout");
        }, 2000);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Something went wrong !!",
      });
    });
};

export const signUpUser =
  (userData, ip, history, enableforeign) => (dispatch) => {
    localStorage.setItem("hide companies", "true");
    dispatch(userDetailsLoading(true));
    dispatch(clearErrors());
    const myIP = localStorage.ip;
    const config = {
      headers: { "X-Real-IP": ip, "X-Identifier": userData.username },
    };
    return ApiRequest.post(
      `signup?sellerid=${userData.username}&ip=${myIP}&seller_type=${
        userData.user_type
      }&name=${userData.fullName}&passw=${userData.password}&country=${
        userData.country
      }&city=${userData.city}&address=${userData.address}&user_type=${
        userData.user_type
      }&mobileNo=${userData.mobile}&email=${userData.email}${
        userData.code !== null ? `&code=${userData.code}` : null
      }&enable_foreign=${enableforeign}&reseller_id=${userData?.reseller_id}`,
      null,
      config
    )
      .then((res) => {
        if (
          (typeof res.data === "string" && res.data.includes("failed")) ||
          res.data.status === "failed"
        ) {
          dispatch({
            type: GET_ERRORS,
            payload: "Something went Wrong !!",
          });
          dispatch(userDetailsLoading(false));
        } else {
          const { token } = res.data;

          localStorage.setItem("jwtUserToken", token);
          const decode = jwt_decode(localStorage.jwtUserToken);
          localStorage.setItem("token exp", decode.exp);

          dispatch(getSellerData(true, history));
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
        dispatch(userDetailsLoading(false));
      });
  };

export const callResendCode = (sellerid) => (dispatch) => {
  const myIP = localStorage.getItem("ip");

  return new Promise((resolve, rejct) => {
    return ApiRequest.post(`verification?sellerid=${sellerid}ip=${myIP}`)
      .then((res) => {
        if (res.data !== "success") {
          dispatch({
            type: GET_ERRORS,
            payload: "Something went wrong!",
          });
        }
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Something went wrong!",
        });
        rejct(err);
      });
  });
};

export const forgotPassword = (userData, history) => (dispatch) => {
  localStorage.setItem("hide companies", "true");
  dispatch(clearErrors());
  const myIP = localStorage.getItem("ip");
  return ApiRequest.post(
    `forget_password?seller_id=${userData.userName}&mobile_last4=${userData.last4Digit}&ip=${myIP}`
  )
    .then((res) => {
      if (
        res.data === "False" ||
        res.data === "false" ||
        res.data === "failed"
      ) {
        dispatch({
          type: GET_ERRORS,
          payload: "The provided information is wrong!",
        });
      } else {
        history.push({
          pathname: `/reset-password`,
          state: {
            sellerId: userData.userName,
            last4Digit: userData.last4Digit,
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Something went wrong!",
      });
    });
};

export const resetPassword = (verfiyData, history) => (dispatch) => {
  dispatch(clearErrors());
  return ApiRequest.post(
    `forget_password_verification?seller_id=${verfiyData.seller_id}&mobile_last4=${verfiyData.last_4_digit}&random_number=${verfiyData.random_number}&new_password=${verfiyData.password}`
  )
    .then((res) => {
      if (
        res.data === "False" ||
        res.data === "false" ||
        res.data === "failed"
      ) {
        dispatch({
          type: GET_ERRORS,
          payload: "The provided information is wrong!",
        });
      } else {
        history.push({
          pathname: "/signin",
          state: { password_changed: true },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Something went wrong!",
      });
    });
};

export const callGetSellerNumber = () => {
  return ApiRequest.post("getsellerno");
};

export const callIpApi = () => {
  return ipdata.lookup();
};

export const getMainPicture = () => {
  return ApiRequest.post("get_main_picture").then((res) => {
    sessionStorage.setItem("main_picture", res.data);
    sessionStorage.setItem("leftImage", res.data.left_image_url);
    sessionStorage.setItem("rightImage", res.data.right_image_url);
  });
};

export const getAdvertise = (lang = "ar") => {
  return ApiRequest.post(`advertise?lang=${lang}`);
};

export const userData = (history) => (dispatch) => {
  dispatch(clearErrors());
  ApiRequest.post(`get_seller_profile`)
    .then((res) => {
      const currentUserData = res.data;
      // Log out the user if the account is disabled.
      // if (currentUserData.enabled !== "true") {
      //   Toast.fire({
      //     title: intl.formatMessage({ id: "You will be logged out because your account is disabled, for more information please contact our support team." }),
      //     icon: "warning",
      //     timer: 10000,
      //   }).then((result) => {
      //     logoutUser(history)(dispatch);
      //   })
      //   return;
      // }
      dispatch({
        type: GET_USER_DATA,
        payload: res.data,
      });
      localStorage.setItem("currentUser", JSON.stringify(currentUserData));
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// log user out
export const logoutUser = (router) => async (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtUserToken");
  localStorage.removeItem("companies");
  localStorage.removeItem("token exp");
  sessionStorage.clear();
  dispatch(setCurrentUser({}));
  router?.push("/signin");
};

export const getSellerData = (boolean, history, id) => (dispatch) => {
  dispatch(userDetailsLoading(true));

  const token = localStorage.jwtUserToken;
  ApiRequest.defaults.headers["token"] = token;

  return ApiRequest.post(`get_seller_profile?seller_id=${id ? id : ""}`)
    .then((res) => {
      dispatch({
        type: GET_USER_DETAILS,
        payload: res.data,
      });
      localStorage.setItem("companies", JSON.stringify(res.data));
      if (res.data?.country !== "palestine") {
        localStorage.setItem("hide companies", "false");
      }
      if (boolean) {
        history.push("/");
      }
      dispatch(userDetailsLoading(false));
      return;
    })
    .catch((err) => {
      dispatch(userDetailsLoading(false));
    });
};

export const getAnotherSellerData = (id, setSeller) => (dispatch) => {
  dispatch(userDetailsLoading(true));

  return ApiRequest.post(`get_seller_profile?seller_id=${id}`)
    .then((res) => {
      setSeller(res.data);
    })
    .catch((err) => {
      console.log("ERR");
    })
    .finally(() => {
      dispatch(userDetailsLoading(false));
    });
};

export const VerifyNumber = (mobile, ip) => (dispatch) => {
  dispatch({
    type: VERIFY_NUMBER_LOADING,
  });
  return ApiRequest.post(
    `send_verification_code_to_mobile?mobile=${mobile}&ip=${ip}`
  )
    .then((res) => {
      if (res.data === true) {
        dispatch({
          type: VERIFY_NUMBER,
          payload: true,
        });
      } else {
        dispatch({
          type: VERIFY_NUMBER,
          payload: false,
        });
      }
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: VERIFY_NUMBER,
        payload: false,
      });
    });
};

export const ConfirmNumber = (mobile, ip, code) => (dispatch) => {
  dispatch({
    type: CONFIRM_NUMBER_LOADING,
  });
  return ApiRequest.post(
    `check_verification_code?number=${mobile}&ip=${ip}&code=${code}`
  )
    .then((res) => {
      if (res.data) {
        dispatch({
          type: CONFIRM_NUMBER,
          payload: true,
        });
        return res.data;
      }
      dispatch({
        type: CONFIRM_NUMBER,
        payload: false,
      });
      dispatch({
        type: CONFIRM_NUMBER_ERROR,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONFIRM_NUMBER,
        payload: false,
      });
      dispatch({
        type: CONFIRM_NUMBER_ERROR,
      });
    });
};

export const updateProfile = (userDetails) => {
  return (dispatch) => {
    return ApiRequest.post(
      `update_seller_profile?name=${userDetails.name}&address=${
        userDetails.address
      }&shop_name=${userDetails.shop}&email=${
        userDetails.email || "N/A"
      }&town=${userDetails.town}&city=${userDetails.city}&street=${
        userDetails.street
      }&zip=${userDetails.zip_code}&passw=${userDetails.password}&id=${
        userDetails.id
      }&allow_f9=${userDetails.allow_f9}&fixed_ip=${
        userDetails.fixed_ip
      }&seller_type=${userDetails.type}&seller_id=${
        userDetails.boolean ? userDetails.id : ""
      }&allowed_debth=${userDetails.allwed_debth}&operation=profile&enabled=${
        userDetails.enabled
      }`
    )
      .then((res) => {
        if (!userDetails.boolean) {
          dispatch({
            type: GET_USER_DETAILS,
            payload: userDetails,
          });
        }
      })
      .catch((err) => {
        Notiflix.Notify.failure("Something went wrong!!, please try again");
      });
  };
};

export const updateDiscountProfile = (userDetails) => async () => {
  try {
    await ApiRequest.post(
      `update_seller_profile?name=${userDetails.name}&address=${
        userDetails.address
      }&shop_name=${userDetails.shop}&email=${
        userDetails.email || "N/A"
      }&town=${userDetails.town}&city=${userDetails.city}&street=${
        userDetails.street
      }&zip=${userDetails.zip_code}&passw=${userDetails.password}&id=${
        userDetails.id
      }&allow_f9=${userDetails.allow_f9}&seller_type=${
        userDetails.seller_type
      }&fixed_ip=${userDetails.fixed_ip}&allowed_debth=${
        userDetails.allwed_debth
      }&seller_id=${userDetails.id}&operation=discount&company_name=${
        userDetails.company
      }&new_value=${userDetails.newDiscount}`
    );
  } catch {
    Notiflix.Notify.failure("Something went wrong!!, please try again");
  }
};

export const updateActiveProfile = (userDetails) => async () => {
  try {
    ApiRequest.post(
      `update_seller_profile?name=${userDetails.name}&address=${
        userDetails.address
      }&shop_name=${userDetails.shop}&email=${
        userDetails.email || "N/A"
      }&town=${userDetails.town}&city=${userDetails.city}&street=${
        userDetails.street
      }&zip=${userDetails.zip_code}&passw=${userDetails.password}&id=${
        userDetails.id
      }&allow_f9=${userDetails.allow_f9}&seller_type=${
        userDetails.seller_type
      }&fixed_ip=${userDetails.fixed_ip}&allowed_debth=${
        userDetails.allwed_debth
      }&seller_id=${userDetails.id}&operation=active&company_name=${
        userDetails.company
      }&new_value=${userDetails.newActive}`
    );

    Notiflix.Notify.success("Updated Successfully");
    return;
  } catch {
    Notiflix.Notify.failure("Something went wrong!!, please try again");
  }
};

const userDetailsLoading = (boolean) => ({
  type: USER_DETAILS_LOADING,
  payload: boolean,
});
