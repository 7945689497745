import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

const SellerCreditsCard = ({ bill, columnStyle, isDarktheme }) => {
    return (
        <div className={`${columnStyle} mt-4 wrapper`}>
            <Link to={{
                pathname: bill.linkTo,
                state: {
                    billTitle: bill.name
                }
            }}>
                <div className={!isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"}>
                    <div className="frame">
                        <img
                            alt='bill card'
                            src={`${bill.imageUrl}`}
                        />
                    </div>
                    <div className={!isDarktheme ? "card nav-layout" : "card dark-nav-layout"}>
                        <h5 className="m-3 text-center">{bill.name}</h5>
                    </div>
                </div>
            </Link>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isDarktheme: state.darkTheme.isDarktheme
    }
}

export default connect(mapStateToProps, null)(SellerCreditsCard)