import React from "react";
import translate from "../../i18n/translate";
import { connect } from "react-redux";
import "./sidebar.css";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { Blink } from "@bdchauvette/react-blink";
import Rating from "../common/Rating";
import { MINIMUM_BALANCE_MESSAGE } from "../../shared/constants/constants";

const LeftSideBar = ({ user, userData, isDarktheme, rating }) => {
  const balance = Number(userData?.balance);
  const screenWidth = window.innerWidth;
  const debitStatus = () => {
    if (userData?.debth > 0) {
      return "text-danger";
    }
    return "text-success";
  };

  const balanceStatus = () => {
    if (balance > 300) {
      return "text-success";
    }
    if (balance > 200) {
      return "text-warning";
    }
    if (balance > 100) {
      return "text-danger";
    }
    return "text-danger blink";
  };
  return (
    <div className="col-lg-2 col-md-3 col-sm-6 ">
      <div className="widget-flat card">
        <div className={!isDarktheme ? "card-body" : "dark-card-body"}>
          {userData?.id ? (
            <>
              <Link to="/running?checkBalance">
                <h6
                  className="text-muted my-0"
                  title="Balance"
                  style={{ fontSize: "1rem" }}
                >
                  {translate("Balance Avaliable")}
                </h6>
                <div
                  className="my-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5
                    className={`${balanceStatus()} my-2`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    ₪ {userData && userData?.balance}
                  </h5>
                  {balance < MINIMUM_BALANCE_MESSAGE ? (
                    <Toolbar
                      title={`Your balance is below ${MINIMUM_BALANCE_MESSAGE} ils , in order to enjoy our services and products, please add credit to your account`}
                      style={{ minHeight: 30, paddingLeft: "12px" }}
                    >
                      <Link to="/seller_credit">
                        <WarningIcon fontSize="medium" color="warning" />
                      </Link>
                    </Toolbar>
                  ) : null}
                </div>
              </Link>

              <hr className="divider my-2"></hr>

              <h6
                className="text-muted my-0"
                title="Debt"
                style={{ fontSize: "1rem" }}
              >
                {translate("Debt")}
              </h6>
              <h5 className={`${debitStatus()} my-2`}>
                ₪ {userData && userData?.debth}
              </h5>

              <hr className="divider my-2"></hr>
              <Link to="/points?checkPoints">
                <h6
                  className="text-muted my-0"
                  title="Points"
                  style={{ fontSize: "1rem" }}
                >
                  {translate("Points")}
                </h6>

                <div
                  className="my-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5 className="text-info my-2">
                    ₪ {userData && userData?.points}
                  </h5>
                  <Toolbar
                    title={
                      userData?.days_remaining > 0
                        ? userData?.days_remaining +
                          " Days untill you can use your points"
                        : Number(userData?.points) <= 50
                        ? `${userData?.points} points`
                        : "Congratulations! , You can now redeem your points!"
                    }
                    style={{ minHeight: 30 }}
                  >
                    {Number(userData?.days_remaining) > 0 ||
                    Number(userData?.points) <= 50 ? (
                      <i className="fas fa-lock" style={{ fontSize: 20 }}></i>
                    ) : (
                      <i
                        className="fas fa-lock-open"
                        style={{ fontSize: 20, color: "#198754" }}
                      ></i>
                    )}
                  </Toolbar>
                </div>
              </Link>
              <hr className="divider my-2"></hr>
              <h6 className="text-muted my-0" style={{ fontSize: "1rem" }}>
                {translate("Allowed debt")}
              </h6>
              <h5 className={`text-warning my-2`}>
                ₪ {userData && userData?.allwed_debth}
              </h5>
              <hr className="divider my-2"></hr>
              <small className="text-nowrap ms-1" style={{ fontSize: "small" }}>
                {translate("Name")}
              </small>
              <p className="user-info mt-1 px-2 text-muted">
                <span className="username text-nowrap ms-1">
                  {user?.name || userData?.name}
                </span>
              </p>
              <small className="text-nowrap ms-1" style={{ fontSize: "small" }}>
                {translate("Rating")}
              </small>
              <p className="user-info mt-1 px-2 text-muted">
                <Rating value={rating} />
              </p>
              <small className="text-nowrap ms-1" style={{ fontSize: "small" }}>
                {translate("Seller ID")}
              </small>
              <p className="user-info mt-1 px-2 text-muted">
                <span className="me-1" style={{ color: "black" }}>
                  {user?.id || userData?.id}
                </span>
              </p>
            </>
          ) : (
            <Blink>
              <div
                style={{
                  height: 5,
                  width: 5,
                  borderWidth: 1,
                  borderRadius: 50,
                  borderColor: "red",
                  backgroundColor: "red",
                  borderStyle: "solid",
                }}
              />
            </Blink>
          )}
        </div>
      </div>
      {screenWidth > 768 && (
        <div
          className="add-banner"
          style={{
            background: `url("${sessionStorage.getItem("leftImage")}")`,
          }}
        ></div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  auth: { user, userData, userDetailsLoading, rating },
  darkTheme: { isDarktheme },
}) => ({
  user,
  userData,
  userDetailsLoading,
  isDarktheme,
  rating,
});
export default connect(mapStateToProps, {})(LeftSideBar);
