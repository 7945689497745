import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import ForgotPassword from "../auth/ForgotPassword";
import Verification from "../auth/Verification";
import SignUpVerification from "../auth/SignUpVerification";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import ResetPassword from "../auth/ResetPassword";
import PrivateRoute from "../common/PrivateRoute";
import AdminRoute from "../common/AdminRoute";
// import AdminRoutes from "./AdminRoutes";
import NumberVerification from "../auth/NumberVerification";
import AdminRoutes from "./AdminRoutes";
import { DarkTheme } from "../../actions/darkTheme";
import NoBalanceCard from "components/cards/NoBalanceCard";

const Routes = ({
  user,
  location,
  isAuthenticated,
  isDarktheme,
  DarkTheme,
}) => {
  useEffect(() => {
    let dark = localStorage.getItem("darkMode");
    if (dark === "true") {
      DarkTheme(true);
    } else {
      DarkTheme(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={!isDarktheme ? "AppBody" : "dark-AppBody"}>
      <Switch>
        {!user && <Route exact path="/" component={SignIn} />}
        <Route exact path="/signup" component={SignUp} />
        <Route
          exact
          path="/NumberVerification"
          component={NumberVerification}
        />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/verification/:id" component={Verification} />
        <Route
          exact
          path="/signup-verification"
          component={SignUpVerification}
        />
        {/* <AdminRoute path='/admin/:path?' exact component={AdminRoutes} />
        <AdminRoute path='/admin/Company/:path?' exact component={AdminRoutes} /> */}
        <AdminRoute path="/admin/:path?" exact component={AdminRoutes} />
        <AdminRoute
          path="/admin/Company/:path?"
          exact
          component={AdminRoutes}
        />
        <PrivateRoute path="/" component={AuthenticatedRoutes} />
      </Switch>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  isDarktheme: state.darkTheme.isDarktheme,
});
const mapDispatchToProps = (dispatch) => {
  return {
    DarkTheme: (boolean) => dispatch(DarkTheme(boolean)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
