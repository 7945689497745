import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { intl } from "i18n/provider";
import { CURRENCIES_SYMBOLS } from "shared/constants/constants";

export default function JawakerCard(props) {
  const [showSelected, setShowSelected] = useState(false);
  const { coin, isSelected, onClick, cardQuantity } = props;
  const { coins, seller_price, customer_price, id, en_des, ar_des } = coin;
  const [quantity, setQuantity] = useState(cardQuantity);

  const handleCardClick = () => {
    onClick(id, isSelected ? 0 : 1);
  };

  const incrementQuantity = (event) => {
    event.stopPropagation();
    setQuantity((prevQuantity) => prevQuantity + 1);
    onClick(id, quantity + 1);
  };

  const decrementQuantity = (event) => {
    event.stopPropagation();
    if (quantity > 1) setQuantity((prevQuantity) => prevQuantity - 1);
    onClick(id, quantity - 1);
  };

  return (
    <Grid item md={4} lg={2} xs={12}>
      <Card
        onClick={handleCardClick}
        onMouseEnter={() => {
          if (isSelected && !showSelected) {
            setShowSelected(true);
          }
        }}
        onMouseLeave={() => {
          if (isSelected && showSelected) {
            setShowSelected(false);
          }
        }}
      >
        <CardActionArea style={{ position: "relative" }}>
          <Fade in={isSelected} mountOnEnter={false} unmountOnExit={false}>
            <div
              style={{
                backgroundColor: "rgba(134, 183, 254, 0.1)",
                opacity: 1,
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon
                style={{
                  color: "#86b7fe",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  fontSize: 52,
                  boxShadow: "initial",
                }}
              />
            </div>
          </Fade>

          <CardMedia
            component="img"
            image={
              "https://static.twofour54.com/wp-content/uploads/sites/5/2017/02/jawaker-logo_tcm12-16701.png"
            }
            alt="Jawaker"
          />
          <CardContent>
            <Grid container justifyContent={"center"}>
              <Grid item md={12} xs={12} container justifyContent={"center"}>
                <Typography fontWeight={"bold"} gutterBottom fontSize={18}>
                  {`${customer_price} ${CURRENCIES_SYMBOLS.ils}`}
                </Typography>
              </Grid>
              {/* <Grid item md={12} xs={12}>
              <Typography variant="body2" fontSize={14} color="text.secondary">
                {intl.locale === "en" ? en_des : ar_des}
              </Typography>
            </Grid> */}
              <Grid item md={12} xs={12} container justifyContent={"center"}>
                <Typography vari ant="h6" fontWeight="bold" fontSize={12}>
                  {intl.formatMessage({ id: "JawakerCoins" }) + ": " + coins}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12} container justifyContent={"center"}>
                <Typography vari ant="h6" fontWeight="bold" fontSize={14}>
                  {intl.formatMessage({ id: "seller cost" }) +
                    ": " +
                    seller_price +
                    CURRENCIES_SYMBOLS.ils}
                </Typography>
              </Grid>
              {isSelected && (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  // zIndex={1000}
                  style={{ position: "relative", zIndex: 20 }}
                >
                  <Grid item>
                    <IconButton onClick={decrementQuantity} color="error">
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="p"
                      sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      {quantity}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={incrementQuantity} color="success">
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
