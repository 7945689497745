import React, { useEffect, useState, useRef } from "react";
import translate from "../../../i18n/translate";
import { useHistory } from "react-router-dom";
import SubNavOoredoo from "./SubNavOoredoo";
import { connect } from "react-redux";
import {
  getOoredooSuper,
  getOoredooSuperNotRenew,
  getOoredooSuperRenew,
} from "../../../actions/companiesAction";
import Selected from "./Selected";
import Badge from "../../ui/Badge/Badge";
import _ from "lodash";
import "./ooredoo.css";
import { dataLanguage } from "../../../i18n/translateRequest";
import CompaniesNav from "../../common/CompaniesNav";
import logo from "../../../assests/images/bundles/ooredoo/banner.jpg";
import { jsonParser } from "shared/utils/ppUtils";

const ShababOoredoo = ({
  getOoredooSuper,
  ooredooSuper,
  getOoredooSuperNotRenew,
  loading,
  getOoredooSuperRenew,
  isDarkTheme,
}) => {
  const history = useHistory().location.pathname;
  const mobileNo = history.split("/")[4];

  const [isRenew, setIsRenew] = useState(false);
  const [isNotRenew, setIsNotRenew] = useState(false);
  const [init, isInit] = useState(false);

  const [g3, setG3] = useState("");
  const [min, setMin] = useState("");
  const [credit, setCredit] = useState("");
  const [rom, setRom] = useState("");
  const [shabab, setShabab] = useState("");
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 col-6"
  );
  const collapseRef = useRef([]);
  const listRef = useRef(null);

  useEffect(() => {
    getOoredooSuper(false);

    const handleSessionStorageChange = () => {
      document.title = "Ooredoo Shabab | Phone Play";

      if (sessionStorage.ooredoo3g) {
        setG3(jsonParser(sessionStorage.ooredoo3g));
      }
      if (sessionStorage.ooredooMin) {
        setMin(jsonParser(sessionStorage.ooredooMin));
      }
      if (sessionStorage.ooredooCredit) {
        setCredit(jsonParser(sessionStorage.ooredooCredit));
      }
      if (sessionStorage.ooredooCredit) {
        setCredit(jsonParser(sessionStorage.ooredooCredit));
      }
      if (sessionStorage.ooredooRom) {
        setRom(jsonParser(sessionStorage.ooredooRom));
      }
      if (sessionStorage.ooredooSuper) {
        setShabab(jsonParser(sessionStorage.ooredooSuper));
      }
      refreshColumnStyle();
    };
    handleSessionStorageChange();

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    // Clean up the event listener
    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNo, history]);

  useEffect(() => {
    if (init) {
      getOoredooPackages();
    }
    isInit(true);

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRenew, isNotRenew]);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      collapseRef.current.forEach((item) => {
        item?.classList?.remove("show");
      });
    }
  };

  const onTypeClick = (item) => {
    setShabab(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    sessionStorage.ooredooSuper = JSON.stringify(item);
  };

  const onRenewClick = () => {
    setIsRenew(!isRenew);
    setIsNotRenew(false);
    isInit(true);
  };
  const onNotRenewClick = () => {
    setIsNotRenew(!isNotRenew);
    setIsRenew(false);
    isInit(true);
  };
  const getOoredooPackages = () => {
    if (!isRenew && !isNotRenew) {
      getOoredooSuper(false);
    } else if (isRenew) {
      getOoredooSuperRenew();
    } else if (isNotRenew) {
      getOoredooSuperNotRenew();
    }
  };
  const refreshClick = () => {
    getOoredooSuper(true);
  };

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6  card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };
  let topDiv = null;

  return (
    <>
      <div ref={(ref) => (topDiv = ref)}>
        <div className="ooredoo-container">
          <CompaniesNav
            providerId={window?.history?.state?.state?.provider_Id}
            title="Ooredoo"
            logo={logo}
            mobile={mobileNo}
            onRefresh={refreshClick}
            actionWidth="300px"
            backLink="/company/ooredoo/MobileNumer"
          >
            <button
              type="button"
              className={`btn btn-light btn-md btn-renewable main-text border-info border-left px-1 ${
                isRenew ? "selected-btn" : ""
              }`}
              onClick={onRenewClick}
            >
              {translate("Renewable")}
            </button>
            <button
              type="button"
              className={`btn btn-light btn-md btn-renewable main-text border-info border-left px-1 ${
                isNotRenew ? "selected-btn" : ""
              }`}
              onClick={onNotRenewClick}
            >
              {translate("notRenew")}
            </button>
          </CompaniesNav>

          <div className="row">
            <div className="col-12">
              <SubNavOoredoo mobile={mobileNo} />
            </div>
          </div>
        </div>
        <div className="position-relative">
          <Selected
            min={min}
            setMin={setMin}
            g3={g3}
            setg3={setG3}
            credit={credit}
            setCredit={setCredit}
            shabab={shabab}
            setShabab={setShabab}
            setRom={setRom}
            rom={rom}
          />

          <hr
            className="mt-3"
            style={{
              border: "2px solid #42ace3",
              backgroundColor: "#42ace3",
              fontWeight: "bolder",
            }}
          />

          <div
            className={`${isDarkTheme ? "dark-card" : "card"} list-cards p-4`}
            ref={listRef}
          >
            <div className="row">
              {!_.isArray(ooredooSuper) && !loading ? (
                <div className="d-flex justify-content-center mt-3">
                  <h2 className="text-info">
                    {translate("No available bundles")}
                  </h2>
                </div>
              ) : (
                ooredooSuper.map((item, index) => (
                  <div key={index.toString()} className={`${columnStyle} mt-3`}>
                    <div
                      className={`${
                        isDarkTheme ? "dark-card" : "card"
                      } charge-card`}
                    >
                      <a
                        href="#0"
                        style={{ cursor: "pointer" }}
                        data-placement="top"
                        title={item && item.des}
                        onClick={() => onTypeClick(item)}
                      >
                        <div className="position-relative">
                          <img alt={item.id || item.ID} src={item.url}></img>
                          {(item.auto_renew === "True" ||
                            item.auto_renew === "true") && (
                            <Badge text={translate("Renewable")}></Badge>
                          )}
                        </div>
                      </a>
                    </div>
                    <div className={`${isDarkTheme ? "dark-card" : "card"}`}>
                      <div className="text-center" id="headingOne">
                        <h5 className="mb-0 mx-">
                          <a
                            className="link-main details"
                            style={{ fontWeight: "bolder" }}
                            data-toggle="collapse"
                            data-target={"#" + index}
                            aria-expanded="true"
                            aria-controls={index}
                          >
                            <small>{translate("Details")}</small>
                          </a>
                        </h5>
                      </div>

                      <div
                        id={index}
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                        ref={(el) => (collapseRef.current[index] = el)}
                      >
                        <div className="card-body">
                          {dataLanguage(item, "ar-des", "en-des", "he-des")}
                          <br />
                          <p style={{ textAlign: "center" }}>{item.bundleid}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  ooredooSuper: state.companies.ooredooSuper,
  loading: state.companies.loading,
  isDarkTheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, {
  getOoredooSuper,
  getOoredooSuperRenew,
  getOoredooSuperNotRenew,
})(ShababOoredoo);
