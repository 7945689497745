import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CompanySettings from "./CompanySettings";
import JawwalUpdateRoaming from './JawwalUpdateRoaming';
import { connect } from 'react-redux';

const JawwalSettingsContainer = ({ isDarktheme }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab sx={!isDarktheme ? '' : { color: 'white' }} label="Jawwal Settings" {...a11yProps(0)} />
                    <Tab sx={!isDarktheme ? '' : { color: 'white', }} label="Update Roaming Bundles" {...a11yProps(1)} />
                </Tabs>
            </Box>

            {value === 0 && <CompanySettings companyId="Jawwal" />}
            {value === 1 && <JawwalUpdateRoaming />}
        </Box>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
    isDarktheme
});
export default connect(mapStateToProps, null)(JawwalSettingsContainer)