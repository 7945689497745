import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useHistory } from "react-router-dom";
import Message from "../../common/Message";
import TextFieldGroup from "../../common/TextFieldGroup";
import "react-phone-number-input/style.css";
import BackNav from "../../common/BankNav";
import ApiRequest from "../../../actions/ApiRequest";
import { jawwalPlus, jawwalPlusCheck } from "../../../actions/companiesAction";
import { getAllCompanies } from "../../../actions/companiesAction";
import ProvidersCards from "../../../core/containers/ProvidersCards/ProvidersCards.jsx";
import { checkNumberProvider } from "../../../shared/utils/ppUtils.js";
import AmountCard from "../../../core/core-components/AmountCard/AmountCard";
import { useMount } from "shared/hooks/ppHooks";

const InputNoPage = ({
  jawwalPlus,
  isDarktheme,
  jawwalPlusCheck,
  allCompanies,
  loading,
  getAllCompanies,
}) => {
  useMount(() => {
    document.title = "Jawwal | Phone Play";
    jawwalPlus(false);
    getAllCompanies();
  }, []);

  const history = useHistory();
  const passedMobileNumber = history.location.state?.mobileNumber;
  const provider_Id = history.location.state?.provider_id;

  const [value, setValue] = useState(passedMobileNumber || "");
  const [errMsg, setErrMsg] = useState("");
  const [showData, setShowData] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);

  const getdata = async (mobileNumber) => {
    try {
      const { data } = await ApiRequest.post(
        `number_provider?number=${mobileNumber}`
      );
      setCurrentNumber(data);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (passedMobileNumber && passedMobileNumber?.trim()?.length === 10) {
      checkNumberProvider(passedMobileNumber, setCurrentNumber, setShowData);
    }
  }, [passedMobileNumber]);

  const handelChangeNumber = async (event) => {
    setValue(event.target.value);
    if (event.target.value.split("").length === 10) {
      // setShowData(true)
      const response = await getdata(event.target.value);
      response?.pci !== "unknown" && setShowData(true);
      // getdata(event.target.value)
    } else {
      setShowData(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    await jawwalPlusCheck(value);
    if (!value || Number.isNaN() || value.split("").length !== 10) {
      setErrMsg("validNoErrMsg");
    } else {
      setErrMsg("");
      sessionStorage.removeItem("JawwalMin");
      sessionStorage.removeItem("Jawwal3g");
      sessionStorage.removeItem("JawwalCredit");
      sessionStorage.removeItem("JawwalRom");
      history.push(`/company/jawwalCredit/${value}`);
    }
  };

  return (
    <>
      <div>
        <div className="card card-home jawwal">
          <div className={isDarktheme ? "Company_Dark_Nav" : "Company_Nav"}>
            <BackNav title="Jawwal" />
            <img
              className="jawwal_Nav_Logo"
              alt="jawwal"
              src="https://halberdbastion.com/sites/default/files/styles/medium/public/2017-08/Jawwal-palestinian-territories-logo.png?itok=aPDt1f0l"
            />
          </div>
        </div>
        <form className="my-5 text-Continer" onSubmit={onSubmit}>
          {errMsg?.length ? <Message msg={errMsg} /> : null}
          <div className="form-group row">
            <div className="col-12 col-md-7 d-flex">
              <label
                htmlFor="inputEmail3"
                className="col-3 col-form-label"
                style={
                  !isDarktheme
                    ? { fontSize: "1.6rem", minWidth: 160 }
                    : { fontSize: "1.6rem", minWidth: 160, color: "white" }
                }
              >
                {translate("jawwalNo")}
              </label>
              <TextFieldGroup
                name={"mobileNumber"}
                value={value}
                onChange={handelChangeNumber}
                type="number"
                autoFocus={true}
              />
            </div>
            <div className="col-3 col-md-2">
              <button
                style={{ width: "110%", minHeight: "55px" }}
                type="submit"
                className="btn sign-but p-2 w-30"
                onSubmit={onSubmit}
              >
                {translate("next")}
              </button>
            </div>
          </div>
        </form>
        {showData && currentNumber?.provider !== "unknown" ? (
          <div
            className={
              isDarktheme &&
              currentNumber?.provider?.toLowerCase() ===
                translate("jawwal")?.props?.id?.toLowerCase()
                ? "Company_Container_Dark"
                : isDarktheme &&
                  currentNumber?.provider?.toLowerCase() !==
                    translate("jawwal")?.props?.id?.toLowerCase()
                ? "Company_Container_Red"
                : !isDarktheme &&
                  currentNumber?.provider?.toLowerCase() ===
                    translate("jawwal")?.props?.id?.toLowerCase()
                ? "Company_Container_white_green"
                : !isDarktheme &&
                  currentNumber?.provider?.toLowerCase() !==
                    translate()?.props.id?.toLowerCase()
                ? "Company_Container_white_Red"
                : "Company_Container"
            }
          >
            <div
              className="img__wrap"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={currentNumber?.provider_logo}
                alt={currentNumber?.provider}
                className="Company_Logo"
              />
              {currentNumber?.pci_url === "N/A" ? (
                <span style={{ width: 140, height: 60 }}>
                  <AmountCard amount={currentNumber?.amount} />
                </span>
              ) : (
                <img
                  src={currentNumber?.pci_url}
                  alt={currentNumber?.pci}
                  className="Company_Logo"
                />
              )}
              <p
                className={
                  isDarktheme
                    ? "Company_Provider_Dark img__description"
                    : "Company_Provider img__description"
                }
              >
                {currentNumber?.pci}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              <p
                className={
                  isDarktheme ? "Company_Provider_Dark" : "Company_Provider"
                }
              >
                {currentNumber?.provider}
              </p>
              <p
                className={
                  isDarktheme ? "Company_Provider_Dark" : "Company_Provider"
                }
                style={{ paddingRight: "40px" }}
              >
                {currentNumber?.amount}₪
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {showData &&
        currentNumber?.provider !== "unknown" &&
        !(
          currentNumber?.provider?.toLocaleLowerCase() ===
          translate("jawwal").props.id.toLowerCase()
        ) ? (
          <>
            <div className="attention_pay_div_container">
              <p className="Company_Provider_Error">
                {translate("Attention_Pay")}
              </p>
            </div>
          </>
        ) : null}
        <div
          className={
            (value === true && currentNumber !== null) ||
            (!showData &&
              currentNumber?.provider?.toLocaleLowerCase() !==
                translate("jawwal").props.id.toLowerCase()) ||
            currentNumber?.provider_logo === "unknown" ||
            value.length > 10 ||
            (value.length > 0 && value.length < 10)
              ? "Fixed_logo_Container"
              : "logo_Container"
          }
        >
          <ProvidersCards currentProviderId={provider_Id} />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({
  darkTheme: { isDarktheme },
  companies: { allCompanies, loading },
}) => ({
  isDarktheme,
  allCompanies,
  loading,
});
export default connect(mapStateToProps, {
  jawwalPlus,
  jawwalPlusCheck,
  getAllCompanies,
})(InputNoPage);
