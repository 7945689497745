import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import UserTypesDropdown from "../../core-components/UserTypesDropdown/UserTypesDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getDiscounts } from "../../../actions/discountsAction";
import { isArray } from "lodash";
import { getSellers } from "../../../actions/sellerCreditsAction";
import { formatSellerOptionsForSelect } from "../../../shared/utils/formatUtils";
import translate from "../../../i18n/translate";
import { USER_TYPES_WITH_ADMIN } from "../../../shared/constants/constants";
import { useIsAdmin } from "shared/hooks/ppHooks";

const propTypes = {
  disabled: PropTypes.bool,
  enableSelectAll: PropTypes.bool,
  selectedSeller: PropTypes.any,
  onSellerSelected: PropTypes.func,
};

const defaultProps = {};

export default function UserTypesDropdownContainer(props) {
  const { onSellerSelected, disabled, enableSelectAll, selectedSeller } = props;
  const [sellersOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState(selectedSeller);
  const [selectedUserType, setSelectedUserType] = useState();

  const sellers = useSelector((state) => state.credits.sellers);
  const discounts = useSelector((state) => state.discounts.discounts);
  const sellerType = useSelector((state) =>
    state.auth?.user.seller_type?.toLowerCase()
  );
  const allSellers = useRef(null);
  const dispatch = useDispatch();

  const isAdmin = USER_TYPES_WITH_ADMIN.admin.value === sellerType;
  const { isReseller } = useIsAdmin();
  const sellerId = useSelector((state) => state.auth.user?.id);
  useEffect(() => {
    document.title = "Report | Phone Play";
    if (!discounts?.length) {
      dispatch(getDiscounts());
    }
    try {
      dispatch(getSellers(isReseller && sellerId)).then(
        ({ all_sellers: data }) => {
          if (isArray(data)) {
            allSellers.current = data;
            setSellerOptions(formatSellerOptionsForSelect(data));
          }
        }
      );
    } catch (e) {
      console.log(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers?.length]);

  useEffect(
    function () {
      if (!allSellers.current) return;

      if (selectedUserType) {
        let sellersOptionsData = formatSellerOptionsForSelect(
          allSellers.current.filter((v) => v.type === selectedUserType?.value)
        );
        setSellerOptions(sellersOptionsData);
        setSeller(null);
      } else {
        setSellerOptions(formatSellerOptionsForSelect(allSellers.current));
        enableSelectAll &&
          setSeller({ value: "", label: translate("Select all") });
      }
    },
    [selectedUserType, enableSelectAll]
  );

  useEffect(() => {
    if (isAdmin && enableSelectAll) {
      setSeller({ value: "", label: translate("Select all") });
    }
  }, [isAdmin, enableSelectAll]);

  useEffect(() => {
    onSellerSelected && onSellerSelected(seller);
  }, [seller, onSellerSelected]);

  return (
    <React.Fragment>
      <UserTypesDropdown
        disabled={disabled}
        onSelectUserType={(value) => setSelectedUserType(value)}
        selectedUserType={selectedUserType}
        selectedSeller={seller}
        sellersOptions={sellersOptions}
        onSelectSeller={(value) => {
          setSeller(
            value ||
              (enableSelectAll && {
                value: "",
                label: translate("Select all") || null,
              })
          );
        }}
      />
    </React.Fragment>
  );
}

UserTypesDropdownContainer.propTypes = propTypes;
UserTypesDropdownContainer.defaultProps = defaultProps;
