import ApiRequest from "../../actions/ApiRequest.js";
import { END_POINTS, HTTP_METHODS } from "./endPoints.js";
import { LOCAL_STORAGE_ITEMS_ENUM } from "../constants/localStorageItemsEnum.js";
import {
  AZY_ID,
  HOT_ID,
  JAWWAL_ID,
  OOREDOO_ID,
} from "shared/constants/constants.js";

const {
  getJawwalBundles,
  checkNumberProvider,
  getPeletalkProducts,
  getAzyProducts,
  getHotProducts,
  getOoredooBundles,
  getAllSellers,
  carrierCheck,
  nedcoEnquiry,
  selcoEnquiry,
  selcoBuyVoucher,
  syncAccounts,
  nedcoBuyVoucher,
  getPaltelBills,
  verifyPaltelNumber,
  verifyMadaNumber,
  getMadaBills,
  payMadaBill,
  getCoolnetBills,
  payGemzoBill,
  getGemzoBills,
  verifyGemzoNumber,
  verifyCoolnetNumber,
  payCoolnetBill,
  payJawwalBill,
  getJawwalBills,
  payOoredooBill,
  getOoredooBills,
  payPaltelBill,
} = END_POINTS;

export async function getJawwalBundlesApiCaller({
  language,
  refresh,
  mobileNumber,
  bundle = "3g",
}) {
  const lang =
    language || localStorage.getItem(LOCAL_STORAGE_ITEMS_ENUM.langCity);
  return await ApiRequest.post(
    getJawwalBundles.url
      .replace("{mobileNumber}", mobileNumber)
      .replace("{bundle}", bundle)
      .replace("{language}", lang)
      .replace("{refresh}", refresh)
  );
}

export async function checkNumberProviderApiCaller({
  mobileNumber = "",
  carrier = "",
}) {
  return await ApiRequest.post(
    checkNumberProvider.url
      .replace("{mobileNumber}", mobileNumber)
      .replace("{carrier}", carrier)
  );
}

export async function getPeletalkProductsApiCaller({ providerId = "" }) {
  return await ApiRequest.post(
    getPeletalkProducts.url.replace("{providerId}", providerId)
  );
}

export async function getAzyProductsApiCaller({ language }) {
  return await ApiRequest.post(
    getAzyProducts.url.replace("{language}", language)
  );
}

export async function getHotProductsApiCaller({ language }) {
  return await ApiRequest.post(
    getHotProducts.url.replace("{language}", language)
  );
}

export async function getOoredooBundlesApiCaller({ bundle, language }) {
  return await ApiRequest.post(
    getOoredooBundles.url
      .replace("{bundle}", bundle)
      .replace("{language}", language)
  );
}

export async function carrierCheckApiCaller({ mobileNumber }) {
  return await ApiRequest.post(
    carrierCheck.url.replace("{mobileNumber}", mobileNumber)
  );
}

export async function getAllSellersApiCaller({ resellerId = "" }) {
  return await ApiRequest.post(
    getAllSellers.url.replace("{resellerId}", resellerId)
  );
}

export async function syncAccountApiCaller({ sellerId, fix }) {
  return await ApiRequest.post(
    syncAccounts.url.replace("{sellerId}", sellerId).replace("{fix}", fix)
  );
}

export async function nedcoEnquiryApiCaller({ sNumber, amount }) {
  return await ApiRequest.post(
    nedcoEnquiry.url.replace("{s_number}", sNumber).replace("{amount}", amount)
  );
}

export async function selcoEnquiryApiCaller({ sNumber, amount }) {
  return await ApiRequest.post(
    selcoEnquiry.url.replace("{s_number}", sNumber).replace("{amount}", amount)
  );
}
export async function nedcoBuyVoucherApiCaller({ sNumber, amount }) {
  return await ApiRequest.post(
    nedcoBuyVoucher.url
      .replace("{s_number}", sNumber)
      .replace("{amount}", amount)
  );
}
export async function selcoBuyVoucherApiCaller({ sNumber, amount }) {
  return await ApiRequest.post(
    selcoBuyVoucher.url
      .replace("{s_number}", sNumber)
      .replace("{amount}", amount)
  );
}

export async function getPaltelBillsApiCalled({ number, id }) {
  const { data } = await ApiRequest(getPaltelBills.url, {
    method: getPaltelBills.method,
    params: {
      number,
      id,
    },
  });
  return data;
}

export async function getMadaBillsApiCalled({ number, id }) {
  const { data } = await ApiRequest(getMadaBills.url, {
    method: getMadaBills.method,
    params: {
      number,
      id,
    },
  });
  return data;
}
export async function getCoolnetBillsApiCalled({ number, id }) {
  const { data } = await ApiRequest(getCoolnetBills.url, {
    method: getCoolnetBills.method,
    params: {
      number,
      id,
    },
  });
  return data;
}

export async function getGemzoBillsApiCalled({ number, id }) {
  const { data } = await ApiRequest(getGemzoBills.url, {
    method: getGemzoBills.method,
    params: {
      number,
      id,
    },
  });
  return data;
}

export async function getJawwalBillsApiCalled({ number }) {
  const { data } = await ApiRequest(getJawwalBills.url, {
    method: getJawwalBills.method,
    params: {
      number,
    },
  });
  return data;
}

export async function getOoredooBillsApiCalled({ number }) {
  const { data } = await ApiRequest(getOoredooBills.url, {
    method: getOoredooBills.method,
    params: {
      number,
    },
  });
  return data;
}

export async function verifyPaltelNumberApiCalled({ number }) {
  const data = await ApiRequest(verifyPaltelNumber.url, {
    method: verifyPaltelNumber.method,
    params: {
      number,
    },
  });

  return !!data.request.response?.toString();
}

export async function verifyMadaNumberApiCalled({ number }) {
  const data = await ApiRequest(verifyMadaNumber.url, {
    method: verifyMadaNumber.method,
    params: {
      number,
    },
  });

  return !!data.request.response?.toString();
}

export async function verifyCoolnetNumberApiCalled({ number }) {
  const data = await ApiRequest(verifyCoolnetNumber.url, {
    method: verifyCoolnetNumber.method,
    params: {
      number,
    },
  });

  return !!data.request.response?.toString();
}

export async function verifyGemzoNumberApiCalled({ number }) {
  const data = await ApiRequest(verifyGemzoNumber.url, {
    method: verifyGemzoNumber.method,
    params: {
      number,
    },
  });

  return !!data.request.response?.toString();
}

export async function payPaltelBillApiCalled({
  number,
  amount,
  wNumber,
  email,
}) {
  const { data } = await ApiRequest(payPaltelBill.url, {
    method: payPaltelBill.method,
    params: {
      number,
      amount,
      w_number: wNumber,
      email,
    },
  });
  return data;
}
export async function payMadaBillApiCalled({ number, amount, wNumber, email }) {
  const { data } = await ApiRequest(payMadaBill.url, {
    method: payMadaBill.method,
    params: {
      number,
      amount,
      w_number: wNumber,
      email,
    },
  });
  return data;
}

export async function payCoolnetBillApiCalled({
  number,
  amount,
  wNumber,
  email,
}) {
  const { data } = await ApiRequest(payCoolnetBill.url, {
    method: payCoolnetBill.method,
    params: {
      number,
      amount,
      w_number: wNumber,
      email,
    },
  });
  return data;
}
export async function payGemzoBillApiCalled({
  number,
  amount,
  wNumber,
  email,
}) {
  const { data } = await ApiRequest(payGemzoBill.url, {
    method: payGemzoBill.method,
    params: {
      number,
      amount,
      w_number: wNumber,
      email,
    },
  });
  return data;
}

export async function payJawwalBillApiCalled({
  number,
  amount,
  id,
  wNumber,
  email,
}) {
  const { data } = await ApiRequest(payJawwalBill.url, {
    method: payJawwalBill.method,
    params: {
      number,
      id,
      amount,
      w_number: wNumber,
      email,
    },
  });
  return data;
}

export async function payOoredooBillApiCalled({
  number,
  amount,
  wNumber,
  email,
  id,
}) {
  const { data } = await ApiRequest(payOoredooBill.url, {
    method: payOoredooBill.method,
    params: {
      number,
      amount,
      w_number: wNumber,
      email,
      id,
    },
  });
  return data;
}

export function getProviderAPiCaller(providerId = "", bundleType = "") {
  if (PROVIDER_ID_TO_BUNDLE_API_CALLER[providerId]) {
    return PROVIDER_ID_TO_BUNDLE_API_CALLER[providerId];
  }
  return getPeletalkProductsApiCaller;
}

export function makeXMLRequest({ url, method = HTTP_METHODS, body }) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method.post, url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.response);
      } else reject(xhr.response);
    };
    xhr.send(body);
  });
}

const PROVIDER_ID_TO_BUNDLE_API_CALLER = {
  [JAWWAL_ID]: getJawwalBundlesApiCaller,
  [OOREDOO_ID]: getOoredooBundlesApiCaller,
  [HOT_ID]: getHotProductsApiCaller,
  [AZY_ID]: getAzyProductsApiCaller,
};
