import React from 'react'
import PageTitle from '../common/PageTitle'

const JawwalPay = () => {
    return (
        <div>
            <PageTitle title={'Jawwal Pay'}>

            </PageTitle>
        </div>
    )
}

export default JawwalPay