import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PeletalkProducts from './PeletalkProducts';
import PeletalkProviders from './PeletalkProviders';
import translate from "../../../i18n/translate";
import { connect } from 'react-redux';

const PeletalkSettings = ({ isDarktheme }) => {

    return (
        <div>
            <TableContainer >
                <Table style={!isDarktheme ? { width: "400px", marginTop: '100px', border: "2px solid black" } : { width: "400px", marginTop: '100px', border: "2px solid white" }} sx={{ minWidth: 650, margin: '26px auto' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow  >
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center">{translate("Peletalk")}</TableCell>
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center">{translate("Update")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center"><PeletalkProducts value={0} /></TableCell>
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center"><PeletalkProducts value={1} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center"><PeletalkProviders value={0} /></TableCell>
                            <TableCell className={!isDarktheme ? "" : "text-light"} align="center"><PeletalkProviders value={1} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}
export default connect(mapStateToProps, null)(PeletalkSettings)