/** @format */

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import translate from "../../i18n/translate";
import { connect } from "react-redux";

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  label,
  defaultValue,
  link,
  error,
  info,
  type,
  onChange,
  disable,
  style,
  labelProps,
  required,
  min,
  ref,
  maxLength,
  autoFocus = false,
  isDarktheme,
  className,
  width,
}) => {
  const validateNumber = (event) => {
    onChange(event);
  };
  const validateTel = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
    let size = event.target.value.length;
    if (size === 0) {
      event.target.value = "";
    } else if (size < 4) {
      event.target.value = "(" + event.target.value;
    } else if (size < 7) {
      event.target.value =
        "(" +
        event.target.value.substring(0, 3) +
        ") " +
        event.target.value.substring(3, 6);
    } else {
      event.target.value =
        "(" +
        event.target.value.substring(0, 3) +
        ") " +
        event.target.value.substring(3, 6) +
        " - " +
        event.target.value.substring(6, 10);
    }
    onChange(event);
  };
  const placeColor = {
    color: !isDarktheme ? "black" : "white",
  };

  labelProps = labelProps || {};

  return (
    <div
      className={`form-group ${className ?? className}`}
      style={{ maxWidth: "100%" }}
    >
      {label && type !== "hidden" && (
        <label htmlFor={name} {...labelProps}>
          <span style={{ ...placeColor }}>
            {typeof label === "string" ? translate(label) : label}{" "}
            {required && <i className="asterisk">*</i>}
          </span>
          {link && link.url && link.text && (
            <a href={link.url} className="float-right">
              {link.text}
            </a>
          )}
        </label>
      )}

      {type === "number" ? (
        <input
          id={name || placeholder || "inputTextField"}
          type="number"
          pattern="[0-9]+([\.,][0-9]+)?"
          className={classnames(
            "form-control form-control-lg rounded input-field",
            {
              "is-invalid": error,
              disabled: disable,
            }
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={validateNumber}
          disabled={disable}
          style={style}
          required={required}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          maxLength={maxLength}
          min={min}
          ref={ref}
        />
      ) : type === "tel" ? (
        <input
          id={name || placeholder || "inputTextField"}
          type="text"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          className={classnames(
            "form-control form-control-lg rounded input-field",
            {
              "is-invalid": error,
              disabled: disable,
            }
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={validateTel}
          maxLength="16"
          disabled={disable}
          style={style}
          required={required}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          min={min}
          ref={ref}
        />
      ) : (
        <input
          id={name || placeholder || "inputTextField"}
          type={type}
          className={classnames(
            "form-control form-control-lg rounded input-field",
            {
              "is-invalid": error,
            }
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disable}
          autoFocus={autoFocus}
          style={style}
          required={required}
          defaultValue={defaultValue}
          ref={ref}
        />
      )}

      {info && <small className="form-text text-muted">{info}</small>}
      {error && (
        <small className="form-text text-left text-danger">
          {typeof error === "object" ? "" : translate(error)}
        </small>
      )}
      {error && <div className="invalid-feedback"></div>}
    </div>
  );
};
TextFieldGroup.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.any,
  link: PropTypes.object,
  error: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disable: PropTypes.string,
  autoFocus: PropTypes.bool,
};
TextFieldGroup.defaultProps = {
  type: "text",
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(TextFieldGroup);
