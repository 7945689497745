import * as React from "react";
import Switch from "@mui/material/Switch";

export default function ControlledSwitches({ Change, check }) {
  return (
    <Switch
      checked={check}
      onChange={Change}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
