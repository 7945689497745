import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getAllSellersApiCaller } from "shared/endPoints/apiCallers";
import HTable from "material-htable2";
import { intlTranselate } from "shared/utils/ppUtils";
import { Box } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ApiRequest from "../../../actions/ApiRequest";
const propTypes = {};

const defaultProps = {};

export default function Dealers(props) {
  const [allSellers, setAllSellers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selected, setSelected] = useState([]);

  async function getSellers() {
    setLoading(true);
    try {
      const {
        data: { all_sellers },
      } = await getAllSellersApiCaller({ resellerId: "" });
      setAllSellers(all_sellers || []);
    } catch (error) {}
    setLoading(false);
  }

  useEffect(() => {
    getSellers();
  }, []);

  function tableData() {
    const returnVal = { heads: [], rows: [] };

    returnVal.heads = [
      {
        component: intlTranselate("seller_id"),
      },
      {
        component: intlTranselate("seller_name"),
      },
      {
        component: intlTranselate("Mobile number"),
      },
    ];

    allSellers.forEach((seller) => {
      const cells = [
        {
          component: <Typography>{seller.seller_id}</Typography>,
        },
        {
          component: <Typography>{seller.name}</Typography>,
        },
        {
          component: <Typography>{seller.mobile_number}</Typography>,
        },
      ];
      returnVal.rows.push({
        id: seller.seller_id,
        cells,
      });
    });

    return returnVal;
  }

  async function onSendMessage(e) {
    e.preventDefault();

    const msg = e.target.message?.value;

    const sendMessage = async (number) => {
      try {
        await ApiRequest.post(`/send_whatsapp?mobile_number=${number}`, {
          msg: msg,
        });
      } catch (error) {}
    };

    for (const id of selected) {
      const mobile_number = allSellers.find(
        (s) => s.seller_id === id
      )?.mobile_number;
      await sendMessage(mobile_number);
    }
  }

  function renderDialog() {
    return (
      <Dialog
        fullWidth
        open={showMessageModal}
        onClose={() => setShowMessageModal(false)}
      >
        <DialogTitle>New Message</DialogTitle>
        <form onSubmit={onSendMessage}>
          <DialogContent>
            <TextField
              multiline
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              name="message"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowMessageModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Send to selected {selected.length}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  const { heads, rows } = tableData();
  return (
    <React.Fragment>
      {renderDialog()}
      <Box paddingY={2}>
        <Stack direction={"row"} gap={2} paddingBottom={2}>
          <Button variant="contained" onClick={() => setShowMessageModal(true)}>
            New Message
          </Button>
        </Stack>
        <HTable
          selectable
          onSelectAllClick={(_, x, selectedIds) => {
            setSelected(selectedIds);
          }}
          onRowSelected={(_, selectedIds) => {
            setSelected(selectedIds);
          }}
          isLoading={isLoading}
          rows={rows}
          heads={heads}
        />
      </Box>
    </React.Fragment>
  );
}

Dealers.propTypes = propTypes;
Dealers.defaultProps = defaultProps;
