import React from 'react'
import translate from "../../../i18n/translate";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

const PointsHeader = ({ children, isDarktheme }) => {
    const history = useHistory().location.pathname;
    return (
        <>
            <div className={!isDarktheme ? "card-home position-relative" : "dark-card-home position-relative"}>
                <h3 className="header-text p-4">{translate("points")}</h3>
                <div>
                    {children}
                </div>
            </div>
            <div className={!isDarktheme ? "card mt-2" : "dark-card mt-2"}>
                <div className="row mb-2 mt-2">
                    <div className="col-sm-1"></div>
                    <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label m-1"
                    >
                        <Link
                            to="/points"
                            className={`semi-nav ${history === "/points" && "active-semi"
                                } m-4`}
                        >
                            {translate("Points report")}
                        </Link>
                    </label>
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                        <Link
                            to="/convert_points"
                            className={`semi-nav ${history === "/convert_points" && "active-semi"
                                } m-0`}
                        >
                            {translate("Convert points")}
                        </Link>
                    </label>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}
export default connect(mapStateToProps, null)(PointsHeader)