// src/components/ContactOptions.js

import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ApiRequest from "../../actions/ApiRequest";
import Toast from "components/common/Toast";

const ContactOptions = ({ sellerId }) => {
  const [contactMethod, setContactMethod] = useState("whatsapp");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(contactMethod);
    setIsLoading(true);
    try {
      const res = await ApiRequest.post(
        `send_seller_info?type=${contactMethod}&seller_id=${sellerId}`
      );
      if (res.data.status.toLowerCase() === "failed") {
        throw new Error(res.data.reason);
      }
      Toast.fire({
        title: "information Sent Successfully!",
        icon: "success",
      });
    } catch (error) {
      Toast.fire({
        title: error.message,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        maxWidth: 400,
        mx: "auto",
      }}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">Choose Contact Method</FormLabel>
        <RadioGroup
          aria-label="contact method"
          name="contactMethod"
          value={contactMethod}
          onChange={(e) => setContactMethod(e.target.value)}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormControlLabel
            value="whatsapp"
            control={<Radio />}
            label="WhatsApp"
          />
          <FormControlLabel value="sms" control={<Radio />} label="SMS" />
        </RadioGroup>
      </FormControl>
      <Button variant="contained" type="submit" sx={{ mt: 2 }} loading>
        Send
      </Button>
    </Box>
  );
};

export default ContactOptions;
