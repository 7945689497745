import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Grid } from "@mui/material";
import { conditionalReturn } from "shared/utils/utils";
import { intlTranselate } from "shared/utils/ppUtils";
import TextFieldGroup from "components/common/TextFieldGroup";
import { makeStyles } from "@mui/styles";
import sharedStyles from "shared/styles/sharedStyles";

const propTypes = {};

const defaultProps = {};

const useStyle = makeStyles({ ...sharedStyles() });

export default function TypeNumber({
  onSubmit,
  isLoading,
  value,
  setValue,
  isValid,
  setIsValid,
  getNumberOnSubmit,
  submitButtonText,
  ...rest
}) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(true);
  const classes = useStyle();

  function onChange(e) {
    setValue?.(e.target.value) || setMobileNumber(e.target.value);
  }

  function onFormSubmit(e) {
    if (!getNumberOnSubmit) return onSubmit(e);

    e.preventDefault();
    onSubmit?.(mobileNumber);
  }
  return (
    <React.Fragment>
      <form onSubmit={onFormSubmit}>
        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item height={"100%"}>
            <label
              onClick={() => {
                setIsValid?.(true) || setIsValidNumber(true);
              }}
              htmlFor="mobileNumber"
            >
              {intlTranselate("jawwalNo")}
            </label>
            <TextFieldGroup
              id="add-balance-number-input"
              value={(!!value && value) || mobileNumber}
              onChange={onChange}
              min={10}
              type="number"
              autoFocus={true}
              name={"mobileNumber"}
            />
            {conditionalReturn(
              !isValidNumber || isValid,
              <label className={classes.errorMsg}>* {intlTranselate({ id: "Enter valid number" })} *</label>
            )}
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} item paddingTop={"10px"}>
            <Button
              sx={{ marginTop: "22px" }}
              variant={"contained"}
              startIcon={(isLoading && <CircularProgress size={20} />) || undefined}
              disabled={isLoading}
              type={"submit"}
              id="add-balance-next-btn"
            >
              {submitButtonText ?? intlTranselate("next")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

TypeNumber.propTypes = propTypes;
TypeNumber.defaultProps = defaultProps;
