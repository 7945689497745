import {
  ADMIN_Edit_REPORT,
  ADMIN_GET_REPORT,
  ADMIN_GET_REPORT_LOADING,
} from "./types";
import Notiflix from "notiflix";
import translate from "../i18n/translate";

import ApiRequest from "./ApiRequest";
import { logoutUser } from "./userAction";

export const getReportData = (params) => async (dispatch) => {
  dispatch(setReportDataLoading(true));
  try {
    const { data } = await ApiRequest.post(
      `admin_reports?from_date=${params.dateFrom}&to_date=${params.dateTo}&report_name=${params.report_name}&operation=get`
    );
    // console.log('data', data.status, data.reason)
    if (data?.reason === "token exipred!") {
      dispatch(logoutUser());
    }
    dispatch(setReportData(data));
    dispatch(setReportDataLoading(false));
  } catch (err) {
    dispatch(setReportDataLoading(false));
    Notiflix.Report.failure(
      `${translate("Some Thing Went Wrong !")}`,
      "",
      "Close"
    );
  }
};

export const setReportData = (reports) => {
  return {
    type: ADMIN_GET_REPORT,
    payload: reports,
  };
};

export const setReportDataLoading = (boolean) => {
  return {
    type: ADMIN_GET_REPORT_LOADING,
    payload: boolean,
  };
};
export const EditReportData =
  (params, editIndex, selectValue, editValue) => async (dispatch) => {
    dispatch(setReportDataLoading(true));
    try {
      const { data } = await ApiRequest.post(
        `admin_reports?from_date=${params.dateFrom}&to_date=${params.dateTo}&report_name=${params.report_name}&setting_name=${selectValue}&value=${editValue}&index=${editIndex}&operation=update`
      );
      dispatch(setEditReportData(data));
      dispatch(setReportDataLoading(false));
    } catch (err) {
      dispatch(setReportDataLoading(false));
      Notiflix.Report.failure(
        `${translate("Some Thing Went Wrong !")}`,
        "",
        "Close"
      );
    }
  };

export const setEditReportData = (reports) => {
  return {
    type: ADMIN_Edit_REPORT,
    payload: reports,
  };
};

export const setSettingsData = (reportNumber) => {
  return {
    type: "SET_SETTINGS_REPORT",
    payload: reportNumber,
  };
};
