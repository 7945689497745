import {
    SET_NOTIFICATIONS,
    GET_NOTIFICATIONS_LOADING,
    SINGLE_NOTIFICATION_LOADING
} from "../actions/types";

const initialState = {
    list: [],
    notificationsLoading: true,
    singleNotificationLoading: true,
    unseenNotifications: 0
};

const notificationReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                list: payload,
                unseenNotifications: payload.filter((notification) => notification.view === "0").length
            };
        case GET_NOTIFICATIONS_LOADING:
            return {
                ...state,
                notificationsLoading: payload,
            }
        case SINGLE_NOTIFICATION_LOADING:
            return {
                ...state,
                singleNotificationLoading: payload,
            }
        default:
            return state;
    }
}

export default notificationReducer
