import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
import { useHistory } from "react-router-dom";
import GetSettings from "./IsrealCompanies/IsraelSettings";

const OoredooSettings = () => {
  const history = useHistory();
  const [ooredooAccounts, setOoredooAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    fetchOoredooAccounts();
  }, []);

  const fetchOoredooAccounts = async () => {
    try {
      const response = await ApiRequest.post(
        `get_topup_accounts?carrier=ooredoo`
      );
      const data = response.data;
      setOoredooAccounts(data);
    } catch (error) {
      Toast.fire({
        title: "Something Went Wrong!",
        icon: "error",
      });
    }
  };

  const handleToggleAccount = (account) => {
    setIsLoading(true);
    ApiRequest.post(
      `update_settings?user_name=${account.topup_account}&new_value=${
        account.enable === "true" ? "false" : "true"
      }&id=${1}&carrier=ooredoo`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          throw new Error(res.data.message);
        }
        const updatedAccounts = ooredooAccounts.map((acc) => {
          if (acc.ccid === account.ccid) {
            return { ...acc, enable: acc.enable === "true" ? "false" : "true" };
          }
          return acc;
        });
        setOoredooAccounts(updatedAccounts);
        setIsChanged(true);
      })
      .catch((err) => {
        Toast.fire({
          title: "Something Went Wrong!",
          icon: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isLoading && isChanged) {
    Toast.fire({
      title: "Settings Updated Successfully!",
      icon: "success",
    });
    setIsChanged(false);
  }
  const onClickOoredooAccount = async (account) => {
    const res = await ApiRequest.post(
      `edit_topup_account?operation=get&carrier=ooredoo&user_name=${account.topup_account}`
    );
    history.push({
      pathname: `/edit-account`,
      state: {
        data: res.data[0],
        carrier: "ooredoo",
        active: account.enable === "true",
      },
    });
  };
  return (
    <GetSettings carrier="ooredoo">
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {ooredooAccounts?.map((account) => (
          <li
            key={account.ccid}
            style={{
              display: "flex",
              alignItems: "center",

              justifyContent: "flex-start",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={account.enable === "true" ? true : false}
                  onChange={() => handleToggleAccount(account)}
                />
              }
            />
            <p
              style={{
                marginLeft: -20,
                cursor: "pointer",
              }}
              onClick={() => onClickOoredooAccount(account)}
            >
              {account.topup_account}
            </p>
          </li>
        ))}
      </ul>
    </GetSettings>
  );
};
export default OoredooSettings;
