import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Notiflix from "notiflix";
import Modal from "components/common/Modal";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { useHistory } from "react-router-dom";
import translate from "i18n/translate";

const GeneralPayModal = ({
  number,
  selectedBill,
  isDisabled,
  clearSelections,
  totalAmount,
  apiCall,
  title = "Pay",
  onSubmitSuccess,
  showEditAmount = false,
  extraFields = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("970");
  const [wNumber, setWNumber] = useState(number);
  const [email, setEmail] = useState(null);
  const [changeText, setChangeText] = useState(totalAmount);
  const [isEditable, setIsEditable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setChangeText(totalAmount);
  }, [totalAmount]);

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const { mutateAsync: payBill, isLoading } = useMutation(apiCall, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        Notiflix.Notify.failure(res?.reason || "Something went wrong");
        return;
      }
      setIsOpen(false);
      setWNumber(null);
      setEmail(null);
      Notiflix.Notify.success(`We are processing your bill`, "", "Close");
      clearSelections();
      onSubmitSuccess && onSubmitSuccess(res);
      history.push("/pay_bills");
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });

  const handleSaveClick = () => {
    setIsEditable(false);
    if (changeText.trim()?.length === 0) {
      setChangeText(totalAmount);
    }
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    setChangeText(totalAmount);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const whatsappNumber = `${countryCode}${wNumber}`;
    const IDs = selectedBill.map((element) => element?.id).join(",");
    const payload = {
      number,
      amount: changeText,
      wNumber: wNumber ? whatsappNumber : "N/A",
      email: email ?? "N/A",
      id: IDs,
    };

    for (const field of extraFields) {
      payload[field.name] = field.value;
    }

    await payBill(payload);
  };

  return (
    <div id="general-pay-modal-div">
      <Button
        onClick={() => {
          !isDisabled && setIsOpen(true);
        }}
        id="general-pay-modal-pay-btn"
        disabled={isDisabled}
        variant="contained"
      >
        {title}
      </Button>
      {isOpen && (
        <Modal
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          title={title}
          width={"40vw"}
          id="general-pay-modal"
          onSuccess={onSubmit}
          onSuccessLoading={isLoading}
          onSuccessDisable={isEditable}
        >
          <form className="phone-form" id="general-pay-modal-form">
            <div className="d-flex w-100 justify-content-around mb-4">
              <div
                className="bg-light p-3 rounded"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 className="m-0">
                  {translate("Number")}: {number}
                </h6>
              </div>
              <div className="bg-light p-3 rounded">
                {!isEditable ? (
                  <div
                    className="bg-light p-3 rounded d-flex  "
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <h6 className="m-0">
                      {translate("money")}: {changeText} ILS
                    </h6>
                    {showEditAmount && (
                      <IconButton
                        onClick={() => setIsEditable(true)}
                        size="small"
                      >
                        <EditIcon color="success" /> {translate("Edit")}
                      </IconButton>
                    )}
                  </div>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <span> {translate("money")}: </span>
                    <TextField
                      value={changeText}
                      onChange={(e) => setChangeText(e.target.value)}
                      variant="outlined"
                      size="small"
                      style={{ width: 100 }}
                    />
                    <IconButton onClick={handleSaveClick} size="small">
                      <SaveIcon sx={{ ml: "2px", mr: "2px" }} color="info" />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} size="small">
                      <CancelIcon sx={{ ml: "2px", mr: "2px" }} color="error" />
                    </IconButton>
                  </Box>
                )}
              </div>
            </div>
            <Box sx={{ display: "flex", width: "80%" }}>
              <Select
                value={countryCode}
                onChange={handleCountryCodeChange}
                sx={{ width: "25%" }}
              >
                <MenuItem value="972">+972 </MenuItem>
                <MenuItem value="970">+970 </MenuItem>
                {/* Add more country codes and names as needed */}
              </Select>
              <TextField
                value={wNumber}
                type="number"
                onChange={(e) => setWNumber(e.target.value)}
                label={translate("Whatsapp number")}
                id="phone-number-input-field"
                sx={{ width: "75%" }}
              />
            </Box>
            <Box sx={{ width: "80%", mt: "16px" }}>
              <TextField
                value={email}
                type="email"
                id="general-pay-modal-email-input-field"
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                sx={{ width: "100%" }}
              />
            </Box>
            {extraFields.map((field, index) => (
              <Box sx={{ width: "80%", mt: "16px" }} key={index}>
                <TextField
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  label={field.label}
                  type={field.type}
                  sx={{ width: "100%" }}
                />
              </Box>
            ))}
          </form>
        </Modal>
      )}
    </div>
  );
};

export default GeneralPayModal;
