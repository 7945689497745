import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import GameCard from "./GameCard";
import Spinner from "../ui/spinner/Spinner";

import translate from "../../i18n/translate";
import { getGamingCards } from "../../actions/gameAction";
import { refreshColumnStyle } from "./reusableFunctions";

const jwakerGameData = {
  id: "jwaker",
  company_name: "Jwaker",
  url: "https://static.twofour54.com/wp-content/uploads/sites/5/2017/02/jawaker-logo_tcm12-16701.png",
  denominations_db_name: "jwaker",
  mintroute_brand_id: "jwakerBrandId123",
};

const Gaming = ({ getGamingCards, gamesArray, loading, isDarktheme }) => {
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 card-md"
  );

  useEffect(() => {
    document.title = "Gaming | Phone Play";
    refreshColumnStyle(setColumnStyle);
    getGamingCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("Gaming")}</h1>
        </div>
      </div>
      <div className="row">
        {gamesArray?.map((game) => (
          <GameCard key={game.id} game={game} columnStyle={columnStyle} />
        ))}
        <GameCard
          key={jwakerGameData.id}
          game={jwakerGameData}
          columnStyle={columnStyle}
        />
      </div>
      {loading && !gamesArray && <Spinner />}
    </div>
  );
};

const mapStateToProps = ({
  games: { gamesArray, loading },
  darkTheme: { isDarktheme },
}) => {
  return {
    gamesArray,
    loading,
    isDarktheme,
  };
};

export default connect(mapStateToProps, { getGamingCards })(Gaming);
