import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useHistory, useLocation } from "react-router-dom";
import SubNav from "./SubNav";
import { getJawwalRom } from "../../../actions/companiesAction";
import Spinner from "../../ui/spinner/Spinner";
import Selected from "./Selected";
import { isArray } from "lodash";
import { dataLanguage } from "../../../i18n/translateRequest";
import CompaniesNav from "../../common/CompaniesNav";
import logo from "../../../assests/images/bundles/jawwal/banner.jpg";
import CompaniesSkeleton from "../../homePage/CompaniesSkeleton";
import { jsonParser } from "shared/utils/ppUtils";
import { IntlProvider } from "react-intl";

const countries = {
  en: {
    Turkey: "Turkey",
    Saudi: "Saudi",
    local: "Local",
    "International roaming packages": "International roaming packages",
    Jordan: "Jordan",
    Qatar: "Qatar",
    china: "China",
    undefined: "Undefined",
  },
  ar: {
    Turkey: "تركيا",
    Saudi: "السعودية",
    local: "محلي",
    "International roaming packages": "باقات التجوال الدولي",
    Jordan: "الأردن",
    Qatar: "قطر",
    china: "الصين",
    undefined: "غير محدد",
  },
  he: {
    Turkey: "טורקיה",
    Saudi: "ערב הסעודית",
    local: "מקומי",
    "International roaming packages": "חבילות רומינג בינלאומיות",
    Jordan: "ירדן",
    Qatar: "קטאר",
    china: "סין",
    undefined: "לא מוגדר",
  },
};

const JawwalRom = ({
  getJawwalRom,
  JawwalRomLoading,
  jawwalRom,
  jawwalRomTabs,
  isDarktheme,
}) => {
  const history = useHistory().location.pathname;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [typeRoming, setTypeRoming] = useState("Jordan");
  const [selected, setSelected] = useState("");
  const [jawwalMin, setJawwalMin] = useState("");
  const [credit, setCredit] = useState("");
  const [jawwal3g, setJawwal3g] = useState("");
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 col-6"
  );
  const collapseRef = useRef([]);
  const listRef = useRef(null);
  const [plus, setPlus] = useState("");

  let mobileNo =
    history.split("/")[3].slice(0, 3) +
    "-" +
    history.split("/")[3].slice(3, 6) +
    "-" +
    history.split("/")[3].slice(6, 10);
  let routerMobileNo = history.split("/")[3];

  useEffect(() => {
    const number = params.get("number");
    const mobileNumber = number || mobileNo;
    getJawwalRom(mobileNumber, false);
    const handleSessionStorageChange = () => {
      document.title = "Rom Jawwal | Phone Play";
      if (sessionStorage.Jawwal3g) {
        setJawwal3g(jsonParser(sessionStorage.Jawwal3g));
      }
      if (sessionStorage.JawwalMin) {
        setJawwalMin(jsonParser(sessionStorage.JawwalMin));
      }
      if (sessionStorage.JawwalCredit) {
        setCredit(jsonParser(sessionStorage.JawwalCredit));
      }
      if (sessionStorage.JawwalRom) {
        setSelected(jsonParser(sessionStorage.JawwalRom));
      }
      if (sessionStorage.JawwalPlus) {
        setPlus(jsonParser(sessionStorage.JawwalPlus));
      }
      refreshColumnStyle();
    };
    handleSessionStorageChange();

    document.addEventListener("click", handleClickOutside);
    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNo, history]);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      collapseRef.current.forEach((item) => {
        item?.classList?.remove("show");
      });
    }
  };

  const selectTypeClick = (type) => {
    setTypeRoming(type);
  };
  const onTypeClick = (item) => {
    sessionStorage.JawwalRom = JSON.stringify(item);
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });

    setSelected(item);
  };
  const refreshClick = () => {
    getJawwalRom(mobileNo, true);
  };
  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6 card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };

  let topDiv = null;
  let language = localStorage.getItem("langCity");

  return (
    <div ref={(ref) => (topDiv = ref)}>
      <div>
        <CompaniesNav
          providerId={window?.history?.state?.state?.provider_Id}
          title="Jawwal"
          logo={logo}
          mobile={routerMobileNo}
          onRefresh={refreshClick}
          actionWidth="130px"
          backLink="/company/jawwalNo"
        />
        <div className="row">
          <div className="col-12">
            <SubNav mobile={history.split("/")[3]} />
          </div>
        </div>
      </div>
      <div className=" position-relative">
        <Selected
          min={jawwalMin}
          setMin={setJawwalMin}
          g3={jawwal3g}
          setg3={setJawwal3g}
          credit={credit}
          setCredit={setCredit}
          rom={selected}
          setRom={setSelected}
          setPlus={setPlus}
          plus={plus}
        />

        <hr
          className="mt-3"
          style={{
            border: "2px solid #42ace3",
            backgroundColor: "#42ace3",
            fontWeight: "bolder",
          }}
        />

        <div className={`${isDarktheme ? "dark-card" : "card"} mb-2`}>
          <div
            className="d-flex justify-content-between mx-3 type-rom"
            style={{ fontFamily: "initial", fontSize: "2rem" }}
          >
            <IntlProvider locale={language} messages={countries[language]}>
              {jawwalRomTabs.map(
                (rom, i) =>
                  rom && (
                    <button
                      key={i}
                      className={`btn ${
                        isDarktheme ? "btn-dark" : "btn-light"
                      } btn-light main-text mx-1 ${
                        typeRoming === rom ? "rom-selected" : null
                      }`}
                      onClick={() => selectTypeClick(rom)}
                    >
                      {translate(rom)}
                    </button>
                  )
              )}
            </IntlProvider>
            <button
              className={`btn ${
                isDarktheme ? "btn-dark" : "btn-light"
              } btn-light main-text mx-1 ${
                typeRoming === "Others" ? "rom-selected" : null
              }`}
              onClick={() => selectTypeClick("Others")}
            >
              {translate("Others")}
            </button>
          </div>
        </div>
        <div
          className={`${isDarktheme ? "dark-card" : "card"} list-cards`}
          ref={listRef}
        >
          <div className="row mb-5">
            {JawwalRomLoading &&
              [...Array(3)].map((e, i) => (
                <CompaniesSkeleton
                  height={130}
                  columnStyle={columnStyle}
                  key={i}
                />
              ))}
            {!isArray(
              jawwalRom.filter(({ country }) => country === typeRoming)
            ) && !JawwalRomLoading ? (
              <div className="d-flex justify-content-center mt-3">
                <h1 className="warning-text">
                  {translate("No available bundles")}
                </h1>
              </div>
            ) : (
              jawwalRom
                .filter(({ country }) => country === typeRoming)
                .map((item, index) => (
                  <div key={index.toString()} className={`${columnStyle} mt-4`}>
                    <div className={`card charge-card`}>
                      <a
                        style={{ cursor: "pointer" }}
                        data-placement="top"
                        title={item && item.des}
                        onClick={() => onTypeClick(item)}
                      >
                        <div className="frame-card">
                          <img
                            alt={item.des}
                            src={item.url}
                            // width="100px"
                            // height="50px"
                          ></img>
                        </div>
                      </a>
                    </div>
                    <div
                      className={`${
                        isDarktheme ? "dark-card" : "card"
                      } filtter-rom filtter-active`}
                    >
                      <div className="text-center" id="headingOne">
                        <h5 className="mb-0">
                          <a
                            href="#0"
                            className="link-main details"
                            style={{ fontWeight: "bolder" }}
                            data-toggle="collapse"
                            data-target={"#" + index}
                            aria-expanded="true"
                            aria-controls={index}
                          >
                            <small>{translate("Details")}</small>{" "}
                          </a>
                        </h5>
                      </div>
                      <div
                        id={index}
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                        ref={(el) => (collapseRef.current[index] = el)}
                      >
                        <div className="card-body">
                          {dataLanguage(item, "ar_des", "en_des", "he_des")}
                          <br />
                          <p style={{ textAlign: "center" }}>
                            {item.id || item.ID}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </div>
    // </IntlProvider>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  jawwalRom: state.companies.jawwalRom,
  JawwalRomLoading: state.companies.JawwalRomLoading,
  jawwalRomTabs: state.companies.jawwalRomTabs,
  isDarktheme: state.darkTheme.isDarktheme,
});
export default connect(mapStateToProps, { getJawwalRom })(JawwalRom);
