// noinspection JSCheckFunctionSignatures
import PropTypes from "prop-types";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import ApiRequest from "../../../../actions/ApiRequest.js";
import { isSuccessStatus } from "../../../../shared/utils/ppUtils.js";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Notiflix from "notiflix";
import Toast from "../../../common/Toast.js";
import { conditionalReturn } from "../../../../shared/utils/utils.js";
import Stepper from "../../../common/Stepper.js";
import { useObjectState } from "shared/hooks";
import ConformationModal from "core/core-components/ConformationModal/ConformationModal.jsx";
import { END_POINTS } from "shared/endPoints/endPoints.js";
import { getSellerDiscountApiCaller } from "actions/discountsAction.js";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import CardsWithPrice from "./CardsWithPrice.jsx";
import PlayerInformation from "./PlayerInformation.jsx";
import ConfirmationMessage from "./ConfirmationMessage.jsx";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const usePubgChargeStyle = makeStyles(() => ({
  disabledGridRoot: {},
  rtl: {
    direction: "rtl",
    flexDirection: "row-reverse",
    "& div": {
      direction: "rtl",
    },
    "& .MuiSvgIcon-root": {
      transform: "none !important",
    },
    "& body.arabic .MuiSvgIcon-root": {
      transform: "none !important",
    },
  },
  nextBtn: {
    "& .css-1d6wzja-MuiButton-startIcon": {
      marginRight: "0px !important",
    },
  },
}));
export default function PubgCharge(props) {
  const { gameName } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [finishedSteps, setFinishedSteps] = React.useState(new Set());
  const [isLoading, setIsLoading] = useObjectState({
    isSearching: false,
    nextStep: false,
    gameCards: false,
    isSubmitting: false,
  });
  const [isDisabled, setIsDisabled] = useObjectState({
    nextBtn: true,
    backBtn: false,
    resetBtn: false,
  });
  const [toggle, setToggle] = useObjectState({ showConfirmationModal: false });
  const [denominationCards, setDenominationCards] = useState([]);
  const [playerId, setPlayerId] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [selectedCardsIds, setSelectedCardsIds] = useState({});

  const intl = useIntl();
  const classes = usePubgChargeStyle();
  const [isSuccessCharging, setIsSuccessCharging] = useState(false);
  const sellerId = useSelector((state) => state.auth.user?.id);
  const { data, isLoading: isLoadingDiscounts } = useQuery(
    END_POINTS.getSellerDiscount.key,
    () => getSellerDiscountApiCaller({ sellerId })
  );
  const sellerDiscount = data?.data?.find(
    (discount) => discount?.product === "pubg"
  )?.discount;
  const steps = ["UC", "player_id", "done"];
  const history = useHistory();
  const { state } = history.location;
  useEffect(() => {
    if (state?.number) {
      setPlayerId(state.number);
    }
  }, [state]);

  const handleNext = () => {
    let currentFinished = new Set(finishedSteps.values() || []).add(activeStep);
    setFinishedSteps(currentFinished);
    if (activeStep === steps.length - 1) {
      onNextClick();
      return;
    }
    if (activeStep === 0 && state?.number) onNextClick();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    let currentFinished = new Set(finishedSteps.values() || []);
    currentFinished.delete(activeStep);

    setFinishedSteps(currentFinished);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
    setFinishedSteps(new Set());
    setPlayerName("");
    setPlayerId("");
    setSelectedCardsIds({});
  };
  useEffect(() => {
    setIsLoading({ gameCards: true });
    (async function () {
      try {
        const { data } = await ApiRequest.post(
          `/get_denominations?brand_name=${gameName}`
        );
        setDenominationCards(data);
      } catch (e) {
        console.log("ERROR", e);
      } finally {
        setIsLoading({ gameCards: false });
      }
    })();
  }, []);

  useEffect(() => {
    let isDisabled = false;
    if (activeStep === 0) {
      isDisabled =
        !Object.values(selectedCardsIds).filter((x) => x)?.length ||
        isLoadingDiscounts;
    }
    if (activeStep === 1) {
      isDisabled = !playerName || !playerName?.length || isLoadingDiscounts;
    }
    setIsDisabled({ nextBtn: isDisabled });

    setIsDisabled({ nextBtn: isDisabled });
  }, [
    playerName,
    setIsDisabled,
    selectedCardsIds,
    isLoadingDiscounts,
    activeStep,
  ]);

  function getSelectedDenominationCards() {
    return Object.entries(selectedCardsIds)
      .filter(([_, quantity]) => quantity > 0)
      .map(([id, quantity]) => {
        const card = getDenominationCards(id);
        return {
          ...card,
          quantity,
        };
      });
  }

  function getDenominationCards(id) {
    return denominationCards.find((x) => x.id === id);
  }

  function getTotalUCPrice() {
    const val = getSelectedDenominationCards().reduce(
      (acc, curr) => {
        const uc = curr.ean.split("UC")[0];
        return {
          customer_price:
            acc.customer_price + curr.customer_price * curr.quantity, // Multiply by quantity
          totalUC: acc.totalUC + Number(uc) * curr.quantity, // Multiply by quantity
          cost: acc.cost + curr.cost * curr.quantity, // Multiply by quantity
        };
      },
      { customer_price: 0, totalUC: 0, cost: 0 }
    );
    const costAfterDiscount = (val.cost * (100 - Number(sellerDiscount))) / 100;
    return {
      ...val,
      cost: parseFloat(costAfterDiscount?.toString())?.toFixed(1),
    };
  }

  const { customer_price, totalUC, cost } = getTotalUCPrice();
  const stepsComps = {
    0: (
      <CardsWithPrice
        customer_price={customer_price}
        totalUC={totalUC}
        cost={cost}
        sellerDiscount={sellerDiscount}
        denominationCards={denominationCards}
        selectedCardsIds={selectedCardsIds}
        setSelectedCardsIds={setSelectedCardsIds}
      />
    ),
    1: (
      <PlayerInformation
        playerName={playerName}
        playerId={playerId}
        setPlayerId={setPlayerId}
        isLoading={isLoading}
        onGetPlayerName={onGetPlayerName}
        classes={classes}
      />
    ),
    2: (
      <ConfirmationMessage
        playerId={playerId}
        playerName={playerName}
        totalUC={totalUC}
        cost={cost}
      />
    ),
  };

  async function onGetPlayerName() {
    setIsLoading({ isSearching: true });
    setPlayerName("");
    try {
      const { data } = await ApiRequest.post(
        `/pubg_get_player_name?player_id=${playerId}`
      );
      if (isSuccessStatus(data) && !data?.player_name?.includes("ID_1973")) {
        setPlayerName(data?.player_name);
      } else {
        Notiflix.Notify.failure(
          data?.reason || intl.formatMessage({ id: "wrong_player_id" })
        );
      }
    } catch (e) {
      Notiflix.Notify.failure("Something went wrong - " + e?.message);
      console.log("PubgCharge => onGetPlayerName", e);
    }
    setIsLoading({ isSearching: false });
  }

  async function onAddUC() {
    setIsLoading({ isSubmitting: true });

    try {
      const cardIds = getSelectedDenominationCards();
      const requests = cardIds.flatMap((card) => {
        return Array(card.quantity)
          .fill()
          .map(() => {
            return ApiRequest.post(
              `/pubg_add_uc?player_id=${playerId}&uc=${card?.ean}`
            );
          });
      });

      await Promise.all(requests);
      setIsSuccessCharging(true);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your request is in progress",
        }),
        icon: "success",
      });
    } catch (e) {
      setIsSuccessCharging(false);
      await Toast.fire({
        title: intl.formatMessage({
          id: "pubg_uc_addition_failed", // Make sure you have this id in your messages
        }),
        icon: "error",
      });
    } finally {
      setIsLoading({ isSubmitting: false });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  async function onNextClick() {
    setIsLoading({ nextStep: true });
    switch (activeStep) {
      case 0: {
        state?.number && onGetPlayerName();
        break;
      }
      case 2:
        onAddUC();
        break;
      default:
        break;
    }
    setIsLoading({ nextStep: false });
  }

  function renderConfirmationModal() {
    const body = (
      <Typography>
        {intl
          .formatMessage({ id: "pubg_conformation_modal_msg" })
          .replace("{player_id}", playerId)
          .replace("{player_name}", playerName)
          .replace("{total_uc}", totalUC)}
      </Typography>
    );
    return (
      <ConformationModal
        conformBtnProps={{ loading: isLoading.isSubmitting, onClick: onAddUC }}
        title={intl.formatMessage({ id: "confirmation" })}
        body={body}
        open={toggle.showConfirmationModal}
        onCancelClick={() => setToggle({ showConfirmationModal: false })}
      />
    );
  }

  return (
    <>
      {renderConfirmationModal()}
      <Box sx={{ width: "100%" }} paddingTop={"16px"} position={"relative"}>
        {conditionalReturn(
          isLoading.nextStep || isLoading.gameCards,
          <Grid
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
            item
            md={12}
            container
            justifyContent={"center"}
            xs={12}
          >
            <CircularProgress size={30} />
          </Grid>
        )}
        <Grid
          container
          style={
            isLoading.nextStep
              ? {
                  backgroundColor: "#8080802e",
                  opacity: 0.5,
                }
              : {}
          }
        >
          <Grid item md={12} xs={12} sm={12} xl={12} dir={"rtl"}>
            <Stepper
              steps={steps}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          </Grid>
          {activeStep === 3 ? (
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              xl={12}
              paddingTop={3}
              container
              justifyContent={"center"}
            >
              <Alert severity={isSuccessCharging ? "success" : "error"}>
                <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold" }}>
                  {isSuccessCharging
                    ? intl.formatMessage({ id: "allStepsCompleted" })
                    : intl.formatMessage({ id: "failedToChargeUC" })}
                </Typography>
              </Alert>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset} size={"large"}>
                  {intl.formatMessage({ id: "reset" })}
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              xl={12}
              paddingTop={3}
              container
              justifyContent={"center"}
              minHeight={300}
            >
              {stepsComps[activeStep]}
            </Grid>
          )}

          <Grid item md={12} xs={12} sm={12} xl={12}>
            {activeStep !== 3 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {activeStep !== 0 ? (
                  <Button
                    size={"large"}
                    variant={"contained"}
                    onClick={handleBack}
                  >
                    {intl.formatMessage({
                      id: "Back",
                    })}
                  </Button>
                ) : (
                  <Box sx={{ flex: "1 1 auto" }} />
                )}
                <LoadingButton
                  size={"large"}
                  disabled={isLoading.nextStep || isDisabled?.nextBtn}
                  variant={"contained"}
                  onClick={handleNext}
                  loading={isLoading.isSubmitting}
                >
                  {intl.formatMessage({
                    id: activeStep === steps.length - 1 ? "done" : "next",
                  })}
                </LoadingButton>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
PubgCharge.propTypes = {
  gameName: PropTypes.string,
};
