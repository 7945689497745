import {
  GET_SELLER_DISCOUNTS,
  CLEAR_ERRORS,
} from "./types";
import ApiRequest from "./ApiRequest";
import sha256 from 'crypto-js/sha256';



export function getSellerDiscountApiCaller({sellerId}) {
  return ApiRequest
  .post(
    `seller_discount?seller_id=${sellerId}`
  )};

export const getDiscounts = (boolean, seller, setDiscount) => (dispatch) => {
  dispatch(clearErrors());
  const storageHash = sha256(`discount`).toString();
  const discounts = JSON.parse(sessionStorage.getItem(storageHash));
  if (false) {
    dispatch({
      type: GET_SELLER_DISCOUNTS,
      payload: discounts,
    });
    return
  }
  return ApiRequest
    .post(
      `seller_discount?seller_id=${boolean ? seller : ''}`
    )
    .then((res) => {
      let discounts = res.data
      if (boolean) {
        setDiscount(discounts)
        return
      }
      sessionStorage.setItem(storageHash, JSON.stringify(res.data));
      dispatch({
        type: GET_SELLER_DISCOUNTS,
        payload: discounts,
      });
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: "Somthing went Wrong !!",
      // });
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
