import {
  ADD_SELLER_CREDITS,
  GET_SELLERS,
  GET_SELLER_CREDITS,
  GET_REWARDS,
  CONVERT_POINTS,
  ADD_REWARDS_LOADING,
  GET_SELLER_DEBT,
  GET_SELLER_DEBT_LOADING,
  PURGE_SELLER_CREDITS,
  GET_TOPUP_ACCOUNTS,
} from "../actions/types";

const initialState = {
  discounts: [],
  rewards: [],
  prizeRewards: [],
  bankRewards: [],
  balanceRewards: [],
  sellers: [],
  loading: false,
  addRewardsLoading: false,
  topUpAccounts: [],
  sellerDebts: [],
  sellerDebtLoading: false,
};

const sellerCreditsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SELLER_CREDITS:
      return {
        ...state,
        // credits: action.payload,
        loading: false,
      };
    case GET_REWARDS:
      return {
        ...state,
        rewards: action.payload,
        prizeRewards: action.payload.filter(({ type }) => type === "prize"),
        bankRewards: action.payload.filter(({ type }) => type === "bank"),
        balanceRewards: action.payload.filter(({ type }) => type === "balance"),
        loading: false,
      };
    case CONVERT_POINTS:
      return {
        ...state,
        // rewards: action.payload,
        loading: false,
      };
    case GET_SELLER_CREDITS:
      return {
        ...state,
        credits: action.payload,
        loading: false,
      };
    case GET_SELLERS:
      return {
        ...state,
        sellers: action.payload,
        loading: false,
      };
    case GET_TOPUP_ACCOUNTS:
      return {
        ...state,
        topUpAccounts: action.payload,
      };
    case ADD_REWARDS_LOADING:
      return {
        ...state,
        addRewardsLoading: action.payload,
      };
    case GET_SELLER_DEBT:
      return {
        ...state,
        sellerDebts: action.payload,
      };
    case GET_SELLER_DEBT_LOADING:
      return {
        ...state,
        sellerDebtLoading: action.payload,
      };
    case PURGE_SELLER_CREDITS:
      return state;
    default:
      return state;
  }
};

export default sellerCreditsReducer;
