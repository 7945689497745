import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import {
  getGroupesData,
  chargeGrpupCompany,
  setGroupCompanies,
} from "../../../actions/companiesAction";
import "./group.css";
import Spinner from "../../ui/spinner/Spinner";
import { isArray, isEmpty, isNil, set } from "lodash";
import CompaniesNav from "../../common/CompaniesNav";
import Button from "../../common/Button";
import GroupCompaniesBundleCard from "./GroupCompaniesBundleCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import sha256 from "crypto-js/sha256";
import update from "immutability-helper";
import { useHistory, useLocation } from "react-router-dom";
import { SESSION_STORAGE_ITEMS_ENUM } from "shared/constants/localStorageItemsEnum";
import { jsonParser } from "shared/utils/ppUtils";
import { noBalanceContext } from "reducers/no_balance";
import { useContext } from "react";
import cellcomLogo from "../../../assests/images/bundles/cellcom/banner.png";
import pelephoneLogo from "../../../assests/images/bundles/pelephone/banner.png";
import partnerLogo from "../../../assests/images/bundles/partner/banner.png";
import golanLogo from "../../../assests/images/bundles/golan/banner.png";
import mobile012Logo from "../../../assests/images/bundles/mobile012/banner.png";
import cellcomSmallLogo from "../../../assests/images/bundles/isreal/cards-07.png";
import golanSmallLogo from "../../../assests/images/bundles/isreal/cards-05.png";
import pelephoneSmallLogo from "../../../assests/images/bundles/isreal/cards-04.png";
import partnerSmallLogo from "../../../assests/images/bundles/isreal/cards-01.png";
import mobile012SmallLogo from "../../../assests/images/bundles/isreal/cards-03.png";

import { useDispatch, useSelector } from "react-redux";
import { setNumberChanged } from "../../../actions/companiesAction";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";
import ConfirmModal from "../shared/InfoModal";
import IsrealSmallCard from "./IsraelSmallCard";
let prevPage = "";

const GroupCom = ({
  getGroupesData,
  companies,
  loading,
  chargeGrpupCompany,
  isDarkTheme,
  setGroupCompanies,
}) => {
  const [selected, setSelected] = useState();
  const noBalanceCtx = useContext(noBalanceContext);
  const history = useHistory();
  const state = history.location.state;

  const pathname = history.location.pathname.split("/");
  const location = useLocation();
  console.log("location", location);
  const bundle = location.state?.bundle;

  const mobileNo = history.location.pathname.split("/")[4];
  const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-4");
  const [loadingSpinner, isLoading] = useState(false);
  const collapseRef = useRef([]);
  const listRef = useRef(null);
  const [logo, setLogo] = useState("");
  const [smallLogo, setSmallLogo] = useState("");
  const [openInfoModel, setOpenInfoModel] = useState(false);

  const dispatch = useDispatch();
  // To read the state value
  const numberIsChanged = useSelector(
    (state) => state.companies.numberIsChanged
  );

  useEffect(() => {
    if (bundle) {
      setSelected(bundle);
    }
  }, [bundle]);

  useEffect(() => {
    if (numberIsChanged) {
      dispatch(setNumberChanged(false));
      clearSelected();
    }
  }, [numberIsChanged]);

  let typeCompany = history.location.pathname.split("/")[3];
  let provider_id = history.location?.state?.provider_Id;

  useEffect(() => {
    if (prevPage === "Partner") {
      prevPage = typeCompany;
      window.location.reload();
    } else {
      prevPage = typeCompany;
    }
  }, [typeCompany]);
  useEffect(() => {
    if (provider_id == "127") {
      setLogo(partnerLogo);
      setSmallLogo(partnerSmallLogo);
    }
    if (provider_id == "218") {
      setLogo(cellcomLogo);
      setSmallLogo(cellcomSmallLogo);
    }
    if (provider_id == "095") {
      setLogo(pelephoneLogo);
      setSmallLogo(pelephoneSmallLogo);
    }
    if (provider_id === "214") {
      setLogo(golanLogo);
      setSmallLogo(golanSmallLogo);
    }
    if (provider_id === "201") {
      setLogo(mobile012Logo);
      setSmallLogo(mobile012SmallLogo);
    }
  }, [history.location, provider_id]);

  const storageHash = sha256(`GroupesData${provider_id}`).toString();

  useEffect(() => {
    getGroupesData(provider_id);
    document.title = history.location.pathname.split("/")[3] + " | Phone Play";
    refreshColumnStyle();

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  // console.log("provider_id", provider_id);

  const onTypeClick = (item) => {
    setSelected({ ...item, logo });
    document
      .getElementsByTagName("html")[0]
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleSessionStorageChange = () => {
      if (sessionStorage.getItem([SESSION_STORAGE_ITEMS_ENUM.peletalkBundle])) {
        setSelected(
          jsonParser(
            sessionStorage.getItem([SESSION_STORAGE_ITEMS_ENUM.peletalkBundle])
          )
        );
      }
    };

    window.addEventListener(
      "sessionStorageChanged",
      handleSessionStorageChange
    );

    // Clean up the event listener
    return () => {
      window.removeEventListener(
        "sessionStorageChanged",
        handleSessionStorageChange
      );
    };
  }, [history]);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      collapseRef.current.forEach((item) => {
        item?.classList?.remove("show");
      });
    }
  };

  const onChargeClick = (e) => {
    e.preventDefault();
    isLoading(true);

    chargeGrpupCompany(
      typeCompany,
      mobileNo,
      {
        ...selected,
        provider_id,
        amount: selected?.amount ? parseFloat(selected?.amount) : 0,
      },
      history
    )
      .then((check) => {
        if (check === "noBalance") {
          noBalanceCtx.openCardHandler();
        }
      })
      .finally(() => {
        clearSelected();
        isLoading(false);
      });
  };

  const clearSelected = () => {
    setSelected(null);
  };

  const refreshColumnStyle = () => {
    switch (localStorage.size) {
      case "default":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
        break;
      case "column3":
        setColumnStyle("col-lg-4 col-md-6 col-sm-6 col-6 card-lg");
        break;
      case "column4":
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6  card-md");
        break;
      case "column6":
        setColumnStyle("col-lg-2 col-md-2 col-sm-4 col-6 card-sm");
        break;
      default:
        setColumnStyle("col-lg-3 col-md-4 col-sm-6 col-6");
    }
  };

  const refreshClick = () => {
    getGroupesData(provider_id, true);
  };

  let topDiv = null;

  let backLink = history.location.pathname.split("/");
  backLink.pop();
  backLink = backLink.join("/");

  useEffect(() => {
    if (companies.length) {
      let groupObj = {};
      for (let i = 0; i < companies.length; i++) {
        groupObj[companies[i].PID] = i;
      }

      localStorage.setItem(storageHash, JSON.stringify(groupObj));
    }
  }, [companies, setGroupCompanies, storageHash]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = companies[dragIndex];
      setGroupCompanies(
        update(companies, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [companies, setGroupCompanies]
  );
  useEffect(() => {
    const storageHash =
      sha256(`GroupesData${provider_id}`).toString() + "_GroupesData";
    window.localStorage.setItem(storageHash, JSON.stringify(companies));
  }, [companies]);

  const mobileNumber = pathname?.[pathname.length - 1];
  const queryParams = new URLSearchParams(location.search);
  const numberFromQuery = queryParams.get("number") || "";
  const disabledButton =
    !/^\d{10}$/.test(mobileNumber) ||
    (numberFromQuery && numberFromQuery !== mobileNumber);

  return (
    <>
      <div ref={(ref) => (topDiv = ref)}>
        <CompaniesNav
          title={typeCompany}
          logo={logo}
          mobile={mobileNo}
          onRefresh={refreshClick}
          actionWidth="130px"
          backLink={backLink}
          providerId={
            window?.history?.state?.state?.provider_Id
              ? window?.history?.state?.state?.provider_Id
              : state?.provider_id
          }
          comingFromReports={
            !window?.history?.state?.state?.provider_Id && state?.provider_id
          }
        />

        <div className="position-relative">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className={IS_MOBILE_DISPLAY ? "col-12" : "col-10"}>
                  <div
                    className={`card m-4s fixed-top1 position-sticky mt-2 ${
                      isDarkTheme && "dark-background"
                    }`}
                    style={IS_MOBILE_DISPLAY ? { padding: "10px 0 30px" } : {}}
                  >
                    {IS_MOBILE_DISPLAY && (
                      <div className="mt-2">
                        <div
                          className={` ${isDarkTheme && "dark-background"}`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h5
                              className="text-muted mt-1 mb-2"
                              title="Balance"
                              style={{ fontSize: "1.2rem" }}
                            >
                              {translate("total")}:{" "}
                            </h5>
                            <h3 className="text-info mt-2">
                              ₪{" "}
                              {selected?.amount
                                ? parseFloat(selected?.amount)
                                : 0}
                            </h3>
                          </div>
                          <Button
                            formType="submit"
                            className={`mx-2 btn btn sign-but ${
                              !selected && "disabled"
                            }`}
                            onClick={() => setOpenInfoModel(true)}
                            title="accept"
                            loading={loadingSpinner}
                            disabled={disabledButton}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row mt-1 fixed-topx px-3">
                      {!isNil(selected) && !isEmpty(selected) && (
                        <div className="col-lg-3 col-md-4 col-sm-4 mt-3">
                          <div className="card">
                            <div className="frame1">
                              {/* <img
                                alt={`${typeCompany} Bundle`}
                                src={selected?.url}
                                width="260px"
                                height="100px"
                              ></img>
                               */}
                              <IsrealSmallCard
                                logo={selected.logo}
                                price={`${selected.amount} ₪`} // Display price
                                hasMinutes={!!selected.min} // Number of minutes
                                hasMessages={!!selected.sms} // Number of SMS messages
                                hasData={!!selected.gb} // Data in GB
                                supports5G={
                                  selected["5g_enabled"]?.toLowerCase() ===
                                  "true"
                                }
                              />
                              <div
                                className="close-btn"
                                onClick={clearSelected}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!IS_MOBILE_DISPLAY && (
                  <div className="col-2">
                    <div className="card total-balance-card mt-2">
                      <div
                        className={`card-body p-2 ${
                          isDarkTheme && "dark-background"
                        }`}
                      >
                        <h5
                          className="text-muted mt-1 mb-2"
                          title="Balance"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {translate("total")}
                        </h5>
                        <h3 className="text-info mt-2">
                          ₪{" "}
                          {selected?.amount ? parseFloat(selected?.amount) : 0}
                        </h3>
                        <Button
                          formType="submit"
                          className={`mx-2 btn btn sign-but ${
                            !selected && "disabled"
                          }`}
                          onClick={() => setOpenInfoModel(true)}
                          title="accept"
                          loading={loadingSpinner}
                          disabled={disabledButton}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr
            className="mt-3"
            style={{
              border: "2px solid #42ace3",
              backgroundColor: "#42ace3",
              fontWeight: "bolder",
            }}
          />

          <div
            className={`${isDarkTheme ? "dark-card" : "card"} list-cards p-4`}
            ref={listRef}
          >
            <div className="row">
              {loading && <Spinner />}
              {!isArray(companies) && !loading ? (
                <div className="d-flex justify-content-center mt-3">
                  <h2 className="text-info">
                    {translate("No available bundles")}
                  </h2>
                </div>
              ) : (
                <DndProvider backend={HTML5Backend}>
                  {companies?.map((item, index) => {
                    return (
                      <GroupCompaniesBundleCard
                        key={item.PID}
                        columnStyle={
                          "col-xl-4 col-lg-6 col-sm-12 col-6 card-lg"
                        }
                        item={item}
                        onTypeClick={onTypeClick}
                        index={index}
                        id={item.PID}
                        collapseRef={collapseRef}
                        isDarkTheme={isDarkTheme}
                        moveCard={moveCard}
                        logo={logo}
                        smallLogo={smallLogo}
                      />
                    );
                  })}
                </DndProvider>
              )}
            </div>
          </div>
        </div>
        <ConfirmModal
          phoneNumber={mobileNumber}
          selectedCards={[selected]}
          open={openInfoModel}
          handleConfirm={(e) => {
            setOpenInfoModel(false);
            onChargeClick(e);
          }}
          handleClose={() => setOpenInfoModel(false)}
          totalAmount={selected?.amount ? parseFloat(selected?.amount) : 0}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  companies: state.companies.groupCompaneis,
  loading: state.companies.loading,
  isDarkTheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, {
  getGroupesData,
  chargeGrpupCompany,
  setGroupCompanies,
})(GroupCom);
