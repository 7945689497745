import {
  GET_JAWWAL_3G,
  GET_JAWWAL_CREDIT,
  GET_JAWWAL_MIN,
  GET_JAWWAL_CHARGE,
  ADD_JAWWAL_CHARGE,
  IS_PLUS,
  GET_NOT_RENEW_JAWWAL_MIN,
  GET_RENEW_JAWWAL_MIN,
  GET_RENEW_JAWWAL_3G,
  GET_NOT_RENEW_JAWWAL_3G,
  GET_JAWWAL_ROM,
  GET_OOREDOO_3G,
  GET_OOREDOO_MIN,
  GET_OOREDOO_ROM,
  GET_OOREDOO_SUPER,
  LOADING_TRUE,
  GET_RENEW_OOREDOO_SUPER,
  GET_NOR_RENEW_OOREDOO_SUPER,
  GET_OOREDOO_NOT_RENEW_ROM,
  GET_OOREDOO_RENEW_ROM,
  GET_OOREDOO_NOT_RENEW_3G,
  GET_OOREDOO_RENEW_3G,
  GET_OOREDOO_NOT_RENEW_MIN,
  GET_OOREDOO_RENEW_MIN,
  GET_GROUP_COMPANIES,
  GET_AZY,
  GET_HOT,
  GET_ALL_COMPANIES,
  ADD_COMPANIES_LOADING,
  COMPANY_SETTINGS_OBJECT,
  GET_JAWWAL_PLUS,
  JAWWAL_BUNDLE_LOADING,
  SET_NUMBER_CHANGED,
} from "../actions/types";

const initialState = {
  allCompanies: [],

  //JAWWAL
  jawwal3g: [],
  jawal3gReniew: [],
  jawal3gNotReniew: [],
  jawwal3gFilteer: [],
  jawwalCreadit: [],
  jawwalMin: [],
  jawalMinReniew: [],
  jawalMinNotReniew: [],
  jawwalRom: [],
  jawwalRomTabs: [],
  JawwalPlus: [],
  chargeJawwal: [],
  jawwalMinFilteer: [],
  isPlus: false,
  JawwalMinLoading: false,
  JawwalRomLoading: false,
  JawwalPlusLoading: false,
  Jawwal3gLoading: false,
  //OOREDOO
  ooredoo3g: [],
  ooredoo3gFilter: [],
  ooredooMin: [],
  ooredooMinFilter: [],
  ooredooRom: [],
  ooredooRomFilter: [],
  ooredooSuper: [],
  ooredooSuperFilter: [],
  groupCompaneis: [],
  azy: [],
  hot: [],
  loading: true,
  addCompaniesLoading: false,
  numberIsChanged: false,

  //Company settings
  companiesSettings: {},
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        allCompanies: action.payload?.filter((c) => c.active === "true"),
        loading: false,
      };
    case GET_JAWWAL_3G:
      return {
        ...state,
        jawwal3g: action.payload,
        jawwal3gFilteer: action.payload,
        jawal3gReniew: action.payload.filter(
          ({ renew }) => renew === "True" || renew === "true"
        ),
        jawal3gNotReniew: action.payload.filter(
          ({ renew }) => renew === "False" || renew === "false"
        ),
        loading: false,
      };
    case GET_JAWWAL_CREDIT:
      return {
        ...state,
        jawwalCreadit: action.payload,
        loading: false,
      };
    case GET_JAWWAL_MIN:
      return {
        ...state,
        jawwalMin: action.payload,
        jawwalMinFilteer: action.payload,
        jawalMinReniew: action.payload.filter(
          ({ renew }) => renew === "True" || renew === "true"
        ),
        jawalMinNotReniew: action.payload.filter(
          ({ renew }) => renew === "False" || renew === "false"
        ),
        loading: false,
      };
    case GET_JAWWAL_CHARGE:
      return {
        ...state,
        chargeJawwal: action.payload,
        loading: false,
      };
    case ADD_JAWWAL_CHARGE:
      return {
        ...state,
        chargeJawwal: (chargeJawwal) => [...chargeJawwal, action.payload],
      };
    case GET_RENEW_JAWWAL_MIN:
      return {
        ...state,
        jawwalMin: state.jawwalMinFilteer.filter(
          ({ renew }) => renew === "True" || renew === "true"
        ),
      };
    case GET_NOT_RENEW_JAWWAL_MIN:
      return {
        ...state,
        jawwalMin: state.jawwalMinFilteer.filter(
          ({ renew }) => renew === "False" || renew === "false"
        ),
      };
    case GET_RENEW_JAWWAL_3G:
      return {
        ...state,
        jawwal3g: state.jawwal3gFilteer.filter(
          ({ renew }) => renew === "True" || renew === "true"
        ),
      };
    case GET_NOT_RENEW_JAWWAL_3G:
      return {
        ...state,
        jawwal3g: state.jawwal3gFilteer.filter(
          ({ renew }) => renew === "False" || renew === "false"
        ),
      };
    case GET_JAWWAL_ROM:
      return {
        ...state,
        jawwalRom: action.payload,
        jawwalRomTabs: [...new Set(action.payload.map((rom) => rom.country))],
        loading: false,
      };
    case GET_JAWWAL_PLUS:
      return {
        ...state,
        JawwalPlus: action.payload,
        loading: false,
      };
    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    //OOREDOO------------------------------------
    case GET_OOREDOO_3G:
      return {
        ...state,
        ooredoo3g: action.payload,
        ooredoo3gFilter: action.payload,
        loading: false,
      };
    case GET_OOREDOO_RENEW_3G:
      return {
        ...state,
        ooredoo3g: state.ooredoo3gFilter.filter(
          ({ auto_renew }) => auto_renew === "True" || auto_renew === "true"
        ),
      };
    case GET_OOREDOO_NOT_RENEW_3G:
      return {
        ...state,
        ooredoo3g: state.ooredoo3gFilter.filter(
          ({ auto_renew }) => auto_renew === "False" || auto_renew === "false"
        ),
      };
    case GET_OOREDOO_MIN:
      return {
        ...state,
        ooredooMin: action.payload,
        ooredooMinFilter: action.payload,
        loading: false,
      };
    case GET_OOREDOO_RENEW_MIN:
      return {
        ...state,
        ooredooMin: state.ooredooMinFilter.filter(
          ({ auto_renew }) => auto_renew === "True" || auto_renew === "true"
        ),
      };
    case GET_OOREDOO_NOT_RENEW_MIN:
      return {
        ...state,
        ooredooMin: state.ooredooMinFilter.filter(
          ({ auto_renew }) => auto_renew === "False" || auto_renew === "false"
        ),
      };
    case GET_OOREDOO_ROM:
      return {
        ...state,
        ooredooRom: action.payload,
        ooredooRomFilter: action.payload,
        loading: false,
      };
    case GET_OOREDOO_RENEW_ROM:
      return {
        ...state,
        ooredooRom: state.ooredooRomFilter.filter(
          ({ auto_renew }) => auto_renew === "True" || auto_renew === "true"
        ),
      };
    case GET_OOREDOO_NOT_RENEW_ROM:
      return {
        ...state,
        ooredooRom: state.ooredooRomFilter.filter(
          ({ auto_renew }) => auto_renew === "False" || auto_renew === "false"
        ),
      };
    case GET_OOREDOO_SUPER:
      return {
        ...state,
        ooredooSuper: action.payload,
        ooredooSuperFilter: action.payload,
        loading: false,
      };
    case GET_RENEW_OOREDOO_SUPER:
      return {
        ...state,
        ooredooSuper: state.ooredooSuperFilter.filter(
          ({ auto_renew }) => auto_renew === "True" || auto_renew === "true"
        ),
      };
    case GET_NOR_RENEW_OOREDOO_SUPER:
      return {
        ...state,
        ooredooSuper: state.ooredooSuperFilter.filter(
          ({ auto_renew }) => auto_renew === "False" || auto_renew === "false"
        ),
      };
    case GET_GROUP_COMPANIES:
      return {
        ...state,
        groupCompaneis: action.payload,
        loading: false,
      };
    case GET_AZY:
      return {
        ...state,
        azy: action.payload,
        loading: false,
      };
    case GET_HOT:
      return {
        ...state,
        hot: action.payload,
        loading: false,
      };
    case ADD_COMPANIES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case IS_PLUS:
      return {
        ...state,
        isPlus: action.payload,
      };
    case COMPANY_SETTINGS_OBJECT:
      const companyName = action.payload.company;
      const settings = action.payload.settings;
      return {
        ...state,
        companiesSettings: {
          ...state.companiesSettings,
          [companyName]: settings,
        },
      };
    case JAWWAL_BUNDLE_LOADING:
      const bundleLoading = action.payload.bundleLoading;
      return {
        ...state,
        [bundleLoading]: action.payload.boolean,
      };
    case SET_NUMBER_CHANGED:
      return {
        ...state,
        numberIsChanged: action.payload,
      };
    default:
      return state;
  }
};

export default companiesReducer;
