import React from 'react'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { intl } from '../../i18n/provider';

import './styles.css'
import { connect } from 'react-redux';

const PageTitle = ({ children, title, selectText, backPage, isDarktheme }) => {
    const history = useHistory()


    return (
        <div className={!isDarktheme ? 'page-detail' : 'dark-page-detail'}>
            <div>
                <div className='d-flex page-title align-items-center' >
                    <ArrowBackIcon style={{ cursor: 'pointer', margin: '0 16px' }} onClick={() => backPage ? history.push(backPage) : history.goBack()} />
                    <h3 style={{ marginBottom: 0 }}>{title}</h3>
                </div>
                {selectText && <h6>{intl.formatMessage({ id: selectText })}</h6>}
            </div>

            <div className='row px-2'>
                {children}
            </div>
        </div>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
    return {
        isDarktheme
    }
}

export default connect(mapStateToProps, null)(PageTitle)