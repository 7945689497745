import React from "react";
import Select from "react-select";
import translate from "../../../i18n/translate";
import { intl } from "../../../i18n/provider";

import "./style.css";
import { connect } from "react-redux";

const VehicleStepper = ({
  driverTypes,
  driverType,
  setDriverType,
  handleNext,
  isDarktheme,
}) => {
  return (
    <form
      className="vehicle-stepper"
      onSubmit={(e) => {
        e.preventDefault();
        if (!driverType) {
          return;
        }
        handleNext(e);
      }}
    >
      <div className="select-input">
        <label
          style={
            !isDarktheme
              ? { marginBottom: 6 }
              : { marginBottom: 6, color: "white" }
          }
        >
          <span>
            {intl.formatMessage({ id: "Select Vehicle Type" })}{" "}
            <i className="asterisk">*</i>
          </span>
        </label>
        <Select
          name="driver_type"
          placeholder={intl.formatMessage({ id: "Select Vehicle Type" })}
          options={driverTypes}
          required={true}
          value={driverType}
          onChange={(v) => setDriverType(v)}
        />
      </div>
      <div
        className="d-flex justify-content-end align-items-center mt-4"
        // style={{ position: 'absolute', top: '200px', width: '100%', padding: '0 16px' }}
      >
        <button
          type="submit"
          className="btn btn-primary"
          style={{ position: "static" }}
          onSubmit={(e) => {
            e.preventDefault();
            if (!driverType) {
              return;
            }
            handleNext(e);
          }}
          disabled={!driverType}
        >
          {translate("next")}
        </button>
      </div>
    </form>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(VehicleStepper);
