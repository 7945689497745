import {
  GET_SELLER_CREDITS,
  CLEAR_ERRORS,
  ADD_SELLER_CREDITS,
  GET_SELLERS,
  GET_REWARDS,
  CONVERT_POINTS,
  ADD_REWARDS_LOADING,
  GET_SELLER_DEBT,
  GET_SELLER_DEBT_LOADING,
  GET_TOPUP_ACCOUNTS,
} from "./types";
import ApiRequest from "./ApiRequest";
import { intl } from "../i18n/provider";
import Toast from "../components/common/Toast";
import { userData } from "./userAction";
import { isArray } from "lodash";
import { logoutUser } from "./userAction";

export const addSellerCredit =
  (amount, sellerId, setLoading, amoutPaid, note) => (dispatch) => {
    dispatch(clearErrors());

    return ApiRequest.post(
      `seller_add_credits?amount=${amount}&sellerid=${sellerId}&location=PAL&amount_paid=${amoutPaid}&note=${
        note ? note : "N/A"
      }`
    )
      .then((res) => {
        const credits = res.data;
        if (credits.status !== "true") {
          const reason =
            credits?.reason ||
            intl.formatMessage({ id: "Operation add credit failed" });
          Toast.fire({
            title: reason,
            icon: "error",
          });
        } else {
          Toast.fire({
            title: intl.formatMessage({
              id: "Operation add credit was successfully performed",
            }),
            icon: "success",
          });
        }
        dispatch({
          type: ADD_SELLER_CREDITS,
          payload: credits,
        });
        dispatch(getSellers);
        dispatch(userData("/add_credits"));
        setLoading(false);
      })
      .catch((err) => {
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: "Somthing went Wrong !!",
        // });
        setLoading(false);
      });
  };

export const getSellerBalance = (sellerId) => (dispatch) => {
  dispatch(clearErrors());

  return ApiRequest.post(`get_seller_balance?&sellerid=${sellerId}`)
    .then((res) => {
      const credits = res.data;
      dispatch({
        type: GET_SELLER_CREDITS,
        payload: credits,
      });
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: "Somthing went Wrong !!",
      // });
    });
};

export const getSellers =
  (sellerId = "") =>
  (dispatch) => {
    sellerId = sellerId ? sellerId : "";
    dispatch(clearErrors());
    return ApiRequest.post(`get_allseller?reseller_id=${sellerId}`)
      .then((res) => {
        if (
          res.data?.reason === "token expired" ||
          res.data?.reason === "admin disabled"
        ) {
          dispatch(logoutUser());
          return;
        }

        const sellers = res.data;
        if (res.data !== "N/A" && isArray(sellers?.all_sellers)) {
          dispatch({
            type: GET_SELLERS,
            payload: sellers?.all_sellers,
          });
        }
        return sellers;
      })
      .catch((err) => {
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: "Somthing went Wrong !!",
        // });
      });
  };

export const getRewards = () => (dispatch) => {
  dispatch(clearErrors());

  return ApiRequest.post(`get_rewards`)
    .then((res) => {
      const sellers = res.data;
      dispatch({
        type: GET_REWARDS,
        payload: sellers,
      });
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: "Somthing went Wrong !!",
      // });
    });
};

export const convertPoints =
  (id, bank, accountNumber, amount, history = "/convert_points") =>
  (dispatch) => {
    dispatch(clearErrors());

    const sellerId = JSON.parse(localStorage.companies).sellerid;

    //bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}
    //    `convert_points?reward_id=${id}&amount=${amount}`

    //`convert_points?reward_id=${id}bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}`

    //bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}
    //    `convert_points?reward_id=${id}&amount=${amount}`

    //bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}
    amount = amount ? amount : "";
    accountNumber = accountNumber ? accountNumber : "";
    bank = bank ? bank : "";
    return ApiRequest.post(
      `convert_points?reward_id=${id}&bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}&location=PAL`
    )
      .then((res) => {
        const sellers = res.data;
        dispatch({
          type: CONVERT_POINTS,
          payload: sellers,
        });
        if (res.data.status === "success") dispatch(userData(history));

        return res;
      })
      .catch((err) => {
        Toast.fire({
          title: intl.formatMessage({ id: "Operation add get reward failed" }),
          icon: "error",
        });
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: "Somthing went Wrong !!",
        // });
      });
  };

export const convertPointsToCash =
  (bank, accountNumber, amount) => (dispatch) => {
    dispatch(clearErrors());

    const sellerId = JSON.parse(localStorage.companies).sellerid;
    return ApiRequest.post(
      `bank_transfer_details?bank_name=${bank}&account_no=${accountNumber}&seller_id=${sellerId}&amount=${amount}&location=PAL`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: "Somthing went Wrong !!",
        // });
      });
  };

export const addRewards = (rewardData) => (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: ADD_REWARDS_LOADING,
    payload: true,
  });
  return ApiRequest.post(
    `add_reward?&id=${rewardData.id}&name=${rewardData.name}&price=${rewardData.price}&url=${rewardData.url}&location=PAL`
  )
    .then((res) => {
      dispatch({
        type: ADD_REWARDS_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADD_REWARDS_LOADING,
        payload: false,
      });
    });
};

export const getSellerDebt = (sellerId, paid, arp) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch({
    type: GET_SELLER_DEBT_LOADING,
    payload: true,
  });
  try {
    const { data } = await ApiRequest.post(
      `seller_debt?seller_id=${sellerId}&paid=${paid}&arp=${arp}`
    );
    if (data?.reason === "token expired" || data?.reason === "admin disabled") {
      dispatch(logoutUser());
      return;
    }
    dispatch({
      type: GET_SELLER_DEBT,
      payload: isArray(data) ? data : [],
    });
    return data;
  } catch (err) {
  } finally {
    dispatch({
      type: GET_SELLER_DEBT_LOADING,
      payload: false,
    });
  }
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const getTopUpAccounts =
  (carrier = "") =>
  async (dispatch) => {
    dispatch(clearErrors());

    return ApiRequest.post(`get_topup_accounts?carrier=${carrier}`)
      .then((res) => {
        const topUpAccounts = res.data;
        dispatch({
          type: GET_TOPUP_ACCOUNTS,
          payload: topUpAccounts,
        });
        return topUpAccounts;
      })
      .catch((err) => {
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: "Somthing went Wrong !!",
        // });
      });
  };
