import React, { useState, useEffect } from "react";
import translate from "../../i18n/translate";
import { getSellerProfit } from "../../actions/reportsAction";
import "./report.css";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../ui/spinner/Spinner";
import DatePicker from "react-datepicker";
import ReportHeader from "./ReportHeader";
import Pagination from "../common/Pagination";
import Select from "react-select";
import { isArray } from "lodash";
import { getSellers } from "../../actions/sellerCreditsAction";
import ExportButton from "components/common/ExportButton";
import { useIsAdmin } from "shared/hooks/ppHooks";

const Profit = ({
  sellerProfit,
  getSellerProfit,
  isDarktheme,
  auth,
  getSellers,
  sellers,
}) => {
  const [loading, isLoading] = useState(false);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [, setTotalValue] = useState(0);
  const [, setTotalSellerCost] = useState(0);
  const [, setTotalProfit] = useState(0);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [sort, setSort] = useState({ value: "5", label: 5 });
  const [sellersOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState(null);
  const { isReseller } = useIsAdmin();
  const sellerId = useSelector((state) => state.auth.user?.id);
  useEffect(() => {
    document.title = "Report | Phone Play";
    //getSellerReports(dateForm.from, dateForm.to)
  }, []);

  useEffect(() => {
    if (sellerProfit.length) {
      setCount(Number(sellerProfit[0].total_count));
      // setCount(Math.ceil(Number(sellerProfit[0].total_count) / sort.label));
      // setTotalCount(Number(sellerProfit[0].total_count))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerProfit]);

  const handleSearch = (offset, rows) => {
    isLoading(true);
    getSellerProfit(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
      offset,
      rows,
      seller?.value || auth.user.id,
      "profit"
    ).then(() => {
      isLoading(false);
    });
  };

  useEffect(() => {
    if (sellerProfit) {
      calculateTotal();
    }
    getSellers(isReseller && sellerId).then(({ all_sellers: data }) => {
      if (isArray(data)) {
        let sellersOptionsData = data?.map((item) => {
          return {
            value: item.seller_id,
            label: item.name + " " + item.seller_id,
            balance: item.balance,
          };
        });
        setSellerOptions([
          { value: "", label: translate("Select all") },
          ...sellersOptionsData,
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerProfit, sellers?.length]);

  const calculateTotal = () => {
    let totalProf = 0;
    let totalVal = 0;
    let totalSeller = 0;
    sellerProfit?.forEach((sellerProfit) => {
      totalVal = totalVal + sellerProfit.cardamount;
      totalSeller = totalSeller + sellerProfit.dealercost;
      totalProf = totalVal - totalSeller;
    });

    setTotalValue(totalVal);
    setTotalSellerCost(totalSeller);
    setTotalProfit(totalProf);
  };

  const handleSortChange = (v) => {
    v = { value: v, label: v };
    setPage(1);
    setSort(v);
    if (sellerProfit?.length) {
      handleSearch("0", v.value);
    }
  };

  const handleOffsetValue = (event, value) => {
    setPage(value);
    let queryOffset = value * sort.label - sort.label;
    handleSearch(queryOffset, sort.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      border: "1px solid #242526",
    }),
    menu: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      color: isDarktheme ? "white" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarktheme ? "white" : "",
    }),
  };

  return (
    <>
      <div>
        <ReportHeader />
        <div className={!isDarktheme ? "mt-3" : "mt-3 dark-input"}>
          <div className="row">
            <div className="form-group row">
              <label className="col-sm-1 col-form-label">
                {translate("from")}
              </label>
              <div className="col-sm-4">
                <DatePicker
                  selected={dateFrom}
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  onChange={(e) => setDateFrom(e)}
                />
              </div>
              <label className="col-sm-1 col-form-label">
                {translate("to")}
              </label>
              <div className="col-sm-4">
                <DatePicker
                  selected={dateTo}
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  onChange={(e) => setDateTo(e)}
                />
              </div>
              <div className="col-sm-2">
                <button
                  onClick={() => handleSearch("0", sort.value)}
                  className="btn sign-but"
                >
                  {translate("search")}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "16px",
              width: 400,
            }}
          >
            {(auth.user?.seller_type === "admin" || isReseller) && (
              <>
                <h6>Select Seller</h6>
                <Select
                  options={sellersOptions}
                  value={seller}
                  className="report-dropdown"
                  onChange={(value) => setSeller(value)}
                  styles={{ height: "32px", ...customStyles }}
                />
              </>
            )}
          </div>
        </div>

        <div
          className={
            !isDarktheme ? "row table-wrapper" : "row dark-table-wrapper"
          }
        >
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "flex-start" }}
          >
            <div className="table-title ">
              <h5>{translate("Profit Table")}</h5>
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <ExportButton reports={sellerProfit} />
            </div>
          </div>

          <Pagination
            count={count}
            page={page}
            handleOffsetValue={handleOffsetValue}
            handleSortChange={handleSortChange}
            sort={sort}
          />
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">{translate("movmentNo")}</th>
                <th scope="col">{translate("Trans. Type")}</th>
                <th scope="col">{translate("Card Type")}</th>
                <th scope="col" style={{ textTransform: "capitalize" }}>
                  {translate("Time")}
                </th>
                <th scope="col">{translate("number")}</th>
                <th scope="col">{translate("value")}</th>
                <th scope="col">{translate("seller cost")}</th>
                <th scope="col">{translate("profit")}</th>
              </tr>
            </thead>
            <tbody>
              {sellerProfit?.map((report, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{report.transid}</td>
                    <td>{report.transtype}</td>
                    <td>{report.card_type}</td>
                    <td>
                      {moment(report.datetime).format("YYYY-MM-DD - HH:mm")}
                    </td>
                    <td
                      style={{
                        color: report?.cancelrequest ? "red" : "black",
                      }}
                    >
                      {report.number}
                    </td>
                    <td>{report.cardamount}</td>
                    <td>{report.dealercost}</td>
                    <td>
                      {(report.cardamount - -Number(report.dealercost)).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td scope="col">{translate("total")}</td>
                <td scope="col"></td>
                <td scope="col"></td>
                <td scope="col"></td>
                <td scope="col"></td>
                <td scope="col">
                  {sellerProfit
                    .reduce((prev, profit) => prev + profit.cardamount, 0)
                    .toFixed(2)}
                </td>
                <td scope="col">
                  {sellerProfit
                    .reduce((prev, profit) => prev + profit.dealercost, 0)
                    .toFixed(2)}
                </td>
                <td scope="col">
                  {sellerProfit
                    .reduce(
                      (prev, profit) =>
                        prev + (profit.cardamount - -Number(profit.dealercost)),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
          {!sellerProfit?.length && (
            <div className="no-data-to-show">
              {translate("No data to show")}
            </div>
          )}
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

const mapStateToProps = (state) => ({
  sellerProfit: state.reports.sellerProfit,
  isDarktheme: state.darkTheme.isDarktheme,
  auth: state.auth,
  sellers: state.credits.sellers,
});

export default connect(mapStateToProps, { getSellerProfit, getSellers })(
  Profit
);
