import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { intl } from "../../../i18n/provider";

import TextField from "@mui/material/TextField";
import Modal from "../../common/Modal";
import TextFieldGroup from "./../../common/TextFieldGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getCompanySettings,
  editCompaanySettingsValue,
} from "../../../actions/companiesAction";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ApiRequest from "../../../actions/ApiRequest";

const CompanySettings = ({
  getCompanySettings,
  editCompaanySettingsValue,
  companyId,
  companiesSettings,
  isDarktheme,
}) => {
  const [loading, setLoading] = useState(false);
  const intle = useIntl();
  const [companySettings, setCompanySettings] = useState([]);
  const [editSettingValueModal, setEditSettingValueModal] = useState(false);
  const [settingValue, setSettingsValue] = useState(null);
  const [descValue, setDescValue] = useState(null);
  const [settingsName, setSettingsName] = useState(null);
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false);
  const [refIndex, setRefIndex] = useState(null);
  const [dropDownValue, setDropDownValue] = React.useState("Any text");

  const [open, setOpen] = React.useState(false);
  const [filterData, setFilterData] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    let source = ApiRequest.CancelToken.source();

    if (!id) {
      id = companyId;
    }
    setLoading(true);
    if (companiesSettings[id]?.length > 0) {
      setLoading(false);
      setCompanySettings(companiesSettings[id]);
      setFilterData(companiesSettings[id]);
    } else {
      getCompanySettings(id, source).then((data) => {
        setLoading(false);
        setCompanySettings(data);
      });
    }

    return () => {
      source.cancel();
    };
  }, [companiesSettings[id]?.length]);

  const handleDropDownChange = (event) => {
    setDropDownValue(event.target.value);
  };

  const onChangeDesc = (e) => {
    setDescValue(e.target.value);
  };
  const SearchfliterData = (e) => {
    const { value } = e.target;
    switch (dropDownValue) {
      case "Any text":
        return setFilterData(
          companiesSettings[id]
            ? companiesSettings[id].filter(
                (company) =>
                  company.settings_name
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                  company.setting_value
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                  company.en_desciption
                    .toLowerCase()
                    .includes(value.toLowerCase())
              )
            : companiesSettings[companyId].filter(
                (company) =>
                  company.settings_name
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                  company.setting_value
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                  company.en_desciption
                    .toLowerCase()
                    .includes(value.toLowerCase())
              )
        );
        break;
      case "Name":
        return setFilterData(
          companiesSettings[id]
            ? companiesSettings[id].filter((company) =>
                company.settings_name
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
            : companiesSettings[companyId].filter((company) =>
                company.settings_name
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
        );
        break;
      case "Value":
        return setFilterData(
          companiesSettings[id]
            ? companiesSettings[id]?.filter((company) =>
                company.setting_value
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
            : companiesSettings[companyId]?.filter((company) =>
                company.setting_value
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
        );
        break;
      case "Description":
        return setFilterData(
          companiesSettings[id]
            ? companiesSettings[id]?.filter((company) =>
                company.en_desciption
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
            : companiesSettings[companyId]?.filter((company) =>
                company.en_desciption
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
        );
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditClick = (value, name, desc, i) => {
    setSettingsValue(value);
    setSettingsName(name);
    setDescValue(desc);
    setEditSettingValueModal(true);
    setRefIndex(i);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setSettingsValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setModalSubmitLoading(true);
    editCompaanySettingsValue({
      name: companyId || id,
      settings_name: settingsName,
      settings_value: settingValue,
      des: descValue,
    }).then((res) => {
      if (res) {
        let companySettingsArray = companySettings;
        companySettingsArray[refIndex]["setting_value"] = settingValue;
        companySettingsArray[refIndex][`${intle.locale}_desciption`] =
          descValue;
        setCompanySettings(companySettingsArray);
      }
      setEditSettingValueModal(false);
      setModalSubmitLoading(false);
    });
  };

  return (
    <div
      className={
        !isDarktheme
          ? "d-flex flex-column align-items-center mt-8"
          : "d-flex flex-column align-items-center mt-8 "
      }
    >
      <h2 className="company-header">
        {(id === "msg_api" ? "Messaging System" : id) || companyId}
      </h2>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <TextField
          sx={!isDarktheme ? "" : { background: "white" }}
          id="outlined-basic"
          label="Search"
          variant="filled"
          size="small"
          onChange={SearchfliterData}
        />
        <FormControl sx={{ m: 1, minWidth: 215 }} size="medium">
          <InputLabel
            className={!isDarktheme ? "" : "text-light"}
            id="demo-controlled-open-select-label"
          >
            {intl.formatMessage({ id: "Search options" })}
          </InputLabel>
          <Select
            className={!isDarktheme ? "" : "text-light"}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={dropDownValue}
            label={intl.formatMessage({ id: "search" })}
            onChange={handleDropDownChange}
            placeholder="Any text"
          >
            <MenuItem value="Any text">
              {intl.formatMessage({ id: "Any text" })}
            </MenuItem>
            <MenuItem value="Description">
              {intl.formatMessage({ id: "Description" })}
            </MenuItem>
            <MenuItem value="Value">
              {intl.formatMessage({ id: "value" })}
            </MenuItem>
            <MenuItem value="Name">
              {intl.formatMessage({ id: "Name" })}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, margin: "auto" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                width="20%"
              >
                {intl.formatMessage({ id: "Index" })}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                width="20%"
              >
                {intl.formatMessage({ id: "Setting name" })}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                width="20%"
              >
                {intl.formatMessage({ id: "Setting value" })}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                width="40%"
              >
                {intl.formatMessage({ id: "Description" })}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
              >
                {intl.formatMessage({ id: "Edit" })}
              </TableCell>
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {companySettings && filterData.length === 0
                ? companySettings.map((company, i) => (
                    <TableRow
                      key={i.toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all", alignItems: "center" }}
                      >
                        {company.index}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all" }}
                      >
                        {company.settings_name}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all" }}
                      >
                        {company.setting_value}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="40%"
                        style={{ wordBreak: "break-all" }}
                        component="th"
                        scope="row"
                      >
                        {company[`${intle.locale}_desciption`]}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        align="center"
                      >
                        <EditIcon
                          color="dark"
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleEditClick(
                              company.setting_value,
                              company.settings_name,
                              company[`${intle.locale}_desciption`],
                              i
                            )
                          }
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))
                : companySettings &&
                  filterData.map((company, i) => (
                    <TableRow
                      key={i.toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all", alignItems: "center" }}
                      >
                        {company.index}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all" }}
                      >
                        {company.settings_name}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="20%"
                        style={{ wordBreak: "break-all" }}
                      >
                        {company.setting_value}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        width="40%"
                        style={{ wordBreak: "break-all" }}
                        component="th"
                        scope="row"
                      >
                        {company[`${intle.locale}_desciption`]}
                      </TableCell>
                      <TableCell
                        className={!isDarktheme ? "" : "text-light"}
                        align="center"
                      >
                        <EditIcon
                          color="dark"
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleEditClick(
                              company.setting_value,
                              company.settings_name,
                              company[`${intle.locale}_desciption`],
                              i
                            )
                          }
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <CircularProgress size={80} sx={{ marginTop: "160px" }} />}
      <Modal
        visible={editSettingValueModal}
        onClose={() => setEditSettingValueModal(false)}
        width={"45vw"}
        title="Edit"
      >
        <form
          onSubmit={handleSubmit}
          style={{ width: "90%", margin: "20px auto" }}
        >
          <TextFieldGroup
            placeholder="settings value"
            name="settings value"
            type="text"
            value={settingValue}
            label={settingsName}
            onChange={onChange}
            required={true}
          />
          <div style={{ width: "59%" }}>
            <div style={{ padding: "10px 0" }}>
              {intl.formatMessage({ id: "Description" })}
            </div>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={4}
              placeholder="Description"
              name="Description"
              type="text"
              value={descValue}
              onChange={onChangeDesc}
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="d-flex justify-content-end align-items-center mt-4"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger mx-3"
              onClick={() => setEditSettingValueModal(false)}
            >
              {intl.formatMessage({ id: "Cancel" })}
            </button>
            <button type="submit" className="btn btn-primary">
              {modalSubmitLoading ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                `${intl.formatMessage({ id: "submit" })}`
              )}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
const mapStateToProps = ({
  companies: { companiesSettings },
  darkTheme: { isDarktheme },
}) => ({
  companiesSettings,
  isDarktheme,
});

export default connect(mapStateToProps, {
  getCompanySettings,
  editCompaanySettingsValue,
})(CompanySettings);
