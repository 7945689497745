import translate from "i18n/translate";

export const profileData = (seller) => {
  return [
    {
      desc: translate("Name"),
      variable: seller?.name,
      aKey: "name",
      isAdmin: false,
      special: true,
    },
    {
      desc: translate("Balance"),
      variable: seller?.balance,
      aKey: "balance",
      isAdmin: false,
      disabled: true,
      special: true,
    },

    {
      desc: translate("Mobile Number"),
      variable: seller?.mobile,
      aKey: "mobile",
      disabled: true,
      isAdmin: false,
      special: true,
    },
    // Only displayed if user is the seller or seller_id matches

    {
      desc: translate("Seller Type"),
      variable: seller?.seller_type,
      aKey: "seller_type",
      isAdmin: true,
      special: true,
    },
    {
      desc: translate("reSeller Id"),
      variable: seller?.reseller_id,
      aKey: "reseller_id",
      isAdmin: true,
      special: true,
    },
    {
      desc: translate("Email"),
      variable: seller?.email,
      aKey: "email",
      isAdmin: false,
    },
    {
      desc: translate("Failed Login Attempt"),
      variable: seller?.FLA,
      aKey: "FLA",
      isAdmin: true,
    },

    {
      desc: translate("Password"),
      variable: seller?.password,
      aKey: "password",
      isAdmin: false,
    },
    {
      desc: translate("Shop"),
      variable: seller?.shop,
      aKey: "shop",
      isAdmin: false,
    },
    {
      desc: translate("Address"),
      variable: seller?.address,
      aKey: "address",
      isAdmin: false,
    },
    {
      desc: translate("country"),
      variable: seller?.country,
      aKey: "country",
      isAdmin: false,
    },
    {
      desc: translate("city"),
      variable: seller?.city,
      aKey: "city",
      isAdmin: false,
    },
    {
      desc: translate("Town"),
      variable: seller?.town,
      aKey: "town",
      isAdmin: false,
    },
    {
      desc: translate("Street"),
      variable: seller?.street,
      aKey: "street",
      isAdmin: false,
    },
    {
      desc: translate("ZIP Code"),
      variable: seller?.zip_code,
      aKey: "zip_code",
      isAdmin: true,
    },
    {
      desc: "referalCode",
      // variable: seller?.commission_code,
      aKey: "referalCode",
      isAdmin: false,
    },
    {
      desc: translate("Fixed Ip"),
      variable: seller?.fixed_ip,
      aKey: "fixed_ip",
      isAdmin: true,
    },
    {
      desc: translate("Allowed Debt"),
      variable: seller?.allwed_debth,
      aKey: "allwed_debth",
      isAdmin: true,
    },
    seller?.enabled == "true" && {
      desc: translate("Edit PIN"),
      variable: seller?.pin,
      aKey: "pin",
      isAdmin: false,
    },
    {
      desc: translate("allow f9"),
      variable: seller?.allow_f9,
      aKey: "allow_f9",
      isAdmin: true,
      checkbox: true,
    },
    {
      desc: translate("Enabled"),
      variable: seller?.enabled,
      aKey: "enabled",
      isAdmin: true,
      checkbox: true,
    },
  ];
};
