import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/common/Modal.js";
import { CircularProgress } from "@mui/material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ApiRequest from "../../../actions/ApiRequest.js";
import { intl } from "../../../i18n/provider.js";
import { dataLanguage } from "../../../i18n/translateRequest.js";
import { conditionalReturn } from "../../../shared/utils/utils.js";
import { jsonParser } from "shared/utils/ppUtils.js";
import { PROVIDER_ROUTES } from "shared/constants/constants.js";
const { useCustomHistory } = require("../../../shared/hooks");

const ProviderCard = ({
  columnStyle,
  callBack,
  rootDivProps,
  company,
  isDarktheme,
  hideInformationIcon,
  mobileNumber,
}) => {
  const { product, url, id } = company;
  const history = useCustomHistory();
  const [serviceModal, setServiceModal] = useState(false);
  const [serviceDataLoading, setServiceDataLoading] = useState(false);
  const [serviceData, setServiceData] = useState(null);

  const getInformationData = async () => {
    setServiceModal(true);
    setServiceDataLoading(true);
    const { data } = await ApiRequest.post(
      `service_numbers?category=telecom&topup_type=topup&id=${id}`
    );

    setServiceDataLoading(false);
    setServiceData(data[0]);
  };

  const link = `/add_balance?providerId=${id}&mobileNumber=${
    mobileNumber || ""
  }`;
  return (
    <div
      className={`${columnStyle} mb-4 wrapper`}
      style={{ position: "relative" }}
      {...rootDivProps}
    >
      <a
        href={link}
        onClick={(e) => {
          e.preventDefault();
          // history.push({ path: link }, { provider_Id: id });
          history.push(
            {
              path: `${PROVIDER_ROUTES.getProviderRoute(
                id?.toString()
              )}/${mobileNumber}`,
            },
            { provider_Id: id }
          );
          callBack?.();
        }}
      >
        <div
          className={
            !isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"
          }
        >
          <div
            className="frame"
            style={{
              background:
                product.toLowerCase() === "partner" ? "#29D6C5" : "auto",
            }}
          >
            <img
              alt=""
              src={url}
              style={{
                objectFit:
                  product.toLowerCase() === "cellcom" ? "cover" : "contain",
              }}
            />
          </div>
          <div
            className={
              !isDarktheme ? "card nav-layout" : "card dark-nav-layout"
            }
          >
            <h5
              className="m-2 text-center"
              style={{ textTransform: "capitalize" }}
            >
              {product}
            </h5>
          </div>
        </div>
      </a>
      {conditionalReturn(
        !hideInformationIcon,
        <div
          className="user-info"
          style={{
            position: "absolute",
            top: 10,
            right: 22,
            cursor: "pointer",
          }}
          onClick={getInformationData}
        >
          <ImportContactsIcon color="info" fontSize="medium" />
        </div>
      )}
      <Modal
        visible={serviceModal}
        onClose={() => setServiceModal(false)}
        width={"600px"}
        title="Company Services Information"
        onConfirm="danger"
      >
        <div className="mt-3">
          {!serviceDataLoading ? (
            serviceData && (
              <div className="card-summary">
                <div className="d-flex align-items-center justify-content-between">
                  <h6>
                    {intl.formatMessage({ id: "AddBalance Credit using:" })}
                  </h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_add_credit",
                      "en_add_credit",
                      "he_add_credit"
                    )}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Check Balance" })}</h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_check_balance",
                      "en_check_balance",
                      "he_check_balance"
                    )}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Check Minute" })}</h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_check_min",
                      "en_check_min",
                      "he_check_min"
                    )}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Get Number" })}</h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_check_number",
                      "en_check_number",
                      "he_check_number"
                    )}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Call Customer Serivce" })}</h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_customer_service",
                      "en_customer_service",
                      "he_customer_service"
                    )}
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{intl.formatMessage({ id: "Check Internet:" })}</h6>
                  <h6>
                    {dataLanguage(
                      serviceData,
                      "ar_check_internet",
                      "en_check_internet",
                      "he_check_internet"
                    )}
                  </h6>
                </div>
              </div>
            )
          ) : (
            <CircularProgress size={35} sx={{ color: "#45b4e3" }} />
          )}
        </div>
      </Modal>
    </div>
  );
};

ProviderCard.defaultProps = {
  rootDivProps: {},
  hideInformationIcon: false,
};

const mapStateToProps = ({ auth: { user }, darkTheme: { isDarktheme } }) => ({
  user,
  isDarktheme,
});

export default connect(mapStateToProps, null)(ProviderCard);
