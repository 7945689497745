import React from "react";
import PropTypes from "prop-types";
import "./selectStyle.css";
import SelectComp from "react-select";
import classNames from "../../../../node_modules/classnames/index";

const propTypes = {
  otherClassName: PropTypes.string,
  rootDivProps: PropTypes.objectOf({ otherClassName: PropTypes.string }),
};

const defaultProps = {};

export default function Select(props) {
  const { rootDivProps, ...otherProps } = props;
  return (
    <div
      className={classNames("report-dropdown-container", rootDivProps?.otherClassName)}
      {...rootDivProps}
    >
      <SelectComp
        className={classNames("report-dropdown", otherProps.otherClassName)}
        {...otherProps}
      />
    </div>
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
