import React from "react";
import { connect } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

import translate from "../../i18n/translate";
import { conditionalReturn } from "../../shared/utils/utils.js";

const BackNav = ({ title, isDarktheme, icon }) => {
  const history = useHistory();

  return (
    <div
      className={`${isDarktheme ? "dark-companies-nav" : "companies-nav"}`}
      style={{ direction: "ltr" }}
    >
      <div>
        <ArrowBackIcon
          style={{ cursor: "pointer" }}
          color={"primary"}
          onClick={() => history.push("/")}
        />
        <p>{translate(`${title}`)}</p>
        {/* {conditionalReturn(
          !!icon,
          <span style={{ marginLeft: 16 }}>
            <img src={icon} alt={title} width={60} style={{ objectFit: "contain" }} height={40} />
          </span>
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
  isDarktheme,
});

export default connect(mapStateToProps)(BackNav);
