
export const dataLanguage = (item, ar, en, he) => {
    let outPut = ''

    let language = localStorage.getItem('langCity')

    if (language === 'ar' && item[ar]) {
        outPut = item[ar]
    }

    if (language === 'en' && item[en]) {
        outPut = item[en]
    }

    if (language === 'he' && item[he]) {
        outPut = item[he]
    }

    if (outPut) {
        return outPut
    }

    if (!outPut) {
        return item[en]
    }

}