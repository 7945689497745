import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Typing from "../../components/ui/Typing/Typing";
import { getAdvertise, logoutUser } from "../../actions/userAction";
import "./subnav.css";
import { jsonParser } from "shared/utils/ppUtils";

const SubNavbar = ({ logoutUser }) => {
  let language = localStorage.getItem("langCity");

  const [advs, setAdvs] = useState(jsonParser(sessionStorage.getItem(`advertise ${language}`)) || []);
  const location = useLocation();
  let reportPathName = location.pathname;
  let reportPageCondition =
    reportPathName === "/report" ||
    reportPathName === "/profit" ||
    reportPathName === "/running" ||
    reportPathName === "/credit_report" ||
    reportPathName === "/debit_report";

  useEffect(() => {
    const currentTime = Date.now() / 1000;
    const token = localStorage.getItem("jwtUserToken");
    const tokenExp = localStorage.getItem("token exp");

    if (Number(tokenExp) < currentTime || !token) {
      logoutUser();
      window.location.href = "/signin";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const advertise = jsonParser(sessionStorage.getItem(`advertise ${language}`));
    if (!(Array.isArray(advertise) && advertise.length > 0)) {
      getAdvertise(localStorage.langCity).then((result) => {
        if (result.data) {
          const advs = Object.values(result.data).filter((val) => val !== "N/A");
          let adds = advs.map((add) => add[language]);
          sessionStorage.setItem(`advertise ${language}`, JSON.stringify(adds));
          setAdvs(adds);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportPageCondition) {
    return null;
  }

  return (
    <div className="subnav">
      {advs.length > 0 && <Typing sentences={advs?.length ? advs : ["N/A", "N/A", "N/A"]} loop={true} />}
    </div>
  );
};
export default connect(null, { logoutUser })(SubNavbar);
