import React, { useState } from 'react'
import Notiflix from "notiflix";

import Button from '../../common/Button'
import TextField from '@mui/material/TextField';

import ApiRequest from '../../../actions/ApiRequest'
import Checkbox from "@mui/material/Checkbox";
import translate from "../../../i18n/translate";
import { connect } from 'react-redux';

const UpdateMintroute = ({ isDarktheme }) => {
    const [loading, setLoading] = useState(false)
    const [brandId, setBrandId] = useState('')
    const [forceUpdate, setForceUpdate] = useState(false);

    const UpdateMintroute = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await ApiRequest.post(`update_mintroute_products?brand_id=${brandId}&force_update=${forceUpdate}`, {}, { timeout: 3000000 })
            setLoading(false)
            Notiflix.Report.success(`Mintroute products has been updated successfully !`, '', 'Close')
        } catch (err) {
            Notiflix.Report.failure(`Some Thing Went Wrong !`, '', 'Close')
            setLoading(false)
        }
    }

    return (
        <div className="UpdateMointorContiner">
            <form className='contact-us mt-4' onSubmit={UpdateMintroute}>
                <TextField
                    sx={!isDarktheme ? '' : { background: 'white', paddingLeft: '5px', paddingTop: "5px" }}
                    variant="standard"
                    label='Category ID.'
                    name={translate('Category ID.')}
                    type='text'
                    value={brandId}
                    onChange={(e) => setBrandId(e.target.value)}
                />
                <div className="report-checkbox-container">
                    <Checkbox
                        sx={!isDarktheme ? '' : { color: 'white' }}
                        className="report-checkbox"
                        onClick={() => setForceUpdate(!forceUpdate)}
                        checked={forceUpdate}
                        color="primary"
                        id="force-Update"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label style={!isDarktheme ? {} : { color: 'white' }} htmlFor="force-Update">{translate('Force Update')}</label>
                </div>
                <Button
                    type='dark'
                    color="white"
                    style={{ width: '100%' }}
                    className='mt-4'
                    title="Update"
                    formType='submit'
                    loading={loading}
                    disabled={!brandId}
                />
            </form>
        </div>
    )
}
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
    isDarktheme

});
export default connect(mapStateToProps, null)(UpdateMintroute)
