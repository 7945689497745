import React from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { formatSellerOptionsForSelect } from "shared/utils/formatUtils";
import { useMutation, useQuery } from "react-query";
import { getAllSellersApiCaller, syncAccountApiCaller } from "shared/endPoints/apiCallers";
import { conditionalReturn, isNullOrUndefined } from "shared/utils/utils";
import { isArray } from "lodash";
import { END_POINTS } from "shared/endPoints/endPoints";
import Select from "core/core-components/Select/Select";
import { makeStyles } from "@mui/styles";
import Notiflix from "notiflix";
import { isSuccessStatus } from "shared/utils/ppUtils";
import { intl } from "i18n/provider";

const propTypes = {};

const defaultProps = {};

const useSystemSupport = (theme) => ({
  rootGrid: {
    direction: "rtl !important",
  },
});

const useStyle = makeStyles(useSystemSupport);

export default function SystemSupport(props) {
  const classes = useStyle(props);
  const [selectedSeller, setSelectedSeller] = React.useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [fix, setFix] = React.useState(false);

  const {
    isLoading: isSubmitting,
    mutateAsync: syncAccount,
    data: syncedData,
  } = useMutation(() => syncAccountApiCaller({ sellerId: selectedSeller?.value || "all", fix }));
  const { data, isLoading: isLoadingSellers } = useQuery(END_POINTS.getAllSellers.key, getAllSellersApiCaller);
  const allSellers =
    isNullOrUndefined(data?.data?.all_sellers) && !isArray(data?.data?.all_sellers) ? [] : data?.data?.all_sellers;

  async function onSyncAccountClick() {
    try {
      const { data } = await syncAccount();
      if (isSuccessStatus(data)) {
        setShowSuccessDialog(true);
        Notiflix.Notify.success("success");
      } else {
        Notiflix.Notify.failure(intl.formatMessage({ id: "Something went wrong" }));
      }
    } catch (error) {
      console.log("onSyncAccountClick",error);
    }
  }

  function renderSuccessDialog() {
    const onClose = () => setShowSuccessDialog(false);
    return (
      <Dialog open={showSuccessDialog} onClose={onClose}>
        <DialogTitle> {intl.formatMessage({ id: "success" })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Corrected Transactions: {syncedData?.data?.No_Of_Records?.corrected_transactions}
          </DialogContentText>
          <DialogContentText>Failed: {syncedData?.data?.No_Of_Records?.failed}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: "Close" })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function renderAccountSync(params) {
    if (isLoadingSellers) return <p>Loading sellers...</p>;

    return (
      <React.Fragment>
        <Grid item container md={12} xs={12} className={classes.rootGrid}>
          <Grid paddingLeft={3} item flexGrow={0}>
            <Button
              startIcon={conditionalReturn(
                isSubmitting,
                <CircularProgress size={18} style={{ color: "white", marginLeft: 8 }} />
              )}
              style={{ height: "32px" }}
              variant="contained"
              onClick={onSyncAccountClick}
            >
              Sync Account
            </Button>
          </Grid>
          <Grid flexGrow={1}>
            <Select
              options={formatSellerOptionsForSelect(allSellers)}
              fullHeight
              value={selectedSeller}
              isClearable
              onChange={setSelectedSeller}
              placeholder="Seller"
            />
          </Grid>
          <Grid item flexGrow={0}>
            <FormControlLabel
              control={<Checkbox style={{ height: "32px" }} value={fix} onChange={(e) => setFix(e.target.checked)} />}
              label="Fix"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  if (isLoadingSellers) return <p>Loading sellers...</p>;

  return (
    <React.Fragment>
      {renderSuccessDialog()}
      {renderAccountSync()}
    </React.Fragment>
  );
}

SystemSupport.propTypes = propTypes;
SystemSupport.defaultProps = defaultProps;
