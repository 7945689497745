import { SESSION_STORAGE_ITEMS_ENUM } from "shared/constants/localStorageItemsEnum";

export const cleanStorage = () => {
  sessionStorage.removeItem("Jawwal3g");
  sessionStorage.removeItem("JawwalMin");
  sessionStorage.removeItem("JawwalRom");
  sessionStorage.removeItem("JawwalPlus");
  sessionStorage.removeItem("JawwalCredit");
  sessionStorage.removeItem("ooredooCredit");
  sessionStorage.removeItem("ooredoo3g");
  sessionStorage.removeItem("ooredooMin");
  sessionStorage.removeItem("ooredooSuper");
  sessionStorage.removeItem("ooredooYOUTH");
  sessionStorage.removeItem(SESSION_STORAGE_ITEMS_ENUM.azyBundle);
  sessionStorage.removeItem(SESSION_STORAGE_ITEMS_ENUM.hotBundle);
  sessionStorage.removeItem(SESSION_STORAGE_ITEMS_ENUM.peletalkBundle);
};
