import React from "react";
import { Checkbox } from "@mui/material";

const DataTable = ({ rows, isLoading, onRowSelect, selectedRows = [] }) => {
  const headers = ["Ref", "Date", "Must Pay", "Amount", "Pay"];

  const isRowSelected = (row, selectedRows) => {
    const rowKey = row.id || row.ref;
    return (
      selectedRows.find(
        (selectedRow) => (selectedRow.id || selectedRow.ref) === rowKey
      ) !== undefined
    );
  };

  const handleCheckboxChange = (row) => {
    if (isRowSelected(row, selectedRows)) {
      // Remove the row if it's already selected
      onRowSelect(
        selectedRows.filter(
          (selectedRow) =>
            (selectedRow.id || selectedRow.ref) !== (row.id || row.ref)
        )
      );
    } else {
      // Add the row if it's not already selected
      onRowSelect([...selectedRows, row]);
    }
  };

  return (
    <table className="table table-striped mt-4" id="data-table">
      <thead id="data-table-head">
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody id="data-table-body">
        {rows?.map((row, i) => (
          <tr key={i}>
            <td>{row.ref || row.id}</td>
            <td>{row.date}</td>
            <td>{row.must_paid}</td>
            <td>{row.amount}</td>
            <td>
              <Checkbox
                color="success"
                sx={{ padding: 0 }}
                disabled={isLoading}
                onChange={() => handleCheckboxChange(row)}
                checked={isRowSelected(row, selectedRows)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
