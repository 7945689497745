// src/PinEntryModal.js
import React, { useState } from "react";
import PinInput from "react-pin-input";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Alert,
} from "@mui/material";
import ApiRequest from "../../actions/ApiRequest";
import translate from "i18n/translate";

const PinEntryModal = ({
  setHideIsraelCompany,
  open,
  getLastTransaction,
  handleClose,
}) => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const closeDialog = () => {
    setPin("");
    setError("");
    handleClose();
  };
  const handleComplete = (value) => {
    setPin(value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pin.length !== 4) {
      setError("PIN must be 4 digits");
      return;
    }

    try {
      // Call your API here
      const { data } = await ApiRequest.post(`allow_f9?pin=${pin}`);

      if (data.reason === "true") {
        setHideIsraelCompany("false");
        localStorage.setItem("hide companies", "false");
        getLastTransaction("0", 5);
        closeDialog();
      } else {
        setError("wrong pin");
      }

      // Handle success (e.g., navigate to another page or display a success message)
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to submit PIN");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{translate("enterPin")}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box
            sx={{ display: "flex", justifyContent: "center", mb: 2 }}
            dir="ltr"
          >
            <PinInput
              length={4}
              focus
              type="numeric"
              inputStyle={{
                borderColor: "#ccc",
                borderRadius: "5px",
                width: "2rem",
                height: "2rem",
                margin: "0 0.5rem",
              }}
              onComplete={handleComplete}
              onChange={() => setError("")}
            />
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            {translate("cancel")}
          </Button>
          <Button type="submit" color="primary">
            {translate("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PinEntryModal;
