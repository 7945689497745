export function formattedObjects(inputData) {
  const selectedDropdown = checkForDropdown(inputData);

  return Object.keys(inputData)?.map((key) => {
    let isBoolean = inputData[key] === "true" || inputData[key] === "false";

    return {
      desc: key,
      variable: isBoolean ? inputData[key] === "true" : inputData[key],
      aKey: key,
      checkbox: isBoolean,
      selectOptions: key.toLocaleLowerCase() == "value" ? selectedDropdown : [],
    };
  });
}

function checkForDropdown(inputData) {
  const dropdownKey = Object.keys(inputData).find((key) => key === "drop_down");

  if (dropdownKey && inputData[dropdownKey]) {
    return inputData[dropdownKey]
      .split(",")
      .map((item) => ({ value: item, label: item }));
  }

  return [];
}
