import {
  GET_SELLER_REPORTS,
  GET_LAST_TRANSACTION,
  GET_SELLER_POINTS,
  CLEAR_ERRORS,
  GET_SELLER_CANCELATION_REPORTS,
  GET_ERRORS,
  GET_SELLER_PROFIT,
  GET_SELLER_RUNNING_REPORTS,
  UPDATE_TRANSACTION_LOADING,
} from "./types";
import ApiRequest from "./ApiRequest";
import { LOCALES_COUNTRIES } from "../i18n";
import { intl } from "../i18n/provider";
import Toast from "../components/common/Toast";
import Notiflix from "notiflix";
import { logoutUser } from "./userAction";
import { jsonParser } from "shared/utils/ppUtils";
window.Notiflix = Notiflix;
window.Toast = Toast;

export const getSellerRunningReports =
  (
    from_date,
    to_date,
    phone = "",
    offset,
    rows,
    transType = "",
    transStatus = "",
    sellerId,
    reportType
  ) =>
  (dispatch) => {
    transStatus = transStatus === "All" ? "" : transStatus;
    transType = transType === "All" ? "" : transType;
    dispatch(clearErrors());

    const hide = localStorage.getItem("hide companies");

    return ApiRequest.post(
      `get_seller_report?report_type=${reportType}&from_date=${from_date}&to_date=${to_date}&offset=${offset}&no_of_rows=${rows}&seller_id=${sellerId}&trans_status=${transStatus}&trans_type=${transType}&hide=${
        hide === "true" ? "true" : ""
      }`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          dispatch({
            type: GET_SELLER_RUNNING_REPORTS,
            payload: [],
          });
          throw new Error("failed");
        }
        dispatch({
          type: GET_SELLER_RUNNING_REPORTS,
          payload: res.data,
        });
        // return res
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getLast3DaysRunningReports =
  (phone = "") =>
  (dispatch) => {
    dispatch(clearErrors());

    const sellerId = jsonParser(localStorage.currentUser).id;

    let from_date = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
    let to_date = new Date().toISOString().slice(0, 10);
    const hide = localStorage.getItem("hide companies");

    return ApiRequest.post(
      `get_seller_report?from_date=${from_date}&to_date=${to_date}&seller_id=${sellerId}&number=${phone}&hide=${
        hide === "true" ? "true" : ""
      }`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          dispatch({
            type: GET_SELLER_RUNNING_REPORTS,
            payload: [],
          });
          throw new Error("failed");
        }
        dispatch({
          type: GET_SELLER_RUNNING_REPORTS,
          payload: res.data,
        });
        // return res
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getSellerPoints =
  (lang, from_date, to_date, offset, paging) => (dispatch) => {
    dispatch(clearErrors());

    const sellerId = jsonParser(localStorage.currentUser).id;

    return ApiRequest.post(
      `seller_points?from_date=${from_date}&to_date=${to_date}&seller_id=${sellerId}&offset=${offset}&paging=${paging}`
    )
      .then((res) => {
        dispatch({
          type: GET_SELLER_POINTS,
          payload: res.data,
        });
        // return res
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getSellerLast3DaysPoints = () => (dispatch) => {
  dispatch(clearErrors());

  let from_date = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  let to_date = new Date().toISOString().slice(0, 10);

  const sellerId = jsonParser(localStorage.currentUser).id;

  return ApiRequest.post(
    `seller_points?from_date=${from_date}&to_date=${to_date}&seller_id=${sellerId}&offset=0&paging=5`
  )
    .then((res) => {
      dispatch({
        type: GET_SELLER_POINTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const getSellerCancelationReports =
  (from_date, to_date, phone = "", transType = "", transStatus = "") =>
  (dispatch) => {
    transStatus = transStatus === "All" ? "" : transStatus;
    transType = transType === "All" ? "" : transType;

    dispatch(clearErrors());

    const sellerId = jsonParser(localStorage.currentUser).id;
    const hide = localStorage.getItem("hide companies");
    return ApiRequest.post(
      `get_seller_report?from_date=${from_date}&to_date=${to_date}&seller_id=${sellerId}&trans_status=${transStatus}&trans_type=${transType}&number=${phone}&canceled=true&hide=${
        hide === "true" ? "true" : ""
      }`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          dispatch({
            type: GET_SELLER_CANCELATION_REPORTS,
            payload: [],
          });
          throw new Error("failed");
        }
        dispatch({
          type: GET_SELLER_CANCELATION_REPORTS,
          payload: res.data,
        });
        // return res
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getSellerReports =
  (
    from_date,
    to_date,
    phone = "",
    transType = "",
    transStatus = "",
    transId = "",
    cardId = "",
    cancelRequest = "",
    canceled = "",
    amount = "",
    renew = "",
    provider = "",
    cart_type = "",
    offset,
    no_of_rows,
    sellerValue,
    resellerId = "",
    topUpAccountNo = "",
    location = "",
    reportType = "",
    note = ""
  ) =>
  (dispatch) => {
    transStatus =
      transStatus === "All" || transStatus === undefined ? "" : transStatus;
    transType = transType === "All" || transType === undefined ? "" : transType;
    provider = provider === "All" || provider === undefined ? "" : provider;
    cart_type = cart_type === "All" || cart_type === undefined ? "" : cart_type;

    dispatch(clearErrors());

    if (!canceled) {
      canceled = "";
    }

    if (!renew) {
      renew = "";
    }

    if (!cancelRequest) {
      cancelRequest = "";
    }

    offset = offset == 0 ? 0 : (offset - 1) * no_of_rows;
    // const hide = localStorage.getItem("hide companies");
    const hide = false;
    return ApiRequest.post(
      `get_seller_report?location=${location}&report_type=$${reportType}&from_date=${from_date}&to_date=${to_date}&cancelRequest=${cancelRequest}&seller_id=${sellerValue}&cardId=${cardId}&amount=${amount}&canceled=${canceled}&trans_id=${transId}&autorenew=${renew}&trans_status=${transStatus}&trans_type=${transType}&number=${phone}&provider=${provider}&cart_type=${cart_type}&offset=${offset}&no_of_rows=${no_of_rows}&topup_account=${topUpAccountNo}&reseller_id=${resellerId}&hide=${
        hide === "true" ? "true" : ""
      }&note=${note}`
    )
      .then(({ data }) => {
        const failed = data.status === "failed";

        if (data?.reason?.includes("token expired")) {
          Notiflix.Notify.failure(data?.reason);
          dispatch(logoutUser());
          return;
        } else {
          dispatch({
            type: GET_SELLER_REPORTS,
            payload: failed ? [] : data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getSellerProfit =
  (from_date, to_date, offset, no_of_rows, sellerId, reportType) =>
  (dispatch) => {
    dispatch(clearErrors());

    const hide = localStorage.getItem("hide companies");
    return ApiRequest.post(
      `get_seller_report?report_type=${reportType}&from_date=${from_date}&to_date=${to_date}&seller_id=${sellerId}&trans_status=success&trans_type=topup&offset=${offset}&no_of_rows=${no_of_rows}&hide=${
        hide === "true" ? "true" : ""
      }`
    )
      .then((res) => {
        if (res.data.status === "failed") {
          dispatch({
            type: GET_SELLER_PROFIT,
            payload: [],
          });
          throw new Error("failed");
        }
        dispatch({
          type: GET_SELLER_PROFIT,
          payload: res.data,
        });
        // return res
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export const getLastTransaction = (offset, no_of_rows) => (dispatch) => {
  dispatch(clearErrors());
  const hide = localStorage.getItem("hide companies");
  return ApiRequest.post(
    `get_seller_transactions?offset=${offset}&no_of_rows=${no_of_rows}&hide=${hide}`
  )
    .then((res) => {
      dispatch({
        type: GET_LAST_TRANSACTION,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const getPaymentsReport =
  ({
    offset = "0",
    no_of_rows = 5,
    seller_id = "",
    date_to,
    date_from,
    note,
  }) =>
  (dispatch) => {
    dispatch(clearErrors());
    return ApiRequest.post(
      `payments?offset=${offset}&no_of_rows=${no_of_rows}&seller_id=${seller_id}&date_to=${date_to}&date_from=${date_from}&note=${note}`
    )
      .then((res) => {
        return res;
        // dispatch({
        //   type: GET_LAST_TRANSACTION,
        //   payload: res.data,
        // });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing went Wrong !!",
        });
      });
  };

export async function getSystemBalance() {
  return await ApiRequest.post(`system_balance`);
}
export const getSystemBalanceAction = () => (dispatch) => {
  dispatch(clearErrors());
  return ApiRequest.post(`system_balance`)
    .then((res) => {
      return res;
      // dispatch({
      //   type: GET_LAST_TRANSACTION,
      //   payload: res.data,
      // });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: "Somthing went Wrong !!",
      });
    });
};

export const showTransctionDetails = (tran_id, lang) => {
  return new Promise((resolve, reject) => {
    ApiRequest.post(`get_tranaction_status?trand_no=${tran_id}&lang=${lang}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelTransction = (tran_id, cnumber) => {
  const language =
    localStorage.langCity ||
    LOCALES_COUNTRIES[process.env.REACT_APP_DEFAULT_LANG];
  return new Promise((resolve, reject) => {
    ApiRequest.post(
      `peletalk_cancelation?transid=${tran_id}&cnumber=${
        cnumber ? cnumber : ""
      }&language=${language}`
    )
      .then((res) => {
        resolve(res.data);
        if (["false", "failed"].includes(res.data?.status?.toLowerCase?.())) {
          Toast.fire({
            title: intl.formatMessage({
              id: res.data.reason,
            }),
            icon: "error",
          });
          return;
        }
        Toast.fire({
          title: intl.formatMessage({
            id: "Operation Cancel Transaction Successed",
          }),
          icon: "success",
        });
      })
      .catch((err) => {
        Toast.fire({
          title: intl.formatMessage({
            id: "Operation Cancel Transaction Failed",
          }),
          icon: "error",
        });
        reject(err);
      });
  });
};

export const updateTransaction = (parameters) => async (dispatch) => {
  dispatch(clearErrors());
  dispatch(updateTransactionLoading(true));

  try {
    const { data } = await ApiRequest.post(
      `update_transaction?transid=${parameters.transid}&value=${parameters.value}`
    );
    if (["token expired", "error getting admin info"].includes(data?.reason)) {
      dispatch(logoutUser());
      return;
    }
  } catch (err) {
    // open failure modal
    Notiflix.Report.failure(`SomeThing Went Wrong !`, "", "Close");
    // console.log("updateTransaction", err);
  } finally {
    dispatch(updateTransactionLoading(false));
  }
};

export const updateTransactionLoading = (boolean) => {
  return {
    type: UPDATE_TRANSACTION_LOADING,
    payload: boolean,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
