import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from "@mui/material/Checkbox";
import TextFieldGroup from '../common/TextFieldGroup'
import Modal from '../common/Modal'

import { updateActiveProfile, updateDiscountProfile } from '../../actions/userAction'

const UserCompanies = ({ discountData,
    setDiscountData,
    isDarktheme,
    seller,
    getDiscounts,
    updateData,
    updateActiveProfile,
    updateDiscountProfile
}) => {

    const [editDiscountActiveModal, setEditDiscountActiveModal] = useState(false)
    const [updateDiscountLoading, setUpdateDiscountActiveLoading] = useState(false)
    const [activeEditData, setActiveEditData] = useState({
        company: '',
        active: '',
        id: ''
    })
    const [discountEditData, setDiscountEditData] = useState({
        company: '',
        discount: '',
        id: ''
    })

    const [currentPageContent, setCurrentPageContent] = useState([]);
    const [buttons, setButtons] = useState([]);
    const [selectedButtom, setSelectedButtom] = useState(0)

    useEffect(() => {
        if (discountData.length) {
            getPageContent(1, discountData)
            getPagesNumbers(discountData)
        }
    }, [discountData, discountData.length])

    const OpenEditModal = (company, discount, id, active) => {
        setDiscountEditData({
            company,
            discount,
            id
        })

        setActiveEditData({
            company,
            active: active === 'true' ? true : false,
            id
        })
        setEditDiscountActiveModal(true)
    }

    const handleDiscountUpdate = async (setUpdateLoading) => {
        setUpdateLoading(true)

        let data = {
            ...seller,
            company: discountEditData.company,
            newDiscount: discountEditData.discount,
            newActive: activeEditData.active
        }

        await updateDiscountProfile(data)
        await updateActiveProfile(data)
    }

    const getPageContent = (pageNumber, data) => {
        const temp = data.length > 0 ? [...data] : []
        let currentContent = temp?.slice(pageNumber * 10 - 10, pageNumber * 10)
        setCurrentPageContent([...currentContent])
    }

    const getPagesNumbers = (data) => {
        let pagesCount = Math.ceil(data?.length / 10);

        let buttons = []
        for (let i = 1; i <= pagesCount; i++) {
            buttons.push({ index: i })
        }
        setButtons(buttons)
    }

    return (
        <div className={!isDarktheme ? 'mt-4' : 'mt-4 dark-tabel'}>
            <TableContainer >
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Company</TableCell>
                            <TableCell align="center">Discount</TableCell>
                            <TableCell align="center">Active</TableCell>
                            <TableCell align="center">Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageContent.map((discount, i) => (
                            <TableRow
                                key={i.toString()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">{discount.product}</TableCell>
                                <TableCell align="center">{discount.discount}%</TableCell>
                                <TableCell align="center">{discount.active}</TableCell>
                                <TableCell align="center"><EditIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => OpenEditModal(discount.product, discount.discount, discount.id, discount.active)} /> </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='row my-4'>
                {buttons?.map((page, index) =>
                    <button key={index.toString()} onClick={() => {
                        getPageContent(index + 1, discountData)
                        setSelectedButtom(index, discountData)
                    }}
                        id="page-number"
                        className={`${index === selectedButtom ? "selected-btn" : ""}`}
                    >{index + 1}</button>
                )}
            </div>
            <Modal
                visible={editDiscountActiveModal}
                onClose={() => setEditDiscountActiveModal(false)}
                width='400pw'
                title='Edit'
                onSuccess={async () => {
                    await handleDiscountUpdate(setUpdateDiscountActiveLoading)
                    getDiscounts(true, (seller?.seller_id || seller?.id), setDiscountData)
                    setEditDiscountActiveModal(false)
                    setUpdateDiscountActiveLoading(false)
                }}
                onSuccessLoading={updateDiscountLoading}

            >
                <form className='phone-form'>
                    <h6 className='my-2'>{discountEditData.company}</h6>

                    <TextFieldGroup required={true} value={discountEditData.discount} type='number' onChange={(e) => setDiscountEditData({
                        ...discountEditData,
                        discount: e.target.value
                    })} label='Discount' />
                    <div className="report-checkbox-container d-flex align-items-center mt-2">
                        <Checkbox
                            checked={activeEditData.active}
                            onClick={() => setActiveEditData({
                                ...activeEditData,
                                active: !activeEditData.active
                            })}
                            id="active-status"
                        />
                        <label htmlFor="active-status">Active Status</label>
                    </div>
                </form>

            </Modal>
        </div>
    )
}

const mapStateToProps = ({ darkTheme: { isDarktheme }, auth: { user, userDetails } }) => ({
    isDarktheme,
    user,
    userDetails
});

export default connect(mapStateToProps, { updateActiveProfile, updateDiscountProfile })(UserCompanies)
