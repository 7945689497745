import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import translate from "../../../i18n/translate.js";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";

const propTypes = {
  open: PropTypes.bool,
  body: PropTypes.any,
  title: PropTypes.any,
  conformBtnProps: PropTypes.object,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

const defaultProps = {
  conformBtnProps: {},
};

const useStyle = makeStyles(() => ({
  paperRoot: {
    minWidth: 400,
  },
  actions: {
    "& button": {
      marginInline: "4px",
    },
  },
}));

export default function ConformationModal(props) {
  const { open, body, conformBtnProps, title, onConfirmClick, onCancelClick } = props;
  const classes = useStyle(props);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onCancelClick}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        classes={{ paper: classes.paperRoot }}
      >
        <DialogTitle color={"info"} id="confirm-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="text" onClick={onCancelClick}>
            {translate("Cancel")}
          </Button>
          <LoadingButton onClick={onConfirmClick} autoFocus variant="contained" {...conformBtnProps}>
            {translate("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ConformationModal.propTypes = propTypes;
ConformationModal.defaultProps = defaultProps;
