import React, { useState, useEffect } from 'react'
import { refreshColumnStyle } from '../Gaming/reusableFunctions'
import SellerCreditsCard from './SellerCreditsCard'
import translate from "../../i18n/translate";
import { connect } from 'react-redux';


const billsArr = [
    { id: 1, name: 'Jawwal Pay', imageUrl: 'https://play-lh.googleusercontent.com/GbSLI696p0mPFFbvP5bJQjs58SoKALyOTJzRaTn9qFGan1PfWTnKr3rZNEDutk4ZJ-Hg', linkTo: '/seller_credit/Jawwal-Pay' },
    { id: 2, name: 'Malchat', imageUrl: 'https://lh3.googleusercontent.com/GSdo_0bCJ7ZFbTOWKjV83uG7-d3iKN6w8dRuYPMC8CgAEMMYRKn_zGEIJNjonFriXUy9t', linkTo: '/seller_credit/Malchat' },
    { id: 3, name: 'PalPay Wallet', imageUrl: 'https://is3-ssl.mzstatic.com/image/thumb/Purple115/v4/b0/d7/b6/b0d7b6ec-b07d-1656-6bfb-86db81d7d4d5/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.jpeg/1200x600wa.png', linkTo: '/seller_credit/PalPay Wallet' },

]

const SellerCard = ({ isDarktheme }) => {
    const [columnStyle, setColumnStyle] = useState("col-lg-3 col-md-4 col-sm-6 card-md");

    useEffect(() => {
        document.title = "Seller Credits | Phone Play";
        refreshColumnStyle(setColumnStyle);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={!isDarktheme ? 'bg-white' : 'bg-dark'}>
                <h1 className='header-text p-4'>{translate("Seller Credits")}</h1>
            </div>

            <div className='row'>
                {billsArr.map((bill) => <SellerCreditsCard key={bill.id.toString()} bill={bill} columnStyle={columnStyle} />)}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isDarktheme: state.darkTheme.isDarktheme
    }
}

export default connect(mapStateToProps, null)(SellerCard)