// import store from "../../store.js";

export const MINIMUM_BALANCE_MESSAGE = 200;

export const USER_TYPES = {
  supermarket: { value: "supermarket", label: "Supermarket" },
  telecom: { value: "telecom", label: "Telecom" },
  library: { value: "library", label: "Library" },
  computer: { value: "computer", label: "Computer" },
  "self employee": { value: "self employee", label: "Self Employee" },
  reseller: { value: "reseller", label: "Reseller" },
  other: { value: "other", label: "Others" },
};

export const USER_TYPES_WITH_ADMIN = {
  ...USER_TYPES,
  admin: { value: "admin", label: "Admin" },
};

export const ONE_DAY_IN_MSEC = 86400000;

export const CARD_TYPES = {
  game: { value: "GAME", label: "Game" },
  credit: { value: "credit", label: "Credit" },
  addCredits: { value: "ADD CREDITS", label: "ADD CREDITS" },
  min: { value: "MIN", label: "Minutes" },
  "3g": { value: "3g", label: "3G" },
  rom: { value: "ROM", label: "Roaming" },
  youth: { value: "YOUTH", label: "Super Shabab" },
  bill: { value: "BILL", label: "Bill" },
};

export const CURRENCIES_SYMBOLS = {
  ils: "₪",
  usd: "$",
};

export const CARD_TYPES_FROM_API = {
  jawwal3g: "3g",
  jawwalMin: "min",
  jawwalRom: "rom",
  JawwalPlus: "plus",
  jawwalCredit: "credit",
  ooredoo3g: "3g",
  ooredooMin: "min",
  ooredooRom: "rom",
  ooredooShabab: "youth",
  ooredooCredit: "credit",
};

export const JAWWAL_Name = "Jawwal";
export const PARTNER_Name = "Partner";
export const MOBILE12_Name = "012 mobile";
export const GOLAN_Name = "Golan telecom";
export const CELECOME_Name = "Cellcom 2";
export const OOREDOO_Name = "Ooredoo";
export const HOT_Name = "Hot";
export const AZY_Name = "Mobile019";
export const PELEPHONE_Name = "Pelephone";

export const JAWWAL_ID = "001";
export const OOREDOO_ID = "002";
export const HOT_ID = "008";
export const AZY_ID = "009";
export const MOBILE12_ID = "201";
export const GOLAN_ID = "214";
export const PARTNER_ID = "127";
export const CELECOME_ID = "218";
export const PELEPHONE_ID = "095";

export const PELETALK_PROVIDERS_IDS = {
  [CELECOME_Name]: CELECOME_ID,
  [PELEPHONE_Name]: PELEPHONE_ID,
  [PARTNER_Name]: PARTNER_ID,
  [MOBILE12_Name]: MOBILE12_ID,
  [GOLAN_Name]: GOLAN_ID,
};

export const PROVIDER_ID_TO_NAME_DICTIONARY = {
  [JAWWAL_ID]: JAWWAL_Name,
  [PARTNER_ID]: PARTNER_Name,
  [MOBILE12_ID]: MOBILE12_Name,
  [GOLAN_ID]: GOLAN_Name,
  [CELECOME_ID]: CELECOME_Name,
  [OOREDOO_ID]: OOREDOO_Name,
  [HOT_ID]: HOT_Name,
  [AZY_ID]: AZY_Name,
  [PELEPHONE_ID]: PELEPHONE_Name,
};

export const PROVIDER_NAME_TO_ID_DICTIONARY = {
  [JAWWAL_Name]: JAWWAL_ID,
  [PARTNER_Name]: PARTNER_ID,
  [OOREDOO_Name]: OOREDOO_ID,
  [HOT_Name]: HOT_ID,
  [AZY_Name]: AZY_ID,
  ...PELETALK_PROVIDERS_IDS,
};

export const PROVIDER_ROUTES = {
  allProviderRoutes: {
    [JAWWAL_Name]: "/company/jawwalCredit",
    [PARTNER_Name]: "/company/partner",
    [MOBILE12_Name]: "/company/012mobile",
    [GOLAN_Name]: "/company/golan",
    [CELECOME_Name]: "/company/cellcom",
    [OOREDOO_Name]: "/company/ooredoo",
    [HOT_Name]: "/company/hot",
    [AZY_Name]: "/company/azy",
    [PELEPHONE_Name]: "/company/pelephone",
  },
  routes: {
    [JAWWAL_ID]: "/company/jawwalCredit",
    [OOREDOO_ID]: "/company/ooredoo/credit",
    [HOT_ID]: "/company/hot",
    [AZY_ID]: "/company/azy",
  },
  getProviderRoute: function (providerId = "", bundleType = "") {
    if (bundleType?.length) {
      const jawwalBundleRoutes = {
        "3g": "/company/jawwal3g",
        min: "/company/jawwalMin",
        rom: "/company/jawwalRom",
        plus: "/company/JawwalPlus",
      };

      const ooredooBundleRoutes = {
        min: "/company/ooredoo/minutes",
        "3g": "/company/ooredoo/3g",
        rom: "/company/ooredoo/rom",
        youth: "/company/ooredoo/shabab",
      };

      if (providerId === JAWWAL_ID) return jawwalBundleRoutes[bundleType];
      if (providerId === OOREDOO_ID) return ooredooBundleRoutes[bundleType];
    }

    return this.routes[providerId]
      ? this.routes[providerId]
      : "/company/group/:company".replace(
          ":company",
          PROVIDER_ID_TO_NAME_DICTIONARY[providerId]
        );
  },
  // allProviders: function () {
  //   const state = store.getState();
  //   return state?.companies?.allCompanies;
  // },
};

export const BUNDLE_TYPES = {
  rom: "rom",
  min: "min",
  "3g": "3g",
  youth: "youth",
};

export const PELETALK_PROVIDERS = {
  cellcom2: CELECOME_Name,
  pelephone: PELEPHONE_Name,
  partner: PARTNER_Name,
  "012Mobile": MOBILE12_Name,
  golan: GOLAN_Name,
};

export const PALESTINIAN_PROVIDERS = {
  ooredoo: "Ooredoo",
  jawwal: "Jawwal",
};

export const ISRAELI_PROVIDERS = {
  hot: HOT_Name,
  azy: AZY_Name,
  ...PELETALK_PROVIDERS,
};

export const PROVIDERS = {
  ...PALESTINIAN_PROVIDERS,
  ...ISRAELI_PROVIDERS,
};

const isIsraeliProvider =
  Object.values(ISRAELI_PROVIDERS).indexOf((p) =>
    p
      .toLocaleLowerCase()
      .includes(PROVIDER_ID_TO_NAME_DICTIONARY["009"]?.toLocaleLowerCase)
  ) !== -1;

export const IS_MOBILE_DISPLAY = window.innerWidth < 768;

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
  const event = new Event("sessionStorageChanged");
  window.dispatchEvent(event);
};
// console.log(isIsraeliProvider);
