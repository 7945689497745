import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AuthHeaderLink from "./AuthHeaderLink";
import translate from "../../i18n/translate";
import "./authheader.css";
import { FaTiktok } from "react-icons/fa";
import { useIsRtl } from "shared/hooks/ppHooks";

const AuthHeader = ({ user, history, isDarktheme }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isRTL = useIsRtl();
  let path = history.location.pathname;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const element = document.body;
    if (element.scrollHeight > element.clientHeight) {
      document.body.classList.add("padding-class");
    } else {
      document.body.classList.remove("padding-class");
    }
  }, [path]);

  const headerData = [
    { id: 1, icon: "fas fa-mobile", linkTo: "/", title: translate("Telecom") },
    {
      id: 3,
      icon: "fas fa-car-crash",
      linkTo: "/insurance",
      title: translate("Insurance"),
    },
    {
      id: 4,
      icon: "fa fa-gamepad",
      linkTo: "/gaming",
      title: translate("Gaming"),
    },
    {
      id: 5,
      icon: "fa fa-credit-card",
      linkTo: "/cards",
      title: translate("Gift Cards"),
    },
    {
      id: 11,
      icon: "fa fa-tags",
      linkTo: "/pay_bills",
      title: translate("Pay Bills"),
    },
    {
      id: 12,
      icon: "fa fa-sim-card",
      linkTo: "/esim",
      title: translate("Esim"),
    },
    {
      id: 13,
      icon: "fa fa-lock-open",
      linkTo: "/icloud",
      title: translate("Icloud"),
    },
    {
      id: 14,
      icon: "fa fa-wallet",
      linkTo: "/wallet",
      title: translate("wallet"),
    },
    { id: 15, icon: "fa fa-sms", linkTo: "/sms", title: translate("SMS") },
    {
      id: 16,
      icon: "fa fa-sim-card",
      linkTo: "/newSim",
      title: translate("newSim"),
    },
    {
      id: 17,
      icon: "fa fa-credit-card",
      linkTo: "/magneticCard",
      title: translate("magneticCard"),
    },
    {
      id: 18,
      icon: "fa-brands fa-tiktok",
      linkTo: "/tiktok",
      title: translate("tiktok"),
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box
        className={
          !isDarktheme ? "auth-header-container" : "dark-auth-header-container"
        }
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: { xs: "10px", sm: "0" },
          backgroundColor: !isDarktheme ? "#fff" : "#1f1f1f",
          padding: "10px 0",
        }}
      >
        {/* <Typography variant="h6" noWrap component="div">
          Logo
        </Typography> */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon /> <Typography>{translate("selectService")}</Typography>
        </IconButton>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "100%",
            justifyContent: "space-between",
            padding: "0 10px",
          }}
        >
          {headerData.map((data) => (
            <AuthHeaderLink data={data} key={data.id} />
          ))}
        </Box>
        <Drawer
          variant="temporary"
          anchor={isRTL ? "right" : "left"}
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
            <List>
              {headerData.map((data) => (
                <ListItem
                  button
                  key={data.id}
                  onClick={() => history.push(data.linkTo)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon>
                    {data.id === 18 ? (
                      <FaTiktok
                        style={{
                          color: "#45B4E3",
                          fontSize: 20,
                        }}
                      />
                    ) : (
                      <i
                        className={data.icon}
                        style={{
                          color: "#45B4E3",
                          fontSize: 20,
                        }}
                      ></i>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    primary={data.title}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Container>
  );
};

const mapStateToProps = ({ auth: { user }, darkTheme: { isDarktheme } }) => {
  return { user, isDarktheme };
};

export default connect(mapStateToProps)(withRouter(AuthHeader));
