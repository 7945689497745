import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";

import { Checkbox } from "@mui/material";
import PageTitle from "../common/PageTitle";
import ApiRequest from "../../actions/ApiRequest";
import {
  getMadaBillsApiCalled,
  payMadaBillApiCalled,
} from "shared/endPoints/apiCallers";
import translate from "i18n/translate";
import { useHistory } from "react-router-dom";
import PayBillsMadaModal from "./PayBillsMadaModal";
import EditableTextFieldForm from "./shared/InputBills";
import DataTable from "./shared/DataTable";
import { calcTotalAmount } from "./shared/calcTotalAmount";
import GeneralPayModal from "./shared/GeneralPayModal";

const MadaBills = () => {
  const [number, setNumber] = useState("");
  const [identity, setIdentity] = useState("");
  const [selectedBill, setSelectedBill] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [bills, setBills] = useState([]);

  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    const { state } = history.location;
    if (state?.number) {
      setNumber(state.number);
      setIdentity(state.identity);
      getMadaBills({ id: state.identity, number: state.number });
    }
  }, []);

  const {
    isLoading,
    mutate: getMadaBills,
    data: madaPills,
    reset,
  } = useMutation(getMadaBillsApiCalled, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        const errorReason = res?.reason ?? "Something went wrong";
        setErrorMessage(errorReason);
        return;
      }

      ApiRequest.post(
        `/restore_ab_company?company=mada&session_id=${res[0].session_id}`
      );
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });

  useEffect(() => {
    if (madaPills?.length > 0) {
      setBills(madaPills);
    }
  }, [madaPills]);

  const onSubmit = (value) => {
    setNumber(value);
    setSelectedBill([]);
    setBills([]);
    getMadaBills({ id: identity, number: value });
  };

  const clearSelections = () => {
    setSelectedBill(null);
    reset();
  };

  const totalAmount = parseFloat(calcTotalAmount(selectedBill)?.toFixed(2));

  return (
    <div>
      <PageTitle title={translate("Mada Bills")} backPage="/pay_bills">
        <img
          src="https://speedtest.mada.ps/logo-v.png"
          alt="mada"
          className="mada-image"
          style={{ width: 250, height: 150, marginTop: 20 }}
        />
        <EditableTextFieldForm
          initialValue={number}
          label={translate("Phone number")}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
        {bills?.length > 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <p>
                {translate("Total Bills Amount")}:{""}
                <span className="text-info">
                  {totalAmount} {translate("ILS")}
                </span>
              </p>
              {/* <PayBillsMadaModal
                billSelected={selectedBill}
                number={number}
                isDisabled={!selectedBill?.amount || isLoading}
                clearSelections={clearSelections}
              /> */}
              <GeneralPayModal
                selectedBill={selectedBill}
                number={number}
                isDisabled={!totalAmount || isLoading}
                clearSelections={clearSelections}
                totalAmount={totalAmount}
                apiCall={payMadaBillApiCalled}
                showEditAmount
              />
            </div>
            <DataTable
              rows={madaPills}
              isLoading={isLoading}
              onRowSelect={setSelectedBill}
              selectedRows={selectedBill}
            />
          </>
        )}
        {madaPills?.length === 0 && <h5>No Results Found</h5>}
        {errorMessage && <h5 className="text-danger">{errorMessage}</h5>}
      </PageTitle>
    </div>
  );
};

export default MadaBills;
