// File: src/components/CompactPlanCard.js

import React from "react";
import { Card, Box, Typography } from "@mui/material";
import {
  Phone as PhoneIcon,
  Message as MessageIcon,
  DataUsage as DataUsageIcon,
  NetworkCheck as NetworkCheckIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";

// Centralized styled components
const CompactCard = styled(Card)({
  width: 160,
  height: 100,
  cursor: "pointer",
  borderRadius: 12,
  border: "1px solid #e0e0e0",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between", // Adjusted to space out the content
  padding: "5px",
  position: "relative",
  overflow: "hidden",
  paddingTop: "10px",
});

const LogoBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PriceText = styled(Typography)({
  fontWeight: "bold",
  fontSize: 12,
  color: "#D9534F",
});

const IconRow = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  paddingBottom: "5px",
});

// Component Definition
const IsrealSmallCard = ({
  logo,
  price,
  hasMinutes,
  hasData,
  hasMessages,
  supports5G,
}) => {
  return (
    <CompactCard>
      <LogoBox>
        <img src={logo} alt="Company Logo" style={{ width: "80px" }} />
      </LogoBox>
      <PriceText>{price}</PriceText> {/* Price prominently displayed */}
      <IconRow>
        {hasMinutes && <PhoneIcon sx={{ fontSize: 14 }} />}
        {hasData && <DataUsageIcon sx={{ fontSize: 14 }} />}
        {hasMessages && <MessageIcon sx={{ fontSize: 14 }} />}
        {supports5G && <NetworkCheckIcon sx={{ fontSize: 14 }} />}
      </IconRow>
    </CompactCard>
  );
};

export default IsrealSmallCard;
