import { intl } from "i18n/provider.js";
import {
  ISRAELI_PROVIDERS,
  PELETALK_PROVIDERS,
  PELETALK_PROVIDERS_IDS,
  PROVIDER_ID_TO_NAME_DICTIONARY,
} from "shared/constants/constants";
import { SESSION_STORAGE_ITEMS_ENUM } from "shared/constants/localStorageItemsEnum.js";
import ApiRequest from "../../actions/ApiRequest.js";
import { isNullOrUndefined } from "./utils.js";

export function isPeletalk(provider = "") {
  return Object.values(PELETALK_PROVIDERS).includes(provider);
}

export function isSuccessStatus(status) {
  if (typeof status === "string") {
    return status === "success";
  } else if (typeof status === "object") {
    return status.status === "success";
  }
}

export async function checkNumberProvider(
  mobileNumber = "",
  setCurrentNumber,
  setShowData
) {
  try {
    const { data } = await ApiRequest.post(
      `number_provider?number=${mobileNumber}`
    );
    setCurrentNumber?.(data);
    setShowData?.(data?.pci !== "unknown");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const isNaString = (str) => str === "N/A";
export const isUnknownString = (str) => str === "unknown";
export const isUnknownOrNaStr = (str) =>
  isNaString(str) || isUnknownString(str);

export const jsonParser = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log("JSON PARSER", e?.message || e);
    return str;
  }
};

export const isPeletalkProvider = function (providerId) {
  return !isNullOrUndefined(PELETALK_PROVIDERS_IDS[providerId]);
};

export function isIsraeliProvider(providerId) {
  return (
    Object.values(ISRAELI_PROVIDERS).findIndex((p) =>
      p
        .toLocaleLowerCase()
        .includes(
          PROVIDER_ID_TO_NAME_DICTIONARY[providerId]?.toLocaleLowerCase()
        )
    ) > -1
  );
}

export function getProviderBundleSessionKey({ providerId, bundleType }) {
  const providerIdToSessionStorageKeyHMap = window.HMap.from(
    SESSION_STORAGE_ITEMS_ENUM
  );
  let sessionKey;

  if (isPeletalkProvider(providerId))
    return SESSION_STORAGE_ITEMS_ENUM.peletalkBundle;

  const providerName =
    PROVIDER_ID_TO_NAME_DICTIONARY[providerId]?.toLocaleLowerCase();

  sessionKey = providerIdToSessionStorageKeyHMap.findValue(
    (_, sessionStorageKey) => {
      const sessionStorageKeyLowered = sessionStorageKey.toLocaleLowerCase();

      if (!isIsraeliProvider(providerId))
        return (
          sessionStorageKeyLowered.includes(providerName) &&
          sessionStorageKeyLowered.includes(bundleType?.toLocaleLowerCase())
        );

      return sessionStorageKeyLowered.includes(providerName);
    }
  );

  return sessionKey;
}

export function intlTranselate(stringId = "") {
  return intl.formatMessage({ id: stringId });
}
