import {
  Box,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateProfile } from "../../actions/userAction";
import ApiRequest from "../../actions/ApiRequest";
import Toast from "components/common/Toast";
import { set } from "lodash";
import translate from "i18n/translate";
const useStyles = makeStyles((theme) => ({
  textField: {
    width: 200,
    marginRight: 1,
  },
  button: {
    marginLeft: 1,
  },
}));
const ChangeProfile = ({
  desc,
  variable,
  userDetails,
  aKey,
  checkbox = false,
  disabled = false,
  special = false,
  enabled,
  getSellerData,
}) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [originalValue, setOriginalValue] = useState("");
  const [changeText, setChangeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsLoading(true);
    sendApiRequest({ ...userDetails, [aKey]: event.target.checked });
  };

  useEffect(() => {
    setOriginalValue(variable);
    setChangeText(variable);
    setIsChecked(variable === "true" ? true : false);
  }, [variable]);

  const handleEditClick = () => {
    setIsEditing(true);
    setChangeText(originalValue);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    if (changeText === originalValue || changeText.trim().length === 0) return;
    userDetails = { ...userDetails, [aKey]: changeText };
    setIsLoading(true);
    sendApiRequest(userDetails);
  };

  const sendApiRequest = async (userDetails) => {
    try {
      const res = await ApiRequest.post(
        `update_seller_profile?operation=profile&${aKey}=${userDetails[aKey]}&seller_id=${userDetails.id}`
      );
      if (res.data.status.toLowerCase() === "failed") {
        throw new Error(res.data.reason);
      }
      // console.log(res.data);
      await getSellerData();
      setOriginalValue(changeText);
      setIsChecked(!isChecked);
      Toast.fire({
        title: "Settings Updated Successfully!",
        icon: "success",
      });
    } catch (err) {
      Toast.fire({
        title: err.message,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setChangeText(event.target.value);
  };

  return (
    <Box>
      {isLoading && (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      )}
      {checkbox && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
              // Other props you may want to customize
              // ...
            />
          }
          label={desc}
        />
      )}
      {!checkbox && (
        <h6>
          {isEditing ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span>{desc}: </span>
              <TextField
                value={changeText}
                onChange={handleChange}
                //   onBlur={handleSaveClick}
                className={classes.textField}
                variant="outlined"
                size="small"
              />
              <Button
                variant="contained"
                onClick={handleSaveClick}
                startIcon={<SaveIcon sx={{ ml: "2px", mr: "2px" }} />}
                sx={{ marginRight: 1 }}
              >
                {translate("save")}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                startIcon={<CancelIcon sx={{ ml: "2px", mr: "2px" }} />}
                color="error"
                sx={{ ml: "5px", mr: "5px" }}
              >
                {translate("cancel")}
              </Button>
            </Box>
          ) : (
            <React.Fragment>
              <span>{desc}: </span>
              {special ? (
                <span
                  style={{
                    color: enabled ? "green" : "red",
                  }}
                >
                  {originalValue}
                </span>
              ) : (
                <p
                  style={{
                    display: "inline",
                    color: enabled ? "green" : "red",
                  }}
                >
                  {originalValue}
                </p>
              )}
              {!disabled && (
                <IconButton onClick={handleEditClick} size="small">
                  <EditIcon />
                </IconButton>
              )}
              {disabled && <div style={{ marginBottom: "15px" }}></div>}
            </React.Fragment>
          )}
        </h6>
      )}
    </Box>
  );
};

export default ChangeProfile;
