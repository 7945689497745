import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import translate from "../../i18n/translate";
import ApiRequest from "../../actions/ApiRequest";
import { TextField } from "@mui/material";
import { intl } from "i18n/provider";
import Toast from "components/common/Toast";
import Notiflix from "notiflix";
import Button from "components/common/Button";
import Spinner from "components/ui/spinner/Spinner";
import { useHistory } from "react-router";
import UploadImage from "components/esim/UploadImage";
import Modal from "components/esim/EsimModal";

const CELLCOM = "cellcom";
const OOREDOO = "ooredoo";
const JAWWAL = "jawwal";

const myCarriers = [
  {
    carrier: OOREDOO,
    image: "https://mmbiztoday.com/wp-content/uploads/2022/08/1200x1200.png",
  },
  {
    carrier: JAWWAL,
    image:
      "https://cdn6.aptoide.com/imgs/0/3/e/03ede0eac01482e56950361340681eae_icon.png",
  },
  {
    carrier: CELLCOM,
    image:
      "https://www.logotypes101.com/logos/657/F64B7253D9F2F0A7B7118F2AE7C90BC5/cellcom.png",
  },
];

const NewSim = ({ isDarktheme }) => {
  const history = useHistory();
  const sellerId = useSelector((state) => state.auth.user?.id);
  const [carrier, setCarrier] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false); // ['newEsim', 'convertEsim'
  const [message, setMessage] = useState(""); // ['newEsim', 'convertEsim'
  const [image, setImage] = useState(""); // ['newEsim', 'convertEsim'
  const [carriers, setCarriers] = useState(myCarriers);
  const [contactUsData, setContactUsData] = useState({
    name: "",
    idNumber: "",
    simNumber: "",
    idSim: "",
  });
  const [esimType, setEsimType] = useState(); // ['newEsim', 'convertEsim'
  const [err, setErr] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("hide companies") === "true") {
      setCarriers(
        myCarriers.filter(
          (carrier) => carrier.carrier === OOREDOO || carrier.carrier === JAWWAL
        )
      );
    }
  }, []);

  const [formErrors, setFormErrors] = useState({
    name: "",
    idNumber: "",
    idSim: "",
    simNumber: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setContactUsData({
      ...contactUsData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validate = (data) => {
    let valid = true;
    const errors = {
      name: "",
      idNumber: "",
      idSim: "",
    };

    if (!data.name.trim()) {
      errors.name = intl.formatMessage({
        id: "Please enter your name",
      });
      valid = false;
    }
    if (!data.idNumber) {
      errors.idNumber = intl.formatMessage({
        id: "Please enter your ID",
      });
      valid = false;
    }
    if (!data.simNumber) {
      errors.simNumber = intl.formatMessage({
        id: "Please enter the telephone number of the missing sim card",
      });
      valid = false;
    }
    if (!data.idSim) {
      errors.idSim = intl.formatMessage({
        id: "Please enter the empty sim card number (ICCID)",
      });
      valid = false;
    }
    return {
      valid,
      errors,
    };
  };

  useEffect(() => {
    document.title = "Esim | Phone Play";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConfirm = async () => {
    setOpenModal(false);
    setLoading(true);
    try {
      let { name, idNumber, idSim, simNumber } = contactUsData;
      const formData = new FormData();
      formData.append("image", image, `img.jpg`);
      const { data } = await ApiRequest.post(
        `sim_swap?carrier=${carrier}&name=${name}&id_number=${idNumber}&iccid=${idSim}&sim_number=${simNumber}`,
        formData
      );
      if (data.status === "failed") {
        throw new Error(data.message);
      }
      setLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Your Message has been sent successfully",
        }),
        icon: "success",
      });
      setContactUsData({
        name: "",
        idNumber: "",
        simNumber: "",
        idSim: "",
      });
      setImage("");
      setCarrier(null);
      setEsimType(null);
      history.push("/");
    } catch (err) {
      setErr(true);
      setLoading(false);
      Toast.fire({
        title: intl.formatMessage({
          id: "Something Went Wrong",
        }),
        icon: "error",
      });
      Notiflix.Report.failure("Something Went Wrong", "", "Close");
    }
  };
  const onSubmit = async (e) => {
    let { name, idNumber, idSim, simNumber } = contactUsData;

    e.preventDefault();
    const { valid, errors } = validate(contactUsData);
    if (!valid) {
      setFormErrors(errors);
      return;
    }
    setErr(false);

    const message = intl
      .formatMessage({ id: "newSimConfirmationMessage" })
      .replace("{name}", name)
      .replace("{idNumber}", idNumber)
      .replace("{idSim}", idSim)
      .replace("{simNumber}", simNumber);
    setMessage(message);
    setOpenModal(true);
  };

  const backgroundStyle = {
    backgroundColor: "#fff",
    width: 170,
    height: 170,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    marginBottom: 16,
  };
  const selectedWalletStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // subtle shadow
    transform: "scale(1.05)", // slightly larger
  };
  const unselectedWalletStyle = {
    filter: "blur(3px)", // Apply a blur effect
    opacity: 0.7, // Reduce the opacity to make it look less focused
  };

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("newSimTitle")}</h1>
        </div>
      </div>
      <div className="row">
        <h3 className="text-center mb-4">{translate("Pick your carrier")}:</h3>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 32,
            justifyContent: "center",
          }}
        >
          {carriers.map((currentCarrier, index) => (
            <div
              onClick={() => setCarrier(currentCarrier.carrier)}
              style={{
                ...backgroundStyle,
                ...(carrier && carrier === currentCarrier.carrier
                  ? selectedWalletStyle
                  : {}),
              }}
              key={index}
            >
              <img
                src={currentCarrier.image}
                alt={currentCarrier.carrier}
                style={{
                  borderRadius: 16,
                  cursor: "pointer",
                  ...(carrier && carrier !== currentCarrier.carrier
                    ? unselectedWalletStyle
                    : {}),
                }}
              />
            </div>
          ))}
        </div>
        {carrier && (
          <form className="contact-us mt-4" onSubmit={onSubmit}>
            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "Enter Your Name",
              })}
              name="name"
              type={"string"}
              onChange={onChange}
              value={contactUsData["name"]}
              error={formErrors["name"]}
              helperText={formErrors["name"]}
            />

            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "Enter Your ID",
              })}
              name="idNumber"
              type="idNumber"
              onChange={onChange}
              value={contactUsData["idNumber"]}
              error={formErrors["idNumber"]}
              helperText={formErrors["idNumber"]}
            />
            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "enter the telephone number of the missing sim card",
              })}
              name="simNumber"
              onChange={onChange}
              value={contactUsData.simNumber}
              error={formErrors.simNumber}
              helperText={formErrors.simNumber}
            />
            <TextField
              variant="standard"
              label={intl.formatMessage({
                id: "Enter the empty sim card number (ICCID)",
              })}
              name="idSim"
              onChange={onChange}
              value={contactUsData.idSim}
              error={formErrors.idSim}
              helperText={formErrors.idSim}
            />
            {carrier === CELLCOM || <UploadImage setImage={setImage} />}

            <Button
              type="primary"
              bgColor="#242526"
              style={{ width: "100%", marginBottom: 16 }}
              className="mt-4"
              title="submit"
              formType="submit"
              disabled={carrier !== CELLCOM && !image}
            />
            {err && (
              <p className="text-danger mt-2">
                {intl.formatMessage({ id: "Some Thing Went Wrong !" })}
              </p>
            )}
          </form>
        )}
        <Modal
          openModal={openModal}
          message={message}
          setOpenModal={setOpenModal}
          onConfirm={handleOnConfirm}
        />
        {loading && <Spinner />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};

export default connect(mapStateToProps)(NewSim);
