import React, { useState } from "react";
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../common/Modal";
import TextFieldGroup from "./../../common/TextFieldGroup";
import CircularProgress from "@mui/material/CircularProgress";
import translate from "../../../i18n/translate";

import { getAllCompanies, addCompany } from "../../../actions/companiesAction";
import { useMount } from "shared/hooks/ppHooks";

const CompaniesPanel = ({
  getAllCompanies,
  loading,
  allCompanies,
  addCompany,
  addCompaniesLoading,
  isDarktheme,
}) => {
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [addCompanyData, setAddCompanyData] = useState(null);

  useMount(() => {
    getAllCompanies();
  });

  const onChange = (e) => {
    const { value, name } = e.target;
    setAddCompanyData({
      ...addCompanyData,
      [name]: value,
    });
  };

  return (
    <div className="d-flex flex-column align-items-center mt-4">
      <div
        className="d-flex justify-content-end my-4"
        style={{ width: "100%" }}
      >
        <button
          className="btn btn-primary"
          onClick={() => setAddCompanyModal(true)}
        >
          {translate("AddBalance A Company")}
        </button>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, margin: "auto" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="1"
              >
                {translate("ID")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="2"
              >
                {translate("Name")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="3"
              >
                {translate("location")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="4"
              >
                {translate("Image")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="5"
              >
                {translate("active")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="6"
              >
                {translate("Edit")}
              </TableCell>
              <TableCell
                className={!isDarktheme ? "" : "text-light"}
                align="center"
                key="7"
              >
                {translate("Delete")}
              </TableCell>
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {allCompanies.map((company, i) => (
                <TableRow
                  key={i.toString()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {company.id}
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    {company.company_name}
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    {company.location}
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    <img
                      height="60"
                      width="80"
                      alt="company"
                      src={company.url}
                      style={{ objectFit: "contain" }}
                    />
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    {company.active}
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    <EditIcon color="primary" sx={{ cursor: "pointer" }} />{" "}
                  </TableCell>
                  <TableCell
                    className={!isDarktheme ? "" : "text-light"}
                    align="center"
                  >
                    <DeleteIcon color="error" sx={{ cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <CircularProgress size={80} sx={{ marginTop: "160px" }} />}
      <Modal
        visible={addCompanyModal}
        onClose={() => setAddCompanyModal(false)}
        width={"45vw"}
        title="AddBalance A Company"
      >
        <form
          className="phone-form"
          onSubmit={(e) => {
            e.preventDefault();
            addCompany(addCompanyData).then(() => setAddCompanyModal(false));
          }}
        >
          <TextFieldGroup
            placeholder="product id"
            name="product_id"
            type="text"
            value={addCompanyData?.product_id}
            label={translate("product id")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="product name"
            name="product_name"
            type="text"
            value={addCompanyData?.product_name}
            label={translate("product name")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="discount"
            name="discount"
            type="text"
            value={addCompanyData?.discount}
            label={translate("discount")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="active"
            name="active"
            type="text"
            value={addCompanyData?.active}
            label={translate("active")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="type"
            name="type"
            type="text"
            value={addCompanyData?.type}
            label={translate("type")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="category"
            name="category"
            type="text"
            value={addCompanyData?.category}
            label={translate("category")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="url"
            name="url"
            type="text"
            value={addCompanyData?.url}
            label={translate("url")}
            onChange={onChange}
            required={true}
          />
          <TextFieldGroup
            placeholder="location"
            name="location"
            type="text"
            value={addCompanyData?.location}
            label={translate("location")}
            onChange={onChange}
            required={true}
          />
          <div
            className="d-flex justify-content-end align-items-center mt-4"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger mx-3"
              onClick={() => setAddCompanyModal(false)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {addCompaniesLoading ? (
                <CircularProgress sx={{ color: "white" }} size={20} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  companies: { allCompanies, loading, addCompaniesLoading },
  darkTheme: { isDarktheme },
}) => ({
  allCompanies,
  loading,
  addCompaniesLoading,
  isDarktheme,
});

export default connect(mapStateToProps, { getAllCompanies, addCompany })(
  CompaniesPanel
);
