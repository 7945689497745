import { HOVER_STYLE, textEllipsis } from "shared/constants/stylesConstants";

const paymentsReportStyles = (theme) => ({
  sellerName:{
    ...textEllipsis(150)
  },
  copyBtn: {
    position: "absolute",
    padding: 0,
    left: "40%",
    top: 26,
    "& svg": {
      fontSize: 16,
    },
  },
  dateDropdown: {
    width: "100%",
    height: "32px !important",
    "& > div": {
      "&:hover": HOVER_STYLE,
    },  },
});
export default paymentsReportStyles;
