import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import translate from "../../i18n/translate";
import "./styles.css";
import classNames from "classnames";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const propTypes = {
  selectedDateFrom: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  selectedDateTo: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  arrowInsteadOfTo: PropTypes.bool,
  onSelectDateFrom: PropTypes.func,
  onSelectDateTo: PropTypes.func,
  className: PropTypes.string,
  toClassName: PropTypes.string,
  fromClassName: PropTypes.string,
  divContainerClassName: PropTypes.string,
  arrowProps: PropTypes.object,
};

const defaultProps = {};

export default function FromToDatePicker(props) {
  const {
    selectedDateFrom,
    arrowInsteadOfTo,
    selectedDateTo,
    disabled,
    onSelectDateFrom,
    onSelectDateTo,
    className,
    toClassName,
    fromClassName,
    divContainerClassName,
    arrowProps,
  } = props;

  return (
    <React.Fragment>
      <div
        style={{ position: "relative" }}
        className={classNames(
          divContainerClassName,
          "section-two d-flex justify-content-between align-items-center"
        )}
      >
        <DatePicker
          selected={selectedDateFrom}
          type="date"
          dateFormat="dd-MM-yyyy"
          className={classNames(
            className,
            toClassName,
            "date-picker form-control report-form-control-date-picker"
          )}
          onChange={onSelectDateFrom}
          disabled={disabled}
        />
        {arrowInsteadOfTo ? <ArrowForwardIcon {...arrowProps} /> : <h6>{translate("to")}</h6>}
        <DatePicker
          selected={selectedDateTo}
          type="date"
          dateFormat="dd-MM-yyyy"
          className={classNames(
            className,
            fromClassName,
            "date-picker form-control report-form-control-date-picker"
          )}
          onChange={onSelectDateTo}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  );
}

FromToDatePicker.propTypes = propTypes;
FromToDatePicker.defaultProps = defaultProps;
