import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Badge from "@mui/material/Badge";
import classes from "./NotificationContainer.module.css";

import { deleteUpdateNotifications } from "../../actions/notificationActions";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import translate from "i18n/translate";
import { set } from "lodash";
import NotificationsIcon from "@mui/icons-material/Notifications";

// import { GoRead } from "react-icons/go";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";

const NotificationContainer = ({
  list,
  deleteUpdateNotifications,
  singleNotificationLoading,
  unseenNotifications,
  newNotification,
  setNewNotification,
}) => {
  // console.log("list", list);
  // list = [
  //   {
  //     title: "Ahmad Marei",
  //     message: " king king king     ",
  //   },
  //   {
  //     title: "Ahmad Marei",
  //     message:
  //       " king king king   king king king  king king king  king king king     ",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const listRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (list.length === 0) {
      handleMenuClose();
    }
  }, [list]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", (e) =>
      handleClickOutside(e, listRef, setShow)
    );

    return () => {
      document.removeEventListener("click", (e) =>
        handleClickOutside(e, listRef, setShow)
      );
    };
  }, []);

  const handleClickOutside = (event, ref, setState) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setState(false);
    }
  };

  const renderMenu = (
    <Menu
      style={{ paddingTop: "0px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      keepMounted
      open={!!anchorEl}
      onClose={handleMenuClose}
    >
      <MenuItem
        style={{
          padding: "10px 16px",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundColor: "#03b2cb",
          color: "white",
          marginTop: -8,
          width: 250,
        }}
      >
        {translate("Notifications")}
      </MenuItem>
      {list.map((notification, index) => (
        <React.Fragment key={notification.index}>
          <MenuItem
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 16px 0",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: 250,
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
          >
            <h6
              style={{
                width: "calc(100% - 77px)",
                fontSize: 16,
                fontWeight: "bold",
                wordWrap: "break-word",
                overflow: "hidden",
              }}
            >
              {notification.title}
            </h6>
            <p
              style={{
                fontSize: 14,
                color: "InfoText",
                width: "100%",
              }}
            >
              {notification.message}
            </p>
            <Box
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* {singleNotificationLoading && (
                <CircularProgress
                  size={15}
                  color="secondary"
                  sx={{ margin: "0 8px" }}
                />
              )} */}
              <IconButton
                onClick={() => {
                  deleteUpdateNotifications(
                    "view",
                    notification.index,
                    notification.view === "0" ? 1 : 0
                  );
                }}
              >
                <MdOutlineMarkEmailRead
                  style={{
                    margin: "0 8px",
                    color: notification.view !== "0" ? "" : "#45b6fe",
                  }}
                />
              </IconButton>
              <Divider orientation="vertical" />
              <IconButton
                onClick={() => {
                  deleteUpdateNotifications(
                    "delete",
                    notification.index,
                    notification.view === "0" ? 1 : 0
                  );
                }}
              >
                <RiDeleteBin5Fill
                  color={`${notification.view !== "0" ? "" : "red"}`}
                />
              </IconButton>
            </Box>
          </MenuItem>
          {index !== list.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Menu>
  );

  return (
    <div
      className={`d-flex align-items-center justify-content-center notification-badge p-2 ${
        newNotification ? classes["shake-effect"] : ""
      }`}
      ref={listRef}
      style={{
        // position: "fixed",
        // backgroundColor: "white",
        borderRadius: "50px",
        top: 15,
        zIndex: 1001,
        cursor: "pointer",
      }}
    >
      <IconButton
        onClick={(e) => {
          if (list.length > 0) {
            setShow(!show);
            handleMenuOpen(e);
            setNewNotification(false);
          }
        }}
      >
        <Badge
          badgeContent={unseenNotifications}
          color="error"
          // onClick={() => setShow(!show)}
        >
          <NotificationsIcon fontSize="large" />
        </Badge>
      </IconButton>
      <div className="position-relative">{renderMenu}</div>
    </div>
  );
};

const mapStateToProps = ({
  notification: { singleNotificationLoading, unseenNotifications },
}) => ({
  singleNotificationLoading,
  unseenNotifications,
});

export default connect(mapStateToProps, { deleteUpdateNotifications })(
  NotificationContainer
);
