import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { useIntl } from "react-intl";
import "./style.css";
import {
  getSellerPoints,
  getSellerLast3DaysPoints,
} from "../../../actions/reportsAction";
import Spinner from "../../ui/spinner/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import PointsHeader from "./PointsHeader";
import Pagination from "../../common/Pagination";
import ReportHeader from "../../report/ReportHeader";
import ExportButton from "components/common/ExportButton";

const Languages = {
  en: "english",
  ar: "arabic",
  il: "israel",
};

const Points = ({
  getSellerPoints,
  sellerPoints,
  getSellerLast3DaysPoints,
  history,
  isDarktheme,
}) => {
  const intl = useIntl();

  const [loading, isLoading] = useState(false);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(
    new Date(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000))
  );

  // paging
  const [sort, setSort] = useState({ value: "5", label: 5 });
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  useEffect(() => {
    document.title = "Seller Points | PhonePlay ";

    if (history.location.search === "?checkPoints") {
      isLoading(true);
      getSellerLast3DaysPoints().then(() => {
        isLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    if (sellerPoints.length) {
      setCount(Math.ceil(Number(sellerPoints[0].total_count) / sort.label));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerPoints]);

  const initSellerPoints = (offset, paging) => {
    isLoading(true);
    getSellerPoints(
      Languages[intl.locale],
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
      offset,
      paging
    ).then((res) => {
      isLoading(false);
    });
  };

  const handleSearch = () => {
    setPage(1);
    initSellerPoints("0", sort.value);
  };

  const handleOffsetValue = (event, value) => {
    setPage(value);
    let queryOffset = value * sort.label - sort.label;
    initSellerPoints(queryOffset, sort.value);
  };

  const handleSortChange = (v) => {
    setPage(1);
    setSort(v);
    if (sellerPoints?.length) {
      initSellerPoints("0", v.value);
    }
  };

  let total = 0;
  // sellerPoints?.forEach((item) => {
  //   total = total + parseFloat(item.points)
  // })

  return (
    <>
      <ReportHeader />
      <div className={!isDarktheme ? "points-page" : "dark-points-page"}>
        <PointsHeader />
        <div>
          <div className="mt-5">
            <div className="row">
              <div className="form-group row">
                <label className="col-sm-1 col-form-label">
                  {translate("from")}
                </label>
                <div className="col-sm-4">
                  <DatePicker
                    selected={dateFrom}
                    type="date"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    onChange={(e) => setDateFrom(e)}
                  />
                </div>
                <label className="col-sm-1 col-form-label">
                  {translate("to")}
                </label>
                <div className="col-sm-4">
                  <DatePicker
                    selected={dateTo}
                    type="date"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    onChange={(e) => setDateTo(e)}
                  />
                </div>
                <div className="col-sm-2">
                  <button onClick={handleSearch} className="btn sign-but">
                    {translate("search")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row table-wrapper">
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "flex-start" }}
            >
              <div className="table-title ">
                <h5>{translate("Points Table")}</h5>
              </div>
              <div
                className=""
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <ExportButton reports={sellerPoints} />
              </div>
            </div>
            <Pagination
              count={count}
              page={page}
              handleOffsetValue={handleOffsetValue}
              handleSortChange={handleSortChange}
              sort={sort}
            />

            <table>
              <thead>
                <tr>
                  <th>{translate("movmentNo")}</th>
                  <th>{translate("date")}</th>
                  <th>{translate("theseller")}</th>
                  <th>{translate("points")}</th>
                  <th>{translate("running_balance")}</th>
                  <th>{translate("The type")}</th>
                </tr>
              </thead>
              <tbody>
                {sellerPoints?.map((item, index) => {
                  return (
                    <tr key={index.toString()}>
                      <td>{item?.trans_id}</td>
                      <td>{item?.date}</td>
                      <td>{item?.seller}</td>
                      <td>{item?.points}</td>
                      <td>{item?.total_points}</td>
                      <td>{item?.type}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ border: "none" }}></td>
                  <td style={{ border: "none" }}></td>
                  <td>{translate("the_sum")}</td>
                  <td>{total?.toFixed(4)}</td>
                  <td style={{ border: "none" }}></td>
                  <td style={{ border: "none" }}></td>
                </tr>
              </tbody>
            </table>

            <div></div>
          </div>
        </div>
        {loading && <Spinner />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sellerPoints: state.reports.sellerPoints,
  isDarktheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, {
  getSellerPoints,
  getSellerLast3DaysPoints,
})(Points);
