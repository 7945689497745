import React, { useState } from "react";
import Select from "react-select";
import Notiflix from "notiflix";

import Modal from "../common/Modal";
import TextFieldGroup from "../common/TextFieldGroup";

import { intl } from "../../i18n/provider";
import ApiRequest from "../../actions/ApiRequest";

const ShareModal = ({
  shareModal,
  setShareModal,
  referalCode,
  contentMessage = null,
}) => {
  const [share, setShare] = useState({
    value: "",
    label: intl.formatMessage({ id: "Share Option" }),
  });
  const encodedData = encodeURIComponent(contentMessage); // URL-encode the original data

  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(contentMessage);
  const [shareLoading, setShareLoading] = useState(false);

  const shareOptions = [
    { value: "whatsapp", label: intl.formatMessage({ id: "whatsapp" }) },
    { value: "sms", label: intl.formatMessage({ id: "SMS" }) },
    { value: "email", label: intl.formatMessage({ id: "Email" }) },
  ];

  const shareProfile = async () => {
    setShareLoading(true);
    const message = contentMessage
      ? `Voucher Code: ${referalCode} ${encodedData}`
      : `Voucher Code: ${referalCode}`;
    try {
      await ApiRequest.post(
        `share?share_id=${share.value}&number=${number}&message=${message}&email=${email}`
      );
      Notiflix.Notify.success("Message Sent");
    } catch {
      Notiflix.Notify.failure("Something went wrong!!, please try again");
    } finally {
      setShareLoading(false);
      setShare({
        value: "",
        label: intl.formatMessage({ id: "Share Option" }),
      });
      setNumber("");
      setMessage("");
      setEmail("");
    }
  };

  return (
    <Modal
      visible={shareModal}
      onClose={() => setShareModal(false)}
      title="share"
      width="500px"
      height="auto"
      onSuccess={async () => {
        await shareProfile();
        setShareModal(false);
      }}
      onSuccessDisable={!number || !share.value}
      onSuccessLoading={shareLoading}
    >
      <form className="phone-form">
        <div className="select-input">
          <label style={{ marginBottom: 6 }}>
            <span>
              {intl.formatMessage({ id: "Share Option" })}
              <i className="asterisk">*</i>
            </span>
          </label>
          <Select
            name="share"
            placeholder={intl.formatMessage({ id: "Share Option" })}
            options={shareOptions}
            value={share}
            required={true}
            onChange={(v) => {
              setShare(v);
              if (v.value === "sms" || v.value === "whatsapp") {
                setEmail("");
              } else {
                setNumber("");
              }
            }}
          />
        </div>
        {(share?.value === "sms" || share?.value === "whatsapp") && (
          <TextFieldGroup
            name="number"
            placeholder={intl.formatMessage({ id: "number" })}
            label={intl.formatMessage({ id: "number" })}
            value={number}
            type="number"
            onChange={(e) => setNumber(e.target.value)}
          />
        )}
        {share?.value === "email" && (
          <TextFieldGroup
            name="email"
            placeholder={intl.formatMessage({ id: "email" })}
            label={intl.formatMessage({ id: "email" })}
            value={email}
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        {!contentMessage && (
          <TextFieldGroup
            name="message"
            placeholder={intl.formatMessage({ id: "message" })}
            label={intl.formatMessage({ id: "message" })}
            value={message}
            type="text"
            onChange={(e) => setMessage(e.target.value)}
          />
        )}
      </form>
    </Modal>
  );
};

export default ShareModal;
