import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import translate from "../../../i18n/translate";
import { isEmpty } from "lodash";
import { getInsurances } from "../../../actions/insuranceAction";
import { useIntl } from "react-intl";
import Stepper from "../../common/Stepper";
import VehicleStepper from "./VehicleStepper";
import LoadStepper from "./LoadStepper";
import PriceStepper from "./PriceStepper";
import { getInsuranceCalculations } from "../../../actions/insuranceAction";
import { refreshColumnStyle } from "../../Gaming/reusableFunctions";
import InsuranceCard from "./InsuranceCard";
import { intl } from "../../../i18n/provider";

import "./style.css";

const Languages = {
  en: "english",
  ar: "arabic",
  il: "israel",
};

const driverTypes = [
  { value: "private", label: intl.formatMessage({ id: "Private" }) },
  { value: "commercial", label: intl.formatMessage({ id: "commercial" }) },
  {
    value: "taxi_1_driver",
    label: intl.formatMessage({ id: "One Driver - Taxi" }),
  },
  {
    value: "taxi_2_drivers",
    label: intl.formatMessage({ id: "Two Drivers - Taxi" }),
  },
  {
    value: "taxi_multiple_drivers",
    label: intl.formatMessage({ id: "Multiple Driver - Taxi" }),
  },
];

const Insurance = ({
  getInsurances,
  insurances,
  getInsuranceCalculations,
  insuranceCalculations,
  submitInsuranceLoading,
  isDarktheme,
}) => {
  const intl = useIntl();

  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 card-md"
  );
  const [steps, setSteps] = useState([
    "Vehicle Type",
    "Vehicle Load",
    "Vehicle Value",
    "Insurances",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [driverType, setDriverType] = useState(null);
  const [engineSize, setEngineSize] = useState(null);
  const [ton, setTon] = useState(null);
  const [passenger, setPassenger] = useState(null);
  const [insuranceType, setInsuranceType] = useState(null);
  const [vehiclePrice, setVehiclePrice] = useState("");
  const [loadVehicleData, setLoadVehicleData] = useState({
    firstName: "",
    SecondName: "",
    ThirdName: "",
    FamilyName: "",
    mobileNumber: "",
    identityNumber: "",
    vehicleNumber: "",
    vehicleType: "",
    vehicleModel: "",
    productionYear: "",
  });

  const [basicFee, setBasicFee] = useState(0);
  const [comprehensiveFee, setComprehensiveFee] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    document.title = "Insurance | PhonePlay ";
    if (Array.isArray(insurances) && isEmpty(insurances)) {
      initInsurances();
    }
    refreshColumnStyle(setColumnStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (driverType) {
      let newSteps = [...steps];
      if (driverType.value === "private" && !engineSize) {
        newSteps[1] = "Engine Size";
      }
      if (driverType.value === "commercial" && !ton) {
        newSteps[1] = "Vehicle Load";
      }
      if (
        (driverType.value === "taxi_1_driver" ||
          driverType.value === "taxi_2_drivers" ||
          driverType.value === "taxi_multiple_drivers") &&
        !passenger
      ) {
        newSteps[1] = "Passengers count";
      }
      setSteps(newSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverType]);

  const handleVehicleDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "vehicleType") {
      //To empty vehicle model when type is changed
      setLoadVehicleData({
        ...loadVehicleData,
        [name]: value,
        vehicleModel: "",
      });
      return;
    }

    setLoadVehicleData({
      ...loadVehicleData,
      [name]: value,
    });
  };

  const handleInsuranceCalculations = (e) => {
    e.preventDefault();
    getInsuranceCalculations(
      driverType?.value,
      engineSize?.value,
      ton?.value,
      passenger,
      loadVehicleData,
      setActiveStep
    );
  };

  useEffect(() => {
    if (insuranceCalculations && !vehiclePrice) {
      setBasicFee(insuranceCalculations.basic_free);
      setTotal(insuranceCalculations.basic_free);
      setComprehensiveFee(0);
      return;
    }
    if (insuranceCalculations && vehiclePrice) {
      setBasicFee(insuranceCalculations.basic_free);

      if (driverType.value === "private" && Number(vehiclePrice) <= 57000) {
        setComprehensiveFee(1000);
        setTotal((1000 + Number(insuranceCalculations.basic_free)).toFixed(2));
        return;
      }
      let comprehensiveValue = (
        (Number(vehiclePrice) / 100) *
        Number(insuranceCalculations.comprehensive_fee)
      ).toFixed(2);
      setComprehensiveFee(comprehensiveValue);
      setTotal(
        (
          Number(comprehensiveValue) + Number(insuranceCalculations.basic_free)
        ).toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceCalculations]);

  const initInsurances = () => {
    getInsurances(Languages[intl.locale]);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setLoadVehicleData({
      firstName: "",
      SecondName: "",
      ThirdName: "",
      FamilyName: "",
      mobileNumber: "",
      identityNumber: "",
      vehicleNumber: "",
      vehicleType: "",
      vehicleModel: "",
      productionYear: "",
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1));
  };
  return (
    <>
      <div className={!isDarktheme ? "insurance" : "dark-insurance"}>
        <div>
          <div className="row mb-5 wrapper">
            <Stepper
              steps={steps}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <VehicleStepper
                driverType={driverType}
                driverTypes={driverTypes}
                setDriverType={setDriverType}
                handleNext={handleNext}
              />
            )}

            {activeStep === 1 && (
              <LoadStepper
                driverType={driverType}
                setEngineSize={setEngineSize}
                setPassenger={setPassenger}
                setTon={setTon}
                passenger={passenger}
                ton={ton}
                engineSize={engineSize}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            )}
            {activeStep === 2 && (
              <PriceStepper
                setVehiclePrice={setVehiclePrice}
                vehiclePrice={vehiclePrice}
                handleNext={handleInsuranceCalculations}
                handleBack={handleBack}
                insuranceType={insuranceType}
                setInsuranceType={setInsuranceType}
              />
            )}
            {activeStep === 3 && (
              <div className="vehicle-stepper">
                <div
                  className={
                    !isDarktheme
                      ? "message-container"
                      : "dark-message-container"
                  }
                  // style={{ width: "420px" }}
                >
                  {basicFee && (
                    <h4>
                      {translate("Basic Insurance Fee")}:{" "}
                      <span>
                        {basicFee} {translate("ILS")}
                      </span>{" "}
                    </h4>
                  )}
                  {comprehensiveFee > 0 && (
                    <h4 style={{ borderBottom: "1px solid lightblue" }}>
                      {translate("Comprehensive Fee")}:{" "}
                      <span>
                        {comprehensiveFee} {translate("ILS")}
                      </span>
                    </h4>
                  )}
                  {total > 0 && comprehensiveFee > 0 && (
                    <h4 style={{ color: "#dc3545" }}>
                      {translate("total")}:{" "}
                      <span>
                        {total} {translate("ILS")}
                      </span>{" "}
                    </h4>
                  )}
                </div>

                <div
                  className="d-flex justify-content-start"
                  // style={{ width: "100%" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={handleBack}
                    style={{ position: "static" }}
                  >
                    {translate("Back")}
                  </button>
                </div>

                <div className="row mb-5 wrapper p-4">
                  <div
                    className="d-flex align-items-center justify-content-center p-4"
                    style={{ width: "100%" }}
                  >
                    <p
                      style={
                        !isDarktheme
                          ? { borderBottom: "1px solid" }
                          : { borderBottom: "1px solid", color: "white" }
                      }
                    >
                      {translate("Please Select A Company")}
                    </p>
                  </div>
                  {insurances.map((insurance, index) => (
                    <InsuranceCard
                      key={index.toString()}
                      columnStyle={columnStyle}
                      insurance={insurance}
                      handleVehicleDataChange={handleVehicleDataChange}
                      loadVehicleData={loadVehicleData}
                      submitInsuranceLoading={submitInsuranceLoading}
                      driverType={driverType}
                      engineSize={engineSize}
                      ton={ton}
                      passenger={passenger}
                      vehiclePrice={vehiclePrice}
                      insuranceType={insuranceType}
                      total={total}
                      handleReset={handleReset}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  insurances: { list, insuranceCalculations, submitInsuranceLoading },
  auth: { user },
  darkTheme: { isDarktheme },
}) => ({
  user,
  insurances: list,
  insuranceCalculations,
  submitInsuranceLoading,
  isDarktheme,
});

export default connect(mapStateToProps, {
  getInsurances,
  getInsuranceCalculations,
})(Insurance);
