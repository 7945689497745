import React, { useState, useEffect } from "react";
import translate from "../../i18n/translate";
import TextFieldGroup from "../common/TextFieldGroup";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword, callResendCode } from "./../../actions/userAction";
import Message from "./../common/Message";
import Spinner from "../ui/spinner/Spinner";
import Logo from "../../assests/images/logo/black-logo.svg";
import whiteLogo from "../../assests/images/logo/white-logo.svg";

import validateResetPasswordInput from "../../validation/validateResetPasswordInput";
import Countdown from "react-countdown";
import humanizeDuration from "humanize-duration";
import { Button } from "react-bootstrap";
import classnames from "classnames";
import axios from "axios";
import VerificationInput from "react-verification-input";

const ResetPassword = ({
  resetPassword,
  callResendCode,
  isAuthenticated,
  massage,
  mobile,
  isDarktheme,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [virefyForm, setVirefyForm] = useState({
    random_number: "",
    password: "",
    password2: "",
    seller_id: history.location.state.sellerId,
    last_4_digit: history.location.state.last4Digit,
  });
  const [errors1, setErrors1] = useState({});
  const [loading, isLoading] = useState(false);
  const [timer, setTimer] = useState(180000);

  useEffect(() => {
    document.title = "Reset Password | Phone Play";
    if (isAuthenticated) {
      history.push("/");
    }

    // commented because of bug
    // const getIP = async () => {
    //   const res = await axios.get('https://geolocation-db.com/json/', null)
    //   const myIp = res.data.IPv4
    //   localStorage.setItem('ip', myIp)
    // }
    // getIP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (e) => {
    setVirefyForm({ ...virefyForm, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    isLoading(true);

    const { errors, isValid } = validateResetPasswordInput(virefyForm);
    if (!isValid) {
      setErrors1(errors);
      isLoading(false);
    } else {
      resetPassword(virefyForm, history).finally(() => {
        isLoading(false);
      });
    }
  };

  const _updateTimer = (props) => {
    setTimer(props.total);
  };
  const _resendCode = () => {
    if (timer === 0) {
      setTimer(180000);
      callResendCode(virefyForm.seller_id);
    }
  };
  return (
    <section className="auth reset-password">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="card-wrapper">
            <div className="card fat">
              <div className="card-body">
                <div className="brand">
                  <img src={!isDarktheme ? Logo : whiteLogo} alt="logo"></img>
                </div>
                <h4 className="card-title text-center">
                  {translate(
                    "We sent you a confirmation code to your mobile number"
                  )}
                  <br />
                  <span className="mobile-number">
                    (xxx)-xxx-{virefyForm?.last_4_digit}
                  </span>
                </h4>

                <h6 className="card-subtitle text-center text-muted pb-0">
                  <Button
                    variant="link"
                    className="py-0 px-1"
                    onClick={_resendCode}
                    disabled={timer !== 0}
                  >
                    {translate("Resend code")}
                  </Button>
                  <Countdown
                    date={Date.now() + timer}
                    onTick={_updateTimer}
                    onComplete={_updateTimer}
                    renderer={(props) => (
                      <span className={classnames({ hidden: timer === 0 })}>
                        (
                        {humanizeDuration(props.total, {
                          language: intl.locale,
                          units: ["m", "s"],
                        })}
                        )
                      </span>
                    )}
                  />
                </h6>

                {massage !== null &&
                  massage !== "" &&
                  massage !== undefined && <Message msg={massage} />}

                <form
                  method="POST"
                  className="verification-validation"
                  noValidate=""
                  onSubmit={(e) => onSubmit(e)}
                >
                  <VerificationInput
                    autoFocus={true}
                    placeholder="-"
                    value={virefyForm.random_number}
                    onChange={(str) => {
                      setVirefyForm({ ...virefyForm, random_number: str });
                    }}
                    classNames={{
                      container: "container-input",
                      character: "character",
                      characterInactive: "character--inactive",
                      characterSelected: "character--selected blink",
                    }}
                    style={{ direction: "ltr" }}
                  />

                  <TextFieldGroup
                    placeholder={intl.formatMessage({ id: "Password" })}
                    name="password"
                    type="password"
                    value={virefyForm.password}
                    onChange={onChange}
                    error={errors1.password}
                    label={translate("Password")}
                    required={true}
                  />

                  <TextFieldGroup
                    placeholder={intl.formatMessage({ id: "Confirm password" })}
                    name="password2"
                    type="password"
                    value={virefyForm.password2}
                    onChange={onChange}
                    error={errors1.password2}
                    label={translate("Confirm password")}
                    required={true}
                  />

                  <div className="form-group mb-0 mt-4 actions">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {translate("Confirm")}
                    </button>
                  </div>

                  <div className="mt-4 text-center">
                    <a href="/signin"> {translate("Return to Sign in")}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </section>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  massage: state.error.massage,
  isDarktheme: state.darkTheme.isDarktheme,
});

export default connect(mapStateToProps, { resetPassword, callResendCode })(
  ResetPassword
);
