import React from "react";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { intl } from "i18n/provider";

const defaultProps = {
  buttonProps: {},
  iconProps: {},
};

export default function RefreshButton({ onClick, buttonProps, iconProps }) {
  // console.log("RefreshButton => onClick", c);
  return (
    <React.Fragment>
      <IconButton
        style={{ margin: 0, marginLeft: "8px", padding: "4px" }}
        color="primary"
        // title={intl.formatDate({ id: "refresh" })}
        onClick={onClick}
        {...buttonProps}
      >
        <RefreshIcon style={{ margin: 0 }} {...iconProps} />
      </IconButton>
    </React.Fragment>
  );
}

RefreshButton.defaultProps = defaultProps;
