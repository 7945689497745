import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { intl } from "i18n/provider";
import { CURRENCIES_SYMBOLS } from "shared/constants/constants";

export default function GameCard(props) {
  const [showSelected, setShowSelected] = useState(false);
  const { card, isSelected, onClick, cardQuantity } = props;
  const { image_path, value, currency, customer_price, brand_name, id, cost } =
    card;
  const [quantity, setQuantity] = useState(cardQuantity);

  const handleCardClick = () => {
    onClick(id, isSelected ? 0 : 1);
  };

  const incrementQuantity = (event) => {
    event.stopPropagation();
    setQuantity((prevQuantity) => prevQuantity + 1);
    onClick(id, quantity + 1);
  };

  const decrementQuantity = (event) => {
    event.stopPropagation();
    if (quantity > 1) setQuantity((prevQuantity) => prevQuantity - 1);
    onClick(id, quantity - 1);
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{ maxWidth: 350 }}
      onMouseEnter={() => {
        if (isSelected && !showSelected) {
          setShowSelected(true);
        }
      }}
      onMouseLeave={() => {
        if (isSelected && showSelected) {
          setShowSelected(false);
        }
      }}
    >
      <CardActionArea style={{ position: "relative" }}>
        <Fade in={isSelected} mountOnEnter={false} unmountOnExit={false}>
          <div
            style={{
              backgroundColor: "rgba(134, 183, 254, 0.1)",
              opacity: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              style={{
                color: "#86b7fe",
                backgroundColor: "white",
                borderRadius: "50%",
                fontSize: 52,
                boxShadow: "initial",
              }}
            />
          </div>
        </Fade>

        <CardMedia component="img" image={image_path} alt="Pubg" />
        <CardContent>
          <Grid container justifyContent={"center"}>
            <Grid item md={12} xs={12} container justifyContent={"center"}>
              <Typography fontWeight={"bold"} gutterBottom fontSize={18}>
                {`${value} ${
                  CURRENCIES_SYMBOLS[currency?.toLowerCase()]
                } (${customer_price} ${CURRENCIES_SYMBOLS.ils})`}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="body2" fontSize={14} color="text.secondary">
                {brand_name}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} container justifyContent={"center"}>
              <Typography variant="h6" fontWeight="bold" fontSize={14}>
                {intl.formatMessage({ id: "seller cost" }) +
                  ": " +
                  cost +
                  CURRENCIES_SYMBOLS.ils}
              </Typography>
            </Grid>
            {isSelected && (
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
                // zIndex={1000}
                style={{ position: "relative", zIndex: 20 }}
              >
                <Grid item>
                  <IconButton onClick={decrementQuantity} color="error">
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {quantity}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={incrementQuantity} color="success">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
