import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import Notiflix from "notiflix";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import TextFieldGroup from "components/common/TextFieldGroup";
import Modal from "components/common/Modal";

import { payPaltelBillApiCalled } from "shared/endPoints/apiCallers";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import { noBalanceContext } from "reducers/no_balance";
import translate from "i18n/translate";

const PayBillModal = ({
  number,
  selectedBill,
  isDisabled,
  clearSelections,
}) => {
  const noBalanceCtx = useContext(noBalanceContext);

  const [isOpen, setIsOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("970");
  const [wNumber, setWNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const history = useHistory();

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const { mutateAsync: payPaltelBill, isLoading } = useMutation(
    payPaltelBillApiCalled,
    {
      onSuccess: (res) => {
        if (res?.reason === "seller no balamce/ disabled") {
          return "noBalance";
        }
        if (res?.status === "failed") {
          Notiflix.Notify.failure(res?.reason || "Something went wrong");
          return;
        }
        setIsOpen(false);
        setWNumber(null);
        setEmail(null);
        Notiflix.Notify.success(`We are processing your bill`, "", "Close");
        clearSelections();
        history.push("/pay_bills");
      },
      onError: () => {
        Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
      },
    }
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const whatsappNumber = `${countryCode}${wNumber}`;
    const payload = {
      number,
      amount: selectedBill?.amount?.split(" ")?.[0],
      wNumber: wNumber ? whatsappNumber : "N/A",
      email: email ?? "N/A",
    };
    const status = await payPaltelBill(payload);
    if (status?.reason === "seller no balamce/ disabled") {
      noBalanceCtx.openCardHandler();
    }
    history.push("/pay_bills");
    setIsOpen(false);
  };

  return (
    <div id="paltel-pay-bills-modal-div">
      <Button
        onClick={() => {
          !isDisabled && setIsOpen(true);
        }}
        id="paltel-pay-bills-pay-btn"
        disabled={isDisabled}
      >
        {translate("Pay Bills")}
      </Button>
      {isOpen && (
        <Modal
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          title={"Pay"}
          width={"40vw"}
          id="paltel-pay-bills-modal"
          onSuccess={onSubmit}
          onSuccessLoading={isLoading}
        >
          <form className="phone-form" id="paltel-bills-modal-form">
            <div className="d-flex w-100 justify-content-around mb-4">
              <div className="bg-light p-3 rounded">
                <h6 className="m-0">
                  {translate("Number")}: {number}
                </h6>
              </div>
              <div className="bg-light p-3 rounded">
                <h6 className="m-0">
                  {translate("money")}: {selectedBill?.amount} ILS
                </h6>
              </div>
            </div>
            <Box sx={{ display: "flex", width: "80%" }}>
              <Select
                value={countryCode}
                onChange={handleCountryCodeChange}
                sx={{ width: "25%" }}
              >
                <MenuItem value="972">+972 </MenuItem>
                <MenuItem value="970">+970 </MenuItem>
                {/* Add more country codes and names as needed */}
              </Select>
              <TextField
                value={wNumber}
                type="number"
                onChange={(e) => setWNumber(e.target.value)}
                // placeholder="Whatsapp number"
                label={translate("Whatsapp number")}
                id="phone-number-input-field"
                sx={{ width: "75%" }}
              />
            </Box>
            <Box sx={{ width: "80%", mt: "16px" }}>
              <TextField
                value={email}
                type="email"
                id="paltel-pay-bills-email-input-field"
                onChange={(e) => setEmail(e.target.value)}
                // placeholder="Email"
                label="Email"
                sx={{ width: "100%" }}
              />
            </Box>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default PayBillModal;
