import React from 'react';
import { connect } from 'react-redux';
import Typist from 'react-text-typist';
import "./style.css";

const Typing = ({
  sentences,
  loop = false,
  isDarktheme
}) => {
  return (
    <div className={!isDarktheme ? "pp-typing" : "pp-dark-typing"}>
      <Typist sentences={sentences} loop={loop} />
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme
  }
}
export default connect(mapStateToProps, null)(Typing);
