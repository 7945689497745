import PageTitle from "components/common/PageTitle";
import translate from "i18n/translate";
import React from "react";

const Bnet = () => {
  return (
    <PageTitle title={translate("Bnet Bills")} backPage="/pay_bills">
      <h3
        style={{
          marginTop: "50px",
        }}
      >
        {translate("comingSoon")}
      </h3>
    </PageTitle>
  );
};

export default Bnet;
