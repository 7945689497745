import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import translate from "i18n/translate";
const ExportButton = ({ reports, children }) => {
  reports = [{ data: [...reports] }];
  const handleExport = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert each report to a worksheet
    reports.forEach((report, index) => {
      const worksheet = XLSX.utils.json_to_sheet(report.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, `Report ${index + 1}`);
    });

    // Generate a binary string from the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Save the file using file-saver
    saveAs(blob, "reports.xlsx");
  };

  return (
    <IconButton
      aria-label="delete"
      onClick={handleExport}
      disabled={reports[0].data.length === 0}
      sx={{ p: 0 }}
    >
      <BsFileEarmarkExcelFill className="ml-2" color="green" />
    </IconButton>
  );
};

export default ExportButton;
