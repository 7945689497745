import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { Box, Grid, Avatar } from "@mui/material";
import { deepPurple, green, red } from "@mui/material/colors";
import translate from "i18n/translate";

const Information = ({ coins, selectedCardsIds }) => {
  const totalCoins = coins.reduce(
    (acc, curr) => acc + (selectedCardsIds[curr.id] || 0) * curr.coins,
    0
  );
  const customer_price = coins.reduce(
    (acc, curr) => acc + (selectedCardsIds[curr.id] || 0) * curr.customer_price,
    0
  );
  const seller_price = coins.reduce(
    (acc, curr) => acc + (selectedCardsIds[curr.id] || 0) * curr.seller_price,
    0
  );

  return (
    <Card sx={{ minWidth: 275, marginBottom: 2, mt: 4 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: deepPurple[500], marginRight: 2 }}>
                <MonetizationOnIcon />
              </Avatar>
              <Typography variant="h6" component="div">
                {translate("Total Coins")}: {totalCoins}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: green[500], marginRight: 2 }}>
                <AccountBalanceWalletIcon />
              </Avatar>
              <Typography variant="h6" component="div">
                {translate("Customer Price")}: {customer_price.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ bgcolor: red[500], marginRight: 2 }}>
                <PriceChangeIcon />
              </Avatar>
              <Typography variant="h6" component="div">
                {translate("Seller Price")}: {seller_price.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Information;
