import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { isUnknownOrNaStr } from "../../../shared/utils/ppUtils.js";
import useAmountCardStyles from "./amountCardStyles.js";

const propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  color: "#540089",
  amount: "10",
  width: "100%",
  height: "100%",
};

export default function AmountCard(props) {
  const classes = useAmountCardStyles(props);
  const { amount, color, width, height, onClick, ...rest } = props;
  const intl = useIntl();
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      width={width}
      height={height}
      borderRadius={"4px"}
      className={classes.cardGridRoot}
      onClick={onClick}
      {...rest}
    >
      <Grid container item width={"50%"} paddingRight={"1%"} justifyContent={"center"}>
        <Typography
          height={"35px"}
          lineHeight={"normal"}
          color={"white"}
          fontWeight={"bold"}
          fontFamily={"Tajawal"}
          fontSize={24}
        >
          {intl.formatMessage({ id: "nis" })}
        </Typography>
      </Grid>
      <Grid
        item
        width={"calc(50% - 4px)"}
        height={"calc(100% - 4px)"}
        alignItems={"center"}
        container
        justifyContent={"center"}
        borderRadius={"4px"}
        style={{ backgroundColor: "white" }}
      >
        <Typography lineHeight={"normal"} color={color} fontWeight={"bold"} fontSize={26}>
          {amount}
        </Typography>
      </Grid>
    </Grid>
  );
}

AmountCard.propTypes = propTypes;
AmountCard.defaultProps = defaultProps;

export function AmountCardOrImg({ src = null, ...rest }) {
  if (src && !isUnknownOrNaStr(src)) return <img src={src} alt={rest?.alt ?? rest?.name ?? rest?.title} {...rest} />;
  return <AmountCard {...rest} />;
}
