import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import translate from "i18n/translate";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Modal from "components/common/Modal";
import { intl } from "i18n/provider";
import Toast from "components/common/Toast";
import { Notify } from "notiflix";
import ApiRequest from "../../actions/ApiRequest";
import { IS_MOBILE_DISPLAY } from "shared/constants/constants";

const Wallet = ({ isDarktheme }) => {
  const [walletNumber, setWalletNumber] = useState("");
  const [confirmWalletNumber, setConfirmWalletNumber] = useState("");
  const [amount, setAmount] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [sendIngDataLoading, setSendIngDataLoading] = useState(false);
  const [isWalletNumberFocused, setIsWalletNumberFocused] = useState(true);
  const [modalMessage, setModalMessage] = useState("");
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const refInput = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiRequest.post(`get_active_wallets`);
        setWallets(response.data);
        console.log(response.data[0]);
        setIsLoading(false);
      } catch (error) {
        Notify.failure(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (refInput.current) {
      refInput.current.focus();
    }
  }, [selectedWallet]);

  const handleWalletNumberChange = (event) => {
    setWalletNumber(event.target.value);
  };

  const handleConfirmWalletNumberChange = (event) => {
    setConfirmWalletNumber(event.target.value);
  };
  const handleOnConfirm = async () => {
    setSendIngDataLoading(true);
    try {
      const response = await ApiRequest.post(
        `wallet_send_money?wallet_id=${selectedWallet.id}&wallet_number=${walletNumber}&amount=${amount}`
      );
      if (response.data.status === "failed") {
        throw new Error(response.data.message);
      }
      setSendIngDataLoading(false);
      setWalletNumber("");
      setConfirmWalletNumber("");
      setAmount();
      Toast.fire({
        icon: "success",
        title: intl.formatMessage({
          id: "Your request has been sent successfully",
        }),
      });
    } catch (error) {
      Notify.failure(error.message);
      Toast.fire({
        icon: "error",
        title: intl.formatMessage({ id: "Your request has been failed" }),
      });
    } finally {
      setSendIngDataLoading(false);
    }
    setOpenModal(false);
  };
  const walletNumbersMatch = walletNumber === confirmWalletNumber;
  const isSubmitDisabled =
    !walletNumbersMatch || walletNumber === "" || amount < 50 || amount > 2000; // Assuming min 10 and max 1000 for the amount
  const showConfirmError =
    [...walletNumber].slice(0, confirmWalletNumber.length).join("") !==
    confirmWalletNumber;
  const handleSubmit = () => {
    setOpenModal(true);

    const formattedMessage = intl.formatMessage(
      { id: "confirmation_msg_wallet" },
      {
        walletNumber: `<span style="font-weight: bold; font-size: 1.2em;">${walletNumber}</span>`,
        amount: `<span style="font-weight: bold; font-size: 1.2em;">${amount}</span>`,
      }
    );
    setModalMessage(formattedMessage);
  };

  const backgroundStyle = {
    backgroundColor: "#fff",
    width: 200,
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    marginBottom: 16,
  };
  const selectedWalletStyle = {
    border: "1px dotted ", // green border
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // subtle shadow
    transform: "scale(1.05)", // slightly larger
  };
  const unselectedWalletStyle = {
    filter: "blur(3px)", // Apply a blur effect
    opacity: 0.7, // Reduce the opacity to make it look less focused
  };

  return (
    <div>
      <div className="card card-home mb-4">
        <div className={!isDarktheme ? "card p-4" : "dark-card p-4"}>
          <h1 className="header-text">{translate("select_wallet")}</h1>
        </div>
      </div>
      {isLoading ? (
        <Box
          sx={{ display: "flex", position: "fixed", left: "50%", top: "40%" }}
        >
          <CircularProgress />
        </Box>
      ) : wallets.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {wallets.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setSelectedWallet(item);
              }}
              style={{
                ...backgroundStyle,
                ...(selectedWallet && selectedWallet.id === item.id
                  ? selectedWalletStyle
                  : {}),
              }}
            >
              <img
                src={item["logo_url"]}
                alt="wallet"
                style={{
                  borderRadius: 16,
                  cursor: "pointer",
                  ...(selectedWallet && selectedWallet.id !== item.id
                    ? unselectedWalletStyle
                    : {}),
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div>no data</div>
      )}

      {selectedWallet && (
        <div style={{ width: IS_MOBILE_DISPLAY ? 300 : 400, margin: "0 auto" }}>
          <TextField
            label={translate("Mobile Number")}
            value={walletNumber}
            onChange={handleWalletNumberChange}
            margin="normal"
            fullWidth
            inputProps={{
              maxLength: 18,
              onCopy: (e) => e.preventDefault(),
            }}
            style={{
              filter: !isWalletNumberFocused ? "blur(3px)" : "none",
              direction: "ltr",
            }}
            onFocus={() => setIsWalletNumberFocused(true)}
            helperText={translate("Enter mobile number")}
            autoFocus
            inputRef={refInput}
          />

          <TextField
            label={translate("Confirm mobile Number")}
            value={confirmWalletNumber}
            onChange={handleConfirmWalletNumberChange}
            margin="normal"
            fullWidth
            error={showConfirmError}
            helperText={
              showConfirmError ? translate("Numbers do not match") : ""
            }
            onFocus={() => setIsWalletNumberFocused(false)}
            onBlur={() => setIsWalletNumberFocused(true)}
            style={{
              direction: "ltr",
            }}
          />

          <TextField
            label={translate("amount_you_want_to_charge")}
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
            fullWidth
            margin="normal"
            inputProps={{
              min: 50, // Minimum amount
              max: 2000, // Maximum amount
            }}
            helperText={intl
              .formatMessage({ id: "amount_helper_text" })
              .replace("{min}", 50)
              .replace("{max}", 2000)}
          />

          <Alert severity="warning" sx={{ marginTop: 2, marginBottom: 2 }}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {translate("imei_confirmation_warning")}
            </Typography>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitDisabled}
            sx={{ marginTop: 2 }}
            onClick={handleSubmit}
          >
            {translate("submit")}
          </Button>
        </div>
      )}

      {openModal && (
        <Modal
          visible={true}
          onClose={() => setOpenModal(false)}
          width={"45vw"}
          title={intl.formatMessage({ id: "Charge The Wallet" })}
        >
          <div style={{ ...backgroundStyle, margin: "0 auto" }}>
            <img
              src={selectedWallet["logo_url"]}
              alt="wallet"
              style={{
                borderRadius: 16,
              }}
            />
          </div>
          <Typography dangerouslySetInnerHTML={{ __html: modalMessage }} />
          <div
            className="d-flex justify-content-end align-items-center mt-4"
            style={{ width: "100%" }}
          >
            <button
              className="btn btn-danger mx-3"
              onClick={() => setOpenModal(false)}
            >
              {translate("Cancel")}
            </button>
            <LoadingButton
              onClick={handleOnConfirm}
              loading={sendIngDataLoading}
              variant="contained"
            >
              {translate("Confirm")}
            </LoadingButton>
          </div>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => ({
  isDarktheme,
});

export default connect(mapStateToProps)(Wallet);
