import React from "react";
import { intl } from "../../i18n/provider";
import CircularProgress from "@mui/material/CircularProgress";
import translate from "i18n/translate";

const Button = ({
  loading,
  type,
  title,
  style,
  className,
  disabled,
  formType,
  children,
  color,
  bgColor,
  ...props
}) => {
  const buttonColor = {
    color: color || className,
    backgroundColor: bgColor || className,
    borderColor: bgColor || className,
  };
  return (
    <button
      disabled={loading || disabled}
      className={`btn btn-${type} custom-btn ${className}`}
      type={formType || "button"}
      style={{ ...buttonColor, ...style }}
      {...props}
    >
      {loading ? (
        <CircularProgress size={22} sx={{ color: "white" }} />
      ) : (
        translate(intl.formatMessage({ id: title }))
      )}
      {!loading && children}
    </button>
  );
};

export default Button;
