import React, { Fragment } from "react";
import { IntlProvider, createIntl, createIntlCache } from "react-intl";

import { LOCALES } from "./locales";
import messages from "./messages";

const cache = createIntlCache();
const locale = localStorage.langCity || LOCALES.ENGLISH;
export const intl = createIntl(
  {
    locale: locale,
    messages: messages[locale],
  },
  cache
);

const handleIntlError = (err) => {
  // Suppress only missing translation errors
  if (err.code === "MISSING_TRANSLATION") {
    return; // Ignore the error entirely
  }
  // Log any other type of error
  console.error(err);
};

const Provider = ({ children, locale = LOCALES.ENGLISH }) => (
  <IntlProvider
    locale={locale}
    textComponent={Fragment}
    messages={messages[locale]}
    onError={handleIntlError}
  >
    {children}
  </IntlProvider>
);
export default Provider;
