import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const GameCard = ({ game, columnStyle, isDarktheme }) => {
  return (
    <div className={`${columnStyle} mb-4 wrapper`}>
      <Link
        to={{
          pathname: game.id === "jwaker" ? "/jwaker" : `/gaming/${game.id}`,
          state: {
            gameTitle: game.company_name,
            url: game.url,
            denominations_db_name: game.denominations_db_name,
            brand_id: game.mintroute_brand_id,
          },
        }}
      >
        <div
          className={
            !isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"
          }
        >
          <div className="frame">
            <img
              alt="game card"
              src={`${game.url}`}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className={
              !isDarktheme ? "card nav-layout" : "card dark-nav-layout"
            }
          >
            <h5 className="m-3 text-center">{game.company_name}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(GameCard);
