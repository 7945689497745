import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import ApiRequest from "../../actions/ApiRequest";
import { Api } from "@mui/icons-material";
import { noBalanceContext } from "reducers/no_balance";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import translate from "i18n/translate";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "@mui/styles";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "80%", md: "60%", lg: "50%" },

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  borderColor: "red",
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    gap: 1,
    color: "red",
    fontWeight: "bold",
  },
}));

export default function NoBalanceCard() {
  const noBalanceCtx = useContext(noBalanceContext);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [amountIsValid, setAmountIsValid] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState("");
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    width: "100px",
    padding: "8px 16px",
  };
  const handleClose = () => {
    noBalanceCtx.closeCard();
    setConfirm(false);
    setAmount("");
    setAmountIsValid(true);
  };
  const changeAmount = (e) => {
    setAmount(e.target.value);
    if (e.target.value <= 0 || e.target.value === "") {
      setAmountIsValid(false);
    } else {
      setAmountIsValid(true);
    }
  };

  const handleConfirm = async () => {
    let language = localStorage.getItem("langCity");

    if (confirm) {
      setLoading(true);
      setSuccess(false);
      if (amount <= 0 || amount === "") {
        setAmountIsValid(false);
        return;
      }
      await ApiRequest.post(
        `send_no_credits_whatsapp?amount=${amount}&dl=${language}`
      );
      setLoading(false);
      setSuccess(true);
      setAmount("");
      setConfirm(false);
      noBalanceCtx.closeCard();
    } else {
      setConfirm(true);
    }
  };

  return (
    <Modal
      open={noBalanceCtx.openCard}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={classes.root}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon sx={{ fontSize: "32px" }} />
            <Typography variant="body1">
              {translate("Payment Unsuccessful")}
            </Typography>
          </div>

          <Typography sx={{ fontSize: "32px" }}>
            {translate(
              "Your current balance is not enough for the current transaction"
            )}
            .
          </Typography>
          <Typography sx={{ fontSize: "20px", marginBottom: "16px" }}>
            {translate("Do you want to send a request to add credits?")}
          </Typography>
        </div>
        {confirm && (
          <Box sx={{ mt: "16px", mb: "16px" }}>
            <TextField
              id="outlined-basic"
              label={translate("enter the amount")}
              variant="outlined"
              value={amount}
              onChange={changeAmount}
              error={!amountIsValid}
              helperText={!amountIsValid && "amount must be greater than 0"}
              type="number"
            />
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={loading}
              onClick={handleConfirm}
            >
              {confirm ? translate("send") : translate("Yes")}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              onClick={handleClose}
              sx={{ width: "100px", padding: "8px 16px" }}
              variant="outlined"
              color="error"
            >
              {translate("cancel")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
