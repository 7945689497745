import React, { useEffect, useState } from "react";
import translate from "../../../i18n/translate";
import { useHistory } from "react-router-dom";
import Message from "../../common/Message";
import TextFieldGroup from "../../common/TextFieldGroup";
import BackNav from "../../common/BankNav";
import { connect } from "react-redux";
import ApiRequest from "../../../actions/ApiRequest";
import { getAllCompanies } from "../../../actions/companiesAction";
import ProvidersCards from "../../../core/containers/ProvidersCards/ProvidersCards.jsx";
import { checkNumberProvider } from "../../../shared/utils/ppUtils.js";
import AmountCard from "../../../core/core-components/AmountCard/AmountCard.jsx";
import { useMount } from "shared/hooks/ppHooks";

const InputNumber = ({
  isDarktheme,
  allCompanies,
  loading,
  getAllCompanies,
}) => {
  const history = useHistory();
  const provider_Id = history.location.state?.provider_id;
  const passedMobileNumber = history.location.state?.mobileNumber;

  const [value, setValue] = useState(passedMobileNumber || "");
  const [isEmpty, setIsEmpty] = useState(false);
  const [showData, setShowData] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);

  let typeCompany = history.location.pathname.split("/")[3];

  useEffect(() => {
    if (passedMobileNumber && passedMobileNumber?.trim()?.length === 10) {
      checkNumberProvider(passedMobileNumber, setCurrentNumber, setShowData);
    }
  }, [passedMobileNumber]);

  // let TranslatedcompanyName=translate(`${typeCompany}`);
  // console.log('typeCompany',TranslatedcompanyName.props.id.toLowerCase())
  useMount(() => {
    document.title = `${history.location.pathname.split("/")[3]} | Phone Play`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllCompanies();
  });

  const getdata = async (mobileNumber) => {
    try {
      const { data } = await ApiRequest.post(
        `number_provider?number=${mobileNumber}`
      );
      setCurrentNumber(data);
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      value === undefined ||
      value.split("").length < 10 ||
      value.split("").length > 10
    ) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      sessionStorage.removeItem("groupCompany");
      history.push({
        pathname: `/company/group/${
          history.location.pathname.split("/")[3]
        }/${value}`,
        state: { provider_Id },
      });
    }
  };
  // console.log('value',value===true,"showData",showData,'currentNumber',currentNumber !== null)
  const handelChangeNumber = async (event) => {
    setValue(event.target.value);
    if (event.target.value.split("").length === 10) {
      // setShowData(true)
      const response = await getdata(event.target.value);
      response?.pci !== "unknown" && setShowData(true);
      // getdata(event.target.value)
    } else {
      setShowData(false);
    }
  };

  return (
    <>
      <div>
        <div className={`card card-home ${typeCompany}`}>
          <div className="">
            <BackNav title={history.location.pathname.split("/")[3]} />
          </div>
        </div>
        <form className="my-5 text-Continer" onSubmit={onSubmit}>
          {isEmpty && <Message msg="Enter valid number" />}
          <div className="form-group row">
            <div className="col-12 col-md-7 d-flex">
              <label
                htmlFor="inputEmail3"
                className={
                  !isDarktheme
                    ? "col-3 col-form-label"
                    : "col-3 col-form-label input_Mobile_Color"
                }
                style={{ fontSize: "1.6rem", width: "40%" }}
              >
                {translate("Mobile Number")}
              </label>
              <div className="input-container" style={{ width: "60%" }}>
                <TextFieldGroup
                  type="number"
                  value={value}
                  onChange={handelChangeNumber}
                  autoFocus={true}
                  name={"mobileNumber"}
                />
              </div>
            </div>
            <div className="col-3 col-md-2">
              <button
                style={{ width: "110%", minHeight: "55px" }}
                type="submit"
                className="btn sign-but p-2 w-30"
                onSubmit={onSubmit}
              >
                {translate("next")}{" "}
              </button>
            </div>
          </div>
        </form>
        {showData && currentNumber?.provider !== "unknown" ? (
          <div
            className={
              isDarktheme &&
              currentNumber?.provider?.toLowerCase() ===
                translate(`${typeCompany}`).props.id.toLowerCase()
                ? "Company_Container_Dark"
                : isDarktheme &&
                  currentNumber?.provider?.toLowerCase() !==
                    translate(`${typeCompany}`).props.id.toLowerCase()
                ? "Company_Container_Red"
                : !isDarktheme &&
                  currentNumber?.provider?.toLowerCase() ===
                    translate(`${typeCompany}`).props.id.toLowerCase()
                ? "Company_Container_white_green"
                : isDarktheme &&
                  currentNumber?.provider?.toLowerCase() !==
                    translate(`${typeCompany}`).props.id.toLowerCase()
                ? "Company_Container_white_Red"
                : "Company_Container"
            }
          >
            <div
              className="img__wrap"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={currentNumber?.provider_logo}
                alt={currentNumber?.provider}
                className="Company_Logo"
              />
              {currentNumber?.pci_url === "N/A" ? (
                <span style={{ width: 140, height: 60 }}>
                  <AmountCard amount={currentNumber?.amount} />
                </span>
              ) : (
                <img
                  src={currentNumber?.pci_url}
                  alt={currentNumber?.pci}
                  className="Company_Logo"
                />
              )}
              <p
                className={
                  isDarktheme
                    ? "Company_Provider_Dark img__description"
                    : "Company_Provider img__description"
                }
              >
                {currentNumber?.pci}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              <p
                className={
                  isDarktheme ? "Company_Provider_Dark" : "Company_Provider"
                }
              >
                {currentNumber?.provider}
              </p>
              <p
                className={
                  isDarktheme ? "Company_Provider_Dark" : "Company_Provider"
                }
                style={{ paddingRight: "40px" }}
              >
                {currentNumber?.amount}₪
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {showData &&
        currentNumber?.provider !== "unknown" &&
        !(
          currentNumber?.provider?.toLocaleLowerCase() ===
          translate(`${typeCompany}`).props.id.toLowerCase()
        ) ? (
          <>
            <div className="attention_pay_div_container">
              <p className="Company_Provider_Error">
                {translate("Attention_Pay")}
              </p>
            </div>
          </>
        ) : null}
        <div className="Fixed_logo_Container">
          <ProvidersCards currentProviderId={provider_Id} />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({
  darkTheme: { isDarktheme },
  companies: { allCompanies, loading },
}) => {
  return {
    isDarktheme,
    allCompanies,
    loading,
  };
};
export default connect(mapStateToProps, { getAllCompanies })(InputNumber);
