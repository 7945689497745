import React from "react";
import PaginationMui from "@mui/material/Pagination";
import Select from "react-select";
import { connect } from "react-redux";
import { TablePagination, Typography, createStyles } from "@mui/material";
import { Box } from "@mui/system";

const sortValues = [
  { value: "5", label: 5 },
  { value: "10", label: 10 },
  { value: "15", label: 15 },
  { value: "20", label: 20 },
  { value: "25", label: 25 },
];

const Pagination = ({
  count,
  page,
  handleOffsetValue,
  handleSortChange,
  sort,
  hideSort,
  isDarktheme,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: isDarktheme ? "transparent" : "",
      border: "1px solid #242526",
    }),
    menu: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      color: isDarktheme ? "white" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarktheme ? "white" : "",
    }),
  };
  return (
    <div
      className={
        !isDarktheme
          ? "d-flex justify-content-between align-items-center mb-4 p-0 light-m"
          : "d-flex justify-content-between align-items-center mb-4 p-0 dark-m"
      }
    >
      {/* <PaginationMui count={count} page={page} onChange={handleOffsetValue} /> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Page: {page}</Typography>
          <PaginationMui
            count={Math.ceil(count / sort.value)}
            page={page}
            onChange={handleOffsetValue}
          />
        </Box>
        <TablePagination
          component="div"
          count={count}
          page={(+page || 1) - 1}
          onPageChange={(e, page) => {
            handleOffsetValue?.(e, page + 1);
          }}
          rowsPerPage={+sort.value}
          onRowsPerPageChange={(e) => handleSortChange?.(e.target.value)}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000, 2000]}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(Pagination);
