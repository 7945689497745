import React, { useEffect, useRef, useState } from "react";
import ApiRequest from "../../../actions/ApiRequest";
import Toast from "components/common/Toast";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  DialogContentText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SystemSettings = () => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const response = await ApiRequest.post("get_settings_items?action=get");
      setSettings(response.data);
    } catch (error) {
      Toast.fire({ title: "Something Went Wrong!", icon: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (data) => {
    setEditData(data);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setDialogOpen(false);
    try {
      const res = await ApiRequest.post(
        `get_settings_items?action=edit&index=${editData.index}&new_value=${editData.value}`
      );
      if (["failed", "fail"].includes(res.data.status)) {
        throw new Error("Update Failed!");
      }
      Toast.fire({ title: "Update Successful!", icon: "success" });
      fetchSettings();
    } catch (error) {
      Toast.fire({ title: "Update Failed!", icon: "error" });
    } finally {
      setIsLoading(false);
      handleDialogClose();
    }
  };

  const handleDeleteClick = (setting) => {
    setDeleteCandidate(setting);
    setDeleteDialogOpen(true);
  };

  const startCountdown = () => {
    setConfirmDisabled(true);
    setCountdown(10);
    clearInterval(intervalIdRef.current); // Clear any existing interval just in case
    intervalIdRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(intervalIdRef.current); // Clear interval when countdown reaches 0
          setIsConfirmed(true); // State to trigger confirmation logic
          setConfirmDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleConfirmDelete = () => {
    if (isConfirmed) executeDeletion();
    else startCountdown();
  };

  const executeDeletion = async () => {
    setDeleteDialogOpen(false);
    if (isConfirmed) {
      // Only execute if countdown is zero
      setIsLoading(true);
      try {
        const res = await ApiRequest.post(
          `get_settings_items?action=delete&index=${deleteCandidate.index}`
        );
        if (["failed", "fail"].includes(res.data.status)) {
          throw new Error("Update Failed!");
        }
        Toast.fire({ title: "Update Successful!", icon: "success" });
        fetchSettings();
      } catch (error) {
        Toast.fire({ title: "Deletion Failed!", icon: "error" });
      } finally {
        setIsLoading(false);
        setConfirmDisabled(false);
        setIsConfirmed(false);
      }
    }
  };

  const handleCloseDeletionDialog = () => {
    clearInterval(intervalIdRef.current); // Clear the countdown interval
    setIsConfirmed(false); // Set to false to prevent deletion when countdown reaches 0
    setDeleteDialogOpen(false);
    setConfirmDisabled(false);
    setCountdown(10); // Reset countdown on successful or aborted deletion
  };
  return (
    <div
      style={{
        margin: "20px auto",
        padding: "30px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        System Settings
      </Typography>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {settings.map((setting) => (
            <Grid item xs={12} sm={6} md={4} key={setting.index}>
              <Card
                elevation={3}
                sx={{
                  minHeight: "200px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" color="ActiveCaption">
                    {setting.index} : {setting.settings}
                  </Typography>
                  <Typography variant="body2">{setting.description}</Typography>
                  <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
                    Value: {setting.value}
                    <IconButton
                      onClick={() => handleEditClick(setting)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteClick(setting)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Edit Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Edit Value</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Value"
            type="text"
            fullWidth
            variant="outlined"
            value={editData?.value || ""}
            onChange={(e) =>
              setEditData({ ...editData, value: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeletionDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          {!isConfirmed && (
            <Box sx={{ display: "flex", alignItems: "flexStart", gap: 1 }}>
              <WarningAmberIcon color="warning" /> {/* Warning icon */}
              <DialogContentText>
                Are you sure you want to delete this setting? This action cannot
                be undone.
                {countdown > 0 ? ` Deleting in ${countdown} seconds...` : ""}
              </DialogContentText>
            </Box>
          )}

          {isConfirmed && (
            <Box sx={{ display: "flex", alignItems: "flexStart", gap: 1 }}>
              <CheckCircleIcon color="primary" /> {/* Confirmation icon */}
              <DialogContentText>
                Please click "Confirm" again to proceed with the deletion. This
                is to ensure that the deletion is intentional.
              </DialogContentText>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDeletionDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="secondary"
            autoFocus
            disabled={confirmDisabled}
          >
            {isConfirmed ? "Confirm again" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SystemSettings;
