// File: src/components/PlanCard.js

import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import {
  Phone as PhoneIcon,
  Message as MessageIcon,
  DataUsage as DataUsageIcon,
  AccessTime as AccessTimeIcon,
  NetworkCheck as NetworkCheckIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import ooredoJawwLogo from "../../../assests/images/ooredoJaww.png";
import palLogo from "../../../assests/images/pal.png";

import translate from "i18n/translate";

// Centralized styled components
const StyledCard = styled(Card)({
  margin: "20px auto",
  cursor: "pointer",
  borderRadius: 16,
  padding: "10px",
  border: "1px solid #e0e0e0",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  position: "relative",
  overflow: "hidden",
  height: 315,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const LogoContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 0",
});

const TitleText = styled(Typography)({
  color: "#D9534F",
  fontWeight: "bold",
  fontSize: "1.4rem",
  textAlign: "center",
});

const InfoContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap", // Allows wrapping
  gap: "10px",
  justifyContent: "space-between",
});

const InfoItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "45%", // Ensures two items per row
});

const InfoText = styled(Typography)({
  fontSize: 14, // Set consistent font size for all text
  marginLeft: 5,
  display: "flex",
});

// Component Definition
const IsraelCard = ({
  logo,
  smallLogo,
  price,
  minutes,
  messages,
  gb,
  palMinutes,
  validate,
  supports_gen_5g,
  addBasicCard,
  fixedIp,
  isBasicCard,
}) => {
  return (
    <StyledCard>
      {isBasicCard ? (
        <div>
          <img src={smallLogo} alt="Company Logo" style={{ width: "70%" }} />
          <TitleText>{price}</TitleText>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <InfoItem>
              <AccessTimeIcon sx={{ fontSize: 12 }} />
              <InfoText>
                {translate("validity")}: {validate} {translate("day")}
              </InfoText>
            </InfoItem>
          </div>
        </div>
      ) : (
        <>
          <div>
            <LogoContainer>
              <img src={logo} alt="Company Logo" style={{ width: "140px" }} />
            </LogoContainer>

            <TitleText>{price}</TitleText>

            <CardContent>
              <InfoContainer>
                {minutes && (
                  <InfoItem>
                    <PhoneIcon sx={{ fontSize: 12 }} />
                    <InfoText>
                      {translate("minutes")}: {minutes}
                    </InfoText>
                  </InfoItem>
                )}
                {messages && (
                  <InfoItem>
                    <MessageIcon sx={{ fontSize: 12 }} />
                    <InfoText>
                      {translate("sms")}: {messages}
                    </InfoText>
                  </InfoItem>
                )}
                {gb && (
                  <InfoItem>
                    <DataUsageIcon sx={{ fontSize: 12 }} />
                    <InfoText>
                      {translate("data")}: {gb} GB
                    </InfoText>
                  </InfoItem>
                )}

                <InfoItem>
                  <AccessTimeIcon sx={{ fontSize: 12 }} />
                  <InfoText>
                    {translate("validity")}: {validate} {translate("day")}
                  </InfoText>
                </InfoItem>

                <InfoItem>
                  <NetworkCheckIcon sx={{ fontSize: 12 }} />
                  <InfoText>
                    {translate("support5G")}: {supports_gen_5g}
                  </InfoText>
                </InfoItem>
                {palMinutes && (
                  <InfoItem>
                    <InfoText>
                      <img src={ooredoJawwLogo} alt="pal Logo" width={50} />:{" "}
                      {palMinutes} {translate("min")}
                    </InfoText>
                  </InfoItem>
                )}
              </InfoContainer>
            </CardContent>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {palMinutes && (
              <img
                src={palLogo}
                alt="Company Logo"
                style={{ width: 50, height: 40 }}
              />
            )}
            {addBasicCard && (
              <div
                style={{
                  width: "80px",
                  position: "relative",
                }}
              >
                <img
                  src={smallLogo}
                  alt="Company Logo"
                  className="w-100"
                  style={{ height: "auto" }}
                />
                {fixedIp && (
                  <span
                    className="position-absolute top-50 start-50 translate-middle  text-white rounded"
                    style={{ fontSize: "8px", fontWeight: "bold" }}
                  >
                    Fixed IP
                  </span>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </StyledCard>
  );
};

export default IsraelCard;
