import React, { useEffect, useState } from "react";
import { getCards } from "../../actions/cardsAction";
import { refreshColumnStyle } from "../Gaming/reusableFunctions";
import { useDispatch, useSelector } from "react-redux";
export default function PrintCards({ card }) {
  const dispatch = useDispatch();
  const [columnStyle, setColumnStyle] = useState(
    "col-lg-3 col-md-4 col-sm-6 card-md"
  );

  const { cards } = useSelector((state) => state.cards);
  useEffect(() => {
    document.title = "Electornic Cards | Phone Play";
    refreshColumnStyle(setColumnStyle);
    dispatch(getCards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        {cards.length && cards.map((x, i) => <h1 key={i}>{x.ID}</h1>)}
      </div>
    </div>
  );
}
