import React from 'react'
import PageTitle from '../common/PageTitle'

const Malchat = () => {
    return (
        <PageTitle title={'Malchat'}>

        </PageTitle>
    )
}

export default Malchat