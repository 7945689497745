import React, { useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";

const GiftCard = ({ card, columnStyle, isDarktheme, moveCard, index, id }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "cards",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "cards",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      className={`${columnStyle} mb-4 wrapper`}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      <Link
        to={{
          pathname: `/cards/${card.brand_name}`,
          state: {
            cardTitle: card.des,
          },
        }}
      >
        <div
          className={
            !isDarktheme ? "card outer-wrapper" : "card dark-outer-wrapper"
          }
        >
          <div className="frame">
            <img alt="card" src={card.url} />
          </div>
          <div
            className={
              !isDarktheme ? "card nav-layout" : "card dark-nav-layout"
            }
          >
            <h5 className="m-3 text-center">{card.des}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};
const mapStateToProps = ({ darkTheme: { isDarktheme } }) => {
  return {
    isDarktheme,
  };
};
export default connect(mapStateToProps, null)(GiftCard);
