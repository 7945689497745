import React, { Fragment, useEffect } from "react";
import Routes from "./components/routing/Routes";
import { Provider } from "react-redux";
import store from "./store";
import { I18Provider } from "./i18n";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  logoutUser,
  setCurrentUser,
  getMainPicture,
} from "./actions/userAction";
import setRequestHeader from "./components/common/setRequestHeader";
import jwt_decode from "jwt-decode";
import Notiflix from "notiflix";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { jsonParser } from "shared/utils/ppUtils";
import NoBalanceProvider from "reducers/no_balance";

const darkTheme = createTheme({
  palette: {
    // mode: "dark",
  },
});
if (localStorage.jwtUserToken) {
  setRequestHeader("token", localStorage.jwtUserToken);
  const decode = jwt_decode(localStorage.jwtUserToken);
  localStorage.setItem("token exp", decode.exp);
  store.dispatch(setCurrentUser(jsonParser(localStorage.getItem("companies"))));
  const currentTime = Date.now() / 1000;
  if (decode.exp < currentTime && !window.location.href.includes("/signin")) {
    store.dispatch(logoutUser());
    window.location.href = "/signin";
  }
}

if (
  !sessionStorage.getItem("rightImage") ||
  sessionStorage.getItem("rightImage") === "undefined" ||
  !sessionStorage.getItem("leftImage") ||
  sessionStorage.getItem("leftImage") === "undefined"
) {
  getMainPicture();
}

const App = () => {
  useEffect(() => {
    window.onkeydown = (e) => {
      if (e.ctrlKey && e.keyCode === 116) {
        sessionStorage.clear(); // clear all cache on hard refresh
      }
    };
  }, []);

  var lang;
  if (localStorage.langCity) {
    lang = localStorage.getItem("langCity");
  }

  let locale = lang || process.env.REACT_APP_DEFAULT_LANG;

  Notiflix.Notify.init({
    className: "notiflix-notify pp-notiflix",
    rtl: true,
  });

  return (
    <Provider store={store}>
      <NoBalanceProvider>
        <I18Provider locale={locale}>
          <Router>
            <ThemeProvider theme={darkTheme}>
              <Fragment>
                <Route component={Routes} />
              </Fragment>
            </ThemeProvider>
          </Router>
        </I18Provider>
      </NoBalanceProvider>
    </Provider>
  );
};

export default App;
