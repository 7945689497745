import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack.js";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { SYSTEM_COLORS } from "shared/constants/stylesConstants";

const propTypes = {
  title: PropTypes.string,
  onBackClick: PropTypes.func,
  rootGridProps: PropTypes.object,
  doNotTranslateTitle: PropTypes.bool,
};

export default function NavigateBackHeader(props) {
  let { title, onBackClick } = props;
  const rootGridProps = props.rootGridProps || {};
  const history = useHistory();
  const intl = useIntl();
  title = title || window.location.toLocaleString().split("/").at(-1) || "Back";

  function onBack() {
    onBackClick ? onBackClick() : history.goBack();
  }

  return (
    <React.Fragment>
      <Grid
        container
        item
        md={12}
        xs={12}
        style={{ borderBottom: `1px solid ${SYSTEM_COLORS.gray}`, direction: "ltr" }}
        marginBottom={1}
        {...rootGridProps}
      >
        <Grid item>
          <IconButton title={intl.formatMessage({ id: "Back" })} onClick={onBack}>
            <ArrowBackIcon color={"primary"} />
          </IconButton>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <Typography
            color={"primary"}
            fontFamily={'"Helvetica Neue W23-regular"'}
            fontSize={"large"}
            fontWeight={200}
            style={{ textTransform: "capitalize", fontSize: "18px" }}
          >
            {intl.formatMessage({ id: title })}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

NavigateBackHeader.propTypes = propTypes;
